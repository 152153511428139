import React, { useState } from 'react'
import { AlbumArtPreviewContainer, AlbumUploadsContainer, MusicTitleInfoContainer, MusicUploadAlbumArt, MusicUploadTitleArtContainer, SongArtCoverWrapper, SongDetailsContainer, SongDetailsSectionTitle, SongDetailsText, SongDetailsWrapper } from '../MusicUploadsFormElements'
import FileUploader from '../../FileUploader';
import LoadingScreen from '../../LoadingScreen';
import { AlbumArtPreview } from '../../AlbumArt';
import ImageUtils from '../../../utils/ImageUtils';
import Page1 from './Page1';
import { FormWrapper } from '../../CustomForm/CustomFormElements';

const VideoUploads = ({videoInfo}) => {
  const { defaultAlbumArt } = ImageUtils();
  const [thumbnail, setThumbnail] = useState({})
  const [isExistingSong, setExisting] = useState(false)
  const [videoTitle, setVideoTitle] = useState(videoInfo?.videoTitle ? videoInfo?.videoTitle : "")

  const handleInput = (e) => {

  }

  const handleToggle = (type) => {

  }

  return (
    <>
    <MusicTitleInfoContainer>
    <MusicUploadTitleArtContainer>
            <MusicUploadAlbumArt
              src={thumbnail?.url ? thumbnail.url : defaultAlbumArt}
              alt={'thumbnail'}
              onContextMenu={(e) => {
                e.preventDefault();
              }}
            />
          </MusicUploadTitleArtContainer>
    </MusicTitleInfoContainer>
    <SongDetailsContainer><SongDetailsWrapper>
      <FormWrapper>
        <Page1 
      isExistingSong={isExistingSong} 
      videoTitle={videoTitle} 
      handleInput={handleInput} 
      handleToggle={handleToggle} />
      </FormWrapper>
      

    </SongDetailsWrapper>
    {(
          <SongArtCoverWrapper>
            <>
              <SongDetailsSectionTitle>Thumbnail</SongDetailsSectionTitle>

              <AlbumUploadsContainer
                for={
                  thumbnail?.progress > 0 && !thumbnail?.url ? "" : "thumbnail"
                }
              >
                <span style={{ display: "flex" }}>
                  <SongDetailsText>
                    {thumbnail?.url ? (
                      `${thumbnail?.file?.name}`
                    ) : (
                      <small style={{ fontStyle: "italic" }}>
                        Click here to add thumbnail (Size should be at least
                        1600 x 1600 pixels){" "}
                      </small>
                    )}
                  </SongDetailsText>
                  {thumbnail?.progress > 0 && !thumbnail?.url && (
                    <SongDetailsText style={{ textDecoration: "underline" }}>
                      Cancel
                    </SongDetailsText>
                  )}
                  {thumbnail?.url && (
                    <SongDetailsText style={{ textDecoration: "underline" }}>
                      Replace
                    </SongDetailsText>
                  )}
                </span>
                <AlbumArtPreviewContainer thumbnail={thumbnail?.url}>
                  {thumbnail?.progress > 0 && !thumbnail?.url && (
                    <>
                      <LoadingScreen
                        transparent
                        isLoaded={thumbnail.url ? true : false}
                      />
                      {thumbnail.progress < 100
                        ? `${thumbnail?.progress.toFixed(2)}%`
                        : `Processing`}
                    </>
                  )}
                  {(thumbnail?.url || !thumbnail?.progress) && (
                    <>
                      <AlbumArtPreview
                        src={thumbnail?.url ? thumbnail.url : defaultAlbumArt}
                        onContextMenu={(e) => {
                          e.preventDefault();
                        }}
                      />
                    </>
                  )}
                </AlbumArtPreviewContainer>
                <FileUploader
                  id="thumbnail"
                  type={"file"}
                  name="thumbnail"
                  fileTypes={"image/x-png,image/gif,image/jpeg, image/jpg"}
                  acceptMultiple="false"
                  fileUpload={thumbnail}
                  setFileUpload={setThumbnail}
                  userType={"podcaster"}
                />
              </AlbumUploadsContainer>
            </>
          </SongArtCoverWrapper>
        )}</SongDetailsContainer>
    </>
  )
}

export default VideoUploads