import styled from "styled-components";
import { Link } from "react-router-dom";
import { PushSpinner } from "react-spinners-kit";
import { hexToRgba } from "../HexToRGBA";

export const Container = styled.div`
display: flex;
flex-direction: column;
  
  align-items:center;
  justify-content: center;
  backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);

  background: linear-gradient(180deg, rgba(179, 111, 247, 0.76) 0%, rgba(84, 120, 154, 0.6232) 100%);
  `;

export const FormWrap = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  align-items:center;
  justify-content: center;
 

  
  
`;
export const QrWrapper = styled.a`
  display:flex;
  align-content: center;
  justify-content: center;
  display: flex;
  margin-bottom: 30px;
`;

export const QrCode = styled.img`
  display:flex;
  align-content: center;
  justify-content: center;

  @media screen and (max-width: 480px) {
    width: 80%;
  }
`;

export const Icon = styled(Link)`
  margin-left: 32px;
  margin-top: 32px;
  text-decoration: none;
  color: ${(props) => props.theme.secondaryBg};
  font-weight: 200;
  font-size: 32px;

  @media screen and (max-width: 480px) {
    margin-left: 16px;
    margin-top: 8px;
  }
`;
export const FormContent = styled.div`
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media screen and (max-width: 480px) {
    padding: 10px;
	
  }
`;
export const Form = styled.form`
  display: flex;
  backdrop-filter: blur(5px);
  flex-direction: column;
  -webkit-backdrop-filter: blur(5px);
  background: ${(props) => hexToRgba(props.theme.mainBg, 0.6)};
  min-width: 400px;
  padding: 10% 6%;
  border-radius: 3%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.9);
`;

export const FormH1 = styled.h1`
  margin-bottom: 8%;;
  color: ${(props) => props.theme.secondaryBg};
  font-size: 1.3rem;
  font-weight: 400;
  text-align: center;
`;

export const FormLabel = styled.label`
  margin-bottom: 10%;
  color: ${(props) => props.theme.secondaryBg};
  font-size: 14px;
`;

export const FormInput = styled.input`
  padding: 16px 16px;
  margin-bottom: 20px;
  border: none;
  border-radius: 4px;
`;

export const FormButton = styled(Link)`
  background: ${(props) => (props.transparent ? props.theme.mainBG : props.theme.mainColor)};
  padding: 2% 1%;
  border: none;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-radius: 3%;
  color: ${(props) => (props.transparent ? props.theme.accentColor : props.theme.secondaryBg)};
  font-size: 14px;
  cursor: pointer;
  margin-bottom: 15px;
  text-decoration: ${(props) => (props.transparent ? 'underline' : 'none')};


  @media screen and (max-width: 480px) {
    margin-top: 0;
    font-size: 14px;
  }
`;

export const Text = styled.span`
  text-align: center;
  color: ${(props) => props.theme.secondaryBg};
  font-size: 14px;
  margin-bottom: 10px;

  @media screen and (max-width: 480px) {
    margin-top: 0;
  }
`;
export const TextLink = styled.a`
  color: ${(props) => props.theme.accentColor};
  cursor: pointer;
  text-decoration: none;

  &:hover{
    transition: all 0.3s ease-in-out;
    text-decoration: underline;
    
  }
`;

export const TextRoute = styled(Link)`
  text-decoration: none;
  color: ${(props) => props.theme.secondaryColor};
  &:hover{
    transition: all 0.3s ease-in-out;
    text-decoration: underline;
    
  }

`
export const TextRoute1 = styled.a`
  text-decoration: none;
  color: ${(props) => props.theme.secondaryColor};
  &:hover{
    transition: all 0.3s ease-in-out;
    text-decoration: underline;
    
  }

`

export const Loader = styled(PushSpinner)`
  display: flex;
  size: 30;
  text-color: ${(props) => props.theme.secondaryColor};
  color: ${(props) => props.theme.secondaryColor};
  justify-content: center;
  align-items: center;
`;

export const Small = styled.small`
  color: ${(props) => props.theme.accentColor};
  text-align: center;


`

export const SignOut = () => {
  localStorage.removeItem("userAddress");
  localStorage.removeItem("userToken");
};
