import React, { useEffect, useState } from "react";
import useMusic from "../../../../hooks/useMusic";
import useArtist from "../../../../hooks/useArtist";
import ContentWrapper from "../../../ContentWrapper";
import { SelectButton, SelectButtonWrapper } from "../../../Select";
import {
  getObjectLength,
  groupInfoByDate,
  groupPurchasesByAmount,
} from "../../../../utils";
import KeyMetrics from "../../../Charts/KeyMetrics";
import { ModalLoadingScreen } from "../../../LoadingScreen";
import LineGraph from "../../../Charts/Line";
import BarGraph from "../../../Charts/Bar";
import { FormText, FormWrapper } from "../../../CustomForm/CustomFormElements";
import { useWebsiteTheme } from "../../../WebsiteTheme/WebsiteThemeContext";
import { CURRENCIES, CURRENCIES_STATS_COLORS } from "../../../../utils/Enum";
import ChartToggle from "../../../Charts";

const RevenueContent = ({ artistId, isLoaded, setLoaded, filter }) => {
  const {themeColors} = useWebsiteTheme()

  const {
    getArtistCollectionPurchasesByDate,
    getArtistRoyaltyPurchasesByDate,
    getArtistDigitalPurchasesByDate,
  } = useArtist({ id: artistId });
  const { getSongs } = useMusic();
  const [metricType, setMetricType] = useState("digital");
  const [purchases, setPurchases] = useState([]);
  const [digitalPurchases, setDigitalPurchases] = useState();
  const [royaltyPurchases, setRoyaltyPurchases] = useState();
  const [collectionPurchases, setCollectionPurchases] = useState();

  const getData = async () => {
    setLoaded(false);
    setCollectionPurchases();
    setDigitalPurchases();
    setRoyaltyPurchases();
    return await Promise.all([
      getArtistDigitalPurchasesByDate(artistId, filter),
      getArtistRoyaltyPurchasesByDate(artistId, filter),
      getArtistCollectionPurchasesByDate(artistId, filter),
    ])
      .then((data) => {
        let p = [];
        if (data[0] !== false && data[0]?.length > 0) {
          p.push(...data[0]);

          setDigitalPurchases(data[0]);
        }
        if (data[1]?.length > 0 && data[1] !== false) {
          p.push(...data[1]);
          setRoyaltyPurchases(data[1]);
          //console.log(data[1])
        }
        if (data[2]?.length > 0 && data[2] !== false) {
          p.push(...data[2]);
          setCollectionPurchases(data[2]);
          //console.log(data[1])
        }
        setPurchases(p);
        setLoaded(true);
        return;
      })
      .catch((err) => {
        console.error(err);
        setLoaded(true);
        return;
      });
  };

  useEffect(() => {
    getData();
    return () => {};
  }, [artistId, filter]);

  /* const getMetricData = () => {
        switch (key) {
            case value:
                
                break;
        
            default:
                break;
        }
    } */

  return (
    <ContentWrapper>
      {!isLoaded && <ModalLoadingScreen text={"Loading Stats..."} />}
      {isLoaded && (
        <>
          <KeyMetrics
            data={[
              {
                heading: "Total Sales",
                onClick: () => {
                  purchases?.length > 0 && setMetricType("total");
                },
                labels:
                  getObjectLength(
                    groupInfoByDate(purchases, "createdAt", filter)
                  ) > 0
                    ? Object.keys(
                        groupInfoByDate(purchases, "createdAt", filter)
                      )
                    : [],
                datasets: [
                  {
                    label: "Sales",
                    data:
                      getObjectLength(
                        groupInfoByDate(purchases, "createdAt", filter)
                      ) > 0
                        ? Object.values(
                            groupInfoByDate(purchases, "createdAt", filter)
                          ).map((purchase) => {
                            return purchase?.length;
                          })
                        : [],
                    borderColor: themeColors.successColor,
                    backgroundColor: themeColors.successColor,
                  },
                ],
              },
              {
                heading: "Digital Sales",
                onClick: () => {
                  digitalPurchases?.length > 0 && setMetricType("digital");
                },
                labels:
                  digitalPurchases?.length > 0
                    ? Object.keys(
                        groupInfoByDate(digitalPurchases, "createdAt", filter)
                      )
                    : [],
                datasets: [
                  {
                    label: "Sales",
                    data:
                      digitalPurchases?.length > 0
                        ? Object.values(
                            groupInfoByDate(
                              digitalPurchases,
                              "createdAt",
                              filter
                            )
                          ).map((purchase) => {
                            return purchase?.length;
                          })
                        : [],
                    borderColor: themeColors.infoColor,
                    backgroundColor: themeColors.infoColor,
                  },
                ],
              },
              {
                heading: "Regium Sales",
                onClick: () => {
                  royaltyPurchases?.length > 0 && setMetricType("royalty");
                },
                labels:
                  royaltyPurchases?.length > 0
                    ? Object.keys(
                        groupInfoByDate(royaltyPurchases, "createdAt", filter)
                      )
                    : [],
                datasets: [
                  {
                    label: "Sales",
                    data:
                      royaltyPurchases?.length > 0
                        ? Object.values(
                            groupInfoByDate(
                              royaltyPurchases,
                              "createdAt",
                              filter
                            )
                          ).map((purchase) => {
                            return purchase?.length;
                          })
                        : [],
                    borderColor: themeColors.accentColor,
                    backgroundColor: themeColors.accentColor,
                  },
                ],
              },
              {
                heading: "NFToken Sales",
                onClick: () => {
                  collectionPurchases?.length && setMetricType("nfToken");
                },
                labels:
                  collectionPurchases?.length > 0
                    ? Object.keys(
                        groupInfoByDate(
                          collectionPurchases,
                          "createdAt",
                          filter
                        )
                      )
                    : [],
                datasets: [
                  {
                    label: "Sales",
                    data:
                      collectionPurchases?.length > 0
                        ? Object.values(
                            groupInfoByDate(
                              collectionPurchases,
                              "createdAt",
                              filter
                            )
                          ).map((purchase) => {
                            return purchase?.length;
                          })
                        : [],
                    backgroundColor: themeColors.mainColor,
                    borderColor: themeColors.mainColor,
                  },
                ],
              },
            ]}
          />
          {purchases?.length > 0 && metricType === "total" && (
            <>
             
                <ChartToggle
                  data={{
                    heading: 'Total Sales',
                    labels:
                      getObjectLength(
                        groupInfoByDate(purchases, "createdAt", filter)
                      ) > 0
                        ? Object.keys(
                            groupInfoByDate(purchases, "createdAt", filter)
                          )
                        : [],
                    datasets: [
                      {
                        label: "Sales",
                        data:
                          getObjectLength(
                            groupInfoByDate(purchases, "createdAt", filter)
                          ) > 0
                            ? Object.values(
                                groupInfoByDate(purchases, "createdAt", filter)
                              ).map((purchase) => {
                                return purchase?.length;
                              })
                            : [],
                        borderColor: themeColors.successColor,
                        backgroundColor: themeColors.successColor,
                      },
                    ],
                  }}
                />
              
            
                <ChartToggle
                type={'bar'}
                  data={{
                    heading: 'Revenue (Grouped By Sales Type)',
                    labels:
                      purchases?.length > 0
                        ? ["Digitals", "Royalties", "Collections"]
                        : [],
                    datasets: [
                      {
                        label: "Sales",
                        data:
                          purchases?.length > 0
                            ? [
                                digitalPurchases?.length > 0
                                  ? digitalPurchases?.length
                                  : 0,
                                royaltyPurchases?.length > 0
                                  ? royaltyPurchases?.length
                                  : 0,
                                collectionPurchases?.length > 0
                                  ? collectionPurchases?.length
                                  : 0,
                              ]
                            : [],
                        borderColor: [themeColors.successColor, themeColors?.accentColor, themeColors?.infoColor],
                        backgroundColor: [themeColors.successColor, themeColors?.accentColor, themeColors?.infoColor],
                      },
                    ],
                  }}
                />
             
              {getObjectLength(groupPurchasesByAmount(purchases)) > 0 && (
               
                  <ChartToggle
                  type={'bar'}
                    data={{
                      heading: 'Revenue (Grouped By Currency)',
                      labels:
                        getObjectLength(groupPurchasesByAmount(purchases)) > 0
                          ? Object.keys(groupPurchasesByAmount(purchases))
                          : [],
                      datasets: [
                        {
                          label: "Total Earnings",
                          data:
                            purchases?.length > 0
                              ? Object.values(
                                  groupPurchasesByAmount(purchases)
                                ).map((purchase) => {
                                  return parseFloat(purchase).toFixed(2);
                                })
                              : [],
                          borderColor: Object.keys(
                            groupPurchasesByAmount(purchases)
                          ).map((purchase) => {
                            return CURRENCIES_STATS_COLORS[purchase];
                          }),
                          backgroundColor: Object.keys(
                            groupPurchasesByAmount(purchases)
                          ).map((purchase) => {
                            return CURRENCIES_STATS_COLORS[purchase];
                          }),
                        },
                      ],
                    }}
                  />
              )}
            </>
          )}
          {digitalPurchases?.length > 0 && metricType === "digital" && (
           
              <ChartToggle
              type={'bar'}
                data={{
                  heading: 'Revenue (Grouped By Currency)',
                  labels:
                    getObjectLength(groupPurchasesByAmount(digitalPurchases)) >
                    0
                      ? Object.keys(groupPurchasesByAmount(digitalPurchases))
                      : [],
                  datasets: [
                    {
                      label: "Total Earnings",
                      data:
                        digitalPurchases?.length > 0
                          ? Object.values(
                              groupPurchasesByAmount(digitalPurchases)
                            ).map((purchase) => {
                              return parseFloat(purchase).toFixed(2);
                            })
                          : [],
                          borderColor: Object.keys(
                            groupPurchasesByAmount(digitalPurchases)
                          ).map((purchase) => {
                            return CURRENCIES_STATS_COLORS[purchase];
                          }),
                      backgroundColor: Object.keys(
                        groupPurchasesByAmount(digitalPurchases)
                      ).map((purchase) => {
                        return CURRENCIES_STATS_COLORS[purchase];
                      }),
                    },
                  ],
                }}
              />

          )}
          {royaltyPurchases?.length > 0 && metricType === "royalty" && (
            
              <ChartToggle
              type={'bar'}
                data={{
                  heading: 'Revenue (Grouped By Currency)',
                  labels:
                    getObjectLength(groupPurchasesByAmount(royaltyPurchases)) >
                    0
                      ? Object.keys(groupPurchasesByAmount(royaltyPurchases))
                      : [],
                  datasets: [
                    {
                      label: "Total Earnings",
                      data:
                        royaltyPurchases?.length > 0
                          ? Object.values(
                              groupPurchasesByAmount(royaltyPurchases)
                            ).map((purchase) => {
                              return parseFloat(purchase).toFixed(2);
                            })
                          : [],
                          borderColor: Object.keys(
                            groupPurchasesByAmount(royaltyPurchases)
                          ).map((purchase) => {
                            return CURRENCIES_STATS_COLORS[purchase];}),
                      backgroundColor: Object.keys(
                        groupPurchasesByAmount(royaltyPurchases)
                      ).map((purchase) => {
                        return CURRENCIES_STATS_COLORS[purchase];
                      }),
                    },
                  ],
                }}
              />
          )}
          {collectionPurchases?.length > 0 && metricType === "collection" && (
            
              <ChartToggle
              type={'bar'}
                data={{
                  heading:'Revenue (Grouped By Currency)',
                  labels:
                    getObjectLength(
                      groupPurchasesByAmount(collectionPurchases)
                    ) > 0
                      ? Object.keys(groupPurchasesByAmount(collectionPurchases))
                      : [],
                  datasets: [
                    {
                      label: "Total Earnings",
                      data:
                        collectionPurchases?.length > 0
                          ? Object.values(
                              groupPurchasesByAmount(collectionPurchases)
                            ).map((purchase) => {
                              return parseFloat(purchase).toFixed(2);
                            })
                          : [],
                      backgroundColor: Object.keys(
                        groupPurchasesByAmount(collectionPurchases)
                      ).map((purchase) => {
                        return CURRENCIES_STATS_COLORS[purchase];
                      }),
                      borderColor: Object.keys(
                        groupPurchasesByAmount(collectionPurchases)
                      ).map((purchase) => {
                        return CURRENCIES_STATS_COLORS[purchase];
                      }),
                    },
                  ],
                }}
              />
          )}

          {purchases?.length === 0 &&
            (metricType === "total" ||
              metricType === "digital" ||
              metricType === "royalty" ||
              metricType === "collection") && (
              <>
                <FormWrapper>
                  <FormText>No Data Available</FormText>
                </FormWrapper>
              </>
            )}
        </>
      )}
    </ContentWrapper>
  );
};

export default RevenueContent;
