import React, {useState, useRef, useEffect} from "react";
import { useAuth } from "../../contexts/AuthContext";
import { CURRENCIES, TRANSACTION_OPTIONS } from "../../utils/Enum";
import CurrencySelector from "../CurrencySelector";
import { FormButton, FormLabel, FormWrapper } from "../CustomForm/CustomFormElements";
import { HoverOption } from "../MenuOptions/MenuOptionsElements";
import Modal from "../Modal";
import { useModal } from "../Modal/ModalContext";
import OptionModal from "../OptionModal";
import QRCodeForm from "../QRCodeForm";
import { Form, FormContent, FormInput } from "./TipFormElements";



const TipForm = ({displayName, address}) => {
  const {currentUser} = useAuth()

  const {toggleModal, modalContent} = useModal()
    const [isOpen, setOpen] = useState(true)
    const [currency, setCurrency] = useState(CURRENCIES.WYM)
    const [isSuccessful, setSuccess] = useState(false)
    const [tip, setTip] = useState(1)
    const [memo, setMemo] = useState()
    const [paymentBody, setPaymentBody] = useState({})
    const [paymentType, setPaymentType] = useState()
    const [paymentHeading, setPaymentHeading] = useState(`Tipping ${displayName}`)
  
  

   

      useEffect(() => {
        setOpen(true)
        return () => {}
      }, [])
      
 const formValidation = () => {
  //console.log(currency)

    if(tip > 0 && memo)
    {
      makePayment()
    }else if(tip > 0) {
      makePayment()
    }
  }

  const onSuccess = () => {
    modalContent(<>
      <OptionModal heading={`Successfully tipped ${displayName}`}>
        <HoverOption onClick={() => {toggleModal(); modalContent()}}>
          OK
        </HoverOption>

      </OptionModal>
    </>)
  }

  const onFailure = () => {
    modalContent(<>
      <OptionModal heading={`Successfully tipped ${displayName}`}>
        <HoverOption onClick={() => {makePayment()}}>
         Try again
        </HoverOption>
        <HoverOption onClick={() => {toggleModal(); modalContent()}}>
          OK
        </HoverOption>

      </OptionModal>
    </>)
  }

  const onCancel = () => {
    toggleModal()
    modalContent()
  }

  const makePayment =() => {
    
      
        setPaymentHeading(`Tipping ${displayName}`)
        modalContent(
          <QRCodeForm 
          body={{sender: currentUser?.uid ? currentUser.uid : undefined,
            receiver: address,
            value: tip,
            pathfinding: true,
            currency: currency,
            instruction: `User is making the following transaction to tip ${displayName}`,
            memo: memo ? memo : false
          }} 
          heading={paymentHeading} type={TRANSACTION_OPTIONS.TIP_USER}
          onSuccess={onSuccess}
          onFail={onFailure}
          cancelQr={onCancel}
          >
          </QRCodeForm>
   )
    setCurrency()
  }


    return (
        <>
            <OptionModal heading={`Tipping ${displayName}`}>
                     <> 
            <FormWrapper row ><FormLabel>Currency</FormLabel><CurrencySelector setCurrency={setCurrency}/></FormWrapper>

            <FormInput min={1} step={0.1} onChange={(e) => setTip(e.target.value)} type='number' placeholder='Tip amount' required value={tip}/>
            <FormInput  onChange={(e) => setMemo(e.target.value) } placeholder={`Send custom message to ${displayName}`} value={memo} />
            <HoverOption type="submit" to='#tip' onClick={formValidation}>Tip</HoverOption>
            <FormButton transparent={true}  to='#'
             onClick={() => {
              toggleModal()
              modalContent()
              }}>Cancel</FormButton>
            </> 
        </OptionModal>
        </>
    )


}

export default TipForm