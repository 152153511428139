import React, { useEffect } from 'react'
import { Fade } from 'react-reveal'
import { useNavigate } from 'react-router-dom'
import { HeroBg, HeroContainer, HeroContent, HeroH1, HeroP, VideoBg } from '../components/HeroSection/HeroElements'
import Video from '../videos/video.mp4'
const PageNotFound = () => {
    const navigate  = useNavigate()

    useEffect(() => {
        const interval = setInterval(() => {
            navigate( '/', {replace: true}) }, 6000);
        return () => clearInterval(interval);
        
    }, [])


    return (<>
        <HeroContainer>
            <HeroBg>
            <VideoBg autoPlay loop muted src={Video}
                    type='video/mp4' contentEditable={false} onContextMenu={(e) => {e.preventDefault()}}/>
            </HeroBg>
            <HeroContent>
                
            <Fade infinite><HeroH1 style={{color: 'white'}}>404 Page Not Found</HeroH1></Fade>
            <Fade infinite><HeroH1 style={{color: 'white'}}>You have gone beyond our radar</HeroH1></Fade>
            <Fade infinite><HeroP style={{color:'white'}}>You will be redirected shortly</HeroP> </Fade>

                </HeroContent>
            </HeroContainer>

    </>)

}

export default PageNotFound