import React, { useEffect, useState } from 'react'
import { SelectButton, SelectButtonWrapper } from '../Select'
import { ModalLoadingScreen } from '../LoadingScreen'
import KeyMetrics from '../Charts/KeyMetrics'
import { getGroupedPlays, getNumListeners, getObjectLength, getPlayCount, groupByPlayingFrom, groupInfoByDate, groupPurchasesByAmount } from '../../utils'
import ContentWrapper from '../ContentWrapper'
import { ChipList } from '../Chip/ChipElements'
import { PlaylistTable, SinglesReleasesTable } from '../ContentTable/ArtistTables'
import { FormText, FormWrapper } from '../CustomForm/CustomFormElements'
import useMusic from '../../hooks/useMusic'
import Chip from '../Chip'
import { CURRENCIES_STATS_COLORS, MUSIC_PLAYING_FROM, MUSIC_PLAYING_FROM_STATS_COLORS } from '../../utils/Enum'
import PieGraph from '../Charts/Pie'
import { getRandomColor } from '../HexToRGBA'
import { useWebsiteTheme } from '../WebsiteTheme/WebsiteThemeContext'
import ChartToggle from '../Charts'

const SongStats = ({song}) => {
    const {themeColors} = useWebsiteTheme()

  const {getSongPlaysByDate, getSongLikesByDate, getNFTokenCollectionsBySong, getSongDigitalPurchasesByDate, getSongRoyaltyPurchasesByDate, getSongPlaylistAdditionsByDate, getEpisodesContainingSongByDate} = useMusic()
  const [filter, setFilter] = useState('7days')
  const [isLoaded,setLoaded] = useState(false)
  const [plays, setPlays] = useState([])
  const [likes, setLikes] = useState([])
  const [collections, setCollections] = useState([])
  const [purchases, setPurchases] = useState([])
  const [playlists, setPlaylists] = useState([])
  const [episodes, setEpisodes] = useState([])
  const [digitalPurchases, setDigitalPurchases] = useState([])
  const [royaltyPurchases, setRoyaltyPurchases] = useState([])
  const [metricType, setMetricType] = useState('plays')
  const [playType, setPlayType] = useState("all")
  const [purchaseType, setPurchaseType] = useState('all')

  const getData = async() => {
      setLoaded(false)
      setPlaylists([])
      setRoyaltyPurchases([])
      setDigitalPurchases([])
      setPlays([])
      setEpisodes([])
      setCollections([])
      setLikes([])



      return await Promise.all([getSongPlaysByDate(song,filter),getSongLikesByDate(song,filter), getNFTokenCollectionsBySong(song, 'all'), getSongDigitalPurchasesByDate(song,filter), getSongRoyaltyPurchasesByDate(song,filter),getSongPlaylistAdditionsByDate(song,filter,"all"), getEpisodesContainingSongByDate(song,filter,"all") ])
      .then((data) => {
          let p = []
          if(getObjectLength(data[0]?.plays) > 0){
              setPlays(data[0]?.plays)
          }
          if(data[1]?.likes?.length > 0){
              setLikes(data[1]?.likes)
          }
          if(data[2]?.collections?.length > 0){
              setCollections(data[2]?.collections)
          }
          if(data[3]?.purchases?.length > 0){
              p.push(...data[3]?.purchases)
              setDigitalPurchases(data[3]?.purchases)
          }
          if(data[4]?.purchases?.length > 0){
              p.push(...data[4]?.purchases)
             setRoyaltyPurchases(data[4]?.purchases) 
          }
          if(data[5]?.playlists?.length > 0){
            setPlaylists(data[5]?.playlists)
          }
          if(data[6]?.episodes?.length > 0){
            setEpisodes(data[6]?.episodes)
          }
          setPurchases(p)
          setLoaded(true)
      }).catch(err => {
          setLoaded(true)
      })
  }


  useEffect(() => {
    getData()
  
    return () => {
      
    }
  }, [song, filter])
  

return (
  <>
   <SelectButtonWrapper>
      <SelectButton disabled={!isLoaded}
                onChange={(e) => setFilter(e.target.value)}>
          <option value={"7days"}>7 days</option>
          <option value={"14days"}>2 Weeks</option>
          <option value={"30days"}>4 Weeks</option>
          <option value={"60days"}>2 Months</option>
          <option value={"180days"}>6 Months</option>
          <option value={"365days"}>12 Months</option>
          <option value={"AllTime"}>All Time</option>
      </SelectButton></SelectButtonWrapper>
  {!isLoaded && <ModalLoadingScreen text={'Loading Stats'}/>}
  {isLoaded && <><KeyMetrics data={[{
      //Plays,
      //likes
      //purchases,
      //tokenization{
          heading: "Plays",
          onClick: ()=> {
              setMetricType("plays")

          },
          labels: getObjectLength(plays) > 0 ? Object.keys(groupInfoByDate([...plays?.full, ...plays?.skips, ...plays?.partial],'timestamp', filter)) : [],
          datasets: [{
              label: "Plays",
              data: getObjectLength(plays)  > 0 ? Object.values(groupInfoByDate([...plays?.full, ...plays?.skips, ...plays?.partial],'timestamp', filter)).map(p => {
                  return getPlayCount(p)
              }) : [],
              borderColor: themeColors.errorColor,
              backgroundColor: themeColors.errorColor
          }]
          
      },{
          heading: "Likes",
          onClick: ()=>{
              setMetricType("likes")

          },
          labels: likes.length > 0 ? Object.keys(groupInfoByDate(likes,'dateCreated', filter)) : [],
          datasets: [{
              label: "Likes",
              data: likes.length > 0 ? Object.values(groupInfoByDate(likes,'dateCreated', filter)).map(l => {
                return l?.length
              }) : [],
              borderColor: themeColors.background,
              backgroundColor: themeColors.background,
          }]
          
      },{
          heading: "Playlists",
          onClick: ()=> {
              setMetricType("playlists")
          },
          labels: playlists?.length > 0 ? Object.keys(groupInfoByDate(playlists,'datePublished', filter)) : [],
          datasets: [{
              label: "playlists",
              data: playlists?.length > 0 ? Object.values(groupInfoByDate(playlists,'datePublished', filter)).map(p => {
                return p?.length
              }) : [],
              borderColor: themeColors.infoColor,
              backgroundColor: themeColors.infoColor
          }]
          
      }
      ,{
          heading: "Revenue",
          onClick: ()=> {
              setMetricType("revenue")
          },
          labels: purchases?.length > 0 ? Object.keys(groupInfoByDate(purchases,'createdAt', filter)) : [],
          datasets: [{
              label: "purchases",
              data: purchases?.length > 0 ? Object.values(groupInfoByDate(purchases,'createdAt', filter)).map(p => {
                return p?.length
              }) : [],
              borderColor: themeColors.successColor,
              backgroundColor: themeColors.successColor,
          }]
          
      }
      ]}/>
      <ContentWrapper>
        {metricType === "plays" && getObjectLength(plays) > 0 && <>
      <ChipList alignment={"right"}>
          <Chip label={"All"} background={playType === 'all' && themeColors.accentColor} onChipClick={() => setPlayType("all")}/>
          <Chip label={"Full Plays"} background={playType === 'full' && themeColors.successColor} onChipClick={() => setPlayType("full")}/>
          <Chip label={"Partial Plays"} background={playType === 'partial' && themeColors.infoColor} onChipClick={() => setPlayType("partial")}/>
          <Chip label={"Skips"} background={playType === 'skip' && themeColors.warningColor} onChipClick={() => setPlayType("skip")}/>
      </ChipList>
      {playType=== 'all' && <>
        <ChartToggle data={{
              heading: 'All Plays (By Type)',
              labels: getObjectLength(plays) > 0 ? Object.keys(groupInfoByDate([...plays?.full, ...plays?.skips, ...plays?.partial],'timestamp', filter)) : [],
              datasets: [{
                  label: "Full",
                  data: plays?.full?.length > 0 ? Object.values(groupInfoByDate(plays?.full,'timestamp', filter)).map(p => {
                      return p.length
                  }) : [],
                  
                  borderColor: themeColors.successColor,
                  backgroundColor: themeColors.successColor,
              },{
                  label: "Partial",
                  data: plays?.partial?.length > 0 ? Object.values(groupInfoByDate(plays?.partial,'timestamp', filter)).map(p => {
                      return p.length
                  }) : [],
                  
                  borderColor: themeColors.infoColor,
                  backgroundColor: themeColors.infoColor
              },{
                  label: "Skips",
                  data: plays?.skips?.length > 0 ? Object.values(groupInfoByDate(plays?.skips,'timestamp', filter)).map(p => {
                      return p?.length
                  }) : [],
                  borderColor: themeColors.warningColor,
                  backgroundColor: themeColors.warningColor
              }]
                         
      }}/>
      
      <ChartToggle data={{
          heading: 'Unique Listeners',
          labels: getObjectLength(plays) > 0 ? Object.keys(groupInfoByDate([...plays?.full, ...plays?.skips, ...plays?.partial],'timestamp', filter)) : [],
          datasets: [{
              label: "Listeners",
              data: getObjectLength(plays) > 0 ? Object.values(getNumListeners(groupInfoByDate([...plays?.full, ...plays?.skips, ...plays?.partial],'timestamp', filter))) : [],
              borderColor: playType === "skip" ? themeColors.warningColor : playType === "partial" ?  themeColors.infoColor : themeColors.successColor,
              backgroundColor: playType === "skip" ? themeColors.warningColor : playType === "partial" ?  themeColors.infoColor : themeColors.successColor
          }]
                     
  }}/>
      </>}{playType !== 'all' && <>
        <ChartToggle data={{
          heading: `${playType[0].toUpperCase()}${playType.substring(1)} Plays`,
          labels: getObjectLength(plays) > 0 ? Object.keys(groupInfoByDate([...plays?.full, ...plays?.skips, ...plays?.partial],'timestamp', filter)) : [],
          datasets: [{
              label: playType,
              data: getObjectLength(plays) > 0 ? Object.values(groupInfoByDate([...plays?.full, ...plays?.skips, ...plays?.partial],'timestamp', filter)).map(plays => {
                  return getGroupedPlays(plays)[playType]
              }) : [],
              
              borderColor: playType === "skip" ? themeColors.warningColor : playType === "partial" ?  themeColors.infoColor : themeColors.successColor,
              backgroundColor: playType === "skip" ? themeColors.warningColor : playType === "partial" ?  themeColors.infoColor : themeColors.successColor
          }]
                     
  }}/>
  <ChartToggle data={{
          heading: 'Unique Listeners',
          labels: getObjectLength(plays) > 0 ? Object.keys(groupInfoByDate([...plays?.full, ...plays?.skips, ...plays?.partial],'timestamp', filter)) : [],
          datasets: [{
              label: "Listeners",
              data: getObjectLength(plays) > 0 ? Object.values(getNumListeners(groupInfoByDate([...plays?.full, ...plays?.skips, ...plays?.partial].filter(p => playType === p?.playType),'timestamp', filter))) : [],
              borderColor: playType === "skip" ? themeColors.warningColor : playType === "partial" ?  themeColors.infoColor : themeColors.successColor,
              backgroundColor: playType === "skip" ? themeColors.warningColor : playType === "partial" ?  themeColors.infoColor : themeColors.successColor
          }]
                     
  }}/>
  </>}


  <ChartToggle 
  type={'bar'}
  data={{
          heading: "Played From",
          labels: getObjectLength(plays) > 0 ? Object.keys(groupByPlayingFrom([...plays?.full, ...plays?.skips, ...plays?.partial])) : [],
          datasets: [{
              label: "Played From",
              data: getObjectLength(plays) > 0 ? Object.values(groupByPlayingFrom([...plays?.full, ...plays?.skips, ...plays?.partial])) : [],
              borderColor: themeColors.successColor,
              backgroundColor: Object.keys(groupByPlayingFrom([...plays?.full, ...plays?.skips, ...plays?.partial])).map( (k) => {
                return MUSIC_PLAYING_FROM_STATS_COLORS[k]
              })
          }]
                     
  }}/>
  

      </>}

      {metricType === 'likes' && likes?.length > 0 && 
      <ChartToggle data={{
          heading: "Likes",
          labels: likes.length > 0 ? Object.keys(groupInfoByDate(likes,'dateCreated', filter)) : [],
          datasets: [{
              label: "Likes",
              data: likes.length > 0 ? Object.values(groupInfoByDate(likes,'dateCreated', filter)).map(l => {
                return l?.length
              }) : [],
              borderColor: themeColors.infoColor,
              backgroundColor: themeColors.infoColor
          }]
          }}/>}
      {metricType === 'revenue' && purchases?.length > 0 && <>
      
          <ChartToggle 
          type={'bar'}
          data={{
              heading: "Total Sales",
              labels: purchases?.length > 0 ? ["Digitals", "Royalties"] : [],
          datasets: [{
              label: "Sales",
              data: purchases?.length > 0 ? [digitalPurchases?.length > 0 ? digitalPurchases?.length : 0, royaltyPurchases?.length > 0 ? royaltyPurchases?.length : 0 ] : [],
                  borderColor: [themeColors.successColor, themeColors?.infoColor],
                  backgroundColor: [themeColors.successColor, themeColors?.infoColor]
              }]
                         
      }}/>
<ContentWrapper>
<ChipList alignment={"right"}>
          <Chip label={"All"} background={purchaseType === 'all' && themeColors.warningColor} onChipClick={() => setPurchaseType("all")}/>
          <Chip label={"Digital"} background={purchaseType === 'digital' && themeColors.successColor} onChipClick={() => setPurchaseType("digital")}/>
          <Chip label={"Royalty"} background={purchaseType === 'royalty' && themeColors.infoColor} onChipClick={() => setPurchaseType("royalty")}/>
      </ChipList>

      {//Purchases by date
      //Purchases grouped by currency
      }
     {purchaseType === 'all' && <>
     <ChartToggle data={{
              heading: "Total Sales",
              labels: purchases?.length > 0 ? Object.keys(groupInfoByDate(purchases, 'createdAt', filter)) : [],
          datasets: [{
              label: "Sales",
              data: purchases?.length > 0 ? Object.values(groupInfoByDate(purchases, 'createdAt', filter)).map(p => {
                  return p.length
              }) : [],
                  borderColor: themeColors.warningColor,
                  backgroundColor: themeColors.warningColor,
              }]
                         
      }}/>
      
      {getObjectLength(groupPurchasesByAmount(purchases)) > 0 && <ChartToggle type={'bar'} data={{
        heading: 'Revenue (Grouped By Currency)',
              labels: getObjectLength(groupPurchasesByAmount(purchases)) > 0 ? Object.keys(groupPurchasesByAmount(purchases)) : [],
              datasets: [{
                  label: "Total Earnings",
                  data: purchases?.length > 0 ? Object.values(groupPurchasesByAmount(purchases)).map(purchase => {
                      return parseFloat(purchase).toFixed(2)
                  }) : [],
                  backgroundColor: Object.keys(
                    groupPurchasesByAmount(purchases)
                  ).map((purchase) => {
                    return CURRENCIES_STATS_COLORS[purchase];
                  })
              }]
                         
      }}/>}
      </>}
       {purchases?.length > 0 && purchaseType !== 'all' && <>
        <ChartToggle data={{
              heading: "Total Sales",
              labels: purchaseType === 'digital' ? digitalPurchases?.length > 0 ? Object.keys(groupInfoByDate(digitalPurchases, 'createdAt', filter)) : [] : royaltyPurchases?.length > 0 ?  Object.keys(groupInfoByDate(royaltyPurchases, 'createdAt', filter)) : [] ,
          datasets: [{
              label: `${purchaseType[0].toUpperCase()}${purchaseType.substring(1)} Sales`,
              data: purchaseType === 'digital' ? digitalPurchases?.length > 0 ? Object.values(groupInfoByDate(digitalPurchases, 'createdAt', filter)).map(p => {
                return p?.length
              }) : [] : royaltyPurchases?.length > 0 ?  Object.values(groupInfoByDate(royaltyPurchases, 'createdAt', filter)).map(p => {
                return p?.length
              }) : [] ,
                  borderColor: purchaseType === 'digital' ? themeColors.successColor : themeColors.infoColor,
                  backgroundColor: purchaseType === 'digital' ? themeColors.successColor : themeColors.infoColor,
              }]
                         
      }}/>
      
      <ChartToggle type={'bar'} data={{
        heading: 'Revenue (Grouped By Currency)',
              labels: purchaseType === 'digital' ?  getObjectLength(groupPurchasesByAmount(digitalPurchases)) > 0 ? Object.keys(groupPurchasesByAmount(digitalPurchases)) : [] : getObjectLength(groupPurchasesByAmount(royaltyPurchases)) > 0 ? Object.keys(groupPurchasesByAmount(royaltyPurchases)) : [] ,
              datasets: [{
                  label: "Total Earnings",
                  data: purchaseType === 'digital' ? getObjectLength(groupPurchasesByAmount(digitalPurchases)) > 0 ? Object.values(groupPurchasesByAmount(digitalPurchases)).map(purchase => {
                      return parseFloat(purchase).toFixed(2)
                  }) : [] : getObjectLength(groupPurchasesByAmount(royaltyPurchases)) > 0 ? Object.values(groupPurchasesByAmount(royaltyPurchases)).map(purchase => {
                      return parseFloat(purchase).toFixed(2)
                  }) : [] ,
                  borderColor: Object.keys(
                    groupPurchasesByAmount(purchaseType === 'digital' ? digitalPurchases : royaltyPurchases)
                  ).map((purchase) => {
                    return CURRENCIES_STATS_COLORS[purchase];
                  }),
                  backgroundColor: Object.keys(
                    groupPurchasesByAmount(purchaseType === 'digital' ? digitalPurchases : royaltyPurchases)
                  ).map((purchase) => {
                    return CURRENCIES_STATS_COLORS[purchase];
                  })
              }]
                         
      }}/>
      </>
      
      }
      
</ContentWrapper>
      </>}
      {metricType === 'playlists' && playlists.length > 0 && <>
        <FormWrapper>
        <FormText>Playlists</FormText>
          <PlaylistTable playlists={playlists} heading={'Playlists'}/>
        </FormWrapper>
      </>}
      {((metricType === 'plays' && plays?.length === 0) || (metricType === 'likes' && likes?.length === 0) || (metricType === 'revenue' && purchases?.length === 0) || (metricType === 'playlists' && playlists?.length === 0)) && <>
        <ContentWrapper>
          <FormWrapper>
            <FormText>No Data Available</FormText>
          </FormWrapper>
        </ContentWrapper>
        </>}
      </ContentWrapper>
      </>}

  
  
  </>
)
}

export default SongStats