import React, { useState } from 'react'
import { Container, ContentWrapper, VideoBg } from './LoginElements'
import QRCodeForm from '../QRCodeForm'
import { TRANSACTION_OPTIONS } from '../../utils/Enum'
import { signInWithCustomToken } from 'firebase/auth'
import { auth } from '../Authorization/firebase'
import { useNavigate } from 'react-router-dom'
import Video from '../../videos/video.mp4'
import { useAuth } from '../../contexts/AuthContext'
import { ModalLoadingScreen } from '../LoadingScreen'




const Login = () => {
    const navigate = useNavigate()
    const {currentUser, unsubscribe, isLabel, isArtist, isPodcaster, } = useAuth()
    const [isLoaded, setLoaded] = useState(false)
    
    const onSignIn = (res) => {  
      setLoaded(true)
    signInWithCustomToken(auth, res ).then(async (user) => {
        
        return await Promise.all([isLabel(user?.user?.uid), isArtist(user?.user?.uid), isPodcaster(user?.user?.uid)])
        .then(async data =>{
          let p = {}
          if(data[0]){
            p = {
              ...p,
              label: data[0]
            }
          }
          if(data[1]){
            p = {
              ...p,
              artist: data[1]
            }
          }
          if(data[2]){
            p = {
              ...p,
              podcaster: data[2]
            }
          }
          
          navigate( `/home/${Object.keys(p)[0]}/${p[Object.keys(p)[0]][`${Object.keys(p)[0]}Id`]}`)
          !localStorage.getItem('currentProfile') && localStorage.setItem('currentProfile',Object.keys(p)[0] )
        })  
        
      }).catch(err => {
        console.log(err)
        navigate("/")
      })
}
  return (
    <>{!currentUser?.uid ? <Container>
    <VideoBg autoPlay loop muted src={Video}
          type='video/mp4' contentEditable={false} onContextMenu={(e) => {e.preventDefault()}}>
      </VideoBg>
      <ContentWrapper>
        {!isLoaded && <QRCodeForm heading={'Sign In'} type={TRANSACTION_OPTIONS.ARTIST_SIGN_IN} body={{
            instruction: "user is signing transaction to access artist portal"
        }} onSuccess={onSignIn} onFail={() => navigate("/")} cancelQr={() => navigate("/")}/>}
        {isLoaded && <ModalLoadingScreen/>}
        </ContentWrapper>
        </Container> : navigate('/home')}
      
    </>
  )
}

export default Login