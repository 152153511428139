import React, { useContext, useState, useEffect } from "react";
import { auth } from "../components/Authorization/firebase";
import {signOut, onAuthStateChanged } from "@firebase/auth";
import { useNavigate } from "react-router";
import LoadingScreen from "../components/LoadingScreen/index";
import { getUserProfile, hashEmail } from "../components/GravatarAPI/index.js";
import { useUser } from "../hooks/useUser";
import styled from "styled-components";
import { getObjectLength, hexToStr, strToHex } from "../utils";
import hexToRgbA from "../components/HexToRGBA";
import { Theme } from "../components/WebsiteThemeElements";
import { DEFAULT_CHANNEL, PAYOUT_TYPE } from "../utils/Enum";
export const AuthContext = React.createContext();


const axios = require('../components/Authorization/client');





export function useAuth() {
  return useContext(AuthContext);
}
export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);
  const [accData, setAccData] = useState()
  const [gravatarInfo, setGravatarInfo] = useState()
  const [modalOpen, setOpen] = useState(false)
  const [nfTokens, setNFTokens] = useState([])
  const [userLikes, setUserLikes] = useState([])
  const [userWatchlist, setUserWatchlist] = useState([])
  const [userPurchases, setUserPurchases] = useState([])
  const [profiles, setProfiles] = useState({})
  const [currentProfile, setCurrentProfile] = useState()
  const [userSubscription, setUserSubscription] = useState(false)
  const navigate = useNavigate()

 async function signup(email, address, photoURL) {
   return axios.post("/api/signup", 
    {
      photoURL:photoURL,
      email: email,
      address: address
    }).then(resp => {
     return resp.data
    }).catch(err => {
      //console.log('error', err.response.data)
      return err.response.data
    })

  }

  async function login(email, address, photoURL) {
      return await axios.post('/api/artistLogin', 
      {
        email: email,
        address: address,
        photoURL: photoURL
      }).then(resp => {
        console.log(resp.data)
          return resp.data
      }).catch(err =>
        {
          return err.response.data
        })
  }


  function logout() {
        signOut(auth).then(val => {
        localStorage.clear()
        setCurrentProfile()
        setProfiles()
        setCurrentUser()
       navigate('/')
        })
       
  }

async function updateUser(email, eligibility)
{
  return axios.post('/api/updateUser',
  {
    email:email,
    isEligible: eligibility
  }).then(res => {
    return res.data
  }).catch(err => {
    return false
  })
}

const getUserSubscription = async(id) => {
  await axios.get(`/api/user/${id}/checkUserSubscription`).then(subscription => {
    if(subscription?.data && !subscription?.data?.error){
      setUserSubscription(subscription.data)
      setCurrentUser(prev => {
        return {...prev,
        subscription: subscription.data
      }
      })
      return subscription.data
    }
    setUserSubscription(false)
    return false
  }).catch(err => {
    setUserSubscription(false)
    return false
  })
}

 const getGravatarInfo = async(email) => {
  //setLoading(true)
  return await getUserProfile(email).then(result => {
   // //console.log('gravatar', result)
    if(result.displayName !== 'undefined' && result !== false)
    {
      
      setGravatarInfo(result)
      (true)
      return true
    }else{
      setGravatarInfo(false)
      return false
    }
    
  }).catch(err => {
    //console.log('gT', false)
    setGravatarInfo(false)
    //setLoading(false)

    return false
  })
}


const isLoggedIn = () => {
  if(currentUser?.uid ){
    return true
  }
  return false
}


const isArtist = async(uid) => {
    return await axios.get(`/api/${uid}/getArtistByUid`).then((artist) => {
      if(artist?.data){
        setCurrentUser(prev =>{
          return{...prev, 
            isArtist: true}
        })
        return artist.data
      }else{
        setCurrentUser(prev =>{
          return{...prev, 
            isArtist: false}
        })
        return false
      }
    }).catch(err => {
      //setArtist(false)
      return false
    })

}

const isLabel = async(uid) => {
    return await axios.get(`/api/${uid}/getLabelByUid`).then((label) => {
      if(label?.data){
        setCurrentUser(prev =>{
          return{...prev, 
            isLabel: true}
        })
        return label.data
      }else{
        setCurrentUser(prev =>{
          return{...prev, 
            isLabel: false}
        })
        return false
      }
    }).catch(err => {
      //setArtist(false)
      return false
    })
}

const isPodcaster = async(uid) => {
    return await axios.get(`/api/${uid}/getPodcasterByUid`).then((podcaster) => {
      if(podcaster?.data){
        setCurrentUser(prev =>{
          return{...prev, 
            isPodcaster: true}
        })
        return podcaster.data
      }else{
        setCurrentUser(prev =>{
          return{...prev, 
            isPodcaster: false}
        })
        return false
      }
    }).catch(err => {
      //setArtist(false)
      return false
    })

}



 async function getUsersStats() {
    return await axios.get('/api/usersStats').then(res => {
      //console.log('stats data',res.data.numUsers)
      return res.data.numUsers}
      ).catch(err => {
        return false
      })
  
  }

  async function setPushNotifications(pushNotification){

    setCurrentUser(prev => {
      return {
        ...prev,
        pushNotifications: pushNotification
      }
    })
    
  }


  const getUserAddressInfo = async(uid) => {
    return await axios.get(`/api/userAddressInfo/${uid}`).then(accInfo => {
      if(accInfo.data && !accInfo?.data?.error){
        setAccData(accInfo?.data?.accInfo?.account)
        setCurrentUser(prev => {
          return {
            ...prev,
            ...accInfo?.data?.accInfo?.account
          }
        })
        return accInfo?.data?.accInfo?.account
      }
      return false
    }).catch(err => {
      console.error(err)
    })
  }

 /*  const makeMicropayment = async (type, id) => {
    let channel = hexToStr(document.querySelector(
      'meta[name="swirl"]'
    ).getAttribute("content")).split(".")
    let url = type && id ? `${strToHex(`${currentUser?.uid}.${id}.${type}`)}` : `${strToHex(`${currentUser?.uid}.${channel[1]}.${hexToStr(channel[0])}`)}`
    if (currentUser?.uid && channel.join(".") !== DEFAULT_CHANNEL) {
      return await axios
        .get(`/api/swirl/${url}`)
        .then((result) => {
          if (result) {
          }
          return;
        })
        .catch((err) => {
          console.error(err);
          return;
        })
    }
  }; */

  const unsubscribe = async () => onAuthStateChanged(auth, async (user) => {
    if (user) {
      setCurrentUser(user);
      getUserAddressInfo(user?.uid)
      //Setup Graravatr Information
      return await Promise.all([isLabel(user?.uid), isArtist(user?.uid), isPodcaster(user?.uid)])
      .then(async data =>{
        let p = {}
        if(data[0]){
          p = {
            ...p,
            label: data[0]
          }
        }
        if(data[1]){
          p = {
            ...p,
            artist: data[1]
          }
        }
        if(data[2]){
          p = {
            ...p,
            podcaster: data[2]
          }
        }
        setProfiles(p)
        !localStorage.getItem('currentProfile') && localStorage.setItem('currentProfile', Object.keys(p)[0])
        setCurrentProfile(localStorage.getItem('currentProfile') ? localStorage.getItem('currentProfile') : Object.keys(p)[0])
        setCurrentUser(prev => {
          return {
            ...prev, 
            currentProfile: Object.keys(p)[0],
            profiles: p
          }
        })

        getGravatarInfo(hashEmail(user?.email)).then(isFound => {
          if(isFound){
            updateUser(user?.email,true).then( res => {
              if(res){
               setCurrentUser((prev) => {
                    return {...prev, ...isFound, ...res}})
              
  }          }).catch(err => {
              //console.log('Done :(', err)
            })
          }
          
          setLoading(false)
        }).catch(err => {
          setLoading(false)
        })
      }).catch(err => {
        logout()
      })
     
      //console.log('g', gravatarInfo)
    } else {
      setCurrentUser();
        if (!localStorage.getItem('user') && localStorage.getItem('accInfo'))
        {
          setGravatarInfo(false)
          localStorage.removeItem('accInfo') 
          setLoading(false)
          return
    }
      setLoading(false)
      return
    }
    
    ;});

useEffect(() => {
  unsubscribe()
  return () => {}
  

  
}, []);








  const value = {
    currentUser,
    signup,
    login,
    logout,
    loading,
    nfTokens,
    accData,
    updateUser,
    getGravatarInfo,
    gravatarInfo,
    modalOpen,
    setOpen,
    currentProfile,
    setCurrentProfile,
    unsubscribe,
    setPushNotifications,
    isArtist,
    isPodcaster,
    isLabel,
    profiles,
    isLoggedIn,
    getUserAddressInfo,
  };

  return (
    <AuthContext.Provider value={value}>
      <div style={{
        height: '100%',
        background: `linear-gradient(180deg, ${hexToRgbA(Theme({}).secondaryBg)} 0%, ${hexToRgbA(Theme({}).mainColor)}  100%)`
      }}>
      {!loading && 
      children 
      }
      
      {loading && (
        

          <LoadingScreen mainColor={gravatarInfo?.profileBackground?.color ? gravatarInfo?.profileBackground?.color : Theme({}).mainColor} />
       
      )}
      </div>
    </AuthContext.Provider>
  );
}
