import styled from "styled-components";
import hexToRgbA, { hexToRgba } from "../HexToRGBA";
import { MEDIA_SCREEN } from "../../utils/Enum";

export const ServicesContainer = styled.div`
  height: auto;
  display: flex;
  background: ${props => props?.theme.secondaryBg};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const ServicesWrapper = styled.div`
  max-width: 1000px;
  height: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(
    ${(props) => (props.numGrid ? props.numGrid : 3)},
    1fr
  );
  align-items: center;
  justify-content: center;
  grid-gap: 16px;
  padding: 50px 50px;

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
  @media screen and (max-width: 375px) {
    grid-template-columns: 1fr;
  }
`;

export const ServicesCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  min-height: 340px;
  max-height: 340px;
  width: auto;
  overflow: hidden;
  max-width: 400px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.9);
  transition: all 0.2s ease-in-out;
  background: ${(props) => hexToRgba(props.theme.secondaryBg, 0.1) };
  &:hover {
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.9);
    background: ${(props) => hexToRgba(props.theme.accentColor, 0.3)};    
  }
`;

export const ServicesIcon = styled.div`
top: 0;
height: 15%;
padding: 3%;
width: 100%;
justify-content: center;
align-items: center;
align-content: center;
margin-bottom: 2%;
background: ${(props) =>
  hexToRgba(props.theme.mainColor,0.3)};
color: ${(props) => props.theme.mainBg};
${ServicesCard}:hover & {
    color: ${(props) => props.theme.mainColor};
    background: ${(props) =>
        `linear-gradient(90deg, ${hexToRgba(props.color1, 0.7)} 25%, ${hexToRgba(
          props.color2,
          0.7
        )} 35%, ${hexToRgba(props.color3, 0.7)} 45%, ${hexToRgba(
          props.theme.secondaryBg, 0.7
        )} 55%)`};
}
`;
export const ServicesH1 = styled.h1`
  font-size: 2.5rem;
  color: ${(props) => props?.light ? props.theme.secondaryBg : props.theme.mainBg };
  margin-bottom: 30px;
  margin-top: 50px;
  text-align: center;
  @media screen and (max-width: ${MEDIA_SCREEN.TABLETS}px) {
    font-size: 2rem;
    
  }
`;

export const ServicesH2 = styled.h2`
  font-size: 1.2rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 2px;
  color: ${(props) => props.theme.mainBg};
`;

export const ServicesP = styled.p`
  font-size: 1rem;
  text-align: center;
  padding: 3%;
  color: ${(props) => props.theme.mainBg};

  ${ServicesCard}:hover & {
  }
`;
