import React, { useEffect, useState } from 'react'
import ContentWrapper from '../../../ContentWrapper'
import { ModalLoadingScreen } from '../../../LoadingScreen'
import useRecordLabel from '../../../../hooks/useRecordLabel'
import { FormText, FormWrapper } from '../../../CustomForm/CustomFormElements'
import { Slider } from '../../../Slider'
import { SLIDER_TYPES } from '../../../../utils/Enum'
import { CustomButton1 } from '../../../ButtonElement'
import { useModal } from '../../../Modal/ModalContext'
import LabelArtistAdder from '../../../LabelArtistAdder'

const ArtistsContent = ({labelId}) => {
    const {getRecordLabelArtists} = useRecordLabel({id: labelId})
    const {modalContent, openModal, closeModal} = useModal()
    const [artists,setArtists] = useState([])
    const [isLoaded, setLoaded] = useState(false)
    const [currentArtist, setCurrentArtist] = useState()


    useEffect(() => {
        setLoaded(false)
      getRecordLabelArtists(labelId).then((a) => {
        if(a?.artists?.length > 0){
            setArtists(a?.artists)
        }
        setLoaded(true)
      }).catch(err => {
        console.error(err)
        setLoaded(true)
      })
    
      return () => {
        
      }
    }, [labelId])
    

  return (
    <>
    <ContentWrapper>
        {!isLoaded && <ModalLoadingScreen text={'Loading Stats...'}/>}
        {isLoaded && <>
        {artists?.length > 0 && 
        <FormWrapper>
        <Slider type={SLIDER_TYPES.ARTIST} slides={artists} id={'artists'} onSlideClick={(i) => setCurrentArtist(artists[i]?.artistId)}/>
        </FormWrapper>
        }
        {artists?.length === 0 && <>
            <FormWrapper>
                <FormText>No Artists Available</FormText>
                <CustomButton1 onClick={() => {
                  openModal();
                  modalContent(
                    <>
                      <LabelArtistAdder />
                    </>
                  );
                }}>Add/Claim Artists</CustomButton1>
            </FormWrapper>
        </>}
        
        </>}
    </ContentWrapper>
    
    </>
  )
}

export default ArtistsContent