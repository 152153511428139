import React, { useState } from 'react'
import { getObjectLength } from '../../../utils'
import { ALERT_TYPES, ARTIST_FORM_INPUT_TYPES, NFT_STORAGE_TYPES, TRANSACTION_OPTIONS } from '../../../utils/Enum'
import { emailRegex, IlpRegex } from '../../../utils/Regex'
import { useAlert } from '../../Alert/AlertContext'
import { Button, CustomButton } from '../../ButtonElement'
import { ButtonWrapper } from '../../ContentList/ContentListElements'
import ContentWrapper from '../../ContentWrapper'
import { FormButton, FormInput1, FormLabel, FormText, FormWrapper } from '../../CustomForm/CustomFormElements'
import { Header } from '../../Header'
import { HoverOption } from '../../MenuOptions/MenuOptionsElements'
import { useModal } from '../../Modal/ModalContext'
import { SongDetailsText } from '../../MusicUploadsForm/MusicUploadsFormElements'
import { SmallText } from '../../NFTCollectionCreator/NFTCollectionCreatorElements'
import OptionModal from '../../OptionModal'
import PageIndexer from '../../PageIndexer'
import QRCodeForm from '../../QRCodeForm'
import ScrollToTop from '../../ScrollToTop'
import { useAuth } from '../../../contexts/AuthContext'
import { useEffect } from 'react'
import { ArtistContentHeading, ErrorText, SettingsWrapper } from '../../ArtistProfile/ArtistContent/ArtistContentElements'
import { TextRoute } from '../../SignUp/SignUpElements'
import Checkbox from '../../Checkbox'

const RegistrationForm = ({artist, discography}) => {
    const {currentUser, profiles} = useAuth()
    const {openModal, closeModal, modalContent} = useModal()
    const {addAlert} = useAlert()
    const {goTo} = ScrollToTop()
    const [artistName, setName] = useState(artist?.profile?.name ? artist?.profile?.name : '')
    const [artistId, setArtistId] = useState(artist?.artistId ? artist?.artistId : undefined)
    const [fullName, setFullName] = useState(artist?.profile?.fullName ? artist?.profile?.fullName : '')
    const [address, setAddress] = useState(artist?.profile?.uid ? artist?.profile?.uid : localStorage.getItem('address') ? localStorage.getItem('address') : currentUser?.uid ? currentUser?.uid :'')
    const [accInfo, setAccInfo] = useState()
    const [paymentPointer, setPaymentPointer] = useState(artist?.profile?.paymentPointer ? artist?.profile?.paymentPointer : '')
    const [email, setEmail] = useState(artist?.profile?.email ? artist?.profile?.email : '')
    const [nftStorageApiKey, setNftStorageApiKey] = useState(artist?.bio ? artist?.bio : '')
    const [bio, setBio] = useState(artist?.profile?.bio ? artist?.profile?.bio : '')
    const [errors, setErrors] = useState({})
    const [currentPage, setCurrentPage] = useState(1)
    const [storageType, setStorageType] = useState( artist?.ipfsStorage?.type ? artist?.ipfsStorage?.type : 'default')
    const [storageApiKeys, setApiKeys] = useState(artist?.ipfsStorage?.apiKeys ? artist?.ipfsStorage?.apiKeys :{})

    useEffect(() => {
      if((!localStorage.getItem('address') && !currentUser?.uid) || profiles?.artist?.artistId){
        goTo('/')
      }
    
      return () => {
        
      }
    }, [])
    

    const handleInput = (e) => {
        switch (e.target.name) {
            case ARTIST_FORM_INPUT_TYPES.NAME:
                setName(e.target.value)
                
                break;
            case ARTIST_FORM_INPUT_TYPES.FULL_NAME:
                setFullName(e.target.value)

                break;
            case ARTIST_FORM_INPUT_TYPES.EMAIL:
                setEmail(e.target.value)

                break;
            case ARTIST_FORM_INPUT_TYPES.PAYMENT_POINTER:
                setPaymentPointer(e.target.value)
                break;
            case ARTIST_FORM_INPUT_TYPES.NFT_STORAGE_API_KEY:
                setNftStorageApiKey(e.target.value)
                break;
        
            default:
                break;
        }
    }

    const onAddressSuccess = (accInfo) => {
        //console.log(accInfo)
        setAddress(accInfo?.account?.Account)
        closeModal()
        modalContent()
    } 

    const onAddressFail = () => {
        closeModal()
        modalContent()
    }

    const changeAddress = () => {
        openModal()
        modalContent(<QRCodeForm 
            heading={'Artist Address Registration'}
            type={TRANSACTION_OPTIONS.CHANGE_ARTIST_LABEL_PODCASTER_ADDRESS}
            body={{
                memo: address ? 'Artist is signing transaction to change login wallet address' : 'Artist is signing transaction to add login wallet address' ,
                instruction: address ? 'Artist is signing transaction to change login wallet address' : 'Artist is signing transaction to add login wallet address' ,
            }}
            onSuccess={onAddressSuccess}
            onFail={onAddressFail}
            cancelQr={() => {
                closeModal()
                modalContent()
            }}
            />)
    }

    const handleApiKeyInput = (e) => {
        switch (e.target.name) {
          case 'key':
            switch (storageType) {
              case NFT_STORAGE_TYPES.FILEBASE:
                let filebase = storageApiKeys?.filebase
                if(e.target.value?.trim()?.length > 0){
                setApiKeys(prev => {
                  return {
                    ...prev,
                    filebase: {
                      ...prev?.filebase,
                      key: e.target.value
                    }
                  }
                })
              }else{
                delete filebase?.key
                setApiKeys(prev => {
                  return {
                    ...prev,
                    filebase
                  }
                })
              }
                break;
              case NFT_STORAGE_TYPES.INTERNET_ARCHIVE:
                let internetArchive = storageApiKeys?.internetArchive
                if(e.target.value?.trim()?.length > 0){
                setApiKeys(prev => {
                  return {
                    ...prev,
                    internetArchive: {
                      ...prev?.internetArchive,
                      key: e.target.value
                    }
                  }
                })}else{
                  delete internetArchive.key
                  setApiKeys(prev => {
                    return {
                      ...prev,
                      internetArchive
                    }
                  })
                }
                break;
              case NFT_STORAGE_TYPES.LIGHTHOUSE:
                let lighthouse = storageApiKeys?.lighthouse
                if(e.target.value?.trim()?.length > 0){
                setApiKeys(prev => {
                  return {
                    ...prev,
                    lighthouse: {
                      ...prev?.lighthouse,
                      key: e.target.value
                    }
                  }
                })}else{
                  delete lighthouse.key
                  setApiKeys(prev => {
                    return {
                      ...prev,
                      lighthouse
                    }
                  })
                }
                break;
              case NFT_STORAGE_TYPES.PINATA:
                let pinata = storageApiKeys?.pinata
                if(e.target.value?.trim()?.length > 0){
                setApiKeys(prev => {
                  return {
                    ...prev,
                    pinata: {
                      ...prev?.pinata,
                      key: e.target.value
                    }
                  }
                })}else{
                  delete pinata.key
                  setApiKeys(prev => {
                    return {
                      ...prev,
                      pinata
                    }
                  })
                }
                break;
              case NFT_STORAGE_TYPES.WEB3_STORAGE:
                let web3Storage = storageApiKeys?.web3Storage
                if(e.target.value?.trim()?.length > 0){
                setApiKeys(prev => {
                  return {
                    ...prev,
                    web3Storage: {
                      ...prev?.web3Storage,
                      key: e.target.value
                    }
                  }
                })}else{
                  delete web3Storage.key
                  setApiKeys(prev => {
                    return {
                      ...prev,
                      web3Storage
                    }
                  })
                }
                break;
            
              default:
                break;
            }
            
            break;
          case 'secret':
            switch (storageType) {
              case NFT_STORAGE_TYPES.FILEBASE:
                let filebase = storageApiKeys?.filebase
                if(e.target.value?.trim()?.length > 0){
                setApiKeys(prev => {
                  return {
                    ...prev,
                    filebase: {
                      ...prev?.filebase,
                      secret: e.target.value
                    }
                  }
                })}else{
                  delete filebase.secret
                  setApiKeys(prev => {
                    return {
                      ...prev,
                      filebase
                    }
                  })
                }
                break;
              case NFT_STORAGE_TYPES.INTERNET_ARCHIVE:
                let internetArchive = storageApiKeys?.internetArchive
                if(e.target.value?.trim()?.length > 0){
                setApiKeys(prev => {
                  return {
                    ...prev,
                    internetArchive: {
                      ...prev?.internetArchive,
                      secret: e.target.value
                    }
                  }
                })}else{
                  delete internetArchive.secret
                  setApiKeys(prev => {
                    return {
                      ...prev,
                      internetArchive
                    }
                  })
                }
                break;
              case NFT_STORAGE_TYPES.LIGHTHOUSE:
                let lighthouse = storageApiKeys?.lighthouse
                if(e.target.value?.trim()?.length > 0){
                setApiKeys(prev => {
                  return {
                    ...prev,
                    lighthouse: {
                      ...prev?.lighthouse,
                      secret: e.target.value
                    }
                  }
                })}else{
                  delete lighthouse.secret
                  setApiKeys(prev => {
                    return {
                      ...prev,
                      lighthouse
                    }
                  })
                }
                break;
              case NFT_STORAGE_TYPES.PINATA:
                let pinata = storageApiKeys?.pinata
                if(e.target.value?.trim()?.length > 0){
                setApiKeys(prev => {
                  return {
                    ...prev,
                    pinata: {
                      ...prev?.pinata,
                      secret: e.target.value
                    }
                  }
                })}else{
                  delete pinata.secret
                  setApiKeys(prev => {
                    return {
                      ...prev,
                      pinata
                    }
                  })
                }
                break;
              case NFT_STORAGE_TYPES.WEB3_STORAGE:
                let web3Storage = storageApiKeys?.web3Storage
                if(e.target.value?.trim()?.length > 0){
                setApiKeys(prev => {
                  return {
                    ...prev,
                    web3Storage: {
                      ...prev?.web3Storage,
                      secret: e.target.value
                    }
                  }
                })}else{
                  delete web3Storage.secret
                  setApiKeys(prev => {
                    return {
                      ...prev,
                      web3Storage
                    }
                  })
                }
                break;
            
              default:
                break;
            }
            break;
          case 'jwt':
            setApiKeys(prev => {
              return {
                ...prev,
                pinata: {
                  ...prev?.pinata,
                  jwt: e.target.value
                }
              }
            })
            break;
          case 'bucketName':
            setApiKeys(prev => {
              return {
                ...prev,
                filebase: {
                  ...prev?.filebase,
                  bucketName: e.target.value
                }
              }
            })
            break;
        
          default:
            break;
        }
      
      }

    const validateStorageApiKeys = () => {
        let errors = {}
        
            if(storageType === NFT_STORAGE_TYPES.FILEBASE){
              if(getObjectLength(storageApiKeys?.filebase) < 3 ){
                errors = {
                  ...errors,
                  filebase: 'Missing API Data. Please fill in all the information'
                }
              }
            }
            if(storageType === NFT_STORAGE_TYPES.INTERNET_ARCHIVE){
              if(getObjectLength(storageApiKeys?.internetArchive) < 2 ){
                errors = {
                  ...errors,
                  internetArchive: 'Missing API Data. Please fill in all the information'
                }
              }
            }
            if(storageType === NFT_STORAGE_TYPES.LIGHTHOUSE){
              if(getObjectLength(storageApiKeys?.lighthouse) < 1 ){
                errors = {
                  ...errors,
                  lighthouse: 'Missing API Data. Please fill in all the information'
                }
              }
            }
            if(storageType === NFT_STORAGE_TYPES.PINATA){
              if(getObjectLength(storageApiKeys?.pinata) < 3 ){
                errors = {
                  ...errors,
                  pinata: 'Missing API Data. Please fill in all the information'
                }
              }
            }
    
            if(getObjectLength(storageApiKeys?.filebase) > 0 && getObjectLength(storageApiKeys?.filebase) < 3 ){
              errors = {
                ...errors,
                filebase: 'Missing API Data. Fill in all the information, or clear it'
              }
            }
    
            if(getObjectLength(storageApiKeys?.internetArchive) > 0 && getObjectLength(storageApiKeys?.internetArchive) < 2 ){
              errors = {
                ...errors,
                internetArchive: 'Missing API Data. Fill in all the information, or clear it'
              }
            }
    
            if(getObjectLength(storageApiKeys?.pinata) > 0 && getObjectLength(storageApiKeys?.pinata) < 3 ){
              errors = {
                ...errors,
                pinata: 'Missing API Data. Fill in all the information, or clear it'
              }
            }
          console.log(errors)
          return errors
        }

    const verifyInfo = () => {
        let err = {}
        setErrors()
        if(artistName?.trim()?.length === 0){
            err = {
                ...err,
                name: 'Artist name cannot be empty'
            }
        }
        if((email?.trim()?.length === 0) || !emailRegex.test(email)){
            err = {
                ...err,
                email: 'Please enter a valid email address'
            }
        }
        if(paymentPointer?.trim().length > 0 && !paymentPointer.includes('ilp.')){
            err = {
                ...err,
                paymentPointer: 'Payment Pointer is invalid'
            }
        }
        if((address?.trim()?.length === 0)){
            err = {
                ...err,
                walletAddress: 'Please add a wallet address'
            }
        }

        let storageErrors = validateStorageApiKeys()
    if(getObjectLength(storageErrors) > 0){
      err = {
        ...err,
        storageApiKeys: storageErrors,
      }
    }
        setErrors(err)
        if(getObjectLength(err) === 0){
            return true
        }
        if(getObjectLength(err) > 0){
            return false
        }
    }

    const onRegistrationFail = () => {
        closeModal()
        modalContent()
    }


    const onRegistrationSuccess = () => {
        currentUser?.uid ? goTo('/home') : goTo('/')
        localStorage.removeItem("address")
        localStorage.removeItem("regType")
        modalContent(<>
            <OptionModal heading={artist?.artistId  ? 'Artist Account Claim':'Artist Account Registration'}>
                <FormText>Your artist {artist?.artistId ? `claim` : 'registration'} is being processed. You will receive a notification to your Xaman wallet shortly. </FormText>
                <HoverOption onClick={() => {
                    closeModal()
                    modalContent()
                }}>Ok</HoverOption>
            </OptionModal>
        </>)
        
    }



    const submitCreate = () => {
       if(verifyInfo()){
        openModal()
        modalContent(<QRCodeForm 
            heading={artist?.artistId  ? 'Artist Account Claim':'Artist Account Registration'}
            type={TRANSACTION_OPTIONS.ARTIST_REGISTRATION}
            body={{
                sender: address,
                profile: {
                    artistId: artistId,
                    name: artistName,
                    uid: address,
                    email: email,
                    paymentPointer: paymentPointer,
                    fullName: fullName,
                    bio: bio.trim().length > 0 ? bio : undefined,
                    discography: discography,
                    ipfsStorage: { type: storageType,
                        apiKeys: storageApiKeys
                      },

                },
                memo: artist?.artistId  ? 'User is signing transaction to claim account for ' + artistName : 'User is signing transaction to register account for ' + artistName ,
                instruction: artist?.artistId  ? 'User is signing transaction to claim account for ' + artistName : 'User is signing transaction to register account for ' + artistName ,
            }}
            onSuccess={onRegistrationSuccess}
            onFail={onRegistrationFail}
            cancelQr={() => {
                closeModal()
                modalContent()
            }}
            />)
       }else{
        addAlert({
            title: "Artist Registration",
            type: ALERT_TYPES.WARNING,
            message: 'Please fill in form',
            secs: 10,
          });
       }
    }

  return (
    <>
    <Header title={'Artist Registration'}/>
    <ContentWrapper>
      { currentPage === 1 && <>
      
      <FormWrapper>
            <FormLabel>Artist Name</FormLabel>
            <FormInput1 name={ARTIST_FORM_INPUT_TYPES.NAME} value={artistName} onChange={handleInput} placeholder={'Artist Name'}/>
            <SmallText>Artists Stage Name. This will be used for displaying in your profile and music</SmallText>
            {errors?.name && <SongDetailsText error >{errors?.name}</SongDetailsText>}
        </FormWrapper>
        <FormWrapper>
        <FormLabel>Full Name (Optional)</FormLabel>
            <FormInput1 name={ARTIST_FORM_INPUT_TYPES.FULL_NAME} value={fullName} onChange={handleInput} placeholder={'Full Name'}/>
            <SmallText>Artists full (real) name. This will be used for any music credits</SmallText>
        </FormWrapper>
        <FormWrapper>
        <FormLabel>Email Address</FormLabel>
            <FormInput1 name={ARTIST_FORM_INPUT_TYPES.EMAIL} type={"email"} value={email} onChange={handleInput} placeholder={'Email Address'}/>
            <SmallText>Email Address to cantact artist. Gravatar account information from this address will be used within the artist profile </SmallText>
            {errors?.email && <SongDetailsText error>{errors?.email}</SongDetailsText>}
        </FormWrapper>
               
        </>}

        {currentPage === 2 && 
            <> <FormWrapper>
            <FormLabel>Wallet Address</FormLabel>
                <FormInput1 name={ARTIST_FORM_INPUT_TYPES.ADDRESS} readOnly value={address} placeholder={'Wallet Address'}/>
                {!currentUser?.uid && <Button onClick={() => changeAddress()}>{address ? `Change Address` : `Sign In`}</Button>}
                <SmallText>A wallet address that will be used to access the artist page . This will be used for receiving payments and signing artist related transactions</SmallText>

                {errors?.walletAddress && <SongDetailsText error>{errors?.walletAddress}</SongDetailsText>}
            </FormWrapper>
            <FormWrapper>
            <FormLabel>Payment Pointer (Optional)</FormLabel>
                <FormInput1 name={ARTIST_FORM_INPUT_TYPES.PAYMENT_POINTER} value={paymentPointer} onChange={handleInput} placeholder={'Payment Pointer'}/>
                <SmallText>This will be used for receiving micropayments and tips using the Interledger Protocol</SmallText>
                {errors?.paymentPointer && <SongDetailsText error>{errors?.paymentPointer}</SongDetailsText>}
            </FormWrapper>
            <FormWrapper>
  <ArtistContentHeading>IPFS (NFT Minting Settings)</ArtistContentHeading>
  <FormText>Select supported storage option for music files and metadata. Sonar Muse will automatically upload audio files for NFT collections to selected option.</FormText>
  <FormText>Note: All NFT metadata files will be uploaded to IPFS, regardless of the selected option.</FormText>
  <FormText>By Default, Sonar Muse uploads files to it's IPFS storage bucket</FormText>
  <FormWrapper>
  <SettingsWrapper>
          <div style={{justifyContent: 'flex-start', justifySelf: 'flex-start'}}>
          <Checkbox onClick={() => setStorageType('default')} label={'Sonar Muse Storage Bucket (Default)'} isChecked={storageType === 'default'}/>
          <Checkbox onClick={() => setStorageType(NFT_STORAGE_TYPES.FILEBASE)} label={'Filebase'} isChecked={storageType === NFT_STORAGE_TYPES.FILEBASE}/>
          <Checkbox onClick={() => setStorageType(NFT_STORAGE_TYPES.INTERNET_ARCHIVE)} label={'Internet Archive'} isChecked={storageType === NFT_STORAGE_TYPES.INTERNET_ARCHIVE}/>
          <Checkbox onClick={() => setStorageType(NFT_STORAGE_TYPES.LIGHTHOUSE)} label={'Lighthouse'} isChecked={storageType === NFT_STORAGE_TYPES.LIGHTHOUSE}/>
          <Checkbox onClick={() => setStorageType(NFT_STORAGE_TYPES.PINATA)} label={'Pinata'} isChecked={storageType === NFT_STORAGE_TYPES.PINATA}/>
          {/* <Checkbox onClick={() => setStorageType(NFT_STORAGE_TYPES.WEB3_STORAGE)} label={'Web3 Storage (Storacha)'} isChecked={storageType === NFT_STORAGE_TYPES.WEB3_STORAGE}/> */}

          </div>
  </SettingsWrapper>
     {(storageType === NFT_STORAGE_TYPES.FILEBASE || errors?.storageApiKeys?.filebase) && <FormWrapper border>
     <FormLabel>Filebase</FormLabel>
     <FormText>To learn more about and create a Filebase account <TextRoute  target="_blank" rel="noopener noreferrer" to={'https://filebase.com/'}>click here</TextRoute></FormText>
    <FormWrapper>
      <FormLabel>Bucket Name</FormLabel>
      <FormInput1 placeholder="Bucket Name" name={'bucketName'} onChange={handleApiKeyInput} value={storageApiKeys?.filebase?.bucketName}/>
    </FormWrapper>
    <FormWrapper>
      <FormLabel>API KEY</FormLabel>
      <FormInput1 placeholder={'API KEY'} name={'key'} onChange={handleApiKeyInput} value={storageApiKeys?.filebase?.key}/>
    </FormWrapper>
    <FormWrapper>
      <FormLabel>API SECRET</FormLabel>
      <FormInput1 placeholder={"API SECRET"} name={'secret'} onChange={handleApiKeyInput} value={storageApiKeys?.filebase?.secret}/>
    </FormWrapper>
    {errors?.storageApiKeys?.filebase && <ErrorText>{errors?.storageApiKeys?.filebase}</ErrorText>}
  </FormWrapper>}
     {(storageType === NFT_STORAGE_TYPES.INTERNET_ARCHIVE || errors?.storageApiKeys?.internetArchive) && <FormWrapper border>
     <FormLabel>Internet Archive</FormLabel>
     <FormText>To learn more about and create an Internet Archive account <TextRoute  target="_blank" rel="noopener noreferrer" to={'https://archive.org/'}>click here</TextRoute></FormText>
    <FormWrapper>
      <FormLabel>API KEY</FormLabel>
      <FormInput1 placeholder={'API KEY'} name={'key'} onChange={handleApiKeyInput} value={storageApiKeys?.internetArchive?.key}/>
    </FormWrapper>
    <FormWrapper>
      <FormLabel>API SECRET</FormLabel>
      <FormInput1  placeholder={"API SECRET"} name={'secret'} onChange={handleApiKeyInput} value={storageApiKeys?.internetArchive?.secret}/>
    </FormWrapper>
    {errors?.storageApiKeys?.internetArchive && <ErrorText>{errors?.storageApiKeys?.internetArchive}</ErrorText>}
  </FormWrapper>}
     {(storageType === NFT_STORAGE_TYPES.LIGHTHOUSE || errors?.storageApiKeys?.lighthouse) && <FormWrapper border>
     <FormLabel>Lighthouse</FormLabel>
     <FormText>To learn more about and create a Lighthouse account <TextRoute  target="_blank" rel="noopener noreferrer" to={'https://files.lighthouse.storage/'}>click here</TextRoute></FormText>
    <FormWrapper>
      <FormLabel>API KEY</FormLabel>
      <FormInput1 placeholder={'API KEY'} name={'key'} onChange={handleApiKeyInput} value={storageApiKeys?.lighthouse?.key}/>
    </FormWrapper>
    {errors?.storageApiKeys?.lighthouse && <ErrorText>{errors?.storageApiKeys?.lighthouse}</ErrorText>}
  </FormWrapper>}
     {(storageType === NFT_STORAGE_TYPES.PINATA || errors?.storageApiKeys?.pinata) && <FormWrapper border>
     <FormLabel>Pinata</FormLabel>
     <FormText>To learn more about and create a Pinata account <TextRoute  target="_blank" rel="noopener noreferrer" to={'https://www.pinata.cloud/'}>click here</TextRoute></FormText>
    <FormWrapper>
      <FormLabel>API KEY</FormLabel>
      <FormInput1  placeholder={'API KEY'} name={'key'} onChange={handleApiKeyInput} value={storageApiKeys?.pinata?.key}/>
    </FormWrapper>
    <FormWrapper>
      <FormLabel>API SECRET</FormLabel>
      <FormInput1  placeholder={"API SECRET"} name={'secret'} onChange={handleApiKeyInput} value={storageApiKeys?.pinata?.secret}/>
    </FormWrapper>
    <FormWrapper>
      <FormLabel>JWT</FormLabel>
      <FormInput1  placeholder={'JWT'} name={'jwt'} onChange={handleApiKeyInput} value={storageApiKeys?.pinata?.jwt}/>
    </FormWrapper>
    {errors?.storageApiKeys?.pinata && <ErrorText>{errors?.storageApiKeys?.pinata}</ErrorText>}
  </FormWrapper>}
     {/* (storageType === NFT_STORAGE_TYPES.WEB3_STORAGE || errors?.storageApiKeys?.web3storage) && <FormWrapper>
     <FormLabel>Internet Archive</FormLabel>
    <FormWrapper>
      <FormLabel>API KEY</FormLabel>
      <FormInput1/>
    </FormWrapper>
    <FormWrapper>
      <FormLabel>API SECRET</FormLabel>
      <FormInput1/>
    </FormWrapper>
    {errors?.storageApiKeys?.web3Storage && <ErrorText>{errors?.storageApiKeys?.web3Storage}</ErrorText>}
  </FormWrapper> */}
  
  </FormWrapper> 
          
</FormWrapper>
            </>
        }
        {currentPage === 3 && 
            <><FormWrapper>KYC Coming Soon</FormWrapper></>
        }
        <PageIndexer onClick={setCurrentPage} pages={2} currentPage={currentPage}/>
        <ButtonWrapper>
            <Button onClick={() => submitCreate()}>Create Account</Button>
            <CustomButton onClick={() => {goTo('/signup')}}>Cancel</CustomButton>
        </ButtonWrapper>
    </ContentWrapper>
    </>
  )
}

export default RegistrationForm