import React, { useEffect, useState } from 'react'
import ContentWrapper from '../../../ContentWrapper'
import {SelectButton, SelectButtonWrapper} from '../../../Select'
import useArtist from '../../../../hooks/useArtist'
import { ModalLoadingScreen } from '../../../LoadingScreen'
import { AlbumsReleasesTable, CollectionsReleasesTable, RoyaltiesTable, SinglesReleasesTable, TopPlayedTable } from '../../../ContentTable/ArtistTables'
import RevenueCard from '../../../Card/RevenueCard'
import { Nav, NavContainer, NavLink } from '../../../NavLink/NavLinkElements'
import { getObjectLength, timestampToDate } from '../../../../utils'
import { useUser } from '../../../../hooks/useUser'

const MusicContent = ({artistId, userId, isLoaded, setLoaded, filter}) => {
    const {getArtistTopAlbumsByDate, getArtistTopSongsByDate, searchArtistPopularSongs, getArtistDiscography,searchArtistSingles, searchArtistAlbums, getArtistMusicRoyaltiesByUserId} = useArtist({artistId})
    const {getCollectionsByUser} = useUser({})
    const [currentTab,setTab] = useState('top')
    const [songs, setSongs] = useState([])
    const [albums, setAlbums] = useState([])
    const [royalties, setRoyalties] = useState({})
    const [collections, setCollections] = useState({})
    const [topAlbums, setTopAlbums] = useState({})
    const [topSongs, setTopSongs] = useState({})
    const [isDiscogLoaded, setDiscogLoaded] = useState(false)

    useEffect(() => {
        getMusicStats()
    
      return () => {
        
      }
    }, [artistId,filter])

    useEffect(() => { 
        if(isLoaded){
        setDiscogLoaded(false)
        getDiscography()
    }
      return () => {
        
      }
    }, [artistId,filter, isLoaded])

    const getSongPlays = (songId) => {
        return topSongs?.songs?.filter(s => s?.songId === songId)[0]?.plays > 0 ? topSongs?.songs?.filter(s => s?.songId)[0]?.plays : 0
    }
    const getAlbumPlays = (albumId) => {
        return topAlbums?.albums?.filter(s => s?.albumId === albumId)[0]?.plays > 0 ? topAlbums?.albums?.filter(s => s?.albumId)[0]?.plays : 0
    }

    const getDiscography = async () => {
        return await Promise.all([searchArtistSingles(artistId, "all"),searchArtistAlbums(artistId, "all"), getArtistMusicRoyaltiesByUserId(userId, "all"), getCollectionsByUser(userId, "all")])
        .then(data => {
            if(data[0]?.length > 0){
             setSongs(data[0]/* {...data[0]?.songs, 
                 songs: data[0]?.songs?.songs?.map(song => {
                 return {...song,
                     plays: getSongPlays(song?.songId)
                 }
             })} */) } 
             if(data[1]?.length > 0){
                setAlbums(data[1]/* {
                 ...data[0]?.albums,
                 albums: data[0]?.albums?.albums?.map(album => {
                     return {...album,
                         plays: getAlbumPlays(album?.albumId)
                     }
                 })
             } */)}
         
         if(data[2]?.length > 0){
            setRoyalties(data[2])
         }
         if(data[3]?.length > 0){
            setCollections(data[3])
         }
         setDiscogLoaded(true)
         }).catch(err => {
             setDiscogLoaded(true)
         })
           
        
       
    }

    const getMusicStats = async () => {
        setLoaded(false)
        setTopAlbums({})
        setTopSongs({})
        setSongs({})
        setAlbums({})
        await Promise.all([getArtistTopSongsByDate(artistId,filter, "all"), getArtistTopAlbumsByDate(artistId,filter, "all")])
        .then(async music => {
            if(music[0]?.length > 0 && music[0] !== false){
                setTopSongs(music[0])
            }
            if(music[1]?.length > 0 && music[1] !== false){
                
                setTopAlbums(music[1])
            }
        
           /*  if(music[2]?.length > 0 && music[2] !== false){
                setAlbums(music[2])
            } */
            setLoaded(true)
        }).catch(err => {
            console.error(err)
            return setLoaded(true)
        })
    }
    
    
    
    
  return (
    <><ContentWrapper>
        {isLoaded && <>
            <NavContainer style={{marginBottom: "5%"}}>
                <Nav>
                    <NavLink active={currentTab === 'top'} onClick={() => setTab('top')}>
                        Top Played
                    </NavLink>
                    <NavLink active={currentTab === 'releases'} onClick={() => setTab('releases')}>
                       Releases
                    </NavLink>
                    <NavLink active={currentTab === 'songs'} onClick={() => setTab('songs')}>
                        Songs
                    </NavLink>
                    <NavLink active={currentTab === 'albums'} onClick={() => setTab('albums')}>
                        Albums
                    </NavLink>
                    <NavLink active={currentTab === 'royalties'} onClick={() => setTab('royalties')}>
                        Regium Tokens
                    </NavLink>
                    <NavLink active={currentTab === 'collections'} onClick={() => setTab('collections')}>
                        NFT Collections
                    </NavLink>
                </Nav>
            </NavContainer>
            {currentTab === 'top' && <>{topSongs?.songs?.length > 0 && <SinglesReleasesTable releases={topSongs?.songs} heading={"Top Played Songs"}/>}
            {topAlbums?.albums?.length > 0 && 
            <AlbumsReleasesTable releases={topAlbums?.albums} heading={"Top Played Albums"} />}</>}

            {isDiscogLoaded && <>{currentTab === 'releases' && (songs?.length > 0 || albums?.length > 0) && <>
                {songs?.length > 0 && <SinglesReleasesTable releases={songs?.songs} heading={'Single Releases'}/>}
                {albums?.length > 0 && <AlbumsReleasesTable releases={albums?.albums} heading={'Album Releases'}/>}
                {royalties?.length > 0 && 
            <RoyaltiesTable royalties={royalties?.royalties.map(r => {
                return {
                    ...r,
                    albumArt: r?.musicDetails?.albumArt,
                    type: `${r?.type[0]?.toUpperCase()}${r?.type.substring(1)}`,
                    nextPayout: r?.nextPayout.split('T')[0],
                    createdAt: timestampToDate(r?.createdAt)?.split('T')[0]

                }
            })} heading={'Regium Tokens'} />}
            {collections?.length > 0 && 
            <CollectionsReleasesTable collections={collections?.collections.map(c => {
                return {
                    ...c,
                    ...c?.mintInfo,
                    albumArt: c?.cover?.url,
                    dateCreated: c?.dateCreated?.split('T')[0],
                    transferFee: parseFloat(c?.mintInfo?.transferFee/1000)

                }
            })} heading={'NFToken Collections'} /> }
            </>}
            
            { currentTab === 'songs' && songs?.length > 0 && 
            <SinglesReleasesTable releases={songs?.songs} heading={'Single Releases'} />}
            {currentTab === 'albums' && albums?.length > 0 && 
            <AlbumsReleasesTable releases={albums?.albums} heading={'Album Releases'} />}
            {currentTab === 'royalties' && royalties?.length > 0 && 
            <RoyaltiesTable royalties={royalties?.royalties.map(r => {
                return {
                    ...r,
                    albumArt: r?.musicDetails?.albumArt,
                    type: `${r?.type[0]?.toUpperCase()}${r?.type.substring(1)}`,
                    nextPayout: r?.nextPayout?.split('T')[0],
                    createdAt: timestampToDate(r?.createdAt)?.split('T')[0]
                    
                }
            })} heading={'Regium Tokens'} />}
            {currentTab === 'collections' && collections?.length > 0 && 
            <CollectionsReleasesTable collections={collections?.collections.map(c => {
                return {
                    ...c,
                    ...c?.mintInfo,
                    albumArt: c?.cover?.url,
                    dateCreated: c?.dateCreated?.split('T')[0],
                    transferFee: parseFloat(c?.mintInfo?.transferFee/1000)

                }
            })} heading={'NFToken Collections'} /> }</>}

            {!isDiscogLoaded && (currentTab === 'releases' || currentTab === 'songs' || currentTab === 'albums' || currentTab === 'royalties' || currentTab === 'collections') && <ModalLoadingScreen/>}
             </>}
            
        
        {!isLoaded && <ModalLoadingScreen text={"Loading Stats..."}/>}
        
    </ContentWrapper></>
  )
}

export default MusicContent