import React, { useEffect, useState } from "react";
import { FaBackward, FaHandshake, FaPen } from "react-icons/fa";
import { Link } from "react-router-dom";
import styled from "styled-components";
import AlbumArt from "../../AlbumArt";
import { CustomButton } from "../../ButtonElement";
import { CardContainer, CardContent } from "../../Card/CardElements";
import { H6, TextLink } from "../../Card/UserCard/UserCardElements";
import ContentWrapper from "../../ContentWrapper";
import { FormButton, FormInput1, FormText, FormWrapper } from "../../CustomForm/CustomFormElements";
import { DEFAULT_IMAGE } from "../../GravatarAPI";
import { Header } from "../../Header";
import { hexToRgba } from "../../HexToRGBA";
import { Heading, Subtitle } from "../../InfoSection/InfoElements";
import { HoverOption } from "../../MenuOptions/MenuOptionsElements";
import { useModal } from "../../Modal/ModalContext";
import { Nav, NavContainer, NavLink } from "../../NavLink/NavLinkElements";
import OptionModal from "../../OptionModal";
import { Text, TextRoute } from "../../QualificationRequirements/QualificationRequirementsElements";
import ScrollToTop from "../../ScrollToTop";
import { SkeletonUserCardImg } from "../../Skeleton/SkeletonElements";
import { ArtistNavImg, ArtistRegistrationNav, ArtistRegistrationNavLink } from "../RegistrationFormElements";
import ArtistAccountClaimer from "./ArtistAccountClaimer";
import RegistrationForm from "./RegistrationForm";
import QRCodeForm from "../../QRCodeForm";
import { TRANSACTION_OPTIONS } from "../../../utils/Enum";
import { Theme } from "../../WebsiteThemeElements";
import { downloadFile } from "../../../utils";
import ImageUtils from "../../../utils/ImageUtils";
import AppBanner from "../../AppBanner";
import { XummApp } from "../../AppBanner/Data";
import { useAuth } from "../../../contexts/AuthContext";



const ArtistRegistration = () => {
  const { goTo } = ScrollToTop();
  const {currentUser, profiles} = useAuth()
  const {artistRegistrationClaim, artistRegistrationCreate, labelRegistrationCreate} = ImageUtils()
  const [regType, setRegType] = useState()
  const {openModal, modalContent, closeModal} = useModal()
  const [address, setAddress] = useState()


  useEffect(() => {
    if((localStorage?.getItem("address") || currentUser?.uid) && localStorage.getItem('regType') === 'artist' && !profiles?.artist){
      if(window.location.href.includes('?reg=claim')){
     setRegType('claim')
     return
    }
    if(window.location.href.includes('?reg=create')){
     setRegType('create')
     return
    }
    setRegType()
    return 
   }else{
    setRegType()
   localStorage.removeItem("address")
   goTo("/signup")
   }
   
   return () => {}
  }, [])


  return (
    <>
     {(!window.location.href.includes('?reg=create') && !window.location.href.includes('?reg=claim')) && 
     <><Header title={'Get Full Access to Sonar Muse for Artists'}/>
      <ContentWrapper>
    <Subtitle style={{ color: "white" }}>
          Select account registration type
        </Subtitle> 
        <div style={{ margin: "10% 0%", padding: "0", width: "100%" }}>
          <NavContainer>
            <ArtistRegistrationNav>
              <ArtistRegistrationNavLink
                onClick={() => {goTo('#?reg=claim')}}
              >
              
                <ArtistNavImg
                    onClick={() => {}}
                    isRounded
                    size={"8rem"}                     
                    albumArt={artistRegistrationClaim}
                  />  
                  Claim Existing Artist Page
              </ArtistRegistrationNavLink>
              <ArtistRegistrationNavLink
                onClick={() => {goTo('#?reg=create')}}
              >
                <ArtistNavImg
                    onClick={() => {}}
                    isRounded
                    size={"8rem"}                    
                    albumArt={artistRegistrationCreate}
                  />
                Create New Artist Page
              </ArtistRegistrationNavLink>{" "}
              <ArtistRegistrationNavLink
                onClick={() => {
                  goTo('/signup')
                  localStorage.removeItem("address")
                  localStorage.removeItem("regType")
              }}
              >
                <FaBackward/>
                Go Back
              </ArtistRegistrationNavLink>{" "}
            </ArtistRegistrationNav>
          </NavContainer>
        </div>
        
      </ContentWrapper></>}
      {window.location.href.includes('?reg=claim') 
        && <ArtistAccountClaimer/>}
        {window.location.href.includes('?reg=create')
        && <RegistrationForm artist={{profile:{
          uid:localStorage.getItem("address")
        }}} />}
    </>
  );
};

export default ArtistRegistration;
