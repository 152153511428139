import React, { useState } from 'react'
import useArtist from '../../../hooks/useArtist'
import { getArtistsNames2, getSongTitle } from '../../../utils'
import { ARTIST_FORM_INPUT_TYPES, ARTIST_SEARCH_TYPES, SLIDER_TYPES } from '../../../utils/Enum'
import { CustomButton } from '../../ButtonElement'
import ContentWrapper from '../../ContentWrapper'
import { FormInput1, FormLabel, FormText, FormWrapper } from '../../CustomForm/CustomFormElements'
import { DEFAULT_IMAGE } from '../../GravatarAPI'
import { Header } from '../../Header'
import { Subtitle } from '../../InfoSection/InfoElements'
import { useModal } from '../../Modal/ModalContext'
import { NavContainer } from '../../NavLink/NavLinkElements'
import OptionModal from '../../OptionModal'
import ScrollToTop from '../../ScrollToTop'
import { ServicesWrapper } from '../../Services/ServicesElements'
import { Slider } from '../../Slider'
import { ArtistNavImg, ArtistRegistrationNav, ArtistRegistrationNavLink } from '../RegistrationFormElements'
import ArtistInfo from './ArtistInfo'
import RegistrationForm from './RegistrationForm'

const ArtistAccountClaimer = () => {
  const { searchArtists, bulkArtistsSearch} = useArtist({});
  const { goTo } = ScrollToTop();
  const [artistName, setName] = useState()
  const [artists, setArtists] = useState([])
  const [artist, setArtist] = useState()
  const [artistInfo, setArtistInfo] = useState()
  const [isLoaded, setLoaded] = useState(false)

  const handleInput = (e) => {
    setArtists([])
    switch (e.target.name) {
        case ARTIST_FORM_INPUT_TYPES.NAME:
          setLoaded(false)
            setName(e.target.value)
            setArtist()
              searchArtists(ARTIST_SEARCH_TYPES.NAME, e.target.value).then(a => {
                if(a?.length > 0){
                  setArtists(a?.filter(artist => !artist?.isVerified))
                  
                }
                setLoaded(true)
              }).catch(err => {
                setLoaded(true)
              })
            break;
            
            default:

            break;
          }
        }


        const displayArtists = () => {
          return (
            <>
             <ServicesWrapper style={{padding: '50px 10px', margin: '0 2%', width:'100%', maxWidth:'100%'}}>
              {artists?.map(a => {
              return (<>
               
              <ArtistRegistrationNavLink
                onClick={() => {
                  setName('')
                  setArtist(a.artistId)
                  return 
                }}
              >
              
                <ArtistNavImg
                    isRounded
                    size={"8rem"}                     
                    albumArt={a?.artistSettings?.profilePictureType === "custom" ? a?.profilePicture : a?.photoUrl ? `${a?.photoUrl}?s=600` :  DEFAULT_IMAGE(600)}
                  />  
                  {a?.artistName}
              </ArtistRegistrationNavLink>

              </>)
            })}</ServicesWrapper>
            </>
          )
        }
      

  return (
    <>
    {!artistInfo && <Header title={'Artist Account Claimer'}/>}
    
    {!artistInfo && <ContentWrapper>
      <Subtitle style={{ color: "white" }}>
          Search for your artist page
        </Subtitle> 
      <FormWrapper>
            <FormInput1 name={ARTIST_FORM_INPUT_TYPES.NAME} value={artistName} onChange={handleInput} placeholder={'Search Artist'}/>
    {isLoaded && (artistName?.trim()?.length > 0) && (artists?.length > 0) && 
      displayArtists()
    }
    {isLoaded && (artists?.length === 0) && !artist && 
      <><FormText>Artist "{artistName}" not found</FormText>
      <CustomButton onClick={() => { goTo('/artist/register?reg=create')}}>Create New Artist</CustomButton></>
    }
    {!isLoaded && (artistName?.trim()?.length > 0) && 
      <><Slider type={SLIDER_TYPES.ARTIST_SKELETON} id={'artistSearch'}  heightScroll />
      <Slider type={SLIDER_TYPES.ARTIST_SKELETON} id={'artistSearch'}  heightScroll /></>
    }
    {isLoaded && artist && <ArtistInfo artist={artist} setArtist={setArtist} setArtistInfo={setArtistInfo}/>}

    </FormWrapper>

    </ContentWrapper>}

    {isLoaded && artistInfo && <RegistrationForm artist={{...artistInfo?.profile, uid: localStorage.getItem("address")}} discography={artistInfo?.discography}/>}
    
    </>
  )
}

export default ArtistAccountClaimer