import React, { useEffect, useState } from 'react'
import useRecordLabel from '../../../../hooks/useRecordLabel'
import { PlaylistTable, SinglesReleasesTable } from '../../../ContentTable/ArtistTables'
import { FormText, FormWrapper } from '../../../CustomForm/CustomFormElements'
import ContentWrapper from '../../../ContentWrapper'
import { EpisodesTable } from '../../../ContentTable/PodcasterTables'
import { MUSIC_PLAYING_FROM } from '../../../../utils/Enum'
import { SelectButton, SelectButtonWrapper } from '../../../Select'
import { ModalLoadingScreen } from '../../../LoadingScreen'
import KeyMetrics from '../../../Charts/KeyMetrics'
import { getGroupedPlays, getNumListeners, getObjectLength, getPlayCount, groupInfoByDate } from '../../../../utils'
import { Theme } from '../../../WebsiteThemeElements'
import Chip from '../../../Chip'
import { ChipList } from '../../../Chip/ChipElements'
import useMusic from '../../../../hooks/useMusic'
import { useWebsiteTheme } from '../../../WebsiteTheme/WebsiteThemeContext'
import ChartToggle from '../../../Charts'

const PlaylistsContent = ({labelId, isLoaded, setLoaded, filter}) => {
    const {getRecordLabelMusicPlaylistsAdditionbyDate, getRecordLabelMusicEpisodesAdditionbyDate} = useRecordLabel({id: labelId})
    const {themeColors} = useWebsiteTheme()

    const {getSongs} = useMusic()
    const [listeners, setListeners] = useState()
    const [playlists, setPlaylists] = useState()
    const [episodes, setEpisodes] = useState()
    const [metricType, setMetricType] = useState("plays")
    const [playType, setPlayType] = useState("full")
    const [songs, setSongs] = useState()
    const [isSongsLoaded, setSongsLoaded] = useState(false)

 
    const getData = async() => {
        setLoaded(false)
        setListeners([])
        setPlaylists([])
        setEpisodes([])
        await Promise.all([getRecordLabelMusicPlaylistsAdditionbyDate(labelId, filter),getRecordLabelMusicPlaylistsAdditionbyDate(labelId, filter, "all"), getRecordLabelMusicEpisodesAdditionbyDate(labelId, filter, "all") ])
        .then(async data => {
            if(data[0]?.listeners?.length > 0){
                setListeners(data[0]?.listeners?.filter(listen => listen?.playingFrom?.playingFrom === MUSIC_PLAYING_FROM.PLAYLIST),)
            }
            if(data[1]?.playlists?.length > 0){
                setPlaylists(data[1]?.playlists)
            }
            if(data[2]?.episodes?.length > 0){
                setEpisodes(data[2]?.episodes)
            }
            return
        }).catch(err => {
            console.error(err)
            return
        }
        )
        setLoaded(true)
    }

    useEffect(() => {
      getData()
    
      return () => {
      }
    }, [labelId, filter])

    useEffect(() => {
        if(isLoaded){
            setSongsLoaded(false)
            let s = getSongsByPlayType()
    if(s?.length > 0){
      getSongs(s).then(Songs => {
        if(Songs?.length > 0){
        setSongs(Songs.map(song => {
            return {
                ...song,
                plays: listeners.filter(l => l?.songId === song?.songId && l?.playType === playType)?.length
            }
        })).sort((a,b) => b?.plays-a?.plays)
    }
        setSongsLoaded(true)
      }).catch(err => {
        setSongsLoaded(true)
      })}
    }
    
      return () => {
      
      }
    }, [playType, labelId, filter, isLoaded])

    /* useEffect(() => {
      if(isLoaded && playlists?.length > 0){
        setPlaylistsLoaded(false)
        
      }
      return () => {
      }
    }, [labelId, filter, isLoaded]) */
    
    const getSongsByPlayType = () => {
        let list = []
        switch (playType) {
            case "skip":
                list = listeners.filter(play => play?.playType === playType && !play?.repeat).map(play => {
                    return play?.songId
                })
                break;
            case "full":
                list = listeners.filter(play => play?.playType === playType || play?.repeat > 0).map(play => {
                    return play?.songId
                })
                break;
            case "partial":
                list = listeners.filter(play => play?.playType === playType && !play?.repeat).map(play => {
                    return play?.songId
                })
                break;
        
            default:
                list = listeners.filter(play => play?.playType === playType && !play?.repeat).map(play => {
                    return play?.songId
                })
                break;
            
        }
        return [...new Set(list)]
    }
    
    

  return (
    <><ContentWrapper>
        {!isLoaded && <ModalLoadingScreen text={"Loading Stats..."}/>}
        {isLoaded && <>
        <KeyMetrics data={[
            {
                heading: "Playlist Plays",
                onClick: ()=> {
                    setMetricType("plays")
    
                },
                labels: listeners?.length > 0 ? Object.keys(groupInfoByDate(listeners, "timestamp", filter)) : [],
                datasets: [{
                    label: "Plays",
                    data: listeners?.length > 0 ? Object.values(groupInfoByDate(listeners, "timestamp", filter)).map(plays => {
                        return getPlayCount(plays)
                    }) : [],
                    borderColor: themeColors.infoColor,
                    backgroundColor: themeColors.infoColor,
                }]

            }, {
                heading: "Playlist Listeners",
                onClick: ()=> {
                    setMetricType("listeners")
    
                },
                labels: listeners?.length > 0 ? Object.keys(groupInfoByDate(listeners, "timestamp", filter)) : [],
                datasets: [{
                    label: "Listeners",
                    data: listeners?.length > 0 ? Object.values(getNumListeners(groupInfoByDate(listeners, "timestamp", filter))) : [],
                    borderColor: themeColors.successColor,
                    backgroundColor: themeColors.successColor
                }]

            },
            {
                heading: "Playlist Additions",
                onClick: ()=> {
                    setMetricType("playlistAdditions")
    
                },
                labels: playlists?.length > 0 ? Object.keys(groupInfoByDate(playlists, "datePublished", filter)) : [],
                datasets: [{
                    label: "Playlists",
                    data: playlists?.length > 0 ? Object.values(groupInfoByDate(playlists, "datePublished", filter))?.map(playlists => {
                        return playlists?.length
                    }) : [],
                    borderColor: themeColors.warningColor,
                    backgroundColor: themeColors.warningColor,
                }]
            },
            {
                heading: "Episodes Additions",
                onClick: ()=> {
                    setMetricType("episodeAdditions")
    
                },
                labels: episodes?.length > 0 ? Object?.keys(groupInfoByDate(episodes, "releaseDate", filter)) : [],
                datasets: [{
                    label: "Episodes",
                    data: episodes?.length > 0 ? Object?.values(groupInfoByDate(episodes, "releaseDate", filter))?.map(episodes => {
                        return episodes?.length
                    }) : [],
                    borderColor: themeColors.errorColor,
                    backgroundColor: themeColors.errorColor
                }]
            }
        ]}/>
      {metricType === "plays" && listeners?.length > 0 &&  <>
        <ChipList alignment={"right"}>
        <Chip
                  label={"All"}
                  background={playType === "all" && themeColors.accentColor}
                  onChipClick={() => setPlayType("all")}
                />
            <Chip label={"Full Plays"} background={playType === 'full' && themeColors.successColor} onChipClick={() => setPlayType("full")}/>
            <Chip label={"Partial Plays"} background={playType === 'partial' && themeColors.infoColor} onChipClick={() => setPlayType("partial")}/>
            <Chip label={"Skips"} background={playType === 'skip' && themeColors.warningColor} onChipClick={() => setPlayType("skip")}/>
        </ChipList>
        {playType === "all" && (
                  
                    <ChartToggle
                      data={{
                        heading: 'All Plays (By Type)',
                        labels:
                          listeners?.length > 0 > 0
                            ? Object.keys(
                                groupInfoByDate(listeners, "timestamp", filter)
                              )
                            : [],
                        datasets: [
                          {
                            label: "Full",
                            data:
                              listeners.filter((p) => p?.playType === "full")
                                ?.length > 0
                                ? Object.values(
                                    groupInfoByDate(
                                      listeners.filter(
                                        (p) => p?.playType === "full"
                                      ),
                                      "timestamp",
                                      filter
                                    )
                                  ).map((p) => {
                                    return p.length;
                                  })
                                : [],

                            borderColor: themeColors.successColor,
                            backgroundColor: themeColors.successColor,
                          },
                          {
                            label: "Partial",
                            data:
                              listeners.filter((p) => p?.playType === "partial")
                                ?.length > 0
                                ? Object.values(
                                    groupInfoByDate(
                                      listeners.filter(
                                        (p) => p?.playType === "partial"
                                      ),
                                      "timestamp",
                                      filter
                                    )
                                  ).map((p) => {
                                    return p.length;
                                  })
                                : [],

                            borderColor: themeColors.infoColor,
                            backgroundColor: themeColors.infoColor,
                          },
                          {
                            label: "Skips",
                            data:
                              listeners.filter((p) => p?.playType === "skip")
                                ?.length > 0
                                ? Object.values(
                                    groupInfoByDate(
                                      listeners.filter(
                                        (p) => p?.playType === "skip"
                                      ),
                                      "timestamp",
                                      filter
                                    )
                                  ).map((p) => {
                                    return p?.length;
                                  })
                                : [],
                            borderColor: themeColors.warningColor,
                            backgroundColor: themeColors.warningColor,
                          },
                        ],
                      }}
                    />
                )}
       {playType !== 'all' && 
        <ChartToggle data={{
                heading: `${playType[0].toUpperCase()}${playType.substring(
                      1
                    )}`,
                labels:listeners?.length > 0 ? Object.keys(groupInfoByDate(listeners, "timestamp", filter)) : [],
                datasets: [{
                    label: playType,
                    data: listeners?.length > 0 ? Object.values(groupInfoByDate(listeners, "timestamp", filter)).map(plays => {
                        return getGroupedPlays(plays)[playType]
                    }): [],
                    
                    borderColor: playType === "skip" ? themeColors.warningColor : playType === "partial" ?  themeColors.infoColor : themeColors.successColor,
                    backgroundColor: playType === "skip" ? themeColors.warningColor : playType === "partial" ?  themeColors.infoColor : themeColors.successColor
                }]
                           
        }}/>}
 {!isSongsLoaded && <ModalLoadingScreen/>}
        {isSongsLoaded && <ContentWrapper><FormWrapper>
            <SinglesReleasesTable releases={songs} heading={`Songs`}/>
            </FormWrapper></ContentWrapper>}
     
        </>}
        {metricType === "listeners" && listeners?.length > 0 && <>
            <ChartToggle data={{
                heading: 'Unique Listeners',
                labels: Object.keys(groupInfoByDate(listeners, "timestamp", filter)),
                datasets: [{
                    label: "Listeners",
                    data: Object.values(getNumListeners(groupInfoByDate(listeners, "timestamp", filter))),
                    
                    borderColor: themeColors.successColor,
                    backgroundColor: themeColors.successColor,
                }]
                           
        }}/>
        
        </>}
        {metricType === "playlistAdditions" && playlists?.length > 0 && <>
            {<ContentWrapper>
            <FormWrapper><PlaylistTable playlists={playlists} heading={`Playlists`}/></FormWrapper>
            </ContentWrapper>}
        </>}
        {metricType === "episodeAdditions" && episodes?.length > 0 && <>
            {episodes?.length > 0 && <ContentWrapper>
            <EpisodesTable releases={episodes} heading={`Episodes`}/>
            </ContentWrapper>}
        </>}
        {(((metricType === "plays" || metricType === "listeners") && listeners?.length === 0)  || (metricType === "playlistAdditions" && playlists?.length === 0) || (metricType === "episodeAdditions" && episodes?.length === 0)) && <>
            <FormWrapper><FormText>No Data Available</FormText></FormWrapper>
        </>}
        </>}
        </ContentWrapper></>
  )
}

export default PlaylistsContent