import React, { useContext, useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import {

  ContentListContainer,
  DetailsContainer,
  DetailsText,
  Button,
  ImageWrapper,
  Img,

  Table,
  TableRow,
  TableData,
  TableHeading,

} from "./ContentListElements";

import { ARTIST_SEARCH_TYPES, CONTENT_TYPES, NOTIFICATION_TYPES } from "../../utils/Enum";
import { Fade } from "react-reveal";
import useMusic from "../../hooks/useMusic";

import { useNavigate } from "react-router-dom";

import { useModal } from "../Modal/ModalContext";
import ImageUtils from "../../utils/ImageUtils";
import { FormText } from "../CustomForm/CustomFormElements";
import SongList from "./SongList";
import UserList from "./UserList";
import ArtistList from "./ArtistList";
import NFTokenList from "./NFTokenList";
import MiscellaneousList from "./MiscellaneousList";

function ContentList({ children, type, content, onButtonClick, displayFullList }) {
  const { currentUser, gravatarInfo, } = useAuth();
  const { toggleModal, modalContent, isOpen } = useModal();
  const { defaultAlbumArt, DEFAULT_IMAGE } = ImageUtils();
  const { getAlbumLike, getSongLike, unlikeSong, likeSong } = useMusic({});
  const [showAll, setShowAll] = useState(displayFullList ? displayFullList : false )
  const [visibleContent, setVisibleContent] = useState(displayFullList ? content : content?.length-1 > 5 ? content?.filter((c, index) => index < 5) : content)
  const [likes, setLikes] = useState([]);
  const navigate  = useNavigate();

 



  const setNotificationTitle = (notification) => {
    switch (notification.type) {
      case NOTIFICATION_TYPES.LIKE:
        if(notification?.songId){
          return <FormText>{`${notification?.senderId?.substring(0,5)}...${notification?.senderId?.substring(notification?.senderId?.length - 4, notification?.senderId?.length - 1)} liked a song of yours`}</FormText>
        }
        if(notification?.albumId){
          return <FormText>{`${notification.senderId} liked an album of yours`}</FormText>
        }
        if(notification?.playlistId){
          return <FormText>{`${notification.senderId} liked a playlist of yours`}</FormText>
        }
        break;
      case NOTIFICATION_TYPES.FOLLOW:
        
        break;
      case NOTIFICATION_TYPES.ARTIST_FOLLOW:
        
        break;
      case NOTIFICATION_TYPES.COMMENT:
        
        break;
      case NOTIFICATION_TYPES.MUSIC_RELEASE:
        
        break;
      case NOTIFICATION_TYPES.PENDING_NFTOKEN:
        
        break;
      case NOTIFICATION_TYPES.PLAYS:
        
        break;
      case NOTIFICATION_TYPES.SUBSCRIPTION:
        
        break;
      case NOTIFICATION_TYPES.TIP:
        
        break;
    
      default:
        break;
    }
  }




  const displayContent = () => {
    switch (type) {
      case CONTENT_TYPES.SONG:
        return (
          <>
          <SongList 
          content={content} 
          showAll={showAll} 
          setShowAll={setShowAll} 
          onButtonClick={onButtonClick} 
          />
           </>)
      case CONTENT_TYPES.GENRE:
        return (
          <>
            <Table>
              <TableRow>
                <TableHeading></TableHeading>
                <TableHeading></TableHeading>
                <TableHeading></TableHeading>
              </TableRow>
              {content.map((genre, index) => {
                return (
                  <TableRow
                    key={genre?.id}
                    id={genre?.id}
                    to={
                      onButtonClick
                        ? "#"
                        : genre?.id
                        ? `/music/${genre?.id}`
                        : "/home"
                    }
                    onClick={() => onButtonClick(genre)}
                  >
                    <TableData>
                      <DetailsContainer>
                        <DetailsText>{genre?.name}</DetailsText>
                      </DetailsContainer>
                    </TableData>
                  </TableRow>
                );
              })}
            </Table>
          </>
        );

      case CONTENT_TYPES.ALBUM:
        return <></>;
      case CONTENT_TYPES.USER:
        return (
          <>
           <UserList content={content} onButtonClick={onButtonClick}/>
          </>
        );

      case CONTENT_TYPES.ARTIST:
        return (
          <>
           <ArtistList content={content}/>
          </>
        );

      case CONTENT_TYPES.PLAYLIST:
        return <></>;
      case CONTENT_TYPES.COLLECTION:
        return (
          <>
            <Table>
              <TableRow>
                <TableHeading></TableHeading>
                <TableHeading></TableHeading>
                <TableHeading></TableHeading>
              </TableRow>
              {content.map((collection, index) => {
                return (
                  <Fade>
                    <TableRow
                      key={index}
                      id={collection?.collectionId}
                      to={
                        onButtonClick
                          ? "#"
                          : collection?.collectionId
                          ? `/artist/${collection?.collectionId}`
                          : "/home"
                      }
                      onClick={
                        onButtonClick && collection
                          ? onButtonClick(collection)
                          : {}
                      }
                    >
                      <TableData>
                        <ImageWrapper>
                          <Img
                            isCircular={true}
                            src={
                              collection?.cover
                                ? collection?.cover
                                : defaultAlbumArt
                            }
                            alt={
                              collection?.name ? collection?.name : "collection"
                            }
                          />
                        </ImageWrapper>
                      </TableData>
                      <TableData>
                        <DetailsContainer>
                          <DetailsText>{collection.name}</DetailsText>
                        </DetailsContainer>
                      </TableData>
                    </TableRow>
                  </Fade>
                );
              })}
            </Table>
          </>
        );

        
      case CONTENT_TYPES.NOTIFICATION:
        return (
          <>
            <Table>
              <TableRow>
                <TableHeading></TableHeading>
                <TableHeading></TableHeading>
                <TableHeading></TableHeading>
              </TableRow>
              {content?.length > 0 && content.map((notification, index) => {
                return (
                  <Fade>
                    <TableRow
                      key={index}
                      id={notification?.notificationId}
                      to={
                       "#"
                      }
                      onClick={
                        onButtonClick && notification
                          ? onButtonClick(notification)
                          : {}
                      }
                    >
                      <TableData>
                        {}
                      </TableData>
                      <TableData>
                        <DetailsContainer>
                          <DetailsText>{setNotificationTitle(notification)}</DetailsText>
                        </DetailsContainer>
                      </TableData>
                    </TableRow>
                  </Fade>
                );
              })}
            </Table>
          </>
        );
        case CONTENT_TYPES.NFTOKEN:
          <NFTokenList content={content}/>
          break;
        case CONTENT_TYPES.MISCELLANEOUS:
          <MiscellaneousList content={content} onClick={onButtonClick}/>
          break;
      default:
        break;
    }
  };
  return (
    <>
      <ContentListContainer>
        {content && displayContent()}
        {!content && { children }}
      </ContentListContainer>
    </>
  );
}

export default ContentList;
