import React from 'react'
import { SLIDER_TYPES } from '../../../utils/Enum'
import ContentWrapper from '../../ContentWrapper'
import { Slider } from '../../Slider'
import { RecordLabelContentHeadingWrapper } from './RecordLabelProfileContentElements'
import ImageSlider from '../../ImageSlider'
import { stringFormatter } from '../../../utils'
import SocialLinks from '../../SocialLinks'

const RecordLabelAbout = ({name, socialLinks, bio, images, label, tourDates}) => {
  return (
    <ContentWrapper heading={`About ${name}`}>
        {socialLinks &&<RecordLabelContentHeadingWrapper style={{flexDirection: 'row', justifyContent:'flex-start'}}> <SocialLinks accounts={socialLinks}/></RecordLabelContentHeadingWrapper>}
        {(bio || label?.labelSettings?.bio) && <div style={{color:'white', whiteSpace: "pre-wrap"}}>{label?.bio ? label?.labelSettings?.bioType === "custom" ? stringFormatter(label.bio): bio : bio }</div>}
        {(images && label?.labelSettings?.showGallery) && <ImageSlider images={images}/>}
        
        {(tourDates && label?.labelSettings?.showTrips) && <>{tourDates}</>}
        </ContentWrapper>
  )
}


export default RecordLabelAbout