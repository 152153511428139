import { DarkTheme, Theme } from "../WebsiteThemeElements";
import { Container, BackgroundWrapper, SonarMuseLoader, SonarMuseLoader1, LoaderWrapper, SonarMuseImg, SonarMuseImg1 } from "./LoadingScreenElements";
import logo from "../../images/sonar_muse_logo_nav1.png";
import invertedLogo from "../../images/sonar_muse_logo_nav_invert1.png";
import logo2 from "../../images/sonar_muse_logo_nav2.png";
import invertedLogo2 from "../../images/sonar_muse_logo_nav_invert2.png";
import { useEffect } from "react";
import { useState } from "react";
import { getRandomColor } from "../HexToRGBA";
import { updateWebPageIcon } from "../../utils/MusicUtils";

export default function LoadingScreen({transparent, mainColor }) {
	const theme = Theme({mainColor: mainColor})
	const [num, setNum] = useState(0)
	const colors = [theme.mainBg, theme.secondaryBg]
	const [color, setColor] = useState(theme?.mainColor)
	useEffect(() => {
		updateWebPageIcon('https://artists.sonarmuse.org/favicon.ico')

		let x = Math.floor((Math.random() * 1))
	  if(!transparent){
		let interval = setTimeout(() => {
			setColor(getRandomColor())
			setNum(x)
		}, 4000);	
		
  		
		return () => clearInterval(interval);
	  }
	  
	})


	return (
		<>
			<Container>
				<BackgroundWrapper transparent={transparent} color={color}>
					<LoaderWrapper>
                    <SonarMuseLoader>
                    <SonarMuseImg num={0} viewBox="0 0 1213 1213" fill="white" xmlns="http://www.w3.org/2000/svg">

<path d="M 103.00,854.00
C 97.78,850.97 94.93,843.39 92.58,838.00
92.58,838.00 77.75,807.00 77.75,807.00
71.05,793.55 64.52,772.60 59.98,758.00
59.98,758.00 55.92,746.00 55.92,746.00
55.92,746.00 49.12,717.00 49.12,717.00
49.12,717.00 45.21,702.00 45.21,702.00
41.63,685.27 40.10,668.02 38.91,651.00
38.91,651.00 37.00,630.00 37.00,630.00
37.00,630.00 37.00,586.00 37.00,586.00
37.00,586.00 37.91,574.00 37.91,574.00
37.91,574.00 43.43,520.00 43.43,520.00
43.43,520.00 50.58,489.00 50.58,489.00
54.64,470.69 60.29,449.58 66.69,432.00
66.69,432.00 80.26,399.00 80.26,399.00
80.26,399.00 89.75,381.00 89.75,381.00
100.88,358.76 111.77,338.50 126.04,318.00
126.04,318.00 157.17,278.00 157.17,278.00
157.17,278.00 174.96,260.00 174.96,260.00
174.96,260.00 201.00,233.17 201.00,233.17
251.75,187.27 313.84,153.22 379.00,132.98
379.00,132.98 402.00,125.75 402.00,125.75
402.00,125.75 419.00,122.40 419.00,122.40
434.14,119.37 466.55,113.07 481.00,113.00
481.00,113.00 501.00,112.00 501.00,112.00
501.00,112.00 535.00,112.00 535.00,112.00
535.00,112.00 556.00,113.00 556.00,113.00
571.63,113.19 597.33,117.47 613.00,120.60
630.09,124.02 657.98,130.99 674.00,137.06
674.00,137.06 700.00,147.80 700.00,147.80
717.40,154.81 734.63,162.17 751.00,171.42
769.90,182.10 787.63,193.60 805.00,206.63
811.27,211.33 826.50,224.53 833.00,225.83
841.57,227.54 844.19,226.52 851.00,227.17
851.00,227.17 874.00,230.00 874.00,230.00
874.00,230.00 882.00,230.00 882.00,230.00
882.00,230.00 933.00,239.35 933.00,239.35
933.00,239.35 956.00,244.63 956.00,244.63
958.53,245.29 963.43,246.26 964.26,249.17
965.34,252.97 959.51,253.80 957.00,253.74
957.00,253.74 941.00,251.47 941.00,251.47
937.24,251.63 931.03,253.98 920.00,254.00
920.00,254.00 906.00,254.00 906.00,254.00
897.06,254.01 897.14,255.11 890.00,255.83
890.00,255.83 864.00,258.00 864.00,258.00
864.00,258.00 893.25,292.00 893.25,292.00
901.31,301.70 901.76,307.15 915.00,307.00
915.00,307.00 929.00,306.00 929.00,306.00
929.00,306.00 956.00,306.00 956.00,306.00
956.00,306.00 984.00,307.91 984.00,307.91
984.00,307.91 1009.00,309.17 1009.00,309.17
1009.00,309.17 1039.00,313.15 1039.00,313.15
1039.00,313.15 1084.00,321.80 1084.00,321.80
1087.77,322.57 1098.35,323.91 1096.34,329.88
1095.35,332.84 1091.77,333.75 1089.00,333.87
1083.58,334.09 1078.64,331.19 1075.00,331.35
1071.47,331.51 1068.68,333.89 1060.00,334.00
1060.00,334.00 1013.00,336.17 1013.00,336.17
1013.00,336.17 1003.00,337.87 1003.00,337.87
1003.00,337.87 969.00,343.55 969.00,343.55
969.00,343.55 933.00,350.00 933.00,350.00
941.36,362.84 953.76,388.55 960.14,403.00
961.45,405.97 965.44,416.73 967.50,418.23
968.93,419.28 970.35,419.12 972.00,418.94
972.00,418.94 992.00,416.17 992.00,416.17
992.00,416.17 1020.00,413.91 1020.00,413.91
1020.00,413.91 1032.00,413.00 1032.00,413.00
1032.00,413.00 1068.00,413.00 1068.00,413.00
1068.00,413.00 1091.00,414.91 1091.00,414.91
1106.59,415.98 1112.08,415.87 1128.00,419.00
1128.00,419.00 1163.00,425.80 1163.00,425.80
1166.66,426.54 1172.88,427.32 1174.58,431.12
1178.39,439.64 1161.54,435.90 1158.00,436.04
1158.00,436.04 1150.00,436.96 1150.00,436.96
1150.00,436.96 1135.00,436.96 1135.00,436.96
1128.78,437.08 1126.63,438.02 1121.00,438.71
1121.00,438.71 1109.00,439.83 1109.00,439.83
1078.01,442.93 1051.99,447.55 1022.00,456.14
1022.00,456.14 996.00,464.70 996.00,464.70
992.82,465.87 987.64,468.14 985.92,471.18
984.20,474.24 988.36,484.89 989.20,489.00
989.20,489.00 997.85,537.00 997.85,537.00
997.85,537.00 1001.00,571.00 1001.00,571.00
1001.00,571.00 1001.00,642.00 1001.00,642.00
1000.98,657.14 996.50,683.42 993.92,699.00
987.10,740.22 975.11,772.88 958.72,811.00
955.49,818.51 951.36,829.34 946.90,836.00
944.35,839.80 941.64,843.82 937.00,841.00
932.82,848.45 925.21,859.76 922.63,867.83
921.51,871.61 923.13,872.45 922.63,876.00
922.07,879.07 918.63,885.25 916.94,888.00
910.44,898.52 892.96,921.60 884.71,931.00
884.71,931.00 874.17,942.00 874.17,942.00
840.34,979.39 798.49,1015.04 754.00,1039.23
754.00,1039.23 699.00,1065.72 699.00,1065.72
694.86,1067.50 682.36,1073.49 678.43,1070.96
676.08,1069.45 675.25,1064.61 674.65,1062.00
674.65,1062.00 669.42,1037.00 669.42,1037.00
664.63,1010.40 661.04,984.04 661.00,957.00
661.00,957.00 660.03,943.00 660.03,943.00
660.03,943.00 661.00,931.00 661.00,931.00
661.49,888.52 669.39,836.61 682.02,796.00
682.02,796.00 692.95,765.00 692.95,765.00
692.95,765.00 698.81,748.00 698.81,748.00
698.81,748.00 707.44,727.00 707.44,727.00
709.12,720.63 704.24,716.14 701.06,711.00
695.63,702.19 695.66,700.61 693.79,691.00
693.40,689.03 692.65,687.01 692.78,685.00
692.91,683.06 693.93,680.71 694.82,679.00
700.46,668.22 710.18,667.01 721.00,669.09
729.45,671.06 732.88,675.18 738.48,669.09
738.48,669.09 755.72,643.00 755.72,643.00
763.66,631.68 782.76,608.01 792.09,598.00
812.89,575.67 840.52,548.94 865.00,530.88
904.51,501.75 937.34,485.94 982.00,467.00
982.00,467.00 973.65,440.00 973.65,440.00
973.65,440.00 967.00,422.00 967.00,422.00
967.00,422.00 941.00,428.01 941.00,428.01
941.00,428.01 902.00,438.34 902.00,438.34
851.56,454.45 803.26,477.81 760.00,508.42
734.20,526.68 709.89,547.15 688.04,570.00
688.04,570.00 665.08,595.00 665.08,595.00
662.32,598.01 657.25,604.49 654.00,606.36
650.65,608.29 648.06,606.82 645.00,605.12
645.00,605.12 628.00,594.42 628.00,594.42
615.06,586.93 599.31,578.70 584.00,581.93
582.63,582.22 580.28,582.62 579.09,583.21
576.01,584.76 573.88,589.18 571.00,592.00
575.00,596.79 575.21,603.53 579.31,611.00
584.63,620.68 595.64,633.10 604.00,640.27
608.25,643.91 618.29,650.89 617.48,657.00
617.11,659.75 608.40,674.49 606.42,678.00
606.42,678.00 593.75,703.00 593.75,703.00
593.75,703.00 585.31,719.00 585.31,719.00
567.22,758.37 556.67,793.87 547.42,836.00
535.36,891.00 533.77,956.27 541.28,1012.00
541.28,1012.00 551.89,1068.00 551.89,1068.00
554.33,1078.59 558.09,1087.86 559.00,1099.00
559.00,1099.00 540.00,1100.00 540.00,1100.00
540.00,1100.00 507.00,1100.00 507.00,1100.00
504.44,1100.00 500.58,1100.24 498.39,1098.83
494.37,1096.23 492.90,1085.58 492.00,1081.00
492.00,1081.00 481.73,1024.00 481.73,1024.00
481.73,1024.00 480.72,1013.00 480.72,1013.00
480.72,1013.00 478.04,996.00 478.04,996.00
478.04,996.00 478.04,986.00 478.04,986.00
478.04,986.00 477.02,978.00 477.02,978.00
477.02,978.00 477.02,960.00 477.02,960.00
477.02,960.00 475.60,944.00 475.60,944.00
475.60,944.00 477.00,926.00 477.00,926.00
477.00,926.00 477.00,910.00 477.00,910.00
477.00,910.00 478.04,900.00 478.04,900.00
478.04,900.00 478.04,891.00 478.04,891.00
478.04,891.00 480.72,874.00 480.72,874.00
480.72,874.00 482.29,857.00 482.29,857.00
482.29,857.00 485.75,838.00 485.75,838.00
489.50,816.38 493.92,795.10 500.00,774.00
511.73,733.31 520.72,708.31 539.26,670.00
539.26,670.00 558.78,633.00 558.78,633.00
560.67,629.42 566.73,620.21 567.15,617.00
567.73,612.62 562.99,607.82 560.90,604.00
557.73,598.21 556.86,593.51 557.01,587.00
557.33,573.82 567.99,564.51 581.00,565.04
589.68,565.39 595.31,569.81 599.96,567.74
603.06,566.35 606.81,560.75 609.08,558.00
609.08,558.00 627.29,536.00 627.29,536.00
643.88,516.82 662.46,499.18 681.00,481.91
681.00,481.91 691.00,472.17 691.00,472.17
729.33,437.55 792.26,399.78 840.00,379.43
840.00,379.43 865.00,369.95 865.00,369.95
884.89,362.65 909.15,351.81 930.00,349.00
930.00,349.00 912.95,322.00 912.95,322.00
911.25,319.28 907.37,312.29 904.82,310.92
902.71,309.79 888.64,311.86 885.00,312.04
885.00,312.04 860.00,315.73 860.00,315.73
860.00,315.73 820.00,322.35 820.00,322.35
729.65,342.80 653.32,377.11 579.00,432.87
579.00,432.87 529.00,475.17 529.00,475.17
524.67,479.09 511.86,493.04 508.00,494.79
501.87,497.55 492.02,489.23 487.00,486.05
472.65,476.97 459.26,469.40 442.00,468.00
442.00,468.00 443.00,456.00 443.00,456.00
446.24,456.76 452.30,459.42 455.00,458.97
458.81,458.35 463.32,453.64 466.00,451.00
466.00,451.00 485.00,432.00 485.00,432.00
501.31,415.71 538.62,385.41 558.00,372.33
626.05,326.39 694.33,292.82 774.00,271.88
796.68,265.92 819.86,262.30 843.00,258.73
843.00,258.73 858.00,256.00 858.00,256.00
858.00,256.00 841.00,239.00 841.00,239.00
834.48,232.49 831.65,228.06 822.00,228.00
822.00,228.00 770.00,228.00 770.00,228.00
770.00,228.00 757.00,228.96 757.00,228.96
757.00,228.96 724.00,231.17 724.00,231.17
724.00,231.17 699.00,234.72 699.00,234.72
614.28,245.87 527.79,276.60 454.00,319.42
431.20,332.65 409.41,347.36 388.00,362.71
388.00,362.71 370.00,376.42 370.00,376.42
367.41,378.44 363.09,382.22 360.00,383.02
354.71,384.39 346.44,377.53 342.00,374.81
330.81,367.94 319.60,361.02 307.00,357.03
302.80,355.70 298.40,354.84 294.00,354.48
294.00,354.48 289.18,354.48 289.18,354.48
287.27,354.20 287.41,353.92 286.00,353.00
286.00,353.00 273.00,362.00 273.00,362.00
273.00,362.00 274.10,364.32 274.10,364.32
274.10,364.32 274.10,379.00 274.10,379.00
278.65,381.61 282.60,389.44 286.44,394.00
286.44,394.00 304.00,411.83 304.00,411.83
306.66,414.24 311.49,417.81 312.96,421.00
314.89,425.19 311.75,428.27 309.00,431.00
309.90,428.94 314.28,422.57 310.67,421.20
307.50,420.00 298.33,429.75 296.00,431.96
296.00,431.96 282.00,443.21 282.00,443.21
273.44,449.95 264.66,457.25 257.01,465.00
248.24,473.90 248.34,476.30 242.65,486.00
242.65,486.00 234.09,498.00 234.09,498.00
234.09,498.00 227.07,511.00 227.07,511.00
227.07,511.00 212.09,532.00 212.09,532.00
212.09,532.00 196.25,562.00 196.25,562.00
196.25,562.00 186.68,587.00 186.68,587.00
178.70,600.55 171.79,614.33 166.06,629.00
163.73,634.96 160.02,640.54 161.00,647.00
161.00,647.00 159.00,646.00 159.00,646.00
159.44,649.30 159.80,649.98 157.00,652.00
157.29,657.84 152.66,666.36 150.40,672.00
150.40,672.00 135.03,713.00 135.03,713.00
135.03,713.00 120.88,762.00 120.88,762.00
120.88,762.00 114.58,792.00 114.58,792.00
114.58,792.00 103.00,854.00 103.00,854.00 Z
M 426.00,465.00
C 425.00,468.56 422.00,470.09 419.00,472.00
419.00,472.00 426.00,465.00 426.00,465.00 Z
M 428.00,465.00
C 428.00,465.00 433.00,466.00 433.00,466.00
433.00,466.00 429.00,468.00 429.00,468.00
429.00,468.00 428.00,465.00 428.00,465.00 Z
M 435.00,468.00
C 435.00,468.00 436.00,468.00 436.00,468.00
436.00,468.00 435.00,469.00 435.00,469.00
435.00,469.00 435.00,468.00 435.00,468.00 Z
M 419.00,473.00
C 419.00,473.00 421.00,477.00 421.00,477.00
421.00,477.00 417.00,478.00 417.00,478.00
417.00,478.00 419.00,473.00 419.00,473.00 Z
M 423.00,484.00
C 423.00,484.00 424.00,484.00 424.00,484.00
424.00,484.00 423.00,485.00 423.00,485.00
423.00,485.00 423.00,484.00 423.00,484.00 Z
M 425.00,490.00
C 425.00,490.00 433.76,504.00 433.76,504.00
439.18,511.41 446.36,518.61 453.00,524.96
456.73,528.53 465.64,534.80 466.09,540.00
466.45,544.20 461.16,549.71 458.73,553.00
458.73,553.00 436.34,584.00 436.34,584.00
411.36,620.72 391.57,660.12 374.42,701.00
374.42,701.00 361.00,738.00 361.00,738.00
346.32,782.05 336.46,823.74 331.83,870.00
331.83,870.00 328.91,906.00 328.91,906.00
328.91,906.00 328.00,918.00 328.00,918.00
328.00,918.00 328.00,968.00 328.00,968.00
328.00,968.00 328.91,978.00 328.91,978.00
328.91,978.00 333.73,1026.00 333.73,1026.00
333.73,1026.00 334.87,1034.00 334.87,1034.00
334.86,1038.18 333.88,1038.29 334.87,1043.00
334.87,1043.00 336.00,1039.00 336.00,1039.00
335.86,1048.05 337.18,1047.78 337.83,1055.00
337.99,1056.73 338.17,1059.97 336.98,1061.36
334.22,1064.58 323.05,1058.28 320.00,1056.75
320.00,1056.75 298.00,1046.22 298.00,1046.22
298.00,1046.22 270.00,1030.00 270.00,1030.00
270.66,1023.10 269.52,1023.84 269.09,1018.00
269.09,1018.00 268.00,998.00 268.00,998.00
268.00,998.00 266.00,979.00 266.00,979.00
266.00,979.00 266.00,957.00 266.00,957.00
265.91,949.48 264.08,947.94 264.08,943.00
264.08,937.89 265.98,936.93 266.00,927.00
266.00,927.00 266.00,906.00 266.00,906.00
266.03,898.75 266.83,899.09 267.85,893.00
267.85,893.00 267.85,884.00 267.85,884.00
267.85,884.00 273.72,837.00 273.72,837.00
273.72,837.00 275.46,824.00 275.46,824.00
280.27,797.38 286.82,770.98 294.29,745.00
294.29,745.00 314.95,684.00 314.95,684.00
317.01,678.48 321.82,663.13 326.00,660.00
325.77,657.33 325.30,656.81 328.00,656.00
327.28,650.76 328.47,649.47 331.00,645.00
331.00,645.00 333.00,646.00 333.00,646.00
333.00,646.00 334.00,639.00 334.00,639.00
334.00,639.00 336.00,640.00 336.00,640.00
336.00,640.00 335.00,637.00 335.00,637.00
335.00,637.00 337.00,638.00 337.00,638.00
337.00,638.00 336.00,635.00 336.00,635.00
336.00,635.00 345.00,617.00 345.00,617.00
345.00,617.00 347.00,617.00 347.00,617.00
347.00,617.00 346.00,615.00 346.00,615.00
346.00,615.00 347.00,613.00 347.00,613.00
347.00,613.00 349.00,614.00 349.00,614.00
349.00,614.00 348.00,611.00 348.00,611.00
348.00,611.00 350.00,612.00 350.00,612.00
350.00,612.00 349.00,609.00 349.00,609.00
349.00,609.00 351.00,610.00 351.00,610.00
351.00,610.00 350.00,607.00 350.00,607.00
350.00,607.00 352.00,608.00 352.00,608.00
352.00,608.00 351.00,605.00 351.00,605.00
351.00,605.00 353.00,606.00 353.00,606.00
352.50,596.43 359.68,598.24 365.17,585.00
365.17,585.00 367.95,576.00 367.95,576.00
375.45,560.48 407.33,517.74 419.00,502.00
420.81,504.54 425.09,499.38 426.04,497.42
427.09,495.27 425.61,492.24 425.00,490.00 Z
M 416.00,496.00
C 416.00,496.00 418.00,501.00 418.00,501.00
418.00,501.00 416.00,496.00 416.00,496.00 Z
M 419.00,505.00
C 419.00,505.00 418.00,505.00 418.00,505.00
418.00,505.00 419.00,506.00 419.00,506.00
419.00,506.00 419.00,505.00 419.00,505.00 Z
M 368.00,579.00
C 368.00,579.00 367.00,579.00 367.00,579.00
367.00,579.00 368.00,580.00 368.00,580.00
368.00,580.00 368.00,579.00 368.00,579.00 Z
M 365.00,581.00
C 365.00,581.00 363.00,585.00 363.00,585.00
363.00,585.00 365.00,581.00 365.00,581.00 Z
M 362.00,586.00
C 362.00,586.00 360.00,590.00 360.00,590.00
360.00,590.00 362.00,586.00 362.00,586.00 Z
M 356.00,599.00
C 356.00,599.00 355.00,599.00 355.00,599.00
355.00,599.00 356.00,600.00 356.00,600.00
356.00,600.00 356.00,599.00 356.00,599.00 Z
M 355.00,601.00
C 355.00,601.00 354.00,601.00 354.00,601.00
354.00,601.00 355.00,602.00 355.00,602.00
355.00,602.00 355.00,601.00 355.00,601.00 Z
M 354.00,603.00
C 354.00,603.00 353.00,603.00 353.00,603.00
353.00,603.00 354.00,604.00 354.00,604.00
354.00,604.00 354.00,603.00 354.00,603.00 Z
M 333.00,641.00
C 333.00,641.00 334.00,641.00 334.00,641.00
334.00,641.00 333.00,642.00 333.00,642.00
333.00,642.00 333.00,641.00 333.00,641.00 Z
M 332.00,643.00
C 332.00,643.00 333.00,643.00 333.00,643.00
333.00,643.00 332.00,644.00 332.00,644.00
332.00,644.00 332.00,643.00 332.00,643.00 Z
M 161.00,644.00
C 161.00,644.00 162.00,644.00 162.00,644.00
162.00,644.00 161.00,645.00 161.00,645.00
161.00,645.00 161.00,644.00 161.00,644.00 Z
M 331.00,648.00
C 331.00,648.00 330.00,648.00 330.00,648.00
330.00,648.00 331.00,649.00 331.00,649.00
331.00,649.00 331.00,648.00 331.00,648.00 Z
M 330.00,650.00
C 330.00,650.00 329.00,650.00 329.00,650.00
329.00,650.00 330.00,651.00 330.00,651.00
330.00,651.00 330.00,650.00 330.00,650.00 Z
M 327.00,657.00
C 327.00,657.00 326.00,657.00 326.00,657.00
326.00,657.00 327.00,658.00 327.00,658.00
327.00,658.00 327.00,657.00 327.00,657.00 Z" 
fill={colors[num]}/>

</SonarMuseImg>
					</SonarMuseLoader>
					<SonarMuseLoader1>
					{/*<SonarMuseImg1 style={{position: 'relative'}} src={invertedLogo2}/>*/}
					</SonarMuseLoader1>
					</LoaderWrapper>
            </BackgroundWrapper>
			</Container>
		</>	
	)
}

export const ModalLoadingScreen = ({mainColor, text}) => {
	const theme = Theme({mainColor: mainColor})

	const [color, setColor] = useState(theme?.mainColor)


	return (
	<BackgroundWrapper transparent={true} color={color} style={{padding: "2%"}}>
					<LoaderWrapper>
                    <SonarMuseLoader>
							<SonarMuseImg num={0} viewBox="0 0 1213 1213" fill="white" xmlns="http://www.w3.org/2000/svg">

							<path d="M 103.00,854.00
           C 97.78,850.97 94.93,843.39 92.58,838.00
             92.58,838.00 77.75,807.00 77.75,807.00
             71.05,793.55 64.52,772.60 59.98,758.00
             59.98,758.00 55.92,746.00 55.92,746.00
             55.92,746.00 49.12,717.00 49.12,717.00
             49.12,717.00 45.21,702.00 45.21,702.00
             41.63,685.27 40.10,668.02 38.91,651.00
             38.91,651.00 37.00,630.00 37.00,630.00
             37.00,630.00 37.00,586.00 37.00,586.00
             37.00,586.00 37.91,574.00 37.91,574.00
             37.91,574.00 43.43,520.00 43.43,520.00
             43.43,520.00 50.58,489.00 50.58,489.00
             54.64,470.69 60.29,449.58 66.69,432.00
             66.69,432.00 80.26,399.00 80.26,399.00
             80.26,399.00 89.75,381.00 89.75,381.00
             100.88,358.76 111.77,338.50 126.04,318.00
             126.04,318.00 157.17,278.00 157.17,278.00
             157.17,278.00 174.96,260.00 174.96,260.00
             174.96,260.00 201.00,233.17 201.00,233.17
             251.75,187.27 313.84,153.22 379.00,132.98
             379.00,132.98 402.00,125.75 402.00,125.75
             402.00,125.75 419.00,122.40 419.00,122.40
             434.14,119.37 466.55,113.07 481.00,113.00
             481.00,113.00 501.00,112.00 501.00,112.00
             501.00,112.00 535.00,112.00 535.00,112.00
             535.00,112.00 556.00,113.00 556.00,113.00
             571.63,113.19 597.33,117.47 613.00,120.60
             630.09,124.02 657.98,130.99 674.00,137.06
             674.00,137.06 700.00,147.80 700.00,147.80
             717.40,154.81 734.63,162.17 751.00,171.42
             769.90,182.10 787.63,193.60 805.00,206.63
             811.27,211.33 826.50,224.53 833.00,225.83
             841.57,227.54 844.19,226.52 851.00,227.17
             851.00,227.17 874.00,230.00 874.00,230.00
             874.00,230.00 882.00,230.00 882.00,230.00
             882.00,230.00 933.00,239.35 933.00,239.35
             933.00,239.35 956.00,244.63 956.00,244.63
             958.53,245.29 963.43,246.26 964.26,249.17
             965.34,252.97 959.51,253.80 957.00,253.74
             957.00,253.74 941.00,251.47 941.00,251.47
             937.24,251.63 931.03,253.98 920.00,254.00
             920.00,254.00 906.00,254.00 906.00,254.00
             897.06,254.01 897.14,255.11 890.00,255.83
             890.00,255.83 864.00,258.00 864.00,258.00
             864.00,258.00 893.25,292.00 893.25,292.00
             901.31,301.70 901.76,307.15 915.00,307.00
             915.00,307.00 929.00,306.00 929.00,306.00
             929.00,306.00 956.00,306.00 956.00,306.00
             956.00,306.00 984.00,307.91 984.00,307.91
             984.00,307.91 1009.00,309.17 1009.00,309.17
             1009.00,309.17 1039.00,313.15 1039.00,313.15
             1039.00,313.15 1084.00,321.80 1084.00,321.80
             1087.77,322.57 1098.35,323.91 1096.34,329.88
             1095.35,332.84 1091.77,333.75 1089.00,333.87
             1083.58,334.09 1078.64,331.19 1075.00,331.35
             1071.47,331.51 1068.68,333.89 1060.00,334.00
             1060.00,334.00 1013.00,336.17 1013.00,336.17
             1013.00,336.17 1003.00,337.87 1003.00,337.87
             1003.00,337.87 969.00,343.55 969.00,343.55
             969.00,343.55 933.00,350.00 933.00,350.00
             941.36,362.84 953.76,388.55 960.14,403.00
             961.45,405.97 965.44,416.73 967.50,418.23
             968.93,419.28 970.35,419.12 972.00,418.94
             972.00,418.94 992.00,416.17 992.00,416.17
             992.00,416.17 1020.00,413.91 1020.00,413.91
             1020.00,413.91 1032.00,413.00 1032.00,413.00
             1032.00,413.00 1068.00,413.00 1068.00,413.00
             1068.00,413.00 1091.00,414.91 1091.00,414.91
             1106.59,415.98 1112.08,415.87 1128.00,419.00
             1128.00,419.00 1163.00,425.80 1163.00,425.80
             1166.66,426.54 1172.88,427.32 1174.58,431.12
             1178.39,439.64 1161.54,435.90 1158.00,436.04
             1158.00,436.04 1150.00,436.96 1150.00,436.96
             1150.00,436.96 1135.00,436.96 1135.00,436.96
             1128.78,437.08 1126.63,438.02 1121.00,438.71
             1121.00,438.71 1109.00,439.83 1109.00,439.83
             1078.01,442.93 1051.99,447.55 1022.00,456.14
             1022.00,456.14 996.00,464.70 996.00,464.70
             992.82,465.87 987.64,468.14 985.92,471.18
             984.20,474.24 988.36,484.89 989.20,489.00
             989.20,489.00 997.85,537.00 997.85,537.00
             997.85,537.00 1001.00,571.00 1001.00,571.00
             1001.00,571.00 1001.00,642.00 1001.00,642.00
             1000.98,657.14 996.50,683.42 993.92,699.00
             987.10,740.22 975.11,772.88 958.72,811.00
             955.49,818.51 951.36,829.34 946.90,836.00
             944.35,839.80 941.64,843.82 937.00,841.00
             932.82,848.45 925.21,859.76 922.63,867.83
             921.51,871.61 923.13,872.45 922.63,876.00
             922.07,879.07 918.63,885.25 916.94,888.00
             910.44,898.52 892.96,921.60 884.71,931.00
             884.71,931.00 874.17,942.00 874.17,942.00
             840.34,979.39 798.49,1015.04 754.00,1039.23
             754.00,1039.23 699.00,1065.72 699.00,1065.72
             694.86,1067.50 682.36,1073.49 678.43,1070.96
             676.08,1069.45 675.25,1064.61 674.65,1062.00
             674.65,1062.00 669.42,1037.00 669.42,1037.00
             664.63,1010.40 661.04,984.04 661.00,957.00
             661.00,957.00 660.03,943.00 660.03,943.00
             660.03,943.00 661.00,931.00 661.00,931.00
             661.49,888.52 669.39,836.61 682.02,796.00
             682.02,796.00 692.95,765.00 692.95,765.00
             692.95,765.00 698.81,748.00 698.81,748.00
             698.81,748.00 707.44,727.00 707.44,727.00
             709.12,720.63 704.24,716.14 701.06,711.00
             695.63,702.19 695.66,700.61 693.79,691.00
             693.40,689.03 692.65,687.01 692.78,685.00
             692.91,683.06 693.93,680.71 694.82,679.00
             700.46,668.22 710.18,667.01 721.00,669.09
             729.45,671.06 732.88,675.18 738.48,669.09
             738.48,669.09 755.72,643.00 755.72,643.00
             763.66,631.68 782.76,608.01 792.09,598.00
             812.89,575.67 840.52,548.94 865.00,530.88
             904.51,501.75 937.34,485.94 982.00,467.00
             982.00,467.00 973.65,440.00 973.65,440.00
             973.65,440.00 967.00,422.00 967.00,422.00
             967.00,422.00 941.00,428.01 941.00,428.01
             941.00,428.01 902.00,438.34 902.00,438.34
             851.56,454.45 803.26,477.81 760.00,508.42
             734.20,526.68 709.89,547.15 688.04,570.00
             688.04,570.00 665.08,595.00 665.08,595.00
             662.32,598.01 657.25,604.49 654.00,606.36
             650.65,608.29 648.06,606.82 645.00,605.12
             645.00,605.12 628.00,594.42 628.00,594.42
             615.06,586.93 599.31,578.70 584.00,581.93
             582.63,582.22 580.28,582.62 579.09,583.21
             576.01,584.76 573.88,589.18 571.00,592.00
             575.00,596.79 575.21,603.53 579.31,611.00
             584.63,620.68 595.64,633.10 604.00,640.27
             608.25,643.91 618.29,650.89 617.48,657.00
             617.11,659.75 608.40,674.49 606.42,678.00
             606.42,678.00 593.75,703.00 593.75,703.00
             593.75,703.00 585.31,719.00 585.31,719.00
             567.22,758.37 556.67,793.87 547.42,836.00
             535.36,891.00 533.77,956.27 541.28,1012.00
             541.28,1012.00 551.89,1068.00 551.89,1068.00
             554.33,1078.59 558.09,1087.86 559.00,1099.00
             559.00,1099.00 540.00,1100.00 540.00,1100.00
             540.00,1100.00 507.00,1100.00 507.00,1100.00
             504.44,1100.00 500.58,1100.24 498.39,1098.83
             494.37,1096.23 492.90,1085.58 492.00,1081.00
             492.00,1081.00 481.73,1024.00 481.73,1024.00
             481.73,1024.00 480.72,1013.00 480.72,1013.00
             480.72,1013.00 478.04,996.00 478.04,996.00
             478.04,996.00 478.04,986.00 478.04,986.00
             478.04,986.00 477.02,978.00 477.02,978.00
             477.02,978.00 477.02,960.00 477.02,960.00
             477.02,960.00 475.60,944.00 475.60,944.00
             475.60,944.00 477.00,926.00 477.00,926.00
             477.00,926.00 477.00,910.00 477.00,910.00
             477.00,910.00 478.04,900.00 478.04,900.00
             478.04,900.00 478.04,891.00 478.04,891.00
             478.04,891.00 480.72,874.00 480.72,874.00
             480.72,874.00 482.29,857.00 482.29,857.00
             482.29,857.00 485.75,838.00 485.75,838.00
             489.50,816.38 493.92,795.10 500.00,774.00
             511.73,733.31 520.72,708.31 539.26,670.00
             539.26,670.00 558.78,633.00 558.78,633.00
             560.67,629.42 566.73,620.21 567.15,617.00
             567.73,612.62 562.99,607.82 560.90,604.00
             557.73,598.21 556.86,593.51 557.01,587.00
             557.33,573.82 567.99,564.51 581.00,565.04
             589.68,565.39 595.31,569.81 599.96,567.74
             603.06,566.35 606.81,560.75 609.08,558.00
             609.08,558.00 627.29,536.00 627.29,536.00
             643.88,516.82 662.46,499.18 681.00,481.91
             681.00,481.91 691.00,472.17 691.00,472.17
             729.33,437.55 792.26,399.78 840.00,379.43
             840.00,379.43 865.00,369.95 865.00,369.95
             884.89,362.65 909.15,351.81 930.00,349.00
             930.00,349.00 912.95,322.00 912.95,322.00
             911.25,319.28 907.37,312.29 904.82,310.92
             902.71,309.79 888.64,311.86 885.00,312.04
             885.00,312.04 860.00,315.73 860.00,315.73
             860.00,315.73 820.00,322.35 820.00,322.35
             729.65,342.80 653.32,377.11 579.00,432.87
             579.00,432.87 529.00,475.17 529.00,475.17
             524.67,479.09 511.86,493.04 508.00,494.79
             501.87,497.55 492.02,489.23 487.00,486.05
             472.65,476.97 459.26,469.40 442.00,468.00
             442.00,468.00 443.00,456.00 443.00,456.00
             446.24,456.76 452.30,459.42 455.00,458.97
             458.81,458.35 463.32,453.64 466.00,451.00
             466.00,451.00 485.00,432.00 485.00,432.00
             501.31,415.71 538.62,385.41 558.00,372.33
             626.05,326.39 694.33,292.82 774.00,271.88
             796.68,265.92 819.86,262.30 843.00,258.73
             843.00,258.73 858.00,256.00 858.00,256.00
             858.00,256.00 841.00,239.00 841.00,239.00
             834.48,232.49 831.65,228.06 822.00,228.00
             822.00,228.00 770.00,228.00 770.00,228.00
             770.00,228.00 757.00,228.96 757.00,228.96
             757.00,228.96 724.00,231.17 724.00,231.17
             724.00,231.17 699.00,234.72 699.00,234.72
             614.28,245.87 527.79,276.60 454.00,319.42
             431.20,332.65 409.41,347.36 388.00,362.71
             388.00,362.71 370.00,376.42 370.00,376.42
             367.41,378.44 363.09,382.22 360.00,383.02
             354.71,384.39 346.44,377.53 342.00,374.81
             330.81,367.94 319.60,361.02 307.00,357.03
             302.80,355.70 298.40,354.84 294.00,354.48
             294.00,354.48 289.18,354.48 289.18,354.48
             287.27,354.20 287.41,353.92 286.00,353.00
             286.00,353.00 273.00,362.00 273.00,362.00
             273.00,362.00 274.10,364.32 274.10,364.32
             274.10,364.32 274.10,379.00 274.10,379.00
             278.65,381.61 282.60,389.44 286.44,394.00
             286.44,394.00 304.00,411.83 304.00,411.83
             306.66,414.24 311.49,417.81 312.96,421.00
             314.89,425.19 311.75,428.27 309.00,431.00
             309.90,428.94 314.28,422.57 310.67,421.20
             307.50,420.00 298.33,429.75 296.00,431.96
             296.00,431.96 282.00,443.21 282.00,443.21
             273.44,449.95 264.66,457.25 257.01,465.00
             248.24,473.90 248.34,476.30 242.65,486.00
             242.65,486.00 234.09,498.00 234.09,498.00
             234.09,498.00 227.07,511.00 227.07,511.00
             227.07,511.00 212.09,532.00 212.09,532.00
             212.09,532.00 196.25,562.00 196.25,562.00
             196.25,562.00 186.68,587.00 186.68,587.00
             178.70,600.55 171.79,614.33 166.06,629.00
             163.73,634.96 160.02,640.54 161.00,647.00
             161.00,647.00 159.00,646.00 159.00,646.00
             159.44,649.30 159.80,649.98 157.00,652.00
             157.29,657.84 152.66,666.36 150.40,672.00
             150.40,672.00 135.03,713.00 135.03,713.00
             135.03,713.00 120.88,762.00 120.88,762.00
             120.88,762.00 114.58,792.00 114.58,792.00
             114.58,792.00 103.00,854.00 103.00,854.00 Z
           M 426.00,465.00
           C 425.00,468.56 422.00,470.09 419.00,472.00
             419.00,472.00 426.00,465.00 426.00,465.00 Z
           M 428.00,465.00
           C 428.00,465.00 433.00,466.00 433.00,466.00
             433.00,466.00 429.00,468.00 429.00,468.00
             429.00,468.00 428.00,465.00 428.00,465.00 Z
           M 435.00,468.00
           C 435.00,468.00 436.00,468.00 436.00,468.00
             436.00,468.00 435.00,469.00 435.00,469.00
             435.00,469.00 435.00,468.00 435.00,468.00 Z
           M 419.00,473.00
           C 419.00,473.00 421.00,477.00 421.00,477.00
             421.00,477.00 417.00,478.00 417.00,478.00
             417.00,478.00 419.00,473.00 419.00,473.00 Z
           M 423.00,484.00
           C 423.00,484.00 424.00,484.00 424.00,484.00
             424.00,484.00 423.00,485.00 423.00,485.00
             423.00,485.00 423.00,484.00 423.00,484.00 Z
           M 425.00,490.00
           C 425.00,490.00 433.76,504.00 433.76,504.00
             439.18,511.41 446.36,518.61 453.00,524.96
             456.73,528.53 465.64,534.80 466.09,540.00
             466.45,544.20 461.16,549.71 458.73,553.00
             458.73,553.00 436.34,584.00 436.34,584.00
             411.36,620.72 391.57,660.12 374.42,701.00
             374.42,701.00 361.00,738.00 361.00,738.00
             346.32,782.05 336.46,823.74 331.83,870.00
             331.83,870.00 328.91,906.00 328.91,906.00
             328.91,906.00 328.00,918.00 328.00,918.00
             328.00,918.00 328.00,968.00 328.00,968.00
             328.00,968.00 328.91,978.00 328.91,978.00
             328.91,978.00 333.73,1026.00 333.73,1026.00
             333.73,1026.00 334.87,1034.00 334.87,1034.00
             334.86,1038.18 333.88,1038.29 334.87,1043.00
             334.87,1043.00 336.00,1039.00 336.00,1039.00
             335.86,1048.05 337.18,1047.78 337.83,1055.00
             337.99,1056.73 338.17,1059.97 336.98,1061.36
             334.22,1064.58 323.05,1058.28 320.00,1056.75
             320.00,1056.75 298.00,1046.22 298.00,1046.22
             298.00,1046.22 270.00,1030.00 270.00,1030.00
             270.66,1023.10 269.52,1023.84 269.09,1018.00
             269.09,1018.00 268.00,998.00 268.00,998.00
             268.00,998.00 266.00,979.00 266.00,979.00
             266.00,979.00 266.00,957.00 266.00,957.00
             265.91,949.48 264.08,947.94 264.08,943.00
             264.08,937.89 265.98,936.93 266.00,927.00
             266.00,927.00 266.00,906.00 266.00,906.00
             266.03,898.75 266.83,899.09 267.85,893.00
             267.85,893.00 267.85,884.00 267.85,884.00
             267.85,884.00 273.72,837.00 273.72,837.00
             273.72,837.00 275.46,824.00 275.46,824.00
             280.27,797.38 286.82,770.98 294.29,745.00
             294.29,745.00 314.95,684.00 314.95,684.00
             317.01,678.48 321.82,663.13 326.00,660.00
             325.77,657.33 325.30,656.81 328.00,656.00
             327.28,650.76 328.47,649.47 331.00,645.00
             331.00,645.00 333.00,646.00 333.00,646.00
             333.00,646.00 334.00,639.00 334.00,639.00
             334.00,639.00 336.00,640.00 336.00,640.00
             336.00,640.00 335.00,637.00 335.00,637.00
             335.00,637.00 337.00,638.00 337.00,638.00
             337.00,638.00 336.00,635.00 336.00,635.00
             336.00,635.00 345.00,617.00 345.00,617.00
             345.00,617.00 347.00,617.00 347.00,617.00
             347.00,617.00 346.00,615.00 346.00,615.00
             346.00,615.00 347.00,613.00 347.00,613.00
             347.00,613.00 349.00,614.00 349.00,614.00
             349.00,614.00 348.00,611.00 348.00,611.00
             348.00,611.00 350.00,612.00 350.00,612.00
             350.00,612.00 349.00,609.00 349.00,609.00
             349.00,609.00 351.00,610.00 351.00,610.00
             351.00,610.00 350.00,607.00 350.00,607.00
             350.00,607.00 352.00,608.00 352.00,608.00
             352.00,608.00 351.00,605.00 351.00,605.00
             351.00,605.00 353.00,606.00 353.00,606.00
             352.50,596.43 359.68,598.24 365.17,585.00
             365.17,585.00 367.95,576.00 367.95,576.00
             375.45,560.48 407.33,517.74 419.00,502.00
             420.81,504.54 425.09,499.38 426.04,497.42
             427.09,495.27 425.61,492.24 425.00,490.00 Z
           M 416.00,496.00
           C 416.00,496.00 418.00,501.00 418.00,501.00
             418.00,501.00 416.00,496.00 416.00,496.00 Z
           M 419.00,505.00
           C 419.00,505.00 418.00,505.00 418.00,505.00
             418.00,505.00 419.00,506.00 419.00,506.00
             419.00,506.00 419.00,505.00 419.00,505.00 Z
           M 368.00,579.00
           C 368.00,579.00 367.00,579.00 367.00,579.00
             367.00,579.00 368.00,580.00 368.00,580.00
             368.00,580.00 368.00,579.00 368.00,579.00 Z
           M 365.00,581.00
           C 365.00,581.00 363.00,585.00 363.00,585.00
             363.00,585.00 365.00,581.00 365.00,581.00 Z
           M 362.00,586.00
           C 362.00,586.00 360.00,590.00 360.00,590.00
             360.00,590.00 362.00,586.00 362.00,586.00 Z
           M 356.00,599.00
           C 356.00,599.00 355.00,599.00 355.00,599.00
             355.00,599.00 356.00,600.00 356.00,600.00
             356.00,600.00 356.00,599.00 356.00,599.00 Z
           M 355.00,601.00
           C 355.00,601.00 354.00,601.00 354.00,601.00
             354.00,601.00 355.00,602.00 355.00,602.00
             355.00,602.00 355.00,601.00 355.00,601.00 Z
           M 354.00,603.00
           C 354.00,603.00 353.00,603.00 353.00,603.00
             353.00,603.00 354.00,604.00 354.00,604.00
             354.00,604.00 354.00,603.00 354.00,603.00 Z
           M 333.00,641.00
           C 333.00,641.00 334.00,641.00 334.00,641.00
             334.00,641.00 333.00,642.00 333.00,642.00
             333.00,642.00 333.00,641.00 333.00,641.00 Z
           M 332.00,643.00
           C 332.00,643.00 333.00,643.00 333.00,643.00
             333.00,643.00 332.00,644.00 332.00,644.00
             332.00,644.00 332.00,643.00 332.00,643.00 Z
           M 161.00,644.00
           C 161.00,644.00 162.00,644.00 162.00,644.00
             162.00,644.00 161.00,645.00 161.00,645.00
             161.00,645.00 161.00,644.00 161.00,644.00 Z
           M 331.00,648.00
           C 331.00,648.00 330.00,648.00 330.00,648.00
             330.00,648.00 331.00,649.00 331.00,649.00
             331.00,649.00 331.00,648.00 331.00,648.00 Z
           M 330.00,650.00
           C 330.00,650.00 329.00,650.00 329.00,650.00
             329.00,650.00 330.00,651.00 330.00,651.00
             330.00,651.00 330.00,650.00 330.00,650.00 Z
           M 327.00,657.00
           C 327.00,657.00 326.00,657.00 326.00,657.00
             326.00,657.00 327.00,658.00 327.00,658.00
             327.00,658.00 327.00,657.00 327.00,657.00 Z" 
							fill={"black"}/>

						</SonarMuseImg>
					</SonarMuseLoader>
					{text && <SonarMuseLoader1>
					{/*<SonarMuseImg1 style={{position: 'relative'}} src={invertedLogo2}/>*/}
					{text}
					</SonarMuseLoader1>}
					</LoaderWrapper>
            </BackgroundWrapper>
	)
}