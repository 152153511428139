import React, { useEffect, useState } from "react";
import { AiOutlineEye } from "react-icons/ai";
import { BsThreeDots } from "react-icons/bs";
import {
  FaDownload,
  FaPause,
  FaPlay,
  FaRegMoneyBillAlt,
  FaShare,
} from "react-icons/fa";
import { GoReport } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import useArtist from "../../hooks/useArtist";
import { useUser } from "../../hooks/useUser";
import {
  ALERT_TYPES,
  CONTENT_TYPES,
  MINTING,
  MUSIC_PLAYING_FROM,
  MUSIC_PURCHASE_OPTIONS,
  SLIDER_TYPES,
  TRANSACTION_OPTIONS,
} from "../../utils/Enum";
import ImageUtils from "../../utils/ImageUtils";
import {
  convertAlbumsToSonarMuseSchema,
  convertAlbumToSonarMuseSchema,
  convertSongsToSonarMuseSchema,
  getAddressName,
  getIPFSFile,
  getNFTImage,
  getNFTokenFlagType,
} from "../../utils/nfTokensUploads";
import ContentList from "../ContentList";
import {
  CollectionSongList,
  MusicStoreSongList,
} from "../ContentList/SongList";
import ContentWrapper from "../ContentWrapper";
import { FormText } from "../CustomForm/CustomFormElements";
import { HoverOption } from "../HoverMenu/HoverMenuElements";
import MenuOptions from "../MenuOptions";
import { useModal } from "../Modal/ModalContext";
import { NavContainer } from "../NavLink/NavLinkElements";
import OptionModal from "../OptionModal";
import ScrollToTop from "../ScrollToTop";
import { Slider } from "../Slider";
import MusicInfo from "./MusicInfo";
import NFTokenInfo from "./MusicInfo/NFTokenInfo";
import {
  AlbumArt,
  AlbumArtContainer,
  AlbumArtWrapper,
  DetailsWrapper,
  Img,
  MusicAboutWrapper,
  MusicContentWrapper,
  MusicDetailsContainer,
  MusicDetailsWrapper,
  MusicHeaderWrapper,
  MusicHeading,
  MusicHeadingBgImage,
  MusicHeadingContainer,
  MusicHeadingImage,
  MusicHeadingImageWrapper,
  MusicHeadingSmall,
  MusicHeadingTextWrapper,
  MusicHeadingWrapper,
  MusicInfoWrapper,
  MusicNav,
  MusicNavLink,
  MusicOptionsContainer,
  MusicPageContainer,
  MusicPageWrapper,
  MusicPlayerWrapper,
  MusicPlayPauseButton,
  MusicPriceWrapper,
  MusicSubheading,
  NftBadgeWrapper,
  NftLogo,
  Option,
  OptionButton,
  OptionMenuButton,
  OptionText,
  PurchaseButton,
  Select,
  SongDetailsText,
  SongDetailsWrapper,
  SubHeading,
  Text,
} from "./MusicPageElements";
import useMusic from "../../hooks/useMusic";
import { downloadFiles } from "../../utils/FileUploadsUtils";
import { useAlert } from "../Alert/AlertContext";
import ProgressBar from "../ProgressBar";
import QRCodeForm from "../QRCodeForm";

const NFTokenPage = ({
  tokenInfo,
  isSongPlaying,
  stopMainPlayer,
  deleteSong,
  hasPurchased,
  currentTab,
  setTab,
}) => {
  const navigate = useNavigate();
  const { modalContent, toggleModal, openModal, closeModal } = useModal();
  const { getArtistName } = useArtist({});
  const { downloadMusic } = useMusic({});
  const { addAlert } = useAlert();
  const { goTo } = ScrollToTop();
  const { defaultAlbumArt, NftBadge } = ImageUtils();
  const [songs, setSongs] = useState([]);
  const { currentUser } = useAuth();
  const { musicOptionsModal } = MenuOptions();
  const [artistName, setArtistName] = useState(
    tokenInfo?.nftoken?.issuer ? tokenInfo?.nftoken?.issuer : ""
  );
  const [tab, setNftTab] = useState(
    tokenInfo?.collection &&
      !tokenInfo.nftoken?.is_burned &&
      (tokenInfo?.collection?.issuer === currentUser?.uid ||
        tokenInfo?.nftoken?.owner === currentUser?.uid)
      ? currentTab
      : "details"
  );

  useEffect(() => {
    getSongs();
    return () => {};
  }, [songs?.length === 0]);

  useEffect(() => {
    displayArtistName();
    return () => {};
  }, [tokenInfo?.nftoken?.issuer]);

  const downloadNFTokenMusic = async () => {
    addAlert({
      title: "Starting Download",
      type: ALERT_TYPES.INFO,
      message: `Processing download. Please wait.`,
    });
    let content = await downloadMusic(
      currentUser?.uid,
      CONTENT_TYPES.NFTOKEN,
      tokenInfo?.nftoken?.nft_id
    );
    await downloadFiles(content);
    addAlert({
      title: "Album Download",
      type: ALERT_TYPES.SUCCESS,
      message: `Downloading Album`,
    });
  };

  const displayArtistName = async () => {
    return getArtistName(tokenInfo?.nftoken?.issuer)
      .then(async (aName) => {
        if (aName) {
          setArtistName(aName);
          return;
        } else {
          let issuerName = await getAddressName(tokenInfo?.nftoken?.issuer);
          setArtistName(issuerName);
          return;
        }
      })
      .catch((err) => {
        setArtistName(tokenInfo?.nftoken?.issuer);

        console.error(err);
        return;
      });
  };

  const getSongs = () => {
    let s = [];
    if (tokenInfo?.nftoken?.metadata?.type?.includes("music")) {
      switch (tokenInfo?.nftoken?.metadata?.mintType) {
        case MINTING.TYPE.SINGLE:
          s = convertSongsToSonarMuseSchema(
            tokenInfo?.nftoken?.metadata?.songs
          )?.map((s) => {
            return {
              ...s,
              playingFrom: {
                playingFrom: MUSIC_PLAYING_FROM.NFT,
                id: tokenInfo?.nftoken?.collectionId,
              },
            };
          });
          setSongs(s);

          break;
        case MINTING.TYPE.ALBUM:
          s = convertAlbumsToSonarMuseSchema(
            tokenInfo?.nftoken?.metadata?.albums
          )[0]?.songs.map((song) => {
            return {
              ...song,
              playingFrom: {
                playingFrom: MUSIC_PLAYING_FROM.NFT,
                id: tokenInfo?.nftoken?.collectionId,
              },
            };
          });
          setSongs(s);

          break;
        case MINTING.TYPE.CUSTOM:
          if (
            tokenInfo?.nftoken?.metadata?.songs.length > 0 &&
            !tokenInfo?.nftoken?.metadata?.albums
          ) {
            setSongs(
              convertSongsToSonarMuseSchema(
                tokenInfo?.nftoken?.metadata?.songs
              )?.map((s) => {
                return {
                  ...s,
                  playingFrom: {
                    playingFrom: MUSIC_PLAYING_FROM.NFT,
                    id: tokenInfo?.nftoken?.collectionId,
                  },
                };
              })
            );
          }
          if (
            tokenInfo?.nftoken?.metadata?.songs.length > 0 &&
            tokenInfo?.nftoken?.metadata?.albums?.length > 0
          ) {
            setSongs(
              convertSongsToSonarMuseSchema(
                tokenInfo?.nftoken?.metadata?.songs
              )?.map((s) => {
                return {
                  ...s,
                  playingFrom: {
                    playingFrom: MUSIC_PLAYING_FROM.NFT,
                    id: tokenInfo?.nftoken?.collectionId,
                  },
                };
              })
            );
          }
          if (
            !tokenInfo?.nftoken?.metadata?.songs &&
            tokenInfo?.nftoken?.metadata?.albums?.length > 0
          ) {
            s = convertAlbumsToSonarMuseSchema(
              tokenInfo?.nftoken?.metadata?.albums
            )[0]?.songs.map((song) => {
              return {
                ...song,
                playingFrom: {
                  playingFrom: MUSIC_PLAYING_FROM.NFT,
                  id: tokenInfo?.nftoken?.collectionId,
                },
              };
            });
            setSongs(s);
          }
          break;

        default:
          break;
      }
    }
  };

  const playSong = (i) => {
    let songs =
      tokenInfo?.nftoken?.metadata?.songs.length > 0
        ? convertSongsToSonarMuseSchema(tokenInfo?.nftoken?.metadata?.songs)
        : [];
    let albums =
      tokenInfo?.nftoken?.metadata?.albums?.songs.length > 0
        ? convertSongsToSonarMuseSchema(
            tokenInfo?.nftoken?.metadata?.albums.songs
          )
        : [];
    let playingFrom = {
      playingFrom: MUSIC_PLAYING_FROM.NFT,
      id: tokenInfo?.nftoken?.nft_id,
    };
  };

  const playMusic = () => {
    let playingFrom = {
      playingFrom: MUSIC_PLAYING_FROM.NFT,
      id: tokenInfo?.nftoken?.collectionId,
    };

    if (tokenInfo?.nftoken?.metadata?.mintType !== MINTING.TYPE.CUSTOM) {
      togglePlay();
      return;
    } else {
      openModal();
      modalContent(
        <>
          <OptionModal heading={"Select Music to Play from NFT"}>
            {tokenInfo?.nftoken?.metadata?.songs?.length > 0 &&
              tokenInfo?.nftoken?.metadata?.albums?.length > 0 && (
                <>
                  {" "}
                  <HoverOption
                    onClick={() => {
                      let s = convertSongsToSonarMuseSchema(
                        tokenInfo?.nftoken?.metadata?.songs
                      ).map((song) => {
                        return {
                          ...song,
                          playingFrom: {
                            playingFrom: MUSIC_PLAYING_FROM.NFT,
                            id: tokenInfo?.nftoken?.collectionId,
                          },
                        };
                      });
                      localStorage.setItem(
                        "isPlayingFrom",
                        JSON.stringify(playingFrom)
                      );
                      setSongs(s);
                      closeModal();
                    }}
                  >
                    Play Songs
                  </HoverOption>
                  <HoverOption
                    onClick={() => {
                      albumSelector(tokenInfo?.nftoken?.metadata?.albums);
                    }}
                  >
                    Play Albums
                  </HoverOption>
                  <HoverOption onClick={() => playAllMusic()}>
                    Play All Music
                  </HoverOption>
                  <HoverOption onClick={() => closeModal()}>Cancel</HoverOption>
                </>
              )}
            {tokenInfo?.nftoken?.metadata?.albums?.length > 1 &&
              albumSelector(tokenInfo?.nftoken?.metadata?.albums)}
          </OptionModal>
        </>
      );
    }
  };

  const playAlbum = (album) => {
    let s = [];
    let playingFrom = {
      playingFrom: MUSIC_PLAYING_FROM.NFT,
      id: tokenInfo?.nftoken?.collectionId,
    };
    album.songs.map((s) => {
      s.push(s);
      return s;
    });
    setSongs(s);
    localStorage.setItem("isPlayingFrom", JSON.stringify(playingFrom));
  };

  const playAllMusic = () => {
    let allMusic = [];
    let playingFrom = {
      playingFrom: MUSIC_PLAYING_FROM.NFT,
      id: tokenInfo?.nftoken?.collectionId,
    };
    let s = convertSongsToSonarMuseSchema(
      tokenInfo?.nftoken?.metadata?.songs
    ).map((song) => {
      return {
        ...song,
        playingFrom: playingFrom,
      };
    });
    allMusic.push(...s);
    convertAlbumsToSonarMuseSchema(tokenInfo?.nftoken?.metadata?.albums).map(
      (album) => {
        return album.songs.map((song) => {
          allMusic.push({
            ...song,
            playingFrom: playingFrom,
          });
          return song;
        });
      }
    );
    setSongs(allMusic);
    localStorage.setItem("isPlayingFrom", JSON.stringify(playingFrom));
    closeModal();
  };

  const togglePlay = () => {};

  const isPlayingFrom = () => {
    if (
      JSON.parse(localStorage.getItem("isPlayingFrom"))?.playingFrom ===
        MUSIC_PLAYING_FROM.NFT &&
      JSON.parse(localStorage.getItem("isPlayingFrom"))?.id ===
        tokenInfo?.nftoken?.nft_id
    ) {
      return true;
    }
    return false;
  };

  const albumSelector = (albums) => {};

  const burnToken = () => {
    openModal();
    modalContent(
      <QRCodeForm
        type={TRANSACTION_OPTIONS.NFTOKEN_BURN}
        heading={`Burning ${
          tokenInfo?.nftoken?.metadata
            ? tokenInfo?.nftoken?.metadata?.name
              ? tokenInfo?.nftoken?.metadata?.name
              : tokenInfo?.nftoken?.metadata?.collection
              ? tokenInfo?.nftoken?.metadata?.collection?.name
              : `${tokenInfo?.nftoken?.nft_id?.substring(
                  0,
                  5
                )}...${tokenInfo?.nftoken?.nft_id?.substring(
                  tokenInfo?.nftoken?.nft_id?.length - 5,
                  tokenInfo?.nftoken?.nft_id?.length - 1
                )}`
            : `${tokenInfo?.nftoken?.nft_id?.substring(
                0,
                5
              )}...${tokenInfo?.nftoken?.nft_id?.substring(
                tokenInfo?.nftoken?.nft_id?.length - 5,
                tokenInfo?.nftoken?.nft_id?.length - 1
              )}`
        }
`}
        body={{ sender: currentUser?.uid, tokenId: tokenInfo?.nftoken?.nft_id }}
        cancelQr={() => {
          toggleModal();
          modalContent();
        }}
        onFail={() => {
          toggleModal();
          modalContent(
            <OptionModal>
              <HoverOption
                onClick={() => {
                  burnToken();
                }}
              >
                Try Again
              </HoverOption>
              <HoverOption
                onClick={() => {
                  closeModal();
                }}
              >
                OK
              </HoverOption>
            </OptionModal>
          );
        }}
        onSuccess={() => {
          goTo("/home");
          modalContent(
            <OptionModal>
              <HoverOption
                onClick={() => {
                  closeModal();
                }}
              >
                OK
              </HoverOption>
            </OptionModal>
          );
        }}
      />
    );
  };

  /* const getSongs = () => {
    let albums = [];
    if (tokenInfo?.nftoken?.metadata?.albums?.length > 0) {
      convertAlbumsToSonarMuseSchema(tokenInfo?.nftoken?.metadata?.albums)
        ?.filter((a) => a?.songs?.length > 0)
        .map((a) => {
          return albums.push(...a?.songs);
        });
    }

    let songs = tokenInfo?.nftoken?.metadata?.songs
      ? convertSongsToSonarMuseSchema(tokenInfo?.nftoken?.metadata?.songs)?.map(
          (s) => s
        )
      : [];
    let music = [...albums, ...songs];
    //console.log(music);
    return music.map((s) => {
      return {
        ...s,
        playingFrom: {
          playingFrom: MUSIC_PLAYING_FROM.NFT,
          id: tokenInfo?.nftoken?.nft_id,
        },
      };
    });
  };
 */
  const playSongs = (i) => {};

  const displayNFTokenFlag = (flag) => {
    switch (flag) {
      case 8:
        return (
          <>
            {` ${MINTING.FLAG.TRANSFERABLE}`}
            <FormText>
              <div style={{ fontStyle: "italic", fontSize: "0.8rem" }}>
                {MINTING.FLAG_DESCRIPTION.TRANSFERABLE}
              </div>
            </FormText>
          </>
        );
      case 1:
        return (
          <>
            {` ${MINTING.FLAG.BURNABLE}`}
            <FormText>
              <div style={{ fontStyle: "italic", fontSize: "0.8rem" }}>
                {MINTING.FLAG_DESCRIPTION.BURNABLE}
              </div>
            </FormText>
          </>
        );

      case 2:
        return (
          <>
            {` ${MINTING.FLAG.ONLY_XRP}`}
            <FormText>
              <div style={{ fontStyle: "italic", fontSize: "0.8rem" }}>
                {MINTING.FLAG_DESCRIPTION.ONLY_XRP}
              </div>
            </FormText>
          </>
        );

      case 4:
        return (
          <>
            {` ${MINTING.FLAG.TRUSTLINE}`}
            <FormText>
              <div style={{ fontStyle: "italic", fontSize: "0.8rem" }}>
                {MINTING.FLAG_DESCRIPTION.TRUSTLINE}
              </div>
            </FormText>
          </>
        );

      default:
        return (
          <>
            {` ${MINTING.FLAG.TRANSFERABLE}`}
            <FormText>
              <div style={{ fontStyle: "italic", fontSize: "0.8rem" }}>
                {MINTING.FLAG_DESCRIPTION.TRANSFERABLE}
              </div>
            </FormText>
          </>
        );
    }
  };

  return (
    <>
      <MusicPageWrapper>
        <MusicHeadingContainer>
          <MusicHeadingBgImage
            src={
              tokenInfo?.collection
                ? tokenInfo?.collection?.cover?.url
                : tokenInfo?.nftoken?.metadata?.image
                ? getNFTImage(tokenInfo?.nftoken?.metadata?.image)
                : defaultAlbumArt
            }
            color={
              tokenInfo?.nftoken?.is_burned
                ? "red"
                : tokenInfo?.nftoken?.metadata?.color
            }
          ></MusicHeadingBgImage>
          <MusicHeadingWrapper>
            <MusicHeadingImageWrapper>
              <MusicHeadingImage
                src={
                  tokenInfo?.nftoken?.metadata?.image
                    ? getNFTImage(tokenInfo?.nftoken?.metadata?.image)
                    : defaultAlbumArt
                }
                color={
                  tokenInfo?.nftoken?.is_burned
                    ? "red"
                    : tokenInfo?.nftoken?.metadata?.color
                }
              />
            </MusicHeadingImageWrapper>
            <MusicHeadingTextWrapper>
              <SubHeading>
                <small>
                  NFToken
                  {tokenInfo?.nftoken?.metadata?.mintType
                    ? `: ${tokenInfo?.nftoken?.metadata?.mintType}`
                    : ""}
                </small>
                <NftBadgeWrapper>
                  <NftLogo src={NftBadge} alt={"NFT"} />
                </NftBadgeWrapper>
              </SubHeading>
              <MusicHeading
                style={{ color: tokenInfo?.nftoken?.is_burned && "red" }}
              >
                {`${
                  tokenInfo.collection?.name
                    ? tokenInfo.collection?.name
                    : tokenInfo?.nftoken?.metadata?.name
                    ? tokenInfo?.nftoken?.metadata?.name
                    : tokenInfo?.nftoken?.metadata?.collection?.name
                    ? tokenInfo?.nftoken?.metadata?.collection?.name
                    : tokenInfo?.nftoken?.nft_id
                } ${
                  tokenInfo?.nftoken?.owner === currentUser?.uid
                    ? `[You Own This]`
                    : ""
                } `}{" "}
                <MusicHeadingSmall>
                  {tokenInfo?.nftoken?.is_burned && ` (Token Burned)`}
                </MusicHeadingSmall>
              </MusicHeading>
              <MusicSubheading>
                <MusicHeading
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate(`/u/${tokenInfo?.nftoken?.issuer}`)}
                >
                  {artistName}
                </MusicHeading>
              </MusicSubheading>
              <MusicHeadingSmall>
                {tokenInfo?.nftoken?.metadata?.type
                  ? tokenInfo?.nftoken?.metadata?.type
                  : tokenInfo?.nftoken?.metadata?.nftType
                  ? tokenInfo?.nftoken?.metadata?.nftType
                  : "N/A"}
                •{getNFTokenFlagType(tokenInfo?.nftoken?.flags)}
              </MusicHeadingSmall>
            </MusicHeadingTextWrapper>
          </MusicHeadingWrapper>
        </MusicHeadingContainer>
        {tokenInfo?.collection?.tokenSupply &&
          tokenInfo?.collection?.amountSold > 0 && (
            <>
              {" "}
              <small style={{ textAlign: "center" }}>
                {parseFloat(
                  (tokenInfo?.collection?.amountSold /
                    tokenInfo?.collection?.tokenSupply) *
                    100
                )}
                % of {tokenInfo?.collection?.tokenSupply} NFTs Sold
              </small>
              <ProgressBar
                width={
                  (tokenInfo?.collection?.amountSold /
                    tokenInfo?.collection?.tokenSupply) *
                  100
                }
              />
            </>
          )}
        <MusicInfoWrapper>
          <MusicAboutWrapper>
            {tokenInfo?.collection?.description}
          </MusicAboutWrapper>
          {tokenInfo?.collection?.price && (
            <Text>
              <FaRegMoneyBillAlt style={{ marginRights: "1%" }} />
              Price: {tokenInfo?.collection?.price}{" "}
              {tokenInfo?.collection?.currency
                ? tokenInfo?.collection.currency
                : "WYM"}
            </Text>
          )}
        </MusicInfoWrapper>
        <MusicInfoWrapper>
          {(tokenInfo?.nftoken?.metadata?.albums?.length > 0 ||
            tokenInfo?.nftoken?.metadata?.songs?.length > 0 ||
            tokenInfo?.collection) &&
            !tokenInfo?.nftoken?.is_burned &&
            currentUser?.uid === tokenInfo?.nftoken?.owner && (
              <>
                
              </>
            )}
        </MusicInfoWrapper>
        <NavContainer>
          <MusicNav>
            {(tokenInfo?.collection ||
              tokenInfo?.nftoken?.metadata?.songs?.length > 0 ||
              tokenInfo?.nftoken?.metadata?.albums?.length > 0) &&
              !tokenInfo?.nftoken?.is_burned &&
              (tokenInfo?.collection?.issuer === currentUser?.uid ||
                tokenInfo?.nftoken?.owner === currentUser?.uid) && (
                <MusicNavLink
                  onClick={() => {
                    setTab("music");
                    setNftTab("music");
                  }}
                  active={tab === "music"}
                >
                  Music
                </MusicNavLink>
              )}

            <MusicNavLink
              onClick={() => {
                setTab("details");
                setNftTab("details");
              }}
              active={tab === "details"}
            >
              NFToken Details
            </MusicNavLink>

            {tokenInfo?.collection && (
              <MusicNavLink
                onClick={() => {
                  setTab("recommendations");
                  setNftTab("recommendations");
                }}
                active={tab === "recommendations"}
              >
                Recommendations
              </MusicNavLink>
            )}
          </MusicNav>
        </NavContainer>
        {tab === "music" &&
          (tokenInfo?.collection?.issuer === currentUser?.uid ||
            tokenInfo?.nftoken?.owner === currentUser?.uid) && (
            <MusicContentWrapper>
              {songs?.length > 0 && (
                <ContentWrapper heading={"Current NFT Playlist"}>
                  <CollectionSongList
                    onButtonClick={playSong}
                    type={CONTENT_TYPES.SONG}
                    content={songs}
                  />
                </ContentWrapper>
              )}
            </MusicContentWrapper>
          )}

        {tab === "recommendations" && (
          <>
            {tokenInfo?.collection && (
              <MusicContentWrapper>
                <ContentWrapper
                  heading={`Similar Collections to ${tokenInfo?.collection?.name}`}
                >
                  <Slider
                    type={SLIDER_TYPES.COLLECTION}
                    id={"similar collections"}
                    slides={[1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 1, 1, 1, 1]}
                  />
                </ContentWrapper>
              </MusicContentWrapper>
            )}

            <MusicContentWrapper>
              <ContentWrapper heading={`Trending Collections`}>
                <Slider
                  type={SLIDER_TYPES.COLLECTION}
                  id={"trending collections"}
                  slides={[1, 2, 3, 4, 5, 6, 7]}
                />
              </ContentWrapper>
            </MusicContentWrapper>
          </>
        )}

        {tab === "details" && (
          <>
            {" "}
            <MusicInfo
              type={CONTENT_TYPES.NFTOKEN}
              content={tokenInfo?.nftoken}
            />
            {tokenInfo?.nftoken?.metadata?.mintType && (
              <ContentWrapper
                heading={`Available Music from NFToken ${
                  tokenInfo?.nftoken?.metadata?.name
                    ? tokenInfo?.nftoken?.metadata?.name
                    : tokenInfo?.nftoken?.metadata?.collection?.name
                    ? tokenInfo?.nftoken?.metadata?.collection?.name
                    : tokenInfo?.nftoken?.nft_id
                }`}
              >
                {tokenInfo?.nftoken?.metadata?.albums?.length > 0 && (
                  <ContentWrapper heading={`Albums`}>
                    {convertAlbumsToSonarMuseSchema(
                      tokenInfo?.nftoken?.metadata?.albums
                    ).map((album, index) => {
                      return (
                        <ContentWrapper
                          heading={album?.albumName}
                          subheading={"Play"}
                          onButtonClick={() => {}}
                        >
                          <MusicStoreSongList
                            onButtonClick={() => {}}
                            type={CONTENT_TYPES.SONG}
                            content={album?.songs.map((s) => {
                              return {
                                ...s,
                                playingFrom: {
                                  playingFrom: MUSIC_PLAYING_FROM.NFT,
                                  id: tokenInfo?.nftoken?.nft_id,
                                },
                              };
                            })}
                          />
                        </ContentWrapper>
                      );
                    })}
                  </ContentWrapper>
                )}

                {tokenInfo?.nftoken?.metadata?.songs?.length > 0 && (
                  <ContentWrapper heading={"Available Songs"}>
                    <MusicStoreSongList
                      onButtonClick={() => {}}
                      type={CONTENT_TYPES.SONG}
                      content={tokenInfo?.nftoken?.metadata?.songs}
                    />
                  </ContentWrapper>
                )}
              </ContentWrapper>
            )}
          </>
        )}
      </MusicPageWrapper>
    </>
  );
};

export default NFTokenPage;
