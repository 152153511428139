import { Client, xrpToDrops, dropsToXrp, Wallet } from "xrpl";
import  { datetoRippleEpoch,strToHex } from "."
import  {PAYMENT_WALLETS, SECRETS, SERVERS } from "./Enum"


export const accAddressInfo = async (address) => {
    const client = new Client(SERVERS.MAINNET[0]);
    const userAddress = address;
    await client.connect();
    let response = await client.request({
      command: "account_info",
      account: userAddress,
      ledger_index: "validated",
    });
    let email = response?.result?.account_data?.EmailHash;
    await client.request({
      command: "subscribe",
      streams: ["ledger"],
    });
    client.on("ledgerClosed", async (ledger) => {
      
    });
  
    // Disconnect when done so Node.js can end the process
  
    if (email) {
      //console.log('user', email)
      //console.log(`email: ${email}, url: ${response.urlgravatar}`)
      await client.disconnect();
      return {
        account: response.result.account_data,
        email: email.toLowerCase(),
        gravatarUrl: response.result.account_data.urlgravatar,
      };
    } else {
      await client.disconnect();
      return false;
    }
  };

export const getTickets = async (secret) => {
    let wallet = Wallet.fromSeed(secret);

    const client = new Client(SERVERS.MAINNET[0]);
    let tickets = [];
    await client.connect();
    try {
      let resp = await client.request({
        command: "account_objects",
        account: wallet.classicAddress,
        ledger_index: "validated",
        type: "ticket",
      });
  
      // Create a function to run on each API call.
      /* function printLedgerResult(){
      //console.log(resp["result"])
    } */
      const ledger_data_index = resp["result"]["ledger_index"];
      // Execute function at least once before checking for markers.
      do {
        //printLedgerResult()
  
        if (resp["result"]["marker"] === undefined) {
          break;
        }
  
        // Specify the same ledger and add the marker to continue querying.
        const ledger_marker = await client.request({
          command: "account_objects",
          ledger_index: ledger_data_index,
          account: wallet.classicAddress,
          marker: resp["result"]["marker"],
          type: "ticket",
        });
        tickets.push(
          ...ledger_marker?.result?.account_objects.map((t) => {
            return t?.TicketSequence;
          })
        );
        resp = ledger_marker;
      } while (true);
  
      await client.disconnect();
      return tickets;
    } catch (err) {
      //console.error(err);
      await client.disconnect();
      return false;
    }
  };

  export const getTicketsFromTx = async (tx) => {
    const client = new Client(SERVERS.MAINNET[0]);
    await client.connect();
    try {
      const resp = await client.request({
        command: "tx",
        transaction: tx,
        binary: false,
      });
     await client.disconnect();
      let tickets = [];
      for (let i = 0; i < resp?.result?.meta?.AffectedNodes?.length; i++) {
        if (
          resp?.result?.meta?.AffectedNodes[i]?.CreatedNode?.NewFields
            ?.TicketSequence
        ) {
          tickets.push(
            resp?.result?.meta?.AffectedNodes[i]?.CreatedNode?.NewFields
              ?.TicketSequence
          );
        }
      }
  
      
      return tickets;
    } catch (err) {
      await client.disconnect();
      //console.error(err);
      return false;
    }
  };

export const getNumberOfTickets = async (wallet) => {
    let ticketNum = await accAddressInfo(wallet);
    return ticketNum?.account?.TicketCount;
  };

  export const createTickets = async (secret) => {
    let wallet = Wallet.fromSeed(secret);
    let availTickets = await getNumberOfTickets(wallet.classicAddress);
    const client = new Client(SERVERS.MAINNET[0]);
    await client.connect();
    if (availTickets === 250) {
      return false;
    } else {
      if (parseInt(availTickets) >= 0) {
      } else {
        availTickets = 0;
      }
      // Prepare transaction -------------------------------------------------------
      let transactionBlob = {
        TransactionType: "TicketCreate",
        Account: wallet.classicAddress,
        TicketCount: 250 - availTickets,
      };
      try {
        // Submit signed blob --------------------------------------------------------
        const tx = await client.submitAndWait(transactionBlob, { wallet });
        let tickets = await getTicketsFromTx(tx?.result?.hash);
        client.disconnect();
        return tickets;
      } catch (err) {
        //console.error(err);
        return false;
      }
    }
  };
  

export const getSequences = async (num, secret) => {
    let wallet = Wallet.fromSeed(secret);
    let availTickets = await getNumberOfTickets(wallet.classicAddress);
    if (parseInt(availTickets) < num && parseInt(availTickets) <= 50) {
      await createTickets(secret);
    }
    let sequences = await getTickets(secret);
    if (sequences.length > 0) {
      return sequences;
    } else {
      return false;
    }
  };

  export const makeChannelPayout = async (blob) => {
    const wallet = Wallet.fromSeed(
      SECRETS.MAIN_NET_SUBSCRIPTION_MICROPAYMENTS_SECRET
    );
    const sequences = await getSequences(
      1,
      SECRETS.MAIN_NET_SUBSCRIPTION_MICROPAYMENTS_SECRET
    );
    const client = new Client(SERVERS.MAINNET[0]);
    await client.connect();
    let transactionBlob = {
      Account: wallet.classicAddress,
      TransactionType: "PaymentChannelFund",
      Channel: blob.paymentChannel,
      Amount: blob.amount,
      SourceTag: 510162502,
      Expiration: datetoRippleEpoch(
        `${new Date(new Date().setFullYear(new Date().getFullYear() + 1))}`
      ),
      Memos: [
        {
          Memo: {
            MemoData: strToHex(
              `Sonar Muse Swerl: Payout for a stream via the Sonar Muse Platform`
            ),
          },
        },
      ],
    };
    if (sequences?.length > 0) {
      transactionBlob = {
        ...transactionBlob,
        TicketSequence: sequences[0],
        Sequence: 0,
      };
    }
    try {
      // Submit signed blob --------------------------------------------------------
      const tx = await client.submitAndWait(transactionBlob, { wallet });
      await client.disconnect();
      return tx;
    } catch (err) {
      //console.error(err);
      await client.disconnect();
      return false;
    }
  };

  export const getPaymentChannelInfo = async (channel, uid) => {
    const client = new Client(SERVERS.MAINNET[0]);
    let channels = [];
    await client.connect();
    try {
      let resp = await client.request({
        id: 1,
        command: "account_channels",
        account: PAYMENT_WALLETS.SUBSCRIPTION_MICROPAYMENTS,
        destination_account: uid,
        ledger_index: "validated",
      });
      //console.log(PAYMENT_WALLETS.SUBSCRIPTION_MICROPAYMENTS, uid)
      // Create a function to run on each API call.
      function printLedgerResult() {
        //console.log(resp["result"]);
      }
      const ledger_data_index = resp["result"]["ledger_index"];
      // Execute function at least once before checking for markers.
      do {
        //printLedgerResult();
  
        if (resp["result"]["marker"] === undefined) {
          break;
        }
  
        // Specify the same ledger and add the marker to continue querying.
        const ledger_marker = await client.request({
          command: "account_channels",
          account: PAYMENT_WALLETS.SUBSCRIPTION_MICROPAYMENTS,
          destination_account: uid,
          ledger_index: ledger_data_index,
          marker: resp["result"]["marker"],
          ledger_index: "validated",
        });
        channels.push(...ledger_marker?.result?.channels);
        resp = ledger_marker;
      } while (true);
      //console.log(channels.filter((c) => c?.channel_id === channel));
      client.disconnect();
      if (channels?.length > 0) {
        return channels.filter((c) => c?.channel_id === channel)[0];
      } else {
        return false;
      }
    } catch (err) {
      //console.error(err);
      return false;
    }
  };
  
  export const getPaymentChannelAmount = async (channel, uid) => {
    return await getPaymentChannelInfo(channel, uid)
      .then(async (txInfo) => {
        //console.log(txInfo?.amount);
        if (txInfo) {
          return txInfo?.amount;
        }
        return false;
      })
      .catch((err) => {
        return false;
      });
  };

export const userChannelFunds = async (channel, uid) => {
  return await getPaymentChannelInfo(channel, uid)
      .then(async (txInfo) => {
        //console.log(txInfo)
        let funds = 0;
        if (txInfo?.amount && txInfo?.balance) {
          funds = txInfo?.amount - txInfo?.balance;
        }
        if (txInfo?.amount && !txInfo?.balance) {
          funds = parseInt(txInfo?.amount);
        }
        return funds
      })
      .catch((err) => {
        return false;
      });
}
  