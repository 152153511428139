import styled from "styled-components";
import { getRandomColor, hexToRgba } from "../HexToRGBA";
import React, { useEffect, useState } from 'react'
import { getObjectLength } from "../../utils";
import { useWebsiteTheme } from "../WebsiteTheme/WebsiteThemeContext";



export const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
    align-content: center;
  height: 40vh;
  overflow: hidden;
  background: transparent;
  margin-bottom: 1%;
   border-radius: 15px;


  `
  export const  HeaderWrapper = styled.div`
  display: flex;
  position:relative;
  flex-direction: column;
  background: ${(props) => 
      ( (props?.background) ? 
        `linear-gradient(180deg, ${props.background} 0%, rgba(84, 120, 154, 0.6232) 100%)`:
          `linear-gradient(180deg, ${props.theme.mainColor} 0%, rgba(84, 120, 154, 0.6232) 100%)`)
  };
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding: 8% 3%;
  
  `

export const Title = styled.title`
  display: flex; 
  font-size: 3rem;
  font-weight: 700;
  text-align: center;
  color: ${props => props.theme.secondaryBg};
  filter: ${props => `drop-shadow(0px 4px 4px ${props.theme.mainBg})`};
`

export const Header = ({title}) => {
  const {themeColors} = useWebsiteTheme()

    const [num, setNum] = useState(0)
    const [color, setColor] = useState(themeColors.mainColor)
    const [color2, setColor2] = useState('rgba(84, 120, 154, 0.6232)')

    useEffect(() => {
        let interval = setTimeout(() => {
            changeColor()
		}, 4000);	
		
        let interval2 = setTimeout(() => {
            changeColor2()
		}, 6000);	
		
  		
		return () => {
            clearInterval(interval)
            clearInterval(interval2)
        };
    })


    const changeColor = () => {
        let colors = Object.keys(themeColors)
            let x = Math.floor((Math.random() * getObjectLength(themeColors)))
            setColor(themeColors[colors[x]])
            
    }

    const changeColor2 = () => {
        setColor2(getRandomColor())
            
    }
    

  return (
    <HeaderContainer>
        <HeaderWrapper background={color} background2={color2}>
            <Title>{title}</Title>
        </HeaderWrapper>
    </HeaderContainer>
  )
}
