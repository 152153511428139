import styled from "styled-components";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { hexToRgba } from "../HexToRGBA";


export const Container= styled.div`
    display: flex;
    width: 100%;
    padding: 2%;
    flex-direction: column;
    margin-bottom: 2%;
    color: ${props => props.theme.secondaryColor};
`

export const ContentTableWrapper = styled(Table)`
    color: ${props => props.theme.secondaryBg};
    
`
export const ContentTableContainer = styled(TableContainer)`

color: ${props => props.theme.secondaryBg};

`

export const ContentTableBody = styled(TableBody)`

`
export const ContentTableCell = styled(TableCell)`
color: ${props => props.theme.secondaryBg};

`
export const ContentTableHead = styled(TableHead)`
`
export const ContentTablePagination = styled(TablePagination)`
`
export const ContentTableRow = styled(TableRow)`
color: ${props => props.theme.secondaryBg};
cursor: pointer;
`

export const ContentTableRow1 = styled(TableRow)`
color: ${props => props.theme.secondaryBg};
cursor: pointer;
&:hover {
  transition: all 0.2s ease-in-out;
  background-color: ${props => hexToRgba(props.theme.mainColor, 0.3)};
  border-radius: 5px;
}

`
export const ContentTableH2 = styled.h2`
color: ${props => props.theme.secondaryBg};
`