import styled from "styled-components";
import hexToRgbA, { hexToRgba, lightOrDark } from "../../HexToRGBA";
import { Nav, NavLink } from "../../NavLink/NavLinkElements";


export const ArtistContentContainer = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
color: white;
`


export const ArtistContentWrapper= styled.div`

display: flex;
flex-direction: column;
align-items: center;
justify-content: flex-start;
padding: 2% 2%;
width: 100%;
`
export const ArtistProfileNav = styled(Nav)`
margin-top: 5%;
color: ${(props) => props.theme.secondaryBg};
`

export const ArtistProfileNavLink = styled(NavLink)`

&:hover {
  background: ${(props) => (props.active ?  props.background? hexToRgba(props.background,1) : hexToRgba(props.theme.secondaryColor,1) :'transparent') };
  color: ${(props) => (props.active ? props.background ? (lightOrDark(props.background) === 'light' ? 'black' : 'white') :props.theme.secondaryBg: props.theme.accentColor )};  transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  transform: ${(props) => (props.active ? 'scale(1.1)'  :'scale(0.9)' )};
}
background: ${(props) => (props.active ?  (props.background ? hexToRgba(props.background,0.8) : hexToRgba(props.theme.secondaryColor,0.8)) : (props.background ? hexToRgba(props.background,0.4) :  hexToRgba(props.theme.secondaryColor,0.4))) };
color: ${(props) => props.active ? props?.background ? (lightOrDark(props.background) === 'light' ? 'black' : 'white') :props.theme.secondaryBg : props.theme.secondaryBg};
  @media screen and (max-width: 480px) {
    font-size: 0.65rem;
  
  }

  
`
export const SettingsWrapper = styled.div`
display: flex;
width: 100%;
height: 100%;
flex-direction: row;
justify-content: flex-start;
align-items: center;
align-content: space-around;
padding: 2% 2%;
`


export const InputLabel = styled.label`
  display: flex;
  margin-right: 25%;
  flex: 1 1 50%;
  padding: 1% 2%;
  flex-wrap: nowrap;
  color: ${(props) => props.theme.secondaryBg};
  font-size: 0.8rem;
`;

export const Input = styled.input`
  display: flex;
  flex: 1 1 100%;
  padding: 1%;
  margin: 1% 2%;;
  border: none;
  background: ${props => hexToRgba(props.theme.secondaryBg,0.6)};
  border-radius: 4px;
`;

export const BioInput = styled.textarea`
  display: flex;
  padding: 10px 16px;
  width: 90%;
  height: 100%;
  resize: vertical;
  margin: 1% 2%;;
  border: none;
  border-radius: 4px;
`;

export const ErrorText = styled.small`
    display: flex;
    padding: 2%;
    color: ${props =>props.theme.accentColor};
`

export const ToggleWrapper = styled.div`
display: flex;
padding: 1% 1%;
flex: 1 1 50%;
font-size: 2.5rem;
`


export const ArtistContentHeadingWrapper = styled.div`
display: flex;
flex-direction: row;
align-items: flex-start;
padding: 0% 2%;
color: ${props => props.theme.secondaryBg};
width: 100%;
`

export const ArtistContentHeading = styled.div`
display: flex;
weight: 700;
font-size: 1.1rem;
color: ${(props) =>  props.theme.secondaryBg};
padding: 1% 0px;
cursor: pointer;
&:hover{
  text-decoration: underline;
  transition: all 0.2s ease-in-out;
  opacity: 1;
}

@media screen and (max-width: 480px) {
  font-size: 0.85rem;

}

`