import { sha256 } from "../../utils";

var md5 = require("md5");
const GRAVATAR_API_KEY = '723:gk-uYH72lkkOaqz0DoGNcjHWIJ_r1pvM2GcNaAg2BROs0LTlOMCatSn2LQ4QZGKg'//process.env.REACT_APP_GRAVATAR_API_KEY

export const DEFAULT_IMAGE = (size) =>
  size
    ? "https://www.gravatar.com/avatar/00000000000000000000000000000000?s=" +
      size +
      "&d=mp&f=y"
    : "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y";

export const DEFAULT_PROFILE_V3 = {
  "hash": false,
  "display_name": false,
  "profile_url": "https://gravatar.com/undefined",
  "avatar_url": "https://0.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y5",
  "avatar_alt_text": false,
  "location": false,
  "description": false,
  "job_title": false,
  "company": false,
  "verified_accounts": [],
  "pronunciation": false,
  "pronouns": false,
  "timezone": false,
  "languages": [],
  "first_name": false,
  "last_name": false,
  "is_organization": false,
  "links": [],
  "interests": [],
  "payments": {
    "links": [],
    "crypto_wallets": [
    ]
  },
  "contact_info": {
    "home_phone": "",
    "work_phone": "",
    "cell_phone": "",
    "email": "",
    "contact_form": "",
    "calendar": ""
  },
  "gallery": [],
  "number_verified_accounts": 0,
}

export const DEFAULT_PROFILE = {

      hash: "0000000000000000000000",
      requestHash: "undefined",
      profileUrl: "https://gravatar.com/undefined",
      thumbnailUrl:
        "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y",
      photos: [
        {
          value:
            "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y",
          type: "thumbnail",
        },
      ],
      urls: [],
};

export function validateUser(userEmail, userHash) {
  let email = hashEmail(userEmail);

  if (email === userHash) {
    return true;
  }
  return false;
}

export function hashEmail(email) {
  let hash = email ? email.trim() : undefined;
  if (hash === undefined) {
    return false;
  }
  return md5(hash.toLowerCase());
}

export function hashEmailV3(email) {
  let hash = email ? email.trim() : undefined;
  if (hash === undefined) {
    return false;
  }
  return sha256(hash.toLowerCase());
}

export async function getUserProfile(email) {
  if(email){
  const userURL = "https://en.gravatar.com/" + email + ".json";
  return await fetch(userURL)
    .then((response) => response.json())
    .then(async (response) => {
      let displayName = response?.entry[0]?.displayName;
      //console.log("Response: ", response.entry[0]);
      if (displayName && displayName !== undefined){
        let id = response?.entry[0]?.profileUrl.split('/')[response?.entry[0]?.profileUrl.split('/').length -1]
        let profile = await getGravatarProfileV3(id)
        return {...profile,
          profileBackground: response?.entry[0]?.profileBackground,
          currency: response?.entry[0]?.currency
        } 
      }else {
          return false
      }
      
    })
    .catch((err) => {
      return false
    })
  }
    return false
}

export const getGravatarProfileV3 = async (id) => {
  return await fetch(`https://api.gravatar.com/v3/profiles/${id}`, {
    headers: {
      'accept': 'application/json',
      'Authorization': `Bearer ${GRAVATAR_API_KEY}`
    }
  })
  .then((response) => response.json())
  .then((response) => {
    if(response?.display_name){
      return response
    }
    return false
  }).catch(err => {
    console.error(err)
    return false
  })
}


export const getUsername = async (email) => {
  return await getUserProfile(email)
    .then((data) => {
      if (data) {
        return data?.display_name;
      } else {
        return false;
      }
    })
    .catch((err) => {
      console.error(err);
    });
};
