import React, { useEffect, useState } from 'react'
import {  CommentActionButtons, CommentBoothContainer, CommentButton, CommentCheckBox, CommentContent, CommentContentMetadata, CommentContentMetatdataText, CommenterBadge, CommenterName, CommenterThumbnail, CommentListWrapper, CommentRow, CommentRowBody, CommentRowContainer, CommentSideContent, CommentSideContentImg, CommentSideContentTitle, CommentSideContentWrapper, CommentText, CommentThread, CommentThreadContainer, CommentWrapper, ReadMore, SideContentButton } from './CommentBoothElements'
import { Nav, NavContainer, NavLink } from '../NavLink/NavLinkElements'
import ContentWrapper from '../ContentWrapper'
import useMusic from '../../hooks/useMusic'
import { useAuth } from '../../contexts/AuthContext'
import { ModalLoadingScreen } from '../LoadingScreen'
import { getSongTitle, getTimestamp } from '../../utils'
import { CONTENT_TYPES } from '../../utils/Enum'
import { FaExternalLinkAlt, FaExternalLinkSquareAlt, FaHeart } from 'react-icons/fa'
import { TextLink } from '../QRCodeForm/QrCodeFormElements'
import { Theme } from '../WebsiteThemeElements'
import Comment from './CommentList/Comment'
import { Header } from '../Header'

const CommentBooth = () => {
    const {getMusicCommentsByArtistId} = useMusic()
    const [isLoaded, setLoaded] = useState(false)
    const {currentUser, currentProfile, profiles} = useAuth()
    const [currentTab, setTab] = useState('all')
    const [comments, setComments] = useState([])
    const [refresh, setRefresh] = useState(false)

    const getComments = async () => {
        await getMusicCommentsByArtistId(profiles?.artist?.artistId).then(c => {
            if(c){
                setComments(c?.comments)
            }
            setLoaded(true)
        }).catch(err => {
            setLoaded(true)
        })
    } 

    useEffect(() => {
      getComments()
    
      return () => {
       
      }
    }, [currentProfile, refresh])

    function sideContent(comment, type){
        switch (type) {
            case CONTENT_TYPES.ALBUM:
                return(
                    <CommentSideContentWrapper >
                                <CommentSideContent to={`/album/${comment?.album?.albumId}`}>
                                    <CommentSideContentImg src={comment?.album?.albumArt}/>
                                    <CommentSideContentTitle>
                                        {comment?.album?.albumName}
                                    </CommentSideContentTitle>
                                </CommentSideContent>
                                <SideContentButton   target="_blank"
                  rel="noopener noreferrer" to={`https://sonarmuse.org/album/${comment?.album?.albumId}`}><FaExternalLinkAlt/></SideContentButton>
                                </CommentSideContentWrapper>
                )
                break;
            case CONTENT_TYPES.SONG:
                return (<CommentSideContentWrapper>
                    <CommentSideContent to={`/song/${comment?.song?.songId}`}>
                        <CommentSideContentImg src={comment?.song?.albumArt}/>
                        <CommentSideContentTitle>
                            {getSongTitle(comment?.song)}
                        </CommentSideContentTitle>
                    </CommentSideContent>
                    <SideContentButton   target="_blank"
                  rel="noopener noreferrer" to={`https://sonarmuse.org/song/${comment?.song?.songId}`}><FaExternalLinkAlt/></SideContentButton>
                    </CommentSideContentWrapper>)
                break;
            case CONTENT_TYPES.COLLECTION:
                return (
                    <CommentSideContentWrapper>
                                <CommentSideContent to={`/collection/${comment?.collection?.collectionId}`}>
                                    <CommentSideContentImg src={comment?.collection?.cover?.url}/>
                                    <CommentSideContentTitle>
                                        {comment?.collection?.collectionName}
                                    </CommentSideContentTitle>
                                </CommentSideContent>
                                <SideContentButton   target="_blank"
                  rel="noopener noreferrer" to={`https://sonarmuse.org/collection/${comment?.collection?.collectionId}`}><FaExternalLinkAlt/></SideContentButton>
                                </CommentSideContentWrapper>
                )
                break;
        
            default:
                break;
        }
    }
    

  return (
    <>
    <CommentBoothContainer>
        <Header title={'Comment Booth'}/>
   {isLoaded && comments?.length > 0 && <> {/* <Nav>
        <NavContainer>
            <NavLink>
        All Comments
        </NavLink>
            <NavLink>
        Song Comments
        </NavLink>
            <NavLink>
        Album Comments
            </NavLink>
        </NavContainer>
    </Nav> */}
    <ContentWrapper>
        <CommentListWrapper>
            {comments?.filter(comment => !comment?.parentId)?.length > 0 && comments?.filter(comment => !comment?.parentId).map(comment => {
                return (
                  <Comment comment={comment} comments={comments} refresh={() => getComments()}/>  
                )
            }) }
        </CommentListWrapper>
    </ContentWrapper> </>}
    {!isLoaded && <ModalLoadingScreen/>}
    </CommentBoothContainer>
    
    
    </>
  )
}

export default CommentBooth