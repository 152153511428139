import React, { useState } from 'react'
import { FormTextArea, FormWrapper } from '../../CustomForm/CustomFormElements'
import { CustomButton, CustomButton1 } from '../../ButtonElement'
import { Theme } from '../../WebsiteThemeElements'
import { sendReply } from '../../../utils/dbUtils/commentsUtils'

const ReplyForm = ({type, id, currentProfile, profileId, commentId, setOpen, refresh}) => {
    const [message, setMessage] = useState("")


    const handleInput = (e) => {
        setMessage(e.target.value)
    }

  return (
    <>
    <FormWrapper>
        <FormTextArea placeholder='Reply to comment...' value={message} onChange={handleInput}/>

    <div style={{display:'flex', padding: '2%'}}>
        <CustomButton1 onClick={async() => 
        {
            await sendReply(type, id, currentProfile, profileId, message, commentId ).then(() => {
                refresh()
                setMessage()
            })
            setOpen(false)
        }
        }>Reply</CustomButton1>
    <CustomButton1 background={Theme({}).warningColor} onClick={() => setOpen(false)}>Cancel</CustomButton1></div>
    </FormWrapper>
    </>
  )
}

export default ReplyForm