import React, { useEffect, useState } from "react";
import KeyMetrics from "../Charts/KeyMetrics";
import useMusic from "../../hooks/useMusic";
import { SelectButton, SelectButtonWrapper } from "../Select";
import { useWebsiteTheme } from "../WebsiteTheme/WebsiteThemeContext";

import {
  getGroupedPlays,
  getNumListeners,
  getObjectLength,
  getPlayCount,
  groupByPlayingFrom,
  groupInfoByDate,
  groupPurchasesByAmount,
} from "../../utils";
import { ModalLoadingScreen } from "../LoadingScreen";
import ContentWrapper from "../ContentWrapper";
import { FormText, FormWrapper } from "../CustomForm/CustomFormElements";
import { ChipList } from "../Chip/ChipElements";
import Chip from "../Chip";
import { getRandomColor } from "../HexToRGBA";
import { CURRENCIES_STATS_COLORS } from "../../utils/Enum";
import ChartToggle from "../Charts";

const CollectionStats = ({ collection, issuer, taxon, type }) => {
  const {themeColors} = useWebsiteTheme()


  const [filter, setFilter] = useState("7days");
  const {
    getCollectionPurchasesByDate,
    getNFTokenCollectionOwners,
    getCollectionPlaysByDate,
    getCollectionLikesByDate,
  } = useMusic();
  const [plays, setPlays] = useState([]);
  const [likes, setLikes] = useState([]);
  const [purchases, setPurchases] = useState([]);
  const [owners, setOwners] = useState([]);
  const [metricType, setMetricType] = useState("plays");
  const [playType, setPlayType] = useState("all");
  const [isLoaded, setLoaded] = useState(false);

  const getData = async () => {
    setLoaded(false);
    setPlays([]);
    setPurchases([]);
    setOwners([]);
    setLikes([]);
    return await Promise.all([
      getCollectionPlaysByDate(collection, filter),
      getCollectionPurchasesByDate(collection, filter),
      getNFTokenCollectionOwners(issuer, taxon),
      getCollectionLikesByDate(collection, filter),
    ])
      .then((data) => {
        if (data[0]?.plays?.length > 0) {
          setPlays(data[0]?.plays);
        }
        if (data[1]?.purchases?.length > 0) {
          setPurchases(data[1]?.purchases);
        }
        if (data[2]?.owners?.length > 0) {
          setOwners(data[2]?.owners);
        }
        if (data[3]?.likes?.length > 0) {
          setLikes(data[3]?.likes);
        }
        setLoaded(true);
      })
      .catch((err) => {
        console.error(err);
        setLoaded(true);
        return;
      });
  };

  useEffect(() => {
    getData();

    return () => {};
  }, [collection, filter]);

  return (
    <ContentWrapper>
      <SelectButtonWrapper>
        <SelectButton
          disabled={!isLoaded}
          onChange={(e) => setFilter(e.target.value)}
        >
          <option value={"7days"}>7 days</option>
          <option value={"14days"}>2 Weeks</option>
          <option value={"30days"}>4 Weeks</option>
          <option value={"60days"}>2 Months</option>
          <option value={"180days"}>6 Months</option>
          <option value={"365days"}>12 Months</option>
          <option value={"AllTime"}>All Time</option>
        </SelectButton>
      </SelectButtonWrapper>

      {!isLoaded && <ModalLoadingScreen text={"Loading Stats"} />}
      {isLoaded && (
        <>
          {" "}
          <KeyMetrics
            data={[
              {
                heading: "Plays",
                onClick: () => {
                  setMetricType("plays");
                },
                labels:
                  plays?.length > 0
                    ? Object.keys(groupInfoByDate(plays, "timestamp", filter))
                    : [],
                datasets: [
                  {
                    label: "Plays",
                    data:
                      plays?.length > 0
                        ? Object.values(
                            groupInfoByDate(plays, "timestamp", filter)
                          ).map((p) => {
                            return p.length;
                          })
                        : [],
                    borderColor: themeColors.background,
                    backgroundColor: themeColors.background,
                  },
                ],
              },
              {
                heading: "Likes",
                onClick: () => {
                  setMetricType("likes");
                },
                labels:
                  likes?.length > 0
                    ? Object.keys(groupInfoByDate(likes, "timestamp", filter))
                    : [],
                datasets: [
                  {
                    label: "likes",
                    data:
                      likes?.length > 0
                        ? Object.values(
                            groupInfoByDate(likes, "timestamp", filter)
                          ).map((l) => {
                            return l;
                          })
                        : [],
                    borderColor: themeColors.successColor,
                    backgroundColor: themeColors.successColor,
                  },
                ],
              },
              {
                heading: "Revenue",
                onClick: () => {
                  setMetricType("revenue");
                },
                labels:
                  purchases.length > 0
                    ? Object.keys(
                        groupInfoByDate(purchases, "createdAt", filter)
                      )
                    : [],
                datasets: [
                  {
                    label: "purchases",
                    data:
                      purchases.length > 0
                        ? Object.values(
                            groupInfoByDate(purchases, "createdAt", filter)
                          ).map((p) => {
                            return p?.length;
                          })
                        : [],
                    borderColor: themeColors.secondaryColor,
                    backgroundColor: themeColors.secondaryColor,
                  },
                ],
              },
            ]}
          />
          {metricType === "plays" && plays?.length > 0 && (
            <>
              <ContentWrapper>
                <ChipList alignment={"right"}>
                  <Chip
                    label={"All"}
                    background={playType === "all" && themeColors.accentColor}
                    onChipClick={() => setPlayType("all")}
                  />
                  <Chip
                    label={"Full Plays"}
                    background={playType === "full" && themeColors.successColor}
                    onChipClick={() => setPlayType("full")}
                  />
                  <Chip
                    label={"Partial Plays"}
                    background={playType === "partial" && themeColors.infoColor}
                    onChipClick={() => setPlayType("partial")}
                  />
                  <Chip
                    label={"Skips"}
                    background={playType === "skip" && themeColors.warningColor}
                    onChipClick={() => setPlayType("skip")}
                  />
                </ChipList>
                {playType === "all" && (
                  

                    <ChartToggle
                      data={{
                        heading: 'All Plays (By Type)',
                        labels:
                          plays?.length > 0
                            ? Object.keys(
                                groupInfoByDate(plays, "timestamp", filter)
                              )
                            : [],
                        datasets:
                          plays?.length > 0
                            ? [
                                {
                                  label: "Full",
                                  data:
                                    plays?.length > 0
                                      ? Object.values(
                                          groupInfoByDate(
                                            plays.filter(
                                              (p) => p?.playType === "full"
                                            ),
                                            "timestamp",
                                            filter
                                          )
                                        ).map((p) => {
                                          return p.length;
                                        })
                                      : [],
                                  borderColor: themeColors.successColor,
                                  backgroundColor: themeColors.successColor,
                                },
                                {
                                  label: "Partial",
                                  data:
                                    plays?.length > 0
                                      ? Object.values(
                                          groupInfoByDate(
                                            plays.filter(
                                              (p) => p?.playType === "partial"
                                            ),
                                            "timestamp",
                                            filter
                                          )
                                        ).map((p) => {
                                          return p.length;
                                        })
                                      : [],
                                  borderColor: themeColors.infoColor,
                                  backgroundColor: themeColors.infoColor,
                                },
                                {
                                  label: "Skips",
                                  data:
                                    plays?.length > 0
                                      ? Object.values(
                                          groupInfoByDate(
                                            plays.filter(
                                              (p) => p?.playType === "skip"
                                            ),
                                            "timestamp",
                                            filter
                                          )
                                        ).map((p) => {
                                          return p.length;
                                        })
                                      : [],
                                  borderColor: themeColors.warningColor,
                                  backgroundColor: themeColors.warningColor,
                                },
                              ]
                            : [{}],
                      }}
                    />
                )}
                {playType !== "all" && (
                  <>
                    

                      <ChartToggle
                        data={{
                          heading: `${playType[0].toUpperCase()}${playType.substring(
                          1
                        )} Plays`,
                          labels:
                            plays?.length > 0
                              ? Object.keys(
                                  groupInfoByDate(plays, "timestamp", filter)
                                )
                              : [],
                          datasets: [
                            {
                              label: playType,
                              data:
                                plays?.length > 0
                                  ? Object.values(
                                      groupInfoByDate(
                                        plays,
                                        "timestamp",
                                        filter
                                      )
                                    ).map((plays) => {
                                      return getGroupedPlays(plays)[playType];
                                    })
                                  : [],

                              borderColor:
                                playType === "skip"
                                  ? themeColors.warningColor
                                  : playType === "partial"
                                  ? themeColors.infoColor
                                  : themeColors.successColor,
                              backgroundColor:
                                playType === "skip"
                                  ? themeColors.warningColor
                                  : playType === "partial"
                                  ? themeColors.infoColor
                                  : themeColors.successColor,
                            },
                          ],
                        }}
                      />
                 
                      <ChartToggle
                        data={{
                          heading: 'Unique Listeners',
                          labels:
                            plays?.length > 0
                              ? Object.keys(
                                  groupInfoByDate(plays, "timestamp", filter)
                                )
                              : [],
                          datasets: [
                            {
                              label: "Listeners",
                              data:
                                plays?.length > 0
                                  ? Object.values(
                                      getNumListeners(
                                        groupInfoByDate(
                                          plays.filter(
                                            (p) => playType === p?.playType
                                          ),
                                          "timestamp",
                                          filter
                                        )
                                      )
                                    )
                                  : [],
                              borderColor:
                                playType === "skip"
                                  ? themeColors.warningColor
                                  : playType === "partial"
                                  ? themeColors.infoColor
                                  : themeColors.successColor,
                              backgroundColor:
                                playType === "skip"
                                  ? themeColors.warningColor
                                  : playType === "partial"
                                  ? themeColors.infoColor
                                  : themeColors.successColor,
                            },
                          ],
                        }}
                      />
                  </>
                )}
              </ContentWrapper>
            </>
          )}
          {metricType === "likes" && likes?.length > 0 && (
            <ContentWrapper>
              <FormWrapper>
              <FormText>No. Likes</FormText>

                <ChartToggle
                  data={{
                    heading: "Likes",
                    labels:
                      likes?.length > 0
                        ? Object.keys(
                            groupInfoByDate(likes, "dateCreated", filter)
                          )
                        : [],
                    datasets: [
                      {
                        label: playType,
                        data:
                          likes?.length > 0
                            ? Object.values(
                                groupInfoByDate(likes, "dateCreated", filter)
                              ).map((l) => {
                                return l?.length;
                              })
                            : [],
                        borderColor: themeColors.infoColor,
                      },
                    ],
                  }}
                />
              </FormWrapper>
            </ContentWrapper>
          )}
          {metricType === "revenue" && purchases?.length > 0 && (
            <ContentWrapper>
              <FormWrapper>
                <FormText>Total Sales</FormText>
                <ChartToggle
                  data={{
                    heading: "Total Sales",
                    labels:
                      purchases?.length > 0
                        ? Object.keys(
                            groupInfoByDate(purchases, "createdAt", filter)
                          )
                        : [],
                    datasets: [
                      {
                        label: "Sales",
                        data:
                          purchases?.length > 0
                            ? Object.values(
                                groupInfoByDate(purchases, "createdAt", filter)
                              ).map((p) => {
                                return p.length;
                              })
                            : [],
                        borderColor: themeColors.warningColor,
                      },
                    ],
                  }}
                />
                {getObjectLength(groupPurchasesByAmount(purchases)) > 0 && (
                  <ChartToggle
                  type={'bar'}
                    data={{
                      heading: 'Revenue (Grouped By Currency)',
                      labels:
                        getObjectLength(groupPurchasesByAmount(purchases)) > 0
                          ? Object.keys(groupPurchasesByAmount(purchases))
                          : [],
                      datasets: [
                        {
                          label: "Total Earnings",
                          data:
                            purchases?.length > 0
                              ? Object.values(
                                  groupPurchasesByAmount(purchases)
                                ).map((purchase) => {
                                  return parseFloat(purchase).toFixed(2);
                                })
                              : [],
                          backgroundColor: Object.keys(
                            groupPurchasesByAmount(purchases)
                          ).map((purchase) => {
                            return CURRENCIES_STATS_COLORS[purchase];
                          }),
                        },
                      ],
                    }}
                  />
                )}
              </FormWrapper>
            </ContentWrapper>
          )}
          {((metricType === "plays" && plays?.length === 0) ||
            (metricType === "likes" && likes?.length === 0) ||
            (metricType === "revenue" && purchases?.length === 0)) && (
            <>
              <ContentWrapper>
                <FormWrapper>
                  <FormText>No Data Available</FormText>
                </FormWrapper>
              </ContentWrapper>
            </>
          )}
        </>
      )}
    </ContentWrapper>
  );
};

export default CollectionStats;
