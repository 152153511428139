import styled from "styled-components"
import AlbumArt from "../AlbumArt"
import { hexToRgba } from "../HexToRGBA"
import { Nav, NavLink } from "../NavLink/NavLinkElements"
import { MEDIA_SCREEN } from "../../utils/Enum"

export const ArtistRegistrationNav = styled(Nav)`
overflow: none;
@media screen and (max-width: ${MEDIA_SCREEN.TABLETS}px) {
  flex-direction: column;
}
`

export const ArtistNavImg = styled(AlbumArt)`
height: ${props => props.size ? props.size : '1rem' };
width: ${props => props.size ? props.size : '1rem'};
border: solid ${(props) => (hexToRgba(props.theme.secondaryColor,1)) } 2px;
  &:hover {
    transition: all 0.3s ease-in-out;
    transform: 'scale(0.9)';
  }
`

export const ArtistRegistrationNavLink = styled(NavLink)`
flex-direction: column;
align-items: center;
justify-items: center;
background: transparent;
padding: 1%;
margin: 2%;
border: solid ${(props) => (hexToRgba(props.theme.secondaryColor,1)) } 2px;
border-radius: 15px;
&:hover {
  background: transparent;
  border-radius: 0px;
  color: ${(props) => (props.active ? props.theme.secondaryBg: props.theme.secondaryColor )};
  border: solid ${(props) => (hexToRgba(props.theme.secondaryBg,1)) } 2px;
  transition: all 0.3s ease-in-out;
  transform: 'scale(0.9)';
}
  color: ${(props) => props.theme.secondaryBg};
    @media screen and (max-width: 480px) {
      font-size: 0.65rem;
    
    }

`