import React, { useEffect, useRef, useState } from "react";
import { MdExplicit } from "react-icons/md";
import { Fade, Zoom } from "react-reveal";
import { useNavigate } from "react-router-dom";
import { PulseSpinner, PushSpinner } from "react-spinners-kit";
import { useAuth } from "../../../contexts/AuthContext";
import useArtist from "../../../hooks/useArtist";
import useMusic from "../../../hooks/useMusic";
import {
  containsObject,
  getObjectLength,
  getUniqueListBy,
  isrcReg,
  newlineFormatter,
} from "../../../utils";
import {
  ALERT_TYPES,
  ARTIST_FORM_INPUT_TYPES,
  ARTIST_FORM_TYPES,
  ARTIST_SEARCH_TYPES,
} from "../../../utils/Enum";
import ImageUtils from "../../../utils/ImageUtils";
import { uploadToIPFS } from "../../../utils/nfTokensUploads";
import { useAlert } from "../../Alert/AlertContext";
import {
  getDownloadUrl,
  storage,
  storageRef,
  uploadBytes,
} from "../../Authorization/firebase";
import { HoverOption } from "../../HoverMenu/HoverMenuElements";
import LoadingScreen, { ModalLoadingScreen } from "../../LoadingScreen";
import { useModal } from "../../Modal/ModalContext";
import OptionModal from "../../OptionModal";
import PageIndexer from "../../PageIndexer";
import { Theme } from "../../WebsiteThemeElements";
import {
  FormInput,
  FormLabel,
  FormWrapper,
  FormTextArea,
  SongArtCoverWrapper,
  SongDetailsContainer,
  SongDetailsSection,
  SongDetailsSectionTitle,
  SongDetailsWrapper,
  MusicUploadsButton,
  ErrorWrapper,
  MusicTitleInfoContainer,
  MusicUploadTitleArtContainer,
  MusicUploadAlbumArt,
  MusicUploadTitleTextWrapper,
  MusicUploadContentTitle,
  MusicUploadInfoText,
  AlbumUploadsContainer,
  SongDetailsText,
  AlbumUploadsInput,
  UploadProgressWrapper,
  AlbumArtPreviewContainer,
  AlbumArtPreview,
  MusicUploadsButtonContainer,
} from "../MusicUploadsFormElements";
import NFTStorageTokenForm from "../NFTStorageTokenForm";
import Page1 from "../SongUploads/Page1";
import Page2 from "../SongUploads/Page2";
import Page3 from "../SongUploads/Page3";
import Page4 from "../SongUploads/Page4";
import Page5 from "../SongUploads/Page5";
import Page6 from "../SongUploads/Page6";
import NFTStorageSonarAPIKeyForm from "../../NFTStorageSonarAPIKeyForm";
import FileUploader from "../../FileUploader";
import Page7 from "../SongUploads/Page7";
import { v4 } from "uuid";
import { UnavailableFeature } from "../../MenuOptions";
import { songNameContainsFeature } from "../../../utils/MusicUtils";

function SongDetails({
  song,
  setAlbumSong,
  index,
  cancelSong,
  deleteSong,
  albumInfo,
  songUploadTasks,
  setSongUploadTasks,
}) {
  const { modalContent, isOpen, toggleModal, openModal, closeModal } = useModal();
  const { currentUser } = useAuth();
  const { searchArtists, validateNFTStorageKey } = useArtist({});
  const { defaultAlbumArt } = ImageUtils();
  const navigate  = useNavigate();
  const { addAlert } = useAlert();
  
  const {
    newSongId,
    createMp3File,
    searchGenres,
    uploadSong,
    getAllGenres,
    generateISRCCode,
  } = useMusic();
  const songNameRef = useRef();
  const songUploadRef = useRef();
  const albumArtRef = useRef();
  const videoFileRef = useRef();
  const additionalFilesRef = useRef();
  const songPriceRef = useRef();
  const releaseDateRef = useRef();
  const genreRef = useRef();
  const isrcRef = useRef();
  const aboutRef = useRef();
  const lyricsRef = useRef();
  const composersRef = useRef();
  const producersRef = useRef();
  const writersRef = useRef();
  const artistNameRef = useRef("");
  const artistIdRef = useRef("");
  const walletAddressRef = useRef("");
  const artistSearchRef = useRef("");
  const artistFullNameRef = useRef("");

  const fArtistNameRef = useRef("");
  const fArtistIdRef = useRef("");
  const fWalletAddressRef = useRef("");
  const fArtistSearchRef = useRef("");
  const fArtistFullNameRef = useRef("");

  const writerNameRef = useRef("");
  const writerIdRef = useRef("");
  const writerWalletAddressRef = useRef("");
  const writerFullNameRef = useRef("");
  const writerSearchRef = useRef("");

  const composerNameRef = useRef("");
  const composerIdRef = useRef("");
  const composerWalletAddressRef = useRef("");
  const composerFullNameRef = useRef("");
  const composerSearchRef = useRef("");

  const producerNameRef = useRef("");
  const producerIdRef = useRef("");
  const producerWalletAddressRef = useRef("");
  const producerFullNameRef = useRef("");
  const producerSearchRef = useRef("");

  const payoutArtistNameRef = useRef("");
  const payoutArtistIdRef = useRef("");
  const payoutArtistWalletAddressRef = useRef("");
  const payoutArtistFullNameRef = useRef("");
  const payoutArtistSearchRef = useRef("");
  const artistPayoutRef = useRef();

  const [percentage, setPercentage] = useState(100);
  const [songInfo, setSongInfo] = useState({});
  const [currency, setCurrency] = useState(song?.currency ? song?.isUserRoyalties ? "XRP" : song?.currency : albumInfo?.currency
  ? albumInfo.currency
  : "WYM");

  const [userRoyalties, setUserRoyalties] = useState(song?.userRoyalties ? song?.userRoyalties : {
    percentage: 10,
    shares: 100,
    price: 0,
    ticker: "",
    shareReserve: 0,
    currency: "XRP"
  })
  const [currentUserArtist, setUserArtist] = useState(albumInfo?.userArtist);
  const [requestRelease, setReleaseRequest] = useState(false);
  const [artistEditng, setArtistEditing] = useState(false);
  const [fArtistEditng, setFArtistEditing] = useState(false);
  const [writerEditing, setWriterEditing] = useState(false);
  const [composerEditing, setComposerEditing] = useState(false);
  const [producerEditing, setProducerEditing] = useState(false);
  const [payoutEditing, setPayoutEditing] = useState(false);
  const [songId, setSongId] = useState();
  const [musicGenres, setMusicGenres] = useState({});
  const [isIsrcValid, setIsrcValid] = useState(false);
  const [albumArt, setAlbumArt] = useState(
    albumInfo?.albumArt ? albumInfo?.albumArt : {}
  );
  const [videoFile, setVideoFile] = useState(
    song?.videoFile ? song?.videoFile : {}
  );
  const [visualizerFile, setVisualizerFile] = useState(
    song?.visualizerFile ? song?.visualizerFile : albumInfo?.visualizerFile ? albumInfo?.visualizerFile : {}
  );
  const [isPreOrder, setPreOrder] = useState(song?.isPreOrder ? song?.isPreOrder :false);
  const [storageToken, setToken] = useState();
  const [additionalFiles, setAdditionalFiles] = useState([]);
  const [isExplicit, setExplicit] = useState(
    song?.isExplicit ? song?.isExplicit : false
  );
  const [isCover, setCover] = useState(song?.isCover ? song?.isCover : false);
  const [isStreamable, setStreamable] = useState(
    song?.isStreamable === true || song?.isStreamable === false
      ? song?.isStreamable
      : albumInfo?.isStreamable
      ? albumInfo.isStreamable
      : false
  );
  const [isDownloadable, setDownloadable] = useState(
    song?.isDownloadable === true || song?.isDownloadable === false
      ? song?.isDownloadable
      : albumInfo?.isDownloadbable
      ? albumInfo.isDownloadbable
      : false
  );
  const [isSubscription, setSubscription] = useState(
    song?.isSubscription === true || song?.isSubscription === false
      ? song?.isSubscription
      : albumInfo?.isSubscription
      ? albumInfo.isSubscription
      : false
  );
  const [isMintable, setMintable] = useState(
    song?.isMintable === true || song?.isMintable === false
      ? song?.isMintable
      : albumInfo?.isMintable
      ? albumInfo.isMintable
      : false
  );
  const [isUserRoyalties, setIsUserRoyalties] = useState(song?.isUserRoyalties ? song?.isUserRoyalties : false)
  const [isVideo, setVideo] = useState(song?.isVideo ? song?.isVideo :false);
  const [isVisualizer, setVisualizer] = useState(song?.isVisualizer ? song?.isVisualizer : albumInfo?.isVisualizer
    ? albumInfo.isVisualizer
    : false);

  const [songUpload, setSong] = useState(
    song?.songUpload ? song?.songUpload : {}
  );
  const [mp3Token, setMp3Token] = useState(song?.mp3Token ? song?.mp3Token : v4())
  const [songName, setName] = useState(song?.songName ? song?.songName : "");
  const [mainGenre, setMainGenre] = useState(
    song?.mainGenre ? song?.mainGenre : undefined
  );
  const [duration, setDuration] = useState(song?.duration ? song?.duration : undefined)
  const [genre, setGenre] = useState("");
  const [subgenre, setSubgenre] = useState("");
  const [subGenres, setSubGenres] = useState(
    song?.subGenres ? song?.subGenres : []
  );
  const [filteredGenres, setGenreFilter] = useState([]);
  const [filteredSubGenres, setSubGenreFilter] = useState([]);
  const [price, setPrice] = useState(song?.price ? song?.price : undefined);
  const [isrcType, setIsrcType] = useState(
    song?.isrcType ? song?.isrcType : "auto"
  );
  const [autoIsrcCode, setAutoIsrcCode] = useState(
    song?.autoIsrcCode ? song?.autoIsrcCode : ""
  );
  const [releaseDate, setReleaseDate] = useState(
    song?.releaseDate
      ? song?.releaseDate
      : albumInfo?.releaseDate
      ? albumInfo?.releaseDate
      : undefined
  );
  const [artistSearch, setArtistSearch] = useState();
  const [featArtistSearch, setFArtistSearch] = useState();
  const [composerSearch, setComposerSearch] = useState();
  const [producerSearch, setProducerSearch] = useState();
  const [writerSearch, setWriterSearch] = useState();
  const [payoutSearch, setPayoutSearch] = useState();
  const [artists, setArtists] = useState(
    song?.artists ? song?.artists : albumInfo?.artists ? albumInfo.artists : []
  );
  const [currentArtist, setCurrentArtist] = useState({});
  const [featArtists, setFeatArtists] = useState(
    song?.featArtists ? song?.featArtists : []
  );
  const [currentFArtist, setCurrentFArtist] = useState({});
  const [isrc, setISRC] = useState(song?.isrc ? song?.isrc : "");
  const [about, setAbout] = useState(song?.about ? song?.about : "");
  const [lyrics, setLyrics] = useState(song?.lyrics ? song?.lyrics : "");
  const [payoutArtists, setPayoutArtists] = useState(
    song?.payoutArtists ? song?.payoutArtists : []
  );
  const [currentPayoutArtist, setCurrentPayoutArtist] = useState({});
  const [composers, setComposers] = useState(
    song?.composers ? song?.composers : []
  );
  const [currentComposer, setCurrentComposer] = useState({});
  const [writers, setWriters] = useState(song?.writers ? song?.writers : []);
  const [currentWriter, setCurrentWriter] = useState({});
  const [producers, setProducers] = useState(
    song?.producers ? song?.producers : []
  );
  const [currentProducer, setCurrentProducer] = useState({});
  const [license, setLicense] = useState(song?.license ? song?.license : albumInfo?.license ? albumInfo?.license : "ARR");
  const [errors, setErrors] = useState(song?.errors ? song?.errors : {});
  const [pageNumber, setPageNum] = useState(1);
  const [isSubmitted, setSubmit] = useState(false);
  const [isLoaded, setLoaded] = useState(false);
  const [isUploading, setUploading] = useState(false);

  //Create a new songID for the current upload if none exists

  /* useEffect(() => {
    autoSave();
    return () => {}
  });

  const autoSave = () => {
    const interval = setTimeout(() => {
      updateSongInfo();
    }, 5000);
    return () => clearInterval(interval);
  }; */

  useEffect(() => {
    setLoaded(false);
    setMainArtist();
    if(!autoIsrcCode || autoIsrcCode?.length === 0){
      getISRCCode();
      }
    return () => {}
  }, []);

  useEffect(() => {
    if (artists?.length === 0) {
      setArtists([{ ...song?.userArtist }]);
    }
    return () => {}
  }, [artists?.length === 0]);

  useEffect(() => {
    if (isUserRoyalties && isDownloadable) {
      setCurrency("XRP")
    }
    return () => {}
  }, [currency, isUserRoyalties, isDownloadable]);

  const setMainArtist = async () => {
    return;
  };

  const getISRCCode = async () => {
    if (!song?.isrc || song?.isrc === "") {
      return await generateISRCCode()
        .then((code) => {
          //console.log("code: ", code);
          if (!code?.error || code !== false) {
            setAutoIsrcCode(code);
            setISRC(code);
          } else {
            //console.log("ID not generated");
          }
        })
        .catch((err) => {
          //console.log(err);
          setAutoIsrcCode();
          setISRC();
        });
    }
  };

  const nextPage = () => {
    const currPage = pageNumber;
    if (currPage < 7) {
      setPageNum(currPage + 1);
    }
  };

  const prevPage = () => {
    const currPage = pageNumber;
    if (currPage > 0) {
      setPageNum(currPage - 1);
    }
  };

  const selectArtist = (type, artist) => {
    let a = [];

    switch (type) {
      case ARTIST_FORM_TYPES.MAIN_ARTIST:
        a = artists;
        setCurrentArtist({ ...artist, exists: true });
        a.push({ ...artist, exists: true });
        setArtists(a);
        setArtistSearch();
        break;
      case ARTIST_FORM_TYPES.FEATURE:
        a = featArtists;
        setCurrentFArtist({ ...artist, exists: true });
        a.push({ ...artist, exists: true });
        setFeatArtists(a);
        setFArtistSearch();
        break;
      case ARTIST_FORM_TYPES.COMPOSER:
        a = composers;
        setCurrentComposer({ ...artist, exists: true });
        a.push({ ...artist, exists: true });
        setComposers(a);
        setComposerSearch();
        break;
      case ARTIST_FORM_TYPES.PRODUCER:
        a = producers;
        setCurrentProducer({ ...artist, exists: true });
        a.push({ ...artist, exists: true });
        setProducers(a);
        setProducerSearch();
        break;
      case ARTIST_FORM_TYPES.WRITER:
        a = writers;
        setCurrentWriter({ ...artist, exists: true });
        a.push({ ...artist, exists: true });
        setWriters(a);
        setWriterSearch();
        break;
      case ARTIST_FORM_TYPES.PAYOUT:
        a = payoutArtists;
        setCurrentPayoutArtist({
          ...artist,
          payoutPercentage: 0,
        });
        a.push({ ...artist, payoutPercentage: 0 });
        setPayoutArtists(a);
        setPayoutSearch();
        break;
      default:
        break;
    }
    clearArtistForm(type);
  };

  //Add specific artist type to list
  const addArtist = (type) => {
    let a = [];

    switch (type) {
      case ARTIST_FORM_TYPES.MAIN_ARTIST:
        a = artists;
        a.push({ ...currentArtist, exists: false });
        setArtists(a);
        setCurrentArtist();
        break;
      case ARTIST_FORM_TYPES.FEATURE:
        a = featArtists;
        a.push({ ...currentFArtist, exists: false });
        setFeatArtists(a);
        setCurrentFArtist();
        break;
      case ARTIST_FORM_TYPES.COMPOSER:
        a = composers;
        a.push({ ...currentComposer, exists: false });
        setComposers(a);
        setCurrentComposer();
        break;
      case ARTIST_FORM_TYPES.PRODUCER:
        a = producers;
        a.push({ ...currentProducer, exists: false });
        setProducers(a);
        setCurrentProducer();
        break;
      case ARTIST_FORM_TYPES.WRITER:
        a = writers;
        a.push({ ...currentWriter, exists: false });
        setWriters(a);
        setCurrentWriter();
        break;
      /* case ARTIST_FORM_TYPES.PAYOUT:
      a = writers
      a.push({...currentPayoutArtist, exists: false});
      setPayoutArtists(a);
      setCurrentPayoutArtist()
        break; */
      default:
        break;
    }
    //console.log("Artists: ", a);
  };

  const clearArtistForm = (type) => {
    switch (type) {
      case ARTIST_FORM_TYPES.MAIN_ARTIST:
        setArtistSearch();
        setCurrentArtist();
        if (artistSearchRef?.current?.value) {
          artistSearchRef.current.value = "";
        }
        if (artistIdRef?.current?.value) {
          artistIdRef.current.value = "";
        }
        if (artistNameRef?.current?.value) {
          artistNameRef.current.value = "";
        }
        if (walletAddressRef?.current?.value) {
          walletAddressRef.current.value = "";
        }
        if (artistFullNameRef.current?.value) {
          artistFullNameRef.current.value = "";
        }
        break;
      case ARTIST_FORM_TYPES.FEATURE:
        setFArtistSearch();
        setCurrentFArtist();
        if (fArtistSearchRef?.current?.value) {
          fArtistSearchRef.current.value = "";
        }
        if (fArtistIdRef?.current?.value) {
          fArtistIdRef.current.value = "";
        }
        if (fArtistNameRef?.current?.value) {
          fArtistNameRef.current.value = "";
        }
        if (fWalletAddressRef?.current?.value) {
          fWalletAddressRef.current.value = "";
        }
        if (fArtistFullNameRef.current?.value) {
          fArtistFullNameRef.current.value = "";
        }
        break;
      case ARTIST_FORM_TYPES.COMPOSER:
        setComposerSearch();
        setCurrentComposer();
        if (composerSearch?.current?.value) {
          composerSearch.current.value = "";
        }
        if (composerIdRef?.current?.value) {
          composerIdRef.current.value = "";
        }
        if (composerNameRef?.current?.value) {
          composerNameRef.current.value = "";
        }
        if (composerWalletAddressRef?.current?.value) {
          composerWalletAddressRef.current.value = "";
        }
        if (composerFullNameRef.current?.value) {
          composerFullNameRef.current.value = "";
        }

        break;
      case ARTIST_FORM_TYPES.PRODUCER:
        setProducerSearch();
        setCurrentProducer();
        if (producerSearchRef?.current?.value) {
          producerSearchRef.current.value = "";
        }
        if (producerIdRef?.current?.value) {
          producerIdRef.current.value = "";
        }
        if (producerNameRef?.current?.value) {
          producerNameRef.current.value = "";
        }
        if (producerWalletAddressRef?.current?.value) {
          producerWalletAddressRef.current.value = "";
        }
        if (producerFullNameRef.current?.value) {
          producerFullNameRef.current.value = "";
        }
        break;
      case ARTIST_FORM_TYPES.WRITER:
        setCurrentWriter();
        setWriterSearch();
        if (writerSearchRef?.current?.value) {
          writerSearchRef.current.value = "";
        }
        if (writerIdRef?.current?.value) {
          writerIdRef.current.value = "";
        }
        if (writerNameRef?.current?.value) {
          writerNameRef.current.value = "";
        }
        if (writerWalletAddressRef?.current?.value) {
          writerWalletAddressRef.current.value = "";
        }
        if (writerFullNameRef.current?.value) {
          writerFullNameRef.current.value = "";
        }
        break;
      case ARTIST_FORM_TYPES.PAYOUT:
        setCurrentPayoutArtist();
        setPayoutSearch();
        if (payoutArtistSearchRef?.current?.value) {
          payoutArtistSearchRef.current.value = "";
        }
        if (payoutArtistIdRef?.current?.value) {
          payoutArtistIdRef.current.value = "";
        }
        if (payoutArtistNameRef?.current?.value) {
          payoutArtistNameRef.current.value = "";
        }
        if (payoutArtistWalletAddressRef?.current?.value) {
          payoutArtistWalletAddressRef.current.value = "";
        }
        if (payoutArtistFullNameRef.current?.value) {
          payoutArtistFullNameRef.current.value = "";
        }
        if (artistPayoutRef.current?.value) {
          artistPayoutRef.current.value = 0.1;
        }
        break;
      default:
        break;
    }
  };

  const validateSongInfo = () => {
    let vErrors = {};
    //console.log("validating");
    if ((songName && songName?.trim() === "") || !songName) {
      vErrors = {
        ...vErrors,
        songName: "You can't release a song without a name",
      };
    }
    if (!songUpload || getObjectLength(songUpload) === 0 || !songUpload?.url) {
      vErrors = {
        ...vErrors,
        songUpload: "Upload a song before releasing",
      };
    }
    if (
      (isStreamable && getObjectLength(payoutArtists) > 0 &&
      payoutArtists?.some((artist) => artist.payoutPercentage === 0)) || (isStreamable && getObjectLength(payoutArtists) === 0)
    ) {
      vErrors = {
        ...vErrors,
        payoutArtists: "Please enter payout percentage for Artists"};
    }
    if (getObjectLength(payoutArtists) > 0 && payoutArtists?.balance > 100) {
      vErrors = {
        ...vErrors,
        payoutArtists:
        "payouts exceed 100%. please redistribute payouts"};
    }
    if (getObjectLength(mainGenre) === 0 || !mainGenre?.id) {
      vErrors = {
        ...vErrors,
        genre: "Select a main genre",
      };
    }
    if (
      ((visualizerFile?.progress > 0 && visualizerFile?.progress < 100) && !visualizerFile?.url) ||
      ((videoFile?.progress > 0 && videoFile?.progress < 100) && !videoFile?.url)
    ) {
      vErrors = {
        ...vErrors,
        visuals: "Visuals still uploading...",
      };
    }
    if(isUserRoyalties && getObjectLength(userRoyalties) > 0){
      let royaltyErrors = {}
      if(userRoyalties?.ticker.trim().length < 3){
        royaltyErrors = {
          ...royaltyErrors,
          ticker: 'Invalid Ticker. Please enter a valid tick. min 3 characters'
        }
      }
      if(userRoyalties.percentage < 10 || userRoyalties.percentage > 50){
        royaltyErrors = {
          ...royaltyErrors,
          percentage: 'Invalid royalty share percentage. please input value between 10% - 50%'
        }
      }
      if (userRoyalties.shareReserve < 0 || userRoyalties.shareReserve > 50) {
        royaltyErrors = {
          ...royaltyErrors,
          reserve:
            "Invalid royalty share reserve. Please enter a valid reserve amount of 50 or less",
        };
      }
      if(getObjectLength(royaltyErrors) > 0){
        vErrors = {
          ...vErrors,
            userRoyalty: royaltyErrors
          }
      }
    }

    if (getObjectLength(vErrors) > 0) {
      setErrors((prev) => {
        return { ...prev, errors: vErrors };
      });
      return false;
    }
    if (getObjectLength(vErrors) === 0) {
      return true;
    }
  };

  const getDuration = (src) => {
    var reader = new FileReader();
    let audio = new Audio();
    let duration;
    reader.readAsDataURL(src);
    reader.onload = function (e) {
      audio.src = e.target.result;
      return audio.addEventListener(
        "loadedmetadata",
        function () {
          // Obtain the duration in seconds of the audio file (with milliseconds as well, a float value)
          duration = audio.duration;
          setSong((prev) => {
            return { ...prev, duration: duration };
          });
  
          // Alternatively, just display the integer value with
          // parseInt(duration)
          // 12 seconds
        },
        false
      );
    };
  };

  const cancelUpload = () => {
    setSubmit(false);
    setReleaseRequest(false);
  };

  const onSuccess = (id) => {
    modalContent();
    toggleModal();
    setReleaseRequest(false);
    setSubmit(true);
    setSongId(id);
    setErrors({ message: "Song Successfully Released" });
  };

  const onFailure = () => {
    modalContent();
    toggleModal();
    setSubmit(false);
    setErrors({
      message: "Your submission was not successfull, please try again",
    });
  };

  const updateSongInfo = () => {
    let songData = {
      songName: songName?.trim(),
      price: price,
      isrc: isrc?.trim()?.length > 0 ? isrc?.trim() : undefined,
      artists: artists,
      isrcType: isrcType,
      featArtists: featArtists,
      songCredits: {
        composers,
        producers,
        writers,
      },
      composers,
      producers,
      writers,
      autoIsrcCode: autoIsrcCode,
      mainGenre: mainGenre,
      subGenres: subGenres,
      isStreamable,
      isCover,
      isDownloadable,
      isSubscription,
      isUserRoyalties,
      mp3Token: mp3Token,
      isUploading: isUploading,
      currency: isDownloadable ? (currency ? currency : "WYM") : undefined,
      isMintable: isMintable,
      isExplicit: isExplicit ? isExplicit : false,
      payoutArtists: payoutArtists,
      songUpload: songUpload,
      songUrl: songUpload?.url,
      videoFile: videoFile,
      visualizerFile: visualizerFile,
      duration: duration,
      userRoyalties: isUserRoyalties ? userRoyalties : undefined,
      songRef: songUpload?.ref,
      albumArt: albumInfo?.albumArt.url,
      albumArtRef: albumInfo?.albumArt.ref,
      about: about?.trim(),
      uploadedBy: currentUser?.uid,
      songId: songId,
      license: license,
      lyrics: lyrics?.trim(),
      releaseDate: releaseDate ? releaseDate : undefined,
    };
    setAlbumSong(songData, index);
  };

  const uploadData = (type) => {
    setErrors({});
    if (validateSongInfo()) {
      let songData = {
        songName: songName?.trim(),
        price: price,
        isrc: isrc?.trim()?.length > 0 ? isrc?.trim() : undefined,
        artists: artists,
        isrcType: isrcType,
        featArtists: featArtists,
        songCredits: {
          composers,
          producers,
          writers,
        },
        composers,
        producers,
        writers,
        autoIsrcCode: autoIsrcCode,
        mainGenre: mainGenre,
        subGenres: subGenres,
        isStreamable,
        isCover,
        isDownloadable,
        isSubscription,
        isUserRoyalties,
        mp3Token: mp3Token,
        isUploading: isUploading,
        currency: isDownloadable ? (currency ? currency : "WYM") : undefined,
        isMintable: isMintable,
        isExplicit: isExplicit ? isExplicit : false,
        payoutArtists: payoutArtists,
        songUpload: songUpload,
        songUrl: songUpload?.url,
        videoFile: videoFile,
        visualizerFile: visualizerFile,
        duration: duration ? duration : songUpload?.duration,
        userRoyalties: isUserRoyalties ? userRoyalties : undefined,
        songRef: songUpload?.ref,
        mp3Url: songUpload?.mp3Url,
        mp3Ref: songUpload?.mp3Ref,
        albumArt: albumInfo?.albumArt.url,
        albumArtRef: albumInfo?.albumArt.ref,
        about: about?.trim(),
        uploadedBy: currentUser?.uid,
        songId: songId,
        license: license,
        lyrics: lyrics?.trim(),
        releaseDate: releaseDate ? releaseDate : undefined,
      };
      setAlbumSong(songData, index);
      cancelSong(index);
    } else {
      setErrors((prev) => {
        return {
          ...prev,
          message: "Unable to add song. Please review your song information",
        };
      });
    }
    return;
  };

  const validateArtistInput = (currentArtist) => {
    if (currentArtist && currentArtist?.artistName?.trim() !== "") {
      return true;
    }
    return false;
  };

  const validateArtistInfo = (id, type) => {
    switch (type) {
      case ARTIST_FORM_TYPES.MAIN_ARTIST:
        if (validateArtistInput(currentArtist)) {
          artistEditng ? editArtist(id, type) : addArtist(type);
        } else {
        }

        break;
      case ARTIST_FORM_TYPES.FEATURE:
        if (validateArtistInput(currentFArtist)) {
          fArtistEditng ? editArtist(id, type) : addArtist(type);
        } else {
        }
        break;
      case ARTIST_FORM_TYPES.COMPOSER:
        if (validateArtistInput(currentComposer)) {
          composerEditing ? editArtist(id, type) : addArtist(type);
        } else {
        }
        break;
      case ARTIST_FORM_TYPES.PRODUCER:
        if (validateArtistInput(currentProducer)) {
          producerEditing ? editArtist(id, type) : addArtist(type);
        } else {
        }
        break;
      case ARTIST_FORM_TYPES.WRITER:
        if (validateArtistInput(currentWriter)) {
          writerEditing ? editArtist(id, type) : addArtist(type);
        } else {
        }
        break;
      case ARTIST_FORM_TYPES.PAYOUT:
        let total = 0
        payoutArtists.map(a => {
          total = parseFloat(a.payoutPercentage) + parseFloat(total)
          return
        })
        //console.log(total)
        if (
          currentPayoutArtist?.payoutPercentage <= 100 &&
          currentPayoutArtist?.payoutPercentage > 0 && (parseFloat(total) + parseFloat(currentPayoutArtist.payoutPercentage) <= 100)
        ) {
          editArtist(id, type);
           clearArtistForm(type);
        } else {
          setCurrentArtist((prev) => {
            return { ...prev, error: "Invalid payout split" };
          });
        }
        break;
      default:
        break;
    }

   
  };

  const editArtist = (id, type) => {
    let a = {};
    let updatedArtists = [];

    switch (type) {
      case ARTIST_FORM_TYPES.MAIN_ARTIST:
        a = artists;

        updatedArtists = a.map((artist, index) =>
          index === id ? currentArtist : artist
        );

        setArtists(updatedArtists);
        setArtistEditing(false);
        break;
      case ARTIST_FORM_TYPES.FEATURE:
        a = featArtists;

        updatedArtists = a.map((artist, index) =>
          index === id ? currentFArtist : artist
        );

        setFeatArtists(updatedArtists);
        setFArtistEditing(false);
        break;
      case ARTIST_FORM_TYPES.COMPOSER:
        a = composers;

        updatedArtists = a.map((artist, index) =>
          index === id ? currentComposer : artist
        );

        setComposers(updatedArtists);
        setComposerEditing(false);
        break;
      case ARTIST_FORM_TYPES.PRODUCER:
        a = producers;

        updatedArtists = a.map((artist, index) =>
          index === id ? currentProducer : artist
        );

        setProducers(updatedArtists);
        setProducerEditing(false);
        break;
      case ARTIST_FORM_TYPES.WRITER:
        a = writers;

        updatedArtists = a.map((artist, index) =>
          index === id ? currentWriter : artist
        );

        setWriters(updatedArtists);
        setWriterEditing(false);
        break;
      case ARTIST_FORM_TYPES.PAYOUT:
        a = payoutArtists;

        updatedArtists = a.map((artist, index) =>
          index === id ? currentPayoutArtist : artist
        );

        setPayoutArtists(updatedArtists);
        setPayoutEditing(false);
        break;
      default:
        break;
    }

    clearArtistForm(type);
  };

  const removeArtist = (id, type) => {
    let a = [];
    let updatedArtists = [];
    switch (type) {
      case ARTIST_FORM_TYPES.MAIN_ARTIST:
        a = artists;
        updatedArtists = a.filter((artist, index) => {
          if (index !== id) return artist;
        });
        if (containsObject(currentUserArtist, updatedArtists)) {
          setArtists(updatedArtists);
        } else {
          setArtists([currentUserArtist, ...updatedArtists]);
        }
        setArtistEditing(false);
        break;
      case ARTIST_FORM_TYPES.FEATURE:
        a = featArtists;
        updatedArtists = a.filter((artist, index) => {
          if (index !== id) return artist;
        });
        setFeatArtists(updatedArtists);
        setFArtistEditing(false);
        break;
      case ARTIST_FORM_TYPES.COMPOSER:
        a = composers;
        updatedArtists = a.filter((artist, index) => {
          if (index !== id) return artist;
        });
        setComposers(updatedArtists);
        setComposerEditing(false);
        break;
      case ARTIST_FORM_TYPES.PRODUCER:
        a = producers;
        updatedArtists = a.filter((artist, index) => {
          if (index !== id) return artist;
        });
        setProducers(updatedArtists);
        setProducerEditing(false);
        break;
      case ARTIST_FORM_TYPES.WRITER:
        a = writers;
        updatedArtists = a.filter((artist, index) => {
          if (index !== id) return artist;
        });
        setWriters(updatedArtists);
        setWriterEditing(false);

        break;
      case ARTIST_FORM_TYPES.PAYOUT:
        a = payoutArtists;
        updatedArtists = a.filter((artist, index) => {
          if (index !== id) return artist;
        });
        setPayoutArtists(updatedArtists);
        setPayoutEditing(false);
        break;
      default:
        break;
    }

    clearArtistForm(type);
  };

  const addGenre = (musicGenre) => {
    const g = subGenres;
    //console.log("MG: ", musicGenre);
    setGenre();
    setSubgenre();
    if (
      g.findIndex((sG) => sG.id === musicGenre.id) === -1 &&
      g.length - 1 <= 3 &&
      musicGenre?.genreId
    ) {
      g.push(musicGenre);
      setSubGenres(g);
    } else if (!musicGenre?.genreId) {
      setMainGenre(musicGenre);
    } else if (musicGenre?.genreId && g.length - 1 === 2) {
      g[2] = musicGenre;
      setSubGenres(g);
    }
    setSubgenre();
    setGenreFilter();
  };

  const removeGenre = (id, subGenre) => {
    const g = subGenres;
    if (subGenre) {
      const updatedGenres = g.filter((sGenre, index) => {
        if (id !== index && subGenre.id !== sGenre.id) return sGenre;
      });
      setSubGenres(updatedGenres);
    } else {
      setMainGenre();
    }
  };

  const setCreativeCommons = (id) => {
    //console.log("cc: ", id);
    setLicense(id);
  };

  const handleToggle = (type) => {
    switch (type) {
      case "explicit":
        setExplicit(!isExplicit);
        break;
      case "streamable":
        let streamable = !isStreamable;
        if (streamable) {
          setSubscription(false);
        }
        setStreamable(streamable);
        break;
      case "downloadable":
        let downloadable = !isDownloadable;
        if (downloadable) {
          setSubscription(false);
        }
        setDownloadable(downloadable);
        break;

      case "video":
        let video = !isVideo;
          setVideo(video);
        break;

      case "visualizer":
        let visualizer = !isVisualizer;
          setVisualizer(visualizer);
        break;

      case "cover":
       let  cover = !isCover;
          setCover(cover);
        break;

        case "royalties":
          let royalties = !isUserRoyalties;
          if (isUserRoyalties === false) {
            openModal();
            modalContent(
              <OptionModal
                onCancel={() => {
                  closeModal();
                  modalContent();
                }}
                heading={"Enable User Royalties Share"}
                additionalText={
                  "Allow your supporters to invest and earn from your music sales and royalty streams.Once enabled, it cannot be disabled"
                }
              >
                <HoverOption
                  onClick={() => {
                    setIsUserRoyalties(true);
                    if (royalties) {
                      setSubscription(false);
                      setStreamable(true);
                    }
                    if (isDownloadable && royalties) {
                      setCurrency("XRP");
                    }
                    closeModal();
                    modalContent();
                  }}
                >
                  OK
                </HoverOption>
              </OptionModal>
            );
          } else {
            setIsUserRoyalties(false);
          }
          
          
         /*  let royalties = !isUserRoyalties
          setIsUserRoyalties(royalties)
          if(royalties){
          setSubscription(false);
          setStreamable(true);}
          if(isDownloadable && royalties){
            setCurrency("XRP")
          } */
        break;

        case "preorder":
          let preOrder = !isPreOrder;
            setPreOrder(preOrder);
          break;

      case "subscription":
        let subscription = !isSubscription;
        setSubscription(subscription);
        if (subscription) {
          setDownloadable(false);
          setStreamable(false);
        }
        
        break;
      case "mintable":
       let mintable = !isMintable;
        if (mintable) {
          toggleModal();
          modalContent(
            <OptionModal
              onCancel={() => {
                toggleModal();
                modalContent();
              }}
              heading={"Set Mintable?"}
            >
              <SongDetailsText>{`By clicking 'OK', you agree to upload your music \n 
        on IPFS and allow for it to be minted as an NFT on our platform.`}</SongDetailsText>
              <HoverOption
                onClick={async () => {
                  setMintable(true)
                      toggleModal()
                          modalContent()
                 /*modalContent(< UnavailableFeature/>)
                   modalContent(<OptionModal><ModalLoadingScreen text={'Updating. Please Wait...'} transparent={true}/></OptionModal>)
                  await validateNFTStorageKey(currentUserArtist?.artistId).then(data => {
                    if(data){
                      setMintable(true)
                      toggleModal()
                          modalContent()
                    }else{
                      modalContent(<>
                      <OptionModal heading={"NFT Storage API Key Not Found"}>
                        <HoverOption onClick={() => {
                          modalContent(<>
                            <NFTStorageTokenForm artistId={currentUserArtist?.artistId} currentUser={currentUser?.uid} setMintable={setMintable}/>
                          </>)
                        }}>
                          Add API Key
                        </HoverOption>
                        <HoverOption onClick={() => {
                          modalContent(<NFTStorageSonarAPIKeyForm artistId={currentUserArtist?.artistId} currentUser={currentUser?.uid} onSuccess={() => {setMintable(true)}}/>)
                        }}> Use Sonar Muse API Key</HoverOption>
                        
                        <HoverOption onClick={() => {
                          toggleModal()
                          modalContent()
                        }}>
                         Cancel
                        </HoverOption>
                      </OptionModal>
                    </>)
                       
                    }
                  }).catch(err => {
                    setMintable(false)
                    modalContent(<>
                      <OptionModal heading={"Something Went Wrong. Please try again"}>
                        <HoverOption onClick={() => {
                          toggleModal()
                          modalContent()
                        }}>
                          Close
                        </HoverOption>
                      </OptionModal>
                    </>)
                  }) */
                 

                }}
              >
                OK
              </HoverOption>
            </OptionModal>
          );
        } else {
          setMintable(false);
        }

        break;
      default:
        break;
    }
    if (!isDownloadable && !isStreamable) {
      setSubscription(true);
    }
  };

  const handleArtistInput = (e, type) => {
    const cArtist = currentArtist;
    const cFArtist = currentFArtist;
    const cWriter = currentWriter;
    const cProducer = currentProducer;
    const cComposer = currentComposer;
    const cPayout = currentPayoutArtist;

    switch (e.target.name) {
      case ARTIST_FORM_INPUT_TYPES.NAME:
        switch (type) {
          case ARTIST_FORM_TYPES.MAIN_ARTIST:
            setCurrentArtist({ ...cArtist, artistName: e.target.value });
            if (e.target.value.trim() !== "") {
              delete cArtist?.errors?.name;
              
            } else {
              setCurrentArtist({
                ...cArtist,
                errors: {
                  ...cArtist?.errors,
                  name: "Artist name cannot be empty",
                },
                artistName: e.target.value,
              });
            }

            AddNEArtistsToSearch(e);
            break;
          case ARTIST_FORM_TYPES.FEATURE:
            setCurrentFArtist({ ...cFArtist, artistName: e.target.value });
            if (e.target.value.trim() !== "") {
              delete cFArtist?.errors?.name;
            } else {
              setCurrentArtist({
                ...cArtist,
                errors: {
                  ...cArtist?.errors,
                  name: "Artist name cannot be empty",
                },
                artistName: e.target.value,
              });
            }
            break;
          case ARTIST_FORM_TYPES.COMPOSER:
            setCurrentComposer({ ...cComposer, artistName: e.target.value });
            if (e.target.value.trim() !== "") {
              delete cComposer?.errors?.name;
              
            } else {
              setCurrentArtist({
                ...cArtist,
                errors: {
                  ...cArtist?.errors,
                  name: "Artist name cannot be empty",
                },
                artistName: e.target.value,
              });
            }
            break;
          case ARTIST_FORM_TYPES.PRODUCER:
            setCurrentProducer({ ...cProducer, artistName: e.target.value });
            if (e.target.value.trim() !== "") {
              delete cProducer?.errors?.name;
            } else {
              setCurrentArtist({
                ...cArtist,
                errors: {
                  ...cArtist?.errors,
                  name: "Artist name cannot be empty",
                },
                artistName: e.target.value,
              });
            }
            break;
          case ARTIST_FORM_TYPES.WRITER:
            setCurrentWriter({ ...cWriter, artistName: e.target.value });
            if (e.target.value.trim() !== "") {
              delete cWriter?.errors?.name;
             
            } else {
              setCurrentArtist({
                ...cArtist,
                errors: {
                  ...cArtist?.errors,
                  name: "Artist name cannot be empty",
                },
                artistName: e.target.value,
              });
            }
            break;
          case ARTIST_FORM_TYPES.PAYOUT:
            setCurrentPayoutArtist({
              ...cPayout,
              artistName: e.target.value,
            });
            if (e.target.value.trim() !== "") {
              delete cPayout?.errors?.name;
            } else {
              setCurrentArtist({
                ...cArtist,
                errors: {
                  ...cArtist?.errors,
                  name: "Artist name cannot be empty",
                  artistName: e.target.value,
                },
              });
            }
            break;
          default:
            break;
        }

        break;
      case ARTIST_FORM_INPUT_TYPES.FULL_NAME:
        switch (type) {
          case ARTIST_FORM_TYPES.MAIN_ARTIST:
            setCurrentArtist({ ...cArtist, fullName: e.target.value });
            break;
          case ARTIST_FORM_TYPES.FEATURE:
            setCurrentFArtist({ ...cFArtist, fullName: e.target.value });
            break;
          case ARTIST_FORM_TYPES.COMPOSER:
            setCurrentComposer({ ...cComposer, fullName: e.target.value });
            break;
          case ARTIST_FORM_TYPES.PRODUCER:
            setCurrentProducer({ ...cProducer, fullName: e.target.value });
            break;
          case ARTIST_FORM_TYPES.WRITER:
            setCurrentWriter({ ...cWriter, fullName: e.target.value });
            break;
          case ARTIST_FORM_TYPES.PAYOUT:
            setCurrentPayoutArtist({ ...cPayout, fullName: e.target.value });
            //console.log(currentPayoutArtist);
            break;
          default:
            break;
        }
        break;
      case ARTIST_FORM_INPUT_TYPES.ID:
        switch (type) {
          case ARTIST_FORM_TYPES.MAIN_ARTIST:
            setCurrentArtist({ ...cArtist, artistId: e.target.value });
            break;
          case ARTIST_FORM_TYPES.FEATURE:
            setCurrentFArtist({ ...cFArtist, artistId: e.target.value });
            break;
          case ARTIST_FORM_TYPES.COMPOSER:
            setCurrentComposer({ ...cComposer, artistId: e.target.value });
            break;
          case ARTIST_FORM_TYPES.PRODUCER:
            setCurrentProducer({ ...cProducer, artistId: e.target.value });
            break;
          case ARTIST_FORM_TYPES.PAYOUT:
            setCurrentPayoutArtist({ ...cPayout, artistId: e.target.value });
            break;
          default:
            break;
        }
        break;
      case ARTIST_FORM_INPUT_TYPES.ADDRESS:
        switch (type) {
          case ARTIST_FORM_TYPES.MAIN_ARTIST:
            setCurrentArtist({ ...cArtist, address: e.target.value });
            break;
          case ARTIST_FORM_TYPES.FEATURE:
            setCurrentFArtist({ ...cFArtist, address: e.target.value });
            break;
          case ARTIST_FORM_TYPES.COMPOSER:
            setCurrentComposer({ ...cComposer, address: e.target.value });
            break;
          case ARTIST_FORM_TYPES.PRODUCER:
            setCurrentProducer({ ...cProducer, address: e.target.value });
            break;
          case ARTIST_FORM_TYPES.WRITER:
            setCurrentWriter({ ...cWriter, address: e.target.value });
            break;
          case ARTIST_FORM_TYPES.PAYOUT:
            setCurrentPayoutArtist({ ...cPayout, address: e.target.value });
            break;
          default:
            break;
        }
        break;
      case ARTIST_FORM_INPUT_TYPES.PAYOUT_PERCENTAGE:
        if(e.target.value?.length > 0 && e.target.value <= 100 ){
          setCurrentPayoutArtist({
            ...cPayout,
            payoutPercentage: parseInt(e.target.value) }
          );
          return
        }
        if(e.target.value?.length === 0){
          setCurrentPayoutArtist({
            ...cPayout,
            payoutPercentage: 0 
          })
        }

        break;

      default:
        break;
    }
  };

  const searchInput = (e, type) => {
    if (e.target.value.length > 0 && type !== ARTIST_FORM_TYPES.PAYOUT) {
      searchArtists(e.target.name, e.target.value).then((data) => {
        if (data && (!data.error || data !== false)) {
          //console.log(data);
          switch (type) {
            case ARTIST_FORM_TYPES.MAIN_ARTIST:
              setArtistSearch({
                type: e.target.name,
                artists: data.filter((artist) => {
                  if (filterExistingArtist(artists, artist) === false) {
                    return artist;
                  }
                }),
              });
              break;
            case ARTIST_FORM_TYPES.FEATURE:
              setFArtistSearch({
                type: e.target.name,
                artists: data.filter((artist) => {
                  if (featArtists) {
                    if (filterExistingArtist(featArtists, artist) === false) {
                      return artist;
                    }
                  } else {
                    return artist;
                  }
                }),
              });
              break;
            case ARTIST_FORM_TYPES.COMPOSER:
              setComposerSearch({
                type: e.target.name,
                artists: data.filter((artist) => {
                  if (filterExistingArtist(composers, artist) === false) {
                    return artist;
                  }
                }),
              });
              break;
            case ARTIST_FORM_TYPES.PRODUCER:
              setProducerSearch({
                type: e.target.name,
                artists: data.filter((artist) => {
                  if (filterExistingArtist(producers, artist) === false) {
                    return artist;
                  }
                }),
              });
              break;
            case ARTIST_FORM_TYPES.WRITER:
              setWriterSearch({
                type: e.target.name,
                artists: data.filter((artist) => {
                  if (filterExistingArtist(writers, artist) === false) {
                    return artist;
                  }
                }),
              });
              break;
            case ARTIST_FORM_TYPES.PAYOUT:
              setPayoutSearch({
                type: e.target.name,
                artists: data.filter((artist) => {
                  if (filterExistingArtist(payoutArtists, artist) === false) {
                    return artist;
                  }
                }),
              });
              break;
            default:
              break;
          }
        } else {
          //Error check
        }
        return;
      });
    }

    if (type === ARTIST_FORM_TYPES.PAYOUT && e.target.value.length > 0) {
      setPayoutSearch({
        type: e.target.name,
        artists: displaySelectedArtists().filter((artist) => {
            let a = false;
            let pArtists = payoutArtists
            
            switch (e.target.name) {
              case ARTIST_SEARCH_TYPES.NAME:
                if (
                  artist?.artistName &&
                  artist.artistName
                    .toLowerCase()
                    .includes(e.target.value.toLowerCase()) && pArtists.some(a => a.artistName.includes(artist.artistName))=== false
                ) {
                  a = true;
                }
  
                break;
              case ARTIST_SEARCH_TYPES.FULL_NAME:
                if (
                  artist?.fullName &&
                  artist?.fullName
                    .toLowerCase()
                    .includes(e.target.value.toLowerCase()) && pArtists.some(a => a.artistName.includes(artist.artistName))=== false
                ) {
                  a = true;
                }
                break;
              case ARTIST_SEARCH_TYPES.ADDRESS:
                if (
                  artist?.address &&
                  artist?.address
                    .toLowerCase()
                    .includes(e.target.value.toLowerCase()) && pArtists.some(a => a.artistName.includes(artist.artistName))=== false
                ) {
                  a = true;
                }
                break;
              case ARTIST_SEARCH_TYPES.ID:
                if (
                  artist?.artistId &&
                  artist?.artistId
                    .toLowerCase()
                    .includes(e.target.value.toLowerCase()) && pArtists.some(a => a.artistName.includes(artist.artistName))=== false
                ) {
                  a = true;
                }
                break;
              default:
                break;
            }
            if (a === true) {
              return artist;
            }
          }),
      });
    }
    if (e.target.value.length === 0) {
      switch (type) {
        case ARTIST_FORM_TYPES.MAIN_ARTIST:
          setArtistSearch();
          break;
        case ARTIST_FORM_TYPES.FEATURE:
          setFArtistSearch();
          break;
        case ARTIST_FORM_TYPES.COMPOSER:
          setComposerSearch();
          break;
        case ARTIST_FORM_TYPES.PRODUCER:
          setProducerSearch();
          break;
        case ARTIST_FORM_TYPES.WRITER:
          setWriterSearch();
          break;
        case ARTIST_FORM_TYPES.PAYOUT:
          setPayoutSearch();
          break;
        default:
          break;
      }
    }
  };

  //Filter out existing artists in list when searching
  const filterExistingArtist = (artists1, searchArtist) => {
    let artistExists = false;
    artists1.map((artist) => {
      if (artist?.artistId === searchArtist.artistId) {
        artistExists = true;
      }
    });
    return artistExists;
  };

  const displaySelectedArtists = () => {
    let allEArtists = [
      ...artists.filter((a) => a.exists),
      ...featArtists.filter((a) => a.exists && currentUserArtist?.artistId !== a?.artistId),
      ...composers.filter((a) => a.exists && currentUserArtist?.artistId !== a?.artistId),
      ...producers.filter((a) => a.exists && currentUserArtist?.artistId !== a?.artistId),
      ...writers.filter((a) => a.exists && currentUserArtist?.artistId !== a?.artistId),
    ];
    let allNEArtists = [
      ...artists.filter((a) => !a.exists),
      ...featArtists.filter((a) => !a.exists ),
      ...composers.filter((a) => !a.exists),
      ...producers.filter((a) => !a.exists),
      ...writers.filter((a) => !a.exists),
    ];
    let allArtists = [
      ...getUniqueListBy(allEArtists, "artistId"),
      ...getUniqueListBy(allNEArtists, "artistName"),
    ];
    return allArtists;
  };

  //Add newly added (Non-existing) artists to searches
  const AddNEArtistsToSearch = (e) => {
    const a = [
      ...artists,
      ...featArtists,
      ...composers,
      ...producers,
      ...writers,
    ];
    const nEArtists = getUniqueListBy(
      a.filter((artist) => !artist.exists),
      "artistName"
    );
    let filteredArtists = [];

    switch (e.target.name) {
      case ARTIST_FORM_INPUT_TYPES.ID:
        filteredArtists = nEArtists.filter((artist) =>
          artist.id.includes(e.target.value)
        );
        break;

      case ARTIST_FORM_INPUT_TYPES.ADDRESS:
        filteredArtists = nEArtists.filter((artist) =>
          artist.address.includes(e.target.value)
        );
        break;

      case ARTIST_FORM_INPUT_TYPES.NAME:
        filteredArtists = nEArtists.filter((artist) =>
          artist.artistName.includes(e.target.value)
        );
        break;

      case ARTIST_FORM_INPUT_TYPES.FULL_NAME:
        filteredArtists = nEArtists.filter((artist) =>
          artist.fullName.includes(e.target.value)
        );
        break;
      default:
        break;
    }
    if (filteredArtists.length > 0) {
      return filteredArtists;
    } else return [];
  };

  const handleInput = async (e) => {
    const currSong = songUpload;
    let prevErrors = errors;
    e.preventDefault();
    switch (e.target.name) {
      case "songUpload" + index:
        //console.log(e.target.files[0]);
        if (e.target.value && e.target.files[0]) {
          let songFileName;
          if (!songUpload.name) {
            songFileName = `${Math.round(Math.random() * 10000000000000)}.${
              e.target.files[0].name.split(".")[
                e.target.files[0].name.split(".").length - 1
              ]
            }`;
            setSong({
              file: e.target.files[0],
              name: songFileName,
              ref: `artists/${currentUser?.uid}/${songFileName}`,
            });
          } else {
            songFileName = songUpload.name;
            setSong((prev) => {
              return {
                ...prev,
                file: e.target.files[0],
                ref: `artists/${currentUser?.uid}/${songFileName}`,
              };
            });
          }
          getDuration(e.target.files[0]);
          try {
            setUploading(true);
            //console.log(isUploading);
            const uploadTask = uploadBytes(
              storageRef(
                storage,
                `artists/${currentUser?.uid}/${songFileName}`
              ),
              e.target.files[0],
              {
                contentType: e.target.files[0].type,
                customMetadata: {
                  fileName: e.target.files[0].name,
                },
              }
            );

            uploadTask.on(
              "state_changed",
              (snapshot) => {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress =
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                updateSongInfo();

                setSong((prev) => {
                  return { ...prev, progress: progress };
                });
                //console.log("Upload is " + progress + "% done");
                switch (snapshot.state) {
                  case "paused":
                    //console.log("Upload is paused");
                    break;

                  case "running":
                    setUploading(true);
                    //console.log("Upload is running");
                    break;
                  default:
                    setUploading(true);
                    //console.log("Upload is " + progress + "% done");
                    break;
                }
              },
              (error) => {
                // A full list of error codes is available at
                setUploading(false);
                switch (error.code) {
                  case "storage/unauthorized":
                    // User doesn't have permission to access the object
                    break;
                  case "storage/canceled":
                    // User canceled the upload
                    break;

                  // ...

                  case "storage/unknown":
                    // Unknown error occurred, inspect error.serverResponse
                    break;

                  default:
                    break;
                }
              },
              () => {
                setUploading(false);
                // Upload completed successfully, now we can get the download URL
                getDownloadUrl(uploadTask.snapshot.ref).then(async(downloadURL) => {
                  //console.log("File available at", downloadURL);
                  return await createMp3File({uid: currentUser?.uid, token: mp3Token, ref: uploadTask.snapshot.ref.fullPath, url: downloadURL }).then((mp3File) => {
                    if(mp3File){
                    setSong((prev) => {
                      return {
                        ...prev,
                        url: downloadURL,
                        file: e.target.files[0],
                        ref: uploadTask.snapshot.ref.fullPath,
                        mp3Url: mp3File.mp3Url,
                        mp3Ref: mp3File.mp3Ref
                      };
                    });}else{
                      setSong((prev) => {
                        return {
                          ...prev,
                          url: downloadURL,
                          file: e.target.files[0],
                          ref: uploadTask.snapshot.ref.fullPath,
                        };
                      })
                    }
                  })
  
                });

                updateSongInfo();
              }
            );
          } catch (err) {
            setUploading(false);
            return;
          }
        }
        break;
      case "name " + index:
        if (e.target.value.trim() === "") {
          setErrors({
            ...prevErrors,
            songName: "You can't release a song without a name",
          });
        } if (songNameContainsFeature(e.target.value) ) {
          setErrors({
            ...prevErrors,
            songName: "Song name must not contain featured any artist(s). Enter featured artist(s) on page 2",
          });
        }
         else {
          delete prevErrors.songName;
          setErrors(prevErrors);
          //console.log("errors:", prevErrors);
        }
        setName(e.target.value);
        break;
      case "main genre " + index:
        if (e.target.value.trim() !== "" && e.target.value.length > 0) {
          searchGenres(e.target.value).then((g) => {
            setGenreFilter(g.genres);
          });
        } else {
          setGenreFilter();
        }
        setGenre(e.target.value.trim());
        break;

      case "subGenre " + index:
        if (e.target.value.trim() !== "" && e.target.value.length > 0) {
          setSubGenreFilter();
          searchGenres(e.target.value).then((g) => {
            //console.log(g.subGenres);
            setSubGenreFilter(g.subGenres);
          });
        } else {
          setSubGenreFilter();
        }
        setSubgenre(e.target.value);
        break;
      case "price " + index:
        if(e.target.value[0] === "0"){
          setPrice(e.target.value.substring(1,e.target.value.length)); 
         }else{
           setPrice(e.target.value)
         }
         
         if(!isNaN(e.target.value) && e.target.value > 0){
           delete prevErrors.price
           setErrors(prevErrors)
           return
         }
         if(e.target.value?.length === 0){
           
           setPrice(0)
         }
         if(isNaN(e.target.value)){
         /*   err = {
             ...err,
             price: "Invalid Price. Please enter price or leave 0"
           } */
           setPrice(parseFloat(e.target.value))
           /* setErrors(err) */
           return
         }
        break;

      case "isrc " + index:
        setISRC(e.target.value.toUpperCase());
        if (isrcReg.test(e.target.value)) {
          setIsrcValid(true);
          //console.log("valid ISRC: ", true);
        } else {
          setIsrcValid(false);
          //console.log("valid ISRC: ", false);
        }
        break;

      case "isrcType":
        switch (e.target.value) {
          case "auto":
            setIsrcType("auto");
            isrcRef.current.value = autoIsrcCode;
            //console.log("ISRC AUTO: ", autoIsrcCode);
            setISRC(autoIsrcCode);
            break;

          case "custom":
            setISRC("");
            setIsrcType("custom");
            isrcRef.current.value = "";

            break;

          default:
            setIsrcType("auto");
            isrcRef.current.value = autoIsrcCode;
            setISRC(autoIsrcCode);
            break;
        }
        break;

      case "storageToken":
        //console.log(e.target.value);
        setToken(e.target.value);
        break;
      case "releaseDateSong " + index:
        setReleaseDate(e.target.value);
        break;
      case "about " + index:
        setAbout(newlineFormatter(e.target.value));
        break;
      case "lyrics " + index:
        setLyrics(newlineFormatter(e.target.value));
        break;

      default:
        break;
    }
    updateSongInfo();
  };

  return (
    <>
      <MusicTitleInfoContainer style={{ background: "transparent" }}>
        <>
          <MusicUploadTitleArtContainer>
            <MusicUploadAlbumArt
              src={albumArt?.url ? albumArt.url : defaultAlbumArt}
              alt={songName}
              onContextMenu={(e) => {
                e.preventDefault();
              }}
            />
          </MusicUploadTitleArtContainer>
          <MusicUploadTitleTextWrapper>
            <MusicUploadContentTitle style={{ fontStyle: "italic" }}>
              {songName.trim().length > 0
                ? featArtists.length > 0
                  ? songName +
                    " (ft. " +
                    featArtists.map((artist, index) => {
                      if (index === featArtists.length - 2) {
                        return artist.artistName + " & ";
                      } else if (index < featArtists.length - 2) {
                        return artist.artistName + ", ";
                      } else if (index === featArtists.length - 1) {
                        return artist.artistName;
                      }
                    }) +
                    ")"
                  : songName
                : "Song Name"}{" "}
              {isExplicit && <MdExplicit />}
            </MusicUploadContentTitle>
            <>
              <MusicUploadInfoText>
                <>
                  {artists.length > 0
                    ? artists.map((artist, index) => {
                        if (index === artists.length - 2) {
                          return artist.artistName + " & ";
                        } else if (index === artists.length - 1) {
                          return artist.artistName;
                        } else if (index <= artists.length - 1) {
                          return artist.artistName + ", ";
                        }
                      })
                    : "Artist(s)"}
                </>
              </MusicUploadInfoText>
            </>
            <MusicUploadInfoText>
              {releaseDate
                ? new Date(releaseDate).toDateString()
                : "Release Date"}
            </MusicUploadInfoText>
            {isDownloadable === true && (
              <MusicUploadInfoText>
                {price ? `${price} ${currency}` : "Price"}
              </MusicUploadInfoText>
            )}
          </MusicUploadTitleTextWrapper>
        </>
        <SongDetailsSection>
          <AlbumUploadsContainer
            for={
              songUpload?.progress &&
              songUpload.progress > 0 &&
              !songUpload?.url
                ? ""
                : "songUpload" + index
            }
          >
            {songUpload?.url && (
              <span style={{ display: "flex", flexDirection: "column" }}>
                <SongDetailsText>Replace Audio File</SongDetailsText>
                <SongDetailsText style={{ fontStyle: "italic" }}>
                  Upload Complete
                </SongDetailsText>
              </span>
            )}
            {!songUpload?.url && !songUpload.progress && (
              <>
                <SongDetailsText>Add Track</SongDetailsText>
                <SongDetailsText style={{ fontStyle: "italic" }}>
                  Select lossless format .wav, .aif or .flac
                </SongDetailsText>
              </>
            )}
            {!songUpload?.url &&
              songUpload.progress > 0 &&
              songUpload.progress < 100 && (
                <span
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <SongDetailsText>Uploading...</SongDetailsText>
                  <SongDetailsText
                    style={{ padding: "2%", textDecoration: "underline" }}
                    onClick={() => {
                      //console.log("cancelled");
                    }}
                  >
                    Cancel
                  </SongDetailsText>
                </span>
              )}
            <FileUploader
                ref={songUploadRef}
                id={"songUpload" + index}
                name={"songUpload" + index}
                fileTypes={".wav"}
                userType={"artists"}
                setDuration={setDuration}
                fileUpload={songUpload}
                setFileUpload={setSong}
                acceptMultiple={false}
              />
          </AlbumUploadsContainer>
          {songUpload?.progress > 0 && !songUpload?.url && (
            <>
              <UploadProgressWrapper>
                <PushSpinner loading={songUpload?.url ? false : true} />

                <SongDetailsText style={{ fontStyle: "italic", padding: "2%" }}>
                  {songUpload?.progress > 0 &&
                    songUpload?.progress < 100 &&
                    !songUpload?.url &&
                    `${songUpload?.progress.toFixed(2)}%`}
                  {songUpload?.progress === 100 &&
                    !songUpload?.url &&
                    `Processing...`}
                </SongDetailsText>
              </UploadProgressWrapper>
            </>
          )}
          <MusicUploadInfoText>
            {songUpload?.file?.name ? songUpload?.file?.name : ""}
          </MusicUploadInfoText>
        </SongDetailsSection>
      </MusicTitleInfoContainer>
      <SongDetailsContainer>
        <SongDetailsWrapper>
          {pageNumber === 1 && (
            <Page1
              index={index}
              handleToggle={handleToggle}
              isExplicit={isExplicit}
              isPreOrder={isPreOrder}
              isCover={isCover}
              isSubscription={isSubscription}
              isStreamable={isStreamable}
              isDownloadable={isDownloadable}
              isMintable={isMintable}
              isVideo={isVideo}
              isVisualizer={isVisualizer}
              songNameRef={songNameRef}
              songName={songName}
              handleInput={handleInput}
              errors={errors}
              setCurrency={setCurrency}
              songPriceRef={songPriceRef}
              currency={currency}
              price={price}
              genreRef={genreRef}
              genre={genre}
              mainGenre={mainGenre}
              removeGenre={removeGenre}
              filteredGenres={filteredGenres}
              addGenre={addGenre}
              subgenre={subgenre}
              subGenres={subGenres}
              filteredSubGenres={filteredSubGenres}
            />
          )}

          {pageNumber === 2 && (
            <Page2
              index={index}
              artists={artists}
              artistSearchRef={artistSearchRef}
              removeArtist={removeArtist}
              clearArtistForm={clearArtistForm}
              setCurrentArtist={setCurrentArtist}
              setArtistEditing={setArtistEditing}
              handleInput={handleArtistInput}
              searchInput={searchInput}
              currentArtist={currentArtist}
              walletAddressRef={walletAddressRef}
              artistSearch={artistSearch}
              artistNameRef={artistNameRef}
              artistFullNameRef={artistFullNameRef}
              artistIdRef={artistIdRef}
              selectArtist={selectArtist}
              validateArtistInfo={validateArtistInfo}
              artistEditng={artistEditng}
              featArtists={featArtists}
              fArtistSearchRef={fArtistSearchRef}
              setCurrentFArtist={setCurrentFArtist}
              setFArtistEditing={setFArtistEditing}
              handleArtistInput={handleArtistInput}
              currentFArtist={currentFArtist}
              fWalletAddressRef={fWalletAddressRef}
              featArtistSearch={featArtistSearch}
              fArtistNameRef={fArtistNameRef}
              fArtistFullNameRef={fArtistFullNameRef}
              fArtistIdRef={fArtistIdRef}
              fArtistEditng={fArtistEditng}
              errors={errors}
            />
          )}

          {pageNumber === 3 && (
            <Fade>
              <Page3
                index={index}
                isStreamable={isStreamable}
                isDownloadable={isDownloadable}
                isrcType={isrcType}
                isrcRef={isrcRef}
                handleInput={handleInput}
                isrc={isrc}
                errors={errors}
                aboutRef={aboutRef}
                lyricsRef={lyricsRef}
                releaseDateRef={releaseDateRef}
                about={about}
                lyrics={lyrics}
                releaseDate={releaseDate}
              />
            </Fade>
          )}

          {pageNumber === 4 && (
            <Fade>
              <Page4
                index={index}
                composers={composers}
                composerSearchRef={composerSearchRef}
                removeArtist={removeArtist}
                clearArtistForm={clearArtistForm}
                setComposerEditing={setComposerEditing}
                setCurrentComposer={setCurrentComposer}
                handleArtistInput={handleArtistInput}
                searchInput={searchInput}
                currentComposer={currentComposer}
                composerEditing={composerEditing}
                composerWalletAddressRef={composerWalletAddressRef}
                composerSearch={composerSearch}
                composerNameRef={composerNameRef}
                composerIdRef={composerIdRef}
                composerFullNameRef={composerFullNameRef}
                selectArtist={selectArtist}
                validateArtistInfo={validateArtistInfo}
                errors={errors}
                writerEditing={writerEditing}
                writers={writers}
                writerSearchRef={writerSearchRef}
                setCurrentWriter={setCurrentWriter}
                setWriterEditing={setWriterEditing}
                currentWriter={currentWriter}
                writerWalletAddressRef={writerWalletAddressRef}
                writerSearch={writerSearch}
                writerNameRef={writerNameRef}
                writerFullNameRef={writerFullNameRef}
                writerIdRef={writerIdRef}
                producerSearchRef={producerSearchRef}
                producers={producers}
                setCurrentProducer={setCurrentProducer}
                setProducerEditing={setProducerEditing}
                currentProducer={currentProducer}
                producerWalletAddressRef={producerWalletAddressRef}
                producerSearch={producerSearch}
                producerNameRef={producerNameRef}
                producerFullNameRef={producerFullNameRef}
                producerIdRef={producerIdRef}
                producerEditing={producerEditing}
              />
            </Fade>
          )}

          {pageNumber === 5 && (
            <Fade>
              <Page5
                visualizerFile={visualizerFile}
                videoFile={videoFile}
                setVisualizerFile={setVisualizerFile}
                setVideoFile={setVideoFile}
              />
            </Fade>
          )}
          {pageNumber === 6 && (
            <Fade>
              <Page6
                license={license}
                setCreativeCommons={setCreativeCommons}
              />
            </Fade>
          )}
          {pageNumber === 7 && (
            <Fade>
              <Page7
                payoutArtists={payoutArtists}
                errors={errors}
                payoutArtistSearchRef={payoutArtistSearchRef}
                removeArtist={removeArtist}
                clearArtistForm={clearArtistForm}
                setCurrentPayoutArtist={setCurrentPayoutArtist}
                setPayoutEditing={setPayoutEditing}
                handleArtistInput={handleArtistInput}
                searchInput={searchInput}
                currentPayoutArtist={currentPayoutArtist}
                payoutArtistFullNameRef={payoutArtistFullNameRef}
                payoutArtistWalletAddressRef={payoutArtistWalletAddressRef}
                payoutSearch={payoutSearch}
                payoutArtistNameRef={payoutArtistNameRef}
                payoutArtistIdRef={payoutArtistIdRef}
                artistPayoutRef={artistPayoutRef}
                selectArtist={selectArtist}
                validateArtistInfo={validateArtistInfo}
                payoutEditing={payoutEditing}
              />
            </Fade>
          )}
        </SongDetailsWrapper>

        <SongArtCoverWrapper>
          <Zoom>
            {/* <SongDetailsSection>
              <SongDetailsSectionTitle>Video File</SongDetailsSectionTitle>
              <AlbumUploadsContainer for="video">
                <SongDetailsText>
                  {videoFile?.url 
                    ? `${videoFileRef.current?.files[0]?.name}`
                    : `Click here to add visual file`}
                </SongDetailsText>
                <AlbumUploadsInput
                  id="video"
                  ref={videoFileRef}
                  type={"file"}
                  name="video"
                  accept="image/gif,video/mp4,video/x-m4v,video/*"
                  multiple="false"
                  onChange={(e) => {
                    handleInput(e);
                  }}
                />
              </AlbumUploadsContainer>
            </SongDetailsSection>

            {nftMint && (
              <SongDetailsSection>
                <SongDetailsSectionTitle>
                  Additional Files
                </SongDetailsSectionTitle>
                <AlbumUploadsContainer for="addFiles">
                  <SongDetailsText>
                    {additionalFiles && additionalFiles.length > 0
                      ? `${additionalFilesRef.current?.files[0]?.name}`
                      : `Add additional files (Max 5)`}
                  </SongDetailsText>
                  <AlbumUploadsInput
                    id="addFiles"
                    ref={additionalFilesRef}
                    type={"file"}
                    max={5}
                    name="addFiles"
                    accept="image/x-png,image/gif,image/jpeg, image/jpg"
                    multiple="false"
                    onChange={(e) => {
                      handleInput(e);
                    }}
                  />
                </AlbumUploadsContainer>
              </SongDetailsSection>
                  )}*/}
          </Zoom>
        </SongArtCoverWrapper>
      </SongDetailsContainer>
      {errors.message && (
        <ErrorWrapper
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <SongDetailsText error>{errors.message}</SongDetailsText>
        </ErrorWrapper>
      )}
      <PageIndexer pages={7} currentPage={pageNumber} onClick={setPageNum} />
      <MusicUploadsButtonContainer style={{ background: "transparent" }}>
        {
          //<MusicUploadsButton
          //outline
          //to={"#upload"}
          // onClick={() => uploadData("draft")}
          //>
          //</MusicUploadsButtonContainer>Save Draft
          //</MusicUploadsButton>
        }
        {pageNumber > 1 && (
          <MusicUploadsButton outline onClick={() => prevPage()}>
            Prev
          </MusicUploadsButton>
        )}
        <MusicUploadsButton
          outline
          onClick={() =>
            pageNumber === 7
              ? (isUploading || (visualizerFile?.progress > 0 && visualizerFile?.progress < 100) || (videoFile?.progress > 0 && videoFile?.progress < 100))
                ? addAlert({
                    title: "Song Upload",
                    type: ALERT_TYPES.WARNING,
                    message: "Song still uploading files, please wait",
                  })
                : uploadData()
              : nextPage()
          }
        >
          {pageNumber === 7 ? "OK" : "Next"}
        </MusicUploadsButton>
        <MusicUploadsButton
          backgroundColor={Theme({}).errorColor}
          onClick={() => deleteSong(index)}
        >
          Delete
        </MusicUploadsButton>
        <MusicUploadsButton
          backgroundColor={Theme({}).accentColor}
          onClick={() => {
            if (isUploading || (visualizerFile?.progress > 0 && visualizerFile?.progress < 100) || (videoFile?.progress > 0 && videoFile?.progress < 100)) {
              addAlert({
                title: "Song Upload",
                type: ALERT_TYPES.WARNING,
                message: "Song still uploading files, please wait",
              });
            } else {
              updateSongInfo();
              cancelSong(index);
            }
          }}
        >
          Minimize
        </MusicUploadsButton>
      </MusicUploadsButtonContainer>
    </>
  );
}

export default SongDetails;
