import React from 'react'
import Comment from './Comment'

const CommentList = ({comments, refresh}) => {

  return (<>{comments.map(comment => {
            return(
            
                <Comment comment={comment} comments={comments} refresh={refresh}/>
            )
        })}</>)
  

}

export default CommentList