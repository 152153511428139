import styled, { keyframes } from 'styled-components'
import { CardContainer } from '../Card/CardElements'
import { CardImg } from '../Card/DigitalPurchaseCard/DigitalPurchaseCardElements'
import { CardImg as UserCardImg } from '../Card/UserCard/UserCardElements'
import { CardImg as ArtistCardImg } from '../Card/ArtistCard/ArtistCardElements'
import { CardImg as NFTokenCardImg } from '../Card/NFTokenCard/NFTokenCardElements'
import { Img, TableRow } from '../ContentList/ContentListElements'
import { hexToRgba } from '../HexToRGBA'
import { ContentTableRow } from '../ContentTable/ContentTableElements'

const SkeletonLoading = keyframes`

    0%{
        background-color: ${ hexToRgba(	'#a3b8c2', 0.3)};
    }
    100%{
        background-color: ${ hexToRgba(	'#f0f3f5', 0.3)};
    }

`

export const SkeletonPurchaseCardImg = styled(CardImg)`
animation: ${props => !props.color && SkeletonLoading} 1s linear infinite alternate; 
`
export const SkeletonNFTokenCardImg = styled(NFTokenCardImg)`
animation: ${props => !props.color && SkeletonLoading} 1s linear infinite alternate; 
`

export const SkeletonText = styled.div`
    display: flex;
    width: 100%;
    height: 1rem;
    opacity: 0.4;
    margin-bottom: .25;
    margin-right: 1%;
    border-radius: .3rem;
    animation: ${SkeletonLoading} 1s linear infinite alternate; 

`

export const SkeletonTextEnd = styled.div`
    display: flex;
    width: 80%;
    height: 1rem;
    margin-bottom: .25;
    opacity: 0.4;
    margin-right: 1%;
    border-radius: .3rem;
    animation: ${SkeletonLoading} 1s linear infinite alternate; 
`


export const SkeletonMusicCard = styled(CardContainer)`
    animation: ${props => !props.color && SkeletonLoading} 1s linear infinite alternate; 
`


export const SkeletonUserCardImg = styled(UserCardImg)`
    animation: ${SkeletonLoading} 1s linear infinite alternate; 
`

export const SkeletonArtistCardImg = styled(ArtistCardImg)`
    animation: ${SkeletonLoading} 1s linear infinite alternate; 
`

export const SkeletonTableRow = styled(TableRow)`
    &: hover{
        background-color: transparent;
        border-radius: 0;
    }
`
export const SkeletonContentListImg = styled(Img)`
    animation: ${SkeletonLoading} 1s linear infinite alternate; 
`

export const SkeletonIcons = styled.div`
    display: flex;
    animation: ${SkeletonLoading} 1s linear infinite alternate; 
    border-radius: 50%;
    opacity: 0.4;
    width: 1.2rem;
    height: 1.2rem;

`

export const SkeletonContentTableRow = styled(ContentTableRow)`
    animation: ${SkeletonLoading} 1s linear infinite alternate; 

`