import React from 'react'
import ContentTable from '..';

  
  
  function createData(
    name,
    code,
    population,
    size,
  ){
    const density = population / size;
    return { name, code, population, size, density };
  }
  
const LabelArtistsTable = ({artists}) => {
    const columns = [
        { id: 'name', label: 'Name', minWidth: 170 },
        { id: 'datejoined', label: 'Date Joined', minWidth: 100 },
        {
          id: 'followers',
          label: 'Followers',
          minWidth: 170,
          align: 'left',
          format: (value) => value.toLocaleString('en-US'),
        },
        {
          id: 'subscribers',
          label: 'Subscribers',
          minWidth: 170,
          align: 'left',
          format: (value) => value.toLocaleString('en-US'),
        },
        {
          id: 'albums',
          label: 'No. Albums',
          minWidth: 170,
          align: 'left',
          format: (value) => value.toFixed(2),
        },
        {
          id: 'singles',
          label: 'No. Singles',
          minWidth: 170,
          align: 'left',
          format: (value) => value.toFixed(2),
        },
      ];
  return (
    <>
    <ContentTable columns={columns} rows={artists}/>
    </>
  )
}

export default LabelArtistsTable