import styled from "styled-components";
import { CustomButton } from "../ButtonElement";
import { hexToRgba } from "../HexToRGBA";

export const MEDIA_SCREEN = { //Screen sizes for devices
    MOBILE: '480',
    TABLETS: '768',
    LAPTOPS: '1024',
    DESKTOPS: '1025',
    TV: '1500'
  }

export const  MusicUploadsConatiner = styled.div`
    display: flex;
    padding: 2% 2% 0% 2%;
    flex-direction: row;
    align-items: center;
    max-width: 100vw;
    overflow: hidden;
    background-color: ${props => props.theme.background};
    transition: 0.8s all ease;
`

export const MusicUploadsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100vw;
    overflow: hidden;
    background-color: ${props => props.theme.mainBg};
    border-radius: 50px 50px 0px 0px;
    filter: drop-shadow(1px 2px 2px rgba(15,15,15,0.76));
`

export const MusicTitleInfoContainer = styled.div`
    display: flex;
    border-radius: 50px 50px 0px 0px;
    padding: 2%;
    align-items: center;
    justify-content: flex-start;
    max-height: 20%;
    background: linear-gradient(to left, ${props => hexToRgba(props.theme.accentColor, 0.5) },${props => hexToRgba(props.theme.mainColor, 0.7) });
    color: ${props => props.theme.secondaryBg}

`
export const MusicUploadTitleTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 100%;

`


export const MusicUploadContentTitle = styled.title`
    display: flex;
    font-size: 1.5rem;
    font-weight: 700;
`

export const MusicUploadInfoText = styled.text`
    display: flex;
    font-size: 0.9rem;
`

export const MusicUploadTitleArtContainer = styled.div`
    display: flex;
    position: relative;
    width: 30rem;
    padding: 2%;
    margin-right: 3%;  
`

export const MusicUploadAlbumArt = styled.img`
    display: flex;
    position: relative;
    width: 12rem;
    height: 12rem;
    border-radius: 10px;
    border: 2px groove ${props => props.theme.mainBg};
`


export const MusicUploadDetailsContainer = styled.div`
    display: flex;
    padding: 1%;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1 1 100%;
    width: 100%;
    height: 100%;

`

export const AlbumDetailsContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content:flex-start;
    padding: 3%;
    border-radius: 15px;
    background: ${props => hexToRgba(props.theme.accentColor, 0.1)};
    margin-top: 1%;
    color: ${props => props.theme.secondaryBg};
    
`
export const AlbumDetailsWrapper = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;
    flex: 1 1 55%;

`
export const AlbumDetailsSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    margin-bottom: 2%;
`

export const AlbumDetailsSectionTitle = styled.title`
    display: flex;
    font-size: 1.2rem;
    font-weight: 700;
    margin-bottom: 2%;
`

export const AlbumArtCoverWrapper = styled.div`
    display: flex;
    width: 100%;
    padding: 2%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex: 1 1 45%;
`
export const AlbumUploadsContainer = styled.label`
    cursor: pointer;
    flex-direction: column;
    padding: 2%;
    white-space: wrap;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;

`
export const AlbumUploadsInput = styled.input`
    cursor: pointer;
    outline: none;
    overflow: hidden;
    width:0;
    height: 0;
    display: none;
    left: -9999;
    filter: alpha(opacity=0); /* IE < 9 */

`
export const AlbumArtPreviewContainer = styled.div`
    display: flex;
    height: 18rem;
    width: 18rem;
    flex-direction: column;
    position: relative;
    justify-content: center;
    align-items: center;
    background: ${props => props.background ? `linear-gradient(to left, ${hexToRgba(props.theme.accentColor, 0.5) },${hexToRgba(props.background, 0.7) })` : `linear-gradient(to left, ${hexToRgba(props.theme.accentColor, 0.5) },${hexToRgba(props.theme.mainColor, 0.7) })`};
    border-radius: ${props => props.albumArt ? '15px' : '0px'};
    margin: 1%;
    border: ${props => props.albumArt? 'none' :"2px dashed white"};


`


export const AlbumArtPreview = styled.img`
    display: flex;
    height: 100%;
    width: 100%;
`

export const SongInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 1%;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(8px);

    background-color: ${props => hexToRgba(props.theme.accentColor, 0.1)};
    transition: all 0.2s ease-in-out;
`

export const SongInfoWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 1%;
`

export const SongInfoTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 100%;
    
`
export const CloseButton = styled.div`
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    border-radius: 25px;
    justify-self: flex-end;
    padding: 2%;
    right: 2%;
    flex: 1 1 50%;
    z-index: 10;
    position: absolute;
    cursor: pointer;

    &: hover{
        background: ${props => hexToRgba(props.theme.mainColor,0.2)};
    }

    `

export const SongDetailsContainer = styled.div`
    display: flex;
    flex: 1 1 100%;
    justify-content: space-around;
    align-content: space-between;
    padding: 3%;
    color: ${props => props.theme.secondaryBg};

`

export const SongArtCoverWrapper = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    padding: 2%;
    flex: 1 1 40%;
    align-items: center;
    justify-content: space-around;
    align-content: space-between;
    flex-direction: column;
`

export const SongDetailsWrapper = styled.div`
    display: flex;
    width: 100%;
    flex: 1 1 60%;
    flex-direction: column;
    justify-content: flex-start;
    align-content: center;
`

export const SongDetailsSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    padding: 3%;
    margin-bottom: 4%;
`

export const SongDetailsSectionTitle = styled.title`
    display: flex;
    font-size: 1.2rem;
    font-weight: 700;
    margin-bottom: 2%;
`

export const SongDetailsText = styled.small`
    display: flex;
    padding: 2%;
    color: ${props => props.error ? props.theme.errorColor :props.background ? props.background : props.theme.secondaryBg};
`

export const FormWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: flex-start;
    width: 100%;
    padding: 1%;
    margin-bottom: 2%;
`

export const ListContainer = styled.li`
    list-style-type: none; 
    flex-direction: ${props => props.flexDirection ? props.flexDirection : 'row'};
    display: flex;
    width: 100%;
    max-height: 30vh;
    justify-content: flex-start;
    overflow: auto;
    scroll-height: 2px;
    flex-wrap: wrap;
    
    
`

export const FormLabel = styled.label`
    display: flex;
    margin-bottom: 2%; 
    font-size: 14px;
    flex: 1 1 50%;
`;

export const FormInput = styled.input`
    display: flex;
    padding: 1%;
    background: transparent;
    outline: none;
    color: ${props => props.theme.secondaryBg};
    margin-bottom: 1%;
    border: 1px solid ${props => props.theme.secondaryBg};
    border-radius: 4px;
    flex: 1 1 100%;
`
export const FormTextArea = styled.textarea`
  display: flex;
  padding: 10px 16px;
  width: 100%;
  height: 100%;
  resize: vertical;
  background: transparent;
  color: ${props => props.theme.secondaryBg};
  border: 1px solid ${props => props.theme.secondaryBg};
  border-radius: 4px;

`;

export const MusicUploadsButtonContainer = styled.section`
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 3%;
    background: linear-gradient(to left, ${props => hexToRgba(props.theme.accentColor, 0.5) },${props => hexToRgba(props.theme.mainColor, 0.7) });
`

export const MusicUploadsButton = styled(CustomButton)`
    padding: 2%;
    min-width: 10rem;
    background: transparent;
    justify-self: center;
    align-self: center;
`

export const FormToggle = styled.div`
    display: flex;
    font-size: 1.5rem;
    align-items: center;
    justify-content: center;
    flex: 1 1 100%;
`

export const UploadProgressWrapper = styled.div`
    display: flex;
    align-content: flex-start;
    align-items: center;
    justify-content: flex-start;
    padding: 3%;

`

export const ErrorWrapper = styled.div`
    display:flex;
    flex-direction: column;
    flex: 1 1 100%;
`

export const SelectButton = styled.select`
    display: flex;
    padding: 1%;
    margin-left: 2%;
    margin-right: 1%;
    font-size: 0.8rem;
    color: ${props => props.theme.secondaryBg};
    cursor: pointer;
    border: solid 1px ${props => hexToRgba(props.theme.mainColor, 0.8)};
    border-radius: 5px;
    outline: none;
    background-color: ${props => props.theme.mainBg};

    @media screen and (max-width: ${MEDIA_SCREEN.TABLETS}px) {
        font-size: 0.5rem;
        margin-left: 1%;
        margin-right: 2%;
        padding: 0;
    }
`
