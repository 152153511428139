import React from 'react'
import { RegistrationForm } from '../../components/RegistrationForm'
import { Access } from '../../components/Navbar/Data'
import Navbar from '../../components/Navbar'

const SignUp = () => {
  return (
    <>
    <Navbar navInfo={Access} />
    <RegistrationForm/>
        
    </>
  )
}

export default SignUp