import React, { useState } from 'react'
import Chip from '../Chip'
import { ChipList } from '../Chip/ChipElements'
import { FormButton1, FormInput1, FormWrapper } from '../CustomForm/CustomFormElements'
import { MintDetailsText, SmallText } from './NFTCollectionCreatorElements'
import { useWebsiteTheme } from '../WebsiteTheme/WebsiteThemeContext'
import { CustomButton } from '../ButtonElement'

const NFTokenRevShare = ({
  address, 
  addressRef,
  clearForm,
  percentage,
  percentageRef, 
  handleInput, 
  addresses, 
  onAdd, 
  errors,
  onDelete, 
  onEdit, 
  setEditing, 
  isEditing,
  setCurrentId, 
  currentId,
  setCurrentRevenue}) => 
  {
const {themeColors} = useWebsiteTheme()
const [isOpen, setOpen] = useState(isEditing ? true : false)  


return (
  <>
  <CustomButton onClick={() => setOpen(true)}>
    Add Wallet Address
  </CustomButton>
    <ChipList>
      {
        addresses && addresses.length > 0  && 
        addresses.map((a, index) => {
        return(<Chip 
          closeButton={true}
          background={currentId === index && themeColors?.mainColor}
        onChipClick={() => 
          {setCurrentRevenue({address:a.address, percentage: a.percentage})
          setCurrentId(index) 
          setEditing(true)}} 
        label={`${a.address.substring(0,4)}...${a.address.substring(a.address.length-4,a.address.length-1)}`} 
        onDelete={() => onDelete(index)}/>)
      })
    }
    </ChipList>
          <SmallText error>
                      {errors?.revenue}
        </SmallText>
        {isOpen && <FormWrapper><div style={{ display: "flex", width: "100%", padding: "2%" }}>          
        <MintDetailsText>Wallet Address</MintDetailsText>
        <FormInput1 
        ref={addressRef} 
        onChange={handleInput} 
        placeholder={"Wallet Address"} 
        name={'rev address'} 
        value={address}/>
        </div>
        <SmallText error>
                      {errors?.address}
        </SmallText>
        <div style={{ display: "flex", width: "100%", padding: "2%" }}>          
        <MintDetailsText>Percentage</MintDetailsText>
        <FormInput1 
        ref={percentageRef}
        onChange={handleInput} 
        name={'rev percentage'} 
        value={percentage} placeholder={"Percentage of collection sale"} min={1} step={1} /></div>
        <SmallText error>
                      {errors?.percentage}
        </SmallText>
        <SmallText error>
                      {errors?.revShare}
        </SmallText>
        <div style={{ display: "flex", width: "100%", padding: "2%",justifyContent:'center' }}>
          {!isEditing &&<FormButton1 onClick={() => onAdd(addressRef?.current?.value, percentageRef?.current?.value)}>Confirm</FormButton1>}
        {isEditing && <FormButton1 onClick={() => onEdit()}>Edit</FormButton1>}
        <FormButton1 onClick={() => {
          setOpen(false)
          clearForm()
          }}>Cancel</FormButton1></div>
        </FormWrapper>}
</>
  )
}

export default NFTokenRevShare