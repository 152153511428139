import React from 'react'
import { FaTimes } from 'react-icons/fa'
import { Close, ModalContainer, ModalWrapper } from './ModalElements'

function Modal({isOpen, onRequestClose, children}) {
  return (
    <ModalContainer isOpen={isOpen}>
      
        <ModalWrapper>
        <Close onClick={onRequestClose}><FaTimes/></Close>
            {children}
        </ModalWrapper>
    </ModalContainer>
  )
}

export default Modal