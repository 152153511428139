import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'
import { Home } from '../../components/Navbar/Data'
import Sidebar from '../../components/Sidebar'
import Navbar from '../../components/Navbar'
import Dashboard, { ArtistDashboard, LabelDashboard, PodcasterDashboard } from '../../components/Dashboard'

const HomePage = () => {
    const {currentUser, logout} = useAuth()
    const navigate = useNavigate()
    const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  useEffect(() => {
    if(!currentUser?.uid){
      console.log('here')
      logout()}
  
    return () => {
    }
  }, [])
  return (
    <><Sidebar isOpen={isOpen} toggle={toggle} navInfo={Home} />
      <Navbar toggle={toggle} navInfo={Home} />
        
        <Dashboard/>
    </>
  )
}

export const ArtistHomePage = () => {
    const {currentUser, logout, profiles} = useAuth()
    const navigate = useNavigate()
    const {artistId} = useParams()
    const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  useEffect(() => {
    if(currentUser?.profiles?.artist?.artistId !== artistId && !currentUser?.profiles?.label?.artists.includes((a) => a?.artistId === artistId)){
      navigate('/home', {replace: true})}
  
    return () => {
    }
  }, [artistId])
  return (
    <><Sidebar isOpen={isOpen} toggle={toggle} navInfo={Home} />
      <Navbar toggle={toggle} navInfo={Home} />
        <ArtistDashboard id={artistId} artist={currentUser?.profiles?.artist?.artistId === artistId ? currentUser?.profiles?.artist : currentUser?.profiles?.label?.artists[currentUser?.profiles?.label?.artists?.indexOf((a) => a?.artistId === artistId)]}/>
    </>
  )
}

export const PodcasterHomePage = () => {
    const {currentUser, logout, profiles} = useAuth()
    const navigate = useNavigate()
    const {podcasterId} = useParams()
    const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  useEffect(() => {
    if(profiles?.podcaster?.podcasterId !== podcasterId){
      navigate('/home', {replace: true})}
  
    return () => {
    }
  }, [])
  return (
    <><Sidebar isOpen={isOpen} toggle={toggle} navInfo={Home} />
      <Navbar toggle={toggle} navInfo={Home} />
        
        <PodcasterDashboard id={podcasterId} podcaster={profiles?.podcaster}/>
    </>
  )
}

export const LabelHomePage = () => {
    const {currentUser, logout, profiles} = useAuth()
    const navigate = useNavigate()
    const {labelId} = useParams()
    const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  useEffect(() => {
    if(profiles?.label?.labelId !== labelId){
      navigate('/home', {replace: true})}
  
    return () => {
    }
  }, [])
  return (
    <><Sidebar isOpen={isOpen} toggle={toggle} navInfo={Home} />
      <Navbar toggle={toggle} navInfo={Home} />
        <LabelDashboard id={labelId} label={profiles?.label}/>
    </>
  )
}

export default HomePage