import React, { useEffect, useState } from "react";
import { ThemeProvider } from "styled-components";
import Modal  from "../Modal";
import styled from "styled-components";

import { useWebsiteTheme } from "../WebsiteTheme/WebsiteThemeContext";

import { FaTimes } from "react-icons/fa";
import { useAuth } from "../../contexts/AuthContext";
import { PulseSpinner } from "react-spinners-kit";
import OptionModal from "../OptionModal";
import { FormLabel, Text, TextLink, TextRoute } from "./QualificationRequirementsElements";
import { FormButton } from "../CustomForm/CustomFormElements";
import { useNavigate } from "react-router-dom";

export const CloseIcon = styled(FaTimes)`
    color: ${props => props.theme.mainBG};
    font-size: 30px;
`

const QualificationRequirements = ({ modalState }) => {
    const {currentUser} = useAuth()
    const navigate  = useNavigate()

    return (
    <>
          <OptionModal heading={'How To Qualify for The WYM Token Distribution'}>
              <Text  >
                1. Visit the{" "}
                <TextLink href="https://en.gravatar.com" target="_blank" rel="noopener noreferrer">Gravatar</TextLink>{" "}
                website and create an account.
              </Text>
              <Text >
                2.Go to the <TextRoute to='/setEmailHash'>Set Email Hash</TextRoute> page or visit{" "}
                <TextLink href="https://xrptoolkit.com" target="_blank" rel="noopener noreferrer">XRPToolkit</TextLink>,
                with the wallet address of your choice, and update your email
                hash with your Gravatar email address.
              </Text>
              <Text >3. Save your settings. </Text>
              <Text >
                4. {currentUser &&
                <>
                  <TextRoute to='/access/signin'>Sign in</TextRoute>, once again, to your Sonar Muse account to check your airdrop eligibility
                status.
                </>}
                {!currentUser  && 
                <>
                <TextRoute to='/access/signup' >Sign up</TextRoute> once again, for a Sonar Muse account to check your airdrop eligibility
                status.
                </>}
              </Text >
              <br />
              <FormLabel>
                Make sure you create one with an email address youre willing to
                assign to your xrp wallet address.
              </FormLabel>
              {/*<FormLabel>
                If you want privacy, you can create a gravatar using a throwaway email address from <TextLink href='https://temp-mail.org/' target="_blank" rel="noopener noreferrer">Temp Mail</TextLink>
              </FormLabel>
              <p />*/}
              <FormLabel>
                Note: You will not be able to change the wallet address or email address assigned
                to your Sonar Muse account, once setup (Beta).
              </FormLabel>   

              <FormButton onClick={() => {
                navigate( '/')
              }}>OK</FormButton>          
          </OptionModal>
    </>
  );
};

export default QualificationRequirements;


 