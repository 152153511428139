import styled from "styled-components";
import { hexToRgba } from "../../HexToRGBA";
import { CardTitle } from "../CardElements";


export const PodcasterCardWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

`
export const PodcasterCardImageWrapper = styled.div`
    display: flex;
    width: 15rem;
    height: 15rem;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 10px 3px rgba(0, 0, 0, 0.3);
    &: hover {
        cursor: pointer;
       
            transform: scale(1.03);
            transition: transform 0.3s ease;
    
    }
`

export const PodcasterCardImg = styled.div`
    display: block;
    width: 100%;
    height: 100%;
    background: ${props => props.color} url(${props => props.src}) center;
    background-size: cover;
    border-radius: 15px;
    box-shadow: 0 10px 3px rgba(0, 0, 0, 0.3);

`

export const PodcasterCardBody = styled.div`
    display: flex;
    padding: 3%;
    flex-direction: column;
    justify-content: flex-start;
 
`

export const PodcasterCardTitle = styled(CardTitle)`
    cursor: pointer;
    &:after{
        ${PodcasterCardWrapper}: hover &{
            transform: scaleX(1);
            left: 0;
        }

    }
`