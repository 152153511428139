import React, { useEffect, useState } from 'react'
/* import {
  AccountObjectsRequest,
  Client,
  PaymentChannelCreate,
  PaymentChannelClaim,
  hashes,
} from 'xrpl' */
import { ALERT_TYPES, PAYMENT_WALLETS, SERVERS, XRPL_SERVERS } from '../../utils/Enum'
import { useAlert } from '../Alert/AlertContext'
import { FormButton, FormButton1, FormInput1, FormText } from '../CustomForm/CustomFormElements'
import axiosInstance from '../Authorization/client'
import OptionModal from '../OptionModal'
import { useModal } from '../Modal/ModalContext'
import { HoverOption } from '../MenuOptions/MenuOptionsElements'
import { FaClipboard } from 'react-icons/fa'
import LoadingScreen from '../LoadingScreen'
import { copyTextToClipboard } from '../../utils'

//const client = new Client({network: SERVERS.MAINNET[0]});

export const generateDevnetWallet = async () => {
  const axios = axiosInstance
  return await axios.get('/api/xrpl/getDevNetWallet').then(data => {
    return {
      address: data.data.classicAddress,
      seed: data.data.seed
  }
}).catch(err => {
    return false
  }
  )
}


const XRPL = ({address, setAddress}) => {
    const [wallet, setWallet] = useState()
    const [isCopied, setCopied] = useState(false)
    const {toggleModal, modalContent, isOpen} = useModal()
    const {addAlert} = useAlert()
   
    
    const getWallet = () => {
      addAlert({message: `Generating new dev net wallet`, type: ALERT_TYPES.INFO, secs: 10})
      if(!isOpen){
        toggleModal()
      }
      modalContent(<>
        <LoadingScreen transparent={true}/>
      </>)
        generateDevnetWallet().then(w => {
            if(w){
              setWallet(w)
              addAlert({title: 'New wallet address generated', message: <small style={{fontSize: '0.6rem'}}>{w.address}</small>, type: ALERT_TYPES.SUCCESS})
              
              modalContent(<>
              <OptionModal heading={'New Dev Net Wallet Generated'}>
                <FormText>{`Address: ${w.address} `}</FormText>
                <FormText>{`Seed: ${w.seed} has been generated`} </FormText>
                <FormText>Please make sure you have stored the wallet seed phrase somewhere safe. We do not store the seed phrase. </FormText>
                <FormText>You can always generate a new address if you lose this wallet</FormText>
                  <HoverOption onClick={() => {
                    copyTextToClipboard(w.seed).then(() => {
                      addAlert({message: `Copied to clipboard`, type: ALERT_TYPES.INFO, secs: 15})
                      setCopied(true)
                    }).catch(err => {
                      addAlert({message: `Something went wrong. Please try again`, type: ALERT_TYPES.DANGER})

                    })
                  }}>Copy to Clipboard <FaClipboard style={{maginLeft: '1%'}}/></HoverOption>
                  <HoverOption onClick={getWallet}>Generate New Address</HoverOption>
                  <HoverOption onClick={() => {
                    toggleModal()
                    modalContent()
                  }}>OK</HoverOption>
              </OptionModal>
              
              </>)
              setAddress(w.address)
          }
        }).catch(err => {
          addAlert({title: 'Wallet Generator Error', message: 'Something went wrong. Please try again.', type: ALERT_TYPES.DANGER})
          toggleModal()
          modalContent()
          return
        })
    }


  return (
    <><FormButton1 style={{padding: '1%'}} onClick={getWallet}>Generate</FormButton1></>
    
  )
}


/* export const getPaymentChannelInfo = async (channel) => {
  let channels = [];
  await client.connect();
  try {
    let resp = await client.request({
      id: 1,
      command: "account_channels",
      account: PAYMENT_WALLETS.SUBSCRIPTION_MICROPAYMENTS,
      ledger_index: "validated",
    });

    // Create a function to run on each API call.
    /* function printLedgerResult() {
      //console.log(resp["result"]);
    } 
    const ledger_data_index = resp["result"]["ledger_index"];
    // Execute function at least once before checking for markers.
    do {
      //printLedgerResult();

      if (resp["result"]["marker"] === undefined) {
        break;
      }

      // Specify the same ledger and add the marker to continue querying.
      const ledger_marker = await client.request({
        command: "account_channels",
        account: PAYMENT_WALLETS.SUBSCRIPTION_MICROPAYMENTS,
        ledger_index: ledger_data_index,
        marker: resp["result"]["marker"],
        ledger_index: "validated",
      });
      channels.push(...ledger_marker?.result?.channels);
      resp = ledger_marker;
    } while (true);
    //console.log(channels.filter((c) => c?.channel_id === channel));
    client.disconnect();
    if (channels?.length > 0) {
      return channels.filter((c) => c?.channel_id === channel)[0];
    } else {
      return false;
    }
  } catch (err) {
    console.error(err);
    return false;
  }
}; */

export default XRPL