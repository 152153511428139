import React, { useState, useRef, useEffect } from "react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  changePaymentPointer,
  defaultPaymentChannel,
  defaultPaymentPointer,
  setPaymentChannels,
  setPaymentPointers,
  setSwərl,
} from "../SonarMuseWebMonetization";
import useArtist from "../../hooks/useArtist";
import useMusic from "../../hooks/useMusic";
import { ArtistDetailsContainer } from "../AudioPlayer/AudioPlayerElements";
import { DEFAULT_IMAGE, getUserProfile } from "../GravatarAPI";
import LoadingScreen from "../LoadingScreen";
import { ArtistContent } from "./ArtistContent";
import ArtistHeader from "./ArtistHeader";
import { ArtistProfileDiv } from "./ArtistProfileElements";
import { shuffleSongs, updateWebPageIcon } from "../../utils/MusicUtils";
import { getUniqueListBy } from "../../utils";
import { useAuth } from "../../contexts/AuthContext";

export const ArtistProfile = ({ artist }) => {
  const [isLoaded, setLoaded] = useState(false);
  const [artistId, setArtist] = useState(artist);
  const [isUserSubscribed, setUserSubscribed] = useState(false)
  const [artistProfile, setArtistProfile] = useState();
  const { currentUser} = useAuth();
  const { validateUserArtistSubscription, getArtistProfile, getArtistDiscography, searchArtistAlbums, searchArtistSingles, searchArtistProducedSongs, searchArtistComposedSongs, searchArtistFeatSongs, searchArtistWrittenSongs } = useArtist({ id: artist });
  const { getSongs } = useMusic();
  const [allMusic, setAllMusic] = useState([])
  const [isFollowing, setIsFollowing] = useState(); // if currentUser follows artist
  const navigate  = useNavigate();
  const [discography, setDiscography] = useState({});
  const [isDiscographyLoaded, setDiscographyLoaded] = useState(false)


  useEffect(() => {
    let aProfile = {};
    setLoaded(false);
    setDiscography({})
    setDiscographyLoaded(false)
    if (artist === null || !artist) {
      navigate( "/home");
    } else {
      getArtistProfile()
        .then((data) => {
          setArtistProfile(data);
          updateWebPageIcon(data?.artistSettings?.profilePictureType === "custom" &&
          data?.profilePicture
            ? data?.profilePicture
            : data?.photoUrl
            ? data?.photoUrl + "?s=600&d=mp"
            : 'https://artists.sonarmuse.org/favicon.ico')
          setLoaded(true);
          getArtistDiscography(artist, 0)
            .then((data2) => {
              setDiscography(data2);
             setDiscographyLoaded(true);
/*                setFullDiscographyLoaded(true);
 */            })
            .catch((err) => {
              console.error(err);
              setDiscographyLoaded(true);
/*               setFullDiscographyLoaded(true);
 */            });
          /* getArtistDiscography(artist)
            .then((data2) => {
              setDiscography(data2);
              setDiscographyLoaded(true);
            })
            .catch((err) => {
              console.error(err);
              setDiscographyLoaded(true);
            }); */
        })
        .catch((err) => {
          console.log(err);
          navigate( "/home");
        });
    }
    return () => {}
  }, [artist]);

  const getProfile = async () => {
    return await getArtistProfile()
      .then((data) => {
        if (data !== false || data.error) {
          document
            .querySelector('meta[name="monetization"]')
            .setAttribute(
              "content",
              data?.profile?.paymentPointer
                ? data.profile.paymentPointer
                : "$ilp.gatehub.net/239263989"
            );
          document
            .querySelector('meta[name="description"]')
            .setAttribute("content", artistProfile?.gravatarProfile?.aboutMe);
          document
            .querySelector('meta[name="twitter:url"]')
            .setAttribute(
              "content",
              `https://dev.sonarmuse.org/artist/${artist}`
            );
          document
            .querySelector('meta[name="twitter:title"]')
            .setAttribute("content", data?.artistName);
          document
            .querySelector('meta[name="twitter:description"]')
            .setAttribute(
              "content",
              data?.profile?.paymentPointer
                ? data.profile.paymentPointer
                : "$ilp.gatehub.net/239263989"
            );
          document
            .querySelector('meta[name="twitter:image"]')
            .setAttribute(
              "content",
              `${data?.gravatarProfile?.thumbnailUrl}?s=600&d=mp`
            );
          document
            .querySelector('meta[property="og:url"]')
            .setAttribute(
              "content",
              `https://dev.sonarmuse.org/artist/${artist}`
            );
          document
            .querySelector('meta[property="og:title"]')
            .setAttribute("content", data?.artistName);
          document
            .querySelector('meta[property="og:description"]')
            .setAttribute("content", artistProfile?.gravatarProfile?.aboutMe);
          document
            .querySelector('meta[property="og:image"]')
            .setAttribute(
              "content",
              `${data?.gravatarProfile?.thumbnailUrl}?s=600&d=mp`
            );
          setArtistProfile(data);
          
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };


  return (
    <>
      {!isLoaded && <LoadingScreen isLoaded={isLoaded} />}
      {isLoaded && (
        <ArtistProfileDiv>
          <ArtistDetailsContainer>
          <ArtistHeader
              artistSettings={artistProfile?.profile?.artistSettings}
              background={artistProfile?.gravatarProfile?.profileBackground}
              wallets={artistProfile?.gravatarProfile?.currency ? Object.values(artistProfile?.gravatarProfile?.currency): undefined}
              isVerified={artistProfile?.profile?.isVerified}
              artistName={artistProfile?.profile?.name}
              artistUid={artistProfile?.profile?.uid}
              discography={discography}
              isSubscribed={isUserSubscribed}
              artistId={artist}
              artist={artistProfile?.profile}
              isUserFollowing={artistProfile?.isUserFollowing}
              profilePicture={
                artistProfile?.profile?.artistSettings?.profilePictureType
                  ? artistProfile?.profile?.artistSettings?.profilePictureType ===
                    "gravatar"
                    ? artistProfile?.gravatarProfile?.avatar_url+ "?s=600"
                    : artistProfile?.profile?.artistSettings?.profilePictureType === "custom"
                    ? artistProfile?.profile?.profilePicture?.url
                    : artistProfile?.gravatarProfile?.avatar_url+ "?s=600"
                  : artistProfile?.gravatarProfile?.avatar_url ? artistProfile?.gravatarProfile?.avatar_url+ "?s=600" : DEFAULT_IMAGE(600)
              }
            />
            <ArtistContent
              artistId={artist}
              artist={artistProfile?.profile}
              isDiscographyLoaded={isDiscographyLoaded}
              discography={discography}
              searchArtistSingles={searchArtistSingles}
              searchArtistAlbums={searchArtistAlbums}
              searchArtistFeatSongs={searchArtistFeatSongs}
              searchArtistComposedSongs={searchArtistComposedSongs}
              searchArtistWrittenSongs={searchArtistWrittenSongs}
              searchArtistProducedSongs={searchArtistProducedSongs}
              isUserSubscribed={isUserSubscribed}
              setDiscography={setDiscography}
              getProfile={getProfile}
              background={
                artistProfile?.gravatarProfile?.profileBackground?.color
              }
              gravatarProfile={artistProfile?.gravatarProfile}
              about={{
                photos: artistProfile?.gravatarProfile?.gallery,
                accounts: artistProfile?.gravatarProfile?.verified_accounts,
                bio: artistProfile?.profile?.artistSettings?.bioType === "custom" ? artistProfile?.profile?.bio : artistProfile?.gravatarProfile?.description,
              }}
            />
          </ArtistDetailsContainer>
        </ArtistProfileDiv>
      )}
    </>
  );
};
