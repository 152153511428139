import React, { useEffect, useState } from 'react'

import { SkeletonPurchaseCardImg } from "../../Skeleton/SkeletonElements";
import { useModal } from "../../Modal/ModalContext";
import MenuOptions, { PlaylistCollaboratorsViewer } from "../../MenuOptions";
import { CardContainer, CardContent, CardTitle, CreatorInfo, CreatorInfoContent, CreatorInfoWrapper, H6, InfoContentText, TextLink } from "./PlaylistCardElements";
import { useAuth } from "../../../contexts/AuthContext";
import ScrollToTop from "../../ScrollToTop";
import ImageUtils from "../../../utils/ImageUtils";
import { Link } from "react-router-dom";
import { getArtistsNames2 } from "../../../utils";
import { ARTIST_SEARCH_TYPES } from "../../../utils/Enum";
import { getRandomColor } from '../../HexToRGBA';
import { useUser } from '../../../hooks/useUser';
import { getResizedImage } from '../../../utils/FileUploadsUtils';
import { useWebsiteTheme } from '../../WebsiteTheme/WebsiteThemeContext';

const PlaylistCard = ({content, cardRef}) => {
    const { openModal, closeModal, modalContent } = useModal();
    const {currentUser} = useAuth()
    const {themeColors} = useWebsiteTheme()

    const { getUserProfile } = useUser({});
    const {goTo} = ScrollToTop()
    const { defaultAlbumArt, DEFAULT_IMAGE } = ImageUtils();
    const [color, setColor] = useState(content?.color ? content?.color : getRandomColor() )
    const [curatorProfile, setCuratorProfile] = useState({})
    const {options} = MenuOptions()
 const [isAlbumArtLoaded,setAlbumArtLoaded] = useState(false)
  const [albumArt, setAlbumArt] = useState(content?.albumArt ? content?.albumArt : undefined)

  useEffect(() => {
    if(!isAlbumArtLoaded && content?.albumArt){
      getResizedImage(content?.albumArt).then(image => {
        setAlbumArt(image)
        
      }).catch(err => {
        setAlbumArt(content?.albumArt)
      })
      setAlbumArtLoaded(true)
    }
  }, [])

    useEffect(() => {
    getUserProfile(content?.curatorId)
        .then((c) => {
            setCuratorProfile({
            uid: content?.curatorId,
            gravatarInfo: c,
            photoUrl: c?.thumbnailUrl,
            ...c,
          });
        })
        .catch((err) => {
          return;
        });
    
      return () => {
      }
    }, [content])
    

  
    return (<CardContainer id={content?.playlistId}
      onContextMenu={(e) => {
        e.preventDefault();
        if(content?.playlistId && currentUser?.uid){
        openModal();
        options(content)};
      }
    } ref={cardRef} background={content?.color}>
      <div style={{ alignItems: "center", margin: "auto" }}><SkeletonPurchaseCardImg background={content?.color} color={content?.color}
      onClick={() => content?.playlistId ? goTo(`/playlist/${content?.playlistId}`) : goTo("#")}
      src={content?.playlistArt}
    /></div>
    <CardContent>
    <H6>Playlist</H6>
      <CardTitle>
        <TextLink
          style={{ color: themeColors.secondaryColor }}
          to={content?.playlistId ? `/playlist/${content?.playlistId}` : "#"}
        >{content?.playlistName}</TextLink>
      </CardTitle>
      <CreatorInfoWrapper>
        <CreatorInfo>
          <CreatorInfoContent>
            
            <InfoContentText style={{ color: themeColors.secondaryBg }}>
              {" "}<Link style={{textDecoration: "none", color: themeColors.secondaryColor}} to={() => content?.curatorId ? `/u/${content?.curatorId}` : "#"}>
                  {curatorProfile?.gravatarInfo ? curatorProfile?.gravatarInfo?.displayName : content?.curatorId && `${content?.curatorId.substring(0, 15)}...`}
              </Link>
            </InfoContentText>
          </CreatorInfoContent>
          </CreatorInfo>
          </CreatorInfoWrapper>
          <CreatorInfoWrapper>
          {(content?.collaborators?.length > 0 || content?.songs?.length > 0 ) &&<CreatorInfo>
           <CreatorInfoContent>
           {content?.collaborators?.length > 0 && <InfoContentText style={{ color: themeColors.accentColor }}
            onClick={() => {
                openModal()
                modalContent(<PlaylistCollaboratorsViewer playlist={content}/>)
            }}
           >
              {content?.collaborators?.length} Other Collaborator(s)
            </InfoContentText>}
           {content?.songs?.length > 0 && <InfoContentText>
              {content?.songs?.length} Song(s)
            </InfoContentText>}
          </CreatorInfoContent>
        </CreatorInfo>}
      </CreatorInfoWrapper>
     
    </CardContent>
  </CardContainer>
    );
}

export default PlaylistCard