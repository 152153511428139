import React, { useEffect, useState } from 'react'
import AppBanner from '../AppBanner'
import { Header } from '../Header'
import ContentWrapper from '../ContentWrapper'
import { Subtitle } from '../InfoSection/InfoElements'
import { NavContainer } from '../NavLink/NavLinkElements'
import { ArtistNavImg, ArtistRegistrationNav, ArtistRegistrationNavLink } from './RegistrationFormElements'
import OptionModal from '../OptionModal'
import { FormButton, FormText, FormWrapper } from '../CustomForm/CustomFormElements'
import { Text, TextRoute } from '../QRCodeForm/QrCodeFormElements'
import { Theme } from '../WebsiteThemeElements'
import { HoverOption } from '../MenuOptions/MenuOptionsElements'
import { CustomButton } from '../ButtonElement'
import { XummApp } from '../AppBanner/Data'
import { TRANSACTION_OPTIONS } from '../../utils/Enum'
import QRCodeForm from '../QRCodeForm'
import ScrollToTop from '../ScrollToTop'
import ImageUtils from '../../utils/ImageUtils'
import { useModal } from '../Modal/ModalContext'
import { downloadFile, getObjectLength } from '../../utils'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'
import { useWebsiteTheme } from '../WebsiteTheme/WebsiteThemeContext'

export const RegistrationForm = () => {
  const {themeColors} = useWebsiteTheme()

    const { goTo } = ScrollToTop();
    const {currentUser, profiles} = useAuth()
    const {podcastersRegistration, artistRegistration, labelRegistration,} = ImageUtils()
    const [regType, setRegType] = useState()
    const {openModal, modalContent, closeModal} = useModal()
    const [address, setAddress] = useState()
    const navigate = useNavigate()
  
    useEffect(() => {
     return () => {}
    }, [])
  
    const onSignInFail = (err) => {
      
      modalContent(<>
      <OptionModal heading={"Something went wrong"}>
        <HoverOption onClick={() => {
          closeModal()
          modalContent()
          }}>OK</HoverOption>
      </OptionModal>
      </>)
    }

    const navigateCurrentUser = (type) => {
      closeModal()
      localStorage.setItem('regType', type)
      localStorage.setItem("address", currentUser?.uid)
      switch(type){
        case 'label':
          navigate("/label/register") 
        break;
        case 'artist':
          navigate("/artist/register") 
        break;
        case 'podcaster':
          
                navigate("/podcaster/register")   
        break;
        default: 
        break;
      }
    }
  
    const onSignInSuccess = (data, accountType) => {
        openModal()
        if( data?.emailHash && data?.isGravatarVerified && data?.gravatar && data?.registration){
          switch (accountType) {
            case "label":
              if(!data?.label){
                closeModal()
                localStorage.setItem("address", data?.address)   
                localStorage.setItem('regType', accountType)
                navigate("/label/register") 
     
            }else{
              modalContent(<>
              <OptionModal heading={"A record label/record company has already been asscociated with this wallet address. Please sign up with another address"}>
                <HoverOption onClick={() => {
                  closeModal()
                  modalContent()
                }}>
                  Go Back
                </HoverOption>
              </OptionModal>
              </>)

            }
              break;
            case "artist":
              if(!data?.artist){
                closeModal()
                localStorage.setItem("address", data?.address)   
                localStorage.setItem('regType', accountType)
                navigate("/artist/register")         
            }else{
              modalContent(<>
                <OptionModal heading={"An artist profile has already been asscociated with this wallet address. Please sign up with another address"}>
                  <HoverOption onClick={() => {
                    closeModal()
                    modalContent()
                  }}>
                    Go Back
                  </HoverOption>
                </OptionModal>
                </>)
            }
              break;
            case "podcaster":
              if(!data?.podcaster){
                closeModal()
                localStorage.setItem("address", data?.address)   
                localStorage.setItem('regType', accountType)
                navigate("/podcaster/register")         
            }else{
              modalContent(<>
                <OptionModal heading={"A podcaster profile has already been asscociated with this wallet address. Please sign up with another address"}>
                  <HoverOption onClick={() => {
                    closeModal()
                    modalContent()
                    }}>
                    Go Back
                  </HoverOption>
                </OptionModal>
                </>)
            }
              break;
          
            default:
              closeModal()
              break;
          }
          
          
          return
       
    } else{
      modalContent(<OptionModal heading={'Requirements'} additionalText={`Before you can continue, make sure you have completed the following`}>
         {!data?.registration && (
                <FormButton
                  style={{
                    marginTop: "2%",
                    border: "1px solid " + themeColors.secondaryBg,
                    background: "transparent",
                  }}
                  transparent={false}
                  target="_blank" rel="noopener noreferrer"
                  to={"https://sonarmuse.org/access/signup"}
                 
                >
                  Sign Up
                </FormButton>
              )}
              {!data?.emailHash && (
                <FormButton
                  style={{
                    marginTop: "2%",
                    background: themeColors.accentColor,
                  }}
                  transparent={false}
                  to={"/setEmailHash"}
                  target="_blank" rel="noopener noreferrer"
                >
                  Set Email Hash
                </FormButton>
              )}
              {!data?.gravatar && (
                <FormButton
                  style={{
                    marginTop: "2%",
                    color: themeColors.infoColor,
                    border: "1px solid " + themeColors.infoColor,
                    background: "transparent",
                  }}
                  transparent={false}
                  to={"https://en.gravatar.com"}
                  target="_blank" rel="noopener noreferrer"
                >
                  Sign up for Gravatar
                </FormButton>
              )}
              {data?.gravatar && !data?.isGravatarVerified &&(
                <FormButton
                  style={{
                    marginTop: "2%",
                    color: themeColors.warningColor,
                    border: "1px solid " + themeColors.warningColor,
                  }}
                  transparent={false}
                  to={"https://en.gravatar.com/profiles/edit#verified-services"}
                  target="_blank" rel="noopener noreferrer"
                >
                  Verify Gravatar Account
                </FormButton>
              )
             
              }
        
      </OptionModal>) 
      return
    }
  
    }
  
    const signIn = (accountType) => {
      openModal()
      modalContent(<QRCodeForm 
        heading={'Account Sign In'}
        type={TRANSACTION_OPTIONS.ARTIST_PROFILE_CHECKER} 
        body={{
          instruction: `User is Signing transaction to sign up for an ${accountType} account`,
          memo: `User is Signing transaction to sign up for an ${accountType} account`,
          type: accountType
        }}
        onSuccess={onSignInSuccess}
              onFail={onSignInFail}
              cancelQr={() => {
                  closeModal()
                  modalContent()
              }}
      
      />)
    };
  
    const regRequirements = (type) => {
      openModal()
      modalContent(<OptionModal heading={'Artist Registration Requirements'}>
        <FormWrapper>
          <Text>1. A non-custodial XRP wallet address, using the Xaman wallet.</Text>
          <Text onClick={() => {closeModal()
            modalContent()}}>2. You have successfully <TextRoute target="_blank" rel="noopener noreferrer" to={'https://sonarmuse.org/access/signup'}>signed up</TextRoute> for the platform. To learn more visit the <TextRoute to={'/faq'}>FAQ page</TextRoute></Text>
          <Text>3. An email address that is signed up to <a style={{color: themeColors.secondaryColor}}  target="_blank" rel="noopener noreferrer" href={'https://en.gravatar.com'}>Gravatar</a> preferrably the address linked to your wallet</Text>
          <FormText>(The gravatar account should have at least 2 <a style={{color: themeColors.secondaryColor}}  target="_blank" rel="noopener noreferrer" href={"https://en.gravatar.com/profiles/edit#verified-services"} > verified services</a> assigned to it eg. Twitter, Github, Wordpress etc.)</FormText>
        </FormWrapper>
      <HoverOption onClick={() => {
        signIn(type)
      }}>I Am Ready To Continue</HoverOption>
      <CustomButton onClick={() => {
        closeModal()
        modalContent()
      }}>Cancel</CustomButton>
      </OptionModal>)
    }

    return (
        <>
            <AppBanner {...XummApp}/>
         <><Header title={'Get Full Access to Sonar Muse for Artists'}/>
          <ContentWrapper>
            <Subtitle style={{color: themeColors?.secondaryBg}}>
              Select Account Registration Type
            </Subtitle> 
            {currentUser?.uid && getObjectLength(profiles) === 3 &&
                <FormText>You cannot link any more profiles to this account</FormText>
              }
            {(!currentUser?.uid || getObjectLength(profiles) < 3) && <div style={{ margin: "10% 0%", padding: "0", width: "100%" }}>
              <NavContainer>
                <ArtistRegistrationNav>
                 {(!currentUser?.isArtist || !currentUser?.isLabel) && <ArtistRegistrationNavLink
                    onClick={() => {currentUser?.uid ? navigateCurrentUser("artist") : regRequirements("artist")}}
                  >
                  
                    <ArtistNavImg
                        onClick={() => {}}
                        isRounded
                        size={"8rem"}                     
                        albumArt={artistRegistration}
                      />  
                      I am an Artist
                  </ArtistRegistrationNavLink>}
                  {!currentUser?.isPodcaster && <ArtistRegistrationNavLink
                    onClick={() => {currentUser?.uid ? navigateCurrentUser("podcaster") :regRequirements("podcaster")}}
                  >
                  
                    <ArtistNavImg
                        onClick={() => {}}
                        isRounded
                        size={"8rem"}                     
                        albumArt={podcastersRegistration}
                      />  
                      I am a Podcaster
                  </ArtistRegistrationNavLink>}
                  {!currentUser?.isLabel && <ArtistRegistrationNavLink
                    onClick={() => {currentUser?.uid ? navigateCurrentUser("label") : regRequirements("label")}}
                  >
                    <ArtistNavImg
                        onClick={() => {}}
                        isRounded
                        size={"8rem"}                    
                        albumArt={labelRegistration}
                      />
                    I am a Record Label/Record Company
                  </ArtistRegistrationNavLink>}{" "}
                </ArtistRegistrationNav>
              </NavContainer>
             
            </div>}
            
          </ContentWrapper></>
          </>)
}

