import { Link } from "react-router-dom";
import styled from "styled-components";
import { hexToRgba } from "../../HexToRGBA";

export const CardContainer = styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;
  gap: 3%;
  margin: 2%;
  border-radius: 50px;
  justify-content: space-between;
  background: ${props => hexToRgba(props.theme.accentColor, 0.2)};
   &: hover {
        cursor: pointer;
        transition: all 0.2s ease-in-out;
    transform: scale(1.05);
    border: solid ${props => hexToRgba(props.theme?.secondaryBg, 0.7)} 1px;
    
    }
`

export const CardImg = styled.div`
  display: flex;
  background: ${props => props.color} url(${props => props.src});
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  margin-bottom: 2%;
  transition: transform 500ms ease;
  cursor:pointer;
  &:hover{
    transition: all 0.2s ease-in-out;
    transform: scale(1.05);
}
&:focus{     
    transition: all 0.2s ease-in-out;
    transform: scale(1.05);
}

  `

  export const CardContent = styled.div`
    display: flex;
    flex-direction: column;
  
  `

  export const H6 = styled.h6`
  display: flex;
    font-size: 1rem;
    width: 100%;
    flex-wrap: nowrap;
    color: ${props => hexToRgba(props.theme.secondaryColor, 1)};
`

  export const ContentText = styled.div`
    display: flex;
     
`

export const TextLink = styled(Link)`
   text-decoration: none;
   cursor: pointer;
   color: ${props => props.theme.secondaryBg};
   &: hover{
    color: ${props => props.theme.mainColor};
    text-decoration: underline;

   }
`