import React, { useEffect, useState } from 'react'
import ArtistSettings from '../../../components/ArtistProfile/ArtistContent/ArtistSettings'
import useArtist from '../../../hooks/useArtist'
import { useAuth } from '../../../contexts/AuthContext'
import { useNavigate, useParams } from 'react-router-dom'
import LoadingScreen from '../../../components/LoadingScreen'
import { getObjectLength } from '../../../utils'

const ArtistProfileSettings = () => {
    const {currentUser, profiles} = useAuth()
    const [profile, setProfile] = useState({})
    const {artistId} = useParams()
    const {getArtistProfile} = useArtist({artistId})
    const [isLoaded, setLoaded] = useState(false)
    const navigate = useNavigate()

    const getProfile = async() => {
      setLoaded(false)
      if(profiles?.artist?.artistId !== artistId){
        navigate(-1)
    }
    await getArtistProfile(artistId).then(artist => {
      if(getObjectLength(artist) > 0 && artist !== false){
        setProfile(artist)
        setLoaded(true)
        return
      }
      navigate(-1)
    }).catch(err => {
      navigate(-1)
      return
    })
    }

    useEffect(() => {
      getProfile()
      return () => {
      }
    }, [artistId])
    
  return (
    <>
    {!isLoaded && <LoadingScreen/>}
    {isLoaded && <ArtistSettings 
                      getProfile={getArtistProfile}
                      artistId={profile?.artistId}
                      artist={profile?.profile}
                      artistSettings={profile?.profile?.artistSettings}
                      gravatarProfile={profile?.gravatarProfile}
                      about={{
                        photos: profile?.gravatarProfile?.gallery,
                        accounts: profile?.gravatarProfile?.verified_accounts,
                        bio:
                        profile?.profile?.artistSettings?.bioType ===
                          "custom"
                            ? profile?.profile?.bio
                            : profile?.gravatarProfile?.description,
                      }}/>}
    </>
  )
}

export default ArtistProfileSettings