import React, { useEffect, useState } from "react";
import { FaEdit, FaHeart, FaReply, FaTrash } from "react-icons/fa";
import CommentList from ".";
import { getTimestamp, timestampToDate } from "../../../utils";
import { getReplies } from "../../../utils/CommentsUtils";
import { HoverOption } from "../../HoverMenu/HoverMenuElements";
import IconButton from "../../IconButton";
import { CommentContainer, CommentFooter, CommentHeader, CommentMessage, CommentsHeaderText, NestedComments, NestedCommentStack } from "./CommentsListElements";
import { DEFAULT_IMAGE, DEFAULT_PROFILE, getUserProfile } from "../../GravatarAPI";
import AlbumArt from "../../AlbumArt";
import { ImageWrapper, Img } from "../ContentListElements";

const Comment = ({ commentId, message, user, createdAt, comments}) => {
  const [ childComments, getChildComments] = useState(getReplies(comments, commentId))
  const [showReplies, setShowReplies] = useState(false)
  const [userProfile, setUserProfile] = useState()
   useEffect(() => {
    if(user?.email){
      getUserProfile(user?.email).then(profile => {
        if(profile){
          setUserProfile(profile)
        }
      })
    }else{
    setUserProfile(DEFAULT_PROFILE)
  }
    return () => {}
  }, [user]) 
  
  return (
    <>
    <CommentContainer>
      <CommentHeader>
        <CommentsHeaderText to={user?.uid ? `/u/${user?.uid}` : '#'}><ImageWrapper style={{ width: '1.5rem', height: '1.5rem'}}><Img isCircular={true} size={'1rem'} src={user?.photoURL ? user?.photoURL : DEFAULT_IMAGE()}/> </ImageWrapper>
        <text style={{marginLeft: '2%', whiteSpace: "nowrap"}}>{userProfile?.displayName ? userProfile?.displayName : user?.uid ? `${user?.uid?.substring(
                0,
                3
              )}...${user?.uid?.substring(
                user?.uid?.length - 5,
                user?.uid?.length - 1
              )}` : 'Anonymous User'}</text></CommentsHeaderText>
        <span>
          <small>{createdAt
            ? getTimestamp( new Date(timestampToDate(createdAt)))
            : getTimestamp(new Date())}</small>
        </span>
      </CommentHeader>
      <CommentMessage>
        {message}
      </CommentMessage>
      <CommentFooter>
      </CommentFooter>
    </CommentContainer>
    {childComments?.length > 0 && showReplies && (
      <>
        <NestedCommentStack>
          <button></button>
          <NestedComments>
            <CommentList comments={childComments}/>
          </NestedComments>
        </NestedCommentStack>
      </>
    )}
    {childComments?.length > 0 && !showReplies &&<HoverOption>Show Replies</HoverOption>}
    </>
  );
};

export default Comment;
