import styled from "styled-components";
import { hexToRgba } from "../HexToRGBA";

export const Container = styled.div`
display: flex;
flex-direction: column;
overflow: hidden;
  min-height:100vh;
  align-items:center;
  justify-content: center;
  backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);

  background: linear-gradient(180deg, rgba(179, 111, 247, 0.76) 0%, rgba(84, 120, 154, 0.6232) 100%);
  `;

  export const VideoBg = styled.video`
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items:center;
  justify-content: center;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  background: ${props => hexToRgba(props.theme.mainBg, 0.3)};
  `

  export const ContentWrapper = styled.div`
  align-items:center;
  align-content: center;
  justify-content: center;
  padding: 8%;
  backdrop-filter: blur(25px);
  -webkit-backdrop-filter: blur(25px);
  width: 100%;
  min-height 100vh;
  overflow: hidden;
  background: ${props => hexToRgba(props.theme.mainBg, 0.3)};
  `