import React from "react";
import {
  CardContainer,
  CardContent,
  CardTitle,
  H6,
  TextLink,
} from "./ArtistCardElements";
import { SkeletonUserCardImg } from "../../Skeleton/SkeletonElements";
import ScrollToTop from "../../ScrollToTop";
import ImageUtils from "../../../utils/ImageUtils";
import { VerifiedBadge } from "../CardElements";
import { CONTENT_TYPES } from "../../../utils/Enum";

const ArtistCard = ({ content }) => {
  const { goTo } = ScrollToTop();
  const { defaultAlbumArt, DEFAULT_IMAGE, verifiedBadge } = ImageUtils();

  return (
    <>
      <CardContainer  onContextMenu={(e) => {
              e.preventDefault();
            }}>
      <div style={{ alignItems: "center", margin: "auto" }}><SkeletonUserCardImg
        type={CONTENT_TYPES.ARTIST}
        id={content?.artistId}
          src={() =>
            content?.artistSettings?.profilePictureType === "custom" &&
            content?.profilePicture
              ? content?.profilePicture
              : content?.photoUrl
              ? content.photoUrl + "?s=600&d=mp"
              : DEFAULT_IMAGE(600)
          }
          onClick={() =>
            content?.artistId
              ? goTo(`/artist/${content?.artistId}`)
              : goTo("#")
          }
        /></div>
        <CardContent>
          <H6>
            Artist{" "}
            {content?.isVerified && <VerifiedBadge badge={verifiedBadge} />}
          </H6>
          <CardTitle>
            <TextLink
              onClick={() =>
                content?.artistId
                  ? goTo(`/artist/${content?.artistId}`)
                  : goTo("#")
              }
              to={content?.artistId ? `/artist/${content?.artistId}` : "#"}
            >
              {content?.artistName}
            </TextLink>
          </CardTitle>
        </CardContent>
      </CardContainer>
    </>
  );
};

export default ArtistCard;
