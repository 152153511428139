import React, { useEffect, useState } from 'react'
import useMusic from '../../../../hooks/useMusic'
import useRecordLabel from '../../../../hooks/useRecordLabel'
import { SelectButton, SelectButtonWrapper } from '../../../Select'
import ContentWrapper from '../../../ContentWrapper'
import { AlbumsReleasesTable, SinglesReleasesTable } from '../../../ContentTable/ArtistTables'
import { ModalLoadingScreen } from '../../../LoadingScreen'
import { FormText, FormWrapper } from '../../../CustomForm/CustomFormElements'

const MusicContent = ({labelId, isLoaded, setLoaded, filter}) => {
    const {getRecordLabelDiscography, getRecordLabelTopAlbumsByDate, getRecordLabelTopSongsByDate} = useRecordLabel({id: labelId})
    const [songs, setSongs] = useState()
    const [albums, setAlbums] = useState()
    const [topAlbums, setTopAlbums] = useState()
    const [topSongs, setTopSongs] = useState()

    useEffect(() => {
        getMusicStats()
    
      return () => {
        
      }
    }, [labelId,filter])

    const getMusicStats = async () => {
        setLoaded(false)
        return await Promise.all([getRecordLabelTopSongsByDate(labelId,filter, 0), getRecordLabelTopAlbumsByDate(labelId,filter, 0)]).then(music => {
            if(music[0]?.length > 0 && music[0] !== false){
                setTopSongs(music[0])
            }
            if(music[1]?.length > 0 && music[1] !== false){
                
                setTopAlbums(music[1])
            }
           /*  if(music[2]?.length > 0 && music[2] !== false){
                setAlbums(music[2])
            } */
            setLoaded(true)
        }).catch(err => {
            console.error(err)
            return setLoaded(true)
        })
    }
    
    
    
  return (
    <><ContentWrapper>
        {isLoaded && <>
            
            {topSongs?.songs?.length > 0 && <SinglesReleasesTable releases={topSongs?.songs} heading={"Top Played Songs"}/>}
            {topAlbums?.albums?.length > 0 && 
            <AlbumsReleasesTable releases={topAlbums?.albums} heading={"Top Played Albums"} />}
            {(!topSongs?.songs && !topAlbums?.albums === 0) && <FormWrapper>
                <FormText>No Music Found</FormText>
                </FormWrapper>}
            
            </>}
         
            
        
        {!isLoaded && <ModalLoadingScreen text={"Loading Stats..."}/>}
        
    </ContentWrapper></>
  )
}

export default MusicContent