import styled from "styled-components";
import Checkbox from "../Checkbox";
import { Link } from "react-router-dom";
import { hexToRgba } from "../HexToRGBA";

export const CommentBoothContainer = styled.div`
    display: flex;
    background-color: ${props => props.theme?.mainBg};
    flex-direction: column;
    color: ${props => props.theme.secondaryBg};
    max-width: 100%;
`

export const CommentListWrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-self: flex-start;
    align-content: center;

`

export const CommentThreadContainer = styled.div`
display: block;
box-sizing: border-box;
width: 100%;
will-change: transform;
`

export const CommentThread = styled.div`
display: flex;
border-bottom: 1px solid ${props => hexToRgba(props.theme.secondaryBg, 0.4)};
flex-direction: column;
padding: 2%;
&:hover {
  transition: all 0.2s ease-in-out;
  background-color: ${props => hexToRgba(props.theme.secondaryBg, 0.2)};
}
`

export const Comment = styled.div`
display: flex; 
padding: 0 24px;
flex-direction: column;
overflow: hidden;
position: relative;

`
export const CommentRowContainer = styled.div`
display: flex; 
`
export const CommentRow = styled.div`
display: flex; 
flex-direction: row;
justify-content: space-between;
`

export const CommentRowBody = styled.div`
display: flex;
flex-direction: row;
flex: 7 1 100%;
`

export const CommentCheckBox = styled.div`

padding-right: 32px;
`

export const CommenterThumbnail = styled.img`
    border-radius: 50%;
    height: 40px;
    margin-right: 16px;
    margin-top: 2px;
    overflow: hidden;
    width: 40px;

`

export const CommentContent = styled.div`
    display: flex;
    flex: 1 1 0;
    flex-basis: 0.000000001px;
    flex-direction: column;

`

export const CommentContentMetadata = styled.div`
display: flex;
width: 100%;
align-content: center;
justify-content: space-between;
color: ${props => props.theme.secondaryColor};
margin-bottom: 4px;
font-weight: 400;
font-size: 13px;
`

export const CommenterName = styled.div`
    display: flex;
    width: 100%;
    padding: 2%;
    margin-right: 1%;
    white-space: nowrap;
`
export const CommenterBadge = styled.div`
display: flex;
margin-bottom: -2px;
margin-right: -3px;
padding: 2%;
vertical-align: text-top;

`

export const CommentContentMetatdataText = styled.text`
display: flex;
width: 100%;
padding: 2%;
color: ${props => props.theme.secondaryBg};

white-space: nowrap;
`

export const CommentWrapper = styled.div`
line-height: 1;
position: relative;
display: block;


`
export const CommentText = styled.text`
max-height: 80px;
overflow: hidden;
white-space: nowrap;
    color: ${props => props.theme.secondaryBg};

word-break: break-word;
font-weight: 400;
font-size: 13px;
line-height: 20px;
padding: 2%;

`
export const ReadMore = styled.div`
margin: 0;
padding: 0;
position: absolute;
max-height: 20px;
text-transform;
width: fit-content;
color: ${props => props.theme.accentColor};
topL 60px;
right: 0;
font-size: 13px;
line-height: 20px;

`

export const CommentActionButtons = styled.div`
display: flex;
flex-direction: row;
margin-top: 8px;
diplay: inline-flex;
align-items: center;
`

export const CommentButton = styled.div`
margin-right: 8px;
cursor: pointer;
font-weight: 700;
align-items: center;
align-content: center;
justify-content: center;
border-radius: 15px;
padding: 3% 5%;
white-space: nowrap;
word-break: nowrap;
width: fit-content;
background-color: ${props => hexToRgba(props.theme.accentColor, 0.3)};

 &:hover{
    background-color: ${props => hexToRgba(props.theme.accentColor, 0.5)} 
    
  }

`

export const CommentSideContentWrapper = styled.div`
display: flex;
justify-content: flex-end;
align-items: center;
margin-top: 2px;
flex: 4 1 100%;
text-overflow: clip;
overflow:initial;

`

export const CommentSideContent = styled(Link)`
text-decoration: none;
curson: pointer;
display: flex;
flex-direction: row;
align-content: center;
justify-content: space-around;
`
export const CommentSideContentImg = styled.img`
background: cover ${props => props?.src} ${props => props?.theme.secondaryColor};
margin-right: 5%px;
width: 5rem;
height: 5rem;
display: flex;
padding: 2%;
border-radius: 10px;
`

export const CommentSideContentTitle = styled.div`
display: box;
width: 168px;
align-content: center;
color: ${props => props.theme.secondaryBg};
font-weight: 400;
font-size: 13px;
line-height: 20px;
text-overflow: ellipsis;
word-break: break-word;
word-wrap: break-word;

`

export const SideContentButton = styled(Link)`
display: flex;
opacity: 0;
padding: 2%;
margin-right: 8px;
font-size: 1.2rem;
 color: ${(props) => props.theme.accentColor};
  cursor: pointer;
  text-decoration: none;

  &:hover{
    border-radius: 50%;
    color: ${(props) => props.theme.secondaryBg};
    background-color: ${(props) => props.theme.secondaryColor}; 
    
  }

${CommentThreadContainer}:hover & {
    display: flex;
    z-index: 150;
    opacity: 100%;
  }
`
