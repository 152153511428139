import React, { useState, useRef, useEffect } from "react";
import { FaClipboard, FaDonate, FaPlayCircle } from "react-icons/fa";
import { ArtistInfoContainer } from "../../AudioPlayer/AudioPlayerElements";
import { CustomButton } from "../../ButtonElement";
import { DEFAULT_IMAGE } from "../../GravatarAPI";
import {
  ActionButton,
  ActionButtonsContainer,
  ArtistInfoWrapper,
  ArtistName,
  ArtistProfileHeader,
  ArtistProfileHeaderContainer,
  ArtistProfilePicture,
  ArtistProfilePictureContainer,
  HeaderContainer,
  HeaderImg,
  PlayButton,
  ShuffleButton,
  VerifiedBadge,
} from "./ArtistHeaderElements";
import verifiedBadge from "../../../images/verified_artist_page.png";
import Modal from "../../Modal";
import { ImageViewer } from "../../ImageViewer";
import { useAuth } from "../../../contexts/AuthContext";
import useArtist from "../../../hooks/useArtist";
import ToolTip from "../../ToolTip";
import { ALERT_TYPES } from "../../../utils/Enum";
import { useAlert } from "../../Alert/AlertContext";
import { copyTextToClipboard, generateQRCode } from "../../../utils";
import OptionModal from "../../OptionModal";
import { ProfileQRCode } from "../../Profile/ProfileElements";
import { HoverOption } from "../../HoverMenu/HoverMenuElements";
import { useModal } from "../../Modal/ModalContext";
import CryptoWalletIcons from "../../CryptoWalletIcons";
import TipForm, { DonationForm } from "../../TipForm";
import TipWallets from "../../TipForm/TipWallets";

const ArtistHeader = ({
  background,
  artist,
  artistSettings,
  discography,
  playDiscography,
  //shuffleDiscography,
  isSubscribed,
  wallets,
  artistName,
  isVerified,
  profilePicture,
  artistUid,
  artistId,
  isUserFollowing,
}) => {
  const [isOpen, setOpen] = useState(false);
  const {toggleModal, modalContent, openModal} = useModal()
  const { addAlert } = useAlert();
  const { currentUser, getUserSubscription, isUserSubscribed } = useAuth();
  const followRef = useRef();
  const [isFollows, setFollowing] = useState(false);
  const { followArtist, unfollowArtist } = useArtist({ id: artistId });

  const openViewer = () => {
    setOpen(!isOpen);
  };

  const closeModal = () => {
    setOpen(false);
  };

  useEffect(() => {
    setFollowing(isUserFollowing);
    return () => {}
  }, [artistId]);

  //MOVE THE BUTTONS INTO CUSTOM COMPONENT CLASSES!
  const onFollowClick = () => {
    // follow and unfollow usera
    followRef.current.enabled = false;
    followArtist()
      .then((data) => {
        if (data !== false) {
          setFollowing(!isFollows);
        }
      })
      .catch((err) => {
        return;
      });
    followRef.current.enabled = true;
  };

  const onUnfollowClick = () => {
    // follow and unfollow user

    followRef.current.enabled = false;
    unfollowArtist()
      .then((data) => {
        if (data !== false) {
          setFollowing(!isFollows);
        }
      })
      .catch((err) => {
        return;
      });
    followRef.current.enabled = true;
  };

  return (
    <>
      <HeaderContainer>
        <Modal ariaHideApp={false} isOpen={isOpen} onRequestClose={closeModal}>
          <ImageViewer
           onContextMenu={(e) => {
            e.preventDefault();
          }}
            img={
              profilePicture
                ? profilePicture + "?s=600&d=mp"
                : DEFAULT_IMAGE(600)
            }
            isCircular={true}
            title={artistName}
          />
        </Modal>
        <ArtistProfileHeader
          background={background}
          headerType={artistSettings?.headerType}
        ></ArtistProfileHeader>
        <ArtistProfileHeaderContainer>
          <ArtistProfilePictureContainer>
            <ArtistProfilePicture
             onContextMenu={(e) => {
              e.preventDefault();
            }}
              onClick={openViewer}
              src={
                profilePicture ? profilePicture : DEFAULT_IMAGE(600)
              }
              alt={artistName}
            />
          </ArtistProfilePictureContainer>
          <ArtistInfoWrapper>
            <ArtistName>
              {currentUser?.uid && Object.keys(discography)?.length > 0 && (
                <>
                  <ToolTip text={"Play Discography"}><PlayButton
                    background={background}
                    onClick={async() => { return
                    }}
                  /></ToolTip>
                 
                </>
              )}
              {artistName}
              {isVerified && (
                <ToolTip text={"This account is verified"}>
                  <VerifiedBadge
                    src={verifiedBadge}
                    onContextMenu={(e) => {
                      e.preventDefault();
                    }}
                  />
                </ToolTip>
              )}
            </ArtistName>
            {currentUser?.uid && (
              <ActionButtonsContainer>
                <ActionButton
                  ref={followRef}
                  background={background}
                  /* onClick={() =>
                    isFollows ? onUnfollowClick() : onFollowClick()
                  } */
                >
                  {" "}
                  {"Follow"}
                </ActionButton>
                 {artistSettings?.tipping && <ActionButton background={background} /* onClick ={() => {
                  toggleModal()
                  modalContent(<OptionModal heading={`Select Wallet to tip ${artistName}`}>
                    {artistUid && <><HoverOption onClick={() => {
                      toggleModal()
                      modalContent(<TipForm displayName={artistName} address={artistUid}/>)
                    }}>XRP Wallet</HoverOption>
                    <HoverOption onClick={() => {
                      toggleModal()
                      modalContent()
                      addAlert({
                        title: "Coming Soon",
                        type: ALERT_TYPES.INFO,
                        secs: 10,
                      })
                    }}>ILP Wallet</HoverOption></>}
                  { wallets?.length > 0 && <HoverOption onClick={() => {
                      toggleModal()
                      modalContent(<TipWallets wallets={wallets} />)
                    }}>Other Wallets</HoverOption>}
                  </OptionModal>)}} */>
                      Send Tip
                    </ActionButton>}
                {artistUid &&
                  isVerified &&
                  artist?.subscriptionFee > 0 &&
                  artistUid !== currentUser?.uid && (
                    <ActionButton background={background}>
                      {isSubscribed ? "Subscribed" : "Subscribe"}
                    </ActionButton>
                    
                  )}
                {/* artistUid &&
                  isVerified &&
                  artist?.donation &&
                  (
                    <ActionButton style={{backgroundColor: 'goldenrod'}} background={background} onClick={() => {
                      toggleModal()
                      modalContent(<OptionModal heading={'Donation'} additionalText={<i>You are about to make a donation to a cause of {artistName}'s choice. To continue select the 'Donate' button</i>}>
                        <HoverOption onClick={()=> {
                          modalContent(<DonationForm displayName={artistName} cause={artist?.donation} currency={artist?.currency} address={artist?.donation?.address}/>)
                        }}>Donate</HoverOption>
                        <HoverOption>Cancel</HoverOption>
                      </OptionModal>)
                    }}>
                      <FaDonate/> Make Donation 
                    </ActionButton>
                    
                  ) */}
              </ActionButtonsContainer>
            )}
          </ArtistInfoWrapper>
        </ArtistProfileHeaderContainer>
      </HeaderContainer>
    </>
  );
};

export default ArtistHeader;
