import React, { useEffect, useState } from "react";
import ContentList from "../ContentList";
import ContentWrapper from "../ContentWrapper";
import {
  AlbumArt,
  albumArt,
  AlbumArtContainer,
  albumArtContainer,
  AlbumArtWrapper,
  albumArtWrapper,
  DetailsWrapper,
  Img,
  MusicAboutWrapper,
  MusicContentWrapper,
  MusicDetailsContainer,
  MusicDetailsWrapper,
  MusicHeaderWrapper,
  MusicHeading,
  MusicHeadingBgImage,
  MusicHeadingContainer,
  MusicHeadingImage,
  MusicHeadingImageWrapper,
  MusicHeadingSmall,
  MusicHeadingTextWrapper,
  MusicHeadingWrapper,
  MusicInfoWrapper,
  MusicOptionsContainer,
  MusicPageContainer,
  MusicPageWrapper,
  MusicPlayerWrapper,
  MusicPlayPauseButton,
  MusicPriceWrapper,
  MusicSubheading,
  NftBadgeWrapper,
  NftLogo,
  Option,
  OptionButton,
  OptionMenuButton,
  OptionText,
  ProgressBar,
  ProgressBarWrapper,
  PurchaseButton,
  Select,
  SongDetailsText,
  SongDetailsWrapper,
  SubHeading,
  Text,
} from "./MusicPageElements";
import { FaPlay, FaPause, FaRegMoneyBillAlt, FaShare } from "react-icons/fa";
import { filterLikes, getSongTitle, timestampToDate } from "../../utils";
import { FcLike, FcLikePlaceholder } from "react-icons/fc";
import { MdBookmarkBorder } from "react-icons/md";
import { GoReport } from "react-icons/go";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { Slider } from "../Slider";
import { AiOutlineEye } from "react-icons/ai";
import defaultImg from "../../images/sm_album_art_placeholder.png";
import MenuOptions from "../MenuOptions";
import {
  ALERT_TYPES,
  CONTENT_TYPES,
  MUSIC_PLAYING_FROM,
} from "../../utils/Enum";
import { BsShuffle, BsThreeDots } from "react-icons/bs";
import { useWebsiteTheme } from "../WebsiteTheme/WebsiteThemeContext";

import { hexToRgba } from "../HexToRGBA";
import { useAlert } from "../Alert/AlertContext";
import MusicEditPage from "../MusicEditPage";
import CommentForm from "../CommentForm";
import { useModal } from "../Modal/ModalContext";
import CommentsSection from "../CommentsSection";
import { shuffleSongs } from "../../utils/MusicUtils";
import { CustomButton } from "../ButtonElement";
import OptionModal from "../OptionModal";
import { HoverOption } from "../MenuOptions/MenuOptionsElements";
import { getAddressName } from "../../utils/nfTokensUploads";

export default function PlaylistPage({
  id,
  playlist,
  isSongPlaying,
  handleToggle,
  currentSong,
  audioPlayer,
  deletePlaylist,
  query,
  location,
}) {
  const { currentUser } = useAuth();
  const { modalContent, toggleModal, isOpen, openModal, closeModal } =
    useModal();

  const navigate = useNavigate();
  const { addAlert } = useAlert();
  const [isEditing, setEditing] = useState(false);
  const [curatorName, setCuratorName] = useState()
  const { deletePlaylistPrompt, musicOptionsModal } = MenuOptions();

  useEffect(() => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);

    if (params.has("edit")) {
      if (currentUser?.uid === playlist?.curator?.curatorId || playlist?.includes(currentUser?.uid) ) {
        setEditing(true);
      }
    } else {
      setEditing(false);
    }
    return () => {};
  }, []);

  useEffect(() => {
    getAddressName(playlist?.curator?.curatorId).then((curator) => {
    setCuratorName(curator)})
    return () => {}
  }, [playlist])

  const handleSelect = async (e) => {
    switch (e.target.value) {
      case "delete":
        deletePlaylistPrompt(id, currentUser?.uid);
        break;
      case "edit":
        const params = new URLSearchParams({ edit: true });
        navigate(`${location.pathname}?edit=true`, {
          replace: true,
        });
        break;

      default:
        break;
    }
  };

  const postComment = () => {
    if (!isOpen) {
      toggleModal();
    }
    modalContent(
      <>
        <CommentForm
          contentName={playlist.playlistName}
          contentId={playlist?.playlistId}
          type={CONTENT_TYPES.PLAYLIST}
        />
      </>
    );
  };

  const playSong = (i) => {
    return;
  };

  const togglePlay = (pl) => {
    
  };

  const isPlayingFrom = () => {
    if (
      localStorage?.getItem("isPlayingFrom") &&
      JSON.parse(localStorage?.getItem("isPlayingFrom"))?.playingFrom ===
        MUSIC_PLAYING_FROM.PLAYLIST &&
      JSON.parse(localStorage?.getItem("isPlayingFrom"))?.id ===
        playlist.playlistId
    ) {
      return true;
    }
    return false;
  };

  return (
    <MusicPageWrapper>
      <MusicHeadingContainer>
        <MusicHeadingBgImage
          color={playlist?.color}
          src={
            playlist?.playlistArt
              ? playlist.playlistArt
              : playlist?.songs && playlist?.songs[0]?.albumArt
              ? playlist?.songs[0]?.albumArt
              : defaultImg
          }
          alt={playlist.playlistName}
        ></MusicHeadingBgImage>
        <MusicHeadingWrapper>
          <MusicHeadingImageWrapper>
            <MusicHeadingImage
              color={playlist?.color}
              src={
                playlist?.playlistArt
                  ? playlist.playlistArt
                  : playlist?.songs && playlist?.songs[0]?.albumArt
                  ? playlist?.songs[0]?.albumArt
                  : defaultImg
              }
              alt={playlist.playlistName}
            />
          </MusicHeadingImageWrapper>
          <MusicHeadingTextWrapper>
            <SubHeading>
              <small>Playlist</small>
            </SubHeading>
            <MusicHeading>{playlist?.playlistName}</MusicHeading>
            <MusicSubheading>
              <MusicHeading
                style={{ cursor: "pointer" }}
                onClick={() => navigate(`/u/${playlist?.curator?.curatorId}`)}
              >
                {curatorName && (curatorName !== playlist?.curator?.curatorId) ? curatorName : `${playlist?.curator?.curatorId.substring(0, 8)}...${playlist?.curator?.curatorId.substring(playlist?.curator?.curatorId.length -5,playlist?.curator?.curatorId.length)}`}
              </MusicHeading>
            </MusicSubheading>
            <MusicHeadingSmall>
              {`${playlist?.songs?.length} tracks`}•
              {new Date(timestampToDate(playlist?.datePublished)).getFullYear()}{playlist?.collaborators?.length > 0 ? `•${playlist?.collaborators?.length} Collaborator(s)`: ''}
            </MusicHeadingSmall>
            <MusicOptionsContainer>
            </MusicOptionsContainer>
          </MusicHeadingTextWrapper>
        </MusicHeadingWrapper>
      </MusicHeadingContainer>

      {!isEditing && (
        <>
          <MusicDetailsContainer>
            <MusicInfoWrapper>
              <MusicAboutWrapper>{playlist?.about}</MusicAboutWrapper>
              {playlist?.collaborators?.length > 0 && <CustomButton onClick={() => {
                openModal()
                modalContent(<>
                  <OptionModal onCancel={() => {closeModal()
                  modalContent()}} heading={`${playlist?.playlistName} Collaborators`}>
                    {playlist?.collaborators.map((c, index)=> {
                      return <HoverOption id={index} onClick={() => {
                        navigate(`/u/${c}`)
                        closeModal()
                        modalContent()
                    }}>{`${c?.substring(0, 8)}...${c?.substring(c?.length -5,c?.length)}`}</HoverOption>
                    })}
                    </OptionModal>
                </>)
              }}>View Collaborators</CustomButton>}
              {playlist?.songs?.length > 0 && (
                <ContentWrapper heading={"Playlist"}>
                  <ContentList
                    type={CONTENT_TYPES.SONG}
                    onButtonClick={playSong}
                    content={
                      playlist?.songs &&
                      playlist?.songs?.map((p) => {
                        return {
                          ...p,
                          playingFrom: {
                            playingFrom: MUSIC_PLAYING_FROM.PLAYLIST,
                            id: playlist?.playlistId,
                          },
                        };
                      })
                    }
                  />
                </ContentWrapper>
              )}
            </MusicInfoWrapper>
          </MusicDetailsContainer>

          <MusicContentWrapper>
            <ContentWrapper heading={`Comments`}>
              <CommentsSection
                type={CONTENT_TYPES.PLAYLIST}
                id={playlist?.playlistId}
                contentName={playlist?.playlistName}
              />
            </ContentWrapper>
          </MusicContentWrapper>
        </>
      )}
      {isEditing && <MusicEditPage type="playlist" content={playlist} />}
    </MusicPageWrapper>
  );
}
