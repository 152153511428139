import React, { useContext } from "react";
import { useState } from "react";
import { ThemeProvider } from "styled-components";
import Alert from ".";
import { ALERT_TYPES, NOTIFICATION_DISPATCH } from "../../utils/Enum";
import { useWebsiteTheme } from "../WebsiteTheme/WebsiteThemeContext";

import {v4} from 'uuid'
import { AlertWrapper } from "./AlertElements";
import { useReducer } from "react";
export const AlertContext = React.createContext();

export function useAlert() {
    return useContext(AlertContext);
  }

 const AlertProvider = ({children}) => {
    const [state, dispatch] = useReducer( (state,action) => {
        switch (action.type) {
            case NOTIFICATION_DISPATCH.ADD_NOTIFICATION:
                return [...state, (action.payload)]
            case NOTIFICATION_DISPATCH.REMOVE_NOTIFICATION:
                return state.filter(el => el.id !== action.id)
        
            default:
                return state
        }
    }, [])
    const addAlert = ({type, title, message, secs}) => {
        return dispatch({
            type: NOTIFICATION_DISPATCH.ADD_NOTIFICATION,
        payload: {
            id: v4(),
        type: type ,
        title: title,
        message: message,
        secs: secs
        
        }})
    } 

    /* displatch({
        type: NOTIFICATION_DISPATCH.ADD_NOTIFICATION,
        payload: {
            id: v4(),
        type: ALERT_TYPES.DANGER ,
        message: 'Hello World',
        }
    }) */
    const value = {
        dispatch,
        addAlert
    }
    return (
        <>
        <AlertContext.Provider value={value} >
            <AlertWrapper>
                {state.map((alert, index) => {
                    return( <Alert dispatch={dispatch} key={alert.id} {...alert}/>)
                })}
            </AlertWrapper>
             {children}
        </AlertContext.Provider>
        </>
    )

}

export default AlertProvider