import { Fade } from "react-reveal";
import ImageUtils from "../../../utils/ImageUtils";
import {
  DetailsContainer,
  DetailsText,
  ImageWrapper,
  Img,
  Table,
  TableData,
  TableHeading,
  TableRow,
} from "../ContentListElements";
import { Small } from "../../QRCodeForm/QrCodeFormElements";
import { useWebsiteTheme } from "../../WebsiteTheme/WebsiteThemeContext";


const PlaylistList = ({content, onClick}) => {
  const {themeColors} = useWebsiteTheme()

  const { defaultAlbumArt, DEFAULT_IMAGE } = ImageUtils();

  return (
    <>
      <Table>
        <TableRow>
          <TableHeading></TableHeading>
          <TableHeading></TableHeading>
          <TableHeading></TableHeading>
        </TableRow>
        {content.map((playlist, index) => {
          return (
            <TableRow
              key={index}
              id={playlist?.playlistId}
              to={"#"}
              onClick={() => (onClick ? onClick(index) : {})}
            >
              <TableData style={{ width: "10%" }}>
                <ImageWrapper>
                  <Img
                    src={playlist?.playlistArt ? playlist?.playlistArt: defaultAlbumArt}
                    alt={playlist?.playlistName}
                  />
                </ImageWrapper>
              </TableData>
              <TableData>
                <DetailsContainer>
                  <DetailsText>
                    {playlist?.playlistName}
                  </DetailsText>
                  <DetailsText style={{ color: themeColors.secondaryColor }}>
                    <small>{playlist?.curatorId} {playlist?.collaborators?.length > 0 && ` & ${playlist?.collaborators?.length} ${playlist?.collaborators?.length > 1 ? `others`: 'other'}`}
                  </small></DetailsText>
                  {playlist?.about && <DetailsText>
                    <Small>{playlist?.about?.length > 30 ? `${playlist?.about?.substring(0, 30)}...`:playlist?.about }</Small>
                  </DetailsText>}
                </DetailsContainer>
                
              </TableData>
              <TableData>{playlist?.songs?.length > 0 && <DetailsContainer>
                  <small style={{whiteSpace: "nowrap"}}>{playlist?.songs?.length} {playlist?.songs?.length > 1 ? `songs` : 'song'}</small>
                </DetailsContainer>}</TableData>
            </TableRow>
          );
        })}
      </Table>
    </>
  );
}

export default PlaylistList