import React from 'react'
import { ErrorWrapper, FormInput, FormLabel, FormWrapper, SongDetailsSection, SongDetailsSectionTitle, SongDetailsText } from '../MusicUploadsFormElements'
import ToggleButton from '../../ToggleButton';

const Page1 = ({videoTitle, errors, handleInput, isExistingSong, handleToggle}) => {
  return (
    <>
    <SongDetailsSection>
    <SongDetailsSectionTitle>Details</SongDetailsSectionTitle>
    <FormWrapper>
        <FormLabel>Video Title</FormLabel>
        <ErrorWrapper>
          <FormInput
            placeholder={"Video Title"}
            name={"title"}
            readOnly={isExistingSong}
            value={videoTitle}
            onChange={(e) => {
              handleInput(e);
            }}
          />
          {errors?.videoTitle && (
            <SongDetailsText error>{errors?.videoTitle}</SongDetailsText>
          )}
        </ErrorWrapper>
      </FormWrapper>
      <FormWrapper>
                  <FormLabel
                    onClick={() => {
                      handleToggle("exists");
                    }}
                  >
                    From an Existing Song on The Platform
                  </FormLabel>
                <ToggleButton  
                onText={'On'}
                offText={'Off'}
                toggle={isExistingSong}
                toggleClick={() => { handleToggle("exits")}}/>
                </FormWrapper>
    </SongDetailsSection>
    
    </>
  )
}

export default Page1