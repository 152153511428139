import { HoverOption } from "../components/MenuOptions/MenuOptionsElements";
import { useModal } from "../components/Modal/ModalContext";
import OptionModal from "../components/OptionModal";
import { useAuth } from "../contexts/AuthContext";

export const updateWebPageIcon = (icon) => {
  if(icon){
  let link = document.querySelector("link[rel~='icon']");
  if (!link) {
    link = document.createElement('link');
    link.rel = 'icon';
    document.getElementsByTagName('head')[0].appendChild(link);
  }
  link.href = icon;} 
}

export function shuffleSongs(songs) {
    let array = songs
    let currentIndex = songs.length
    let randomIndex
  
    // While there remain elements to shuffle.
    while (currentIndex !== 0) {
  
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  
    return array;
  }


  export const convertRoyaltiesToArrObj = (revShare) => {
    let keys = Object.keys(revShare)
    return keys.map((key, index) => {
      return {artistId: key,
      percentage: revShare[key]}
    })
  }

  export const songNameContainsFeature = function (name){
    let name1 = name.toLowerCase()
    if(name1?.includes("(ft.")|| name1?.includes("(feat.") || 
    name1?.includes("(featuring.")){
      return true
    }
    return false
  }