import React, { useEffect, useState } from 'react'
import Sidebar from '../../components/Sidebar';
import Navbar from '../../components/Navbar';
import LandingPage from '../../components/LandingPage';
import { Welcome } from '../../components/Navbar/Data';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

const WelcomePage = () => {
  const {currentUser} = useAuth()
    const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const navigate = useNavigate()
  useEffect(() => {
    if(currentUser?.uid){
      navigate("/home",{replace: true})}
  
    return () => {
    }
  }, [])
  
  return (
    
   <> <Sidebar isOpen={isOpen} toggle={toggle} navInfo={Welcome} />
      <Navbar toggle={toggle} navInfo={Welcome} />
    <LandingPage/></> 
  )
}

export default WelcomePage