import React from 'react'
import ContentWrapper from './ContentWrapper'
import { FormText, FormWrapper } from './CustomForm/CustomFormElements'
import { HeroH1 } from './HeroSection/HeroElements'
import { Container } from './SignUp/SignUpElements'
import { useEffect } from 'react'
import { useState } from 'react'


export const MEDIA_SCREEN = { //Screen sizes for devices
    MOBILE: '480',
    TABLETS: '768',
    LAPTOPS: '1024',
    DESKTOPS: '1025',
    TV: '1500'
  }

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

const ScreenRealEstate = ({children}) => {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }
  
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, [])

    
  return (
    <>{windowDimensions.width >= parseInt(MEDIA_SCREEN.TABLETS) && children }
    {windowDimensions.width < parseInt(MEDIA_SCREEN.TABLETS) && <Container>
        <ContentWrapper>
            <FormWrapper>
                <HeroH1>Screen Too Small</HeroH1>
                <FormText>The Sonar Muse for Artists Portal is not available on mobile devices. Please visit the artist portal on your desktop.</FormText>
            </FormWrapper>
        </ContentWrapper>
        </Container>}
    </>
  )
}

export default ScreenRealEstate