import styled from "styled-components";
import { hexToRgba } from "../HexToRGBA";


export const HoverContainter = styled.div`
    position: relative;
    padding: 2%;
    display: inline-block;
`


export const ChildDiv = styled.div`
    display: flex;
`

export const HoverContent = styled.div`
    display: flex;
    position: absolute;
    flex-direction: column;
    background-color: ${props => hexToRgba(props.theme.mainBg, 0.8)};
    padding: 5%;
    min-width: 10rem;
    border-radius: 15px;
    margin-right: 30%;
    margin-bottom: 50%;
    overflow: auto;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);

`

export const HoverOption = styled.div`
    display:flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    padding: 2%;
    &:hover {
        background-color: ${props => hexToRgba(props.theme.accentColor, 0.8)}; 
    }
`
