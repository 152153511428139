import * as React from 'react';
import TableCell from '@mui/material/TableCell';
import TablePagination from '@mui/material/TablePagination';
import './ContentTable.css'
import {Container, ContentTableBody, ContentTableCell, ContentTableContainer, ContentTableHead, ContentTableRow, ContentTableRow1, ContentTableWrapper } from './ContentTableElements';
import { ImageWrapper, Img } from '../ContentList/ContentListElements';
import { useWebsiteTheme } from '../WebsiteTheme/WebsiteThemeContext';


export default function ContentTable({columns, rows, onCellClick}) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const {themeColors} = useWebsiteTheme()

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Container>
      <ContentTableContainer sx={{ maxHeight: 800 }}>
        <ContentTableWrapper>
          <ContentTableHead>
            <ContentTableRow>
              <ContentTableCell key={'#'} style={{minWidth: '80'}} align={'center'}>
              <text style={{color: themeColors.secondaryBg}}>#</text> 
              </ContentTableCell>
              {columns.map((column) => (
                <ContentTableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  <text style={{color: themeColors.secondaryBg}}>{column.label}</text>
                </ContentTableCell>
              ))}
            </ContentTableRow>
          </ContentTableHead>
          <ContentTableBody>
            {rows
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <ContentTableRow1 role="checkbox" tabIndex={-1} key={row.code}>
                    <TableCell><text style={{color: themeColors.secondaryBg}}>{index + 1}</text></TableCell>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell onClick={() => onCellClick ? onCellClick(index) : {}} key={column.id} align={column.align}>
                          {column.id === 'albumArt' || column.id === 'thumbnail' || column.id === 'playlistArt' ? <ImageWrapper>
                  <Img
                    src={
                      value
                    }
                    alt={column?.label}
                  /></ImageWrapper>:<text style={{color: themeColors.secondaryBg}}>{column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}</text>}
                        </TableCell>
                      );
                    })}
                  </ContentTableRow1>
                );
              })}
          </ContentTableBody>
        </ContentTableWrapper>
      </ContentTableContainer>
     {rows?.length > 10 && <TablePagination
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        style={{color: themeColors?.secondaryBg}}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />}

    </Container>
  );
}
