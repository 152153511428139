import styled from "styled-components";

export const Container = styled.div`
display: flex;
flex-direction: row;
align-items: center;
flex: 1 1 50%;
justify-content: flex-start;
padding: 3% 1%;
flex-wrap:wrap;
color: ${props => props.theme.secondaryColor};
position: relative;
@media screen and (max-width: 960px) {
 
    flex: 1 1 20%;
  }
  @media screen and (max-width: 960px) {
    font-size: 0.8em;
  }
`

export const SocialLink = styled.a`
display: flex;
flex: none;
align-items: center;
justify-content: center;
border-radius: 50%;
background-color: ${props => props.background ? props.background : props.theme.mainColor};
padding: 2% 2%;
margin-right:1.5%;
color: ${props => props.theme.secondaryBg};
text-decoration: none;
&:hover{
    transform: scale(1.1);
    transition: 1s eas-out;
}`


export const UrlLink = styled.a`
display: flex;
flex: none;
align-items: center;
justify-content: center;
border-radius: 50%;
padding: 1% 1%;
margin-right:1%;
color: ${props => props.theme.secondaryColor};
text-decoration: none;
&:hover{
    color: ${props => props.background ? props.background : props.theme.mainColor};
    transform: scale(1.1);
    transition: 1s eas-out;
}`
