import { useState } from "react";
import { Fade, Rotate } from "react-reveal";
import styled, { keyframes } from "styled-components";
import hexToRgbA, { hexToRgba } from "./HexToRGBA";

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 5%;
    z-index: 999;
    `


export const Image = styled.img`
    display:flex;
    width: 20rem;
    transition: 1s ease-out;
    margin: 5% 5%;
    border-radius: ${props => (props.isCircular ? '50%': 0)};
    cursor: pointer;
    &:hover{
        transform: scale(1.1);
        transform: ${props => (props.rotate ? 'rotateY(180deg)': 0)};

    }

`

    const rotate = keyframes`
    transform: rotateX(45deg);
    from {
        transform: rotate(0 deg);
    }
    to {
    transform rotate(360deg);
    }

`

export const AnimatedImage = styled.img`
    display:flex;
    width: 15rem;
    height: 15rem;
    justify-self: center;
    border: 2px black bevel;
    oveflow: hidden;
    transition: 1s ease-out;
    border-radius: ${props => (props.isCircular ? '50%': 0)};
    cursor: pointer;
    border-radius: 50%;
    
    transform-style: preserve-3d;
   
    

`

export const RotationImageBorder = styled.div`
    display: flex;
    width: 30rem;
    height: 30rem;
    align-items: center;
    align-content: center;
    justify-content: center;
    transition: 1s ease-out;
    background-image: radial-gradient(circle, rgba(255,0,0,0) , ${props => hexToRgba('#302f2a',0.9)} );
    border-radius: 50%;
    animation:  ${rotate} infinite 10s linear ;
    
    &:hover{
        perspective: 100px;
        transform: ${props => (props.rotate ? 'rotateY(180deg)': 0)};
        animation: none;

    }

    `

const ImageTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    background: ${props => hexToRgba(props.theme.mainBg, 0.8)};
    color: ${props => props.theme.secondaryBg};
    position: relative;
    padding: 3% 1%;
    
   
`
const ImageTitle = styled.p`
    display: flex;
    font-size: 1.5rem;
    font-weight:700;
   

`
const ImageDescription = styled.p`
    display: flex;
    font-weight:500;
  


`


export const ImageViewer = ({img, alt, title, description, isCircular, rotate, animate, stop}) => {

    return (
       <> 
       <Container>
           {
            animate  ? 
            <>{!stop ? 
            <RotationImageBorder>
                <Fade><AnimatedImage isCircular={true} src={img} alt={alt} onContextMenu={(e) => {e.preventDefault()}}/></Fade>

            </RotationImageBorder> :             
            
            
                <Fade><Image rotate={rotate} isCircular={false} src={img} alt={alt} onContextMenu={(e) => {e.preventDefault()}}/></Fade>

            }</>
              :  
            <Image rotate={rotate} isCircular={isCircular} src={img} alt={alt} onContextMenu={(e) => {e.preventDefault()}}/>
            }
        <ImageTextContainer>
        {title && <ImageTitle>{title}</ImageTitle>}
        {description && <ImageDescription>{description}</ImageDescription>}
        </ImageTextContainer>
        </Container> 
        
        </>
    )

}