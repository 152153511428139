import React, { useEffect, useState } from "react";
import { SelectButton, SelectButtonWrapper } from "../Select";
import KeyMetrics from "../Charts/KeyMetrics";
import useMusic from "../../hooks/useMusic";
import {
  getGroupedPlays,
  getNumListeners,
  getObjectLength,
  getPlayCount,
  groupByPlayingFrom,
  groupInfoByDate,
} from "../../utils";
import { useWebsiteTheme } from "../WebsiteTheme/WebsiteThemeContext";

import { ModalLoadingScreen } from "../LoadingScreen";
import ContentWrapper from "../ContentWrapper";
import { getRandomColor } from "../HexToRGBA";
import { ChipList } from "../Chip/ChipElements";
import Chip from "../Chip";
import { FormText, FormWrapper } from "../CustomForm/CustomFormElements";
import { PlaylistTable } from "../ContentTable/ArtistTables";
import { MUSIC_PLAYING_FROM_STATS_COLORS } from "../../utils/Enum";
import ChartToggle from "../Charts";

const EpisodeStats = ({ episode }) => {
  const {themeColors} = useWebsiteTheme()

  const {
    getEpisodePlaysByDate,
    getEpisodeLikesByDate,
    getEpisodePlaylistAdditionsByDate,
  } = useMusic();
  const [filter, setFilter] = useState("7days");
  const [isLoaded, setLoaded] = useState(false);
  const [metricType, setMetricType] = useState("plays");
  const [likes, setLikes] = useState([]);
  const [plays, setPlays] = useState([]);
  const [playType, setPlayType] = useState("all");
  const [playlists, setPlaylists] = useState([]);

  const getData = async () => {
    setLoaded(false);
    setPlays([]);
    setPlaylists([]);
    setLikes([]);
    return await Promise.all([
      getEpisodePlaysByDate(episode, filter),
      getEpisodeLikesByDate(episode, filter),
      getEpisodePlaylistAdditionsByDate(episode, filter, "all"),
    ])
      .then((data) => {
        if (data[0]?.plays?.length > 0) {
          setPlays(data[0]?.plays);
        }

        if (data[1]?.likes?.length > 0) {
          setLikes(data[1]?.likes);
        }
        if (data[2]?.playlists?.length > 0) {
          setPlaylists(data[2]?.playlists);
        }
        setLoaded(true);
      })
      .catch((err) => {
        console.error(err);
        setLoaded(true);
        return;
      });
  };

  useEffect(() => {
    getData();

    return () => {};
  }, [episode, filter]);

  return (
    <>
      {" "}
      <SelectButtonWrapper>
        <SelectButton
          disabled={!isLoaded}
          onChange={(e) => setFilter(e.target.value)}
        >
          <option value={"7days"}>7 days</option>
          <option value={"14days"}>2 Weeks</option>
          <option value={"30days"}>4 Weeks</option>
          <option value={"60days"}>2 Months</option>
          <option value={"180days"}>6 Months</option>
          <option value={"365days"}>12 Months</option>
          <option value={"AllTime"}>All Time</option>
        </SelectButton>
      </SelectButtonWrapper>
      {!isLoaded && <ModalLoadingScreen text={"Loading Stats"} />}
      {isLoaded && (
        <>
          <KeyMetrics
            data={[
              {
                heading: "Plays",
                onClick: () => {
                  setMetricType("plays");
                },
                labels:
                  plays?.length > 0
                    ? Object.keys(groupInfoByDate(plays, "timestamp", filter))
                    : [],
                datasets: [
                  {
                    label: "Plays",
                    data:
                      plays?.length > 0
                        ? Object.values(
                            groupInfoByDate(plays, "timestamp", filter)
                          ).map((p) => {
                            return getPlayCount(p);
                          })
                        : [],
                    borderColor: themeColors.mainColor,
                    backgroundColor: themeColors.mainColor,
                  },
                ],
              },
              {
                heading: "Likes",
                onClick: () => {
                  setMetricType("likes");
                },
                labels:
                  likes?.length > 0
                    ? Object.keys(groupInfoByDate(likes, "timestamp", filter))
                    : [],
                datasets: [
                  {
                    label: "likes",
                    data:
                      likes?.length > 0
                        ? Object.values(
                            groupInfoByDate(likes, "timestamp", filter)
                          ).map((l) => {
                            return l;
                          })
                        : [],
                    borderColor: themeColors.successColor,
                    backgroundColor: themeColors.successColor,
                  },
                ],
              },
              {
                heading: "Playlists",
                onClick: () => {
                  setMetricType("playlists");
                },
                labels:
                  playlists.length > 0
                    ? Object.keys(
                        groupInfoByDate(playlists, "dateCreated", filter)
                      )
                    : [],
                datasets: [
                  {
                    label: "playlists",
                    data:
                      playlists.length > 0
                        ? Object.values(
                            groupInfoByDate(playlists, "dateCreated", filter)
                          ).map((p) => {
                            return p?.length;
                          })
                        : [],
                    borderColor: themeColors.warningColor,
                    backgroundColor: themeColors.warningColor,
                  },
                ],
              },
            ]}
          />
          <ContentWrapper>
            {metricType === "plays" && getObjectLength(plays) > 0 && (
              <>
                <ChipList alignment={"right"}>
                  <Chip
                    label={"All"}
                    background={playType === "all" && themeColors.accentColor}
                    onChipClick={() => setPlayType("all")}
                  />
                  <Chip
                    label={"Full Plays"}
                    background={playType === "full" && themeColors.successColor}
                    onChipClick={() => setPlayType("full")}
                  />
                  <Chip
                    label={"Partial Plays"}
                    background={playType === "partial" && themeColors.infoColor}
                    onChipClick={() => setPlayType("partial")}
                  />
                  <Chip
                    label={"Skips"}
                    background={playType === "skip" && themeColors.warningColor}
                    onChipClick={() => setPlayType("skip")}
                  />
                </ChipList>
                <>
                  {" "}
                  {playType === "all" && (
                    <>
                        <ChartToggle
                          data={{
                            heading: 'All Plays (By Type)',
                            labels:
                              getObjectLength(plays) > 0
                                ? Object.keys(
                                    groupInfoByDate(
                                      [
                                        ...plays?.full,
                                        ...plays?.skips,
                                        ...plays?.partial,
                                      ],
                                      "timestamp",
                                      filter
                                    )
                                  )
                                : [],
                            datasets: [
                              {
                                label: "Full",
                                data:
                                  plays?.full?.length > 0
                                    ? Object.values(
                                        groupInfoByDate(
                                          plays?.full,
                                          "timestamp",
                                          filter
                                        )
                                      ).map((p) => {
                                        return p.length;
                                      })
                                    : [],

                                borderColor: themeColors.successColor,
                                backgroundColor: themeColors.successColor,
                              },
                              {
                                label: "Partial",
                                data:
                                  plays?.partial?.length > 0
                                    ? Object.values(
                                        groupInfoByDate(
                                          plays?.partial,
                                          "timestamp",
                                          filter
                                        )
                                      ).map((p) => {
                                        return p.length;
                                      })
                                    : [],

                                borderColor: themeColors.infoColor,
                                backgroundColor: themeColors.infoColor,
                              },
                              {
                                label: "Skips",
                                data:
                                  plays?.skips?.length > 0
                                    ? Object.values(
                                        groupInfoByDate(
                                          plays?.skips,
                                          "timestamp",
                                          filter
                                        )
                                      ).map((p) => {
                                        return p?.length;
                                      })
                                    : [],
                                borderColor: themeColors.warningColor,
                                backgroundColor: themeColors.warningColor,
                              },
                            ],
                          }}
                        />
                     
                       
                        <ChartToggle
                          data={{
                            heading: 'Unique Listeners',
                            labels:
                              getObjectLength(plays) > 0
                                ? Object.keys(
                                    groupInfoByDate(
                                      [
                                        ...plays?.full,
                                        ...plays?.skips,
                                        ...plays?.partial,
                                      ],
                                      "timestamp",
                                      filter
                                    )
                                  )
                                : [],
                            datasets: [
                              {
                                label: "Listeners",
                                data:
                                  getObjectLength(plays) > 0
                                    ? Object.values(
                                        getNumListeners(
                                          groupInfoByDate(
                                            [
                                              ...plays?.full,
                                              ...plays?.skips,
                                              ...plays?.partial,
                                            ],
                                            "timestamp",
                                            filter
                                          )
                                        )
                                      )
                                    : [],
                                borderColor:
                                  playType === "skip"
                                    ? themeColors.warningColor
                                    : playType === "partial"
                                    ? themeColors.infoColor
                                    : themeColors.successColor,
                                backgroundColor:
                                  playType === "skip"
                                    ? themeColors.warningColor
                                    : playType === "partial"
                                    ? themeColors.infoColor
                                    : themeColors.successColor,
                              },
                            ],
                          }}
                        />
                      
                    </>
                  )}
                  {playType !== "all" && (
                    <>
                      
                        <ChartToggle
                          data={{
                            heading: `${playType[0].toUpperCase()}${playType.substring(
                            1
                          )} Plays`,
                            labels:
                              getObjectLength(plays) > 0
                                ? Object.keys(
                                    groupInfoByDate(
                                      [
                                        ...plays?.full,
                                        ...plays?.skips,
                                        ...plays?.partial,
                                      ],
                                      "timestamp",
                                      filter
                                    )
                                  )
                                : [],
                            datasets: [
                              {
                                label: playType,
                                data:
                                  getObjectLength(plays) > 0
                                    ? Object.values(
                                        groupInfoByDate(
                                          [
                                            ...plays?.full,
                                            ...plays?.skips,
                                            ...plays?.partial,
                                          ],
                                          "timestamp",
                                          filter
                                        )
                                      ).map((plays) => {
                                        return getGroupedPlays(plays)[playType];
                                      })
                                    : [],

                                borderColor:
                                  playType === "skip"
                                    ? themeColors.warningColor
                                    : playType === "partial"
                                    ? themeColors.infoColor
                                    : themeColors.successColor,
                                backgroundColor:
                                  playType === "skip"
                                    ? themeColors.warningColor
                                    : playType === "partial"
                                    ? themeColors.infoColor
                                    : themeColors.successColor,
                              },
                            ],
                          }}
                        />
                     
                        
                        <ChartToggle
                          data={{
                            heading: 'Unique Listeners',
                            labels:
                              getObjectLength(plays) > 0
                                ? Object.keys(
                                    groupInfoByDate(
                                      [
                                        ...plays?.full,
                                        ...plays?.skips,
                                        ...plays?.partial,
                                      ],
                                      "timestamp",
                                      filter
                                    )
                                  )
                                : [],
                            datasets: [
                              {
                                label: "Listeners",
                                data:
                                  getObjectLength(plays) > 0
                                    ? Object.values(
                                        getNumListeners(
                                          groupInfoByDate(
                                            [
                                              ...plays?.full,
                                              ...plays?.skips,
                                              ...plays?.partial,
                                            ].filter(
                                              (p) => playType === p?.playType
                                            ),
                                            "timestamp",
                                            filter
                                          )
                                        )
                                      )
                                    : [],
                                borderColor:
                                  playType === "skip"
                                    ? themeColors.warningColor
                                    : playType === "partial"
                                    ? themeColors.infoColor
                                    : themeColors.successColor,
                                backgroundColor:
                                  playType === "skip"
                                    ? themeColors.warningColor
                                    : playType === "partial"
                                    ? themeColors.infoColor
                                    : themeColors.successColor,
                              },
                            ],
                          }}
                        />
                    </>
                  )}
                </>
                
                  <ChartToggle
                  type={'bar'}
                    data={{
                      heading: 'Played From',
                      labels:
                        getObjectLength(plays) > 0
                          ? Object.keys(
                              groupByPlayingFrom([
                                ...plays?.full,
                                ...plays?.skips,
                                ...plays?.partial,
                              ])
                            )
                          : [],
                      datasets: [
                        {
                          label: "Played From",
                          data:
                            getObjectLength(plays) > 0
                              ? Object.values(
                                  groupByPlayingFrom([
                                    ...plays?.full,
                                    ...plays?.skips,
                                    ...plays?.partial,
                                  ])
                                )
                              : [],
                          borderColor:  Object.keys(
                            groupByPlayingFrom([
                              ...plays?.full,
                              ...plays?.skips,
                              ...plays?.partial,
                            ])
                          ).map((k) => {
                            return MUSIC_PLAYING_FROM_STATS_COLORS[k]
                          }),
                          backgroundColor: Object.keys(
                            groupByPlayingFrom([
                              ...plays?.full,
                              ...plays?.skips,
                              ...plays?.partial,
                            ])
                          ).map((k) => {
                            return MUSIC_PLAYING_FROM_STATS_COLORS[k]
                          }),
                        },
                      ],
                    }}
                  />
               
              </>
            )}
            {metricType === 'playlists' && playlists.length > 0 && <>
        <FormWrapper>
        <FormText>Playlists</FormText>
          <PlaylistTable playlists={playlists} heading={'Playlists'}/>
        </FormWrapper>
      </>}
      {metricType === 'likes' && likes?.length > 0 && <FormWrapper>
        <FormText>No. Likes</FormText>
      <ChartToggle data={{
          heading: "No. Likes",
          labels: likes.length > 0 ? Object.keys(groupInfoByDate(likes,'dateCreated', filter)) : [],
          datasets: [{
              label: "Likes",
              data: likes.length > 0 ? Object.values(groupInfoByDate(likes,'dateCreated', filter)).map(l => {
                return l?.length
              }) : [],
              borderColor: themeColors.infoColor,
              backgroundColor: themeColors.infoColor,
          }]
          }}/>
      </FormWrapper>}
            {((metricType === "plays" && plays?.length === 0) ||
              (metricType === "likes" && likes?.length === 0) ||
              (metricType === "playlists" && playlists?.length === 0)) && (
              <>
                <ContentWrapper>
                  <FormWrapper>
                    <FormText>No Data Available</FormText>
                  </FormWrapper>
                </ContentWrapper>
              </>
            )}
          </ContentWrapper>
        </>
      )}
    </>
  );
};

export default EpisodeStats;
