import React from 'react'
import { Form, FormButton, FormButton1, FormContainer, FormContent1, FormH1 } from '../CustomForm/CustomFormElements'
import Modal from '../Modal'
import { useModal } from '../Modal/ModalContext'

const OptionModal = ({heading, additionalText, children, onCancel}) => {
  return (
    <>
        <div style={{color: 'white'}}>
            <Form isOpen={true}>
                <FormH1>
                    {heading}
                </FormH1>
                <FormContent1>
                    <div style={{justifySelf: 'center', textAlign: 'center', whiteSpace: 'wrap'}}>{additionalText}</div>
                    {children}
                </FormContent1>
                {onCancel && <FormButton1 transparent={true} onClick={onCancel}>Cancel</FormButton1>}
            </Form>
        </div>
    </>
  )
}

export default OptionModal