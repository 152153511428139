import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import Navbar from '../../../components/Navbar';
import { Home, Welcome } from '../../../components/Navbar/Data';
import Sidebar from '../../../components/Sidebar';
import {PodcasterProfile as Profile} from '../../../components/PodcasterProfile'


const PodcasterProfile = () => {
    const [podcasterProfile, setPodcaster] = useState()
    const {podcaster} = useParams()
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => {setIsOpen(!isOpen)}
    const navigate  = useNavigate()
    
    useEffect(() => {
        let rProfile = podcaster
        if(!podcaster)
        {
            navigate(-1)
        }
        setPodcaster(podcaster)
        return () => {} 
    }, [podcaster])



    return( 
        <>
             <Sidebar
          isOpen={isOpen}
          toggle={toggle}
          navInfo={localStorage.getItem('accInfo')? Home(localStorage.getItem('accInfo')) : Welcome}
        />
          <Navbar toggle={toggle} navInfo={localStorage.getItem('accInfo')? Home(localStorage.getItem('accInfo')) : Welcome}/>
            <Profile podcaster={podcaster}/>
        </>
        )
    
}

export default PodcasterProfile