import React, { useState } from 'react'
import Checkbox from '../Checkbox'
import { CCForm, CCFormWrapper, CCIconsWrapper, CCInfo, CCInfoIcon, CCLink, CCList, FormContainer } from './CreativeCommonsSelectorElements'
import {Data} from './Data'
import {FaInfo,FaCreativeCommons,FaCreativeCommonsNd, FaCreativeCommonsNc, FaCreativeCommonsBy, FaCreativeCommonsZero, FaCreativeCommonsSa, FaRegCopyright} from "react-icons/fa"
import { Theme } from '../WebsiteThemeElements'
import { Fade } from 'react-reveal'

export default function CreativeCommonsSelector({creativeCommons, setCreativeCommons}) {
    const [ccInfo, setCCInfo] = useState()

    const showInfo = (id) => {
        const cc = ccInfo
        //console.log("id: ", id)
        if(cc !== id){
            setCCInfo(id)
        }else{
            setCCInfo()
        }
    }


  const  displayIcons = (icons, id) => {
        return (
        <CCIconsWrapper onClick={() => showInfo(id)}>
            {icons.map(icon => {
                let i 
            switch(icon){
                case "attribute":
                   i = <><FaCreativeCommons/><FaCreativeCommonsBy/></>
                   break
                case "shareAlike":
                    i = <FaCreativeCommonsSa/>
                    break
                case "nonCommercial":
                    i = <FaCreativeCommonsNc/>
                    break
                case "nonDerivatives":
                    i = <FaCreativeCommonsNd/>
                    break
                case "zero":
                    i = <><FaCreativeCommons/><FaCreativeCommonsZero/></>
                    break
                case "copyright":
                    i = <FaRegCopyright/>
                    break
                default:
                    break
            }
            return i
        })}{id !== "ARR" && <CCInfoIcon />}
        </CCIconsWrapper>)
    }


  return (
    <>
        <FormContainer>
            <CCForm>
                <CCList>
                {Data && Data.map((cc, index) => {
                        if(cc.id === "ARR") {
                        return(<><CCFormWrapper >
                    <Checkbox id={index} label={cc.name} isChecked={cc.id === creativeCommons} onClick={() => setCreativeCommons(cc.id)} />
                            {displayIcons(cc.icons, cc.id)}
                </CCFormWrapper>
                <br/>
                <i>Creative Commons</i>
                </>)
                }else{
                    return(<>
                    <CCFormWrapper>
                        <Checkbox id={index} label={cc.name} isChecked={cc.id === creativeCommons} onClick={() => setCreativeCommons(cc.id)} />
                        {displayIcons(cc.icons, cc.id)}
                    </CCFormWrapper>
                    {
                        cc.id === ccInfo &&
                        <Fade>
                            <CCInfo>{cc.about}<CCLink target="_blank" rel="noopener noreferrer" href={cc.link}>learn more</CCLink></CCInfo>
                        </Fade>
                    }</>)
                }
            })}
                </CCList>
            </CCForm>
        </FormContainer>
    </>
  )
}
