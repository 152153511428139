import React, { useEffect, useState } from "react";
import {
  FaEdit,
  FaExternalLinkAlt,
  FaHeart,
  FaReply,
  FaTrash,
  FaUser,
} from "react-icons/fa";
import { getSongTitle, getTimestamp, timestampToDate } from "../../../utils";
import { getReplies } from "../../../utils/CommentsUtils";
import { HoverOption } from "../../HoverMenu/HoverMenuElements";
import {
  Comment as Com,
  CommentActionButtons,
  CommentButton,
  CommentCheckBox,
  CommentContent,
  CommentContentMetadata,
  CommentContentMetatdataText,
  CommenterBadge,
  CommenterName,
  CommenterThumbnail,
  CommentListWrapper,
  CommentRow,
  CommentRowBody,
  CommentRowContainer,
  CommentSideContent,
  CommentSideContentImg,
  CommentSideContentTitle,
  CommentSideContentWrapper,
  CommentText,
  CommentThread,
  CommentThreadContainer,
  CommentWrapper,
  ReadMore,
  SideContentButton,
} from "../CommentBoothElements";
import { useAuth } from "../../../contexts/AuthContext";
import { CONTENT_TYPES } from "../../../utils/Enum";
import { NestedComments, NestedCommentStack } from "./CommentsListElements";
import CommentList from ".";
import artistBadge from '../../../images/artist_page_owner.png'
import { Theme } from "../../WebsiteThemeElements";
import ReplyForm from "./ReplyForm";
import { useModal } from "../../Modal/ModalContext";
import { VerifiedBadge } from "../../ArtistProfile/ArtistHeader/ArtistHeaderElements";
import { DEFAULT_IMAGE } from "../../GravatarAPI";
import { useUser } from "../../../hooks/useUser";

const Comment = ({ comment, comments, refresh, isHidden }) => {
  const [childComments, getChildComments] = useState(
    getReplies(comments, comment?.commentId)
  );
  const {showComment, hideComment} = useUser({})
  const { openModal, closeModal, modalContent } = useModal();
  const { currentUser, profiles, currentProfile } = useAuth();
  const [isReply, setReply] = useState(false);
  const [showReplies, setShowReplies] = useState(false);

const getProfilePicture = () => {
  if(comment?.user){
    return comment?.user?.photoURL
  }
  if(comment?.artist){
    return comment?.artist?.profilePictureType === 'gravatar' ? comment?.artist?.profilePicture : comment?.artist?.avatar_url
  }
  if(comment?.label){
    return comment?.label?.profilePictureType === 'gravatar' ? comment?.label?.profilePicture : comment?.label?.avatar_url
  }
  if(comment?.podcaster){
    return comment?.podcaster?.profilePictureType === 'gravatar' ? comment?.podcaster?.profilePicture : comment?.podcaster?.avatar_url
  }else{
    return DEFAULT_IMAGE()
  }
}

  function sideContent(comment, type) {
    switch (type) {
      case CONTENT_TYPES.ALBUM:
        return (
          <CommentSideContentWrapper>
            <CommentSideContent to={`/album/${comment?.album?.albumId}`}>
              <CommentSideContentImg src={comment?.album?.albumArt} />
              <CommentSideContentTitle>
                {comment?.album?.albumName}
              </CommentSideContentTitle>
            </CommentSideContent>
            <SideContentButton
              target="_blank"
              rel="noopener noreferrer"
              to={`https://sonarmuse.org/album/${comment?.album?.albumId}`}
            >
              <FaExternalLinkAlt />
            </SideContentButton>
          </CommentSideContentWrapper>
        );
        break;
      case CONTENT_TYPES.SONG:
        return (
          <CommentSideContentWrapper>
            <CommentSideContent to={`/song/${comment?.song?.songId}`}>
              <CommentSideContentImg src={comment?.song?.albumArt} />
              <CommentSideContentTitle>
                {getSongTitle(comment?.song)}
              </CommentSideContentTitle>
            </CommentSideContent>
            <SideContentButton
              target="_blank"
              rel="noopener noreferrer"
              to={`https://sonarmuse.org/song/${comment?.song?.songId}`}
            >
              <FaExternalLinkAlt />
            </SideContentButton>
          </CommentSideContentWrapper>
        );
        break;
      case CONTENT_TYPES.COLLECTION:
        return (
          <CommentSideContentWrapper>
            <CommentSideContent
              to={`/collection/${comment?.collection?.collectionId}`}
            >
              <CommentSideContentImg src={comment?.collection?.cover?.url} />
              <CommentSideContentTitle>
                {comment?.collection?.collectionName}
              </CommentSideContentTitle>
            </CommentSideContent>
            <SideContentButton
              target="_blank"
              rel="noopener noreferrer"
              to={`https://sonarmuse.org/collection/${comment?.collection?.collectionId}`}
            >
              <FaExternalLinkAlt />
            </SideContentButton>
          </CommentSideContentWrapper>
        );
        break;

      default:
        break;
    }
  }

  return (
    <>
      <CommentThreadContainer>
        <CommentThread>
          <Com>
            <CommentRowContainer>
              <CommentRow>
                <CommentRowBody>
                  <CommentCheckBox />
                  <CommenterThumbnail src={getProfilePicture()} />
                  <CommentContent>
                    <CommentContentMetadata>
                      <CommenterName>
                        {comment?.artistId
                          ? comment?.artist?.artistName
                          : comment?.labelId
                          ? comment?.label?.labelName
                          : comment?.podcasterId
                          ? comment?.podcaster?.podcasterName
                          : comment?.user?.gravatarInfo
                          ? comment?.user?.gravatarInfo?.display_name
                          : comment?.uid}
                      </CommenterName>
                      <CommenterBadge>{comment?.user ? <FaUser/> : <VerifiedBadge style={{
                        width: '1rem',
                        height: '1rem',
                      }} src={artistBadge} onContextMenu={(e) => {e.preventDefault()}}/>}</CommenterBadge> •
                      <CommentContentMetatdataText>
                        {getTimestamp(new Date(comment?.createdAt))}
                      </CommentContentMetatdataText>
                    </CommentContentMetadata>
                    <CommentWrapper>
                      <CommentText>{comment?.message}</CommentText>
                      <ReadMore />
                    </CommentWrapper>
                    <CommentActionButtons>
                      {!comment?.artistId && (
                        <>
                          {" "}
                          <CommentButton
                            onClick={() => {
                              setReply(true);
                            }}
                          >
                            Reply
                          </CommentButton>
                          {/* <CommentButton>
                            <FaHeart /> Like
                          </CommentButton> */}
                        </>
                      )}
                      {(comment[comment?.type]?.uploadedBy ===
                        currentUser?.uid ||
                        comment[comment?.type]?.mintInfo?.issuer ===
                          currentUser?.uid) && (
                        <CommentButton
                          style={{ backgroundColor: Theme({}).errorColor }}
                          onClick={async() => {
                            return await hideComment(comment?.commentId).then((hide) => {
                              if(hide){
                                refresh()
                              }
                            })
                          }}
                        >
                          Remove
                        </CommentButton>
                      )}
                    </CommentActionButtons>
                  </CommentContent>
                </CommentRowBody>
              </CommentRow>
              {!comment?.parentId && sideContent(comment, comment?.type)}
            </CommentRowContainer>
            <br />
            {isReply && (
              <ReplyForm
                type={comment?.type}
                id={comment[`${comment?.type}Id`]}
                currentProfile={currentProfile}
                profileId={profiles[currentProfile][`${currentProfile}Id`]}
                commentId={comment?.commentId}
                setOpen={setReply}
                refresh={refresh}
              />
            )}
          </Com>
          {childComments?.length > 0 && (
            <>
              <NestedCommentStack>
                <NestedComments>
                  <CommentList comments={childComments.filter((c, index) => (childComments?.length > 5 && !showReplies) ? index < 5 : c )} />
                </NestedComments>
              </NestedCommentStack>
            </>
          )}
          {childComments?.length > 5 && !showReplies && (
            <HoverOption onClick={() => setShowReplies(true)}>
              Show Replies
            </HoverOption>
          )}
        </CommentThread>
      </CommentThreadContainer>
    </>
  );
};

export default Comment;
