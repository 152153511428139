import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { MdExplicit } from "react-icons/md";
import { getArtistsNames, getArtistsNames2, getPodcastersNames, getPodcastersNames2, getSongTitle } from "../../utils";
import { ARTIST_SEARCH_TYPES, CONTENT_TYPES, PODCASTER_SEARCH_TYPES } from "../../utils/Enum";
import ImageUtils from "../../utils/ImageUtils";
import { getRandomColor } from "../HexToRGBA";
import MenuOptions from "../MenuOptions";
import { useModal } from "../Modal/ModalContext";
import {
  CardBody,
  CardWrapper,
  CardButton,
  CardContent,
  CardTitle,
  VerifiedBadge,
  CardLink,
  CardText,
  CardContainer,
} from "./CardElements";
import { useAuth } from "../../contexts/AuthContext";
import ScrollToTop from "../ScrollToTop";
import { SkeletonMusicCard } from "../Skeleton/SkeletonElements";
import PodcastCard from "./PodcastCard";
import PodcasterCard from "./PodcasterCard";
import RecordLabelCard from "./RecordLabelCard";
import DraftUploadCard from "./DraftUploadCard";
import { RiVideoFill } from "react-icons/ri";
import GenreCard from "./GenreCard";

function Card({ type, content, onClick, cardRef }) {
  const { currentUser } = useAuth();
  const {goTo} = ScrollToTop()
  const {options} = MenuOptions()
  const { defaultAlbumArt, DEFAULT_IMAGE } = ImageUtils();
  const navigate  = useNavigate();
  const { toggleModal, modalContent, isOpen } = useModal();
  const { verifiedBadge } = ImageUtils();
  const [color, setColor] = useState(content?.color ? content?.color : getRandomColor() )


  const displayCardContent = () => {
    switch (type) {
      case CONTENT_TYPES.DRAFT:
        return (
         <DraftUploadCard cardRef={cardRef} content={content}/>
        );
      case CONTENT_TYPES.GENRE:
        return (
          <GenreCard content={content} cardRef={cardRef}/>
        );
      case CONTENT_TYPES.ARTIST:
        return (
          <SkeletonMusicCard
            type={CONTENT_TYPES.ARTIST}
            id={content?.artistId}
            img={
              content?.artistSettings?.profilePictureType === "custom" && content?.profilePicture ?
              content?.profilePicture :
              content?.photoUrl
                ? content.photoUrl + "?s=600&d=mp"
                : DEFAULT_IMAGE(600)
            }
            onClick={() =>
              content?.artistId
                ? goTo(`/artist/${content?.artistId}`)
                : {}
            }
          >
            <CardContent>
              <CardTitle type={CONTENT_TYPES.ARTIST}>
                {content?.artistName}{" "}
                {content?.isVerified && <VerifiedBadge badge={verifiedBadge} />}
              </CardTitle>
            </CardContent>
          </SkeletonMusicCard>
        );

      case CONTENT_TYPES.SONG:
        return (
          <SkeletonMusicCard 
            id={content?.songId}
            onContextMenu={(e) => {
              e.preventDefault();
              if(content?.songId && currentUser?.uid){
              toggleModal();
              options(content)};
            }}
           
            img={content?.albumArt ? content?.albumArt : defaultAlbumArt}
          >
            <CardContent>
              <CardTitle  onClick={() => content?.songId ?
              goTo(`/song/${content?.songId}`) :
              goTo('#')
            }>
                {getSongTitle(content)} 
              </CardTitle>
              <CardBody>
              <CardText onClick={() => content?.artists[0]?.artistId ? goTo(`/artist/${content?.artists[0].artistId}`): goTo('#') }>
                 {content?.explicit && <MdExplicit />}{content?.video?.url && <RiVideoFill />} {getArtistsNames2(content?.artists, ARTIST_SEARCH_TYPES.NAME)
                    ?.length > 20
                    ? `${getArtistsNames2(
                        content?.artists,
                        ARTIST_SEARCH_TYPES.NAME
                      ).substring(0, 20)}...`
                    : getArtistsNames(
                        content?.artists,
                        ARTIST_SEARCH_TYPES.NAME
                      )}
                </CardText>
              </CardBody>
            </CardContent>
          </SkeletonMusicCard>
        );
      case CONTENT_TYPES.ALBUM:
        return (
          <SkeletonMusicCard 
            id={content?.albumId}
            onContextMenu={(e) => {
              e.preventDefault();
              if(content?.albumId && currentUser?.uid){
              toggleModal();
              options(content)};
            }}
            onClick={() => {
              goTo(`/album/${content?.albumId}`);
            }}
            img={content?.albumArt ? content?.albumArt : defaultAlbumArt}
          >
            <CardContent>
              <CardTitle>{content?.albumName}</CardTitle>
             <CardText>Album</CardText>
              <CardBody>
                <CardText>
                  {getArtistsNames2(content?.artists, ARTIST_SEARCH_TYPES.NAME)
                    ?.length > 20
                    ? `${getArtistsNames2(
                        content?.artists,
                        ARTIST_SEARCH_TYPES.NAME
                      ).substring(0, 20)}...`
                    : getArtistsNames(
                        content?.artists,
                        ARTIST_SEARCH_TYPES.NAME
                      )}
                </CardText>
              </CardBody>
            </CardContent>
          </SkeletonMusicCard>
        );


      case CONTENT_TYPES.PLAYLIST:
        return (
          <CardContainer 
            id={content?.playlistId}
            color={color}
            onClick={() =>
              content?.playlistId
                ? goTo(`/playlist/${content?.playlistId}`)
                : {}
            }
            img={content?.playlistArt}
          >
            <CardContent id={content?.playlistId}>
              <CardTitle>
                {content?.playlistName}
              </CardTitle>
              <CardBody>
                <CardText>Playlist</CardText>
                <CardLink 
                color={color}
                 onClick={() => (content?.curatorId || content?.curator.curatorId) ? goTo(`/u/${content?.curatorId}`) : goTo("#")}>
                  {content?.curatorId ? content?.curatorId?.substring(0, 15) : content?.curator.curatorId.substring(0, 15)}...
                </CardLink>
              </CardBody>
            </CardContent>
          </CardContainer>
        );
        
      case CONTENT_TYPES.RECORD_LABEL:
        return (
          <RecordLabelCard content={content}/>
        );
        
      case CONTENT_TYPES.PODCASTER:
        return (
          <PodcasterCard content={content}/>
        );

      case CONTENT_TYPES.COLLECTION:
        return (
          <SkeletonMusicCard 
            id={content?.collectionId}
            img={content?.cover?.url ? content?.cover.url : DEFAULT_IMAGE(200)}
            color={content?.cover?.color}
            onClick={() =>
              content?.collectionId
                ? goTo(`/collection/${content?.collectionId}`)
                : {}
            }
          >
            <CardContent>
              <CardTitle>{content?.name}</CardTitle>
              <CardBody>
                <p>{content?.issuer}</p>
              </CardBody>
            </CardContent>
          </SkeletonMusicCard>
        );

      case CONTENT_TYPES.EPISODE:
        return (
          <PodcastCard content={content}/>
        );

      case CONTENT_TYPES.PODCAST:
        return (
          <SkeletonMusicCard 
            id={content?.episodeId}
            img={content?.thumbnail ? content?.thumbnail : DEFAULT_IMAGE(200)}
            color={content?.color} 
          >
            <CardContent>
              <CardTitle onClick={() =>
              content?.episodeId
                ? goTo(`/episode/${content?.episodeId}`)
                : {}
            }>{`${content?.episodeName}`}</CardTitle>
              <CardText>Podcast Episode</CardText>
              <CardBody>
              {getPodcastersNames2(content?.podcasters, PODCASTER_SEARCH_TYPES.NAME)
                    ?.length > 20
                    ? `${getPodcastersNames2(
                        content?.podcasters,
                        PODCASTER_SEARCH_TYPES.NAME
                      ).substring(0, 20)}...`
                    : getPodcastersNames(
                        content?.podcasters,
                        PODCASTER_SEARCH_TYPES.NAME
                      )}
              </CardBody>
            </CardContent>
          </SkeletonMusicCard>
        );
      default:
        return (
          <SkeletonMusicCard
            img={defaultAlbumArt}
            onContextMenu={(e) => {
              e.preventDefault();
            }}
          ></SkeletonMusicCard>
        );
    }
  };

  return <CardWrapper>{displayCardContent()}</CardWrapper>;
}

export default Card;
