import React from 'react'
import styled from 'styled-components'
import { CURRENCIES} from '../utils/Enum'
import { hexToRgba } from './HexToRGBA'

export const MEDIA_SCREEN = { //Screen sizes for devices
  MOBILE: '480',
  TABLETS: '768',
  LAPTOPS: '1024',
  DESKTOPS: '1025',
  TV: '1500'
}


export const SelectButton = styled.select`
    display: flex;
    margin-left: 2%;
    margin-right: 1%;
    font-size: 1rem;
    color: ${props => props.theme.secondaryBg};
    cursor: pointer;
    border: solid 1px ${props => hexToRgba(props.theme.mainColor, 0.8)};
    border-radius: 5px;
    outline: none;
    background-color: ${props => props.theme.mainBg};

    @media screen and (max-width: ${MEDIA_SCREEN.TABLETS}px) {
        font-size: 0.5rem;;
    }
`


const CurrencySelector = ({setCurrency, currency}) => {

  const  handleSelect = (e) => {
                setCurrency(e.target.value)
    }

  return (
    <SelectButton value={currency}  onChange={(e) => handleSelect(e)}>
      {Object.keys(CURRENCIES).map(c => {
        return(<option value={c}>{c}</option>)
      })}
        

    </SelectButton>
  )
}

export default CurrencySelector