import React from 'react'
import ContentTable from '..';
import ScrollToTop from '../../ScrollToTop';
import { convertDuration } from '../../../utils';



export const EpisodesTable = ({releases, heading}) => {
  const {goTo} = ScrollToTop()
    const columns = [
      {id: 'thumbnail',  minWidth: 50 },
        { id: 'episodeName', label: 'Name', minWidth: 350 },
        { id: 'displayDate', label: 'Release Date', minWidth: 100 },
      ];
  return (
    <><h2>{heading}</h2><ContentTable columns={columns} rows={releases} /></>
  )
}

export const EpisodesReleaseTable = ({releases}) => {
  const {goTo} = ScrollToTop()
    const columns = [
      {id: 'thumbnail',  minWidth: 50 },
        { id: 'episodeName', label: 'Name', minWidth: 350 },
        { id: 'displayDate', label: 'Release Date', minWidth: 100 },
        {
            id: 'duration',
            label: 'Duration',
            minWidth: 170,
            align: 'left',
        },
        {
          id: 'plays',
          label: 'Plays',
          minWidth: 80,
          align: 'left',
        },
      ];
  return (
    <><h2>Releases</h2><ContentTable columns={columns} rows={releases.map(r => {
      return {
        ...r,
        duration: r?.duration > 0 ? convertDuration(r?.duration) : '00:00:00'
      }
    })} onCellClick={(index) => goTo(`/episode/${releases[index]?.episodeId}`)}/></>
  )
}

export function TopEpisodeReleases({releases}){
  const {goTo} = ScrollToTop()
   const columns = [
    {id: 'thumbnail',  minWidth: 50 },
    { id: 'episodeName', label: 'Name', minWidth: 350 },
    { id: 'displayDate', label: 'Release Date', minWidth: 100 },
    {
      id: 'duration',
      label: 'Duration',
      minWidth: 170,
      align: 'left',
  },
    {
        id: 'plays',
        label: 'Plays',
        minWidth: 80,
        align: 'left',
    }
  ];
    return (
        <><h2>Top Episodes</h2><ContentTable columns={columns} rows={releases} onCellClick={(index) => goTo(`/episode/${releases[index]?.episodeId}`)}/></>
      )
}