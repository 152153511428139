import React from 'react'
import { Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,  
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { colorRandomizer, getRandomColor } from '../HexToRGBA';

ChartJS.register(  
    Title,
    Tooltip,
    Legend,
    ArcElement
  )
const PieGraph = ({title, data}) => {
    const options = {
        responsive: true,
        plugins: {
            legend:{
                position: "bottom"
            },
            title: {
                display: title && true,
                text: title
            }
        }
    }
  return (
    <Pie data={{...data, hoverOffset: 4}}/>
  )
}

export default PieGraph