import React, { useEffect, useRef, useState } from "react";
import { FaRecordVinyl } from "react-icons/fa";
import useArtist from "../../../hooks/useArtist";
import {
  ALERT_TYPES,
  ARTIST_SEARCH_TYPES,
  CONTENT_TYPES,
  MUSIC_PURCHASE_OPTIONS,
  SLIDER_TYPES,
} from "../../../utils/Enum";
import ImageUtils from "../../../utils/ImageUtils";
import {
  convertAlbumsToSonarMuseSchema,
  convertSongsToSonarMuseSchema,
  getAddressName,
  getNFTImage,
} from "../../../utils/nfTokensUploads";
import Alert from "../../Alert";
import { useAlert } from "../../Alert/AlertContext";
import {
  FormInput,
  FormText,
  FormWrapper,
} from "../../CustomForm/CustomFormElements";
import { useModal } from "../../Modal/ModalContext";
import OptionModal from "../../OptionModal";
import ScrollToTop from "../../ScrollToTop";
import { SkeletonNFTokenCardImg, SkeletonPurchaseCardImg } from "../../Skeleton/SkeletonElements";
import { Theme } from "../../WebsiteThemeElements";
import { PlayButton } from "../DigitalPurchaseCard/DigitalPurchaseCardElements";
import {
  BuyButton,
  CardContainer,
  CardContent,
  CardImg,
  CardTitle,
  CreatorImg,
  CreatorInfo,
  CreatorInfoContent,
  CreatorInfoWrapper,
  H6,
  InfoContentText,
  PlayContainer,
  TextLink,
} from "./NFTokenCardElements";
import { SoldOutText } from "../CardElements";
import { accAddressInfo } from "../../../utils/xrpl";
import { getUserProfile } from "../../GravatarAPI";
import { getObjectLength } from "../../../utils";
import { useUser } from "../../../hooks/useUser";
import LoadingScreen, { ModalLoadingScreen } from "../../LoadingScreen";
import { Slider } from "../../Slider";
import ContentWrapper from "../../ContentWrapper";
import { useAuth } from "../../../contexts/AuthContext";
import { getCollectionInfo } from "../../../utils/dbUtils/nftokenUtils";
import { PurchaseCardSkeleton } from "../../Skeleton";
import { useWebsiteTheme } from "../../WebsiteTheme/WebsiteThemeContext";

const NFTokenCard = ({ content }) => {
  const { toggleModal, modalContent, isOpen, openModal, closeModal } =
    useModal();
    const {themeColors} = useWebsiteTheme()

  const { defaultAlbumArt, DEFAULT_IMAGE } = ImageUtils();
  const [artistName, setArtistName] = useState(
    content?.nftoken?.issuer ? content?.nftoken?.issuer : ""
  );
  const [artistId, setArtistId] = useState("");
  const [profileImg, setProfileImg] = useState(DEFAULT_IMAGE(600));
  const {goTo, scrollToTop} = ScrollToTop()
  const { addAlert } = useAlert();
  const { getArtistName, searchArtists } = useArtist({});
  const [collection, setCollection] = useState(
    content?.collection ? content?.collection : undefined
  );

  function buy() {
    /* toggleModal();
    modalContent(
      <PurchaseModal
        type={MUSIC_PURCHASE_OPTIONS.NFT_PURCHASE}
        content={collection}
      />
    ); */
  }

  useEffect(() => {
    if (content?.nftoken?.issuer) {
      displayArtistName();
    }
    return () => {};
  }, [content]);

  useEffect(() => {
    if (collection) {
      getCollectionInfo(collection?.collectionId, setCollection);
    }

    return () => {};
  }, [collection?.collectionId]);

  const displayArtistName = async () => {
    return searchArtists(ARTIST_SEARCH_TYPES.ADDRESS, content?.nftoken?.issuer)
      .then(async (artist) => {
        if (artist) {
          setArtistName(artist?.artistName);
          setProfileImg(
            artist?.artistSettings?.profilePictureType === "custom" &&
              artist?.profilePicture
              ? artist?.profilePicture
              : artist?.photoUrl
              ? artist.photoUrl + "?s=600&d=mp"
              : DEFAULT_IMAGE(600)
          );
          setArtistId(artist?.artistId);
          return;
        } else {
          let issuerName = await getAddressName(content?.nftoken?.issuer);
          if (getObjectLength(issuerName) > 0) {
            setArtistName(issuerName?.name);
            setProfileImg(issuerName?.profileUrl + "?s=600&d=mp");
            return;
          }
          setArtistName(content?.nftoken?.issuer);
          return;
        }
      })
      .catch((err) => {
        setArtistName(content?.nftoken?.issuer);
        //console.error(err)
      });
  };

  return (
    <>
      <CardContainer background={content?.nftoken?.metadata?.color}>
        <div style={{ alignItems: "center", margin: "auto" }}>
          {" "}
          <SkeletonPurchaseCardImg
            to={
              content?.nftoken?.nft_id
                ? `/nftoken/${content?.nftoken?.nft_id}`
                : "#"
            }
            onClick={() => (content?.nftoken?.nft_id ? closeModal() : {})}
            src={
              content?.nftoken?.metadata?.image
                ? getNFTImage(content?.nftoken?.metadata?.image)
                : defaultAlbumArt
            }
          />
        </div>
        <CardContent>
          {content?.collection?.tokenSupply &&
            content?.collection?.tokenSupply -
              content?.collection?.amountSold <=
              10 &&
            content?.collection?.tokenSupply - content?.collection?.amountSold >
              0 && (
              <SoldOutText
                style={{
                  color: content?.nftoken?.metadata?.color
                    ? content?.nftoken?.metadata?.color
                    : themeColors.warningColor,
                }}
              >
                LIMITED SUPPLY LEFT
              </SoldOutText>
            )}
          {content?.collection?.tokenSupply &&
            content?.collection?.amountSold ===
              content?.collection?.tokenSupply && (
              <SoldOutText>SOLD OUT</SoldOutText>
            )}

          <CardTitle>
            <TextLink
              alt={true}
              onClick={() => (content?.nftoken?.nft_id ? closeModal() : {})}
              to={
                content?.nftoken?.nft_id
                  ? `/nftoken/${content?.nftoken?.nft_id}`
                  : "#"
              }
            >{`${
              content?.nftoken?.metadata
                ? content?.nftoken?.metadata?.name
                  ? content?.nftoken?.metadata?.name
                  : content?.nftoken?.metadata?.collection
                  ? content?.nftoken?.metadata?.collection?.name
                  : `${content?.nftoken?.nft_id?.substring(
                      0,
                      5
                    )}...${content?.nftoken?.nft_id?.substring(
                      content?.nftoken?.nft_id?.length - 5,
                      content?.nftoken?.nft_id?.length - 1
                    )}`
                : `${content?.nftoken?.nft_id?.substring(
                    0,
                    5
                  )}...${content?.nftoken?.nft_id?.substring(
                    content?.nftoken?.nft_id?.length - 5,
                    content?.nftoken?.nft_id?.length - 1
                  )}`
            }`}</TextLink>
          </CardTitle>
          <CreatorInfoWrapper>
            <CreatorImg src={profileImg} />
            <CreatorInfo>
              <CreatorInfoContent>
                <H6>Created By</H6>
                <InfoContentText
                  onClick={() =>
                    artistId.trim()?.length > 0 || content?.nftoken?.issuer
                      ? closeModal()
                      : {}
                  }
                >
                  <TextLink
                    to={
                      artistId.trim()?.length > 0
                        ? `/artist/${artistId}`
                        : content?.nftoken?.issuer
                        ? `/u/${content?.nftoken?.issuer}`
                        : "#"
                    }
                  >
                     { content?.nftoken?.issuer !== artistName
                      ? artistName
                      :`${content?.nftoken?.issuer?.substring(0, 8)}...`}
                  </TextLink>
                </InfoContentText>
              </CreatorInfoContent>
              <CreatorInfoContent>
                <H6>Price</H6>
                <InfoContentText>
                  {content?.collection
                    ? parseFloat(content?.collection?.price) > 0
                      ? `${content?.collection?.price} ${
                          content?.collection?.currency
                            ? content?.collection?.currency
                            : "XRP"
                        }`
                      : "Pick Your Own Price"
                    : "Not Sold Here"}
                </InfoContentText>
              </CreatorInfoContent>
            </CreatorInfo>
          </CreatorInfoWrapper>
          <CreatorInfoWrapper>
            {content?.collection && (
              <BuyButton
                onClick={() => {
                   if (
                    content?.collection?.amountSold ===
                    content?.collection?.tokenSupply
                  ) {
                    addAlert({
                      title: "NFToken Purchase",
                      type: ALERT_TYPES.DANGER,
                      message: "NFToken Sold Out",
                    });
                    return
                  }
                  
                  if (
                    content?.collection?.collectionId &&
                    ((content?.collection?.tokenSupply &&
                      content?.collection?.amountSold <
                        content?.collection?.tokenSupply) ||
                      !content?.collection?.tokenSupply)
                  ) {
                    buy();
                    return
                  }
                 
                }}
              >
                <FaRecordVinyl />
                {content?.collection?.collectionId &&
                ((content?.collection?.tokenSupply &&
                  content?.collection?.amountSold <
                    content?.collection?.tokenSupply) ||
                  !content?.collection?.tokenSupply)
                  ? "Buy NFT From Collection"
                  : "SOLD OUT"}
              </BuyButton>
            )}
            {content?.collection && (
              <CreatorInfoContent>
                <InfoContentText>
                  <TextLink
                    onClick={() => closeModal()}
                    to={ 
                      content?.collection?.collectionId ? 
                        `/collection/${content?.collectionId}`
                         : "#"
                    }
                  >
                    View Collection
                  </TextLink>
                </InfoContentText>
              </CreatorInfoContent>
            )}
          </CreatorInfoWrapper>
        </CardContent>
      </CardContainer>
    </>
  );
};

export const GroupNFTokenCollectionCard = ({ content }) => {
  const { currentUser } = useAuth();
  const { toggleModal, modalContent, isOpen, openModal, closeModal } =
    useModal();
    const {themeColors} = useWebsiteTheme()

  const { defaultAlbumArt, DEFAULT_IMAGE } = ImageUtils();
  const [artistName, setArtistName] = useState(content?.nftoken?.issuer);
  const [artistId, setArtistId] = useState("");
  const [profileImg, setProfileImg] = useState(DEFAULT_IMAGE(600));
  const { goTo } = ScrollToTop();
  const { addAlert } = useAlert();
  const { getArtistName, searchArtists } = useArtist({});
  const [collection, setCollection] = useState(
    content?.collection ? content?.collection : undefined
  );

  function buy() {
   /*  toggleModal();
    modalContent(
      <PurchaseModal
        type={MUSIC_PURCHASE_OPTIONS.NFT_PURCHASE}
        content={content?.collection}
      />
    ); */
  }

  useEffect(() => {
    displayArtistName();

    return () => {};
  }, []);

  useEffect(() => {
    if (collection) {
      getCollectionInfo(collection?.collectionId, setCollection);
    }

    return () => {};
  }, [collection?.collectionId]);

  const displayArtistName = async () => {
    return await searchArtists(
      ARTIST_SEARCH_TYPES.ADDRESS,
      content?.nftoken?.issuer
    )
      .then(async (artist) => {
        if (artist) {
          setArtistName(artist?.artistName);
          setProfileImg(
            artist?.artistSettings?.profilePictureType === "custom" &&
              artist?.profilePicture
              ? artist?.profilePicture
              : artist?.photoUrl
              ? artist.photoUrl + "?s=600&d=mp"
              : DEFAULT_IMAGE(600)
          );
          setArtistId(artist?.artistId);
          return;
        } else {
          return await getAddressName(content?.nftoken?.issuer).then(
            (issuerName) => {
              if (issuerName?.name) {
                setArtistName(issuerName?.name);
                setProfileImg(issuerName?.profileUrl + "?s=600&d=mp");
                return;
              }
            }
          );
        }
      })
      .catch(async (err) => {
        return await getAddressName(content?.nftoken?.issuer).then(
          (issuerName) => {
            if (issuerName?.name) {
              setArtistName(issuerName?.name);
              setProfileImg(issuerName?.profileUrl + "?s=600&d=mp");
              return;
            }
          }
        );
      });
  };

  return (
    <>
      <CardContainer background={content?.nftoken?.metadata?.color}>
        <div style={{ alignItems: "center", margin: "auto" }}>
          {" "}
          <SkeletonNFTokenCardImg
            onClick={() => {
              if (content?.tokens?.length > 1) {
                openModal();
                return modalContent(
                  <OptionModal heading={`${
                    content?.nftoken?.metadata?.collection?.name
                      ? content?.nftoken?.metadata?.collection?.name
                      : `${content?.nftoken?.issuer} | Taxon: ${content?.nftoken?.Taxon}`} (${content?.tokens?.length})`
                  }>
                  <NFTokenModal
                    collection={content?.tokens}
                  />
                </OptionModal>);
              } else {
                if (
                  content?.collection ||
                  (content?.nftoken?.metadata?.mintType &&
                    content?.nftoken?.Owner === currentUser?.uid)
                ) {
                  /* openModal()
              return modalContent(<OpenMusicStorePlayer content={content?.collection
                ? collection
                : {
                    ...content?.nftoken?.metadata,
                    albums:
                      content?.nftoken?.metadata?.albums?.length > 0
                        ? convertAlbumsToSonarMuseSchema(
                            content?.nftoken?.metadata?.albums, content?.nftoken?.metadata?.image
                          )
                        : [],
                    songs:
                      content?.nftoken?.metadata?.songs?.length > 0
                        ? convertSongsToSonarMuseSchema(
                            content?.nftoken?.metadata?.songs, content?.nftoken?.metadata?.image
                          )
                        : [],
                  }} type={content?.collection
                    ? CONTENT_TYPES.COLLECTION
                    : CONTENT_TYPES.NFTOKEN}/>)
                  return; */
                } else {
                  if (content?.nftoken?.NFTokenID) {
                    goTo(`/nftoken/${content?.nftoken?.NFTokenID}`);
                    return;
                  }
                }
              }
            }}
            src={
              content?.tokens?.length > 1
                ? content?.nftoken?.metadata?.collection?.image
                  ? getNFTImage(content?.nftoken?.metadata?.collection?.image)
                  : content?.nftoken?.metadata?.image
                  ? getNFTImage(content?.nftoken?.metadata?.image)
                  : defaultAlbumArt
                : content?.nftoken?.metadata?.image
                ? getNFTImage(content?.nftoken?.metadata?.image)
                : defaultAlbumArt
            }
          >
          {(content?.collection ||
            (content?.nftoken?.metadata?.mintType && (content?.tokens?.length === 1 || !content?.tokens) &&
              content?.nftoken?.Owner === currentUser?.uid)) && (
            <PlayContainer><PlayButton style={{width: "6rem", height: "6rem"}}
              
            /></PlayContainer>
          )}</SkeletonNFTokenCardImg>
        </div>
        <CardContent>
          {collection?.tokenSupply &&
            collection?.tokenSupply - collection?.amountSold <= 10 &&
            collection?.tokenSupply - collection?.amountSold > 0 && (
              <SoldOutText
                style={{
                  color: content?.nftoken?.metadata?.color
                    ? content?.nftoken?.metadata?.color
                    : themeColors.warningColor,
                }}
              >
                LIMITED SUPPLY LEFT
              </SoldOutText>
            )}
          {collection?.tokenSupply &&
            collection?.amountSold === collection?.tokenSupply && (
              <SoldOutText>SOLD OUT</SoldOutText>
            )}

          <CardTitle>
            <TextLink alt
              style={{ color: themeColors.secondaryColor }}
              to={
                content?.tokens?.length > 1
                  ? "#"
                  : `/nftoken/${content?.nftoken?.NFTokenID}`
              }
              onClick={() => {
                if (content?.tokens?.length > 1) {
                  openModal();
                  return modalContent(
                    <OptionModal heading={`${
                      content?.nftoken?.metadata?.collection?.name
                        ? content?.nftoken?.metadata?.collection?.name
                        : `${content?.nftoken?.issuer} | Taxon: ${content?.nftoken?.Taxon}`} (${content?.tokens?.length})`
                    }>
                    <NFTokenModal
                      collection={content?.tokens}
                    />
                  </OptionModal>
                  );
                }

              }}
            >{`${
              content?.tokens?.length > 1
                ? content?.nftoken?.metadata?.collection?.name
                  ? content?.nftoken?.metadata?.collection?.name
                  : content?.nftoken?.metadata?.name
                  ? content?.nftoken?.metadata?.name
                  : `${content?.nftoken?.NFTokenID?.substring(
                      0,
                      5
                    )}...${content?.nftoken?.NFTokenID?.substring(
                      content?.nftoken?.NFTokenID?.length - 5,
                      content?.nftoken?.NFTokenID?.length - 1
                    )}`
                : content?.nftoken?.metadata?.name
                ? content?.nftoken?.metadata?.name
                : `${content?.nftoken?.NFTokenID?.substring(
                    0,
                    5
                  )}...${content?.nftoken?.NFTokenID?.substring(
                    content?.nftoken?.NFTokenID?.length - 5,
                    content?.nftoken?.NFTokenID?.length - 1
                  )}`
            }`}</TextLink>
          </CardTitle>
          <CreatorInfoWrapper>
            <CreatorImg src={profileImg} />
            <CreatorInfo>
              <CreatorInfoContent>
                <H6>Created By</H6>
                <InfoContentText
                  onClick={() =>
                    artistId.trim()?.length > 0 || content?.nftoken?.issuer
                      ? closeModal()
                      : {}
                  }
                >
                  <TextLink
                    to={
                      artistId.trim()?.length > 0
                        ? `/artist/${artistId}`
                        : content?.nftoken?.issuer
                        ? `/u/${content?.nftoken?.issuer}`
                        : "#"
                    }
                  >
                    {content?.nftoken?.issuer !== artistName
                      ? artistName
                      :`${content?.nftoken?.issuer?.substring(0, 8)}...`}
                  </TextLink>
                </InfoContentText>
              </CreatorInfoContent>
              <CreatorInfoContent>
                {collection && collection?.price && (
                <>
                <H6>Price</H6>
                <InfoContentText>
                { (collection?.price &&parseFloat(collection?.price)) > 0 ? `${collection?.price} ${collection?.currency ? collection?.currency : 'XRP' }` : "FREE (PYP)"}
                </InfoContentText>
              </>
                )}
                {!collection && <H6>Not Sold Here</H6>}
              </CreatorInfoContent>
            </CreatorInfo>
          </CreatorInfoWrapper>
          <CreatorInfoWrapper style={{ justifyContent: "center" }}>
            {content?.tokens?.length > 1 && (
              <InfoContentText>
                <TextLink
                to={'#'}
                  onClick={() => {
                    openModal();
                    return modalContent(
                      <NFTokenModal
                        groupName={
                          content?.nftoken?.metadata?.collection?.name
                            ? content?.nftoken?.metadata?.collection?.name
                            : `${content?.issuer} | Taxon: ${content?.Taxon}`
                        }
                        collection={content?.tokens}
                      />
                    );
                     /*content?.collection?.collectionId
                      ? goTo(`/collection/${content?.collectionId}`)
                    : goTo("#")*/
                  }}
                >
                  View All NFTokens ({content?.tokens?.length})
                </TextLink>
              </InfoContentText>
            )}
            {content?.tokens?.length === 1 && (
              <InfoContentText>
                <TextLink
                 to={
                    content?.nftoken?.NFTokenID
                      ? `/nftoken/${content?.nftoken?.NFTokenID}`
                      : "#"
                  }
                >
                  View Token
                </TextLink>
              </InfoContentText>
            )}
          </CreatorInfoWrapper>
          <CreatorInfoWrapper>
            {collection && (
              <BuyButton
                onClick={() => {
                  if (
                    collection?.collectionId &&
                    ((collection?.tokenSupply &&
                      collection?.amountSold < collection?.tokenSupply) ||
                      !collection?.tokenSupply)
                  ) {
                    buy();
                  }
                  if (collection?.amountSold === collection?.tokenSupply) {
                    addAlert({
                      title: "NFToken Purchase",
                      type: ALERT_TYPES.DANGER,
                      message: "NFToken Sold Out",
                    });
                  }
                }}
              >
                <FaRecordVinyl />
                {collection?.collectionId &&
                ((collection?.tokenSupply &&
                  collection?.amountSold < collection?.tokenSupply) ||
                  !collection?.tokenSupply)
                  ? "Buy NFT From Collection"
                  : "SOLD OUT"}
              </BuyButton>
            )}

            {collection && (
              <InfoContentText>
                <TextLink
                  to ={
                    collection?.collectionId
                      ? `/collection/${collection?.collectionId}`
                      : "#"
                  }
                >
                  View Collection
                </TextLink>
              </InfoContentText>
            )}
          </CreatorInfoWrapper>
        </CardContent>
      </CardContainer>
    </>
  );
}; 

export const NFTokenModal = ({ collection }) => {
  const [isLoaded, setLoaded] = useState(false);
  const [NFTokens, setNFTokens] = useState([]);
  const { getNFTokenDetails } = useUser({});

  useEffect(() => {
    getNFTokens();
  }, []);

  const getNFTokens = async () => {
    let tokens = [];
    for (let i = 0; i < collection.length; i++) {
      await getNFTokenDetails(collection[i]?.NFTokenID)
        .then((data) => {
          //console.log('tokenInfo: ',data)
          if (data && !data.error) {
            tokens.push(data);
          } else {
            tokens.push(collection[i]);
          }
        })
        .catch((err) => {
          console.error(err);
          tokens.push(collection[i]);
        });
    }
    setNFTokens(tokens);
    setLoaded(true);
  };

  return (
    <>
      {isLoaded && NFTokens?.length > 0 && (
            <Slider
              heightScroll={NFTokens?.length > 3 && true}
              type={SLIDER_TYPES.NFTOKEN}
              slides={NFTokens}
              id={"NFTokens"}
            />
      )}
      {!isLoaded && NFTokens.length === 0 && (
        <ModalLoadingScreen transparent={true} text={"Loading NFTokens. Please wait"}/>
      )}
    </>
  );
};

/*
export const GroupNFTokenCollectionCard = ({ content }) => {
  const { currentUser } = useAuth();
  const { toggleModal, modalContent, isOpen, openModal, closeModal } =
    useModal();
  const { defaultAlbumArt, DEFAULT_IMAGE } = ImageUtils();
  const [tokenInfo, setTokenInfo] = useState()
  const [isLoaded, setLoaded] = useState(false)
  const { getNFTokenDetails } = useUser({});
  const [artistName, setArtistName] = useState(content?.issuer);
  const [artistId, setArtistId] = useState("");
  const [profileImg, setProfileImg] = useState(DEFAULT_IMAGE(600));
  const { goTo } = ScrollToTop();
  const { openMusicStorePlayer } = MenuOptions();
  const { addAlert } = useAlert();
  const { getArtistName, searchArtists } = useArtist({});
  const [collection, setCollection] = useState();

  function buy() {
    toggleModal();
    modalContent(
      <PurchaseModal
        type={MUSIC_PURCHASE_OPTIONS.NFT_PURCHASE}
        content={tokenInfo?.collection}
      />
    );
  }

  useEffect(() => {
    getNFTokenDetails(content.tokens[0]?.NFTokenID).then(info => {
      setTokenInfo({
        collection: info?.collection,
        nftoken: info?.nftoken,
        tokens: content?.tokens
      })
      if(info?.collection){
        setCollection(info?.collection)
      }
      displayArtistName();
      setLoaded(true)
    })
    return () => {};
  }, [content]);

  useEffect(() => {
    if (collection) {
      getCollectionInfo(collection?.collectionId, setCollection);
    }

    return () => {};
  }, [collection]);

  const displayArtistName = async () => {
    return await searchArtists(
      ARTIST_SEARCH_TYPES.ADDRESS,
      content?.issuer
    )
      .then(async (artist) => {
        if (artist) {
          setArtistName(artist?.artistName);
          setProfileImg(
            artist?.artistSettings?.profilePictureType === "custom" &&
              artist?.profilePicture
              ? artist?.profilePicture
              : artist?.photoUrl
              ? artist.photoUrl + "?s=600&d=mp"
              : DEFAULT_IMAGE(600)
          );
          setArtistId(artist?.artistId);
          return;
        } else {
          return await getAddressName(content?.issuer).then(
            (issuerName) => {
              if (issuerName?.name) {
                setArtistName(issuerName?.name);
                setProfileImg(issuerName?.profileUrl + "?s=600&d=mp");
                return;
              }
            }
          );
        }
      })
      .catch(async (err) => {
        return await getAddressName(content?.issuer).then(
          (issuerName) => {
            if (issuerName?.name) {
              setArtistName(issuerName?.name);
              setProfileImg(issuerName?.profileUrl + "?s=600&d=mp");
              return;
            }
          }
        );
      });
  };

  return (
    <>
    {!isLoaded && <PurchaseCardSkeleton />}
      {isLoaded && <CardContainer background={tokenInfo?.nftoken?.metadata?.color}>
        <div style={{ alignItems: "center", margin: "auto" }}>
          {" "}
          <SkeletonPurchaseCardImg
            onClick={() => {
              if (tokenInfo?.tokens?.length > 1) {
                openModal();
                return modalContent(
                  <NFTokenModal
                    groupName={
                      tokenInfo?.nftoken?.metadata?.collection?.name
                        ? tokenInfo?.nftoken?.metadata?.collection?.name
                        : `${tokenInfo?.nftoken?.issuer} | Taxon: ${tokenInfo?.nftoken?.Taxon}`
                    }
                    collection={tokenInfo?.tokens}
                  />
                );
              } else {
                if (
                  tokenInfo?.collection ||
                  (tokenInfo?.nftoken?.metadata?.mintType &&
                    tokenInfo?.nftoken?.owner === currentUser?.uid)
                ) {
                  openMusicStorePlayer(
                    tokenInfo?.collection
                      ? collection
                      : {
                          ...tokenInfo?.nftoken?.metadata,
                          albums:
                            tokenInfo?.nftoken?.metadata?.albums?.length > 0
                              ? convertAlbumsToSonarMuseSchema(
                                  tokenInfo?.nftoken?.metadata?.albums, tokenInfo?.nftoken?.metadata?.image
                                )
                              : [],
                          songs:
                            tokenInfo?.nftoken?.metadata?.songs?.length > 0
                              ? convertSongsToSonarMuseSchema(
                                  tokenInfo?.nftoken?.metadata?.songs, tokenInfo?.nftoken?.metadata?.image
                                )
                              : [],
                        },
                    tokenInfo?.collection
                      ? CONTENT_TYPES.COLLECTION
                      : CONTENT_TYPES.NFTOKEN
                  );
                  return;
                } else {
                  if (tokenInfo?.nftoken?.nft_id) {
                    goTo(`/nftoken/${tokenInfo?.nftoken?.nft_id}`);
                    return;
                  }
                }
              }
            }}
            src={
              tokenInfo?.tokens?.length > 1
                ? tokenInfo?.nftoken?.metadata?.collection?.image
                  ? getNFTImage(tokenInfo?.nftoken?.metadata?.collection?.image)
                  : tokenInfo?.nftoken?.metadata?.image
                  ? getNFTImage(tokenInfo?.nftoken?.metadata?.image)
                  : defaultAlbumArt
                : tokenInfo?.nftoken?.metadata?.image
                ? getNFTImage(tokenInfo?.nftoken?.metadata?.image)
                : defaultAlbumArt
            }
          />
          {(tokenInfo?.collection ||
            (tokenInfo?.nftoken?.metadata?.mintType &&
              tokenInfo?.nftoken?.Owner === currentUser?.uid)) && (
            <PlayButton
              onClick={() => {
                openMusicStorePlayer(
                  tokenInfo?.collection
                    ? collection
                    : {
                        ...tokenInfo?.nftoken?.metadata,
                        albums:
                          tokenInfo?.nftoken?.metadata?.albums?.length > 0
                            ? convertAlbumsToSonarMuseSchema(
                                tokenInfo?.nftoken?.metadata?.albums, tokenInfo?.nftoken?.metadata?.image
                              )
                            : [],
                        songs:
                          tokenInfo?.nftoken?.metadata?.songs?.length > 0
                            ? convertSongsToSonarMuseSchema(
                                tokenInfo?.nftoken?.metadata?.songs, tokenInfo?.nftoken?.metadata?.image
                              )
                            : [],
                      },
                  tokenInfo?.collection
                    ? CONTENT_TYPES.COLLECTION
                    : CONTENT_TYPES.NFTOKEN
                );
              }}
            />
          )}
        </div>
        <CardContent>
          {collection?.tokenSupply &&
            collection?.tokenSupply - collection?.amountSold <= 10 &&
            collection?.tokenSupply - collection?.amountSold > 0 && (
              <SoldOutText
                style={{
                  color: tokenInfo?.nftoken?.metadata?.color
                    ? tokenInfo?.nftoken?.metadata?.color
                    : themeColors.warningColor,
                }}
              >
                LIMITED SUPPLY LEFT
              </SoldOutText>
            )}
          {collection?.tokenSupply &&
            collection?.amountSold === collection?.tokenSupply && (
              <SoldOutText>SOLD OUT</SoldOutText>
            )}

          <CardTitle>
            <TextLink
              style={{ color: themeColors.secondaryColor }}
              to={
                tokenInfo?.tokens?.length > 1
                  ? "#"
                  : `/nftoken/${tokenInfo?.nftoken?.nft_id}`
              }
              onClick={() => {
                if (tokenInfo?.tokens?.length > 1) {
                  openModal();
                  return modalContent(
                    <NFTokenModal
                      groupName={
                        tokenInfo?.nftoken?.metadata?.collection?.name
                          ? tokenInfo?.nftoken?.metadata?.collection?.name
                          : `${tokenInfo?.issuer} | Taxon: ${tokenInfo?.Taxon}`
                      }
                      collection={tokenInfo?.tokens}
                    />
                  );
                }

              }}
            >{`${
              tokenInfo?.tokens?.length > 1
                ? tokenInfo?.nftoken?.metadata?.collection?.name
                  ? tokenInfo?.nftoken?.metadata?.collection?.name
                  : tokenInfo?.nftoken?.metadata?.name
                  ? tokenInfo?.nftoken?.metadata?.name
                  : `${tokenInfo?.nftoken?.nft_id?.substring(
                      0,
                      5
                    )}...${tokenInfo?.nftoken?.nft_id?.substring(
                      tokenInfo?.nftoken?.nft_id?.length - 5,
                      tokenInfo?.nftoken?.nft_id?.length - 1
                    )}`
                : tokenInfo?.nftoken?.metadata?.name
                ? tokenInfo?.nftoken?.metadata?.name
                : `${tokenInfo?.nftoken?.nft_id?.substring(
                    0,
                    5
                  )}...${tokenInfo?.nftoken?.nft_id?.substring(
                    tokenInfo?.nftoken?.nft_id?.length - 5,
                    tokenInfo?.nftoken?.nft_id?.length - 1
                  )}`
            }`}</TextLink>
          </CardTitle>
          <CreatorInfoWrapper>
            <CreatorImg src={profileImg} />
            <CreatorInfo>
              <CreatorInfoContent>
                <H6>Created By</H6>
                <InfoContentText
                  onClick={() =>
                    artistId.trim()?.length > 0 || tokenInfo?.nftoken?.issuer
                      ? closeModal()
                      : {}
                  }
                >
                  <TextLink
                    to={
                      artistId.trim()?.length > 0
                        ? `/artist/${artistId}`
                        : tokenInfo?.nftoken?.issuer
                        ? `/u/${tokenInfo?.nftoken?.issuer}`
                        : "#"
                    }
                  >
                    {tokenInfo?.nftoken?.issuer !== artistName
                      ? artistName
                      :`${tokenInfo?.nftoken?.issuer?.substring(0, 8)}...`}
                  </TextLink>
                </InfoContentText>
              </CreatorInfoContent>
              <CreatorInfoContent>
                {collection && collection?.price && (
                  <>
                    <H6>Price</H6>
                    <InfoContentText>
                      {parseFloat(collection?.price) > 0
                        ? collection?.price
                        : "Pick Your Own Price"}{" "}
                      {collection?.currency ? collection?.currency : "XRP"}
                    </InfoContentText>
                  </>
                )}
                {!collection && <H6>Not Sold Here</H6>}
              </CreatorInfoContent>
            </CreatorInfo>
          </CreatorInfoWrapper>
          <CreatorInfoWrapper style={{ justifyContent: "center" }}>
            {tokenInfo?.tokens?.length > 1 && (
              <InfoContentText>
                <TextLink
                to={'#'}
                  onClick={() => {
                    openModal();
                    return modalContent(
                      <NFTokenModal
                        groupName={
                          tokenInfo?.nftoken?.metadata?.collection?.name
                            ? tokenInfo?.nftoken?.metadata?.collection?.name
                            : `${tokenInfo?.issuer} | Taxon: ${tokenInfo?.Taxon}`
                        }
                        collection={tokenInfo?.tokens}
                      />
                    );
                    /*  tokenInfo?.collection?.collectionId
                      ? goTo(`/collection/${tokenInfo?.collectionId}`)
                      : goTo("#") 
                  }}
                >
                  View All NFTokens ({tokenInfo?.tokens?.length})
                </TextLink>
              </InfoContentText>
            )}
            {tokenInfo?.tokens?.length === 1 && (
              <InfoContentText>
                <TextLink
                 to={
                    tokenInfo?.nftoken?.nft_id
                      ? `/nftoken/${tokenInfo?.nftoken?.nft_id}`
                      : "#"
                  }
                >
                  View Token
                </TextLink>
              </InfoContentText>
            )}
          </CreatorInfoWrapper>
          <CreatorInfoWrapper>
            {collection && (
              <BuyButton
                onClick={() => {
                  if (
                    collection?.collectionId &&
                    ((collection?.tokenSupply &&
                      collection?.amountSold < collection?.tokenSupply) ||
                      !collection?.tokenSupply)
                  ) {
                    buy();
                  }
                  if (collection?.amountSold === collection?.tokenSupply) {
                    addAlert({
                      title: "NFToken Purchase",
                      type: ALERT_TYPES.DANGER,
                      message: "NFToken Sold Out",
                    });
                  }
                }}
              >
                <FaRecordVinyl />
                {collection?.collectionId &&
                ((collection?.tokenSupply &&
                  collection?.amountSold < collection?.tokenSupply) ||
                  !collection?.tokenSupply)
                  ? "Buy NFT From Collection"
                  : "SOLD OUT"}
              </BuyButton>
            )}

            {collection && (
              <InfoContentText>
                <TextLink
                  to ={
                    collection?.collectionId
                      ? `/collection/${collection?.collectionId}`
                      : "#"
                  }
                >
                  View Collection
                </TextLink>
              </InfoContentText>
            )}
          </CreatorInfoWrapper>
        </CardContent>
      </CardContainer>}
    </>
  );
};*/



 

export default NFTokenCard;
