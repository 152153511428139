import React, { useState } from 'react'
import { useEffect } from 'react'
import { ThemeProvider } from 'styled-components'
import { ALERT_TYPES, NOTIFICATION_DISPATCH } from '../../utils/Enum'
import { Theme } from '../WebsiteThemeElements'
import { AlertContainer, AlertMessage, AlertMessageContainer, Closebtn, ExpirationBar, AlertTitle } from './AlertElements'

const Alert = ({message, type, dispatch, id, secs, title}) => {
    const theme = Theme({})
    const [exit, setExit] = useState(false)
    const [width, setWidth] = useState(0)
    const [intervalId, setIntervalId] = useState(null)
    const [color, setColor] = useState(
      type === ALERT_TYPES.SUCCESS
      ? theme.successColor
      : type === ALERT_TYPES.INFO
      ? theme.infoColor
      : type === ALERT_TYPES.WARNING
      ? theme.warningColor
      : type === ALERT_TYPES.DANGER
      ? theme.errorColor
      : theme.mainColor)


  const handleStartTimer = () => {
    const intId = setInterval(() => {
      setWidth(prev => {
        if(prev < 100){
          return prev + 0.5
        }
        clearInterval(intId)
        return prev
      })
    }, secs? secs : 40)

    setIntervalId(intId)
  }


  const handlePauseTimer = () => {
    clearInterval(intervalId)
  }


  const handleCloseAlert = () => {
    setExit(true);
    handlePauseTimer()
    setTimeout(() => {
      //Remove notification from DOM
      dispatch({
        type: NOTIFICATION_DISPATCH.REMOVE_NOTIFICATION,
        id: id
      })
    }, 400)
  }

  //once the component is unmounted
  useEffect(() => {
    if(width === 100){
      handleCloseAlert()
    }
    return () => {}
  }, [width])

  //once the component is mounted
  useEffect(() => {
    handleStartTimer()
    return () => {}
  }, [])
  

  return (
    <AlertContainer exit={exit} onMouseEnter={handlePauseTimer} onMouseLeave={handleStartTimer}>
        {title && <><AlertTitle type={color}>{title}<Closebtn onClick={() => handleCloseAlert()}>X</Closebtn></AlertTitle>
        </>}
        <AlertMessageContainer>
        <AlertMessage>{message}</AlertMessage>
        {!title && <Closebtn onClick={() => handleCloseAlert()}>X</Closebtn>}
        </AlertMessageContainer>
    <ExpirationBar type={color} width={width}/>
    </AlertContainer>
  )
}

export default Alert