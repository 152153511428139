import React, { useEffect, useState } from "react";
import usePodcaster from "../../../../hooks/usePodcaster";
import {
  getGroupedPlays,
  getNumListeners,
  getObjectLength,
  getPlayCount,
  groupInfoByDate,
} from "../../../../utils";
import { MUSIC_PLAYING_FROM } from "../../../../utils/Enum";
import ContentWrapper from "../../../ContentWrapper";
import { ModalLoadingScreen } from "../../../LoadingScreen";
import KeyMetrics from "../../../Charts/KeyMetrics";
import { ChipList } from "../../../Chip/ChipElements";
import Chip from "../../../Chip";
import { FormText, FormWrapper } from "../../../CustomForm/CustomFormElements";
import { PlaylistTable } from "../../../ContentTable/ArtistTables";
import {
  EpisodesReleaseTable,
  EpisodesTable,
} from "../../../ContentTable/PodcasterTables";
import useMusic from "../../../../hooks/useMusic";
import { useWebsiteTheme } from "../../../WebsiteTheme/WebsiteThemeContext";
import ChartToggle from "../../../Charts";

const PlaylistsContent = ({ podcasterId, isLoaded, setLoaded, filter }) => {
  const {themeColors} = useWebsiteTheme()


  const {
    getPodcasterEpisodePlaylistsAdditionbyDate,
    getPodcasterListenersByDate,
  } = usePodcaster({ id: podcasterId });
  const { getEpisodes } = useMusic();
  const [listeners, setListeners] = useState([]);
  const [playlists, setPlaylists] = useState([]);
  const [groupedListens, setGroupedListens] = useState({});
  const [groupedPlaylists, setGroupedPlaylists] = useState({});
  const [metricType, setMetricType] = useState("plays");
  const [playType, setPlayType] = useState("full");
  const [playlistsLoaded, setPlaylistsLoaded] = useState(false);
  const [episodes, setEpisodes] = useState([]);
  const [isEpisodesLoaded, setEpisodesLoaded] = useState(false);

  const getData = async () => {
    setLoaded(false);
    setListeners([]);
    setGroupedListens({});
    setGroupedPlaylists({});
    setPlaylists([]);
    setEpisodes([]);
    await Promise.all([
      getPodcasterListenersByDate(podcasterId, filter),
      getPodcasterEpisodePlaylistsAdditionbyDate(podcasterId, filter, "all"),
    ])
      .then(async (data) => {
        if (data[0]?.listeners?.length > 0) {
          setListeners(
            data[0]?.listeners?.filter(
              (listen) =>
                listen?.playingFrom?.playingFrom === MUSIC_PLAYING_FROM.PLAYLIST
            )
          );
          let l = groupInfoByDate(
            data[0]?.listeners?.filter(
              (listen) =>
                listen?.playingFrom?.playingFrom === MUSIC_PLAYING_FROM.PLAYLIST
            ),
            "timestamp",
            filter
          );
          setGroupedListens(l);
        }
        if (data[1]?.playlists?.length > 0) {
          setPlaylists(data[1]?.playlists);
          let p = groupInfoByDate(data[1]?.playlists, "datePublished", filter);
          setGroupedPlaylists(p);
        }

        return;
      })
      .catch((err) => {
        console.error(err);
        return;
      });
    setLoaded(true);
  };

  useEffect(() => {
    getData();

    return () => {};
  }, [podcasterId, filter]);

  useEffect(() => {
    if (isLoaded) {
      setEpisodesLoaded(false);
      let s = getEpisodesByPlayType();
      if (s?.length > 0) {
        getEpisodes(s)
          .then((Episodes) => {
            if (Episodes?.length > 0) {
              setEpisodes(
                Episodes.map((song) => {
                  return {
                    ...song,
                    plays: listeners.filter(
                      (l) =>
                        l?.songId === song?.songId && l?.playType === playType
                    )?.length,
                  };
                })
              ).sort((a, b) => b?.plays - a?.plays);
            }
            setEpisodesLoaded(true);
          })
          .catch((err) => {
            setEpisodesLoaded(true);
          });
      }
    }

    return () => {};
  }, [playType, podcasterId, filter, isLoaded]);

  useEffect(() => {
    if (isLoaded && getObjectLength(groupedPlaylists) > 0) {
      setPlaylistsLoaded(false);
    }
    return () => {};
  }, [podcasterId, filter, isLoaded]);

  const getEpisodesByPlayType = () => {
    let list = [];
    switch (playType) {
      case "skip":
        list = listeners
          ?.filter((play) => play?.playType === playType && !play?.repeat)
          .map((play) => {
            return play?.songId;
          });
        break;
      case "full":
        list = listeners
          ?.filter((play) => play?.playType === playType || play?.repeat > 0)
          .map((play) => {
            return play?.songId;
          });
        break;
      case "partial":
        list = listeners
          ?.filter((play) => play?.playType === playType && !play?.repeat)
          .map((play) => {
            return play?.songId;
          });
        break;

      default:
        list = listeners.map((play) => {
            return play?.songId;
          });
        break;
    }
    return [...new Set(list)];
  };

  return (
    <>
      <ContentWrapper>
        {!isLoaded && <ModalLoadingScreen text={"Loading Stats..."} />}
        {isLoaded && (
          <>
            <KeyMetrics
              data={[
                {
                  heading: "Playlist Plays",
                  onClick: () => {
                    setMetricType("plays");
                  },
                  labels:
                    getObjectLength(groupedListens) > 0
                      ? Object.keys(groupedListens)
                      : [],
                  datasets: [
                    {
                      label: "Plays",
                      data:
                        getObjectLength(groupedListens) > 0
                          ? Object.values(groupedListens).map((plays) => {
                              return getPlayCount(plays);
                            })
                          : [],
                      borderColor: themeColors.mainColor,
                      backgroundColor: themeColors.mainColor,
                    },
                  ],
                },
                {
                  heading: "Playlist Listeners",
                  onClick: () => {
                    setMetricType("listeners");
                  },
                  labels:
                    getObjectLength(groupedListens) > 0
                      ? Object.keys(groupedListens)
                      : [],
                  datasets: [
                    {
                      label: "Listeners",
                      data:
                        getObjectLength(groupedListens) > 0
                          ? Object.values(getNumListeners(groupedListens))
                          : [],
                      borderColor: themeColors.secondaryColor,
                      backgroundColor: themeColors.secondaryColor,
                    },
                  ],
                },
                {
                  heading: "Playlist Additions",
                  onClick: () => {
                    setMetricType("playlistAdditions");
                  },
                  labels:
                    getObjectLength(groupedPlaylists) > 0
                      ? Object.keys(groupedPlaylists)
                      : [],
                  datasets: [
                    {
                      label: "Playlists",
                      data:
                        getObjectLength(groupedPlaylists) > 0
                          ? Object.values(groupedPlaylists)?.map(
                              (playlists) => {
                                return playlists?.length;
                              }
                            )
                          : [],
                      borderColor: themeColors.errorColor,
                      backgroundColor: themeColors.errorColor,
                    },
                  ],
                },
              ]}
            />
            {metricType === "plays" && getObjectLength(groupedListens) > 0 && (
              <>
                <ChipList alignment={"right"}>
                  <Chip
                    label={"All"}
                    background={playType === "all" && themeColors.accentColor}
                    onChipClick={() => setPlayType("all")}
                  />
                  <Chip
                    label={"Full Plays"}
                    background={playType === "full" && themeColors.successColor}
                    onChipClick={() => setPlayType("full")}
                  />
                  <Chip
                    label={"Partial Plays"}
                    background={playType === "partial" && themeColors.infoColor}
                    onChipClick={() => setPlayType("partial")}
                  />
                  <Chip
                    label={"Skips"}
                    background={playType === "skip" && themeColors.warningColor}
                    onChipClick={() => setPlayType("skip")}
                  />
                </ChipList>
                {playType === "all" && (
                    <ChartToggle
                      data={{
                        heading: 'All Plays (By Type)',
                        labels:
                          listeners?.length > 0 > 0
                            ? Object.keys(
                                groupInfoByDate(listeners, "timestamp", filter)
                              )
                            : [],
                        datasets: [
                          {
                            label: "Full",
                            data:
                              listeners.filter((p) => p?.playType === "full")
                                ?.length > 0
                                ? Object.values(
                                    groupInfoByDate(
                                      listeners.filter(
                                        (p) => p?.playType === "full"
                                      ),
                                      "timestamp",
                                      filter
                                    )
                                  ).map((p) => {
                                    return p.length;
                                  })
                                : [],

                            backgroundColor: themeColors.successColor,
                            borderColor: themeColors.successColor,
                          },
                          {
                            label: "Partial",
                            data:
                              listeners.filter((p) => p?.playType === "partial")
                                ?.length > 0
                                ? Object.values(
                                    groupInfoByDate(
                                      listeners.filter(
                                        (p) => p?.playType === "partial"
                                      ),
                                      "timestamp",
                                      filter
                                    )
                                  ).map((p) => {
                                    return p.length;
                                  })
                                : [],

                            borderColor: themeColors.infoColor,
                            backgroundColor: themeColors.infoColor,
                          },
                          {
                            label: "Skips",
                            data:
                              listeners.filter((p) => p?.playType === "skip")
                                ?.length > 0
                                ? Object.values(
                                    groupInfoByDate(
                                      listeners.filter(
                                        (p) => p?.playType === "skip"
                                      ),
                                      "timestamp",
                                      filter
                                    )
                                  ).map((p) => {
                                    return p?.length;
                                  })
                                : [],
                            borderColor: themeColors.warningColor,
                            backgroundColor: themeColors.warningColor,
                          },
                        ],
                      }}
                    />
                )}
                {playType !== "all" && (
                    <ChartToggle
                      data={{
                        heading: `${playType[0].toUpperCase()}${playType.substring(
                      1
                    )}`,
                        labels:
                          getObjectLength(groupedListens) > 0
                            ? Object.keys(groupedListens)
                            : [],
                        datasets: [
                          {
                            label: playType,
                            data:
                              getObjectLength(groupedListens) > 0
                                ? Object.values(groupedListens).map((plays) => {
                                    return getGroupedPlays(plays)[playType];
                                  })
                                : [],

                            borderColor:
                              playType === "skip"
                                ? themeColors.warningColor
                                : playType === "partial"
                                ? themeColors.infoColor
                                : themeColors.successColor,
                          },
                        ],
                      }}
                    />
                )}
                {!isEpisodesLoaded && <ModalLoadingScreen />}
                {isEpisodesLoaded && (
                  <ContentWrapper>
                    <FormWrapper>
                      <EpisodesReleaseTable
                        releases={episodes}
                        heading={`Episodes`}
                      />
                    </FormWrapper>
                  </ContentWrapper>
                )}
              </>
            )}
            {metricType === "listeners" &&
              getObjectLength(groupedListens) > 0 && (
                <>
             
                    <ChartToggle
                      data={{
                        heading: playType,
                        labels: Object.keys(groupedListens),
                        datasets: [
                          {
                            label: "Listeners",
                            data: Object.values(
                              getNumListeners(groupedListens)
                            ),

                            borderColor: themeColors.successColor,
                            backgroundColor: themeColors.successColor,
                          },
                        ],
                      }}
                    />
                  
                </>
              )}
            {metricType === "playlistAdditions" &&
              getObjectLength(groupedPlaylists) > 0 && (
                <>
                  {
                    <ContentWrapper>
                      <FormWrapper>
                        <PlaylistTable
                          playlists={playlists}
                          heading={`Playlists`}
                        />
                      </FormWrapper>
                    </ContentWrapper>
                  }
                </>
              )}
            {(((metricType === "plays" || metricType === "listeners") &&
              listeners?.length === 0) ||
              (metricType === "playlistAdditions" &&
                getObjectLength(groupedPlaylists) === 0)) && (
              <>
                <FormWrapper>
                  <FormText>No Data Available</FormText>
                </FormWrapper>
              </>
            )}
          </>
        )}
      </ContentWrapper>
    </>
  );
};

export default PlaylistsContent;
