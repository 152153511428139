import React, { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { CONTENT_TYPES } from '../../../utils/Enum'
import { Button1 } from '../../ButtonElement'
import ContentList from '../../ContentList'
import { DraggableSongList } from '../../ContentList/SongList'
import ContentWrapper from '../../ContentWrapper'
import { FormWrapper } from '../../CustomForm/CustomFormElements'
import LoadingScreen, { ModalLoadingScreen } from '../../LoadingScreen'
import { HoverOption } from '../../MenuOptions/MenuOptionsElements'
import { useModal } from '../../Modal/ModalContext'
import { SongDetailsText } from '../../MusicUploadsForm/MusicUploadsFormElements'
import NFTStorageTokenForm from '../../MusicUploadsForm/NFTStorageTokenForm'
import OptionModal from '../../OptionModal'
import Page1 from './Page1'

const AlbumEdit = ({album, submitChanges, currentUser, validateNFTStorageKey}) => {
    const location= useLocation()
    const navigate  = useNavigate()
    const [albumInfo, setAlbumInfo] = useState(album ? album : {})
    const [songs, setSongs] = useState(album?.songs ? album?.songs : [])
    const { modalContent, isOpen, toggleModal } = useModal();


    const handleInput = (e) => {
      switch (e.target.name) {
        case "album price":
          setAlbumInfo((prev) => {
            return { ...prev, price: e.target.value };
          });
          if(e.target.value[0] === "0"){
            setAlbumInfo((prev) => {
              return { ...prev, price: e.target.value.substring(1,e.target.value.length) };
            })
           }else{
             setAlbumInfo((prev) => {
              return { ...prev, price: e.target.value };
            })
           }
           
  /*          if(!isNaN(e.target.value) && e.target.value > 0){
             delete errs.price
             setErrors(errs)
             return
           } */
           if(e.target.value?.length === 0){
             
             setAlbumInfo((prev) => {
              return { ...prev, price: 0 };
            })
           }
           if(isNaN(e.target.value)){
           /*   err = {
               ...err,
               price: "Invalid Price. Please enter price or leave 0"
             } */
  
             setAlbumInfo((prev) => {
              return { ...prev, price: parseFloat(e.target.value) };
            })
             /* setErrors(err) */
             return
           }
          break;
  
       
        case "album about":
          setAlbumInfo((prev) => {
            return { ...prev, about: e.target.value };
          });
          break;
        default:
          break;
      }
    };
  
    const handleToggle = (type) => {
      let streamable = albumInfo?.isStreamable ? albumInfo?.isStreamable : false;
      let downloadable = albumInfo?.isDownloadable
        ? albumInfo?.isDownloadable
        : false;
      let subscription = albumInfo?.isSubscription
        ? albumInfo?.isSubscription
        : false;
      let mintable = albumInfo?.isMintable ? albumInfo?.isMintable : false;
      let explicit = albumInfo?.isExplicit ? albumInfo?.isExplicit : false;
      switch (type) {
        case "explicit":
          explicit = !explicit;
          setAlbumInfo((prev) => {
            return { ...prev, isExplicit: explicit };
          });
          break;
        case "streamable":
          streamable = !streamable;
          if (streamable) {
            setAlbumInfo((prev) => {
              return { ...prev, isSubscription: false };
            });
          }
          setAlbumInfo((prev) => {
            return { ...prev, isStreamable: streamable };
          });
          break;
        case "downloadable":
          downloadable = !downloadable;
          if (downloadable) {
            setAlbumInfo((prev) => {
              return { ...prev, isSubscription: false };
            });
          }
          setAlbumInfo((prev) => {
            return { ...prev, isDownloadable: downloadable };
          });
          break;
        case "subscription":
          subscription = !subscription;
          if (subscription) {
            setAlbumInfo((prev) => {
              return { ...prev, isDownloadable: false, isStreamable: false };
            });
          }
          setAlbumInfo((prev) => {
            return { ...prev, isSubscription: subscription };
          });
          break;
        case "mintable":
          mintable = !mintable;
          if (mintable) {
            toggleModal();
            modalContent(
              <OptionModal
                onCancel={() => {
                  toggleModal();
                  modalContent();
                }}
                heading={"Set Mintable?"}
              >
                <SongDetailsText>{`By clicking 'OK', you agree to upload your music \n 
          on IPFS and allow for it to be minted as an NFT on our platform.`}</SongDetailsText>
                <HoverOption
                  onClick={async () => {
                    modalContent(
                    <OptionModal><ModalLoadingScreen text={'Updating. Please Wait...'} transparent={true}/></OptionModal>)
                    await validateNFTStorageKey(albumInfo?.artists[0]?.artistId).then(data => {
                      if(data){
                        setAlbumInfo((prev) => {
                          return { ...prev, isMintable: true };
                        });
                        toggleModal()
                            modalContent()
                      }else{
                        modalContent(<>
                        <OptionModal heading={"NFT Storage API Key Not Found"}>
                          <HoverOption onClick={() => {
                            modalContent(<>
                              <NFTStorageTokenForm artistId={albumInfo?.artists[0]?.artistId} currentUser={currentUser?.uid} setMintable={(value) => {
                                setAlbumInfo((prev) => {
                                  return { ...prev, isMintable: value };
                                });
                              }}/>
                            </>)
                          }}>
                            Add API Key
                          </HoverOption>
                          <HoverOption onClick={() => {
                            toggleModal()
                            modalContent()
                          }}>
                           Cancel
                          </HoverOption>
                        </OptionModal>
                      </>)
                         
                      }
                    }).catch(err => {
                      setAlbumInfo((prev) => {
                        return { ...prev, isMintable: false };
                      });
                      modalContent(<>
                        <OptionModal heading={"Something Went Wrong. Please try again"}>
                          <HoverOption onClick={() => {
                            toggleModal()
                            modalContent()
                          }}>
                            Close
                          </HoverOption>
                        </OptionModal>
                      </>)
                    })
                   
  
                  }}
                >
                  OK
                </HoverOption>
              </OptionModal>
            );
          } else {
            setAlbumInfo((prev) => {
              return { ...prev, isMintable: false };
            });
          } 
  
          break;
        default:
          break;
      }
      if (downloadable === false && streamable === false) {
        setAlbumInfo((prev) => {
          return { ...prev, isSubscription: true, isStreamable: false };
        });
      }
    };
  
    const setCurrency = (currency) => {
      setAlbumInfo((prev) => {
        return { ...prev, currency: currency };
      });
    };
  return (
    <>
    <FormWrapper><Page1 albumInfo={albumInfo}
    handleToggle={handleToggle}
    handleInput={handleInput}
    setCurrency={setCurrency}/></FormWrapper>
    <FormWrapper><ContentWrapper heading={"Songs"}>
        <DraggableSongList content={songs} setContent={setSongs} />
      </ContentWrapper></FormWrapper>
      <div style={{
        flexDirection: "row", 
      display: "flex", 
      width: "100%", 
      alignContent:"center", 
      alignItems:"center", 
      justifyContent: "space-around"}}><Button1 onClick ={ () =>{
      /* //console.log({
        isDownloadable: albumInfo?.isDownloadable,
        isStreamable: albumInfo?.isStreamable,
        isMintable: albumInfo?.isMintable,
        about: albumInfo?.about,
        price: albumInfo?.isDownloadable ? albumInfo?.price : undefined,
        currency: albumInfo?.isDownloadable ? albumInfo?.currency : undefined,
        songs : songs.map(s => {
          return s.songId
        })
      }) */
      submitChanges(CONTENT_TYPES.ALBUM, {
        isDownloadable: albumInfo?.isDownloadable,
        isStreamable: albumInfo?.isStreamable,
        isMintable: albumInfo?.isMintable,
        about: albumInfo?.about,
        price: albumInfo?.isDownloadable ? albumInfo?.price : undefined,
        currency: albumInfo?.isDownloadable ? albumInfo?.currency : undefined,
        songs : songs.map(s => {
          return s.songId
        })
      })}
    }>Submit Changes</Button1>
    <Button1 onClick={() => {
        navigate( `/album/${album?.albumId}`,{replace: true});

    }}>Cancel</Button1></div>
    </>
  )
}

export default AlbumEdit