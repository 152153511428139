import React, { useEffect, useState } from "react";
import useArtist from "../../../../hooks/useArtist";
import ContentWrapper from "../../../ContentWrapper";
import { SelectButton, SelectButtonWrapper } from "../../../Select";
import { ModalLoadingScreen } from "../../../LoadingScreen";
import {
  getGroupedPlays,
  getNumListeners,
  getNumListenersByDate,
  getObjectLength,
  getPlayCount,
  groupInfoByDate,
} from "../../../../utils";
import { useWebsiteTheme } from "../../../WebsiteTheme/WebsiteThemeContext";
import BarGraph from "../../../Charts/Bar";
import PieGraph from "../../../Charts/Pie";
import KeyMetrics from "../../../Charts/KeyMetrics";
import { getRandomColor } from "../../../HexToRGBA";
import { ChipList } from "../../../Chip/ChipElements";
import Chip from "../../../Chip";
import { Theme } from "../../../WebsiteThemeElements";
import useMusic from "../../../../hooks/useMusic";
import {
  CollectionSongList,
  MusicStoreSongList,
} from "../../../ContentList/SongList";
import { SinglesReleasesTable } from "../../../ContentTable/ArtistTables";
import { FormText, FormWrapper } from "../../../CustomForm/CustomFormElements";
import ChartToggle from "../../../Charts";

const ListenersContent = ({ artistId, isLoaded, setLoaded, filter }) => {
  const { themeColors } = useWebsiteTheme();
  const {
    getArtistSubscribersByDate,
    getArtistListenersByDate,
    getArtistFollowersByDate,
  } = useArtist({ id: artistId });
  const { getSongs } = useMusic();
  const [metricType, setMetricType] = useState("plays");
  const [listeners, setListeners] = useState([]);
  const [numListeners, setNumListeners] = useState({});
  const [groupedListens, setGroupListens] = useState({});
  const [subscribers, setSubscribers] = useState({});
  const [followers, setFollowers] = useState({});
  const [playType, setPlayType] = useState("all");
  const [songs, setSongs] = useState([]);
  const [isSongsLoaded, setSongsLoaded] = useState(false);
  const getData = async () => {
    setLoaded(false);
    setListeners([]);
    setGroupListens({});
    setSubscribers([]);
    setFollowers([]);
    return await Promise.all([
      getArtistListenersByDate(artistId, filter),
      getArtistSubscribersByDate(artistId, filter),
      getArtistFollowersByDate(artistId, filter),
    ])
      .then((data) => {
        if (data[0] !== false && data[0]?.listeners?.length > 0) {
          setListeners(data[0]?.listeners);
          let g = groupInfoByDate(data[0]?.listeners, "timestamp", filter);
          setGroupListens(g);
          setNumListeners(getNumListeners(g));
        }
        if (data[1]?.length > 0 && data[1] !== false) {
          setSubscribers(
            groupInfoByDate(data[1]?.subscribers, "dateSubscribed", filter)
          );
          //console.log(data[1])
        }
        if (data[2]?.length > 0 && data[2] !== false) {
          setFollowers(
            groupInfoByDate(data[2]?.followers, "dateFollowed", filter)
          );
          console.log(data[2]);
        }
        setLoaded(true);
        return;
      })
      .catch((err) => {
        console.error(err);
        setLoaded(true);
        return;
      });
  };

  useEffect(() => {
    getData();
    return () => {};
  }, [artistId, filter]);

  useEffect(() => {
    if (isLoaded) {
      setSongsLoaded(false);
      let s = getSongsByPlayType();
      if (s?.length > 0) {
        getSongs(s)
          .then((Songs) => {
            if (Songs?.length > 0) {
              setSongs(
                Songs.map((song) => {
                  return {
                    ...song,
                    plays:
                      playType === "all"
                        ? listeners.filter((l) => l?.songId === song?.songId)
                            ?.length
                        : listeners.filter(
                            (l) =>
                              l?.songId === song?.songId &&
                              l?.playType === playType
                          )?.length,
                  };
                })
              ).sort((a, b) => b?.plays - a?.plays);
            }
            setSongsLoaded(true);
          })
          .catch((err) => {
            setSongsLoaded(true);
          });
      }
    }

    return () => {};
  }, [playType, artistId, filter, isLoaded]);

  const getSongsByPlayType = () => {
    let list = [];
    switch (playType) {
      case "skip":
        list = listeners
          ?.filter((play) => play?.playType === playType && !play?.repeat)
          .map((play) => {
            return play?.songId;
          });
        break;
      case "full":
        list = listeners
          ?.filter((play) => play?.playType === playType || play?.repeat > 0)
          .map((play) => {
            return play?.songId;
          });
        break;
      case "partial":
        list = listeners
          ?.filter((play) => play?.playType === playType && !play?.repeat)
          .map((play) => {
            return play?.songId;
          });
        break;

      default:
        list = listeners?.map((play) => {
          return play?.songId;
        });
        break;
    }
    return [...new Set(list)];
  };

  return (
    <ContentWrapper>
      {!isLoaded && <ModalLoadingScreen text={"Loading Stats..."} />}
      {isLoaded && listeners?.length > 0 && (
        <>
          <KeyMetrics
            data={[
              {
                heading: "Plays",
                onClick: () => {
                  setMetricType("plays");
                },
                labels:
                  getObjectLength(groupedListens) > 0
                    ? Object.keys(groupedListens)
                    : [],
                datasets: [
                  {
                    label: "Plays",
                    data:
                      getObjectLength(groupedListens) > 0
                        ? Object.values(groupedListens).map((plays) => {
                            return getPlayCount(plays);
                          })
                        : [],
                    borderColor: themeColors.infoColor,
                    backgroundColor: themeColors.infoColor,
                  },
                ],
              },
              {
                heading: "Listeners",
                onClick: () => {
                  setMetricType("listeners");
                },
                labels:
                  getObjectLength(numListeners) > 0
                    ? Object.keys(numListeners)
                    : [],
                datasets: [
                  {
                    label: "Listeners",
                    data:
                      getObjectLength(numListeners) > 0
                        ? Object.values(numListeners)
                        : [],
                    borderColor: themeColors.warningColor,
                    backgroundColor: themeColors.warningColor,
                  },
                ],
              },
              {
                heading: "Followers",
                onClick: () => {
                  console.log("followers");
                  setMetricType("followers");
                },
                labels:
                  getObjectLength(followers) > 0 ? Object.keys(followers) : [],
                datasets: [
                  {
                    label: "followers",
                    data:
                      getObjectLength(followers) > 0
                        ? Object.values(followers).map((f) => {
                            return f?.length;
                          })
                        : [],
                    borderColor: themeColors.successColor,
                    backgroundColor: themeColors.successColor,
                  },
                ],
              },
              {
                heading: "Subscribers",
                onClick: () => {
                  setMetricType("subscribers");
                },
                labels:
                  getObjectLength(subscribers) > 0
                    ? Object.keys(subscribers)
                    : [],
                datasets: [
                  {
                    label: "Subscribers",
                    data:
                      getObjectLength(subscribers) > 0
                        ? Object.values(subscribers)
                        : [],
                    borderColor: themeColors.errorColor,
                    backgroundColor: themeColors.errorColor,
                  },
                ],
              },
            ]}
          />
          {metricType === "plays" && listeners?.length > 0 && (
            <>
              <ChipList alignment={"right"}>
                <Chip
                  label={"All"}
                  background={playType === "all" && themeColors.accentColor}
                  onChipClick={() => setPlayType("all")}
                />
                <Chip
                  label={"Full Plays"}
                  background={playType === "full" && themeColors.successColor}
                  onChipClick={() => setPlayType("full")}
                />
                <Chip
                  label={"Partial Plays"}
                  background={playType === "partial" && themeColors.infoColor}
                  onChipClick={() => setPlayType("partial")}
                />
                <Chip
                  label={"Skips"}
                  background={playType === "skip" && themeColors.warningColor}
                  onChipClick={() => setPlayType("skip")}
                />
              </ChipList>
              <>
                {playType === "all" && (
                  <ChartToggle
                    data={{
                      heading: "All Plays (By Type)",
                      labels:
                        getObjectLength(groupedListens) > 0
                          ? Object.keys(groupedListens)
                          : [],
                      datasets: [
                        {
                          label: "Full",
                          data:
                            listeners.filter((p) => p?.playType === "full")
                              ?.length > 0
                              ? Object.values(
                                  groupInfoByDate(
                                    listeners.filter(
                                      (p) => p?.playType === "full"
                                    ),
                                    "timestamp",
                                    filter
                                  )
                                ).map((p) => {
                                  return p.length;
                                })
                              : [],
                          backgroundColor: themeColors.successColor,
                          borderColor: themeColors.successColor,
                        },
                        {
                          label: "Partial",
                          data:
                            listeners.filter((p) => p?.playType === "partial")
                              ?.length > 0
                              ? Object.values(
                                  groupInfoByDate(
                                    listeners.filter(
                                      (p) => p?.playType === "partial"
                                    ),
                                    "timestamp",
                                    filter
                                  )
                                ).map((p) => {
                                  return p.length;
                                })
                              : [],
                          backgroundColor: themeColors.infoColor,
                          borderColor: themeColors.infoColor,
                        },
                        {
                          label: "Skips",
                          data:
                            listeners.filter((p) => p?.playType === "skip")
                              ?.length > 0
                              ? Object.values(
                                  groupInfoByDate(
                                    listeners.filter(
                                      (p) => p?.playType === "skip"
                                    ),
                                    "timestamp",
                                    filter
                                  )
                                ).map((p) => {
                                  return p?.length;
                                })
                              : [],
                          backgroundColor: themeColors.warningColor,
                          borderColor: themeColors.warningColor,
                        },
                      ],
                    }}
                  />
                )}{" "}
                {playType !== "all" && (
                  <ChartToggle
                    data={{
                      heading: `${playType[0].toUpperCase()}${playType.substring(
                        1
                      )}`,
                      labels:
                        getObjectLength(groupedListens) > 0
                          ? Object.keys(groupedListens)
                          : [],
                      datasets: [
                        {
                          label: playType,
                          data:
                            getObjectLength(groupedListens) > 0
                              ? Object.values(groupedListens).map((plays) => {
                                  return getGroupedPlays(plays)[playType];
                                })
                              : [],
                          backgroundColor:
                            playType === "skip"
                              ? themeColors.warningColor
                              : playType === "partial"
                              ? themeColors.infoColor
                              : themeColors.successColor,
                          borderColor:
                            playType === "skip"
                              ? themeColors.warningColor
                              : playType === "partial"
                              ? themeColors.infoColor
                              : themeColors.successColor,
                        },
                      ],
                    }}
                  />
                )}
              </>

              {!isSongsLoaded && <ModalLoadingScreen />}
              {isSongsLoaded && (
                <ContentWrapper>
                  <FormWrapper>
                    <SinglesReleasesTable
                      releases={songs}
                      heading={`${
                        playType !== "all"
                          ? `${playType[0].toUpperCase()}${playType.substring(
                              1
                            )} Played Songs`
                          : "All Songs"
                      }`}
                    />
                  </FormWrapper>
                </ContentWrapper>
              )}
            </>
          )}
          {metricType === "listeners" && listeners?.length > 0 && (
            <>
              <ContentWrapper>
                <ChartToggle
                  data={{
                    heading: "Listeners",
                    labels:
                      getObjectLength(numListeners) > 0
                        ? Object.keys(numListeners)
                        : [],
                    datasets: [
                      {
                        label: "Listeners",
                        data:
                          getObjectLength(numListeners) > 0
                            ? Object.values(numListeners)
                            : [],
                        backgroundColor: themeColors.infoColor,
                        borderColor: themeColors.infoColor,
                        fill: true,
                      },
                    ],
                  }}
                />
              </ContentWrapper>
            </>
          )}
          {(((metricType === "plays" || metricType === "listeners") &&
            listeners?.length === 0) ||
            (metricType === "followers" && getObjectLength(followers) === 0) ||
            (metricType === "subscribers" &&
              getObjectLength(subscribers) === 0)) && (
            <>
              <FormWrapper>
                <FormText>No Data Available</FormText>
              </FormWrapper>
            </>
          )}
        </>
      )}
    </ContentWrapper>
  );
};

export default ListenersContent;
