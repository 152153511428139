import React from 'react'
import ArtistRegistration from '../../../components/RegistrationForm/ArtistRegistration'
import { Access } from '../../../components/Navbar/Data'
import Navbar from '../../../components/Navbar'

const ArtistRegistrationPage = () => {
  return (
    <>
    <Navbar navInfo={Access} />
        <ArtistRegistration/>
    </>
  )
}

export default ArtistRegistrationPage