import React from 'react'
import ContentWrapper from '../../components/ContentWrapper'
import { FormText, FormWrapper } from '../../components/CustomForm/CustomFormElements'
import { HeroH1 } from '../../components/HeroSection/HeroElements'
import { TextRoute } from '../../components/QRCodeForm/QrCodeFormElements'
import { FaChevronRight } from 'react-icons/fa'
import { Container, VideoBg, ContentWrapper as CW } from '../../components/SignUp/SignUpElements'
import { VideoUtils } from '../../utils/ImageUtils'

export const Releases = () => {
    const {video1} = VideoUtils()

  return (
    <><Container>
    <VideoBg autoPlay loop muted src={video1}
                  type='video/mp4' contentEditable={false} onContextMenu={(e) => {e.preventDefault()}}>
              </VideoBg>
              <CW>
              <ContentWrapper>
        
    <FormWrapper>
        <HeroH1>Make Your Presence Felt</HeroH1>
        <br/>
    <FormText style={{width: '50%'}}>
    Through your artist profile, get ready to release your musical work to the Sonar Muse platform and more. Release in a web2 or web3 format.
    </FormText>
    <TextRoute to={'/signup'}>Sign Up for Sonar Muse for Artists <FaChevronRight /></TextRoute>
    </FormWrapper>
    
    </ContentWrapper></CW>
      </Container></>
  )
}



export const Promote = () => {
    const {video1} = VideoUtils()

  return (
    <><Container>
    <VideoBg autoPlay loop muted src={video1}
                  type='video/mp4' contentEditable={false} onContextMenu={(e) => {e.preventDefault()}}>
              </VideoBg>
              <CW>
              <ContentWrapper>
        
        <FormWrapper>
            <HeroH1>Reach for The Stars.</HeroH1>
            <br/>
        <FormText style={{width: '50%'}}>
            With a collection of tools that let you share songs and commemorate milestones with your fans, no matter where they are, you can promote your music.
        </FormText>
        <TextRoute to={'/signup'}>Sign Up for Sonar Muse for Artists <FaChevronRight /></TextRoute>
        </FormWrapper>
        
        </ContentWrapper></CW>
        </Container></>
  )
}



export const Explore = () => {
    const {video1} = VideoUtils()

  return (
    <><Container>
    <VideoBg autoPlay loop muted src={video1}
                  type='video/mp4' contentEditable={false} onContextMenu={(e) => {e.preventDefault()}}>
              </VideoBg>
              <CW>
              <ContentWrapper>
        
    <FormWrapper>
        <HeroH1>Aspire to Inspire.</HeroH1>
        <br/>
    <FormText style={{width: '50%'}}>
        Learn more about the tools and services provided by the Sonar Muse for Artists platform. Allowing them to focus more on their creative methods.
    </FormText>
    </FormWrapper>
    
    </ContentWrapper></CW>
      </Container></>
  )
}



export const Statistics = () => {
    const {video1} = VideoUtils()

  return (
     <><Container>
     <VideoBg autoPlay loop muted src={video1}
                   type='video/mp4' contentEditable={false} onContextMenu={(e) => {e.preventDefault()}}>
               </VideoBg>
               <CW>
               <ContentWrapper>
        
    <FormWrapper>
        <HeroH1>Find Your Community.</HeroH1>
        <br/>
    <FormText style={{width: '50%'}}>
    Analyse the performance each song, album or podcast episode, and observe how your followers respond to it all.</FormText>
    <TextRoute to={'/signup'}>Sign Up for Sonar Muse for Artists <FaChevronRight /></TextRoute>
    </FormWrapper>
    
    </ContentWrapper></CW>
      </Container></>)
}

