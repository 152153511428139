import  { convertDurationToString, hexToStr, timestampToDate } from "../index"
import { db} from "../../components/Authorization/firebase"
import {query, doc, getDoc, collection, getDocs, where, limit, orderBy, updateDoc, onSnapshot, deleteDoc} from 'firebase/firestore'
import { PAYOUT_TYPE } from "../Enum"
import { getUserDetails } from "./userUtils"
import axiosInstance from "../../components/Authorization/client"


export const getPodcasterEpisodes = async (podcasterId) => {
    return await getDocs(query(collection(db, "episodes"), where("podcasters", "array-contains", podcasterId), orderBy('releaseDate', 'desc')))
    .then(async (episodes) => {
      let episodeInfo= []
      for(let i = 0; i < episodes.docs?.length; i++) {
        let e = episodes.docs[i]
        let likes = (await getDocs(query(collection(db, "likes"), where("episodeId", "==", e.id)))).size
        //let purchases = (await getDocs(query(collection(db, "digitalPurchases"), where("episodeId", "==", e.id)))).size
        episodeInfo.push({
          episodeId: e.id,
          ...e.data(),
          duration: convertDurationToString(e.data().duration),
          likes: likes,
          //purchases: purchases,
          releaseDate: `${new Date(timestampToDate(e?.data()?.releaseDate)).getDate()}-${new Date(timestampToDate(e?.data()?.releaseDate)).getMonth() + 1}-${new Date(timestampToDate(e?.data()?.releaseDate)).getFullYear()}`
        }
      )
      
      }
      return episodeInfo
    })
  }
  
  export const getTopPlayedPodcasterEpisodes = async (podcasterId) => {
    return await getDocs(query(collection(db, "episodes"), where("podcasters", "array-contains", podcasterId), limit(10))) //order by plays desc
    .then(async (episodes) => {
      let episodeInfo= []
      for(let i = 0; i < episodes.docs?.length; i++) {
        let e = episodes.docs[i]
        let likes = (await getDocs(query(collection(db, "likes"), where("episodeId", "==", e.id)))).size
        //let purchases = (await getDocs(query(collection(db, "digitalPurchases"), where("episodeId", "==", e.id)))).size
        episodeInfo.push({
          episodeId: e.id,
          ...e.data(),
          duration: convertDurationToString(e.data().duration),
          likes: likes,
          releaseDate: `${new Date(timestampToDate(e?.data()?.releaseDate)).getDate()}-${new Date(timestampToDate(e?.data()?.releaseDate)).getMonth() + 1}-${new Date(timestampToDate(e?.data()?.releaseDate)).getFullYear()}`
        }
      )
      
      }
      return episodeInfo
    })
  }

  export const getPodcasterNotifications = (podcasterId, setNotifications) => {
    return onSnapshot(query(collection(db, "podcasterNotifications"), where("podcasterId", "==", podcasterId), orderBy('createdAt', 'desc')), notifSnapshot => {
        setNotifications(notifSnapshot.docs.map(n => {
            return {notificationId: n?.id,
                ...n.data()}
        }))
    })
}

  export const getPodcasterFollowers = async(podcasterId, setFollowers) => {
    onSnapshot(query(collection(db, "podcasterFollows"), where("podcasterId", "==", podcasterId)), async(followSnapshot) => {
        let followers = followSnapshot.docs.map(n => {
            return {followId: n?.id,
                ...n.data(),
            }
        })
        var podcasterFollowers = [];
    for (var i = 0; i <= followers?.length - 1; i++) {
      let podcasterfollower = await getUserDetails(
        followers[i].followerId
      );
      if(!podcasterfollower?.uid){
        await deleteDoc(db,(`podcasterFollows/${followers[i].followId}`))
      }else{
      podcasterFollowers.push({
        email: podcasterfollower.email,
        photoUrl: podcasterfollower.photoURL,
        uid: podcasterfollower.uid,
        dateFollowed: followers[i].dateFollowed,
      });}
    }
    setFollowers(podcasterFollowers)
    })
}
  export const getPodcasterSubscribers = (podcasterId, setSubscribers) => {
    onSnapshot(query(collection(db, "podcasterSubscriptions"), where("podcasterId", "==", podcasterId)), async(subscribeSnapshot) => {
        let subscribers = subscribeSnapshot.docs.map(n => {
            return {subscriptionId: n?.id,
                ...n.data(),
            }
        })
        var podcasterSubscribers = [];
    for (var i = 0; i <= subscribers?.length - 1; i++) {
      let podcasterSubscriber = await getUserDetails(
        subscribers[i].SubscriberId
      );
      if(!podcasterSubscriber?.uid){
        await deleteDoc(db(`podcasterSubscribs/${subscribers[i].subscriptionId}`))
      }else{
      podcasterSubscribers.push({
        email: podcasterSubscriber.email,
        photoUrl: podcasterSubscriber.photoURL,
        uid: podcasterSubscriber.uid,
        dateSubscribed: subscribers[i].dateSubscribed,
      });}
    }
    setSubscribers(podcasterSubscribers)
    })
}

export const getPodcasterPendingMicropayments = async(podcasterId, setPendingFunds) => {
  onSnapshot(doc(db,`podcasters/${podcasterId}`), async (podcasterSnapshot) => {
    if(podcasterSnapshot?.exists){
      if(podcasterSnapshot.data()?.paymentChannel?.channel){
        return await axiosInstance.get(`/api/${podcasterId}/${podcasterSnapshot.data()?.paymentChannel?.channel}/getPodcasterCurrentBalance`).then((funds) => {
          if(funds.data?.funds){
            return setPendingFunds(funds.data.funds + (podcasterSnapshot.data()?.pendingFunds ? parseInt(podcasterSnapshot.data()?.pendingFunds) : 0))
          }
          return setPendingFunds(podcasterSnapshot.data()?.pendingFunds ? podcasterSnapshot.data()?.pendingFunds : 0)

        }).catch((err) => {
          return setPendingFunds(podcasterSnapshot.data()?.pendingFunds ? podcasterSnapshot.data()?.pendingFunds : 0)
        })
      }
      return setPendingFunds(podcasterSnapshot.data()?.pendingFunds ? podcasterSnapshot.data()?.pendingFunds : 0)
    }
    return setPendingFunds(0)
  })
}