import React, {useEffect} from 'react'
import { FaFacebook, FaFlickr, FaFoursquare, FaLink, FaTumblr, FaTwitter, FaVimeoV, FaWordpressSimple } from 'react-icons/fa';
import {Container,SocialLink, UrlLink} from './SocialLinksElements'
import { ThemeContext, ThemeProvider } from 'styled-components';
import { Theme } from '../WebsiteThemeElements';


const SocialLinks = ({accounts, background}) => {
   
    const displayAccounts = (accs) => {
        return accs.map((account) => {
          let acc = [];
          switch (account.shortname) {
            case "twitter":
              return (
                <>
                  
                    <SocialLink background={background} target="_blank" rel="noopener noreferrer" key={account.domain} href={account.url}>
                    <FaTwitter />
                    </SocialLink>
                </>
              );
    
            case "tumblr":
              return (
                <>
                   
                   <SocialLink background={background} target="_blank" rel="noopener noreferrer" key={account.domain} href={account.url}>
                     <FaTumblr />
                     </SocialLink>
                </>
              );
    
            case "vimeo":
              return (
                <>
                    <SocialLink background={background} target="_blank" rel="noopener noreferrer" key={account.domain} href={account.url}>
                      <FaVimeoV />
                      </SocialLink>
                </>
              );
    
            case "foursquare":
              return (
                <>
                    <SocialLink background={background} target="_blank" rel="noopener noreferrer" key={account.domain} href={account.url}>
                    <FaFoursquare />
                </SocialLink>
                </>
              );
    
            case "wordpress":
              acc = (
                <>
                  
                  <SocialLink background={background} target="_blank" rel="noopener noreferrer" key={account.domain} href={account.url}>
                        <FaWordpressSimple />
                    </SocialLink>
                 
                </>
              );
              break;

              case "flickr":
              acc = (
                <>
                  
                  <SocialLink background={background} target="_blank" rel="noopener noreferrer" key={account.domain} href={account.url}>
                        <FaFlickr />
                    </SocialLink>
                 
                </>
              );
              break;

              case "facebook":
              acc = (
                <>
                  
                  <SocialLink background={background} target="_blank" rel="noopener noreferrer" key={account.domain} href={account.url}>
                        <FaFacebook/>
                    </SocialLink>
                 
                </>
              );
              break;
    
            default:
              return (
                <>
                    <SocialLink background={background} target="_blank" rel="noopener noreferrer" key={account.domain} href={account.url}>
                    <FaLink />
                    </SocialLink>
                </>
              );
          }
          return acc
        });
      };
   
    return(
        <>
            <Container background={background}>
           { accounts && displayAccounts(accounts)}
            </Container>
        </>
    )
}

export const UrlLinks = ({urlLinks, background}) =>{

    const displayUrls = (urls) => {
        return urls.map((url, id) => {
          return (
            <>
              <UrlLink key={id} background={background} target="_blank" rel="noopener noreferrer" href={url.value}>
                <FaLink style={{marginRight: '1%'}}/>
                  {url.title}
              </UrlLink>
            </>
          );
        })
       }


       return(
        <>
            <Container background={background} >
            {urlLinks && displayUrls(urlLinks)}
            </Container>
        </>
    )
}

export default SocialLinks