import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAuth } from '../../../contexts/AuthContext'
import useRecordLabel from '../../../hooks/useRecordLabel'
import RecordLabelSettings from '../../../components/RecordLabelProfile/RecordLabelProfileContent/RecordLabelSettings'
import LoadingScreen from '../../../components/LoadingScreen'
import { getObjectLength } from '../../../utils'

const LabelSettings = () => {
    const {currentUser, profiles} = useAuth()
    const [profile, setProfile] = useState({})
    const {labelId} = useParams()
    const {getRecordLabelProfile} = useRecordLabel({labelId})
    const [isLoaded, setLoaded] = useState(false)
    const navigate = useNavigate()

    const getProfile = async() => {
      setLoaded(false)
      if(profiles?.label?.labelId !== labelId){
        navigate(-1)
    }

    await getRecordLabelProfile(labelId).then(label => {
      if(getObjectLength(label) > 0 && label !== false){
        setProfile(label)
        setLoaded(true)
        return
      }
      navigate(-1)
    }).catch(err => {
      navigate(-1)
      return
    })
    }

    useEffect(() => {
      getProfile()
      return () => {
      }
    }, [labelId])
    
  return (
    <>
    {!isLoaded && <LoadingScreen/>}
    {isLoaded && <RecordLabelSettings 
                      getProfile={getRecordLabelProfile}
                      labelId={profile?.labelId}
                      label={profile?.profile}
                      labelSettings={profile?.profile?.labelSettings}
                      gravatarProfile={profile?.gravatarProfile}
                      about={{
                        photos: profile?.gravatarProfile?.gallery,
                        accounts: profile?.gravatarProfile?.verified_accounts,
                        bio:
                        profile?.profile?.labelSettings?.bioType ===
                          "custom"
                            ? profile?.profile?.bio
                            : profile?.gravatarProfile?.description,
                      }}/>}
    </>
  )
}

export default LabelSettings