import React from 'react'
import Login from '../../components/Login'
import { Access } from '../../components/Navbar/Data'
import Navbar from '../../components/Navbar'


const SignIn = () => {
  return (
    <><Navbar navInfo={Access} />

    <Login/>
    </>
  )
}

export default SignIn