import { NFTStorage, Blob } from "nft.storage";
import axios from "../components/Authorization/client";
import { FormText } from "../components/CustomForm/CustomFormElements";
import { MINTING } from "./Enum";
import useArtist from "../hooks/useArtist";
import { accAddressInfo } from "./xrpl";
import { getUserProfile } from "../components/GravatarAPI";
import { getObjectLength } from ".";


const mintNFToken = (apiToken, data) => {
  const client = new NFTStorage({ token: apiToken });
};

export const convertFileToBytes = async (file) => {
  const reader = new FileReader();
  const fileByteArray = [];
  reader.readAsArrayBuffer(file);
  reader.onloadend = (evt) => {
    if (evt.target.readyState === FileReader.DONE) {
      const arrayBuffer = evt.target.result,
        array = new Uint8Array(arrayBuffer);
      for (const a of array) {
        fileByteArray.push(a);
      }
    }
    return fileByteArray;
  };
};

//mintStorage IPFS upload
export const uploadZipToIPFS = async (apiToken, content) => {
  let file = new File([content.file], content.name);
  const client = new NFTStorage({ token: apiToken });
  let cid = await client
    .storeDirectory([file])
    .then((id) => {
      return {
        name: content.name,
        type: "application/zip",
        uri: id,
      };
    })
    .catch((err) => {
      console.error(err);
      return false;
    });
  return cid;
};

export const uploadImageToIPFS = async (apiToken, content) => {
  let file = new File([content.file], content.name);
  const client = new NFTStorage({ token: apiToken });
  let cid = await client
    .storeDirectory([file])
    .then((id) => {
      return `ipfs://${id}/${content.name}`;
    })
    .catch((err) => {
      //console.log(err);
      return false;
    });
  return cid;
};
export const uploadImageToIPFS1 = async (uid,url, content) => {
  return await axios.post('/api/uploadToIPFS',{
    "fileName": "8144r-hB.jpg",
    "uid": "rKNVUBxSf9nKVHpPxYJpJAvsc3pabRdsak",
    "url": "https://firebasestorage.googleapis.com/v0/b/sonar-muse.appspot.com/o/artists%2FrKNVUBxSf9nKVHpPxYJpJAvsc3pabRdsak%2F6588574468521.jpg?alt=media&token=d83fb8ca-b72a-4322-86b8-fc38f229310c",
    "mimeType": "image/jpeg"
}).then((res) => {
    return {
      name: res.data?.name,
      uri: res.data?.cid,
      type: res.data?.type
    }
  }).catch(err => {
    console.error(err)
    return false
  })
};


export const uploadCARToIPFS = async (apiToken, content) => {};

export const getIPFSFile = (fileInfo) => {
  let url;
  if (fileInfo?.uri && fileInfo?.uri?.substring(0, 7) === "ipfs://") {
    url = (`https://nftstorage.link/ipfs/${fileInfo?.uri?.substring(7, fileInfo?.uri?.length)}/${fileInfo?.name}`)
      .split(" ")
      .join("%20");
      return url;
  }
  if (
    fileInfo?.uri?.substring(0, 8) === "https://" ||
    fileInfo?.uri?.substring(0, 7) === "http://"
  ) {
    url = fileInfo?.uri?.split(" ").join("%20");
    return url;
  }
  if(fileInfo?.uri?.substring(0, 3) === "baf" && fileInfo?.name){
    url = (`https://nftstorage.link/ipfs/${fileInfo?.uri}/${fileInfo?.name}`).split(" ")
      .join("%20");
      return url
  }
  if(fileInfo?.uri?.substring(0, 3) === "baf"){
    url = (
      `https://nftstorage.link/ipfs/${fileInfo?.uri}`
    ).split(" ")
      .join("%20");
      return url
  }
  if(fileInfo?.substring(0, 8) === "https://" ||
  fileInfo?.substring(0, 7) === "http://"){
    url = fileInfo?.split(" ").join("%20");
    return url; 
  }
  if (fileInfo?.substring(0, 7) === "ipfs://") {
    url = ("https://nftstorage.link/ipfs/" +fileInfo?.substring(7, fileInfo?.length))
      .split(" ")
      .join("%20");
      return url;
  }
  if(fileInfo?.substring(0, 3) === "baf"){
    url = (
      `https://nftstorage.link/ipfs/${fileInfo}`
    ).split(" ")
      .join("%20");
      return url
  }
  
    url = (
      "https://nftstorage.link/ipfs/" +fileInfo
    ).split(" ")
      .join("%20");
  return url;
};

export const getNFTImage = (uri) => { // USED ONLY FOR GETTING THE MAIN IMAGE FOR ANY NFTOKEN
  let url;
  if (uri?.substring(0, 7) === "ipfs://") {
    url = ("https://nftstorage.link/ipfs/" + uri?.substring(7, uri?.length))
      .split(" ")
      .join("%20").split("(").join("%28").split(")").join("%29");
  }
  if (
    uri?.substring(0, 8) === "https://" ||
    uri?.substring(0, 7) === "http://"
  ) {
    url = uri?.split(" ").join("%20");
    url = url.split("(").join("%28")
    url = url.split(")").join("%29")
  }
  return url;
};

export const convertSongToSonarMuseSchema = (song, albumArt) => {
  let data = song;
  let artists = song?.artists;
  let url = getIPFSFile(song?.audio);
  data = {
    ...data,
    url: url,
    artists: artists,
    licensing: song?.licensing?.id,
    songCredits: song?.credits,
    albumArt: getIPFSFile(albumArt ? albumArt : song?.albumArt),
    uploadedBy: song.uploadedBy?.wallet?.xrp,
  };
  delete data.audio;
  delete data.credits;
  delete data.mainArtists;
  return data;
};

export const convertSongsToSonarMuseSchema = (songs, albumArt) => {
  let songsData = [];
  for (let i = 0; i <= songs.length - 1; i++) {
    let song = convertSongToSonarMuseSchema(songs[i], albumArt);
    songsData.push(song);
  }
  return songsData;
};

export const convertAlbumToSonarMuseSchema = (album, albumArt) => {
  let songs = convertSongsToSonarMuseSchema(album?.songs,albumArt);
  let albumData = {
    ...album,
    albumArt: getIPFSFile(albumArt ? albumArt : album?.albumArt),
    licensing: album?.licensing?.id,
    uploadedBy: album?.uploadedBy?.wallet?.xrp,
    songs: songs,
  };
  return albumData;
};

export const convertAlbumsToSonarMuseSchema = (albums, albumArt) => {
  let albumsData = [];
  for (let i = 0; i <= albums.length - 1; i++) {
    let album = convertAlbumToSonarMuseSchema(albums[i], albumArt);
    albumsData.push(album);
  }
  return albumsData;
};

export const getNFTokenFlagType = (flag) => {
  switch (flag) {
    case 8:
      return  MINTING.FLAG.TRANSFERABLE
      
    case 9:
      return  <>{MINTING.FLAG.TRANSFERABLE},{MINTING.FLAG.BURNABLE} </>

    case 10:
      return  <>{MINTING.FLAG.TRANSFERABLE},{MINTING.FLAG.ONLY_XRP} </>

    case 4:
      return  MINTING.FLAG.TRUSTLINE

    default:
      return  MINTING.FLAG.TRANSFERABLE
          
          
  }
}

export const displayNFTokenFlag = (flag) => {
  switch (flag) {
    case 8:
      return (
        <>
          {` ${MINTING.FLAG.TRANSFERABLE}`}
          <div style={{ fontStyle: "italic", fontSize: "0.8rem" }}>
            {MINTING.FLAG_DESCRIPTION.TRANSFERABLE}
          </div>
        </>
      );
    case 9:
      return (
        <>
          {` ${MINTING.FLAG.BURNABLE}`}

          <div style={{ fontStyle: "italic", fontSize: "0.8rem" }}>
            {MINTING.FLAG_DESCRIPTION.BURNABLE}
          </div>
        </>
      );

    case 10:
      return (
        <>
          {` ${MINTING.FLAG.ONLY_XRP}`}
          <div style={{ fontStyle: "italic", fontSize: "0.8rem" }}>
            {MINTING.FLAG_DESCRIPTION.ONLY_XRP}
          </div>
        </>
      );

    case 4:
      return (
        <>
          {` ${MINTING.FLAG.TRUSTLINE}`}
          <div style={{ fontStyle: "italic", fontSize: "0.8rem" }}>
            {MINTING.FLAG_DESCRIPTION.TRUSTLINE}
          </div>
        </>
      );

    default:
      return (
        <>
          {` ${MINTING.FLAG.TRANSFERABLE}`}
          <div style={{ fontStyle: "italic", fontSize: "0.8rem" }}>
            {MINTING.FLAG_DESCRIPTION.TRANSFERABLE}
          </div>
        </>
      );
  }
};

export const convertRevShareToArrObj = (revShare) => {
  let keys = Object.keys(revShare)
  return keys.map((key, index) => {
    return {address: key,
    percentage: revShare[key]}
  })
}

export const convertArrObjToRevShare = (revShare) => {

  let rev = {}
  revShare.map((key, index) => {
    rev = {
      ...rev,
      [key.address]: key.percentage
    }
    return key
  })
  return rev
}


export const  getAddressName = async(address) => {
      return await accAddressInfo(address).then(info => {
        if(info?.email){
          return getUserProfile(info?.email).then(profile => {
            if(profile){
              return {name: profile?.displayName ? profile?.displayName : profile?.preferredUsername,
              profileUrl: profile.thumbnailUrl}
            }else{
              return address
            }
          })
        }else{
          return address
        }
  }).catch(err => {
    return address
   //console.error(err)
  })
}

