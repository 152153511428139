import React, { useEffect, useRef, useState } from "react";
import { FaRecordVinyl } from "react-icons/fa";
import { Link } from "react-router-dom";
import { v4 } from "uuid";
import useArtist from "../../../hooks/useArtist";
import {
  ALERT_TYPES,
  CONTENT_TYPES,
  MUSIC_PURCHASE_OPTIONS,
  NOTIFICATION_DISPATCH,
} from "../../../utils/Enum";
import ImageUtils from "../../../utils/ImageUtils";
import Alert from "../../Alert";
import { useAlert } from "../../Alert/AlertContext";
import { FormInput, FormText } from "../../CustomForm/CustomFormElements";
import { useModal } from "../../Modal/ModalContext";
import OptionModal from "../../OptionModal";
import ScrollToTop from "../../ScrollToTop";
import { SkeletonPurchaseCardImg } from "../../Skeleton/SkeletonElements";
import {
  BuyButton,
  CardContainer,
  CardContent,
  CardImg,
  CardTitle,
  CreatorImg,
  CreatorInfo,
  CreatorInfoContent,
  CreatorInfoWrapper,
  H6,
  InfoContentText,
  TextLink,
} from "../NFTokenCard/NFTokenCardElements";
import { SoldOutText } from "../CardElements";
import { getAddressName } from "../../../utils/nfTokensUploads";
import { getObjectLength } from "../../../utils";
import { getResizedImage } from "../../../utils/FileUploadsUtils";
import { useWebsiteTheme } from "../../WebsiteTheme/WebsiteThemeContext";

const SwerlCollectionCard = ({ content }) => {
  const { toggleModal, modalContent, isOpen, openModal, closeModal } =
    useModal();
    const {themeColors} = useWebsiteTheme()

  const { defaultAlbumArt, DEFAULT_IMAGE } = ImageUtils();
  const [artistName, setArtistName] = useState(content?.issuer ? content?.issuer : '')
  const [profileImg, setProfileImg] = useState(DEFAULT_IMAGE(600))
  const { goTo } = ScrollToTop();
  const { addAlert } = useAlert();
  const {getArtistName} = useArtist({})

  function buy() {
  /*   toggleModal();
    modalContent(
      <SwerlVoucherPurchase
        type={MUSIC_PURCHASE_OPTIONS.NFT_PURCHASE}
        content={content}
      />
    ); */
  }

  useEffect(() => {
    displayArtistName()
    return () => {}
  }, [content?.issuer])

  const displayArtistName = async() => {
    let issuerName = await getAddressName(content?.issuer)
        if(getObjectLength(issuerName) > 0){
          setArtistName(issuerName?.name)
          setProfileImg(issuerName?.profileUrl + "?s=600&d=mp")
          return
        }
        setArtistName(issuerName)
        return
      
  }

  return (
    <>
      <CardContainer background={content?.color}>
         <div> <SkeletonPurchaseCardImg
          src={content?.cover?.url ? content?.cover?.url : defaultAlbumArt}
        /></div>
        <CardContent>
          <CardTitle>
            <TextLink
            
              style={{ color: themeColors.secondaryColor }}
              onClick={() => goTo("#")
              }
            >{`${content?.name}`}</TextLink>
          </CardTitle>
          <CreatorInfoWrapper>
            <CreatorImg
              src={
                profileImg
              }
            />
            <CreatorInfo>
              <CreatorInfoContent>
                <H6>Created By</H6>
                <InfoContentText
                  onClick={() =>
                    content?.issuer ? goTo(`/u/${content?.issuer}`) :  goTo(`#`)
                  }
                >Sonar Muse Swerl</InfoContentText>
              </CreatorInfoContent>
              <CreatorInfoContent>
                <H6>Price</H6>
                <InfoContentText>
                {content?.price ? `${content?.price} ${content?.currency ? content?.currency : 'XRP' }` : "Pay Your Own Price"}
                </InfoContentText>
              </CreatorInfoContent>
            </CreatorInfo>
          </CreatorInfoWrapper>
          <CreatorInfoWrapper>
            <BuyButton
              onClick={
                () =>{
                  buy()
                }
                  
              }
            >
              <FaRecordVinyl />Purchase Voucher
            </BuyButton><CreatorInfoContent>
              <InfoContentText>
                <TextLink>
            
                </TextLink>
              </InfoContentText>
            </CreatorInfoContent>
          </CreatorInfoWrapper>
        </CardContent>
      </CardContainer>
    </>
  );
};

export default SwerlCollectionCard;
