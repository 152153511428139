import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../contexts/AuthContext';
import { SLIDER_TYPES } from '../../../utils/Enum';
import ContentWrapper from '../../ContentWrapper';
import { NavContainer } from '../../NavLink/NavLinkElements';
import { Slider } from '../../Slider';
import PodcasterAbout from './PodcasterAbout';
import PodcasterEpisodes from './PodcasterEpisodes';
import { PodcasterProfileNav, PodcasterProfileNavLink } from './PodcasterProfileContentElements';
import PodcasterSettings from './PodcasterSettings';
import PodcasterStats from './PodcasterStats';
import usePodcaster from '../../../hooks/usePodcaster';
import { useLocation, useNavigate } from 'react-router-dom';

const PodcasterProfileContent = ({ podcasterId, profile, background, getProfile, gravatarProfile, about }) => {
    const {currentUser} = useAuth()
    const location = useLocation()
    const navigate  = useNavigate()
    const { getPodcasterEpisodes } = usePodcaster({id: podcasterId});
    const [isStats, setStatsActive] = useState(false);
    const [currentTab, setTab] = useState("discography");
    const [isPurchase, setPurchaseActive] = useState(false);
    const [isEpisodes, setEpisodesActive] = useState(true);
    const [isAbout, setAboutActive] = useState(false);
    const [isSettings, setSettingsActive] = useState(false);
    const [isLoaded, setLoaded] = useState(false)
    const [isEpisodesLoaded, setEpisodesLoaded] = useState(false)
    const [podcasterEpisodes, setPodcasterEpisodes] = useState()

    useEffect(() => {
      if(window?.location?.search.includes(`tab=`)){
        const url = new URL(window.location.href);
        const params = new URLSearchParams(url.search)
        setCurrentTab(params.get("tab"))
      }else{
        setCurrentTab()
      }
      return () => {}
    }, []);
  
      const setCurrentTab = (tab) => {
      const url = new URL(window.location.href);
      const params = new URLSearchParams(url.search)
      switch (tab) {
        case "episodes":
          setTab(tab);
          params.set("tab", tab)
          setEpisodesActive(true);
          setPurchaseActive(false);
          setSettingsActive(false);
          setAboutActive(false);
          setStatsActive(false);
          break;
        case "purchase":
          setTab(tab);
          params.set("tab", tab)
          setPurchaseActive(true);
          setEpisodesActive(false);
          setSettingsActive(false);
          setAboutActive(false);
          setStatsActive(false);
          break;
        case "about":
          setTab(tab);
          params.set("tab", tab)
          setEpisodesActive(false);
          setPurchaseActive(false);
          setSettingsActive(false);
          setStatsActive(false);
          setAboutActive(true);
          break;
  
        case "settings":
          params.set("tab", tab)
          setSettingsActive(true);
          setPurchaseActive(false);
          setEpisodesActive(false);
          setAboutActive(false);
          setStatsActive(false);
          setTab(tab);
          break;
  
        case "stats":
          params.set("tab", tab)
          setSettingsActive(false);
          setPurchaseActive(false);
          setEpisodesActive(false);
          setAboutActive(false);
          setStatsActive(true);
          setTab(tab);
          break;
  
        default:
          setTab("episodes");
          params.delete("tab")
          setEpisodesActive(true);
          setPurchaseActive(false);
          setStatsActive(false);
          setSettingsActive(false);
          setAboutActive(false);
          break;
      }
      navigate(`${params.toString().length > 0 ? `?${params.toString()}` : '#'}`,{
        replace:true},
      );
    };

    useEffect(() => {
      setEpisodesLoaded(false);
      getPodcasterEpisodes(podcasterId)
        .then((episodes) => {
          if (episodes?.length > 0) {
            setPodcasterEpisodes(episodes);
          }
          setEpisodesLoaded(true);
        })
        .catch((err) => {
          console.error(err);
          setEpisodesLoaded(true);
        });
        return () => {}
    }, [profile]);

  return (
    <>
    <NavContainer>
        <PodcasterProfileNav>
          <PodcasterProfileNavLink
            background={background}
            active={isEpisodes}
            onClick={() => setCurrentTab("episodes")}
          >
            Episodes
          </PodcasterProfileNavLink>
          <PodcasterProfileNavLink
            background={background}
            active={isAbout}
            onClick={() => setCurrentTab("about")}
          >
            About
          </PodcasterProfileNavLink>
          {/* <PodcasterProfileNavLink
            background={background}
            active={isPurchase}
            onClick={() => setCurrentTab("purchase")}
          >
            Purchase
          </PodcasterProfileNavLink> */}
          {profile?.uid === currentUser?.uid && (
            <>
              <PodcasterProfileNavLink
                background={background}
                active={isSettings}
                onClick={() => setCurrentTab("settings")}
              >
                Settings
              </PodcasterProfileNavLink>
              <PodcasterProfileNavLink
                background={background}
                active={isStats}
                onClick={() => setCurrentTab("stats")}
              >
                Stats
              </PodcasterProfileNavLink>
            </>
          )}
        </PodcasterProfileNav>
      </NavContainer>
      {isEpisodes &&
      <PodcasterEpisodes isLoaded={isEpisodesLoaded} episodes={podcasterEpisodes} name={profile?.name}/>}
      {isAbout && 
      <PodcasterAbout name={profile?.name}
      socialLinks={about.accounts}
      images={about.photos}
      bio={about.bio}
      podcaster={profile}
      tourDates={about?.travel}/>}
      {profile?.uid === currentUser?.uid && isSettings && 
      <PodcasterSettings about={about} podcaster={profile} getProfile={getProfile} podcasterId={podcasterId} podcasterSettings={profile?.podcasterSettings} name={profile?.podcasterName} setTab={setCurrentTab}/>}
      {profile?.uid === currentUser?.uid && isStats && 
      <PodcasterStats name={profile?.name}/>}
    </>
  )
}

export default PodcasterProfileContent