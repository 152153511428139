import  { convertDurationToString, hexToStr, strToHex, timestampToDate } from "../index"
import { db} from "../../components/Authorization/firebase"
import {query, doc, getDoc, collection, getDocs, where, limit, orderBy, updateDoc, onSnapshot, deleteDoc} from 'firebase/firestore'
import { PAYOUT_TYPE } from "../Enum"
import { getUserDetails } from "./userUtils"
import { getUserProfile } from "../../components/GravatarAPI"
import { filterArtistInfo, getArtistAlbums, getArtistSongs } from "./artistUtils"
import axiosInstance from "../../components/Authorization/client"


export const getRecordLabelArtists = async(labelId, setArtists) => {
    return onSnapshot(query(collection(db, "artists"), where("recordLabel", "array-contains", labelId)), async(artistSnapshot) => {
        
        let artists = artistSnapshot.docs.map(a => {
            return {artistId: a?.id,
                ...a.data()} // filter information 
        })
        for(let i = 0; i < artists?.length; i ++){
            let artist = artists[i]
            let gravatar = artist?.email && await getUserProfile(strToHex(artist?.email))
            artists[i] = {
                ...filterArtistInfo(artist),
                gravatarProfile: gravatar ? gravatar : undefined
            }
        }
        setArtists(artists)
    })
}

export const getRecordLabelSongs = async(labelId, setSongs) => {
    return getDocs(query(collection(db, "artists"), where("recordLabel", "array-contains", labelId))).then(async(artistsDoc) => {
        if(artistsDoc.empty){
            return setSongs([])
        }
        let artists = artistsDoc.docs.map(a => {
            return a.id
        })
        let songs = []
        for(let i = 0; i < artists.length; i++){
            songs.push(...(await getArtistSongs(artists[i])))
        }
        setSongs(songs)
    })
}

export const getRecordLabelAlbums = async(labelId, setAlbums) => {
    return getDocs(query(collection(db, "artists"), where("recordLabel", "array-contains", labelId))).then(async(artistsDoc) => {
        if(artistsDoc.empty){
            return setAlbums([])
        }
        let artists = artistsDoc.docs.map(a => {
            return a.id
        })
        let songs = []
        for(let i = 0; i < artists.length; i++){
            songs.push(...(await getArtistAlbums(artists[i])))
        }
        setAlbums(songs)
    })
}


export const getRecordLabelNotifications = (labelId, setNotifications) => {
    return onSnapshot(query(collection(db, "recordLabelNotifications"), where("labelId", "==", labelId), orderBy('createdAt', 'desc')), notifSnapshot => {
        setNotifications(notifSnapshot.docs.map(n => {
            return {notificationId: n?.id,
                ...n.data()}
        }))
    })
}

  export const getRecordLabelFollowers = async(labelId, setFollowers) => {
    onSnapshot(query(collection(db, "recordLabelFollows"), where("labelId", "==", labelId)), async(followSnapshot) => {
        let followers = followSnapshot.docs.map(n => {
            return {followId: n?.id,
                ...n.data(),
            }
        })
        var labelFollowers = [];
    for (var i = 0; i <= followers?.length - 1; i++) {
      let labelfollower = await getUserDetails(
        followers[i].followerId
      );
      if(!labelfollower?.uid){
        await deleteDoc(db,(`recordLabelFollows/${followers[i].followId}`))
      }else{
      labelFollowers.push({
        email: labelfollower.email,
        photoUrl: labelfollower.photoURL,
        uid: labelfollower.uid,
        dateFollowed: followers[i].dateFollowed,
      });}
    }
    setFollowers(labelFollowers)
    })
}

  export const getRecordLabelSubscribers = (labelId, setSubscribers) => {
    onSnapshot(query(collection(db, "recordLabelSubscriptions"), where("labelId", "==", labelId)), async(subscribeSnapshot) => {
        let subscribers = subscribeSnapshot.docs.map(n => {
            return {subscriptionId: n?.id,
                ...n.data(),
            }
        })
        var labelSubscribers = [];
    for (var i = 0; i <= subscribers?.length - 1; i++) {
      let labelSubscriber = await getUserDetails(
        subscribers[i].subscriberId
      );
      if(!labelSubscriber?.uid){
        await deleteDoc(db(`recordLabelSubscriptions/${subscribers[i].subscriptionId}`))
      }else{
      labelSubscribers.push({
        email: labelSubscriber.email,
        photoUrl: labelSubscriber.photoURL,
        uid: labelSubscriber.uid,
        dateSubscribed: subscribers[i].dateSubscribed,
      });}
    }
    setSubscribers(labelSubscribers)
    })
}


export const getRecordLabelPendingMicropayments = async(labelId, setPendingFunds) => {
    onSnapshot(doc(db,`labels/${labelId}`), async (labelSnapshot) => {
      if(labelSnapshot?.exists){
        if(labelSnapshot.data()?.paymentChannel?.channel){
          return await axiosInstance.get(`/api/${labelId}/${labelSnapshot.data()?.paymentChannel?.channel}/getlabelCurrentBalance`).then((funds) => {
            if(funds.data?.funds){
              return setPendingFunds(funds.data.funds + (labelSnapshot.data()?.pendingFunds ? parseInt(labelSnapshot.data()?.pendingFunds) : 0))
            }
            return setPendingFunds(labelSnapshot.data()?.pendingFunds ? labelSnapshot.data()?.pendingFunds : 0)
  
          }).catch((err) => {
            return setPendingFunds(labelSnapshot.data()?.pendingFunds ? labelSnapshot.data()?.pendingFunds : 0)
          })
        }
        return setPendingFunds(labelSnapshot.data()?.pendingFunds ? labelSnapshot.data()?.pendingFunds : 0)
      }
      return setPendingFunds(0)
    })
  }