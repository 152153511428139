import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { hexToRgba, lightOrDark } from './HexToRGBA'

export const Button = styled(Link)`
    border-radius: 50px;
    background: ${props => (props.primary ? props.theme.mainColor : props.theme.secondaryColor)};
    white-space: nowrap;
    padding: ${({ big }) => (big ? '14px 48px' : '12px 38px')};
    color: ${props => (props.dark ? props.theme.secondaryBg : props.theme.mainBg)};
    font-size: ${({ fontBig }) => (fontBig ? '20px' : '16px')};
    outline: none;
    border: none;
    text-decoration: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 2%;
    margin-bottom: 2%;
    transition: all 0.2s ease-in-out;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: ${(props) => (props.primary ? props.theme.secondaryBg : props.theme.mainColor)};
        color: ${(props) => (props.primary ? props.theme.mainColor : props.theme.secondaryBg)};
    }
`

export const Button1 = styled.div`
    border-radius: 50px;
    background: ${props => (props.primary ? props.theme.mainColor : props.theme.secondaryColor)};
    white-space: nowrap;
    padding: ${({ big }) => (big ? '14px 48px' : '12px 38px')};
    color: ${props => (props.dark ? props.theme.secondaryBg : props.theme.mainBg)};
    font-size: ${({ fontBig }) => (fontBig ? '20px' : '16px')};
    outline: none;
    border: none;
    text-decoration: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 2%;
    margin-bottom: 2%;
    transition: all 0.2s ease-in-out;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: ${(props) => (props.primary ? props.theme.secondaryBg : props.theme.mainColor)};
        color: ${(props) => (props.primary ? props.theme.mainColor : props.theme.secondaryBg)};
    }
`

// Button Themes

// Button Themed

export const CustomButton= styled.div`
    border-radius: 50px;
    white-space: nowrap;
    padding: ${({ big }) => (big ? '14px 48px' : '12px 38px')};    
    color: ${(props) => props.background ? props.background : props.theme.secondaryBg};
    background: transparent;
    cursor: pointer;
    display: flex;
    width: auto;
    font-size: ${({ fontBig }) => (fontBig ? '20px' : '16px')};
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    border: none;
    outline: none;
    margin-left: 2%;
    margin-bottom: 2%;
    &:hover {
        transition: all 0.2s ease-in-out;
        color: ${(props) => (props.active ? props.theme.mainColor : props.theme.secondaryColor)};
        border: ${(props) => props.outline ? '1px solid' : 'none'};

    }
     @media screen and (max-width: 480px) {
    margin-top: 0;
    font-size: ${({ fontBig }) => (fontBig ? '16px' : '14px')};
  }
`

export const CustomButton1 = styled.div`
    border-radius: 50px;
    background: ${props => (props.background ? props.background : props.theme.mainColor )};
    white-space: nowrap;
    padding: 2% 4%;
    width: 100%;
    color: ${props => (props?.background ? (lightOrDark(props.background) === 'light' ? 'black': 'white')  : props.theme.mainBg)};
    font-size: 1.2rem;
    outline: none;
    border: none;
    text-decoration: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 2%;
    margin-bottom: 2%;
    transition: all 0.2s ease-in-out;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: ${(props) => (props.background ? props.background: props.theme.secondaryBg)};
        color: ${(props) => (props?.background ? (lightOrDark(props.background) === 'light' ? 'black' : 'white') : props.theme.secondaryColor)};
    }
      @media screen and (max-width: 480px) {
    margin-top: 0;
    font-size: 1rem;
  }
`