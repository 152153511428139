import React, { useState } from 'react'
import ContentWrapper from '../../../ContentWrapper'
import { Nav, NavContainer, NavLink } from '../../../NavLink/NavLinkElements'
import MusicContent from './MusicContent'
import ListenersContent from './ListenersContent'
import PlaylistContent from './PlaylistContent'
import RevenueContent from './RevenueContent'
import ArtistSettings from '../../../ArtistProfile/ArtistContent/ArtistSettings'
import { SelectButton, SelectButtonWrapper } from '../../../Select'

export const ArtistDashboard = ({artist}) => {
    const [currentTab, setCurrentTab] = useState("music")
    const [filter, setFilter] = useState('7days')
    const [isLoaded, setLoaded] = useState(true)
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search)
  const setTab = (tab) => {
    setCurrentTab(tab) 
    setLoaded(false)
  }

  return (
    <>
   <ContentWrapper heading={`${currentTab[0].toUpperCase()}${currentTab.substring(1)}`} subheading={ <SelectButtonWrapper>
        <SelectButton disabled={!isLoaded}
                  onChange={(e) => setFilter(e.target.value)}>
            <option value={"7days"}>7 days</option>
            <option value={"14days"}>2 Weeks</option>
            <option value={"30days"}>4 Weeks</option>
            <option value={"60days"}>2 Months</option>
            <option value={"180days"}>6 Months</option>
            <option value={"365days"}>12 Months</option>
            <option value={"AllTime"}>All Time</option>
        </SelectButton></SelectButtonWrapper>}>
        <NavContainer>
            <Nav>
            <NavLink active={currentTab === "music"} onClick={() => setTab("music")}>Music</NavLink>
            <NavLink active={currentTab === "audience"} onClick={() => setTab("audience")}>Audience</NavLink>
            <NavLink active={currentTab === "playlists"} onClick={() => setTab("playlists")}>Playlists</NavLink>
            <NavLink active={currentTab === "revenue"} onClick={() => setTab("revenue")}>Revenue</NavLink>
        </Nav></NavContainer>
        {currentTab === "music" && <MusicContent filter={filter} isLoaded={isLoaded} setLoaded={setLoaded} artistId={artist?.artistId} userId={artist?.address} />}
        {currentTab === "audience" && <ListenersContent filter={filter} isLoaded={isLoaded} setLoaded={setLoaded}  artistId={artist?.artistId} />}
        {currentTab === "playlists" && <PlaylistContent filter={filter} isLoaded={isLoaded} setLoaded={setLoaded}  artistId={artist?.artistId} />}
        {currentTab === "revenue" && <RevenueContent filter={filter} isLoaded={isLoaded} setLoaded={setLoaded}  artistId={artist?.artistId} />}
    </ContentWrapper>
    </>
  )
}

