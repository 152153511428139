import React, { useState } from 'react'
import { Nav, NavContainer, NavLink } from '../../../NavLink/NavLinkElements'
import ContentWrapper from '../../../ContentWrapper'
import MusicContent from './MusicContent'
import ArtistsContent from './ArtistsContent'
import ListenersContent from './ListenersContent'
import PlaylistsContent from './PlaylistsContent'
import RevenueContent from './RevenueContent'
import RecordLabelSettings from '../../../RecordLabelProfile/RecordLabelProfileContent/RecordLabelSettings'
import { SelectButton, SelectButtonWrapper } from '../../../Select'

const LabelDash = ({label, getProfile}) => {
  const [currentTab, setCurrentTab] = useState("music")
  const [filter, setFilter] = useState('7days')
  const [isLoaded, setLoaded] = useState(true)
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search)

const setTab = (tab) => {
  setCurrentTab(tab) 
  setLoaded(false)
}

return (
  <>
  {!params?.has("settings", "true") && <ContentWrapper heading={`${currentTab[0].toUpperCase()}${currentTab.substring(1)}`} subheading={ <SelectButtonWrapper>
      <SelectButton disabled={!isLoaded}
                onChange={(e) => setFilter(e.target.value)}>
          <option value={"7days"}>7 days</option>
          <option value={"14days"}>2 Weeks</option>
          <option value={"30days"}>4 Weeks</option>
          <option value={"60days"}>2 Months</option>
          <option value={"180days"}>6 Months</option>
          <option value={"365days"}>12 Months</option>
          <option value={"AllTime"}>All Time</option>
      </SelectButton></SelectButtonWrapper>}>
          <NavContainer>
              <Nav>
              <NavLink active={currentTab === "music"} onClick={() => setTab("music")}>Music</NavLink>
              <NavLink active={currentTab === "artists"} onClick={() => setTab("artists")}>Artists</NavLink>
              <NavLink active={currentTab === "audience"} onClick={() => setTab("audience")}>Audience</NavLink>
              <NavLink active={currentTab === "playlists"} onClick={() => setTab("playlists")}>Playlists</NavLink>
              <NavLink active={currentTab === "revenue"} onClick={() => setTab("revenue")}>Revenue</NavLink>
          </Nav></NavContainer>
          {currentTab === "music" && <MusicContent filter={filter} isLoaded={isLoaded} setLoaded={setLoaded}  labelId={label?.labelId} />}
          {currentTab === "artists" && <ArtistsContent filter={filter} isLoaded={isLoaded} setLoaded={setLoaded}  labelId={label?.labelId} />}
          {currentTab === "audience" && <ListenersContent filter={filter} isLoaded={isLoaded} setLoaded={setLoaded}  labelId={label?.labelId} />}
          {currentTab === "playlists" && <PlaylistsContent filter={filter} isLoaded={isLoaded} setLoaded={setLoaded}  labelId={label?.labelId} />}
          {currentTab === "revenue" && <RevenueContent filter={filter} isLoaded={isLoaded} setLoaded={setLoaded}  labelId={label?.labelId} />}
      </ContentWrapper>}
      {params?.has("settings", "true") && <RecordLabelSettings 
                      getProfile={getProfile}
                      podcasterId={label?.labelId}
                      label={label?.profile}
                      labelSettings={label?.profile?.labelSettings}
                      gravatarProfile={label?.gravatarProfile}
                      about={{
                        photos: label?.gravatarProfile?.gallery,
                        accounts: label?.gravatarProfile?.verified_accounts,
                        bio:
                          label?.profile?.labelSettings?.bioType ===
                          "custom"
                            ? label?.profile?.bio
                            : label?.gravatarProfile?.description,
                      }}/>}
      </>
    )
}

export default LabelDash