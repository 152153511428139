import verifiedBadge from '../images/verified_artist_page.png'
import defaultAlbumArt from '../images/sm_album_art_placeholder.png'
import NftBadge from '../images/nft_badge.png'
import SonarNavInvert from '../images/sonar_muse_logo_nav_invert.png'
import { DEFAULT_IMAGE } from '../components/GravatarAPI'
import logo from "../images/sonar_muse_for_artists_logo.png";
import invertedLogo from "../images/sonar_muse_for_artists_logo_invert.png";
import logo2 from "../images/sonar_muse_for_artists_logo_nav.png";
import invertedLogo2 from "../images/sonar_muse_for_artists_logo_nav_invert.png";
import wakeAndBakeVibez from "../images/wake_and_bake_vibez.png"
import artistBadge from "../images/artist_page_owner.png"
import labelRegistration from "../images/label_registration_create_new.png"
import podcastersRegistration from "../images/podcaster_registration.png"
import artistRegistration from "../images/artist_registration.png"
import artistRegistrationClaim from "../images/artist_registration_claim.png"
import artistRegistrationCreate from "../images/artist_registration_create_new.png"
import video from "../videos/video.mp4"
import video1 from "../videos/video1.mp4"
import video2 from "../videos/video2.mp4"
import video3 from "../videos/video3.mp4"


export default function ImageUtils(){

    return {
        verifiedBadge,
        defaultAlbumArt,
        NftBadge,
        artistBadge,
        SonarNavInvert,
        DEFAULT_IMAGE,
        logo,
        logo2,
        invertedLogo,
        invertedLogo2,
        wakeAndBakeVibez,
        artistRegistrationClaim,
        artistRegistrationCreate,
        labelRegistration,
        artistRegistration,
        podcastersRegistration

    }



}

export function VideoUtils(){
    return {
        video,
        video1,
        video2,
        video3,
    }
}


