import React, { useEffect, useMemo, useRef, useState } from "react";
import useMusic from "../../../hooks/useMusic";
import useArtist from "../../../hooks/useArtist";
import { Fade, Zoom } from "react-reveal";
import { Prompt, useNavigate, useLocation, useParams } from "react-router-dom";
import { DEFAULT_IMAGE } from "../../GravatarAPI";
import albumArtPlaceholder from "../../../images/sm_album_art_placeholder.png";
import { hexToRgba } from "../../HexToRGBA";
import {
  storage,
  storageRef,
  uploadBytes,
  getDownloadUrl,
} from "../../Authorization/firebase";
import { useAuth } from "../../../contexts/AuthContext";
import { PulseSpinner, PushSpinner } from "react-spinners-kit";
import { FaToggleOn, FaToggleOff } from "react-icons/fa";
import {
  AlbumArtCoverWrapper,
  AlbumArtPreview,
  AlbumArtPreviewContainer,
  AlbumArtUploadsContainer,
  AlbumDetailsContainer,
  AlbumDetailsSectionTitle,
  AlbumDetailsWrapper,
  AlbumUploadsContainer,
  AlbumUploadsInput,
  ErrorWrapper,
  FormInput,
  FormLabel,
  FormTextArea,
  FormToggle,
  ListContainer,
  MusicTitleInfoContainer,
  MusicUploadAlbumArt,
  MusicUploadContentTitle,
  MusicUploadInfoText,
  MusicUploadsButton,
  MusicUploadsButtonContainer,
  MusicUploadTitleArtContainer,
  MusicUploadTitleTextWrapper,
  SelectButton,
  SongArtCoverWrapper,
  SongDetailsContainer,
  SongDetailsSection,
  SongDetailsSectionTitle,
  SongDetailsText,
  SongDetailsWrapper,
  UploadProgressWrapper,
} from "../MusicUploadsFormElements";

import {
  ALERT_TYPES,
  ARTIST_FORM_INPUT_TYPES,
  ARTIST_FORM_TYPES,
  ARTIST_SEARCH_TYPES,
  TRANSACTION_OPTIONS,
} from "../../../utils/Enum";
import CreativeCommonsSelector from "../../CreativeCommonsSelector";
import { v4 } from "uuid";
import LoadingScreen, { ModalLoadingScreen } from "../../LoadingScreen";
import QRCodeForm from "../../QRCodeForm";
import Modal from "../../Modal";
import {
  containsObject,
  getDuration,
  getObjectLength,
  getUniqueListBy,
  isrcReg,
  newlineFormatter,
} from "../../../utils";
import PageIndexer from "../../PageIndexer";
import { convertFileToBytes } from "../../../utils/nfTokensUploads";
import { ChipList } from "../../Chip/ChipElements";
import { useModal } from "../../Modal/ModalContext";
import OptionModal from "../../OptionModal";
import { HoverOption } from "../../MenuOptions/MenuOptionsElements";
import { MdExplicit } from "react-icons/md";

import Page2 from "./Page2";
import Page1 from "./Page1";
import Page6 from "./Page6";
import Page5 from "./Page5";
import Page4 from "./Page4";
import Page3 from "./Page3";
import { Theme } from "../../WebsiteThemeElements";
import { useAlert } from "../../Alert/AlertContext";
import { FormWrapper } from "../../CustomForm/CustomFormElements";
import NFTStorageTokenForm from "../NFTStorageTokenForm";
import NFTStorageSonarAPIKeyForm from "../../NFTStorageSonarAPIKeyForm";
import { saveSongAsDraft } from "../../../utils/dbUtils/draftsUtils";
import FileUploader from "../../FileUploader";
import Page7 from "./Page7";
import Alert from "../../Alert";
import { Small } from "../../QRCodeForm/QrCodeFormElements";
import { UnavailableFeature } from "../../MenuOptions";
import useRecordLabel from "../../../hooks/useRecordLabel";
import { songNameContainsFeature } from "../../../utils/MusicUtils";

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const SongUploads = ({ info }) => {
  const {
    modalContent,
    isOpen,
    toggleModal,
    noCloseModal,
    openModal,
    closeModal,
  } = useModal();
  const { addAlert } = useAlert();
  const { currentUser, profiles, currentProfile } = useAuth();
  const { searchArtists, validateNFTStorageKey } = useArtist({});
  const {getRecordLabelArtists} = useRecordLabel({})
  const navigate = useNavigate();
  const location = useLocation();
  
  const {
    newSongId,
    createMp3File,
    searchGenres,
    uploadSong,
    getAllGenres,
    generateISRCCode,
    deleteDraft,
  } = useMusic();
  const songNameRef = useRef();
  const songUploadRef = useRef();
  const albumArtRef = useRef();
  const videoFileRef = useRef();
  const additionalFilesRef = useRef();
  const songPriceRef = useRef();
  const releaseDateRef = useRef();
  const genreRef = useRef();
  const isrcRef = useRef();
  const aboutRef = useRef();
  const lyricsRef = useRef();
  const composersRef = useRef();
  const producersRef = useRef();
  const writersRef = useRef();
  const artistNameRef = useRef("");
  const artistIdRef = useRef("");
  const walletAddressRef = useRef("");
  const artistSearchRef = useRef("");
  const artistFullNameRef = useRef("");

  const fArtistNameRef = useRef("");
  const fArtistIdRef = useRef("");
  const fWalletAddressRef = useRef("");
  const fArtistSearchRef = useRef("");
  const fArtistFullNameRef = useRef("");

  const writerNameRef = useRef("");
  const writerIdRef = useRef("");
  const writerWalletAddressRef = useRef("");
  const writerFullNameRef = useRef("");
  const writerSearchRef = useRef("");

  const composerNameRef = useRef("");
  const composerIdRef = useRef("");
  const composerWalletAddressRef = useRef("");
  const composerFullNameRef = useRef("");
  const composerSearchRef = useRef("");

  const producerNameRef = useRef("");
  const producerIdRef = useRef("");
  const producerWalletAddressRef = useRef("");
  const producerFullNameRef = useRef("");
  const producerSearchRef = useRef("");

  const payoutArtistNameRef = useRef("");
  const payoutArtistIdRef = useRef("");
  const payoutArtistWalletAddressRef = useRef("");
  const payoutArtistFullNameRef = useRef("");
  const payoutArtistSearchRef = useRef("");
  const artistPayoutRef = useRef();

  const [percentage, setPercentage] = useState(100);
  const [userRoyalties, setUserRoyalties] = useState(
    info?.userRoyalties
      ? info?.userRoyalties
      : {
          percentage: 10,
          shares: 100,
          price: 0,
          shareReserve: 0,
          ticker: "",
          currency: "XRP",
        }
  );
  const [songInfo, setSongInfo] = useState({});
  const [currency, setCurrency] = useState(
    info?.currency ? (info?.isUserRoyalties ? "XRP" : info?.currency) : "WYM"
  );
  const [currentUserArtist, setUserArtist] = useState(
    info?.userArtist ? info?.userArtist : {}
  );
  const [requestRelease, setReleaseRequest] = useState(false);
  const [artistEditng, setArtistEditing] = useState(false);
  const [fArtistEditng, setFArtistEditing] = useState(false);
  const [writerEditing, setWriterEditing] = useState(false);
  const [composerEditing, setComposerEditing] = useState(false);
  const [producerEditing, setProducerEditing] = useState(false);
  const [payoutEditing, setPayoutEditing] = useState(false);
  const [songId, setSongId] = useState(info?.songId ? info?.songId : undefined);
  const [isCancelSongUpload, setCancelSongUpload] = useState(false);
  const [isCancelImageUpload, setCancelImageUpload] = useState(false);
  const [musicGenres, setMusicGenres] = useState({});
  const [isIsrcValid, setIsrcValid] = useState(false);
  const [albumArt, setAlbumArt] = useState(
    info?.albumArt ? info?.albumArt : {}
  );
  const [videoFile, setVideoFile] = useState(
    info?.videoFile ? info?.videoFile : {}
  );
  const [visualizerFile, setVisualizerFile] = useState(
    info?.visualizerFile ? info?.visualizerFile : {}
  );
  const [storageToken, setToken] = useState();
  const [additionalFiles, setAdditionalFiles] = useState([]);
  const [isExplicit, setExplicit] = useState(
    info?.explicit ? info?.explicit : false
  );
  const [isCover, setCover] = useState(info?.isCover ? info?.isCover : false);
  const [isStreamable, setStreamable] = useState(
    info?.isStreamable ? info?.isStreamable : true
  );
  const [isDownloadable, setDownloadable] = useState(
    info?.isDownloadable ? info?.isDownloadable : false
  );
  const [isSubscription, setSubscription] = useState(
    info?.isSubscription ? info?.isSubscription : false
  );
  const [isMintable, setMintable] = useState(
    info?.isMintable ? info?.isMintable : false
  );
  const [isVideo, setVideo] = useState(info?.isVideo ? info?.isVideo : false);
  const [isVisualizer, setVisualizer] = useState(
    info?.isVisualizer ? info?.isVisualizer : false
  );
  const [isUserRoyalties, setIsUserRoyalties] = useState(
    info?.isUserRoyalties ? info?.isUserRoyalties : false
  );
  const [isPreOrder, setPreOrder] = useState(
    info?.isPreOrder ? info?.isPreOrder : false
  );
  const [songUpload, setSong] = useState(
    info?.songUpload ? info?.songUpload : {}
  );
  const [songName, setName] = useState(info?.songName ? info?.songName : "");
  const [mainGenre, setMainGenre] = useState(
    info?.mainGenre ? info?.mainGenre : undefined
  );
  const [genre, setGenre] = useState("");
  const [subgenre, setSubgenre] = useState("");
  const [mp3Token, setMp3Token] = useState(
    info?.mp3Token ? info?.mp3Token : v4()
  );
  const [subGenres, setSubGenres] = useState(
    info?.subGenres ? info?.subGenres : []
  );
  const [filteredGenres, setGenreFilter] = useState([]);
  const [filteredSubGenres, setSubGenreFilter] = useState([]);
  const [duration, setDuration] = useState(
    info?.duration ? info?.duration : undefined
  );
  const [price, setPrice] = useState(
    info?.isDownloadable ? (info?.price ? info?.price : 1) : undefined
  );
  const [isrcType, setIsrcType] = useState(
    info?.isrcType ? info?.isrcType : "auto"
  );
  const [autoIsrcCode, setAutoIsrcCode] = useState(
    info?.autoIsrcCode ? info?.autoIsrcCode : undefined
  );
  const [releaseDate, setReleaseDate] = useState(
    info?.releaseDate ? info?.releaseDate : undefined
  );
  const [artistSearch, setArtistSearch] = useState();
  const [featArtistSearch, setFArtistSearch] = useState();
  const [composerSearch, setComposerSearch] = useState();
  const [producerSearch, setProducerSearch] = useState();
  const [writerSearch, setWriterSearch] = useState();
  const [payoutSearch, setPayoutSearch] = useState();
  const [artists, setArtists] = useState(info?.artists ? info?.artists : []);
  const [currentArtist, setCurrentArtist] = useState({});
  const [featArtists, setFeatArtists] = useState(
    info?.featuredArtists ? info?.featuredArtists : []
  );
  const [currentFArtist, setCurrentFArtist] = useState({});
  const [isrc, setISRC] = useState(info?.isrc ? info?.isrc : "");
  const [about, setAbout] = useState(info?.about ? info?.about : "");
  const [lyrics, setLyrics] = useState(info?.lyrics ? info?.lyrics : "");
  const [payoutArtists, setPayoutArtists] = useState(
    info?.payoutArtists ? info?.payoutArtists : []
  );
  const [currentPayoutArtist, setCurrentPayoutArtist] = useState({});
  const [composers, setComposers] = useState(
    info?.songCredits?.composers ? info?.songCredits?.composers : []
  );
  const [currentComposer, setCurrentComposer] = useState({});
  const [writers, setWriters] = useState(
    info?.songCredits?.writers ? info?.songCredits?.writers : []
  );
  const [currentWriter, setCurrentWriter] = useState({});
  const [producers, setProducers] = useState(
    info?.songCredits?.producers ? info?.songCredits?.producers : []
  );
  const [currentProducer, setCurrentProducer] = useState({});
  const [license, setLicense] = useState(info?.license ? info?.license : "ARR");
  const [prevChanges, setPrevChanges] = useState({});
  const [errors, setErrors] = useState({});
  const [pageNumber, setPageNum] = useState(1);
  const [isSubmitted, setSubmit] = useState(false);
  const [isLoaded, setLoaded] = useState(false);
  const [hideButtons, setHideButtons] = useState(false);

  useEffect(() => {
    window.addEventListener("beforeunload", function (e) {
      // Cancel the event
      e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
      // Chrome requires returnValue to be set
      e.returnValue = "";
    });

    return () => {};
  });

  useEffect(() => {
    if (!isLoaded) {
      setMainArtist();
    }
    if (!autoIsrcCode || autoIsrcCode?.length === 0) {
      getISRCCode();
    }
    return () => {};
    /*     const params = new URLSearchParams({id: 'test' });
    navigate( {pathname: location.pathname, search: params.toString()} )  
 */
  }, []);

  useEffect(() => {
    if (getObjectLength(songUpload) > 0 || songId) {
      autoSave();
    }
    return () => {};
  }, [songUpload, songId]);

  const autoSave = () => {
    /* const interval = setTimeout(() => {
      if(window.location.pathname === location.pathname){
      updateSongInfo();}
    }, 25000);
    return () => clearInterval(interval); */
  };

  const updateSongInfo = () => {
    //console.log("auto Save")
    setSongInfo({
      songName: songName,
      sender: currentUser?.uid,
      price: price,
      isrc: isrc.trim(),
      artists,
      userArtist: currentUserArtist,
      autoIsrcCode: autoIsrcCode,
      isrcType: isrcType,
      featuredArtists: featArtists,
      mp3Token: mp3Token,
      songCredits: {
        composers,
        producers,
        writers,
      },
      mainGenre: mainGenre,
      subGenres: subGenres,
      isStreamable,
      isDownloadable,
      isSubscription,
      isUserRoyalties,
      isCover,
      type: "draft",
      isMintable: isMintable,
      explicit: isExplicit,
      licensing: license,
      userRoyalties: isUserRoyalties ? userRoyalties : undefined,
      currency: isDownloadable ? (currency ? currency : "XRP") : undefined,
      duration: songUpload.duration,
      payoutArtists: payoutArtists,
      visualizerFile: visualizerFile,
      songUpload: songUpload,
      albumArt: albumArt,
      videoFile: videoFile,
      about: about,
      uploadedBy: currentUser?.uid,
      songId: songId,
      lyrics: lyrics,
      releaseDate: releaseDate,
    });
    if (JSON.stringify(prevChanges) !== JSON.stringify(songInfo)) {
      let prev = songInfo;
      setHideButtons(true);
      return saveSongAsDraft(songInfo)
        .then((data) => {
          setHideButtons(false);
          if (data && !data?.error) {
            setPrevChanges(prev);
            setSongId(data);
            const url = new URL(window.location.href);
            const params = new URLSearchParams(url.search);
            params.set("id", data);
            if (window.location.pathname === location.pathname) {
              navigate(`${location.pathname}?${params.toString()}`, {
                replace: true,
              });
            }
            /*  addAlert({
              title: 'Auto Saved!',
              message: 'Song saved to drafts',
              type: ALERT_TYPES.INFO
            }) */
            return;
          }
        })
        .catch((err) => {
          console.error(err);
          setHideButtons(false);
        });
    }
  };

  const cancelMusicUpload = () => {
    let sInfo = {
      songName: songName,
      sender: currentUser?.uid,
      price: price,
      isrc: isrc.trim(),
      artists,
      autoIsrcCode: autoIsrcCode,
      isrcType: isrcType,
      featuredArtists: featArtists,
      songCredits: {
        composers,
        producers,
        writers,
      },
      mainGenre: mainGenre,
      subGenres: subGenres,
      isStreamable,
      isDownloadable,
      isSubscription,
      isCover,
      type: "draft",
      isMintable: isMintable,
      explicit: isExplicit,
      licensing: license,
      currency: isDownloadable ? (currency ? currency : "XRP") : undefined,
      duration: songUpload.duration,
      payoutArtists: payoutArtists,
      songUpload: songUpload,
      albumArt: albumArt,
      about: about,
      uploadedBy: currentUser?.uid,
      songId: songId,
      lyrics: lyrics,
      releaseDate: releaseDate,
    };
    if (
      songId?.length > 0 ||
      sInfo?.songName?.length > 0 ||
      getObjectLength(sInfo?.songUpload) > 0 ||
      getObjectLength(sInfo?.albumArt) > 0
    ) {
      if (!isOpen) {
        toggleModal();
      }
      modalContent(
        <>
          <OptionModal heading={"Are you sure you want to leave uploading"}>
            <HoverOption
              onClick={() => {
                navigate(-1);
                toggleModal();
                modalContent();
              }}
            >
              Leave
            </HoverOption>
            <HoverOption
              onClick={() => {
                toggleModal();
                modalContent();
              }}
            >
              Cancel
            </HoverOption>
            {songId?.length > 0 && (
              <HoverOption
                onClick={() => {
                  toggleModal();
                  modalContent();
                  navigate(-1);
                  deleteDraft(songId);
                }}
              >
                Leave and Delete Draft
              </HoverOption>
            )}
          </OptionModal>
        </>
      );
      return;
    }
    navigate(-1);
  };

  const setMainArtist = async () => {
    if(currentProfile === 'label'){
     if(artists?.length === 0 && getObjectLength(currentUserArtist) === 0 && !info?.userArtist){
      openModal()
      noCloseModal(<ModalLoadingScreen text={'Loading. Please wait...'}/>)
      return await getRecordLabelArtists(currentUser?.profiles?.label?.labelId).then((labelArtists) => {
        console.log(labelArtists)
        if(labelArtists?.length > 0){
          noCloseModal(<OptionModal onCancel={() => {
            toggleModal()
              modalContent()
              navigate(-1)
          }} heading={'Select Artist'}>
            {labelArtists?.map((artist) => {
              return (
                <HoverOption onClick={() => {
                  setArtists((prev) => {
                    return getUniqueListBy([{ ...artist, exists: true }, ...prev]);
                  });
                  setUserArtist({ ...artist, exists: true });
                  setLoaded(true)
                }}>{artist?.artistName}</HoverOption>
              )
            })}
          </OptionModal>)
        }else{
          noCloseModal(<OptionModal onCancel={() => {
            toggleModal()
              modalContent()
              navigate(-1)
          }} heading={'No Artists Found'} additionalText={'Add artists to Roster before you can upload music'}>
            <HoverOption onClick={() => {
              toggleModal()
              modalContent()
              navigate(-1)}}>OK</HoverOption>
          </OptionModal>)
        }
      }).catch(err => {
        noCloseModal(<OptionModal onCancel={() => {
          toggleModal()
            modalContent()
            navigate(-1)
        }} heading={'Something Went Wrong'}>
          <HoverOption onClick={() => {
            toggleModal()
            modalContent()
            navigate(-1)}}>OK</HoverOption>
            <HoverOption onClick={() => {
              setMainArtist()
            }}>Try Again</HoverOption>
        </OptionModal>)
      })
      
     
    }else{
      if (info?.userArtist){
        if(artists?.length > 0 &&
        !containsObject(artists, { ...info?.userArtist, exists: true })
      ) {
        setArtists((prev) => {
          return getUniqueListBy([{ ...info?.userArtist, exists: true }, ...prev]);
        });
        setUserArtist({ ...info?.userArtist, exists: true });
      }
    }
      else{
        if(artists?.length > 0 && getObjectLength(currentUserArtist) > 0 &&
          !containsObject(artists, { ...currentUserArtist, exists: true })
        ) {
          setArtists((prev) => {
            return getUniqueListBy([{ ...currentUserArtist, exists: true }, ...prev]);
          });
          setUserArtist({ ...currentUserArtist, exists: true });
        }
        if(artists?.length === 0 && getObjectLength(currentUserArtist) > 0){
          setArtists((prev) => {
            return getUniqueListBy([{ ...currentUserArtist, exists: true }, ...prev]);
          });
          setUserArtist({ ...currentUserArtist, exists: true });
        }
        if(artists?.length === 0 && getObjectLength(currentUserArtist) === 0){
          openModal()
      noCloseModal(<ModalLoadingScreen text={'Loading. Please wait...'}/>)
      return await getRecordLabelArtists(currentUser?.profiles?.label?.labelId).then((labelArtists) => {
        console.log(labelArtists)
        if(labelArtists?.length > 0){
          noCloseModal(<OptionModal onCancel={() => {
            toggleModal()
              modalContent()
              navigate(-1)
          }} heading={'Select Artist'}>
            {labelArtists?.map((artist) => {
              return (
                <HoverOption onClick={() => {
                  setArtists((prev) => {
                    return getUniqueListBy([{ ...artist, exists: true }, ...prev]);
                  });
                  setUserArtist({ ...artist, exists: true });
                  setLoaded(true)
                }}>{artist?.artistName}</HoverOption>
              )
            })}
          </OptionModal>)
        }else{
          noCloseModal(<OptionModal onCancel={() => {
            toggleModal()
              modalContent()
              navigate(-1)
          }} heading={'No Artists Found'} additionalText={'Add artists to Roster before you can upload music'}>
            <HoverOption onClick={() => {
              toggleModal()
              modalContent()
              navigate(-1)}}>OK</HoverOption>
          </OptionModal>)
        }
      }).catch(err => {
        noCloseModal(<OptionModal onCancel={() => {
          toggleModal()
            modalContent()
            navigate(-1)
        }} heading={'Something Went Wrong'}>
          <HoverOption onClick={() => {
            toggleModal()
            modalContent()
            navigate(-1)}}>OK</HoverOption>
            <HoverOption onClick={() => {
              setMainArtist()
            }}>Try Again</HoverOption>
        </OptionModal>)
      })
        }
      }
    }
    setLoaded(true);
  }else{
      return await searchArtists(ARTIST_SEARCH_TYPES.ADDRESS, currentUser?.uid).then(
        (artist) => {
          if (artists?.length === 0) {
            setUserArtist({
              ...artist,
              exists: true,
            });
            setArtists((prev) => {
              return getUniqueListBy([{ ...artist, exists: true }]);
            });
          }
          if (
            artists?.length > 0 &&
            !containsObject(artists, { ...artist, exists: true })
          ) {
            setArtists((prev) => {
              return getUniqueListBy([{ ...artist, exists: true }, ...prev]);
            });
            setUserArtist({ ...artist, exists: true });
          }else{
            setArtists((prev) => {
              return getUniqueListBy([{ ...artist, exists: true }, ...prev]);
            });
            setUserArtist({ ...artist, exists: true });
          }
          setLoaded(true);
        }
      );
    }
    
  };

  const getISRCCode = async () => {
    return await generateISRCCode()
      .then((code) => {
        if (!code?.error || code !== false) {
          setAutoIsrcCode(code);
          setISRC(code);
        } else {
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const nextPage = () => {
    const currPage = pageNumber;
    if (currPage < 7) {
      setPageNum(currPage + 1);
    }
    window.scrollTo(0, 100);
  };

  const prevPage = () => {
    const currPage = pageNumber;
    if (currPage > 0) {
      setPageNum(currPage - 1);
    }
  };

  const selectArtist = (type, artist) => {
    let a = [];

    switch (type) {
      case ARTIST_FORM_TYPES.MAIN_ARTIST:
        a = artists;
        setCurrentArtist({ ...artist, exists: true });
        a.push({ ...artist, exists: true });
        setArtists(a);
        setArtistSearch();
        break;
      case ARTIST_FORM_TYPES.FEATURE:
        a = featArtists;
        setCurrentFArtist({ ...artist, exists: true });
        a.push({ ...artist, exists: true });
        setFeatArtists(a);
        setFArtistSearch();
        break;
      case ARTIST_FORM_TYPES.COMPOSER:
        a = composers;
        setCurrentComposer({ ...artist, exists: true });
        a.push({ ...artist, exists: true });
        setComposers(a);
        setComposerSearch();
        break;
      case ARTIST_FORM_TYPES.PRODUCER:
        a = producers;
        setCurrentProducer({ ...artist, exists: true });
        a.push({ ...artist, exists: true });
        setProducers(a);
        setProducerSearch();
        break;
      case ARTIST_FORM_TYPES.WRITER:
        a = writers;
        setCurrentWriter({ ...artist, exists: true });
        a.push({ ...artist, exists: true });
        setWriters(a);
        setWriterSearch();
        break;
      case ARTIST_FORM_TYPES.PAYOUT:
        a = payoutArtists;
        setCurrentPayoutArtist({
          ...artist,
          payoutPercentage: 0,
        });
        a.push({ ...artist, payoutPercentage: 0 });
        setPayoutArtists(a);

        setPayoutSearch();
        break;
      default:
        break;
    }
    clearArtistForm(type);
  };

  //Add specific artist type to list
  const addArtist = (type) => {
    let a = [];

    switch (type) {
      case ARTIST_FORM_TYPES.MAIN_ARTIST:
        a = artists;
        a.push({ ...currentArtist, exists: false });
        setArtists(a);
        setCurrentArtist({});
        break;
      case ARTIST_FORM_TYPES.FEATURE:
        a = featArtists;
        a.push({ ...currentFArtist, exists: false });
        setFeatArtists(a);
        setCurrentFArtist({});
        break;
      case ARTIST_FORM_TYPES.COMPOSER:
        a = composers;
        a.push({ ...currentComposer, exists: false });
        setComposers(a);
        setCurrentComposer({});
        break;
      case ARTIST_FORM_TYPES.PRODUCER:
        a = producers;
        a.push({ ...currentProducer, exists: false });
        setProducers(a);
        setCurrentProducer({});
        break;
      case ARTIST_FORM_TYPES.WRITER:
        a = writers;
        a.push({ ...currentWriter, exists: false });
        setWriters(a);
        setCurrentWriter({});
        break;
      /* case ARTIST_FORM_TYPES.PAYOUT:
      a = writers
      a.push({...currentPayoutArtist, exists: false});
      setPayoutArtists(a);
      setCurrentPayoutArtist()
        break; */
      default:
        break;
    }
  };

  const clearArtistForm = (type) => {
    switch (type) {
      case ARTIST_FORM_TYPES.MAIN_ARTIST:
        setArtistSearch();
        setCurrentArtist({});
        if (artistSearchRef?.current?.value) {
          artistSearchRef.current.value = "";
        }
        if (artistIdRef?.current?.value) {
          artistIdRef.current.value = "";
        }
        if (artistNameRef?.current?.value) {
          artistNameRef.current.value = "";
        }
        if (walletAddressRef?.current?.value) {
          walletAddressRef.current.value = "";
        }
        if (artistFullNameRef.current?.value) {
          artistFullNameRef.current.value = "";
        }
        break;
      case ARTIST_FORM_TYPES.FEATURE:
        setFArtistSearch();
        setCurrentFArtist({});
        if (fArtistSearchRef?.current?.value) {
          fArtistSearchRef.current.value = "";
        }
        if (fArtistIdRef?.current?.value) {
          fArtistIdRef.current.value = "";
        }
        if (fArtistNameRef?.current?.value) {
          fArtistNameRef.current.value = "";
        }
        if (fWalletAddressRef?.current?.value) {
          fWalletAddressRef.current.value = "";
        }
        if (fArtistFullNameRef.current?.value) {
          fArtistFullNameRef.current.value = "";
        }
        break;
      case ARTIST_FORM_TYPES.COMPOSER:
        setComposerSearch();
        setCurrentComposer({});
        if (composerSearch?.current?.value) {
          composerSearch.current.value = "";
        }
        if (composerIdRef?.current?.value) {
          composerIdRef.current.value = "";
        }
        if (composerNameRef?.current?.value) {
          composerNameRef.current.value = "";
        }
        if (composerWalletAddressRef?.current?.value) {
          composerWalletAddressRef.current.value = "";
        }
        if (composerFullNameRef.current?.value) {
          composerFullNameRef.current.value = "";
        }

        break;
      case ARTIST_FORM_TYPES.PRODUCER:
        setProducerSearch();
        setCurrentProducer({});
        if (producerSearchRef?.current?.value) {
          producerSearchRef.current.value = "";
        }
        if (producerIdRef?.current?.value) {
          producerIdRef.current.value = "";
        }
        if (producerNameRef?.current?.value) {
          producerNameRef.current.value = "";
        }
        if (producerWalletAddressRef?.current?.value) {
          producerWalletAddressRef.current.value = "";
        }
        if (producerFullNameRef.current?.value) {
          producerFullNameRef.current.value = "";
        }
        break;
      case ARTIST_FORM_TYPES.WRITER:
        setCurrentWriter({});
        setWriterSearch();
        if (writerSearchRef?.current?.value) {
          writerSearchRef.current.value = "";
        }
        if (writerIdRef?.current?.value) {
          writerIdRef.current.value = "";
        }
        if (writerNameRef?.current?.value) {
          writerNameRef.current.value = "";
        }
        if (writerWalletAddressRef?.current?.value) {
          writerWalletAddressRef.current.value = "";
        }
        if (writerFullNameRef.current?.value) {
          writerFullNameRef.current.value = "";
        }
        break;
      case ARTIST_FORM_TYPES.PAYOUT:
        setCurrentPayoutArtist();
        setPayoutSearch();
        if (payoutArtistSearchRef?.current?.value) {
          payoutArtistSearchRef.current.value = "";
        }
        if (payoutArtistIdRef?.current?.value) {
          payoutArtistIdRef.current.value = "";
        }
        if (payoutArtistNameRef?.current?.value) {
          payoutArtistNameRef.current.value = "";
        }
        if (payoutArtistWalletAddressRef?.current?.value) {
          payoutArtistWalletAddressRef.current.value = "";
        }
        if (payoutArtistFullNameRef.current?.value) {
          payoutArtistFullNameRef.current.value = "";
        }
        if (artistPayoutRef.current?.value) {
          artistPayoutRef.current.value = 0.1;
        }
        break;
      default:
        break;
    }
  };

  const validateSongInfo = () => {
    let vErrors = {};
    if ((songName && songName?.trim() === "") || !songName) {
      vErrors = {
        ...vErrors,
        songName: "You can't release a song without a name",
      };
    }
    if (
      !songUpload ||
      Object.keys(songUpload).length === 0 ||
      !songUpload?.url ||
      (songUpload?.progress > 0 && songUpload?.progress < 100)
    ) {
      vErrors = {
        ...vErrors,
        songUpload: "Upload a song before releasing",
      };
    }
    if (
      ((visualizerFile?.progress > 0 && visualizerFile?.progress < 100) && !visualizerFile?.url) ||
      ((videoFile?.progress > 0 && videoFile?.progress < 100) && !videoFile?.url)
    ) {
      vErrors = {
        ...vErrors,
        visuals: "Visuals still uploading...",
      };
    }
    if (!albumArt || !albumArt?.url || albumArt?.url === false) {
      vErrors = {
        ...vErrors,
        albumArt: "Upload album art before releasing ",
      };
    }
    if (
      (isStreamable &&
        getObjectLength(payoutArtists) > 0 &&
        payoutArtists?.some((artist) => artist.payoutPercentage === 0)) ||
      (isStreamable && getObjectLength(payoutArtists) === 0)
    ) {
      vErrors = {
        ...vErrors,
        payoutArtists: "Please enter payout percentage for Artists",
      };
    }
    if (getObjectLength(payoutArtists) > 0 && payoutArtists?.balance > 100) {
      vErrors = {
        ...vErrors,
        payoutArtists: "payouts exceed 100%. please redistribute payouts",
      };
    }

    if (!mainGenre || Object.keys(mainGenre)?.length === 0 || !mainGenre?.id) {
      vErrors = {
        ...vErrors,
        genre: "Select a main genre",
      };
    }
    if (isUserRoyalties && getObjectLength(userRoyalties) > 0) {
      let royaltyErrors = {};
      if (userRoyalties?.ticker.trim().length < 3) {
        royaltyErrors = {
          ...royaltyErrors,
          ticker: "Invalid Ticker. Please enter a valid tick. min 3 characters",
        };
      }
      if (userRoyalties.percentage < 10 || userRoyalties.percentage > 50) {
        royaltyErrors = {
          ...royaltyErrors,
          percentage:
            "Invalid royalty share percentage. please input value between 10% - 50%",
        };
      }
      if (userRoyalties.shareReserve < 0 || userRoyalties.shareReserve > 50) {
        royaltyErrors = {
          ...royaltyErrors,
          reserve:
            "Invalid royalty share reserve. Please enter a valid reserve amount of 50 or less",
        };
      }
      if (getObjectLength(royaltyErrors) > 0) {
        vErrors = {
          ...vErrors,
          userRoyalty: royaltyErrors,
        };
      }
    }
    console.log("errors: ", vErrors);
    if (getObjectLength(vErrors) > 0) {
      setErrors((prev) => {
        return { ...prev, ...vErrors };
      });
      return false;
    } else {
      return true;
    }
  };

  const getDuration = (src) => {
    var reader = new FileReader();
    let audio = new Audio();
    let duration;
    reader.readAsDataURL(src);
    reader.onload = function (e) {
      audio.src = e.target.result;
      return audio.addEventListener(
        "loadedmetadata",
        function () {
          // Obtain the duration in seconds of the audio file (with milliseconds as well, a float value)
          duration = audio.duration;
          setSong((prev) => {
            return { ...prev, duration: duration };
          });
          // example 12.3234 seconds

          // Alternatively, just display the integer value with
          // parseInt(duration)
          // 12 seconds
        },
        false
      );
    };
  };

  /* const uploadFiles = async () => {
    let art;
    let song;
    modalContent(
      <>
        <OptionModal
          heading={"Uploading files to your NFT.Storage account (IPFS)"}
        >
          <div style={{ justifyContent: "center", marginLeft: " 50%" }}>
            <SongDetailsText>Uploading Song. Please Wait</SongDetailsText>
            <PulseSpinner loading={true} />
          </div>
        </OptionModal>
      </>
    );
    await uploadToIPFS(storageToken, songUpload)
      .then((data) => {
        song = data;
        modalContent(
          <>
            <OptionModal
              heading={"Uploading files to your NFT.Storage account (IPFS)"}
            >
              <div style={{ justifyContent: "center", marginLeft: " 50%" }}>
                <SongDetailsText>
                  Uploading Artwork. Please Wait
                </SongDetailsText>
                <PulseSpinner loading={true} />
              </div>
            </OptionModal>
          </>
        );
        uploadToIPFS(storageToken, albumArt)
          .then((data1) => {
            art = data1;

            if (song && art) {
              setSongInfo((prev) => {
                return {
                  ...prev,
                  ipfs: {
                    albumArt: {
                      uri: art,
                      name: albumArt.name,
                      type: albumArt.file.type,
                    },
                    song: {
                      uri: song,
                      name: songUpload.name,
                      type: songUpload.file.type,
                    },
                  },
                };
              });
              toggleModal();
              modalContent();
              setReleaseRequest(true);
            }
          })
          .catch((err) => {
            console.error(err);
            modalContent(
              <>
                <OptionModal>
                  <SongDetailsText
                    error
                  >{`Please provide us with your NFT.Storage api token. We will not store this`}</SongDetailsText>
                  <FormInput
                    name={"storageToken"}
                    onChange={handleInput}
                    value={storageToken}
                  />
                  <HoverOption onClick={() => uploadFiles()}>
                    Continue
                  </HoverOption>
                </OptionModal>
              </>
            );
          });
      })
      .catch((err) => {
        console.error(err);
        modalContent(
          <>
            <OptionModal>
              <SongDetailsText
                error
              >{`Please provide us with your NFT.Storage api token. We will not store this`}</SongDetailsText>
              <FormInput
                name={"storageToken"}
                onChange={handleInput}
                value={storageToken}
              />
              <HoverOption onClick={() => uploadFiles()}>Continue</HoverOption>
            </OptionModal>
          </>
        );
      });
  }; */

  const cancelUpload = () => {
    modalContent();
    toggleModal();
    addAlert({
      title: "Song Upload",
      message: "Upload Cancelled",
      type: ALERT_TYPES.WARNING,
    });
    setSubmit(false);
    setHideButtons(false);
    setReleaseRequest(false);
  };

  const onSuccess = (id) => {
    modalContent();
    toggleModal();
    setReleaseRequest(false);
    setSubmit(true);
    setHideButtons(false);
    setSongId(id);
    setErrors({ message: "Song Successfully Released" });
  };

  const onFailure = () => {
    modalContent();
    toggleModal();
    setSubmit(false);
    setHideButtons(false);
    setErrors({
      message: "Your submission was not successful, please try again",
    });
  };

  const uploadData = (type) => {
    let songData = {};
    setHideButtons(true);
    try {
      let err = errors;
      if (err?.message) {
        delete err.message;
      }
      setErrors((prev) => {
        return { ...err };
      });
      if (songName && songName?.trim()?.length > 0 && artists?.length > 0) {
        switch (type) {
          case "draft":
            songData = {
              songName: songName,
              sender: currentUser?.uid,
              price: price,
              isrc: isrc.trim(),
              artists,
              autoIsrcCode: autoIsrcCode,
              isrcType: isrcType,
              featuredArtists: featArtists,
              songCredits: {
                composers,
                producers,
                writers,
              },
              mainGenre: mainGenre,
              subGenres: subGenres,
              isStreamable,
              isDownloadable,
              isSubscription,
              isCover,
              type: "draft",
              isMintable: isMintable,
              isUserRoyalties: isUserRoyalties,
              mp3Token: mp3Token,
              explicit: isExplicit,
              license: license,
              currency: isDownloadable
                ? currency
                  ? currency
                  : "XRP"
                : undefined,
              userRoyalties: isUserRoyalties ? userRoyalties : undefined,
              duration: duration,
              payoutArtists: payoutArtists,
              songUpload: songUpload,
              albumArt: albumArt,
              visualizerFile: visualizerFile,
              videoFile: videoFile,
              about: about,
              uploadedBy: currentUser?.uid,
              songId: songId,
              lyrics: lyrics,
              releaseDate: releaseDate,
            };
            setSongInfo({
              songName: songName,
              sender: currentUser?.uid,
              price: price,
              isrc: isrc.trim(),
              artists,
              autoIsrcCode: autoIsrcCode,
              isrcType: isrcType,
              featuredArtists: featArtists,
              songCredits: {
                composers,
                producers,
                writers,
              },
              mainGenre: mainGenre,
              subGenres: subGenres,
              isStreamable,
              isDownloadable,
              isSubscription,
              isUserRoyalties,
              isCover,
              type: "draft",
              isMintable: isMintable,
              explicit: isExplicit,
              license: license,
              currency: isDownloadable
                ? currency
                  ? currency
                  : "XRP"
                : undefined,
              userRoyalties: isUserRoyalties ? userRoyalties : undefined,
              duration: duration,
              payoutArtists: payoutArtists,
              songUpload: songUpload,
              albumArt: albumArt,
              videoFile: videoFile,
              about: about,
              uploadedBy: currentUser?.uid,
              songId: songId,
              lyrics: lyrics,
              releaseDate: releaseDate,
            });
            addAlert({
              title: "Saving Draft",
              message: "Saving song to drafts...",
              type: ALERT_TYPES.INFO,
            });
            return saveSongAsDraft(songData)
              .then((data) => {
                if (data) {
                  setHideButtons(false);
                  setSongId(data);
                  setPrevChanges(songInfo);
                  const url = new URL(window.location.href);
                  const params = new URLSearchParams(url.search);
                  params.set("id", data);
                  navigate(`${location.pathname}?${params.toString()}`, {
                    replace: true,
                  });
                  addAlert({
                    title: "Draft Saved!",
                    message: "Song saved to drafts",
                    type: ALERT_TYPES.SUCCESS,
                  });
                  setErrors({ message: "Draft saved!" });
                  return;
                }
                setHideButtons(false);
                addAlert({
                  title: "Draft Error",
                  message: "Failed to save to drafts",
                  type: ALERT_TYPES.DANGER,
                });
              })
              .catch((err) => {
                console.error(err);
                setHideButtons(false);
                addAlert({
                  title: "Draft Error",
                  message: "Failed to save to drafts",
                  type: ALERT_TYPES.DANGER,
                });
              });
            break;
          case "publish":
            openModal();
            noCloseModal(
              <ModalLoadingScreen text={"Validating Upload. Please Wait..."} />
            );
            if (validateSongInfo()) {
              //setSongInfo();
              toggleModal();
              noCloseModal(
                <>
                  {" "}
                  <QRCodeForm
                    heading={`Release of ${songName} by ${artists[0].artistName}`}
                    body={{
                      songName: songName.trim(),
                      sender: currentUser?.uid,
                      memo: `${currentUser?.uid} is submitting the song ${songName} to be released`,
                      instruction: `${currentUser?.uid} is submitting the song ${songName} to be released`,
                      price: price,
                      isrc:
                        isrcType === "custom"
                          ? isrc?.trim()?.length > 0
                            ? isrc?.trim()
                            : autoIsrcCode
                          : autoIsrcCode,
                      artists,
                      featuredArtists: featArtists,
                      songCredits: {
                        composers,
                        producers,
                        writers,
                      },
                      genre: mainGenre,
                      subGenres: subGenres,
                      isStreamable,
                      isDownloadable,
                      isSubscription,
                      isUserRoyalties,
                      isCover,
                      currency: isDownloadable
                        ? currency
                          ? currency
                          : "WYM"
                        : undefined,
                      isMintable: isMintable,
                      explicit: isExplicit ? isExplicit : undefined,
                      userRoyalties: isUserRoyalties
                        ? userRoyalties
                        : undefined,
                      royalties: payoutArtists,
                      songUrl: songUpload.url,
                      duration: duration ? duration : songUpload?.duration,
                      songRef: songUpload.ref,
                      mp3Url: songUpload?.mp3Url,
                      mp3Ref: songUpload?.mp3Ref,
                      albumArt: albumArt.url,
                      albumArtRef: albumArt.ref,
                      video:
                        getObjectLength(videoFile) > 0
                          ? {
                              url: videoFile?.url,
                              duration: videoFile?.duration,
                              ref: videoFile?.ref,
                            }
                          : undefined,
                      visualizer:
                        getObjectLength(visualizerFile) > 0
                          ? {
                              url: visualizerFile?.url,
                              duration: visualizerFile?.duration,
                              ref: visualizerFile?.ref,
                            }
                          : undefined,
                      videoResolutions:
                        getObjectLength(videoFile) > 0 &&
                        videoFile?.videoResolutions?.length > 0
                          ? videoFile?.videoResolutions
                          : undefined,
                      about: about.trim(),
                      uploadedBy: currentUser?.uid,
                      songId: songId,
                      licensing: license,
                      lyrics: lyrics.trim(),
                      releaseDate: releaseDate ? releaseDate : undefined,
                      displayDate: releaseDate
                        ? new Date(releaseDate)
                        : new Date(),
                      type: "publish",
                    }}
                    cancelQr={cancelUpload}
                    onSuccess={onSuccess}
                    onFail={onFailure}
                    type={TRANSACTION_OPTIONS.MUSIC_UPLOAD.SONG}
                  ></QRCodeForm>
                </>
              );
            } else {
              closeModal();
              modalContent();
              setHideButtons(false);
              setErrors((prev) => {
                return {
                  ...prev,
                  message:
                    "Unable to release song. Please review your song information",
                };
              });
            }
            break;
          default:
            break;
        }
      } else {
        if (!artists || artists?.length === 0) {
          setErrors((prev) => {
            return { ...prev, artists: "No artist selected for song" };
          });
        }
        if (!songName || songName?.trim() === "") {
          setErrors((prev) => {
            return {
              ...prev,
              songName: "You can't release a song witout a name",
            };
          });
        }
        setErrors((prev) => {
          return {
            ...prev,
            message: "Unable to upload. Please review your song information",
          };
        });
      }
    } catch (err) {
      console.error(err);
      setHideButtons(false);
      addAlert({
        title: "Draft Error",
        message: "Failed to save to drafts",
        type: ALERT_TYPES.DANGER,
      });
    }
  };

  const validateArtistInput = (currentArtist) => {
    if (currentArtist && currentArtist?.artistName?.trim() !== "") {
      return true;
    }
    return false;
  };

  const validateArtistInfo = (id, type) => {
    switch (type) {
      case ARTIST_FORM_TYPES.MAIN_ARTIST:
        if (validateArtistInput(currentArtist)) {
          artistEditng ? editArtist(id, type) : addArtist(type);
        } else {
        }

        break;
      case ARTIST_FORM_TYPES.FEATURE:
        if (validateArtistInput(currentFArtist)) {
          fArtistEditng ? editArtist(id, type) : addArtist(type);
        } else {
        }
        break;
      case ARTIST_FORM_TYPES.COMPOSER:
        if (validateArtistInput(currentComposer)) {
          composerEditing ? editArtist(id, type) : addArtist(type);
        } else {
        }
        break;
      case ARTIST_FORM_TYPES.PRODUCER:
        if (validateArtistInput(currentProducer)) {
          producerEditing ? editArtist(id, type) : addArtist(type);
        } else {
        }
        break;
      case ARTIST_FORM_TYPES.WRITER:
        if (validateArtistInput(currentWriter)) {
          writerEditing ? editArtist(id, type) : addArtist(type);
        } else {
        }
        break;
      case ARTIST_FORM_TYPES.PAYOUT:
        let total = 0;
        payoutArtists.map((a) => {
          total = parseFloat(a.payoutPercentage) + parseFloat(total);
          return;
        });
        //console.log(total)
        if (
          currentPayoutArtist?.payoutPercentage <= 100 &&
          currentPayoutArtist?.payoutPercentage > 0 &&
          parseFloat(total) +
            parseFloat(currentPayoutArtist.payoutPercentage) <=
            100
        ) {
          editArtist(id, type);
          clearArtistForm(type);
        } else {
          setCurrentArtist((prev) => {
            return { ...prev, error: "Invalid payout split" };
          });
        }
        break;
      default:
        break;
    }
    setCurrentArtist({});
    clearArtistForm(type);
  };

  const editArtist = (id, type) => {
    let a = {};
    let updatedArtists = [];

    switch (type) {
      case ARTIST_FORM_TYPES.MAIN_ARTIST:
        a = artists;

        updatedArtists = a.map((artist, index) =>
          index === id ? currentArtist : artist
        );

        setArtists(updatedArtists);
        setArtistEditing(false);
        break;
      case ARTIST_FORM_TYPES.FEATURE:
        a = featArtists;

        updatedArtists = a.map((artist, index) =>
          index === id ? currentFArtist : artist
        );

        setFeatArtists(updatedArtists);
        setFArtistEditing(false);
        break;
      case ARTIST_FORM_TYPES.COMPOSER:
        a = composers;

        updatedArtists = a.map((artist, index) =>
          index === id ? currentComposer : artist
        );

        setComposers(updatedArtists);
        setComposerEditing(false);
        break;
      case ARTIST_FORM_TYPES.PRODUCER:
        a = producers;

        updatedArtists = a.map((artist, index) =>
          index === id ? currentProducer : artist
        );

        setProducers(updatedArtists);
        setProducerEditing(false);
        break;
      case ARTIST_FORM_TYPES.WRITER:
        a = writers;

        updatedArtists = a.map((artist, index) =>
          index === id ? currentWriter : artist
        );

        setWriters(updatedArtists);
        setWriterEditing(false);
        break;
      case ARTIST_FORM_TYPES.PAYOUT:
        a = payoutArtists;

        updatedArtists = a.map((artist, index) =>
          index === id ? currentPayoutArtist : artist
        );

        setPayoutArtists(updatedArtists);
        setPayoutEditing(false);
        break;
      default:
        break;
    }

    clearArtistForm(type);
  };

  const removeArtist = (id, type) => {
    let a = [];
    let updatedArtists = [];
    switch (type) {
      case ARTIST_FORM_TYPES.MAIN_ARTIST:
        a = artists;
        updatedArtists = a.filter((artist, index) => {
          if (index !== id) return artist;
        });
        if (containsObject(currentUserArtist, updatedArtists)) {
          setArtists(updatedArtists);
        } else {
          setArtists([currentUserArtist, ...updatedArtists]);
        }
        setArtistEditing(false);
        break;
      case ARTIST_FORM_TYPES.FEATURE:
        a = featArtists;
        updatedArtists = a.filter((artist, index) => {
          if (index !== id) return artist;
        });
        setFeatArtists(updatedArtists);
        setFArtistEditing(false);
        break;
      case ARTIST_FORM_TYPES.COMPOSER:
        a = composers;
        updatedArtists = a.filter((artist, index) => {
          if (index !== id) return artist;
        });
        setComposers(updatedArtists);
        setComposerEditing(false);
        break;
      case ARTIST_FORM_TYPES.PRODUCER:
        a = producers;
        updatedArtists = a.filter((artist, index) => {
          if (index !== id) return artist;
        });
        setProducers(updatedArtists);
        setProducerEditing(false);
        break;
      case ARTIST_FORM_TYPES.WRITER:
        a = writers;
        updatedArtists = a.filter((artist, index) => {
          if (index !== id) return artist;
        });
        setWriters(updatedArtists);
        setWriterEditing(false);

        break;
      case ARTIST_FORM_TYPES.PAYOUT:
        a = payoutArtists;
        updatedArtists = a.filter((artist, index) => {
          if (index !== id) return artist;
        });
        setPayoutArtists(updatedArtists);
        setPayoutEditing(false);
        break;
      default:
        break;
    }

    clearArtistForm(type);
  };

  const addGenre = (musicGenre) => {
    const g = subGenres;
    setGenre();
    setSubgenre();
    if (
      g.findIndex((sG) => sG.id === musicGenre.id) === -1 &&
      g.length - 1 <= 3 &&
      musicGenre?.genreId
    ) {
      g.push(musicGenre);
      setSubGenres(g);
    } else if (!musicGenre?.genreId) {
      setMainGenre(musicGenre);
    } else if (musicGenre?.genreId && g.length - 1 === 2) {
      g[2] = musicGenre;
      setSubGenres(g);
    }
    setSubgenre();
    setGenreFilter();
  };

  const removeGenre = (id, subGenre) => {
    const g = subGenres;
    if (subGenre) {
      const updatedGenres = g.filter((sGenre, index) => {
        if (id !== index && subGenre.id !== sGenre.id) return sGenre;
      });
      setSubGenres(updatedGenres);
    } else {
      setMainGenre();
    }
  };

  const setCreativeCommons = (id) => {
    setLicense(id);
  };

  const handleToggle = (type) => {
    switch (type) {
      case "explicit":
        setExplicit(!isExplicit);
        break;
      case "streamable":
        let streamable = !isStreamable;
        setStreamable(streamable);
        if (streamable) {
          setSubscription(false);
        }

        break;
      case "downloadable":
        let downloadable = !isDownloadable;
        if (downloadable) {
          setSubscription(false);
        } else {
          setPreOrder(false);
        }
        setDownloadable(downloadable);
        break;

      case "video":
        let video = !isVideo;
        setVideo(video);
        break;

      case "visualizer":
        let visualizer = !isVisualizer;
        setVisualizer(visualizer);
        break;
      case "preorder":
        let preOrder = !isPreOrder;
        if (preOrder) {
          setDownloadable(true);
        }
        setPreOrder(preOrder);
        break;

      case "cover":
        let cover = !isCover;
        setCover(cover);

        break;

      case "royalties":
        let royalties = !isUserRoyalties;
        if (isUserRoyalties === false) {
          openModal();
          modalContent(
            <OptionModal
              onCancel={() => {
                closeModal();
                modalContent();
              }}
              heading={"Enable User Royalties Share"}
              additionalText={
                "Allow your supporters to invest and earn from your music sales and royalty streams."
              }
            >
              <Small>Note: Once enabled, it cannot be disabled.</Small>
              <HoverOption
                onClick={() => {
                  setIsUserRoyalties(true);
                  if (royalties) {
                    setSubscription(false);
                    setStreamable(true);
                  }
                  if (isDownloadable && royalties) {
                    setCurrency("XRP");
                  }
                  closeModal();
                  modalContent();
                }}
              >
                OK
              </HoverOption>
            </OptionModal>
          );
        } else {
          setIsUserRoyalties(false);
        }
        /*  let royalties = !isUserRoyalties
          setIsUserRoyalties(royalties)
          if(royalties){
          setSubscription(false);
          setStreamable(true);}
          if(isDownloadable && royalties){
            setCurrency("XRP")
          } */
        break;

      case "subscription":
        let subscription = !isSubscription;
        setSubscription(subscription);
        if (subscription) {
          setDownloadable(false);
          setStreamable(false);
          setIsUserRoyalties(false);
        }

        break;
      case "mintable":
        let mintable = !isMintable;
        if (mintable) {
          toggleModal();
          modalContent(
            <OptionModal
              onCancel={() => {
                toggleModal();
                modalContent();
              }}
              heading={"Set Mintable?"}
            >
              <SongDetailsText>{`By clicking 'OK', you agree to upload your music \n 
        on IPFS and allow for it to be minted as an NFT on our platform.`}</SongDetailsText>
              <HoverOption
                onClick={async () => {
                  setMintable(true)
                      toggleModal()
                          modalContent()
                  /*modalContent(< UnavailableFeature/>)
                   modalContent(
                    <OptionModal>
                      <ModalLoadingScreen
                        text={"Updating. Please Wait..."}
                        transparent={true}
                      />
                    </OptionModal>
                  );
                  await validateNFTStorageKey(currentUserArtist?.artistId)
                    .then((data) => {
                      if (data) {
                        setMintable(true);
                        toggleModal();
                        modalContent();
                      } else {
                        modalContent(
                          <>
                            <OptionModal
                              heading={"NFT Storage API Key Not Found"}
                            >
                              <HoverOption
                                onClick={() => {
                                  modalContent(
                                    <>
                                      <NFTStorageTokenForm
                                        artistId={currentUserArtist?.artistId}
                                        currentUser={currentUser?.uid}
                                        setMintable={setMintable}
                                      />
                                    </>
                                  );
                                }}
                              >
                                Add API Key
                              </HoverOption>
                              <HoverOption
                                onClick={() => {
                                  modalContent(
                                    <NFTStorageSonarAPIKeyForm
                                      artistId={currentUserArtist?.artistId}
                                      currentUser={currentUser?.uid}
                                      onSuccess={() => {
                                        setMintable(true);
                                      }}
                                    />
                                  );
                                }}
                              >
                                {" "}
                                Use Sonar Muse API Key
                              </HoverOption>

                              <HoverOption
                                onClick={() => {
                                  toggleModal();
                                  modalContent();
                                }}
                              >
                                Cancel
                              </HoverOption>
                            </OptionModal>
                          </>
                        );
                      }
                    })
                    .catch((err) => {
                      setMintable(false);
                      modalContent(
                        <>
                          <OptionModal
                            heading={"Something Went Wrong. Please try again"}
                          >
                            <HoverOption
                              onClick={() => {
                                toggleModal();
                                modalContent();
                              }}
                            >
                              Close
                            </HoverOption>
                          </OptionModal>
                        </>
                      );
                    }); */
                }}
              >
                OK
              </HoverOption>
            </OptionModal>
          );
        } else {
          setMintable(false);
        }

        break;
      default:
        break;
    }
  };

  const handleArtistInput = (e, type) => {
    const cArtist = currentArtist;
    const cFArtist = currentFArtist;
    const cWriter = currentWriter;
    const cProducer = currentProducer;
    const cComposer = currentComposer;
    const cPayout = currentPayoutArtist;

    switch (e.target.name) {
      case ARTIST_FORM_INPUT_TYPES.NAME:
        switch (type) {
          case ARTIST_FORM_TYPES.MAIN_ARTIST:
            setCurrentArtist({ ...cArtist, artistName: e.target.value });
            if (e.target.value.trim() !== "") {
              delete cArtist?.errors?.name;
            } else {
              setCurrentArtist({
                ...cArtist,
                errors: {
                  ...cArtist?.errors,
                  name: "Artist name cannot be empty",
                },
                artistName: e.target.value,
              });
            }

            AddNEArtistsToSearch(e);
            break;
          case ARTIST_FORM_TYPES.FEATURE:
            setCurrentFArtist({ ...cFArtist, artistName: e.target.value });
            if (e.target.value.trim() !== "") {
              delete cFArtist?.errors?.name;
            } else {
              setCurrentArtist({
                ...cArtist,
                errors: {
                  ...cArtist?.errors,
                  name: "Artist name cannot be empty",
                },
                artistName: e.target.value,
              });
            }
            break;
          case ARTIST_FORM_TYPES.COMPOSER:
            setCurrentComposer({ ...cComposer, artistName: e.target.value });
            if (e.target.value.trim() !== "") {
              delete cComposer?.errors?.name;
            } else {
              setCurrentArtist({
                ...cArtist,
                errors: {
                  ...cArtist?.errors,
                  name: "Artist name cannot be empty",
                },
                artistName: e.target.value,
              });
            }
            break;
          case ARTIST_FORM_TYPES.PRODUCER:
            setCurrentProducer({ ...cProducer, artistName: e.target.value });
            if (e.target.value.trim() !== "") {
              delete cProducer?.errors?.name;
            } else {
              setCurrentArtist({
                ...cArtist,
                errors: {
                  ...cArtist?.errors,
                  name: "Artist name cannot be empty",
                },
                artistName: e.target.value,
              });
            }
            break;
          case ARTIST_FORM_TYPES.WRITER:
            setCurrentWriter({ ...cWriter, artistName: e.target.value });
            if (e.target.value.trim() !== "") {
              delete cWriter?.errors?.name;
            } else {
              setCurrentArtist({
                ...cArtist,
                errors: {
                  ...cArtist?.errors,
                  name: "Artist name cannot be empty",
                },
                artistName: e.target.value,
              });
            }
            break;
          case ARTIST_FORM_TYPES.PAYOUT:
            setCurrentPayoutArtist({
              ...cPayout,
              artistName: e.target.value,
            });
            if (e.target.value.trim() !== "") {
              delete cPayout?.errors?.name;
            } else {
              setCurrentArtist({
                ...cArtist,
                errors: {
                  ...cArtist?.errors,
                  name: "Artist name cannot be empty",
                  artistName: e.target.value,
                },
              });
            }
            break;
          default:
            break;
        }

        break;
      case ARTIST_FORM_INPUT_TYPES.FULL_NAME:
        switch (type) {
          case ARTIST_FORM_TYPES.MAIN_ARTIST:
            setCurrentArtist({ ...cArtist, fullName: e.target.value });
            break;
          case ARTIST_FORM_TYPES.FEATURE:
            setCurrentFArtist({ ...cFArtist, fullName: e.target.value });
            break;
          case ARTIST_FORM_TYPES.COMPOSER:
            setCurrentComposer({ ...cComposer, fullName: e.target.value });
            break;
          case ARTIST_FORM_TYPES.PRODUCER:
            setCurrentProducer({ ...cProducer, fullName: e.target.value });
            break;
          case ARTIST_FORM_TYPES.WRITER:
            setCurrentWriter({ ...cWriter, fullName: e.target.value });
            break;
          case ARTIST_FORM_TYPES.PAYOUT:
            setCurrentPayoutArtist({ ...cPayout, fullName: e.target.value });
            break;
          default:
            break;
        }
        break;
      case ARTIST_FORM_INPUT_TYPES.ID:
        switch (type) {
          case ARTIST_FORM_TYPES.MAIN_ARTIST:
            setCurrentArtist({ ...cArtist, artistId: e.target.value });
            break;
          case ARTIST_FORM_TYPES.FEATURE:
            setCurrentFArtist({ ...cFArtist, artistId: e.target.value });
            break;
          case ARTIST_FORM_TYPES.COMPOSER:
            setCurrentComposer({ ...cComposer, artistId: e.target.value });
            break;
          case ARTIST_FORM_TYPES.PRODUCER:
            setCurrentProducer({ ...cProducer, artistId: e.target.value });
            break;
          case ARTIST_FORM_TYPES.PAYOUT:
            setCurrentPayoutArtist({ ...cPayout, artistId: e.target.value });
            break;
          default:
            break;
        }
        break;
      case ARTIST_FORM_INPUT_TYPES.ADDRESS:
        switch (type) {
          case ARTIST_FORM_TYPES.MAIN_ARTIST:
            setCurrentArtist({ ...cArtist, address: e.target.value });
            break;
          case ARTIST_FORM_TYPES.FEATURE:
            setCurrentFArtist({ ...cFArtist, address: e.target.value });
            break;
          case ARTIST_FORM_TYPES.COMPOSER:
            setCurrentComposer({ ...cComposer, address: e.target.value });
            break;
          case ARTIST_FORM_TYPES.PRODUCER:
            setCurrentProducer({ ...cProducer, address: e.target.value });
            break;
          case ARTIST_FORM_TYPES.WRITER:
            setCurrentWriter({ ...cWriter, address: e.target.value });
            break;
          case ARTIST_FORM_TYPES.PAYOUT:
            setCurrentPayoutArtist({ ...cPayout, address: e.target.value });
            break;
          default:
            break;
        }
        break;
      case ARTIST_FORM_INPUT_TYPES.PAYOUT_PERCENTAGE:
        if (e.target.value?.length > 0 && e.target.value <= 100) {
          setCurrentPayoutArtist({
            ...cPayout,
            payoutPercentage: parseInt(e.target.value),
          });
          return;
        }
        if (e.target.value?.length === 0) {
          setCurrentPayoutArtist({
            ...cPayout,
            payoutPercentage: 0,
          });
        }

        break;

      default:
        break;
    }
  };

  const searchInput = (e, type) => {
    if (e.target.value.length > 0 && type !== ARTIST_FORM_TYPES.PAYOUT) {
      searchArtists(e.target.name, e.target.value).then((data) => {
        if (data && (!data.error || data !== false)) {
          switch (type) {
            case ARTIST_FORM_TYPES.MAIN_ARTIST:
              setArtistSearch({
                type: e.target.name,
                artists: data.filter((artist) => {
                  if (filterExistingArtist(artists, artist) === false) {
                    return artist;
                  }
                }),
              });
              break;
            case ARTIST_FORM_TYPES.FEATURE:
              setFArtistSearch({
                type: e.target.name,
                artists: data.filter((artist) => {
                  if (featArtists) {
                    if (filterExistingArtist(featArtists, artist) === false) {
                      return artist;
                    }
                  } else {
                    return artist;
                  }
                }),
              });
              break;
            case ARTIST_FORM_TYPES.COMPOSER:
              setComposerSearch({
                type: e.target.name,
                artists: data.filter((artist) => {
                  if (filterExistingArtist(composers, artist) === false) {
                    return artist;
                  }
                }),
              });
              break;
            case ARTIST_FORM_TYPES.PRODUCER:
              setProducerSearch({
                type: e.target.name,
                artists: data.filter((artist) => {
                  if (filterExistingArtist(producers, artist) === false) {
                    return artist;
                  }
                }),
              });
              break;
            case ARTIST_FORM_TYPES.WRITER:
              setWriterSearch({
                type: e.target.name,
                artists: data.filter((artist) => {
                  if (filterExistingArtist(writers, artist) === false) {
                    return artist;
                  }
                }),
              });
              break;
            case ARTIST_FORM_TYPES.PAYOUT:
              setPayoutSearch({
                type: e.target.name,
                artists: data.filter((artist) => {
                  if (filterExistingArtist(payoutArtists, artist) === false) {
                    return artist;
                  }
                }),
              });
              break;
            default:
              break;
          }
        } else {
          //Error check
        }
        return;
      });
    }

    if (type === ARTIST_FORM_TYPES.PAYOUT && e.target.value.length > 0) {
      setPayoutSearch({
        type: e.target.name,
        artists: displaySelectedArtists().filter((artist) => {
          let a = false;
          let pArtists = payoutArtists;

          switch (e.target.name) {
            case ARTIST_SEARCH_TYPES.NAME:
              if (
                artist?.artistName &&
                artist.artistName
                  .toLowerCase()
                  .includes(e.target.value.toLowerCase()) &&
                pArtists.some((a) =>
                  a.artistName.includes(artist.artistName)
                ) === false
              ) {
                a = true;
              }

              break;
            case ARTIST_SEARCH_TYPES.FULL_NAME:
              if (
                artist?.fullName &&
                artist?.fullName
                  .toLowerCase()
                  .includes(e.target.value.toLowerCase()) &&
                filterExistingArtist(payoutArtists, artist) === false
              ) {
                a = true;
              }
              break;
            case ARTIST_SEARCH_TYPES.ADDRESS:
              if (
                artist?.address &&
                artist?.address
                  .toLowerCase()
                  .includes(e.target.value.toLowerCase()) &&
                filterExistingArtist(payoutArtists, artist) === false
              ) {
                a = true;
              }
              break;
            case ARTIST_SEARCH_TYPES.ID:
              if (
                artist?.artistId &&
                artist?.artistId
                  .toLowerCase()
                  .includes(e.target.value.toLowerCase()) &&
                filterExistingArtist(payoutArtists, artist) === false
              ) {
                a = true;
              }
              break;
            default:
              break;
          }
          if (a === true) {
            return artist;
          }
        }),
      });
    }
    if (e.target.value.length === 0) {
      switch (type) {
        case ARTIST_FORM_TYPES.MAIN_ARTIST:
          setArtistSearch();
          break;
        case ARTIST_FORM_TYPES.FEATURE:
          setFArtistSearch();
          break;
        case ARTIST_FORM_TYPES.COMPOSER:
          setComposerSearch();
          break;
        case ARTIST_FORM_TYPES.PRODUCER:
          setProducerSearch();
          break;
        case ARTIST_FORM_TYPES.WRITER:
          setWriterSearch();
          break;
        case ARTIST_FORM_TYPES.PAYOUT:
          setPayoutSearch();
          break;
        default:
          break;
      }
    }
  };

  //Filter out existing artists in list when searching
  const filterExistingArtist = (artists1, searchArtist) => {
    let artistExists = false;
    artists1.map((artist) => {
      if (artist?.artistId === searchArtist.artistId) {
        artistExists = true;
      }
    });
    return artistExists;
  };

  const displaySelectedArtists = () => {
    let allEArtists = [
      ...artists.filter((a) => a.exists),
      ...featArtists.filter(
        (a) => a.exists && currentUserArtist?.artistId !== a?.artistId
      ),
      ...composers.filter(
        (a) => a.exists && currentUserArtist?.artistId !== a?.artistId
      ),
      ...producers.filter(
        (a) => a.exists && currentUserArtist?.artistId !== a?.artistId
      ),
      ...writers.filter(
        (a) => a.exists && currentUserArtist?.artistId !== a?.artistId
      ),
    ];
    let allNEArtists = [
      ...artists.filter((a) => !a.exists),
      ...featArtists.filter((a) => !a.exists),
      ...composers.filter((a) => !a.exists),
      ...producers.filter((a) => !a.exists),
      ...writers.filter((a) => !a.exists),
    ];
    let allArtists = [
      ...getUniqueListBy(allEArtists, "artistId"),
      ...getUniqueListBy(allNEArtists, "artistName"),
    ];
    return allArtists;
  };

  //Add newly added (Non-existing) artists to searches
  const AddNEArtistsToSearch = (e) => {
    const a = [
      ...artists,
      ...featArtists,
      ...composers,
      ...producers,
      ...writers,
    ];
    const nEArtists = getUniqueListBy(
      a.filter((artist) => !artist.exists),
      "artistName"
    );
    let filteredArtists = [];

    switch (e.target.name) {
      case ARTIST_FORM_INPUT_TYPES.ID:
        filteredArtists = nEArtists.filter((artist) =>
          artist.id.includes(e.target.value)
        );
        break;

      case ARTIST_FORM_INPUT_TYPES.ADDRESS:
        filteredArtists = nEArtists.filter((artist) =>
          artist.address.includes(e.target.value)
        );
        break;

      case ARTIST_FORM_INPUT_TYPES.NAME:
        filteredArtists = nEArtists.filter((artist) =>
          artist.artistName.includes(e.target.value)
        );
        break;

      case ARTIST_FORM_INPUT_TYPES.FULL_NAME:
        filteredArtists = nEArtists.filter((artist) =>
          artist.fullName.includes(e.target.value)
        );
        break;
      default:
        break;
    }
    if (filteredArtists.length > 0) {
      return filteredArtists;
    } else return [];
  };

  const handleInput = async (e) => {
    const currSong = songUpload;
    let prevErrors = errors;
    e.preventDefault();
    switch (e.target.name) {
      case "songUpload":
        if (e.target.value && e.target.files[0]) {
          let songFileName;
          if (!songUpload.name) {
            songFileName = `${Math.round(Math.random() * 10000000000000)}.${
              e.target.files[0].name.split(".")[
                e.target.files[0].name.split(".").length - 1
              ]
            }`;
            setSong({ file: e.target.files[0], name: songFileName });
          } else {
            songFileName = songUpload.name;
            setSong((prev) => {
              return { ...prev, file: e.target.files[0] };
            });
          }
          getDuration(e.target.files[0]);
          try {
            const uploadTask = uploadBytes(
              storageRef(
                storage,
                `artists/${currentUser?.uid}/${songFileName}`
              ),
              e.target.files[0],
              {
                contentType: e.target.files[0].type,
                customMetadata: {
                  fileName: e.target.files[0].name,
                },
              }
            );

            uploadTask.on(
              "state_changed",
              (snapshot) => {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress =
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

                setSong((prev) => {
                  return { ...prev, progress: progress };
                });

                //console.log("Upload is " + progress + "% done");
                switch (snapshot.state) {
                  case "paused":
                    //console.log("Upload is paused");
                    break;
                  case "running":
                    //console.log("Upload is running");
                    break;
                  default:
                    //console.log("Upload is " + progress + "% done");
                    break;
                }
              },
              (error) => {
                // A full list of error codes is available at

                switch (error.code) {
                  case "storage/unauthorized":
                    // User doesn't have permission to access the object
                    break;
                  case "storage/canceled":
                    // User canceled the upload
                    break;

                  // ...

                  case "storage/unknown":
                    // Unknown error occurred, inspect error.serverResponse
                    break;

                  default:
                    break;
                }
              },
              () => {
                // Upload completed successfully, now we can get the download URL
                getDownloadUrl(uploadTask.snapshot.ref).then(
                  async (downloadURL) => {
                    //console.log("File available at", downloadURL);
                    return await createMp3File({
                      uid: currentUser?.uid,
                      token: mp3Token,
                      ref: uploadTask.snapshot.ref.fullPath,
                      url: downloadURL,
                    }).then((mp3File) => {
                      if (mp3File) {
                        setSong((prev) => {
                          return {
                            ...prev,
                            url: downloadURL,
                            file: e.target.files[0],
                            ref: uploadTask.snapshot.ref.fullPath,
                            mp3Url: mp3File.mp3Url,
                            mp3Ref: mp3File.mp3Ref,
                          };
                        });
                      } else {
                        setSong((prev) => {
                          return {
                            ...prev,
                            url: downloadURL,
                            file: e.target.files[0],
                            ref: uploadTask.snapshot.ref.fullPath,
                          };
                        });
                      }
                    });
                  }
                );
                updateSongInfo();
              }
            );
          } catch (err) {
            return;
          }
        }
        break;
      case "name song":
        if (e.target.value.trim() === "") {
          setErrors({
            ...prevErrors,
            songName: "You can't release a song without a name",
          });
        } 
        if (songNameContainsFeature(e.target.value) ) {
          setErrors({
            ...prevErrors,
            songName: "Song name must not contain featured any artist(s). Enter featured artist(s) on page 2",
          });
        }
        else {
          delete prevErrors.songName;
          setErrors(prevErrors);
        }
        setName(e.target.value);
        break;
      case "main genre song":
        if (e.target.value.trim() !== "" && e.target.value.length > 0) {
          searchGenres(e.target.value).then((g) => {
            setGenreFilter(g.genres);
          });
        } else {
          setGenreFilter();
        }
        setGenre(e.target.value.trim());
        break;

      case "subGenre song":
        if (e.target.value.trim() !== "" && e.target.value.length > 0) {
          setSubGenreFilter();
          searchGenres(e.target.value).then((g) => {
            setSubGenreFilter(g.subGenres);
          });
        } else {
          setSubGenreFilter();
        }
        setSubgenre(e.target.value);
        break;
      case "price song":
        if (e.target.value[0] === "0") {
          setPrice(e.target.value.substring(1, e.target.value.length));
        } else {
          setPrice(e.target.value);
        }

        if (!isNaN(e.target.value) && e.target.value > 0) {
          delete prevErrors.price;
          setErrors(prevErrors);
          return;
        }
        if (e.target.value?.length === 0) {
          setPrice(0);
        }
        if (isNaN(e.target.value)) {
          /*   err = {
             ...err,
             price: "Invalid Price. Please enter price or leave 0"
           } */
          setPrice(parseFloat(e.target.value));
          /* setErrors(err) */
          return;
        }
        break;

      case "isrc song":
        setISRC(e.target.value.toUpperCase());
        if (isrcReg.test(e.target.value)) {
          setIsrcValid(true);
        } else {
          setIsrcValid(false);
        }
        break;

      case "isrcType":
        switch (e.target.value) {
          case "auto":
            setIsrcType("auto");
            isrcRef.current.value = autoIsrcCode;
            setISRC(autoIsrcCode);
            break;

          case "custom":
            setISRC("");
            setIsrcType("custom");
            isrcRef.current.value = "";

            break;

          default:
            setIsrcType("auto");
            isrcRef.current.value = autoIsrcCode;
            setISRC(autoIsrcCode);
            break;
        }
        break;

      case "storageToken":
        setToken(e.target.value);
        break;
      case "releaseDateSong ":
        setReleaseDate(e.target.value);
        break;
      case "about song":
        setAbout(newlineFormatter(e.target.value));
        break;
      case "lyrics song":
        setLyrics(newlineFormatter(e.target.value));
        break;
      case "albumArt":
        const currArt = albumArt;
        if (e.target.value && e.target.files[0]) {
          var reader = new FileReader();

          //Read the contents of Image File.
          reader.readAsDataURL(e.target.files[0]);
          reader.onload = function (e1) {
            //Initiate the JavaScript Image object.
            var image = new Image();

            //Set the Base64 string return from FileReader as source.
            image.src = e1.target.result;

            setAlbumArt((prev) => {
              return { ...prev, file: e.target.files[0] };
            });
            image.onload = function () {
              var height = this.height;
              var width = this.width;
              if (height < 1600 || width < 1600) {
                alert("Height and Width must not be less than 1600px.");
                setAlbumArt(currArt);
                return false;
              }

              //let artBlob = convertFileToBytes(e.target.files[0])
              let imageFileName;
              if (!albumArt.name) {
                imageFileName = `${Math.round(
                  Math.random() * 10000000000000
                )}.${
                  e.target.files[0].name.split(".")[
                    e.target.files[0].name.split(".").length - 1
                  ]
                }`;
                setAlbumArt((prev) => {
                  return { ...prev, name: imageFileName };
                });
              } else {
                imageFileName = albumArt.name;
              }
              const uploadTask = uploadBytes(
                storageRef(
                  storage,
                  `artists/${currentUser?.uid}/${imageFileName}`
                ),
                e.target.files[0],
                { contentType: e.target.files[0].type }
              );

              uploadTask.on(
                "state_changed",
                (snapshot) => {
                  // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                  const progress =
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                  setAlbumArt((prev) => {
                    return { ...prev, progress: progress };
                  });

                  if (isCancelSongUpload) {
                    if (!uploadTask.isComplete()) {
                      //Upload is not complete yet, let's cancel
                      uploadTask.cancel();
                    } else {
                      //Upload is complete, but user wanted to cancel. Let's delete the file
                      uploadTask.snapshot.ref.delete();
                      // storageRef.delete(); // will delete all your files
                    }
                  }
                  //console.log("Upload is " + progress + "% done");
                  switch (snapshot.state) {
                    case "paused":
                      break;
                    case "running":
                      if (isCancelSongUpload) {
                        if (!uploadTask.isComplete()) {
                          //Upload is not complete yet, let's cancel
                          uploadTask.cancel();
                        } else {
                          //Upload is complete, but user wanted to cancel. Let's delete the file
                          uploadTask.snapshot.ref.delete();
                          // storageRef.delete(); // will delete all your files
                        }
                      }
                      break;
                    default:
                      if (isCancelSongUpload) {
                        if (!uploadTask.isComplete()) {
                          //Upload is not complete yet, let's cancel
                          uploadTask.cancel();
                        } else {
                          //Upload is complete, but user wanted to cancel. Let's delete the file
                          uploadTask.snapshot.ref.delete();
                          // storageRef.delete(); // will delete all your files
                        }
                      }
                      //console.log("Upload is " + progress + "% done");
                      break;
                  }
                },
                (error) => {
                  // A full list of error codes is available at
                  // https://firebase.google.com/docs/storage/web/handle-errors
                  switch (error.code) {
                    case "storage/unauthorized":
                      // User doesn't have permission to access the object
                      break;
                    case "storage/canceled":
                      setAlbumArt(currArt);
                      // User canceled the upload
                      break;
                    // ...
                    case "storage/unknown":
                      setAlbumArt(currArt);
                      // Unknown error occurred, inspect error.serverResponse
                      break;
                    default:
                      break;
                  }
                },
                () => {
                  // Upload completed successfully, now we can get the download URL
                  getDownloadUrl(uploadTask.snapshot.ref).then(
                    (downloadURL) => {
                      //console.log("File available at", downloadURL);
                      setAlbumArt((prev) => {
                        return {
                          ...prev,
                          url: downloadURL,
                          file: e.target.files[0],
                          ref: uploadTask.snapshot.ref.fullPath,
                        };
                      });
                      updateSongInfo();
                    }
                  );
                }
              );
            };
          };
        }
        break;
      case "video":
        const currVid = videoFile;
        if (e.target.value && e.target.files[0]) {
          let reader = new FileReader();

          //Read the contents of Image File.
          reader.readAsDataURL(e.target.files[0]);
          reader.onload = function (e1) {
            //Initiate the JavaScript Image object.

            //Set the Base64 string return from FileReader as source.

            setVideoFile((prev) => {
              return { ...prev, file: e.target.files[0] };
            });

            //let artBlob = convertFileToBytes(e.target.files[0])
            let videoFileName;
            if (!albumArt.name) {
              videoFileName = `${Math.round(Math.random() * 10000000000000)}.${
                e.target.files[0].name.split(".")[
                  e.target.files[0].name.split(".").length - 1
                ]
              }`;
              setAlbumArt((prev) => {
                return { ...prev, name: videoFileName };
              });
            } else {
              videoFileName = albumArt.name;
            }
            const uploadTask = uploadBytes(
              storageRef(
                storage,
                `artists/${currentUser?.uid}/${videoFileName}`
              ),
              e.target.files[0],
              { contentType: e.target.files[0].type }
            );

            uploadTask.on(
              "state_changed",
              (snapshot) => {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress =
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setVideoFile((prev) => {
                  return { ...prev, progress: progress };
                });

                if (isCancelSongUpload) {
                  if (!uploadTask.isComplete()) {
                    //Upload is not complete yet, let's cancel
                    uploadTask.cancel();
                  } else {
                    //Upload is complete, but user wanted to cancel. Let's delete the file
                    uploadTask.snapshot.ref.delete();
                    // storageRef.delete(); // will delete all your files
                  }
                }
                //console.log("Upload is " + progress + "% done");
                switch (snapshot.state) {
                  case "paused":
                    break;
                  case "running":
                    if (isCancelSongUpload) {
                      if (!uploadTask.isComplete()) {
                        //Upload is not complete yet, let's cancel
                        uploadTask.cancel();
                      } else {
                        //Upload is complete, but user wanted to cancel. Let's delete the file
                        uploadTask.snapshot.ref.delete();
                        // storageRef.delete(); // will delete all your files
                      }
                    }
                    break;
                  default:
                    if (isCancelSongUpload) {
                      if (!uploadTask.isComplete()) {
                        //Upload is not complete yet, let's cancel
                        uploadTask.cancel();
                      } else {
                        //Upload is complete, but user wanted to cancel. Let's delete the file
                        uploadTask.snapshot.ref.delete();
                        // storageRef.delete(); // will delete all your files
                      }
                    }
                    //console.log("Upload is " + progress + "% done");
                    break;
                }
              },
              (error) => {
                // A full list of error codes is available at
                // https://firebase.google.com/docs/storage/web/handle-errors
                switch (error.code) {
                  case "storage/unauthorized":
                    // User doesn't have permission to access the object
                    break;
                  case "storage/canceled":
                    setVideoFile(currArt);
                    // User canceled the upload
                    break;
                  // ...
                  case "storage/unknown":
                    setVideoFile(currArt);
                    // Unknown error occurred, inspect error.serverResponse
                    break;
                  default:
                    break;
                }
              },
              () => {
                // Upload completed successfully, now we can get the download URL
                getDownloadUrl(uploadTask.snapshot.ref).then((downloadURL) => {
                  //console.log("File available at", downloadURL);
                  setVideoFile((prev) => {
                    return {
                      ...prev,
                      url: downloadURL,
                      file: e.target.files[0],
                      ref: uploadTask.snapshot.ref.fullPath,
                    };
                  });
                  updateSongInfo();
                });
              }
            );
          };
        }
        break;
      default:
        break;
    }
  };

  return (
    <>
      {requestRelease === true && (
        <Modal isOpen={requestRelease} onRequestClose={cancelUpload}>
          <QRCodeForm
            heading={`Release of ${songName} by ${artists[0].artistName}`}
            body={songInfo}
            cancelQr={cancelUpload}
            onSuccess={onSuccess}
            onFail={onFailure}
            type={TRANSACTION_OPTIONS.MUSIC_UPLOAD.SONG}
          ></QRCodeForm>
        </Modal>
      )}
      <MusicTitleInfoContainer>
        <>
          <MusicUploadTitleArtContainer>
            <MusicUploadAlbumArt
              src={albumArt?.url ? albumArt.url : albumArtPlaceholder}
              alt={songName}
              onContextMenu={(e) => {
                e.preventDefault();
              }}
            />
          </MusicUploadTitleArtContainer>
          <MusicUploadTitleTextWrapper>
            <MusicUploadContentTitle style={{ fontStyle: "italic" }}>
              {songName.trim().length > 0
                ? featArtists.length > 0
                  ? songName +
                    " (ft. " +
                    featArtists.map((artist, index) => {
                      if (index === featArtists.length - 2) {
                        return artist.artistName + " & ";
                      } else if (index < featArtists.length - 2) {
                        return artist.artistName + ", ";
                      } else if (index === featArtists.length - 1) {
                        return artist.artistName;
                      }
                    }) +
                    ")"
                  : songName
                : "Song Name"}{" "}
              {isExplicit && <MdExplicit />}
            </MusicUploadContentTitle>
            <>
              <MusicUploadInfoText>
                <>
                  {artists?.length > 0 &&
                    artists.map((artist, index) => {
                      if (index === artists.length - 2) {
                        return artist.artistName + " & ";
                      } else if (index === artists.length - 1) {
                        return artist.artistName;
                      } else if (index <= artists.length - 1) {
                        return artist.artistName + ", ";
                      }
                    })}
                </>
              </MusicUploadInfoText>
            </>
            <MusicUploadInfoText>
              {releaseDate
                ? new Date(releaseDate).toDateString()
                : "Release Date"}
            </MusicUploadInfoText>
            {isDownloadable === true && (
              <MusicUploadInfoText>
                {price ? `${price} ${currency}` : "Price"}
              </MusicUploadInfoText>
            )}
          </MusicUploadTitleTextWrapper>
        </>
        <SongDetailsSection>
          <AlbumUploadsContainer
            for={
              songUpload?.progress &&
              songUpload.progress > 0 &&
              !songUpload?.url
                ? ""
                : "songUpload"
            }
          >
            {songUpload?.url && (
              <span style={{ display: "flex", flexDirection: "column" }}>
                <SongDetailsText>Replace Audio File</SongDetailsText>
                <SongDetailsText style={{ fontStyle: "italic" }}>
                  Upload Complete
                </SongDetailsText>
              </span>
            )}
            {!songUpload?.url && !songUpload.progress && (
              <>
                <SongDetailsText>Add Track</SongDetailsText>
                <SongDetailsText style={{ fontStyle: "italic" }}>
                  Select lossless format .wav, .aif or .flac
                </SongDetailsText>
              </>
            )}
            {!songUpload?.url && songUpload.progress > 0 && (
              <span
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <SongDetailsText>Uploading...</SongDetailsText>
                <SongDetailsText
                  style={{ padding: "2%", textDecoration: "underline" }}
                  onClick={() => {}}
                >
                  Cancel
                </SongDetailsText>
              </span>
            )}
            <FileUploader
              id={"songUpload"}
              name={"songUpload"}
              fileTypes={".wav"}
              userType={"artists"}
              setDuration={setDuration}
              fileUpload={songUpload}
              setFileUpload={setSong}
              acceptMultiple={false}
            />
          </AlbumUploadsContainer>
          {songUpload?.progress > 0 && !songUpload?.url && (
            <>
              <UploadProgressWrapper>
                <PushSpinner loading={songUpload?.url ? false : true} />

                <SongDetailsText style={{ fontStyle: "italic", padding: "2%" }}>
                  {songUpload?.progress > 0 &&
                    songUpload?.progress < 100 &&
                    !songUpload?.url &&
                    `${songUpload?.progress.toFixed(2)}%`}
                  {songUpload?.progress === 100 &&
                    !songUpload?.url &&
                    `Processing...`}
                </SongDetailsText>
              </UploadProgressWrapper>
            </>
          )}
          <MusicUploadInfoText>
            {songUpload?.file?.name ? songUpload?.file?.name : ""}
          </MusicUploadInfoText>
        </SongDetailsSection>
      </MusicTitleInfoContainer>
      <SongDetailsContainer>
        <SongDetailsWrapper>
          {pageNumber === 1 && (
            <FormWrapper>
              <Page1
                index={"song"}
                handleToggle={handleToggle}
                isCover={isCover}
                isExplicit={isExplicit}
                isPreOrder={isPreOrder}
                isStreamable={isStreamable}
                isSubscription={isSubscription}
                isDownloadable={isDownloadable}
                isMintable={isMintable}
                isVideo={isVideo}
                isVisualizer={isVisualizer}
                isUserRoyalties={isUserRoyalties}
                songNameRef={songNameRef}
                songName={songName}
                handleInput={handleInput}
                errors={errors}
                setCurrency={setCurrency}
                songPriceRef={songPriceRef}
                currency={currency}
                price={price}
                genreRef={genreRef}
                genre={genre}
                mainGenre={mainGenre}
                removeGenre={removeGenre}
                filteredGenres={filteredGenres}
                addGenre={addGenre}
                subgenre={subgenre}
                subGenres={subGenres}
                filteredSubGenres={filteredSubGenres}
              />
            </FormWrapper>
          )}

          {pageNumber === 2 && (
            <Fade>
              <FormWrapper>
                <Page2
                  index={"song"}
                  artists={artists}
                  artistSearchRef={artistSearchRef}
                  removeArtist={removeArtist}
                  clearArtistForm={clearArtistForm}
                  setCurrentArtist={setCurrentArtist}
                  setArtistEditing={setArtistEditing}
                  handleInput={handleArtistInput}
                  searchInput={searchInput}
                  currentArtist={currentArtist}
                  walletAddressRef={walletAddressRef}
                  artistSearch={artistSearch}
                  artistNameRef={artistNameRef}
                  artistFullNameRef={artistFullNameRef}
                  artistIdRef={artistIdRef}
                  selectArtist={selectArtist}
                  validateArtistInfo={validateArtistInfo}
                  artistEditng={artistEditng}
                  featArtists={featArtists}
                  fArtistSearchRef={fArtistSearchRef}
                  setCurrentFArtist={setCurrentFArtist}
                  setFArtistEditing={setFArtistEditing}
                  handleArtistInput={handleArtistInput}
                  currentFArtist={currentFArtist}
                  fWalletAddressRef={fWalletAddressRef}
                  featArtistSearch={featArtistSearch}
                  fArtistNameRef={fArtistNameRef}
                  fArtistFullNameRef={fArtistFullNameRef}
                  fArtistIdRef={fArtistIdRef}
                  fArtistEditng={fArtistEditng}
                  errors={errors}
                />
              </FormWrapper>
            </Fade>
          )}

          {pageNumber === 3 && (
            <Fade>
              <FormWrapper>
                <Page3
                  index={"song"}
                  isStreamable={isStreamable}
                  isDownloadable={isDownloadable}
                  isrcType={isrcType}
                  isrcRef={isrcRef}
                  isPreOrder={isPreOrder}
                  handleInput={handleInput}
                  isrc={isrc}
                  errors={errors}
                  aboutRef={aboutRef}
                  lyricsRef={lyricsRef}
                  releaseDateRef={releaseDateRef}
                  about={about}
                  lyrics={lyrics}
                  releaseDate={releaseDate}
                />
              </FormWrapper>
            </Fade>
          )}

          {pageNumber === 4 && (
            <Fade>
              <FormWrapper>
                <Page4
                  index={"song"}
                  composers={composers}
                  composerSearchRef={composerSearchRef}
                  removeArtist={removeArtist}
                  clearArtistForm={clearArtistForm}
                  setComposerEditing={setComposerEditing}
                  setCurrentComposer={setCurrentComposer}
                  handleArtistInput={handleArtistInput}
                  searchInput={searchInput}
                  currentComposer={currentComposer}
                  composerEditing={composerEditing}
                  composerWalletAddressRef={composerWalletAddressRef}
                  composerSearch={composerSearch}
                  composerNameRef={composerNameRef}
                  composerIdRef={composerIdRef}
                  composerFullNameRef={composerFullNameRef}
                  selectArtist={selectArtist}
                  validateArtistInfo={validateArtistInfo}
                  errors={errors}
                  writerEditing={writerEditing}
                  writers={writers}
                  writerSearchRef={writerSearchRef}
                  setCurrentWriter={setCurrentWriter}
                  setWriterEditing={setWriterEditing}
                  currentWriter={currentWriter}
                  writerWalletAddressRef={writerWalletAddressRef}
                  writerSearch={writerSearch}
                  writerNameRef={writerNameRef}
                  writerFullNameRef={writerFullNameRef}
                  writerIdRef={writerIdRef}
                  producerSearchRef={producerSearchRef}
                  producers={producers}
                  setCurrentProducer={setCurrentProducer}
                  setProducerEditing={setProducerEditing}
                  currentProducer={currentProducer}
                  producerWalletAddressRef={producerWalletAddressRef}
                  producerSearch={producerSearch}
                  producerNameRef={producerNameRef}
                  producerFullNameRef={producerFullNameRef}
                  producerIdRef={producerIdRef}
                  producerEditing={producerEditing}
                />
              </FormWrapper>
            </Fade>
          )}

          {pageNumber === 5 && (
            <Fade>
              <FormWrapper>
                <Page5
                  videoFile={videoFile}
                  visualizerFile={visualizerFile}
                  setVideoFile={setVideoFile}
                  setVisualizerFile={setVisualizerFile}
                />
              </FormWrapper>
            </Fade>
          )}
          {pageNumber === 6 && (
            <Fade>
              <FormWrapper>
                <Page6
                  license={license}
                  setCreativeCommons={setCreativeCommons}
                />
              </FormWrapper>
            </Fade>
          )}
          {pageNumber === 7 && (
            <Fade>
              <FormWrapper>
                <Page7
                  payoutArtists={payoutArtists}
                  userRoyalties={userRoyalties}
                  setUserRoyalties={setUserRoyalties}
                  isUserRoyalties={isUserRoyalties}
                  errors={errors}
                  setErrors={setErrors}
                  payoutArtistSearchRef={payoutArtistSearchRef}
                  removeArtist={removeArtist}
                  clearArtistForm={clearArtistForm}
                  setCurrentPayoutArtist={setCurrentPayoutArtist}
                  setPayoutEditing={setPayoutEditing}
                  handleArtistInput={handleArtistInput}
                  searchInput={searchInput}
                  currentPayoutArtist={currentPayoutArtist}
                  payoutArtistFullNameRef={payoutArtistFullNameRef}
                  payoutArtistWalletAddressRef={payoutArtistWalletAddressRef}
                  payoutSearch={payoutSearch}
                  payoutArtistNameRef={payoutArtistNameRef}
                  payoutArtistIdRef={payoutArtistIdRef}
                  artistPayoutRef={artistPayoutRef}
                  selectArtist={selectArtist}
                  validateArtistInfo={validateArtistInfo}
                  payoutEditing={payoutEditing}
                />
              </FormWrapper>
            </Fade>
          )}
        </SongDetailsWrapper>

        <SongArtCoverWrapper>
          <Zoom>
            <SongDetailsSection>
              <SongDetailsSectionTitle>Album Art</SongDetailsSectionTitle>

              <AlbumUploadsContainer
                for={albumArt?.progress > 0 && !albumArt?.url ? "" : "albumArt"}
              >
                <span style={{ display: "flex" }}>
                  <SongDetailsText>
                    {albumArt?.url ? (
                      `${albumArt?.file?.name}`
                    ) : (
                      <small style={{ fontStyle: "italic" }}>
                        Click here to add Album Art (Size should be at least
                        1600 x 1600 pixels){" "}
                      </small>
                    )}
                  </SongDetailsText>
                  {albumArt?.progress > 0 && !albumArt?.url && (
                    <SongDetailsText
                      onClick={() => setCancelSongUpload(true)}
                      style={{ textDecoration: "underline" }}
                    >
                      Cancel
                    </SongDetailsText>
                  )}
                  {albumArt?.url && (
                    <SongDetailsText style={{ textDecoration: "underline" }}>
                      Replace
                    </SongDetailsText>
                  )}
                </span>
                <AlbumArtPreviewContainer albumArt={albumArt?.url}>
                  {albumArt?.progress > 0 && !albumArt?.url && (
                    <>
                      <LoadingScreen
                        transparent
                        isLoaded={albumArt.url ? true : false}
                      />
                      {albumArt.progress < 100
                        ? `${albumArt?.progress.toFixed(2)}%`
                        : `Processing`}
                    </>
                  )}
                  {(albumArt?.url || !albumArt?.progress) && (
                    <>
                      <AlbumArtPreview
                        src={albumArt?.url ? albumArt.url : albumArtPlaceholder}
                        onContextMenu={(e) => {
                          e.preventDefault();
                        }}
                      />
                    </>
                  )}
                </AlbumArtPreviewContainer>
                <AlbumUploadsInput
                  id="albumArt"
                  ref={albumArtRef}
                  type={"file"}
                  name="albumArt"
                  accept="image/x-png,image/gif,image/jpeg, image/jpg"
                  multiple="false"
                  onChange={(e) => {
                    handleInput(e);
                  }}
                />
              </AlbumUploadsContainer>
            </SongDetailsSection>

            {/*nftMint && (
              <SongDetailsSection>
                <SongDetailsSectionTitle>
                  Additional Files
                </SongDetailsSectionTitle>
                <AlbumUploadsContainer for="addFiles">
                  <SongDetailsText>
                    {additionalFiles && additionalFiles.length > 0
                      ? `${additionalFilesRef.current?.files[0]?.name}`
                      : `Add additional files (Max 5)`}
                  </SongDetailsText>
                  <AlbumUploadsInput
                    id="addFiles"
                    ref={additionalFilesRef}
                    type={"file"}
                    max={5}
                    name="addFiles"
                    accept="image/x-png,image/gif,image/jpeg, image/jpg"
                    multiple="false"
                    onChange={(e) => {
                      handleInput(e);
                    }}
                  />
                </AlbumUploadsContainer>
              </SongDetailsSection>
                  )}*/}
          </Zoom>
        </SongArtCoverWrapper>
      </SongDetailsContainer>
      {errors.message && (
        <ErrorWrapper
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <SongDetailsText error>{errors.message}</SongDetailsText>
        </ErrorWrapper>
      )}
      <PageIndexer pages={7} currentPage={pageNumber} onClick={setPageNum} />
      <MusicUploadsButtonContainer>
        {!isSubmitted && (
          <MusicUploadsButton
            backgroundColor={hideButtons && Theme({}).secondaryColor}
            outline={!hideButtons}
            to={"#"}
            onClick={() => (hideButtons ? {} : uploadData("draft"))}
          >
            Save Draft
          </MusicUploadsButton>
        )}

        {pageNumber > 1 && !isSubmitted && (
          <MusicUploadsButton outline onClick={() => prevPage()}>
            Prev
          </MusicUploadsButton>
        )}
        {pageNumber < 7 && pageNumber > 1 && !isSubmitted && (
          <MusicUploadsButton
            outline
            onClick={() => {
              nextPage();
              return;
            }}
          >
            Next
          </MusicUploadsButton>
        )}
        {pageNumber === 7 && (
          <MusicUploadsButton
            outline={!hideButtons}
            onClick={() => {
              if (isSubmitted === true && songId) {
                navigate(`/song/${songId}`);
                return;
              } else {
                if (hideButtons) {
                  return;
                }
                uploadData("publish");
                return;
              }
            }}
          >
            {isSubmitted === true ? "View Release" : "Release Song"}
          </MusicUploadsButton>
        )}
        {!isSubmitted && (
          <MusicUploadsButton outline onClick={() => cancelMusicUpload()}>
            Cancel
          </MusicUploadsButton>
        )}
      </MusicUploadsButtonContainer>
    </>
  );
};

export default SongUploads;
