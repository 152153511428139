import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'
import useMusic from '../../hooks/useMusic'
import { ALERT_TYPES, CONTENT_TYPES, SLIDER_TYPES } from '../../utils/Enum'
import ContentWrapper from '../ContentWrapper'
import LoadingScreen from '../LoadingScreen'
import { Slider } from '../Slider'
import { MusicUploadsConatiner, MusicUploadsWrapper } from './MusicUploadsFormElements'
import { getDraftsByUser } from '../../utils/dbUtils/draftsUtils'
import OptionModal from '../OptionModal'
import { HoverOption } from '../MenuOptions/MenuOptionsElements'
import { useModal } from '../Modal/ModalContext'
import { useAlert } from '../Alert/AlertContext'
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const DraftUploads = () => {
  const {openModal, modalContent, closeModal, toggleOpen} = useModal()
    const {
        currentUser,
        isArtist,
        isLabel,
        isPodcaster, profiles, currentProfile
      } = useAuth();
    const {getDraftsByUid,deleteAllDrafts} = useMusic();
    const [drafts, setDrafts] = useState([])
    const [isLoaded, setLoaded] = useState(false)
    const location = useLocation()
    const query = useQuery()
    const navigate  = useNavigate()

    useEffect(() => {
        if(currentUser?.isArtist || currentUser?.isPodcaster || currentUser?.isLabel){
          getDraftsByUid(currentUser?.uid).then((data) => {
            if(data){
              if(currentProfile !== 'podcaster'){
                setDrafts(data?.filter(d => d?.type !== 'episode'))
              }else{
                setDrafts(data?.filter(d => d?.type === 'episode'))
              }
            
            setLoaded(true)
          }
        }).catch(err => {
        //console.error(err)
        setLoaded(true)
      })
      getDraftsByUser(currentUser?.uid, setDrafts)
    }
      return () => {}
    }, [!isLoaded])

    const confirmDeleteAll = async() => {
      openModal()
      modalContent(<OptionModal heading={"Are you sure you want to delete all your drafts?"}>
        <HoverOption onClick={async() => {
        
          await deleteAllDrafts(currentUser?.uid).then((data) => {
            if(data && !data.error){
             closeModal() 
            
             return
            }
           
          }).catch(err => {
            
            return
          })
        }}>Confirm</HoverOption>
        <HoverOption onClick={() => {
          closeModal()
          modalContent()
        }}>Cancel</HoverOption>
      </OptionModal>)

    }
    
  return (
    <> 
    <MusicUploadsConatiner>
            
            <MusicUploadsWrapper>  
                <ContentWrapper heading={"Drafts"} subheading={drafts?.length > 0 && 'Delete All'} onClick={() => drafts?.length && confirmDeleteAll() }>
                    {isLoaded && drafts?.length > 0 && <Slider id={'drafts'} heightScroll type={SLIDER_TYPES.DRAFT} key={'drafts'} slides={drafts} />}
                    {!isLoaded && (!drafts || drafts?.length === 0) && <Slider id={'drafts'} type={SLIDER_TYPES.MUSIC_SKELETON}/>}
                    {isLoaded && drafts?.length === 0 && <>No Drafts Available</>}
                    
                </ContentWrapper>         
    </MusicUploadsWrapper> 
            
        </MusicUploadsConatiner>
    </>
  )
}

export default DraftUploads