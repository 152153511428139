import React from 'react'
import LabelRegistration from '../../../components/RegistrationForm/LabelRegistration'
import { Access } from '../../../components/Navbar/Data'
import Navbar from '../../../components/Navbar'

const LabelRegistrationPage = () => {
  return (
    <><Navbar navInfo={Access} />
    <LabelRegistration/></>
  )
}

export default LabelRegistrationPage