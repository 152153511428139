import React from 'react'
import { SLIDER_TYPES } from '../../../utils/Enum'
import ContentWrapper from '../../ContentWrapper'
import { Slider } from '../../Slider'

const PodcasterStats = () => {
  return (
    <ContentWrapper heading={`My Stats`}>
        <Slider type={SLIDER_TYPES.EPISODE} id={'stats'}/>
        </ContentWrapper>
  )
}

export default PodcasterStats