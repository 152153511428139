import { addDoc, collection, deleteDoc, doc, updateDoc } from "firebase/firestore"
import { auth, db, timestamp } from "../../components/Authorization/firebase"

export const sendReply = async (type, typeId, profileType, profileId, message, commentId) => {
   let commentRef = collection(db, '/comments')
    return await addDoc(commentRef,{
        type,
        [`${type}Id`]: typeId,
        [`${profileType}Id`]: profileId,
        parentId: commentId,
        noPayment: true,
        message,
        createdAt: timestamp.now()
    }).then(data => {
        return
    })
}

export const deleteReply = async (commentId) => {

   let commentRef = doc(db, `/comments/${commentId}`)
    return await deleteDoc(commentRef).then(data => {
        return true
    })
}

export const likeComment = async (comment) => {

   let commentRef = doc(db, `/comments/${comment?.commentId}`)
    return await updateDoc(commentRef, {
        like: true
    }).then(data => {
        return true
    })
}

export const unlikeComment = async (comment) => {

   let commentRef = doc(db, `/comments/${comment?.commentId}`)

    return await updateDoc(commentRef, {
        like: false,
    }).then(data => {
        return true
    })
}