import React, { useEffect, useState } from 'react'
import ContentWrapper from '../ContentWrapper'
import useMusic from '../../hooks/useMusic'
import KeyMetrics from '../Charts/KeyMetrics'
import { Nav, NavContainer, NavLink } from '../NavLink/NavLinkElements'
import { calcRoyaltyPurchaseQuantity, getEpochData, getObjectLength, groupInfoByDate, groupPurchasesByAmount } from '../../utils'
import { SelectButton, SelectButtonWrapper } from '../Select'
import { ModalLoadingScreen } from '../LoadingScreen'
import { FormText, FormWrapper } from '../CustomForm/CustomFormElements'
import BarGraph from '../Charts/Bar'
import { Timer } from '../CountdownTimer'
import { useWebsiteTheme } from '../WebsiteTheme/WebsiteThemeContext'
import { CURRENCIES_STATS_COLORS } from '../../utils/Enum'
import ChartToggle from '../Charts'

const RoyaltyStats = ({royalty}) => {
  const {themeColors} = useWebsiteTheme()
    const {getSongRoyaltyPurchasesByDate, getAlbumRoyaltyPurchasesByDate, getEpisodeRoyaltyPurchasesByDate} = useMusic()
    const [filter, setFilter] = useState('7days')
    const [metricType, setMetricType] = useState('epochs')
    const [epochs, setEpochs] = useState([])
    const [purchases, setPurchases] = useState([])
    const [isLoaded, setLoaded] = useState(false)

    const getData = async () => {
      switch (royalty?.type) {
        case 'song':
          return await getSongRoyaltyPurchasesByDate(royalty?.musicDetails?.songId, filter).then(data => {
            if(data?.purchases?.length > 0){
              setPurchases(data?.purchases)
            }
            setLoaded(true)
          }).catch(err => {
            console.error(err)
            setLoaded(true)
          })
          break;
        case 'album':
          return await getAlbumRoyaltyPurchasesByDate(royalty?.musicDetails?.albumId, filter).then(data => {
            if(data?.purchases?.length > 0){
              setPurchases(data?.purchases)
            }
            setLoaded(true)
          }).catch(err => {
            console.error(err)
            setLoaded(true)
          })
          break;
        case 'episode':
          return await getEpisodeRoyaltyPurchasesByDate(royalty?.musicDetails?.episodeId, filter).then(data => {
            if(data?.purchases?.length > 0){
              setPurchases(data?.purchases)
            }
            setLoaded(true)
          }).catch(err => {
            console.error(err)
            setLoaded(true)
          })
          break;
      
        default:
          break;
      }
    }

    useEffect(() => {
      getData()
      setEpochs(royalty?.prevPayouts?.length > 0 ? getEpochData(royalty?.prevPayouts) : [])
      return () => {
      }
    }, [filter])
    

  return (
    <>
      {" "}
      <SelectButtonWrapper>
        <SelectButton
          disabled={!isLoaded}
          onChange={(e) => setFilter(e.target.value)}
        >
          <option value={"7days"}>7 days</option>
          <option value={"14days"}>2 Weeks</option>
          <option value={"30days"}>4 Weeks</option>
          <option value={"60days"}>2 Months</option>
          <option value={"180days"}>6 Months</option>
          <option value={"365days"}>12 Months</option>
          <option value={"AllTime"}>All Time</option>
        </SelectButton>
      </SelectButtonWrapper>
      {!isLoaded && <ModalLoadingScreen text={"Loading Stats"} />}
      {isLoaded && (
        <ContentWrapper>
          <KeyMetrics
            data={[
              {
                onClick: () => {
                  setMetricType("epochs");
                },
                heading: "Payouts",
                labels: Object.keys(epochs),
                datasets: [
                  {
                    label: "XRP",
                    data: Object.values(epochs),
                    borderColor: themeColors.successColor,
                    backgroundColor: themeColors.successColor,
                  },
                ],
              },
              {
                heading: "Purchases",
                onClick: () => {
                  setMetricType("purchases");
                },
                labels:
                  purchases?.length > 0
                    ? Object.keys(
                        groupInfoByDate(purchases, "createdAt", filter)
                      )
                    : [],
                datasets: [
                  {
                    label: "Purchases",
                    data:
                      purchases?.length > 0
                        ? Object.values(
                            groupInfoByDate(purchases, "createdAt", filter)
                          ).map((p) => {
                            return p?.length;
                          })
                        : [],
                    borderColor: themeColors.secondaryColor,
                    backgroundColor: themeColors.secondaryColor,
                  },
                ],
              },
            ]}
          />
          {metricType === "epochs" && getObjectLength(epochs) > 0 && (
            <>
              {" "}
              <ContentWrapper>
                <Timer
                  heading={`$${royalty?.ticker} Payout Epoch ${
                    royalty.prevPayouts?.length > 0
                      ? royalty.prevPayouts?.length + 1
                      : 1
                  }`}
                  isPage={false}
                  date={royalty.nextPayout}
                  details={`Snapshot of $${
                    royalty?.ticker
                  } Shareholders for payout epoch ${
                    royalty.prevPayouts?.length > 0
                      ? royalty.prevPayouts?.length + 1
                      : 1
                  } ${
                    !royalty?.snapshot
                      ? `will take place on ${
                          new Date(
                            new Date()?.setDate(
                              new Date(royalty.nextPayout).getDate() - 3
                            )
                          )
                            .toISOString()
                            .split("T")[0]
                        }`
                      : "has been taken."
                  }`} /* img={royalty?.musicDetails?.albumArt
                ? royalty?.musicDetails?.albumArt
                : royalty?.musicDetails.thumbnail} */
                />
              </ContentWrapper>
              <div style={{ marginTop: "20%" }}></div>
              <ContentWrapper>
              
                  <ChartToggle
                    data={{
                      heading: 'Epoch Payouts (In XRP)',
                      labels: Object.keys(epochs),
                      datasets: [
                        {
                          label: "Payout",
                          data: Object.values(epochs),
                          borderColor: themeColors.successColor,
                          backgroundColor: themeColors.successColor,
                        },
                      ],
                    }}
                  />
              
              </ContentWrapper>
            </>
          )}

          {metricType === "purchases" && purchases?.length > 0 && (
            <ContentWrapper>
              
                <ChartToggle
                  data={{
                    heading: "Total Sales",
                    labels:
                      purchases?.length > 0
                        ? Object.keys(
                            groupInfoByDate(purchases, "createdAt", filter)
                          )
                        : [],
                    datasets: [
                      {
                        label: "Sales",
                        data:
                          purchases?.length > 0
                            ? Object.values(
                                groupInfoByDate(purchases, "createdAt", filter)
                              ).map((p) => {
                                return p.length;
                              })
                            : [],
                        borderColor: themeColors.warningColor,
                        backgroundColor: themeColors.warningColor,
                      },
                    ],
                  }}
                />
                <ChartToggle
                  data={{
                    heading: "Total Quantity Purchase",
                    labels:
                      purchases?.length > 0
                        ? Object.keys(
                            groupInfoByDate(purchases, "createdAt", filter)
                          )
                        : [],
                    datasets: [
                      {
                        label: "Quantity",
                        data:
                          purchases?.length > 0
                            ? Object.values(
                                groupInfoByDate(purchases, "createdAt", filter)
                              ).map((p) => {
                                return calcRoyaltyPurchaseQuantity(p);
                              })
                            : [],
                        borderColor: themeColors.successColor,
                        backgroundColor: themeColors.successColor,
                      },
                    ],
                  }}
                />
              
              {getObjectLength(groupPurchasesByAmount(purchases)) > 0 && (
               
                  <ChartToggle
                  type={'bar'}
                    data={{
                      heading: 'Revenue (Grouped By Currency)',
                      labels:
                        getObjectLength(groupPurchasesByAmount(purchases)) > 0
                          ? Object.keys(groupPurchasesByAmount(purchases))
                          : [],
                      datasets: [
                        {
                          label: "Total Earnings",
                          data:
                            purchases?.length > 0
                              ? Object.values(
                                  groupPurchasesByAmount(purchases)
                                ).map((purchase) => {
                                  return parseFloat(purchase).toFixed(2);
                                })
                              : [],
                          borderColor: Object.keys(
                            groupPurchasesByAmount(purchases)
                          ).map((purchase) => {
                            return CURRENCIES_STATS_COLORS[purchase];
                          }),
                          backgroundColor: Object.keys(
                            groupPurchasesByAmount(purchases)
                          ).map((purchase) => {
                            return CURRENCIES_STATS_COLORS[purchase];
                          }),
                        },
                      ],
                    }}
                  />
              )}
            </ContentWrapper>
          )}

          {((metricType === "epochs" && epochs?.length === 0) ||
            (metricType === "purchases" && purchases?.length === 0)) && (
            <>
              <ContentWrapper>
                <FormWrapper>
                  <FormText>No Data Available</FormText>
                </FormWrapper>
              </ContentWrapper>
            </>
          )}
        </ContentWrapper>
      )}
    </>
  );
}

export default RoyaltyStats