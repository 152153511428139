import styled from "styled-components";
import { hexToRgba } from "../HexToRGBA";

export const Container = styled.div`
    position: relative;
    flex-direction: ${props => props.flexDirection ? props.flexDirection : 'row'};
    display: flex;
    padding: 2%;
    cursor: pointer;
    align-items: center;
    justify-content: flex-start;
    

`

export const CheckboxWrapper = styled.div`
    display: flex;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;
    &:hover {
        border-radius: 50%;
        transition: all 0.3s ease-in-out;
        background: ${(props) => hexToRgba(props.theme.mainColor, 0.2)};
      }
    ${Container}:hover &{
        transform: scale(1.1);
        transition: all 0.3s ease-in-out;
      }

`

export const Label = styled.label`
    cursor: pointer;
    display: flex;
    white-space: nowrap;
    padding: 2%;
    font-size: 0.8rem;
    @media screen and (max-width: 480px) {
        white-space: pre-line;
    }
    ${Container}:hover &{
        transform: scale(0.97);
        transition: all 0.3s ease-in-out;
      }

`