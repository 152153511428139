import React from 'react'
import axiosInstance from "../components/Authorization/client";
import { ALERT_TYPES, PODCASTER_SEARCH_TYPES } from '../utils/Enum';
import { useAlert } from '../components/Alert/AlertContext';
import { useAuth } from '../contexts/AuthContext';
import useMusic from './useMusic';
const axios = axiosInstance

const usePodcaster = ({id}) => {
    const {addAlert} = useAlert()
    const {currentUser} = useAuth()
    const {getEpisode} = useMusic()

    const getPodcasterProfile = (id) => {
        return axios.get(`/api/podcasters/${id}/getProfile`).then(podcaster => {
            if(podcaster.data && !podcaster.data.error){
                return podcaster.data
            }
            return false
        }).catch(err => {
            return false
        })
    }

    const markPodcasterNotificationAsRead = async (notificationId) => {
        return await axios
          .get(`/api/notifications/podcaster/${notificationId}/markAsRead`)
          .then((notifications) => {
            if (notifications?.data && !notifications?.data?.error) {
              return notifications.data;
            } else {
              return false;
            }
          })
          .catch((err) => {
            console.error(err);
            return false;
          });
      };

    const followPodcaster = (podcasterId) =>{
        
        addAlert({type: ALERT_TYPES.INFO, title: 'Podcaster Follow', secs: 10})

        return axios.post(`/api/${podcasterId ? podcasterId:id}/followPodcaster`, {uid: currentUser?.uid})
        .then((data) => {
            addAlert({type: ALERT_TYPES.SUCCESS, message: 'Podcaster has been followed'})

            return data.data
        })
        .catch(err => {
            console.error(err)
            addAlert({type: ALERT_TYPES.DANGER, title: 'Follow Error', message: 'Oops, Something went wrong. Try again.'})

            return false
        })
    }

    const unfollowPodcaster = async(podcasterId) => {
    addAlert({type: ALERT_TYPES.INFO, title: 'Podcaster Unfollow', secs: 10})

        return axios.post(`/api/${podcasterId ? podcasterId:id}/unfollowPodcaster`, {uid: currentUser?.uid})
        .then((data) => {
            addAlert({type: ALERT_TYPES.SUCCESS, message: 'Podcaster has been unfollowed'})

            return data.data
        })
        .catch(err => {
            addAlert({type: ALERT_TYPES.DANGER, title: 'Follow Error', message: 'Oops, Something went wrong. Try again.'})

            return false
        })
    }

    const getPodcasters = (page) => {
       return axios.get(`/api/podcasters/getPodcasters`).then(podcasters => {
            if(podcasters.data && !podcasters.data.error){
                return podcasters.data
            }
            return false
        }).catch(err => {
            return false
        })
    }

    const searchPodcasters = async(type, podcaster) => {
        switch (type) {
            case PODCASTER_SEARCH_TYPES.ID:
                return await axios.post(`/api/podcaster/search`, {podcasterId: podcaster, type: PODCASTER_SEARCH_TYPES.ID})
                .then((data) => 
                {
            return data.data
            }).catch(err => {
            return false
            })
                
            case PODCASTER_SEARCH_TYPES.ADDRESS:
                return await axios.post(`/api/podcaster/search`, {address: podcaster, type: PODCASTER_SEARCH_TYPES.ADDRESS})
                .then((data) => {
                    return data.data
                })
                .catch(err => {
                    return false
                })
                
            case PODCASTER_SEARCH_TYPES.NAME:
                return await axios.post(`/api/podcaster/search`, {podcasterName: podcaster, type: PODCASTER_SEARCH_TYPES.NAME})
                .then((data) => {
                    return data.data
                })
                .catch(err => {
                    return false
                })
                      
            case PODCASTER_SEARCH_TYPES.FULL_NAME:
                return await axios.post(`/api/podcaster/search`, {podcasterName: podcaster, type: PODCASTER_SEARCH_TYPES.FULL_NAME})
                .then((data) => {
                    return data.data
                })
                .catch(err => {
                    return false
                })    
            default:
                break;
        }
    }

    const getPodcasterEpisodes = async(id, page) => {
        return await axios.get(`/api/${id}/getPodcasterEpisodes/${page}`)
         .then((episodes) => {
         if(episodes?.data.error || episodes?.data === false){
             return false
         }
         return episodes.data
     })
     .catch(err => {
         return false
     })
 
     }
     
   const updatePodcasterProfile = async(pId, profile, txInfo) => {
    return axios.post(`/api/${pId}/updatePodcasterProfile`, {profile: profile, txInfo})
    .then((data) => {
        if(data.data.error || data.data === false)
        {
            return false
        }
        return data.data
    })
    .catch(err => {
        return false
    })
}  

const enablePodcasterWebMonetization = async(podcasterId, payload, txId) => {
    return await axios.post(`/api/${podcasterId}/enablePodcasterWebMonetization`, {
        payload: payload,
        txId: txId
    }).then(result => {
        if(result.data && !result.data.error){
            return result.data
        }
        return false
    }).catch(err => {
        console.error(err)
        return false
    })
}

const getPodcasterFunds = async(podcasterId, paymentChannel) => {
    return await axios.get(`/api/${podcasterId}/${paymentChannel}/getPodcasterCurrentBalance`)
    .then((balance) => {
        if(balance.data && !balance.data.error){
            return balance.data
        }
    }).catch(err => {
        return false
    })
}

const getPodcasterMusicRoyaltiesByUserId = async(uid, next) => {
    return await axios
      .get(`/api/royalties/${uid}/getPodcasterMusicRoyalties/${next}`)
      .then((royalties) => {
        if (royalties.data && !royalties.data.error) {
          return royalties.data;
        }
        return false;
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  };

  //Statistics

  const getPodcasterTopEpisodesByDate = async(podcasterId, filter, page) => {
    return  axios.get(`/api/stats/podcaster/${podcasterId}/${filter}/${page}/getTopEpisodes`)
     .then(songs => {
         if(!songs.data || songs.data.error){
             return false
         }
         return songs.data
     }).catch(err => {
         console.error(err)
         return false
     })
 }


 const getPodcasterFollowersByDate = async (podcasterId, filter) => {
    return axios.get(`/api/stats/podcaster/${podcasterId}/${filter}/getFollowers`)
     .then(followers => {
         if(!followers.data || followers.data.error){
             return false
         }
         return followers.data
     }).catch(err => {
         console.error(err)
         return false
     })
 }

 const getPodcasterSubscribersByDate = async (podcasterId, filter) => {
     return axios.get(`/api/stats/podcaster/${podcasterId}/${filter}/getSubscribers`)
     .then(subscribers => {
         if(!subscribers.data || subscribers.data?.error){
             return false
         }
         return subscribers.data
     }).catch(err => {
         console.error(err)
         return false
     })
 }

 const getPodcasterListenersByDate = async (podcasterId, filter) => {
     return axios.get(`/api/stats/podcaster/${podcasterId}/${filter}/getListeners`)
     .then(listeners => {
         if(!listeners.data || listeners.data.error){
             return false
         }
         return listeners.data
     }).catch(err => {
         console.error(err)
         return false
     })
 }
 
 const getPodcasterEpisodePlaylistsAdditionbyDate = async (podcasterId, filter, page) => {
     return axios.get(`/api/stats/podcaster/${podcasterId}/${filter}/${page}/getPlaylistsAdditions`)
     .then(playlists => {
         if(!playlists.data || playlists.data.error){
             return false
         }
         return playlists.data
     }).catch(err => {
         console.error(err)
         return false
     })
 }


  return {
    getPodcasterProfile,
    enablePodcasterWebMonetization,
    getPodcasterFunds,
    markPodcasterNotificationAsRead,
    getPodcasters,
    searchPodcasters,
    followPodcaster,
    unfollowPodcaster,
    getPodcasterEpisodes,
    updatePodcasterProfile,
    getPodcasterMusicRoyaltiesByUserId,
    getPodcasterTopEpisodesByDate,
    getPodcasterFollowersByDate,
    getPodcasterSubscribersByDate,
    getPodcasterEpisodePlaylistsAdditionbyDate,
    getPodcasterListenersByDate
  }
}

export default usePodcaster