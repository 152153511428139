import React, { useEffect, useState } from "react";
import { FaRecordVinyl } from "react-icons/fa";
import { MdExplicit } from "react-icons/md";
import { useAuth } from "../../../contexts/AuthContext";
import {
  getArtistsNames,
  getArtistsNames2,
  getSongTitle,
} from "../../../utils";
import {
  ALERT_TYPES,
  ARTIST_SEARCH_TYPES,
  CONTENT_TYPES,
  MUSIC_PURCHASE_OPTIONS,
} from "../../../utils/Enum";
import ImageUtils from "../../../utils/ImageUtils";
import { useAlert } from "../../Alert/AlertContext";
import { ArtistSelectorModal, OpenMusicStorePlayer } from "../../MenuOptions";
import { useModal } from "../../Modal/ModalContext";
import OptionModal from "../../OptionModal";
import Ribbon from "../../Ribbon";
import ScrollToTop from "../../ScrollToTop";
import { SkeletonPurchaseCardImg } from "../../Skeleton/SkeletonElements";
import {
  BuyButton,
  CardContainer,
  CardContent,
  CardImg,
  CardTitle,
  CreatorImg,
  CreatorInfo,
  CreatorInfoContent,
  CreatorInfoWrapper,
  H6,
  InfoContentText,
  PlayButton,
  PlayContainer,
  SmallPlayContainer,
  TextLink,
} from "./DigitalPurchaseCardElements";
import { RiVideoFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import { getResizedImage } from "../../../utils/FileUploadsUtils";
import { useWebsiteTheme } from "../../WebsiteTheme/WebsiteThemeContext";

const DigitalPurchaseCard = ({ type, content, cardRef }) => {
  const { toggleModal, modalContent, isOpen, openModal, closeModal } =
    useModal();
    const {themeColors} = useWebsiteTheme()

  const { addAlert } = useAlert();
  const { currentUser } = useAuth();
  const { goTo, scrollToTop } = ScrollToTop();
  const { defaultAlbumArt, DEFAULT_IMAGE } = ImageUtils();
  const [isAlbumArtLoaded,setAlbumArtLoaded] = useState(false)
  const [albumArt, setAlbumArt] = useState(content?.albumArt)

  useEffect(() => {
    if(!isAlbumArtLoaded){
      getResizedImage(content?.albumArt).then(image => {
        setAlbumArt(image)
        
      }).catch(err => {
        setAlbumArt(content?.albumArt)
      })
      setAlbumArtLoaded(true)
    }
  }, [])
  function buy(purchaseType) {
    /* toggleModal();
    modalContent(<PurchaseModal type={purchaseType} content={content} />); */
  }

  const displayCardContent = () => {
    switch (type) {
      case CONTENT_TYPES.SONG:
        return (
          <CardContainer ref={cardRef} background={content?.color}>
            <div style={{ alignItems: "center", margin: "auto" }}>
              <SkeletonPurchaseCardImg
                onClick={() => {
                  if (content?.songId) {
                    /* openModal();
                    modalContent(
                      <OpenMusicStorePlayer content={content} type={type} />
                    ); */
                  }
                }}
                src={isAlbumArtLoaded ? albumArt : content?.albumArt ? content?.albumArt : defaultAlbumArt}
              >
                {content?.songId && (
                  <PlayContainer>
                    <PlayButton
                      style={{ width: "6rem", height: "6rem" }}
                      onClick={() => {
                        /* openModal();
                        modalContent(
                          <OpenMusicStorePlayer content={content} type={type} />
                        ); */
                      }}
                    />
                  </PlayContainer>
                )}
              </SkeletonPurchaseCardImg>
              <SmallPlayContainer>
                <PlayButton
                  
                  onClick={() => {
                    /* openModal();
                    modalContent(
                      <OpenMusicStorePlayer content={content} type={type} />
                    );*/
                  }} 
                />
              </SmallPlayContainer>
            </div>
            <CardContent>
              <CardTitle>
                <TextLink
                 alt={true}
                  //onClick={() => content?.songId ? goTo(`/song/${content?.songId}`) : {}}
                  to={"#"}
                >
                  {getSongTitle(content)}{" "}
                </TextLink>
              </CardTitle>
              <CreatorInfoWrapper>
                <CreatorInfo>
                  <CreatorInfoContent>
                    <H6>
                      {content?.explicit && <MdExplicit />}
                      {content?.video?.url && <RiVideoFill />}
                      {content?.artists && content?.artists?.length > 1
                        ? `Artists`
                        : `Artist`}
                    </H6>
                    <InfoContentText style={{ color: themeColors.secondaryBg }}>
                      {" "}
                      <TextLink
                      
                        onClick={() => {
                          return scrollToTop();
                        }}
                        to={"#"}
                      >
                       {getArtistsNames2(content?.artists, ARTIST_SEARCH_TYPES.NAME)
                    ?.length > 30
                    ? `${getArtistsNames2(
                        content?.artists,
                        ARTIST_SEARCH_TYPES.NAME
                      ).substring(
                        0,
                        25
                      )}...`
                    : getArtistsNames2(
                        content?.artists,
                        ARTIST_SEARCH_TYPES.NAME
                      )}
                      </TextLink>
                    </InfoContentText>
                  </CreatorInfoContent>
                  <CreatorInfoContent>
                    <H6>Price</H6>
                    <InfoContentText>
                      {content?.price && parseFloat(content?.price) > 0
                        ? `${content?.price} ${
                            content?.currency ? content?.currency : "XRP"
                          }`
                        : "FREE (PYP)"}
                    </InfoContentText>
                  </CreatorInfoContent>
                </CreatorInfo>
              </CreatorInfoWrapper>
              <CreatorInfoWrapper>
                <CreatorInfoContent>
                  <InfoContentText>
                    <TextLink
                      //onClick={() => content?.songId ? goTo(`/song/${content?.songId}`) : {}}
                      to={"#"}
                    >
                      View Song
                    </TextLink>
                  </InfoContentText>
                </CreatorInfoContent>
              </CreatorInfoWrapper>
            </CardContent>
          </CardContainer>
        );

      case CONTENT_TYPES.ALBUM:
        return (
          <CardContainer ref={cardRef} background={content?.color}>
            <div>
              <SkeletonPurchaseCardImg
                onClick={() => {
                  if (content?.albumId) {
                    /* openModal();
                    modalContent(
                      <OpenMusicStorePlayer content={content} type={type} />
                    ); */
                  }
                }}
                src={content?.albumArt ? content?.albumArt : defaultAlbumArt}
              >
                {content?.albumId && (
                  <PlayContainer>
                    <PlayButton
                      style={{ width: "6rem", height: "6rem" }}
                      onClick={() => {
                        /* openModal();
                        modalContent(
                          <OpenMusicStorePlayer content={content} type={type} />
                        ); */
                      }}
                    />
                  </PlayContainer>
                )}
              </SkeletonPurchaseCardImg>
              <SmallPlayContainer>
                <PlayButton
                 
                  onClick={() => {
                    /* openModal();
                    modalContent(
                      <OpenMusicStorePlayer content={content} type={type} />
                    ); */
                  }}
                />
              </SmallPlayContainer>
            </div>
            <CardContent>
              <CardTitle>
                <TextLink
                  style={{ color: themeColors.secondaryColor }}
                  to={"#"}
                >
                  {content?.albumName}
                </TextLink>
              </CardTitle>
              <CreatorInfoWrapper>
                <CreatorInfo>
                  <CreatorInfoContent>
                    <H6>
                      {content?.artists && content?.artists?.length > 1
                        ? "Artists"
                        : "Artist"}
                    </H6>
                    <InfoContentText style={{ color: themeColors.secondaryBg }}>
                      {" "}
                      <TextLink
                        
                        onClick={() => {
                          
                          return scrollToTop();
                        }}
                        to={"#"}
                      >
                        {getArtistsNames2(content?.artists, ARTIST_SEARCH_TYPES.NAME)
                    ?.length > 30
                    ? `${getArtistsNames2(
                        content?.artists,
                        ARTIST_SEARCH_TYPES.NAME
                      ).substring(
                        0,
                        25
                      )}...`
                    : getArtistsNames2(
                        content?.artists,
                        ARTIST_SEARCH_TYPES.NAME
                      )}
                      </TextLink>
                    </InfoContentText>
                  </CreatorInfoContent>
                  <CreatorInfoContent>
                    <H6>Price</H6>
                    <InfoContentText>
                      {content?.price
                        ? `${content?.price} ${
                            content?.currency ? content?.currency : "XRP"
                          }`
                        : "Pick Your Own Price"}
                    </InfoContentText>
                  </CreatorInfoContent>
                </CreatorInfo>
              </CreatorInfoWrapper>
              <CreatorInfoWrapper>
                <CreatorInfoContent>
                  <InfoContentText>
                    <TextLink
                      to={"#"}
                    >
                      View Album
                    </TextLink>
                  </InfoContentText>
                </CreatorInfoContent>
              </CreatorInfoWrapper>
            </CardContent>
          </CardContainer>
        );

      default:
        break;
    }
  };

  return <>{displayCardContent()}</>;
};

export default DigitalPurchaseCard;
