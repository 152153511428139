import React from 'react'
import OptionModal from '../OptionModal'
import { HoverOption } from '../MenuOptions/MenuOptionsElements'
import { useModal } from '../Modal/ModalContext';
import { UnavailableFeature } from '../MenuOptions';

const LabelArtistAdder = () => {
    const { openModal, closeModal, modalContent } = useModal();
  return (
    <OptionModal heading={'Label Artist Roster Adder'}>
        <HoverOption onClick={() => {
            openModal()
            modalContent(<UnavailableLabelFeature/>)
        }}>
            Create New Artist Profile
        </HoverOption>
        <HoverOption onClick={() => {
            openModal()
            modalContent(<UnavailableLabelFeature/>)
        }}>
            Claim Artist Profile
        </HoverOption>
        <HoverOption onClick={() => {
            openModal()
            modalContent(<UnavailableLabelFeature/>)
        }}>
            Request Artist For Label
        </HoverOption>
    </OptionModal>
  )
}

function UnavailableLabelFeature (){
    const { modalContent, openModal, closeModal } = useModal();
  return (
    <>
      <OptionModal heading={"This feature is currently being worked on"} 
      additionalText={'If you would like to make changes to your artist roster, contact the sonar muse team to manually make the changes for you'}>

        <HoverOption
          onClick={() => {
            modalContent();
            closeModal();
          }}
        >
          OK
        </HoverOption>
      </OptionModal>
    </>
  );
}

function LabelArtistCreator({}){
    /*
    Create artist profile with parameters that the label has control of
    if the label adds the artist wallet address then check if artist is registered on the platform.
    if artist is registered on platform, send email or push notification to confirm artist page being created
    */
}

function LabelArtistClaimer(){
    /*
        Search for unclaimed artist profile to add to roster
        if artist profile has a wallet address or email address
        We will send the artist a message alerting that a label will be claiming their profile
    */
}

function LabelArtistRequester(){
/*
    Search for artist from list of verified artist
    choose the control parameters that the label requests has options to have with the artist
    // send request out to artist
    // artist can edit request if they want
    // artist can either accept or decline request
    // Label can cancel request any time.
    \\ on decline - send decline notification to label
    \\ on accept - send accept notification to label and artist and add record label to artist profile
    \\ on edit: artist or label can either accept or decline label request


*/
}

export default LabelArtistAdder