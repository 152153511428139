import styled from "styled-components"

 export const BannerContainer = styled.div`
 
 background-color: ${props => props.theme.accentColor};
 height: 10vh;
 width: 100vw;
 display: flex;
 margin-top: 0.1%;
 
 justify-content: space-between;
 padding: 0.5rem calc((100vw - 1000px) / 2);
 margin-bottom: 1%;
 position: fixed;
 top: 0;
 z-index: 20;

 @media screen and (max-width: 768px) {
  height: 8vh;
  width: 98vw;
}

@media screen and (max-width: 480px) {
  height: 10vh;
  width: 98vw;
}
@media screen and (max-width: 380px) {
  height: 10vh;
  width: 98vw;
}


 `
 
 export const BannerH2 = styled.text`
 padding: 0 1rem;
 display: flex;
 align-items: center;
 font-size: 0.8rem;
 color: ${props => props.theme.secondaryBg};
 @media screen and (max-width: 768px) {
   font-size: 0.8em;
 }
 
 @media screen and (max-width: 480px) {
   font-size: 0.6em;
 }
 @media screen and (max-width: 380px) {
  font-size: 0.4em;
}
 
 `

 export const AppLogo = styled.img`
  color: #fff;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  font-weight: 700;
  position: relative;
  margin-bottom: 15px;
  &:hover{
    transform: scale(1.07);
    transition: 1s eas-out;
}
 `

 export const AppButtons = styled.div`
    display: flex;
    position: relative;
    padding-top: 3%;
    left:-5%;
    justify-content: flex-start;
    align-items: center;
 `

 export const Button = styled.a`
 display: flex;
 outline: none;
 cursor: pointer;
 justify-content: center;
 align-items:center;
 transition: all 0.2s ease-in-out;
 text-decoration: none;
 margin-left: 2%;
 margin-right:2%;
 `

 export const Img = styled.img`
 display: flex;
    width: 7rem;
    margin-bottom: 30px;
  
    @media screen and (max-width: 768px) {
		width: 6rem;
      margin-right: 5px;
      margin-bottom: 20px;
    }
    
    @media screen and (max-width: 480px) {
		width: 4rem;
      margin-right: 5px;
      margin-bottom: 15px;
    }

    &:hover{
      transform: scale(1.07);
      transition: 1s eas-out;
  }
 
 `