import React, { useEffect, useState } from 'react'
import { GlassContainer, MainDashContainer } from '../DashboardElements'
import RevenueCard from '../../Card/RevenueCard'
import { FaMonero, FaRegMoneyBillAlt } from 'react-icons/fa'
import { RevenueCards } from '../../Card/RevenueCard/RevenueCardElements'
import { ContentHeading } from '../../ContentWrapper/ContentWrapperElements'
import ContentTable from '../../ContentTable'
import { RiCoupon3Fill } from 'react-icons/ri'
import { BsDisc } from 'react-icons/bs'
import { AlbumsReleasesTable, SinglesReleasesTable, TopPlayedTable } from '../../ContentTable/ArtistTables'
import { CONTENT_TYPES } from '../../../utils/Enum'
import { getObjectLength } from '../../../utils'
import { EpisodesReleaseTable, TopEpisodeReleases } from '../../ContentTable/PodcasterTables'
import { TableSkeleton } from '../../Skeleton'
import LabelArtistsTable from '../../ContentTable/RecordLabelTables'
import { getArtistDigitalSales } from '../../../utils/dbUtils/artistUtils'

const MainDash = ({type, content, isLoaded, id}) => {
    const [isRevLoaded, setRevLoaded] = useState(false)
    const [digitalSales, setDigitalSales] = useState([])

    useEffect(() => {
      switch (type) {
        case CONTENT_TYPES.ARTIST:
            getArtistRevenue("all")
            break;
      
        default:
            setRevLoaded(true)
            break;
      }
    
      return () => {
      }
    }, [id])

    const getArtistRevenue = async(duration) => {
        await getArtistDigitalSales(id, "all").then(
            digi => {
                setDigitalSales(digi)
                setRevLoaded(true)
            }
        )
    }
    
  return (
    <>
    <MainDashContainer>
    {type === CONTENT_TYPES.ARTIST && <>
        <ArtistDashContent content={content} isLoaded={isLoaded}/>
    </>}
    {type === CONTENT_TYPES.PODCASTER && <>
        <PodcasterDashContent content={content} isLoaded={isLoaded}/>
    </>}
    {type === CONTENT_TYPES.RECORD_LABEL && <>
        <RecordLabelDashContent content={content} isLoaded={isLoaded}/>
    </>}
  </MainDashContainer>
  </>
  )
}

function ArtistDashContent({isLoaded,content}){
    return (
        <>
        {isLoaded && <>
            {content?.topSongs?.length > 0 && <TopPlayedTable topSongs={content?.topSongs}/>}
            {getObjectLength(content?.discography) > 0 &&
            <>{content?.discography?.songs?.length > 0 && 
            <SinglesReleasesTable releases={content?.discography?.songs} />}
            {content?.discography?.albums?.length > 0 && 
            <AlbumsReleasesTable releases={content?.discography?.albums} />}</>}
        </>}
        {!isLoaded && <>
            <TableSkeleton/>
            <TableSkeleton/>
            <TableSkeleton/>
        </>}
        </>
    )
}

function PodcasterDashContent({isLoaded,content}){
    return (
        <>
            {isLoaded && <>{content?.topEpisodes?.length > 0 && <TopEpisodeReleases releases={content?.topEpisodes}/>}
            {content?.episodes?.length > 0 && 
            <EpisodesReleaseTable releases={content?.episodes} />}</>}
             {!isLoaded && <>
            <TableSkeleton/>
            <TableSkeleton/>

        </>}
        </>
    )
}
function RecordLabelDashContent({isLoaded,content}){
    return (
        <>
            {isLoaded && <>{content?.artists?.length > 0 && <LabelArtistsTable releases={content?.artists}/>}
            {content?.discography?.albums.length > 0 && 
            <AlbumsReleasesTable releases={content?.discography?.albums} />}
            {content?.discography?.songs.length > 0 && 
            <AlbumsReleasesTable releases={content?.discography?.songs} />}
            </>}
             {!isLoaded && <>
            <TableSkeleton/>
            <TableSkeleton/>
            <TableSkeleton/>

        </>}
        </>
    )
}

export default MainDash