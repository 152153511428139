import React from 'react'
import { ThemeProvider } from 'styled-components'
import appStore from '../../images/app_store_badge.png'
import playStore from '../../images/google_play_badge.png'
import { Theme } from '../WebsiteThemeElements'

import { 
    Button, 
    BannerContainer, 
    BannerH2, 
    AppLogo, 
    AppButtons,
    Img 
} from './AppBannerElements'


const AppBanner = ({appName, logo, playStoreLink, appStoreLink}) => {
   
    return (
        <>
        <BannerContainer>
            <BannerH2>
            <BannerH2>
            <img style={ {borderRadius: '50px', marginRight: '1%', height:'2.5rem'}} src={logo} alt={appName}/>

            </BannerH2>
                Download the {appName} Wallet to Register

            </BannerH2>
           
                <AppButtons>
                    <Button href={playStoreLink}>
                        <Img src={playStore} alt='Google Play'/>
                    </Button>
                    <Button href={appStoreLink}>
                        <Img src={appStore} alt='App Store'/>
                    </Button>
                </AppButtons>
            
        </BannerContainer>
        </>
    )
}

export default AppBanner