import React, { useEffect, useState } from "react";
import ContentList from "../ContentList";
import ContentWrapper from "../ContentWrapper";
import {
  AlbumArt,
  AlbumArtContainer,
  AlbumArtWrapper,
  DetailsWrapper,
  Img,
  MusicAboutWrapper,
  MusicContentWrapper,
  MusicDetailsContainer,
  MusicDetailsWrapper,
  MusicHeaderWrapper,
  MusicHeading,
  MusicHeadingBgImage,
  MusicHeadingContainer,
  MusicHeadingImage,
  MusicHeadingImageWrapper,
  MusicHeadingSmall,
  MusicHeadingTextWrapper,
  MusicHeadingWrapper,
  MusicInfoWrapper,
  MusicNav,
  MusicNavLink,
  MusicOptionsContainer,
  MusicPageContainer,
  MusicPageWrapper,
  MusicPlayerWrapper,
  MusicPlayPauseButton,
  MusicPriceWrapper,
  MusicSubheading,
  NftBadgeWrapper,
  NftLogo,
  Option,
  OptionButton,
  OptionMenuButton,
  ProgressBar,
  ProgressBarWrapper,
  PurchaseButton,
  Select,
  SongDetailsText,
  SongDetailsWrapper,
  SubHeading,
  Text,
} from "./MusicPageElements";
import { FaPlay, FaPause, FaShare, FaRegMoneyBillAlt } from "react-icons/fa";
import NftBadge from "../../images/nft_badge.png";
import { FcLike, FcLikePlaceholder } from "react-icons/fc";
import { GoReport } from "react-icons/go";
import { AiOutlineEye, AiFillEye, AiOutlineDeliveredProcedure } from "react-icons/ai";
import { filterLikes, getSongTitle, timestampToDate } from "../../utils";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate, useLocation } from "react-router-dom";
import {
  ALERT_TYPES,
  CONTENT_TYPES,
  MUSIC_PLAYING_FROM,
  MUSIC_PURCHASE_OPTIONS,
  SLIDER_TYPES,
} from "../../utils/Enum";
import { BsShuffle, BsThreeDots } from "react-icons/bs";
import MenuOptions from "../MenuOptions";
import ImageUtils from "../../utils/ImageUtils";
import { NavContainer } from "../NavLink/NavLinkElements";
import { useModal } from "../Modal/ModalContext";
import MusicInfo from "./MusicInfo";
import { Slider } from "../Slider";
import { useAlert } from "../Alert/AlertContext";
import MusicEditPage from "../MusicEditPage";
import CommentForm from "../CommentForm";
import CommentsSection from "../CommentsSection";
import useMusic from "../../hooks/useMusic";
import { shuffleSongs } from "../../utils/MusicUtils";
import { MdExplicit } from "react-icons/md";
import ScrollToTop from "../ScrollToTop";
import AlbumStats from "../MusicStatsPage/AlbumStats";
import OptionModal from "../OptionModal";
import { HoverOption } from "../MenuOptions/MenuOptionsElements";
import { CollectionSongList, MusicStoreSongList } from "../ContentList/SongList";

function AlbumPage({
  id,
  album,
  location,
}) {
  const { currentUser, currentProfile, profiles } = useAuth();
  const { modalContent, toggleModal, isOpen, openModal, closeModal } =
    useModal();
    const {goTo} = ScrollToTop()
  const [currentTab, setTab] = useState('tracklist')
  const navigate  = useNavigate();
  const { defaultAlbumArt } = ImageUtils();
  const { addAlert } = useAlert();
  const [isEditing, setEditing] = useState(false);

  useEffect(() => {
    const url = new URL(window.location.href);
        const params = new URLSearchParams(url.search);
    if (params?.has("edit")) {
      if (currentUser?.uid === album?.uploadedBy) {
        setEditing(true);
      }
    } else {
      setEditing(false);
    }
    return () => {}
  });

  useEffect(() => {
    if(currentProfile !== 'artist' && currentProfile !== 'label'){
    navigate(-1)
    }else{
      
    if((currentUser?.uid !== album?.uploadedBy && (currentProfile === 'label' && ((profiles?.label?.artists?.length > 0  && !profiles?.label?.artists?.some(a => album?.artists?.includes(artist => artist?.artistId === a?.artistId))) || !profiles?.label?.artists)) ) && (currentProfile === 'artist' && !album?.artists?.some(a => a?.artistId === profiles?.artist?.artistId) )){
      navigate(-1)
    }
  }
    return () => {}
  }, [id])


  return (
    <MusicPageWrapper>
      <MusicHeadingContainer>
        <MusicHeadingBgImage
          color={album?.color}
          src={
            album?.albumArt
              ? album.albumArt
              : album?.songs && album?.songs[0]?.albumArt
              ? album?.songs[0].albumArt
              : defaultAlbumArt
          }
          alt={album.albumName}
        ></MusicHeadingBgImage>
        <MusicHeadingWrapper>
          <MusicHeadingImageWrapper>
            <MusicHeadingImage
              color={album?.color}
              src={
                album?.albumArt
                  ? album.albumArt
                  : album?.songs && album?.songs[0]?.albumArt
                  ? album?.songs[0].albumArt
                  : defaultAlbumArt
              }
              alt={album.albumName}
            />
          </MusicHeadingImageWrapper>
          <MusicHeadingTextWrapper>
            <SubHeading><small>Album</small></SubHeading>
            <MusicHeading>{album?.albumName}{album?.songs?.some(s => s?.explicit === true) && <MdExplicit style={{ marginLeft: "1%" }} />} </MusicHeading>
            <MusicSubheading>
            <MusicHeading
                style={{ cursor: "pointer" }}
              >
                {album?.artists?.map((artist, index) => {
                  if (index < album?.artists?.length - 1) {
                    return <div>{artist.artistName + " • "}</div>
                  } else {
                    return <div onClick={() =>
                      goTo(`#`)
                    }>{artist?.artistName}</div>;
                  }
                })}
              </MusicHeading>
            </MusicSubheading>
           
              <MusicHeadingSmall>{album?.genre?.name}•{`${album?.songs?.length} tracks`}•{new Date(timestampToDate(album?.releaseDate)).getFullYear()}</MusicHeadingSmall>
            
            <MusicOptionsContainer>
              {!isEditing && (
                <>
                {currentUser?.uid === album?.uploadedBy &&<> <MusicPlayPauseButton onClick={() => {
                    const params = new URLSearchParams({ edit: true });
                    navigate(`${location.pathname}?edit=true`,{
                      replace:true},
                    );

                 }}>
                   Edit
                 </MusicPlayPauseButton>
                 
                 <MusicPlayPauseButton transparent onClick={() => {
                  openModal()
                    modalContent(<OptionModal heading={'Coming Soon'} additionalText={'Distribute your album to all major web2 and web3 platforms and earn your royalties in XRP'}>
                      <HoverOption onClick={() => {
                        closeModal()
                        modalContent()
                      }}>OK</HoverOption>
                    </OptionModal>)

                 }}>
                   Distribute <AiOutlineDeliveredProcedure style={{marginLeft: '2%'}}/>
                 </MusicPlayPauseButton></>}
                
                </>
              )}
            </MusicOptionsContainer>
          </MusicHeadingTextWrapper>
        </MusicHeadingWrapper>
      </MusicHeadingContainer>
      {!isEditing && (
        <>
          <NavContainer>
            <MusicNav>
              <MusicNavLink
                onClick={() => setTab("tracklist")}
                active={currentTab === "tracklist"}
              >
               {album?.albumName} Tracklist
              </MusicNavLink>
              <MusicNavLink
                onClick={() => setTab("about")}
                active={currentTab === "about"}
              >
                About {album?.albumName}
              </MusicNavLink>
              <MusicNavLink
                onClick={() => setTab("stats")}
                active={currentTab === "stats"}
              >
                 Statistics
              </MusicNavLink>
            </MusicNav>
          </NavContainer>
          <MusicDetailsContainer>

            {currentTab === 'tracklist' && (
              <ContentWrapper heading={`Tracklist`}>
                  <CollectionSongList content={album?.songs} onButtonClick={(i) => goTo(`/song/${album?.songs[i]}`)}/>
              </ContentWrapper>
            )}
            
            {currentTab === "about" && (
              <MusicInfo type={CONTENT_TYPES.ALBUM} content={album} />
            )}
            {currentTab === "stats" && (
              <><AlbumStats album={album?.albumId} />
              </>
            )}
       
          </MusicDetailsContainer>
        </>
      )}
      {isEditing && <MusicEditPage type={"album"} content={album} />}
    </MusicPageWrapper>
  );
}

export default AlbumPage;
