import React, { useEffect, useState } from 'react'
import PodcasterSettings from '../../../components/PodcasterProfile/PodcasterProfileContent/PodcasterSettings'
import usePodcaster from '../../../hooks/usePodcaster'
import { useNavigate, useParams } from 'react-router-dom'
import { useAuth } from '../../../contexts/AuthContext'
import LoadingScreen from '../../../components/LoadingScreen'
import { getObjectLength } from '../../../utils'

const PodcasterProfileSettings = () => {
    const {currentUser, profiles} = useAuth()
    const [isLoaded, setLoaded] = useState(false)
    const [profile, setProfile] = useState({})
    const {podcasterId} = useParams()
    const {getPodcasterProfile} = usePodcaster({podcasterId})
    const navigate = useNavigate()


    const getProfile = async() => {
      setLoaded(false)
      if(profiles?.podcaster?.podcasterId !== podcasterId){
        navigate(-1)
    }
    await getPodcasterProfile(podcasterId).then(podcaster => {
      if(getObjectLength(podcaster) > 0 && podcaster !== false){
        setProfile(podcaster)
        setLoaded(true)
        return
      }
      navigate(-1)
    }).catch(err => {
      navigate(-1)
      return
    })
    }

    useEffect(() => {
      getProfile()
      return () => {
      }
    }, [podcasterId])
    
  return (
    <>
    {!isLoaded && <LoadingScreen/>}
    {isLoaded &&  <PodcasterSettings 
                      getProfile={getPodcasterProfile}
                      podcasterId={currentUser?.profile?.podcaster?.podcasterId}
                      podcaster={currentUser?.profile?.podcaster?.profile}
                      podcasterSettings={currentUser?.profile?.podcaster?.profile?.podcasterSettings}
                      gravatarProfile={currentUser?.profile?.podcaster?.gravatarProfile}
                      about={{
                        photos: currentUser?.profile?.podcaster?.gravatarProfile?.gallery,
                        accounts: currentUser?.profile?.podcaster?.gravatarProfile?.verified_accounts,
                        bio:
                        currentUser?.profile?.podcaster?.profile?.podcasterSettings?.bioType ===
                          "custom"
                            ? currentUser?.profile?.podcaster?.profile?.bio
                            : currentUser?.profile?.podcaster?.gravatarProfile?.description,
                      }}/>}
    </>
  )
}

export default PodcasterProfileSettings