import React from 'react'
import PodcasterRegistration from '../../../components/RegistrationForm/PodcasterRegistration'
import { Access } from '../../../components/Navbar/Data'
import Navbar from '../../../components/Navbar'

const PodcasterRegistrationPage= () => {
  return (
    <><Navbar navInfo={Access} />
    <PodcasterRegistration/></>
  )
}

export default PodcasterRegistrationPage