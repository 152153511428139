import React, { useEffect, useState } from "react";
import ImageUtils from "../../../utils/ImageUtils";
import {
  convertAlbumsToSonarMuseSchema,
  convertSongsToSonarMuseSchema,
  getNFTImage,
} from "../../../utils/nfTokensUploads";
import ScrollToTop from "../../ScrollToTop";
import {
  SkeletonMusicCard,
  SkeletonPurchaseCardImg,
} from "../../Skeleton/SkeletonElements";
import { useUser } from "../../../hooks/useUser";
import { ALERT_TYPES, AUDIO_EXPORT_TYPES, CONTENT_TYPES, SLIDER_TYPES } from "../../../utils/Enum";
import { Slider } from "../../Slider";
import { Theme } from "../../WebsiteThemeElements";
import LoadingScreen, { ModalLoadingScreen } from "../../LoadingScreen";
import { useModal } from "../../Modal/ModalContext";
import OptionModal from "../../OptionModal";
import { HoverOption } from "../../MenuOptions/MenuOptionsElements";
import {
  CardContainer,
  CardContent,
  CardTitle,
  CreatorInfo,
  CreatorInfoContent,
  CreatorInfoWrapper,
  DownloadButton,
  H6,
  PlayButton,
  SmallPlayContainer,
  TextLink,
} from "./DownloadCardElements";
import { getDownloadInfo } from "../../../utils/dbUtils/downloadsUtils";
import { useAlert } from "../../Alert/AlertContext";
import useMusic from "../../../hooks/useMusic";
import { downloadFiles, getResizedImage } from "../../../utils/FileUploadsUtils";

const DownloadCard = ({ content, onCardClick, cardRef }) => {
  const { goTo } = ScrollToTop();
  const { addAlert } = useAlert();
  const { downloadMusic, requestMusicDownload } = useMusic();
  const { openModal, modalContent, closeModal } = useModal();
  const { defaultAlbumArt, DEFAULT_IMAGE } = ImageUtils();
  const [downloadInfo, setDownloadInfo] = useState({});
  const [type, setType] = useState(
    content?.albumName
      ? CONTENT_TYPES.ALBUM
      : content?.songName
      ? CONTENT_TYPES.SONG
      : CONTENT_TYPES.NFTOKEN
  );



  useEffect(() => {
    getDownloadInfo(
      content?.owner,
      type,
      content?.albumId
        ? content?.albumId
        : content?.songId
        ? content?.songId
        : content?.nftoken?.NFTokenID,
      setDownloadInfo
    );

    return () => {};
  }, [content]);

  const musicCard = () => {
    return (
      <CardContainer ref={cardRef}>
        <div style={{ alignItems: "center", margin: "auto" }}>
          <SkeletonPurchaseCardImg
            onContextMenu={(e) => {
              e.preventDefault();
            }}
            src={
              content?.albumArt
                ? content?.albumArt
                : content?.nftoken?.metadata?.image
                ? getNFTImage(content?.nftoken?.metadata?.image)
                : defaultAlbumArt
            }
            onClick={() => {
              if (content?.tokens?.length > 1) {
                openModal();
                return modalContent(
                  <OptionModal heading={`${ content?.nftoken?.metadata?.collection?.name
                    ? content?.nftoken?.metadata?.collection?.name
                    : `${content?.Issuer} | Taxon: ${content?.Taxon}`} (${content?.tokens?.length})`}
                  additionalText={"Select a Token to Download"}>
                  <NFTokenDownloadModal
                    onClick={onCardClick}
                    collection={content?.tokens}
                  /></OptionModal>
                )
              } else {
                openModal();
                return modalContent(
                  <DownloadCardOptions
                    content={content}
                    onClick={onCardClick}
                  />
                );
              }
            }}
          />
          {((type === CONTENT_TYPES.NFTOKEN && (content?.tokens?.length === 1 || !content?.tokens)) ||
            type !== CONTENT_TYPES.NFTOKEN) && (
            <SmallPlayContainer><PlayButton
              onClick={() => {
                /* openModal();
                modalContent(
                  <OpenMusicStorePlayer
                    content={
                      content?.nftoken
                        ? {
                            ...content?.nftoken?.metadata,
                            albums:
                              content?.nftoken?.metadata?.albums?.length > 0
                                ? convertAlbumsToSonarMuseSchema(
                                    content?.nftoken?.metadata?.albums,
                                    content?.nftoken?.metadata?.image
                                  )
                                : [],
                            songs:
                              content?.nftoken?.metadata?.songs?.length > 0
                                ? convertSongsToSonarMuseSchema(
                                    content?.nftoken?.metadata?.songs,
                                    content?.nftoken?.metadata?.image
                                  )
                                : [],
                          }
                        : content
                    }
                    type={type}
                  />
                ); */
              }}
            /></SmallPlayContainer>
          )}
        </div>

        <CardContent>
          <H6>
            {" "}
            {content?.albumName
              ? "Album"
              : content?.songName
              ? "Song"
              : content?.nftoken
              ? content?.tokens?.length > 1
                ? `NFToken Collection (${content?.tokens?.length})`
                : "NFToken"
              : undefined}
          </H6>

          <CardTitle>
            {content?.albumName
              ? content?.albumName
              : content?.songName
              ? content?.songName
              : content?.nftoken
              ? content?.tokens?.length > 1
                ? content?.nftoken?.metadata?.collection?.name
                  ? content?.nftoken?.metadata?.collection?.name
                  : content?.nftoken?.metadata?.name
                  ? content?.nftoken?.metadata?.name
                  : `${content?.nftoken?.NFTokenID?.substring(
                      0,
                      5
                    )}...${content?.nftoken?.NFTokenID?.substring(
                      content?.nftoken?.NFTokenID?.length - 5,
                      content?.nftoken?.NFTokenID?.length - 1
                    )}`
                : content?.nftoken?.metadata
                ? content?.nftoken?.metadata?.name
                  ? content?.nftoken?.metadata?.name
                  : content?.nftoken?.metadata?.collection
                  ? content?.nftoken?.metadata?.collection?.name
                  : `${content?.nftoken?.NFTokenID?.substring(
                      0,
                      5
                    )}...${content?.nftoken?.NFTokenID?.substring(
                      content?.nftoken?.NFTokenID?.length - 5,
                      content?.nftoken?.NFTokenID?.length - 1
                    )}`
                : `${content?.nftoken?.NFTokenID?.substring(
                    0,
                    5
                  )}...${content?.nftoken?.NFTokenID?.substring(
                    content?.nftoken?.NFTokenID?.length - 5,
                    content?.nftoken?.NFTokenID?.length - 1
                  )}`
              : undefined}
          </CardTitle>
          <br />
          <CreatorInfoWrapper>
            {type === CONTENT_TYPES.NFTOKEN && content?.tokens?.length > 1 && (
              <div style={{ justifyContent: "center" }}>
                <DownloadButton onClick={ () => {
                  openModal();
                return modalContent(
                  <OptionModal heading={`${ content?.nftoken?.metadata?.collection?.name
                    ? content?.nftoken?.metadata?.collection?.name
                    : `${content?.Issuer} | Taxon: ${content?.Taxon}`} (${content?.tokens?.length})`}
                  additionalText={"Select Token to Download"}>
                  <NFTokenDownloadModal
                    onClick={onCardClick}
                    collection={content?.tokens}
                  /></OptionModal>
                )}}>View Tokens</DownloadButton>
              </div>
            )}
            {((type === CONTENT_TYPES.NFTOKEN && (content?.tokens?.length === 1 || !content?.tokens)) || type !== CONTENT_TYPES.NFTOKEN) && (
              <DownloadButton
                onClick={async () => {
                  if (downloadInfo?.url && downloadInfo?.ref) {
                    addAlert({
                      title: `${type[0].toUpperCase()}${type.substring(
                        1
                      )} Download`,
                      type: ALERT_TYPES.SUCCESS,
                      message: `Downloading ${type[0].toUpperCase()}${type.substring(
                        1
                      )}`,
                    });
                    return await downloadFiles(downloadInfo);
                  }
                  if (
                    downloadInfo?.status === "processing" ||
                    downloadInfo?.status === "request"
                  ) {
                    addAlert({
                      title: `Processing Download`,
                      type: ALERT_TYPES.WARNING,
                      message: `Download Processing You Will Receive a Notification Once The Download is Ready`,
                    })
                    return;
                  }
                  try{
                    addAlert({
                      title: "Requesting Download",
                      type: ALERT_TYPES.INFO,
                    });
                    console.log(type === CONTENT_TYPES.NFTOKEN && content)

                    let download = await requestMusicDownload(content.owner, type,content?.albumId
                      ? content?.albumId
                      : content?.songId
                      ? content?.songId
                      : content?.nftoken?.NFTokenID, AUDIO_EXPORT_TYPES.MP3)
                    if(download?.url && download?.ref){
                        downloadFiles(download)
                    }
                    if(download?.message){
                      addAlert({
                        title: "Processing Download",
                        type: ALERT_TYPES.SUCCESS,
                        message: `Download Processing. You Will Receive a Notification Once The Download is Ready`,
                      });
                      if(download?.error){
                        addAlert({
                          title: `Download Error`,
                          type: ALERT_TYPES.DANGER,
                          message: `Downloading Could Not Be Processed. Please Try Again Later`,
                        })
                      }
                    }
                  }catch(err){
                    addAlert({
                      title: `Download Error`,
                      type: ALERT_TYPES.DANGER,
                      message: `Downloading Could Not Be Processed. Please Try Again Later`,
                    })
                  }
                }}
              >
                {(downloadInfo?.url && downloadInfo?.ref)
                  ? `Download Ready`
                  : downloadInfo?.status === "processing" ||
                    downloadInfo?.status === "request"
                  ? "Processing Download..."
                  : `Request Download`}
              </DownloadButton>
            )}
            {((type === CONTENT_TYPES.NFTOKEN &&
              content?.tokens?.length === 1) ||
              type !== CONTENT_TYPES.NFTOKEN) && (
              <CreatorInfo>
                <CreatorInfoContent><TextLink>View {type[0].toUpperCase()}{type.substring(1)}</TextLink></CreatorInfoContent>
              </CreatorInfo>
            )}
          </CreatorInfoWrapper>
        </CardContent>
      </CardContainer>
    );
  };

  return <>{musicCard()}</>;
};

export const NFTokenDownloadModal = ({collection, onClick }) => {
  const [isLoaded, setLoaded] = useState(false);
  const [NFTokens, setNFTokens] = useState([]);
  const { getNFTokenDetails } = useUser({});

  useEffect(() => {
    getNFTokens();
  }, []);

  const getNFTokens = async () => {
    let tokens = [];
    for (let i = 0; i < collection.length; i++) {
      await getNFTokenDetails(collection[i]?.NFTokenID)
        .then((data) => {
          //console.log('tokenInfo: ',data)
          if (data && !data.error) {
            tokens.push(data);
          } else {
            tokens.push(collection[i]);
          }
        })
        .catch((err) => {
          console.error(err);
          tokens.push(collection[i]);
        });
    }
    setNFTokens(tokens);
    setLoaded(true);
  };

  return (
    <>
      {isLoaded && NFTokens?.length > 0 && (
            <Slider
              heightScroll={NFTokens?.length > 3 && true}
              type={SLIDER_TYPES.DOWNLOAD}
              onSlideClick={onClick}
              slides={NFTokens.map((nft) => {
                return {
                  ...nft,
                  nftoken: {
                    ...nft.nftoken,
                    NFTokenID: nft.nftoken.nft_id,
                    Issuer: nft.nftoken.issuer,
                    Owner: nft?.nftoken?.owner,
                    URI: nft?.nftoken?.uri,
                    Taxon: nft?.nftoken?.nft_taxon,
                  },
                };
              })}
              id={"NFTokens"}
            />
      )}
      {!isLoaded && NFTokens.length === 0 && (
        <ModalLoadingScreen transparent={true} text={"Loading NFTokens. Please wait"}/>
      )}
    </>
  );
};

export const DownloadCardOptions = ({ content, onClick }) => {
  const { goTo } = ScrollToTop();
  const { toggleModal, modalContent, isOpen, openModal, closeModal } =
    useModal();

  return (
    <>
      <OptionModal
        heading={
          content?.albumName
            ? content?.albumName
            : content?.songName
            ? content?.songName
            : content?.nftoken
            ? content?.tokens?.length > 1
              ? content?.nftoken?.metadata?.collection?.name
                ? content?.nftoken?.metadata?.collection?.name
                : content?.nftoken?.metadata?.name
                ? content?.nftoken?.metadata?.name
                : `${content?.nftoken?.NFTokenID?.substring(
                    0,
                    5
                  )}...${content?.nftoken?.NFTokenID?.substring(
                    content?.nftoken?.NFTokenID?.length - 5,
                    content?.nftoken?.NFTokenID?.length - 1
                  )}`
              : content?.nftoken?.metadata
              ? content?.nftoken?.metadata?.name
                ? content?.nftoken?.metadata?.name
                : content?.nftoken?.metadata?.collection
                ? content?.nftoken?.metadata?.collection?.name
                : `${content?.nftoken?.NFTokenID?.substring(
                    0,
                    5
                  )}...${content?.nftoken?.NFTokenID?.substring(
                    content?.nftoken?.NFTokenID?.length - 5,
                    content?.nftoken?.NFTokenID?.length - 1
                  )}`
              : `${content?.nftoken?.NFTokenID?.substring(
                  0,
                  5
                )}...${content?.nftoken?.NFTokenID?.substring(
                  content?.nftoken?.NFTokenID?.length - 5,
                  content?.nftoken?.NFTokenID?.length - 1
                )}`
            : undefined
        }
      >
        <HoverOption
          onClick={() => {
            /* openModal();
            modalContent(
              <OpenMusicStorePlayer
                content={
                  content?.albumId || content?.songId
                    ? content
                    : {
                        ...content?.nftoken?.metadata,
                        albums:
                          content?.nftoken?.metadata?.albums?.length > 0
                            ? convertAlbumsToSonarMuseSchema(
                                content?.nftoken?.metadata?.albums,
                                content?.nftoken?.metadata?.image
                              )
                            : [],
                        songs:
                          content?.nftoken?.metadata?.songs?.length > 0
                            ? convertSongsToSonarMuseSchema(
                                content?.nftoken?.metadata?.songs,
                                content?.nftoken?.metadata?.image
                              )
                            : [],
                      }
                }
                type={
                  content?.albumId
                    ? CONTENT_TYPES.ALBUM
                    : content?.songId
                    ? CONTENT_TYPES.SONG
                    : CONTENT_TYPES.NFTOKEN
                }
              />
            ); */
          }}
        >
          Play{" "}
          {content?.albumId ? "Album" : content?.songId ? "Song" : "NFToken"}
        </HoverOption>
        <HoverOption
          onClick={() => {
            content?.albumId
              ? goTo(`/album/${content?.albumId}`)
              : content?.songId
              ? goTo(`/song/${content?.songId}`)
              : goTo(`/nftoken/${content?.nftoken?.NFTokenID}`);
            closeModal();
            modalContent();
          }}
        >
          View{" "}
          {content?.albumId ? "Album" : content?.songId ? "Song" : "NFToken"}
        </HoverOption>
        <HoverOption
          onClick={() => {
            onClick(content);
            closeModal();
            modalContent();
          }}
        >
          Download
        </HoverOption>
      </OptionModal>
    </>
  );
};

export default DownloadCard;
