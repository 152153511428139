import React, { useEffect, useState } from "react";
import { useAuth } from "../../../contexts/AuthContext";
import useRecordLabel from "../../../hooks/useRecordLabel";
import { SLIDER_TYPES } from "../../../utils/Enum";
import ContentWrapper from "../../ContentWrapper";
import { NavContainer } from "../../NavLink/NavLinkElements";
import { Slider } from "../../Slider";
import RecordLabelAbout from "./RecordLabelAbout";
import RecordLabelArtists from "./RecordLabelArtists";
import {
  RecordLabelProfileNav,
  RecordLabelProfileNavLink,
} from "./RecordLabelProfileContentElements";
import { getObjectLength } from "../../../utils";
import RecordLabelDiscography from "./RecordLabelDiscography";
import RecordLabelPurchases from "./RecordLabelPurchases";
import RecordLabelSettings from "./RecordLabelSettings";
import RecordLabelStats from "./RecordLabelStats";
import { useLocation, useNavigate } from "react-router-dom";

const RecordLabelProfileContent = ({ profile, labelId, background, about, getProfile  }) => {
  const { currentUser } = useAuth();
  const location = useLocation()
  const navigate  = useNavigate()
  const { getRecordLabelArtists, getRecordLabelDiscography } = useRecordLabel({id: labelId});
  const [isStats, setStatsActive] = useState(false);
  const [currentTab, setTab] = useState("discography");
  const [isPurchase, setPurchaseActive] = useState(false);
  const [isDiscography, setDiscographyActive] = useState(true);
  const [isArtists, setArtistsActive] = useState(false);
  const [isAbout, setAboutActive] = useState(false);
  const [isSettings, setSettingsActive] = useState(false);
  const [isArtistLoaded, setArtistLoaded] = useState(false);
  const [isDiscogLoaded, setDiscogLoaded] = useState(false);
  const [labelArtists, setLabelArtists] = useState([]);
  const [labelDiscography, setLabelDiscography] = useState({});

  useEffect(() => {
    if(window?.location?.search.includes(`tab=`)){
      const url = new URL(window.location.href);
      const params = new URLSearchParams(url.search)
      setCurrentTab(params.get("tab"))
    }else{
      setCurrentTab()
    }
    return () => {}
  }, []);

    const setCurrentTab = (tab) => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search)
    switch (tab) {
      case "discography":
        setTab(tab);
        params.set("tab", tab)
        setDiscographyActive(true);
        setPurchaseActive(false);
        setArtistsActive(false);
        setSettingsActive(false);
        setAboutActive(false);
        setStatsActive(false);
        break;
      case "artists":
        setTab(tab);
        params.set("tab", tab)
        setPurchaseActive(false);
        setArtistsActive(true);
        setDiscographyActive(false);
        setSettingsActive(false);
        setAboutActive(false);
        setStatsActive(false);
        break;
      case "purchase":
        setTab(tab);
        params.set("tab", tab)
        setPurchaseActive(true);
        setArtistsActive(false);
        setDiscographyActive(false);
        setSettingsActive(false);
        setAboutActive(false);
        setStatsActive(false);
        break;
      case "about":
        setTab(tab);
        params.set("tab", tab)
        setDiscographyActive(false);
        setArtistsActive(false);
        setPurchaseActive(false);
        setSettingsActive(false);
        setStatsActive(false);
        setAboutActive(true);
        break;

      case "settings":
        params.set("tab", tab)
        setSettingsActive(true);
        setArtistsActive(false);
        setPurchaseActive(false);
        setDiscographyActive(false);
        setAboutActive(false);
        setStatsActive(false);
        setTab(tab);
        break;

      case "stats":
        params.set("tab", tab)
        setSettingsActive(false);
        setArtistsActive(false);
        setPurchaseActive(false);
        setDiscographyActive(false);
        setAboutActive(false);
        setStatsActive(true);
        setTab(tab);
        break;

      default:
        setTab("discography");
        params.delete("tab")
        setDiscographyActive(true);
        setArtistsActive(false);
        setPurchaseActive(false);
        setStatsActive(false);
        setSettingsActive(false);
        setAboutActive(false);
        break;
    }
    navigate(`${params.toString().length > 0 ? `?${params.toString()}` : '#'}`,{
      replace:true},
    );
  };

  useEffect(() => {
    setArtistLoaded(false);
    getRecordLabelArtists(labelId)
      .then((artists) => {
        if (artists?.length > 0) {
          setLabelArtists(artists);
        }
        setArtistLoaded(true);
      })
      .catch((err) => {
        console.error(err);
        setArtistLoaded(true);
      });
      return () => {}
  }, [profile]);

  useEffect(() => {
    setDiscogLoaded(false);
    getRecordLabelDiscography(labelId)
      .then((discog) => {
        if (discog?.songs?.length > 0 || discog?.albums?.length > 0) {
          setLabelDiscography(discog);
        }
        setDiscogLoaded(true);
      })
      .catch((err) => {
        console.error(err);
        setDiscogLoaded(true);
      });
      return () => {}
  }, [profile]);

  const getPurchasableMusic = () => {
      return {
        albums: labelDiscography?.albums?.length > 0 ? labelDiscography?.albums?.filter(a => !a?.isDownloadable) : [],
        songs: labelDiscography?.songs?.length > 0 ? labelDiscography?.songs?.filter(s => !s?.isDownloadable) : []
      }
  }

  return (
    <>
      <NavContainer>
        <RecordLabelProfileNav>
          <RecordLabelProfileNavLink
            background={background}
            active={isDiscography}
            onClick={() => setCurrentTab("discography")}
          >
            Discography
          </RecordLabelProfileNavLink>
          <RecordLabelProfileNavLink
            background={background}
            active={isAbout}
            onClick={() => setCurrentTab("about")}
          >
            About
          </RecordLabelProfileNavLink>
          <RecordLabelProfileNavLink
            background={background}
            active={isArtists}
            onClick={() => setCurrentTab("artists")}
          >
            Artists
          </RecordLabelProfileNavLink>
          <RecordLabelProfileNavLink
            background={background}
            active={isPurchase}
            onClick={() => setCurrentTab("purchase")}
          >
            Purchase
          </RecordLabelProfileNavLink>
          {profile?.uid === currentUser?.uid && (
            <>
              <RecordLabelProfileNavLink
                background={background}
                active={isSettings}
                onClick={() => setCurrentTab("settings")}
              >
                Settings
              </RecordLabelProfileNavLink>
              <RecordLabelProfileNavLink
                background={background}
                active={isStats}
                onClick={() => setCurrentTab("stats")}
              >
                Stats
              </RecordLabelProfileNavLink>
            </>
          )}
        </RecordLabelProfileNav>
      </NavContainer>
      {isDiscography && (
        <RecordLabelDiscography discography={labelDiscography} isLoaded={isDiscogLoaded} name={profile?.name}/>
      )}

      {isAbout && <RecordLabelAbout name={profile?.name}
      socialLinks={about.accounts}
      images={about.photos}
      bio={about.bio}
      label={profile}
      tourDates={about?.travel}/>}

      {isArtists && !isArtistLoaded && (
        <ContentWrapper>
          <Slider type={SLIDER_TYPES.ARTIST_SKELETON} id={"artists"} />
          <Slider type={SLIDER_TYPES.ARTIST_SKELETON} id={"artists"} />
          <Slider type={SLIDER_TYPES.ARTIST_SKELETON} id={"artists"} />
        </ContentWrapper>
      )}
      {isArtists && isArtistLoaded && (
        <RecordLabelArtists name={profile?.name} artists={labelArtists} />
      )}

      {isPurchase && getObjectLength(labelDiscography) > 0 && (
        <RecordLabelPurchases name={profile?.name} content={labelDiscography}/>
      )}
       {profile?.uid === currentUser?.uid && isSettings && 
      <RecordLabelSettings about={about} label={profile} getProfile={getProfile} labelId={labelId} labelSettings={profile?.labelSettings} name={profile?.name} setTab={setCurrentTab}/>}
      {profile?.uid === currentUser?.uid && isStats && 
      <RecordLabelStats name={profile?.name}/>}
    </>
  );
};

export default RecordLabelProfileContent;
