import styled from "styled-components";
import hexToRgbA, { hexToRgba } from "../HexToRGBA";

export const GlassContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2%;
  padding-top: 5%;
  height: 97%;
  min-height: 100vh;
  width: 100%;
  //grid-template-columns: 65% 30%;
  gap: 2%;
`;

export const DashboardContainer = styled.div`
  display: flex;
  min-height: 100vh;
  color: ${(props) => props.theme.secondaryBg};
  flex-direction: column;
  justify-content: space-evenly;
  background-color: ${(props) => hexToRgba(props.theme.mainBg, 1)};
`;
export const MainDashContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3%;
`;
export const DashButton = styled.div`
  white-space: nowrap;
  margin-bottom: 12px;
  padding: 6px;
  color: ${(props) =>
    props.background ? props.background : props.theme.mainBg};
  background: transparent;
  cursor: pointer;
  display: flex;
  width: auto;
  font-size: 0.7rem;
  transition: all 0.2s ease-in-out;
  border-radius: 1rem;
  border: 1px solid ${(props) => props.theme.secondaryBg};
  outline: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    color: ${(props) =>
      props.active ? props.theme.mainColor : props.theme.secondaryColor};
    border: ${(props) => (props.outline ? "1px solid" : "none")};
  }
`;

export const DashboardHeader = styled.div`
display: flex;
flex-direction: row;
align-content: center;
align-items: center;
justify-content: space-between;
padding: 3%;
`
