import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ImageUtils from '../../../utils/ImageUtils'
import { getUsername, getUserProfile, hashEmail } from '../../GravatarAPI'
import { getRandomColor } from '../../HexToRGBA'
import ScrollToTop from '../../ScrollToTop'
import { SkeletonUserCardImg } from '../../Skeleton/SkeletonElements'
import { VerifiedBadge } from '../CardElements'
import { CardContainer, CardContent, CardImg, H6, TextLink } from './UserCardElements'

const UserCard = ({content}) => {
  const {DEFAULT_IMAGE, artistBadge} = ImageUtils()
  const {goTo} = ScrollToTop()
  const [color, setColor] = useState(content?.color ? content?.color : getRandomColor() )
  const [username, setUsername] = useState('')

  

 useEffect(() => {
   getUsername(content?.email).then(uName => {
    setUsername(uName)
   }).catch(err => {
    console.error(err)
   })
   return () => {}
   
 }, [content?.email])
 

  return (
    <><CardContainer>
        <SkeletonUserCardImg onClick={() => content?.uid ? goTo(`/u/${content?.uid}`): goTo('#') } color={color} src={content?.photoURL ? content.photoURL+'?s=600&d=mp' : DEFAULT_IMAGE(600)}/>
        <CardContent>
          <H6><TextLink onClick={() => content?.uid ? goTo(`/u/${content?.uid}`): goTo('#') } to={content?.uid ? `/u/${content?.uid}`: '#'}>{(username && username?.trim() !== '') ? username : content?.uid ? `${content?.uid?.substring(0,5)}...${content?.uid?.substring(content?.uid.length - 4, content.uid?.length -1)}` : 'user' }</TextLink>{content?.isArtist && <VerifiedBadge badge={artistBadge} onContextMenu={(e) => {e.preventDefault()}}/>}</H6>
        </CardContent>
    </CardContainer></>
  )
}

export default UserCard