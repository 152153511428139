import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import Sidebar from '../../components/Sidebar';
import { Home, Welcome } from '../../components/Navbar/Data';
import BannerMarketing from '../../components/BannerMarketing';
import Navbar from '../../components/Navbar';

const MarketingCampaign = () => {
    const [isOpen, setIsOpen] = useState(false);
	const navigate  = useNavigate()
	const toggle = () => setIsOpen(!isOpen);
	const {currentUser} = useAuth()
	useEffect(() => {
		if (!currentUser?.uid) {
		  navigate( "/");
		  return () => {}
		}return () => {}}, [])

	return (
		<>
		<Sidebar
          isOpen={isOpen}
          toggle={toggle}
          navInfo={localStorage.getItem('accInfo')? Home(localStorage.getItem('accInfo')) : Welcome}
        />
          <Navbar toggle={toggle} navInfo={localStorage.getItem('accInfo') ? Home(localStorage.getItem('accInfo')) : Welcome}/>
			<BannerMarketing/>
            
		</>	
	)
}

export default MarketingCampaign