import { getObjectLength, hexToStr, timestampToDate } from "../index";
import { db } from "../../components/Authorization/firebase";
import {
  query,
  doc,
  getDoc,
  collection,
  getDocs,
  where,
  limit,
  orderBy,
  updateDoc,
  onSnapshot,
  setDoc,
  addDoc
} from "firebase/firestore";
import { PAYOUT_TYPE } from "../Enum";
import { hashEmail } from "../../components/GravatarAPI";
import { getEpisode, getEpisodes, getSong, getSongs } from "./musicUtils";

export const getUserDetails = async (userId) => {
  return getDocs(
    query(collection(db, `users`), where("uid", "==", userId), limit(1))
  )
    .then((docs) => {
      if (docs?.docs[0]?.exists) {
        return { userId: docs?.docs[0].id, ...docs?.docs[0].data() };
      } else {
        return false;
      }
    })
    .catch((err) => {
      return false;
    });
};

export const getUpdatedUserInfo = (uid, setUser) => {
  return onSnapshot(
    query(collection(db, `users`), where("uid", "==", uid), limit(1)),
    async (docs) => {
      if (docs?.docs[0]?.exists) {
        return { userId: docs?.docs[0].id, ...docs?.docs[0].data() };
      } else {
        return false;
      }
    },
    (err) => {
      return;
    }
  );
};

export const getUserSubscriptionInfo = async (
  userId,
  setSubscription,
  getSubscription
) => {
  if (userId) {
    return onSnapshot(
      query(collection(db, `users`), where("uid", "==", userId), limit(1)),
      async (subscrSnapshot) => {
        if (!subscrSnapshot.empty) {
          let userInfo = {
            userId: subscrSnapshot.docs[0].id,
            ...subscrSnapshot.docs[0].data(),
          };
          let amount = userInfo?.subscription;
          return await getDocs(
            query(
              collection(db, "subscriptions"),
              where("subscriber", "==", userId),
              orderBy("dateCreated", "desc"),
              limit(1)
            )
          )
            .then((doc) => {
              if (!doc.empty) {
                let subscription = {
                  subscriptionId: doc.docs[0].id,
                  ...doc.docs[0].data(),
                };
                let currentDate = new Date();
                let endDate = new Date(subscription?.endDate);
                if (currentDate?.getTime() >= endDate?.getTime()) {
                  return updateDoc(getDoc(`users/${userInfo.userId}`), {
                    subscription: false,
                  }).then(() => {
                    return getSubscription();
                  });
                } else {
                  delete subscription.subscriber;
                  setSubscription({
                    subscription: amount,
                    ...subscription,
                  });
                  return {
                    subscription: amount,
                    ...subscription,
                  };
                }
              }
              return getSubscription();
            })
            .catch((err) => {
              console.error(err);
              getSubscription();
            });
        } else {
          return;
        }
      }
    );
  } else {
    return;
  }
};

export const getNumRegisteredUsers = (setUsers, setLoaded) => {
  setLoaded(false);
  return onSnapshot(collection(db, `users`), async (usersSnapshot) => {
    if (usersSnapshot.empty) {
      return;
    } else {
      setUsers(usersSnapshot.size);
      setLoaded(true);
      return;
    }
  });
};


export const updateCurrentSong = async (uid, currentSong) => {
  //console.log(currentSong);
  return updateDoc(doc(db, `userQueues/${uid}`), {
    currentSong: currentSong?.songId
      ? currentSong?.songId
      : `${currentSong?.episodeId}.episode`,
  }).then(() => {
    return;
  });
};

export const updateQueue = async (uid, queueOptions) => {
  if(getObjectLength(queueOptions) > 0){
  let userQueueDoc = doc(db, `userQueues/${uid}`)
  let options = queueOptions
 if(options?.playlist?.length > 0){
  let queue =  options?.playlist.map(s => {
    return Object.fromEntries(Object.entries(s).filter(([_, d]) => d !== null && d !== undefined))
  }) /* options?.playlist.map((m) => {
    if (m?.episodeId) {
      return `${m?.episodeId}.episode`;
    }
    return m?.songId;
  }) */;
  //console.log(queue)
  delete options.playlist
  options = {
    ...options,
    currentSong: queue[queueOptions?.currentSong]?.songId
    ? queue[queueOptions?.currentSong]?.songId
    : `${queue[queueOptions?.currentSong]?.episodeId}.episode`,
    queue: queue,
  }
  options = Object.fromEntries(Object.entries(options).filter(([_, d]) => d !== null && d !== undefined))
  
  try{

 
  let exists = (await getDoc(userQueueDoc)).exists()
  if(exists){
  return setDoc(userQueueDoc, options).then(() => {
    return;
  }).catch(err => {
    console.error(err)
    //update using API
    return
  });
}else{
  return addDoc(userQueueDoc, options).then(() => {
    return
  }).catch(err => {
    console.error(err)
    //update using API
    return
  })
}
}catch(err){
  console.error(err)
}
}
}
};

export const getUserSubscription = async (uid, setSubscription) => {
  return onSnapshot(query(collection(db, "users",), where("uid", "==", uid), limit(1)),(snapshot) => {
    if(snapshot.empty){
      return setSubscription(false)
    }
    setSubscription(snapshot.docs[0].data().subscription)
    return
  } )
}
