import React, { useEffect, useState } from "react";
import useRecordLabel from "../../../../hooks/useRecordLabel";
import useMusic from "../../../../hooks/useMusic";
import { SelectButton, SelectButtonWrapper } from "../../../Select";
import { ModalLoadingScreen } from "../../../LoadingScreen";
import KeyMetrics from "../../../Charts/KeyMetrics";
import {
  getGroupedPlays,
  getNumListeners,
  getObjectLength,
  getPlayCount,
  groupInfoByDate,
} from "../../../../utils";

import { FormText, FormWrapper } from "../../../CustomForm/CustomFormElements";
import { ChipList } from "../../../Chip/ChipElements";
import Chip from "../../../Chip";
import ContentWrapper from "../../../ContentWrapper";
import { SinglesReleasesTable } from "../../../ContentTable/ArtistTables";
import { useWebsiteTheme } from "../../../WebsiteTheme/WebsiteThemeContext";
import ChartToggle from "../../../Charts";

const ListenersContent = ({ labelId, isLoaded, setLoaded, filter }) => {
  const {themeColors} = useWebsiteTheme()


  const {
    getRecordLabelListenersByDate,
    getRecordLabelFollowersByDate,
    getRecordLabelSubscribersByDate,
  } = useRecordLabel({ id: labelId });
  const { getSongs } = useMusic();
  const [playType, setPlayType] = useState("all");
  const [metricType, setMetricType] = useState("plays");
  const [subscribers, setSubscribers] = useState([]);
  const [followers, setFollowers] = useState([]);
  const [listeners, setListeners] = useState([]);
  const [numListeners, setNumListeners] = useState();
  const [songs, setSongs] = useState();
  const [isSongsLoaded, setSongsLoaded] = useState(false);

  const getData = async () => {
    setLoaded(false);
    setListeners([]);
    setSubscribers([]);
    setFollowers([]);
    return await Promise.all([
      getRecordLabelListenersByDate(labelId, filter),
      getRecordLabelSubscribersByDate(labelId, filter),
      getRecordLabelFollowersByDate(labelId, filter),
    ])
      .then((data) => {
        if (data[0] !== false && data[0]?.listeners?.length > 0) {
          setListeners(data[0]?.listeners);
          setNumListeners(
            getNumListeners(
              groupInfoByDate(data[0]?.listeners, "timestamp", filter)
            )
          );
        }
        if (data[1]?.length > 0 && data[1] !== false) {
          setSubscribers(data[1]?.subscribers);
        }
        if (data[2]?.length > 0 && data[2] !== false) {
          setFollowers(data[2]?.followers);
        }
        setLoaded(true);
        return;
      })
      .catch((err) => {
        console.error(err);
        setLoaded(true);
        return;
      });
  };

  useEffect(() => {
    getData();
    return () => {};
  }, [labelId, filter]);

  useEffect(() => {
    if (isLoaded) {
      setSongsLoaded(false);
      let s = getSongsByPlayType();
      if (s?.length > 0) {
        getSongs(s)
          .then((Songs) => {
            if (Songs?.length > 0) {
              setSongs(
                Songs.map((song) => {
                  return {
                    ...song,
                    plays:
                      playType === "all"
                        ? listeners.filter((l) => l?.songId === song?.songId)
                            ?.length
                        : listeners.filter(
                            (l) =>
                              l?.songId === song?.songId &&
                              l?.playType === playType
                          )?.length,
                  };
                })
              ).sort((a, b) => b?.plays - a?.plays);
            }
            setSongsLoaded(true);
          })
          .catch((err) => {
            setSongsLoaded(true);
          });
      }
    }

    return () => {};
  }, [playType, labelId, filter, isLoaded]);

  const getSongsByPlayType = () => {
    let list = [];
    switch (playType) {
      case "skip":
        list = listeners
          .filter((play) => play?.playType === playType && !play?.repeat)
          .map((play) => {
            return play?.songId;
          });
        break;
      case "full":
        list = listeners
          .filter((play) => play?.playType === playType || play?.repeat > 0)
          .map((play) => {
            return play?.songId;
          });
        break;
      case "partial":
        list = listeners
          .filter((play) => play?.playType === playType && !play?.repeat)
          .map((play) => {
            return play?.songId;
          });
        break;

      default:
        list = listeners.map((play) => {
          return play?.songId;
        });
        break;
    }
    return [...new Set(list)];
  };

  return (
    <ContentWrapper>
      
      {!isLoaded && <ModalLoadingScreen text={"Loading Stats..."} />}
      {isLoaded && (
        <>
          <KeyMetrics
            data={[
              {
                heading: "Plays",
                onClick: () => {
                  setMetricType("plays");
                },
                labels:
                  listeners?.length > 0
                    ? Object.keys(
                        groupInfoByDate(listeners, "timestamp", filter)
                      )
                    : [],
                datasets: [
                  {
                    label: "Plays",
                    data:
                      listeners?.length > 0
                        ? Object.values(
                            groupInfoByDate(listeners, "timestamp", filter)
                          ).map((plays) => {
                            return getPlayCount(plays);
                          })
                        : [],
                    borderColor: themeColors.mainBg,
                    backgroundColor: themeColors.mainBg,
                  },
                ],
              },
              {
                heading: "Listeners",
                onClick: () => {
                  setMetricType("listeners");
                },
                labels:
                  getObjectLength(numListeners) > 0
                    ? Object.keys(numListeners)
                    : [],
                datasets: [
                  {
                    label: "Listeners",
                    data:
                      getObjectLength(numListeners) > 0
                        ? Object.values(numListeners)
                        : [],
                    borderColor: themeColors.warningColor,
                    backgroundColor: themeColors.warningColor,
                  },
                ],
              },
              {
                heading: "Followers",
                onClick: () => {
                  setMetricType("followers");
                },
                labels:
                  getObjectLength(followers) > 0 ? Object.keys(followers) : [],
                datasets: [
                  {
                    label: "followers",
                    data:
                      getObjectLength(followers) > 0
                        ? Object.values(followers).map((f) => {
                            return f?.length;
                          })
                        : [],
                    borderColor: themeColors.successColor,
                    backgroundColor: themeColors.successColor,
                  },
                ],
              },
              {
                heading: "Subscribers",
                onClick: () => {
                  setMetricType("subscribers");
                },
                labels:
                  getObjectLength(subscribers) > 0
                    ? Object.keys(subscribers)
                    : [],
                datasets: [
                  {
                    label: "Subscribers",
                    data:
                      getObjectLength(subscribers) > 0
                        ? Object.values(subscribers)
                        : [],
                    borderColor: themeColors.mainColor,
                    backgroundColor: themeColors.mainColor,
                  },
                ],
              },
            ]}
          />
          {metricType === "plays" && listeners?.length > 0 && (
            <>
              <ChipList alignment={"right"}>
                <Chip
                  label={"All"}
                  background={playType === "all" && themeColors.accentColor}
                  onChipClick={() => setPlayType("all")}
                />
                <Chip
                  label={"Full Plays"}
                  background={playType === "full" && themeColors.successColor}
                  onChipClick={() => setPlayType("full")}
                />
                <Chip
                  label={"Partial Plays"}
                  background={playType === "partial" && themeColors.infoColor}
                  onChipClick={() => setPlayType("partial")}
                />
                <Chip
                  label={"Skips"}
                  background={playType === "skip" && themeColors.warningColor}
                  onChipClick={() => setPlayType("skip")}
                />
              </ChipList>
              <FormWrapper>
                {playType === "all" && (
                  
                    <ChartToggle
                      data={{
                        heading: 'All Plays (By Type)',
                        labels:
                          listeners?.length > 0 > 0
                            ? Object.keys(
                                groupInfoByDate(listeners, "timestamp", filter)
                              )
                            : [],
                        datasets: [
                          {
                            label: "Full",
                            data:
                              listeners.filter((p) => p?.playType === "full")
                                ?.length > 0
                                ? Object.values(
                                    groupInfoByDate(
                                      listeners.filter(
                                        (p) => p?.playType === "full"
                                      ),
                                      "timestamp",
                                      filter
                                    )
                                  ).map((p) => {
                                    return p.length;
                                  })
                                : [],

                            borderColor: themeColors.successColor,
                            backgroundColor: themeColors.successColor,
                          },
                          {
                            label: "Partial",
                            data:
                              listeners.filter((p) => p?.playType === "partial")
                                ?.length > 0
                                ? Object.values(
                                    groupInfoByDate(
                                      listeners.filter(
                                        (p) => p?.playType === "partial"
                                      ),
                                      "timestamp",
                                      filter
                                    )
                                  ).map((p) => {
                                    return p.length;
                                  })
                                : [],

                            borderColor: themeColors.infoColor,
                            backgroundColor: themeColors.infoColor,
                          },
                          {
                            label: "Skips",
                            data:
                              listeners.filter((p) => p?.playType === "skip")
                                ?.length > 0
                                ? Object.values(
                                    groupInfoByDate(
                                      listeners.filter(
                                        (p) => p?.playType === "skip"
                                      ),
                                      "timestamp",
                                      filter
                                    )
                                  ).map((p) => {
                                    return p?.length;
                                  })
                                : [],
                            borderColor: themeColors.warningColor,
                            backgroundColor: themeColors.warningColor,
                          },
                        ],
                      }}
                    />
    
                )}
                {playType !== "all" && (
                  
                    <ChartToggle
                      data={{
                        heading: `${playType[0].toUpperCase()}${playType.substring(1)}`,
                        labels:
                          listeners?.length > 0
                            ? Object.keys(
                                groupInfoByDate(listeners, "timestamp", filter)
                              )
                            : [],
                        datasets: [
                          {
                            label: playType,
                            data:
                              listeners?.length > 0
                                ? Object.values(
                                    groupInfoByDate(
                                      listeners,
                                      "timestamp",
                                      filter
                                    )
                                  ).map((plays) => {
                                    return getGroupedPlays(plays)[playType];
                                  })
                                : [],

                            borderColor:
                              playType === "skip"
                                ? themeColors.warningColor
                                : playType === "partial"
                                ? themeColors.infoColor
                                : themeColors.successColor,
                            backgroundColor:
                              playType === "skip"
                                ? themeColors.warningColor
                                : playType === "partial"
                                ? themeColors.infoColor
                                : themeColors.successColor,
                          },
                        ],
                      }}
                    />
                )}
              </FormWrapper>

              {!isSongsLoaded && <ModalLoadingScreen />}
              {isSongsLoaded && (
                <ContentWrapper>
                  <FormWrapper>
                    <SinglesReleasesTable
                      releases={songs}
                      heading={`${
                        playType !== "all"
                          ? `${playType[0].toUpperCase()}${playType.substring(
                              1
                            )} Played Songs`
                          : "All Songs"
                      }`}
                    />
                  </FormWrapper>
                </ContentWrapper>
              )}
            </>
          )}
          {metricType === "listeners" && listeners?.length > 0 && (
            <>
              <FormWrapper>
                <FormText>Unique Listeners</FormText>
                <ChartToggle
                  data={{
                    heading: "Unique Listeners",
                    labels:
                      getObjectLength(numListeners) > 0
                        ? Object.keys(numListeners)
                        : [],
                    datasets: [
                      {
                        label: "Listeners",
                        data:
                          getObjectLength(numListeners) > 0
                            ? Object.values(numListeners)
                            : [],
                        backgroundColor: themeColors.infoColor,
                        borderColor: themeColors.infoColor,
                        fill: true,
                      },
                    ],
                  }}
                />
              </FormWrapper>
            </>
          )}
          {(((metricType === "plays" || metricType === "listeners") &&
            listeners?.length === 0) ||
            (metricType === "followers" && getObjectLength(followers) === 0) ||
            (metricType === "subscribers" &&
              getObjectLength(subscribers) === 0)) && (
            <>
              <FormWrapper>
                <FormText>No Data Available</FormText>
              </FormWrapper>
            </>
          )}
        </>
      )}
    </ContentWrapper>
  );
};

export default ListenersContent;
