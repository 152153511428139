import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import MusicPage from '../../components/MusicPage'
import Navbar from '../../components/Navbar';
import { Home, Welcome } from '../../components/Navbar/Data';
import Sidebar from '../../components/Sidebar';

function Album() {
  const [album, setAlbumId] = useState()
  const {albumId} = useParams()
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {setIsOpen(!isOpen)}
  const navigate  = useNavigate()
  
  useEffect(() => {
      if(!albumId)
      {
          navigate(-1)
      }
      setAlbumId(albumId)
      return () => {}
  }, [albumId])



  return( 
      <>
            <Sidebar
          isOpen={isOpen}
          toggle={toggle}
          navInfo={localStorage.getItem('accInfo')? Home(localStorage.getItem('accInfo')) : Welcome}
        />
          <Navbar toggle={toggle} navInfo={localStorage.getItem('accInfo')? Home(localStorage.getItem('accInfo')) : Welcome}/>
          <MusicPage id={albumId} type={'album'}/>
          
      </>
      )
}

export default Album