import React, { useState } from "react";
import OptionModal from "../OptionModal";
import { FormInput1 } from "../CustomForm/CustomFormElements";
import { Small } from "../QRCodeForm/QrCodeFormElements";
import { HoverOption } from "../MenuOptions/MenuOptionsElements";
import QRCodeForm from "../QRCodeForm";
import { TRANSACTION_OPTIONS } from "../../utils/Enum";
import { useAuth } from "../../contexts/AuthContext";
import { useModal } from "../Modal/ModalContext";
import { FormWrapper } from "../MusicUploadsForm/MusicUploadsFormElements";
import { CustomButton } from "../ButtonElement";
import axiosInstance from "../Authorization/client";
import LoadingScreen from "../LoadingScreen";

const MicropaymentsFundsClaimer = ({
  funds,
  type,
  name,
  paymentChannel,
  onSuccess,
}) => {
  const [fundsToClaim, setFundsToClaim] = useState();
  const { openModal, closeModal, modalContent } = useModal();
  const { currentUser } = useAuth();

  const handleInput = (e) => {
    switch (e.target.name) {
      case "claimFunds":
        if (parseFloat(e.target.value * 1000000) <= funds - 100000) {
          setFundsToClaim(e.target.value);
        }
        break;

      default:
        break;
    }
  };

  const onReceiptSuccess = (signature, publicKey) => {
    modalContent(<OptionModal heading={"Authorization Successful"} additionalText={"The Funds has Successfully been claimed"} onCancel={() => {
        closeModal()
        modalContent()
    }}>
        <HoverOption onClick={() => acceptFunds(signature, publicKey)}>OK</HoverOption>
    </OptionModal>)
  };

   const acceptFunds = async(signature, publicKey) =>{
    openModal()
    modalContent(
        <LoadingScreen transparent={true}/>
      );
      return await axiosInstance.post(`/api/claimMicropayment`, {
        sender: currentUser?.uid,
        userToken: localStorage.getItem("userToken"),
        signature: signature,
        publicKey: publicKey,
        paymentChannel: paymentChannel?.channel,
        value: fundsToClaim,
        memo: `${type} ${name} is signing the following transaction to claim micropayment funds (${fundsToClaim} XRP) from Sonar Muse`,
        instruction: `${type} ${name} is signing the following transaction to claim micropayment funds (${fundsToClaim} XRP) from Sonar Muse`,

    }).then((res) => {
      if(res.data.result){
        onSuccess()
      }else{
        closeModal()
        modalContent(<OptionModal onCancel={() => {
          closeModal()
          modalContent()
        }}>
          <HoverOption onClick={() => acceptFunds(signature, publicKey)}>Try Again</HoverOption>
        </OptionModal>)
      }
    }).catch(err => {
      console.error(err)
    })
  } 

  const claimFunds = () => {
    return (
      <OptionModal heading={"Claim Funds"} onCancel={() => closeModal()}>
        <FormInput1
          name={"claimFunds"}
          onChange={handleInput}
          max={(funds - 100000) / 1000000}
          value={fundsToClaim}
          placeholder="Input amount to claim"
        />
        <Small style={{padding: "2%"}}>Max: {(funds - 100000) / 1000000} XRP</Small>

        <HoverOption onClick={() => (parseInt(fundsToClaim) > 0 && (parseInt(fundsToClaim) <= (funds - 100000) / 1000000)) ?  confirmClaim() : {}}>Authorize Claim Funds</HoverOption>
        <CustomButton
          onClick={() => {
            setFundsToClaim((funds - 100000) / 1000000);
            confirmClaim();
          }}
        >
          Authorize Claim Max Funds
        </CustomButton>
      </OptionModal>
    );
  };

  const confirmClaim = () => {
    //console.log(paymentChannel)
    openModal();
    modalContent(
      <QRCodeForm
        type={TRANSACTION_OPTIONS.AUTHORIZE_MONETIZATION_CLAIM}
        heading={`Authorizing Micropayment Claim Receipt for ${name}`}
        body={{
          memo: `${type} ${name} is authorizing a receipt to claim ${fundsToClaim} XRP from micropayments`,
          paymentChannel: paymentChannel?.channel,
          publicKey: paymentChannel?.publicKey,
          sender: currentUser?.uid,
          value: fundsToClaim,
          instruction: `${type} ${name} is authorizing a receipt to claim ${fundsToClaim} XRP from micropayments`,
        }}
        onSuccess={onReceiptSuccess}
        onFail={() => {
          modalContent();
          closeModal();
        }}
        cancelQr={() => {
          modalContent();
          closeModal();
        }}
      />
    );
  };

  return <>{claimFunds()}</>;
};

export const MicropaymentFundsDisable = ({funds,
  type,
  name,
  paymentChannel,
  onSuccess,

}) => {
  const { openModal, closeModal, modalContent } = useModal();
  const { currentUser } = useAuth();
  const [payload, setPayload] = useState()
  const [txId, setTxId] = useState()

  const onReceiptSuccess = (pload, txHash, signature, publicKey) => {
    setPayload(pload)
    setTxId(txHash)
    modalContent(<OptionModal heading={"Authorization Successful"} additionalText={"The Funds has Successfully been claimed"} onCancel={() => {
        closeModal()
        modalContent()
    }}>
        <HoverOption onClick={() => acceptFunds(signature, publicKey)}>OK</HoverOption>
    </OptionModal>)
  };

   const acceptFunds = async(signature, publicKey) =>{
    openModal()
    modalContent(
        <LoadingScreen transparent={true}/>
      );
      return await axiosInstance.post(`/api/claimAndDisableMicropayment`, {
        sender: currentUser?.uid,
        userToken: localStorage.getItem("userToken"),
        signature: signature,
        publicKey: publicKey,
        paymentChannel: paymentChannel?.channel,
        value: funds,
        memo: `${type} ${name} is signing the following transaction to disable micropayment funds from Sonar Muse`,
        instruction: `${type} ${name} is signing the following transaction to disable micropayments from Sonar Muse`,

    }).then(async (res) => {
      if(res.data.result){
        let url 
        switch (type) {
          case 'Artist':
            url = `${currentUser?.uid}/disableArtistWebMonetization`
            break;
          case 'Label':
            url = `${currentUser?.uid}/disableRecordLabelWebMonetization`
            break;
          case 'Podcaster':
            url = `${currentUser?.uid}/disablePodcasterWebMonetization`

            break;
        
          default:
            break;
        }
        return await axiosInstance.post(url,{payload: payload, txId: txId}).then(() => {
          return onSuccess()
        }).catch((err) => {
          closeModal()
          modalContent()
        })
        
      }else{
        closeModal()
        modalContent(<OptionModal heading={"Something went wrong"} onCancel={() => {
          closeModal()
          modalContent()
        }}>
          <HoverOption onClick={() => acceptFunds(signature, publicKey)}>Try Again</HoverOption>
        </OptionModal>)
      }
    }).catch(err => {
      console.error(err)
    })
  } 

  const confirmDisable = () => {
    //console.log(paymentChannel)
    openModal();
    modalContent(
      <QRCodeForm
        type={TRANSACTION_OPTIONS.AUTHORIZE_MONETIZATION_DISABLE}
        heading={`Authorizing Micropayment Disable Receipt for ${name}`}
        body={{
          memo: `${type} ${name} is authorizing a receipt to disable micropayments`,
          paymentChannel: paymentChannel?.channel,
          publicKey: paymentChannel?.publicKey,
          sender: currentUser?.uid,
          value: funds,
          instruction: `${type} ${name} is authorizing a receipt to disable micropayments`,
        }}
        onSuccess={onReceiptSuccess}
        onFail={() => {
          modalContent();
          closeModal();
        }}
        cancelQr={() => {
          modalContent();
          closeModal();
        }}
      />
    );
  };


  const disableFunds = () => {
    
  }

  return <><OptionModal heading={'Are you sure you want to continue with disabling micropayments?'} onCancel={() => {
    modalContent();
        closeModal();
  }}>
    <HoverOption onClick={confirmDisable}>Continue</HoverOption>
    
  </OptionModal></>;

}

export default MicropaymentsFundsClaimer;
