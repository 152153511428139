import React, { useEffect, useState } from "react";
import useRecordLabel from "../../../../hooks/useRecordLabel";
import {
  getObjectLength,
  groupInfoByDate,
  groupPurchasesByAmount,
} from "../../../../utils";

import BarGraph from "../../../Charts/Bar";
import { ModalLoadingScreen } from "../../../LoadingScreen";
import KeyMetrics from "../../../Charts/KeyMetrics";
import { SelectButton, SelectButtonWrapper } from "../../../Select";
import ContentWrapper from "../../../ContentWrapper";
import { FormText, FormWrapper } from "../../../CustomForm/CustomFormElements";
import { useWebsiteTheme } from "../../../WebsiteTheme/WebsiteThemeContext";
import { CURRENCIES_STATS_COLORS } from "../../../../utils/Enum";
import ChartToggle from "../../../Charts";

const RevenueContent = ({ labelId, isLoaded, setLoaded, filter }) => {
  const {themeColors} = useWebsiteTheme()


  const { getRecordLabelDigitalPurchasesByDate } = useRecordLabel({
    id: labelId,
  });
  const [metricType, setMetricType] = useState("digital");
  const [purchases, setPurchases] = useState([]);
  const [digitalPurchases, setDigitalPurchases] = useState();
  const [royaltyPurchases, setRoyaltyPurchases] = useState();

  const getData = async () => {
    setLoaded(false);
    setDigitalPurchases([]);
    setRoyaltyPurchases([]);
    return await Promise.all([
      getRecordLabelDigitalPurchasesByDate(
        labelId,
        filter
      ) /* getRecordLabelRoyaltyPurchasesByDate(labelId, filter), getRecordLabelCollectionPurchasesByDate(labelId, filter) */,
    ])
      .then((data) => {
        let p = [];
        if (data[0] !== false && data[0]?.length > 0) {
          p.push(...data[0]);

          setDigitalPurchases(data[0]);
        }
        /* if(data[1]?.length > 0 && data[1] !== false){
                p.push(...data[1])
                setRoyaltyPurchases(data[1])
                //console.log(data[1])
            }
            if(data[2]?.length > 0 && data[2] !== false){
                p.push(...data[2])
                setCollectionPurchases(data[2])
                //console.log(data[1])
            } */
        setPurchases(p);
        setLoaded(true);
        return;
      })
      .catch((err) => {
        console.error(err);
        setLoaded(true);
        return;
      });
  };

  useEffect(() => {
    getData();
    return () => {};
  }, [labelId, filter]);

  return (
    <ContentWrapper>
      {!isLoaded && <ModalLoadingScreen text={"Loading Stats..."} />}
      {isLoaded && (
        <>
          <KeyMetrics
            data={[
              {
                heading: "Total Sales",
                onClick: () => {
                  purchases?.length > 0 && setMetricType("total");
                },
                labels:
                  getObjectLength(
                    groupInfoByDate(purchases, "createdAt", filter)
                  ) > 0
                    ? Object.keys(
                        groupInfoByDate(purchases, "createdAt", filter)
                      )
                    : [],
                datasets: [
                  {
                    label: "Sales",
                    data:
                      getObjectLength(
                        groupInfoByDate(purchases, "createdAt", filter)
                      ) > 0
                        ? Object.values(
                            groupInfoByDate(purchases, "createdAt", filter)
                          ).map((purchase) => {
                            return purchase?.length;
                          })
                        : [],
                    borderColor: themeColors.successColor,
                    backgroundColor: themeColors.successColor,
                  },
                ],
              },
              {
                heading: "Digital Sales",
                onClick: () => {
                  digitalPurchases?.length > 0 && setMetricType("digital");
                },
                labels:
                  digitalPurchases?.length > 0
                    ? Object.keys(
                        groupInfoByDate(digitalPurchases, "createdAt", filter)
                      )
                    : [],
                datasets: [
                  {
                    label: "Sales",
                    data:
                      digitalPurchases?.length > 0
                        ? Object.values(
                            groupInfoByDate(
                              digitalPurchases,
                              "createdAt",
                              filter
                            )
                          ).map((purchase) => {
                            return purchase?.length;
                          })
                        : [],
                    borderColor: themeColors.infoColor,
                    backgroundColor: themeColors.infoColor,
                  },
                ],
              } /* ,{
            heading: "Regium Sales",
            onClick: ()=>{
                royaltyPurchases?.length > 0 && setMetricType("royalty")

            },
            labels: royaltyPurchases?.length > 0 ? Object.keys(groupInfoByDate(royaltyPurchases, "createdAt", filter)) : [],
            datasets: [{
                label: "Sales",
                data: royaltyPurchases?.length > 0 ? Object.values(groupInfoByDate(royaltyPurchases, "createdAt", filter)).map(purchase => {
                    return purchase?.length
                }) : [],
                backgroundColor: themeColors.infoColor
            }]
            
        },{
            heading: "NFToken Sales",
            onClick: ()=> {
                collectionPurchases?.length && setMetricType("nfToken")
            },
            labels: collectionPurchases?.length > 0 ? Object.keys(groupInfoByDate(collectionPurchases, "createdAt", filter)) : [],
            datasets: [{
                label: "Sales",
                data: collectionPurchases?.length > 0 ? Object.values(groupInfoByDate(collectionPurchases, "createdAt", filter)).map(purchase => {
                    return purchase?.length
                }) : [],
                backgroundColor: themeColors.infoColor
            }]
            
        }, */,
            ]}
          />
          {purchases?.length > 0 && metricType === "total" && (
            <>
                <ChartToggle
                data={{
                  heading: "Total Sales",
                  labels:
                    getObjectLength(
                      groupInfoByDate(purchases, "createdAt", filter)
                    ) > 0
                      ? Object.keys(
                          groupInfoByDate(purchases, "createdAt", filter)
                        )
                      : [],
                  datasets: [
                    {
                      label: "Sales",
                      data:
                        getObjectLength(
                          groupInfoByDate(purchases, "createdAt", filter)
                        ) > 0
                          ? Object.values(
                              groupInfoByDate(purchases, "createdAt", filter)
                            ).map((purchase) => {
                              return purchase?.length;
                            })
                          : [],
                      borderColor: themeColors.successColor,
                      backgroundColor: themeColors.successColor,
                    },
                  ],
                }}
              />
              <ChartToggle
                data={{
                  heading: "Total Sales (Grouped By Sales Type)",
                  labels:
                    purchases?.length > 0
                      ? ["Digitals" /* , "Royalties", "Collections" */]
                      : [],
                  datasets: [
                    {
                      label: "Sales",
                      data:
                        purchases?.length > 0
                          ? [
                              digitalPurchases?.length > 0
                                ? digitalPurchases?.length
                                : 0 /*  royaltyPurchases?.length > 0 ? royaltyPurchases?.length : 0 , collectionPurchases?.length > 0 ? collectionPurchases?.length : 0 */,
                            ]
                          : [],
                      borderColor: [themeColors.successColor, themeColors?.infoColor],
                      backgroundColor: [themeColors.successColor, themeColors?.infoColor],
                    },
                  ],
                }}
              />
              {getObjectLength(groupPurchasesByAmount(purchases)) > 0 && (
                    <ChartToggle
                    type={'bar'}
                  data={{
                    heading:'Revenue (Grouped By Currency)',
                    labels:
                      getObjectLength(groupPurchasesByAmount(purchases)) > 0
                        ? Object.keys(groupPurchasesByAmount(purchases))
                        : [],
                    datasets: [
                      {
                        label: "Total Earnings",
                        data:
                          purchases?.length > 0
                            ? Object.values(
                                groupPurchasesByAmount(purchases)
                              ).map((purchase) => {
                                return parseFloat(purchase).toFixed(2);
                              })
                            : [],
                        backgroundColor: Object.keys(
                          groupPurchasesByAmount(purchases)
                        ).map((purchase) => {
                          return CURRENCIES_STATS_COLORS[purchase];
                        }),
                        borderColor: Object.keys(
                          groupPurchasesByAmount(purchases)
                        ).map((purchase) => {
                          return CURRENCIES_STATS_COLORS[purchase];
                        }),
                      },
                    ],
                  }}
                />
              )}
            </>
          )}
          {digitalPurchases?.length > 0 && metricType === "digital" && (
              <ChartToggle
                data={{
                  heading: 'Revenue (Grouped By Currency)',
                  labels:
                    getObjectLength(groupPurchasesByAmount(digitalPurchases)) >
                    0
                      ? Object.keys(groupPurchasesByAmount(digitalPurchases))
                      : [],
                  datasets: [
                    {
                      label: "Total Earnings",
                      data:
                        digitalPurchases?.length > 0
                          ? Object.values(
                              groupPurchasesByAmount(digitalPurchases)
                            ).map((purchase) => {
                              return parseFloat(purchase).toFixed(2);
                            })
                          : [],
                      borderColor: Object.keys(
                        groupPurchasesByAmount(digitalPurchases)
                      ).map((purchase) => {
                        return CURRENCIES_STATS_COLORS[purchase];
                      }),
                      backgroundColor: Object.keys(
                        groupPurchasesByAmount(digitalPurchases)
                      ).map((purchase) => {
                        return CURRENCIES_STATS_COLORS[purchase];
                      }),
                    },
                  ],
                }}
              />
 
          )}
          {/* royaltyPurchases?.length > 0 && metricType === "royalty" && <>
        </> */}
          {/* collectionPurchases?.length > 0 && metricType === "collection" && <>
        </> */}
          {purchases?.length === 0 &&
            (metricType === "total" || metricType === "digital") && (
              <>
                <FormWrapper>
                  <FormText>No Data Available</FormText>
                </FormWrapper>
              </>
            )}
        </>
      )}
    </ContentWrapper>
  );
};

export default RevenueContent;
