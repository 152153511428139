import styled, { keyframes } from "styled-components";
import hexToRgbA, { hexToRgba } from "../HexToRGBA";

const MoveBackground = keyframes`
0% {
    background: ${props => props.theme.mainBg};
}
50% {
    background: ${(props) => `linear-gradient(180deg, ${hexToRgbA(props.theme.secondaryBg)} 0%, ${hexToRgbA(props.theme.mainColor)}  100%)`};

}
100% {
    background: ${props => props.theme.secondaryBg};
}

`
export const Container = styled.div`

    width: 100%;
    height: 100vh;
    background: ${(props) => props.transparent ? 'transparent' : `linear-gradient(180deg, ${hexToRgbA(props.theme.secondaryBg)} 0%, ${hexToRgbA(props.color)}  100%)`};
`


export const BackgroundWrapper = styled.div`
display: flex;
height: 100%;
overflow: hidden;
align-items: center;
justify-content: center;
background: ${(props) => props.transparent ? 'transparent' : `linear-gradient(180deg, ${hexToRgbA(props.theme.secondaryBg)} 0%, ${hexToRgbA(props.color)}  100%)`};
`
const ScaleLogo = keyframes`
0%{
    transform: scale(1);
    transition: 1s ease-out;
}
50% {
    
    transform: scale(1.07);
}
100%{
  
    transform: scale(1);
    transition: 1s ease-out; 
}
`

const RotateLogo = keyframes`
    from{
        transform: rotate(540deg);
        transition: 1s ease-out;

    }
    to{
        transform rotate(0deg);
 
    }
`
const Rotate = keyframes`
    from{
        transform: rotate(0 deg);
        transition: 1s ease-out;

    }
    to{
        transform rotate(360deg);
 
    }
`

export const LoaderWrapper = styled.div`
    flex-direction: column;
    align-items: center;
    justify-content: center;
    display: flex;
    background: transparent;
    animation: ${ScaleLogo} 2s infinite;


`



export const SonarMuseLoader = styled.div`
    display: flex;
    height: 120px;
	width: 120px;
    align-content:center;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden;


`
export const SonarMuseImg = styled.svg`
    display: flex;
    border-radius: 50%;
    border: none;
    animation: ${ScaleLogo} 2s linear infinite;


`

export const SonarMuseLoader1 = styled.div`
    display: flex;
    align-items: center;

    justify-content: center;
`
export const SonarMuseImg1 = styled.div`
    display: flex;
    height: 3rem;
    width: 12rem;
    background: transparent url(${(props) => props.src}) no-repeat center;
    background-size: contain;


`





