import React, {Freagmaent} from 'react'

import PropTypes from 'prop-types'
import { ProfileAboutText, ProfileContainer, ProfileContentWrapper, ProfileDiv, ProfileInfoContainer, ProfileInfoText, ProfileInfoWrapper, ProfileLink, ProfileNavLink, ProfileNavWrapper, ProfileTabs, ProfileWrapper } from '../Profile/ProfileElements'
import { ThemeProvider } from 'styled-components'
import { Theme } from '../WebsiteThemeElements'
import ProfileContent from '../Profile/ProfileContent'
import { FaLink, FaMapMarkerAlt } from 'react-icons/fa'
import ProfileHeader from '../Profile/ProfileHeader'
import defaultImg from '../../images/sonar_muse_logo_nav1.png'
import { SkeletonContentListImg, SkeletonIcons, SkeletonMusicCard, SkeletonPurchaseCardImg, SkeletonTableRow, SkeletonText, SkeletonTextEnd, SkeletonUserCardImg } from './SkeletonElements'
import { BuyButton, CardContainer, CardContent, CardTitle, CreatorInfo, CreatorInfoContent, CreatorInfoWrapper, H6, InfoContentText } from '../Card/DigitalPurchaseCard/DigitalPurchaseCardElements'
import { CONTENT_TYPES } from '../../utils/Enum'
import { CardBody, CardWrapper } from '../Card/CardElements'
import { Button, DetailsContainer, DetailsText, ImageWrapper, NumButton, Table, TableData, TableRow } from '../ContentList/ContentListElements'
import ContentTable from '../ContentTable'



export const UserProfileSkeleton = () => {
     let skeleton = {
         displayPicture: defaultImg,
         background: {
             color: false
         }

     }
}


export const PurchaseCardSkeleton = () => {
    return (
        <CardContainer>
            <SkeletonPurchaseCardImg/>
            <CardContent>
            <CardTitle>
            <SkeletonText/>
            <SkeletonTextEnd/>
            </CardTitle>
            <CreatorInfoWrapper>
            
            <SkeletonIcons/>
               
                  <CreatorInfo>
                  <SkeletonTextEnd/>
                 
                </CreatorInfo>
              
            </CreatorInfoWrapper>
            <CreatorInfoWrapper>
              <SkeletonText/>
              <CreatorInfoContent>
                <InfoContentText>
                <SkeletonTextEnd/>
                </InfoContentText>
              </CreatorInfoContent>
            </CreatorInfoWrapper>
          </CardContent>
        </CardContainer>
    )
}


export const UserCardSkeleton = () => {
  return (
    <><CardContainer>
        <SkeletonUserCardImg />
        <CardContent>
          <H6><SkeletonText/></H6>
        </CardContent>
    </CardContainer></>
  )
}


export const GenreCardSkeleton = () => {
  return (
    <CardWrapper>
      <SkeletonMusicCard 
    type={CONTENT_TYPES.GENRE}>
      <CardContent>
        <CardTitle><SkeletonTextEnd/></CardTitle>
        <H6><SkeletonText/></H6>
      </CardContent>
    </SkeletonMusicCard>
    </CardWrapper>
  );
}


export const ArtistCardSkeleton = () => {
  return (
    <CardWrapper><SkeletonMusicCard
      type={CONTENT_TYPES.ARTIST}
    >
      <CardContent>
        <CardTitle type={CONTENT_TYPES.ARTIST}>
          <SkeletonText/>
          
        </CardTitle>
        <SkeletonTextEnd/>
      </CardContent>
    </SkeletonMusicCard></CardWrapper>
  );
}

export const MusicCardSkeleton = () => {
  return (
    <CardWrapper><SkeletonMusicCard>
      <CardContent>
        <CardTitle><SkeletonText/></CardTitle>
        <CardBody>
          <SkeletonTextEnd/>
        </CardBody>
      </CardContent>
    </SkeletonMusicCard></CardWrapper>
  );
}

export const ContentListSkeleton = () => {
  const content = [0,1,2,3,4]
  return (
    <Table>
    {content.map((song, index) => {
      return (
        <SkeletonTableRow 
          key={index}>
          
          <TableData style={{width: '10%'}}>
            <ImageWrapper >
              <SkeletonContentListImg/>
            </ImageWrapper>
          </TableData>
          <TableData style={{flex: '1 1 100%'}}>
            <DetailsContainer>
              <DetailsText style={{inlineSize: '100%', color: 'transparent'}}>
              {'text'}<SkeletonText style={{flex: '1 1 100%'}} />
              </DetailsText>
              <DetailsText >

                <SkeletonText/>

              </DetailsText>
            </DetailsContainer>
          </TableData>
          <TableData style={{flex: '1 1 100%'}}>
              <DetailsText style={{inlineSize: '100%'}}>
                <small><SkeletonTextEnd/></small>
              </DetailsText>

          </TableData>
          <TableData style={{flex: '1 1 50%'}}>

              <SkeletonIcons/>

          </TableData>
          <TableData>
            
              <SkeletonIcons/>
            
          </TableData>
        </SkeletonTableRow >
    
  )})}
  </Table>)
}

export const UserContentListSkeleton = () => {
  const content = [0,1,2,3,4]
  return (
    <Table>
    {content.map((song, index) => {
      return (
        <TableRow
          key={index}>
          <TableData style={{width: '5%'}}>
            <NumButton>
                {index + 1}
            </NumButton>
          </TableData>
          <TableData style={{width: '10%'}}>
            <ImageWrapper >
              <SkeletonContentListImg isCircular
              />
            </ImageWrapper>
          </TableData>
          <TableData style={{flex: '1 1 100%'}}>
            <DetailsContainer>
              <DetailsText style={{inlineSize: '100%', color: 'transparent'}}>
              {'text'}<SkeletonText style={{flex: '1 1 100%'}} />
              </DetailsText>
              <DetailsText >

                <SkeletonText/>

              </DetailsText>
            </DetailsContainer>
          </TableData>
          <TableData style={{flex: '1 1 100%'}}>
              <DetailsText style={{inlineSize: '100%'}}>
                <small><SkeletonTextEnd/></small>
              </DetailsText>

          </TableData>
          <TableData style={{flex: '1 1 50%'}}>

              <SkeletonIcons/>

          </TableData>
          <TableData>
            
              <SkeletonIcons/>
            
          </TableData>
        </TableRow>
    
  )})}
  </Table>)
}


export const TableSkeleton = () => {
  return (
    <><ContentTable columns={[{ id: 'loading', label: 'Loading...', minWidth: 350 },
    { id: 'loading', label: 'Loading...', minWidth: 100 },
    {
        id: 'loading',
        label: 'Loading...',
        minWidth: 170,
        align: 'left',
    },
  ]} rows={[{},{},{},{},{},{},{},{}]}/></>
  )
}