export const Theme = ({mainColor}) => {
   return  {
    mainBg: "#0a141f",
    secondaryBg: "#EFD8FD",
    mainColor: mainColor ? mainColor : "#5F0994",
    secondaryColor: "#7B5CEB",
    accentColor: "#E0985C",
    errorColor: "#ff0000",
    warningColor:"#ff9800",
    infoColor: "#2196F3",
    successColor:"#04AA6D",
    background: 'black'
}
};

export const DarkTheme = ({mainColor}) => {
    return  {
        mainBg: "#0a141f",
        secondaryBg: "#EFD8FD",
        mainColor: mainColor ? mainColor : "#5F0994",
        secondaryColor: "#7B5CEB",
        accentColor: "#E0985C",
        errorColor: "#ff0000",
        warningColor:"#ff9800",
        infoColor: "#2196F3",
        successColor:"#04AA6D",
        background: 'black'
    }
};
export const LightTheme = ({mainColor}) => {
   return  {
    mainBg: "#F0E7B9",
    secondaryBg: "#050917",
    mainColor: mainColor ? mainColor : "#09940E",
    secondaryColor: "#422F1F",
    accentColor: "#240F6C",
    errorColor: "#ff0000",
    warningColor:"#ff9800",
    infoColor: "#2196F3",
    successColor:"#04AA6D",
    background: 'white'
}
};

