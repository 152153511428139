import React, { useState } from 'react'
import ContentWrapper from '../../../ContentWrapper'
import { Nav, NavContainer, NavLink } from '../../../NavLink/NavLinkElements'
import ListenersContent from './ListenersContent'
import PlaylistsContent from './PlaylistsContent'
import RevenueContent from './RevenueContent'
import EpisodeContent from './EpisodeContent'
import PodcasterSettings from '../../../PodcasterProfile/PodcasterProfileContent/PodcasterSettings'
import { SelectButton, SelectButtonWrapper } from '../../../Select'

const PodcasterDash = ({podcaster, getProfile}) => {
  const [currentTab, setCurrentTab] = useState("episodes")
  const [filter, setFilter] = useState('7days')
  const [isLoaded, setLoaded] = useState(true)
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search)
const setTab = (tab) => {
  setCurrentTab(tab) 
  setLoaded(false)
}

return (
  <>
 <ContentWrapper heading={`${currentTab[0].toUpperCase()}${currentTab.substring(1)}`} subheading={ <SelectButtonWrapper>
      <SelectButton disabled={!isLoaded}
                onChange={(e) => setFilter(e.target.value)}>
          <option value={"7days"}>7 days</option>
          <option value={"14days"}>2 Weeks</option>
          <option value={"30days"}>4 Weeks</option>
          <option value={"60days"}>2 Months</option>
          <option value={"180days"}>6 Months</option>
          <option value={"365days"}>12 Months</option>
          <option value={"AllTime"}>All Time</option>
      </SelectButton></SelectButtonWrapper>}>
        <NavContainer>
            <Nav>
            <NavLink active={currentTab === "episodes"} onClick={() => setTab("episodes")}>Episodes</NavLink>
            <NavLink active={currentTab === "audiences"} onClick={() => setTab("audiences")}>Audience</NavLink>
            <NavLink active={currentTab === "playlists"} onClick={() => setTab("playlists")}>Playlists</NavLink>
            <NavLink active={currentTab === "revenue"} onClick={() => setTab("revenue")}>Revenue</NavLink>
        </Nav></NavContainer>
        {currentTab === "episodes" && <EpisodeContent filter={filter} isLoaded={isLoaded} setLoaded={setLoaded} podcasterId={podcaster?.podcasterId} />}
        {currentTab === "audiences" && <ListenersContent filter={filter} isLoaded={isLoaded} setLoaded={setLoaded} podcasterId={podcaster?.podcasterId} />}
        {currentTab === "playlists" && <PlaylistsContent filter={filter} isLoaded={isLoaded} setLoaded={setLoaded} podcasterId={podcaster?.podcasterId} />}
        {currentTab === "revenue" && <RevenueContent filter={filter} isLoaded={isLoaded} setLoaded={setLoaded} podcasterId={podcaster?.podcasterId} />}
    </ContentWrapper>
    </>
  )
}

export default PodcasterDash