import React, { useState } from 'react'
import { ThemeProvider } from 'styled-components'
import Video from '../../videos/video1.mp4'
import { Button } from '../ButtonElement'
import { Theme } from '../WebsiteThemeElements'
import { Fade, Zoom } from "react-reveal"
import {
    HeroContainer,
    HeroBg,
    VideoBg,
    HeroContent,
    HeroP,
    HeroH1,
    HeroBtnWrapper,
    ArrowForward,
    ArrowRight
} from './HeroElements'

const HeroSection = () => {
    const [hover, setHover] = useState(false)
    const onHover = () => {
        setHover(!hover)
    }

    return (
        <HeroContainer>
            <HeroBg style={{opacity: "0.5"}}>
                <VideoBg autoPlay loop muted src={Video}
                    type='video/mp4' contentEditable={false} onContextMenu={(e) => {e.preventDefault()}}/>
            </HeroBg>
            <HeroContent>
                
                <Fade> <HeroH1>Share Your Frequency With The World.</HeroH1></Fade>
                <Zoom><HeroP>
                    Get access to tools that will help connect with fans across the globe, and build a presence through your artist profile.
                </HeroP></Zoom>
                <HeroBtnWrapper>
                    <Button to='/signup'
                        primary='true'
                        dark='true'
                        onMouseEnter={onHover}
                        onMouseLeave={onHover}
                        smooth={true}
                        exact='true' 
                        >
                        Get started {hover ? <ArrowForward /> : <ArrowRight />}
                    </Button>
                </HeroBtnWrapper>
            </HeroContent>
        </HeroContainer>
    )

}

export default HeroSection