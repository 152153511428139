import React, { useEffect, useRef, useState } from "react";
import { FaRecordVinyl } from "react-icons/fa";
import useArtist from "../../../hooks/useArtist";
import {
  ALERT_TYPES,
  ARTIST_SEARCH_TYPES,
  CONTENT_TYPES,
  MUSIC_PURCHASE_OPTIONS,
  NOTIFICATION_DISPATCH,
} from "../../../utils/Enum";
import ImageUtils from "../../../utils/ImageUtils";
import Alert from "../../Alert";
import { useAlert } from "../../Alert/AlertContext";
import { FormInput, FormText } from "../../CustomForm/CustomFormElements";
import { useModal } from "../../Modal/ModalContext";
import ScrollToTop from "../../ScrollToTop";
import { SkeletonNFTokenCardImg, SkeletonPurchaseCardImg } from "../../Skeleton/SkeletonElements";
import { PlayButton } from "../DigitalPurchaseCard/DigitalPurchaseCardElements";
import {
  BuyButton,
  CardContainer,
  CardContent,
  CardImg,
  CardTitle,
  CreatorImg,
  CreatorInfo,
  CreatorInfoContent,
  CreatorInfoWrapper,
  H6,
  InfoContentText,
  PlayContainer,
  SmallPlayContainer,
  TextLink,
} from "../NFTokenCard/NFTokenCardElements";
import { SoldOutText } from "../CardElements";
import { getAddressName } from "../../../utils/nfTokensUploads";
import { getObjectLength } from "../../../utils";
import { getCollectionInfo } from "../../../utils/dbUtils/nftokenUtils";
import { getResizedImage } from "../../../utils/FileUploadsUtils";
import { useWebsiteTheme } from "../../WebsiteTheme/WebsiteThemeContext";

const CollectionCard = ({ content, cardRef }) => {
  const { toggleModal, modalContent, isOpen, openModal, closeModal } =
    useModal();
    const {themeColors} = useWebsiteTheme()

  const { defaultAlbumArt, DEFAULT_IMAGE } = ImageUtils();
  const [artistName, setArtistName] = useState(content?.issuer ? content?.issuer : '')
  const [profileImg, setProfileImg] = useState(DEFAULT_IMAGE(600))
  const [artistId, setArtistId] = useState("")
  const [collection, setCollection] = useState(content)
  const { goTo } = ScrollToTop();
  const { addAlert } = useAlert();
  const {getArtistName, searchArtists} = useArtist({})
  const [isAlbumArtLoaded,setAlbumArtLoaded] = useState(false)
  const [albumArt, setAlbumArt] = useState(content?.musicDetails?.albumArt ? content?.musicDetails?.albumArt : content?.musicDetails?.thumbnail)

  useEffect(() => {
    if(!isAlbumArtLoaded){
      getResizedImage(collection?.cover?.url).then(image => {
        setAlbumArt(image)
        
      }).catch(err => {
        setAlbumArt(collection?.cover?.url)
      })
      setAlbumArtLoaded(true)
    }
  }, [])
  
  function buy() {
    /* toggleModal();
    modalContent(
      <PurchaseModal
        type={MUSIC_PURCHASE_OPTIONS.NFT_PURCHASE}
        content={content}
      />
    ); */
  }

  useEffect(() => {
    displayArtistName()
    return () => {}
  }, [content?.issuer])

  useEffect(() => {
    if(content?.collectionId){
      getCollectionInfo(content?.collectionId, setCollection)
    }
    return () => {}
    
  }, [content?.collectionId])

  const  displayArtistName = async() => {
    return searchArtists(ARTIST_SEARCH_TYPES.ADDRESS,content?.issuer).then(async(artist) => {
      if(artist){
        setArtistName(artist?.artistName)
        setProfileImg(artist?.artistSettings?.profilePictureType === "custom" && artist?.profilePicture ?
        artist?.profilePicture :
        artist?.photoUrl
          ? artist.photoUrl + "?s=600&d=mp"
          : DEFAULT_IMAGE(600))
          setArtistId(artist?.artistId)
        return
      }else{
        let issuerName = await getAddressName(content?.issuer)
        if(getObjectLength(issuerName) > 0){
          setArtistName(issuerName?.name)
          setProfileImg(issuerName?.profileUrl + "?s=600&d=mp")
          return
        }
        setArtistName(issuerName)
        return
      
      }
      
    }).catch(err => {
      setArtistName(content?.issuer)
     //console.error(err)
    })
  }

  return (
    <>
      <CardContainer ref={cardRef} background={collection?.color}>
         <div> <SkeletonNFTokenCardImg
          onClick={() => {
            if(collection?.collectionId){ 
              
              openModal()
              /* modalContent(<OpenMusicStorePlayer content={collection} type={CONTENT_TYPES.COLLECTION}/>) */
            }
          }}
          src={isAlbumArtLoaded? albumArt : collection?.cover?.url ? collection?.cover?.url : defaultAlbumArt}
        >{collection?.collectionId && <PlayContainer><PlayButton style={{width: "6rem", height: "6rem"}} onClick={() => {
          openModal()
              /* modalContent(<OpenMusicStorePlayer content={collection} type={CONTENT_TYPES.COLLECTION}/>) */
        }}/></PlayContainer>}</SkeletonNFTokenCardImg>
          <SmallPlayContainer><PlayButton onClick={() => {
          openModal()
              /* modalContent(<OpenMusicStorePlayer content={collection} type={CONTENT_TYPES.COLLECTION}/>) */
        }}/></SmallPlayContainer>
        </div>
        <CardContent>
        {(collection?.tokenSupply && ((collection?.tokenSupply - collection?.amountSold) <= 10 && (collection?.tokenSupply - collection?.amountSold) > 0)) && <SoldOutText style={{color: collection?.color ? collection?.color : themeColors.warningColor}}>LIMITED SUPPLY LEFT</SoldOutText>}
        {(collection?.tokenSupply && collection?.amountSold === collection?.tokenSupply) && <SoldOutText>SOLD OUT</SoldOutText>}

          <CardTitle>
            <TextLink alt
          
              to={'#'}
            >{`${collection?.name}`}</TextLink>
          </CardTitle>
          <CreatorInfoWrapper>
            <CreatorImg
              src={
                profileImg
              }
            />
            <CreatorInfo>
              <CreatorInfoContent>
                <H6>Created By</H6>
                <InfoContentText><TextLink to={`#`}>
                  {collection?.issuer === artistName? `${collection?.issuer?.substring(0, 8)}...` : collection?.issuer !== artistName ? artistName :  ''}</TextLink></InfoContentText>
              </CreatorInfoContent>
              <CreatorInfoContent>
                <H6>Price</H6>
                <InfoContentText>
                { (collection?.price &&parseFloat(collection?.price)) > 0 ? `${collection?.price} ${collection?.currency ? collection?.currency : 'XRP' }` : "FREE (PYP)"}
                </InfoContentText>
              </CreatorInfoContent>
            </CreatorInfo>
          </CreatorInfoWrapper>
          <CreatorInfoWrapper>
            <CreatorInfoContent>
              <InfoContentText>
                <TextLink
                to={"#"}
                                  
                >
                  View Collection
                </TextLink>
              </InfoContentText>
            </CreatorInfoContent>
          </CreatorInfoWrapper>
        </CardContent>
      </CardContainer>
    </>
  );
};



export default CollectionCard;
