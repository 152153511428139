import { GravatarQuickEditorCore } from '@gravatar-com/quick-editor';
import React from 'react'
import { FormText, FormWrapper } from '../CustomForm/CustomFormElements';
import { ActionButton, BioInput, InputLabel, SettingsWrapper } from './GravatarSettingsElements';
import ToggleButton from '../ToggleButton';
import { stringFormatter } from '../../utils';
import { Theme } from '../WebsiteThemeElements';

const GravatarSettings = ({type, options, email, hasGravatarProfile}) => {
  return (
    <>{!hasGravatarProfile &&<FormWrapper border> 
      <FormText>No Settings Available </FormText>
         <FormText style={{whiteSpace: 'nowrap'}}>
          Visit
          <a
            style={{ color: Theme({}).secondaryColor, margin: "0 0.5%" }}
            target="_blank"
            rel="noopener noreferrer"
            href="https://en.gravatar.com"
          >
            {"  Gravatar "}
          </a>{" "}
          to sign up for an account
        </FormText></FormWrapper>}
    {type !== 'User' && <><FormWrapper border> <SettingsWrapper>
    <InputLabel>Profile Picture</InputLabel>
    <ToggleButton
      onText={"Custom"}
      offText={"Gravatar"}
      toggle={options?.profilePictureType === "custom"}
      toggleClick={options?.switchProfilePictureType}
    />
  </SettingsWrapper>{options?.profilePictureType === 'gravatar' && hasGravatarProfile && <SettingsWrapper>
            <InputLabel>Profile Picture</InputLabel>
            <ActionButton
          style={{ padding: "1% 2%" }}
          onClick={() => {
            new GravatarQuickEditorCore( {
              email: email,
              scope: ['avatars'],
              onProfileUpdated: () => {
              },
              onOpened: () => {
              },
            } ).open()
            
          }}
        >
          Edit
        </ActionButton>
          </SettingsWrapper>}</FormWrapper>
    <FormWrapper border>
      <SettingsWrapper>
            <InputLabel>Header</InputLabel>
            <ToggleButton
              onText={"Color"}
              offText={"Background Image"}
              toggle={options?.headerType === "color"}
              toggleClick={options?.switchHeaderType}
            />
          </SettingsWrapper>
          {hasGravatarProfile && <SettingsWrapper>
            <InputLabel>Change/Upload Header</InputLabel>
            <ActionButton
          style={{ padding: "1% 2%" }}
          onClick={() => {
            new GravatarQuickEditorCore( {
              email: email,
              scope: ['design'],
              onProfileUpdated: () => {
              },
              onOpened: () => {
              },
            } ).open()
            
          }}
        >
          Edit
        </ActionButton>
          </SettingsWrapper>}</FormWrapper>
   <FormWrapper border>  
    <SettingsWrapper>
      <InputLabel>{type} Bio</InputLabel>
      <ToggleButton
        onText={"Custom"}
        offText={"Gravatar"}
        toggle={options?.bioType === "custom"}
        toggleClick={() => {
          if (options?.bio) {
            options?.switchBioType();
          }
        }}
      />
    </SettingsWrapper>
    {options?.bioType === "custom" && (
      <BioInput
        name={options?.bioInputName}
        type={"text"}
        disabled={options?.bioType !== "custom"}
        placeholder="Bio"
        value={stringFormatter(options?.bio)}
        onChange={options?.handleInput}
      />
    )}
  { options?.bioType === 'gravatar' && hasGravatarProfile && <SettingsWrapper>
            <InputLabel>Bio</InputLabel>
            <ActionButton
          style={{ padding: "1% 2%" }}
          onClick={() => {
            new GravatarQuickEditorCore( {
              email: email,
              scope: ['about'],
              onProfileUpdated: () => {
              },
              onOpened: () => {
              },
            } ).open()
            
          }}
        >
          Edit
        </ActionButton>
          </SettingsWrapper>}</FormWrapper>
          {hasGravatarProfile && <><FormWrapper border>
    <SettingsWrapper>
            <InputLabel>Social Platforms Links</InputLabel>
            <ActionButton
          style={{ padding: "1% 2%" }}
          onClick={() => {
            new GravatarQuickEditorCore( {
              email: email,
              scope: ['verified-accounts'],
              onProfileUpdated: () => {
              },
              onOpened: () => {
              },
            } ).open()
            
          }}
        >
          Edit
        </ActionButton>
          </SettingsWrapper></FormWrapper>
    <FormWrapper border> <SettingsWrapper>
    <InputLabel>Enable Tipping</InputLabel>
    <ToggleButton
      onText={"On"}
      offText={"Off"}
      toggle={options?.tipping}
      toggleClick={options?.toggleTipping}
    />
  </SettingsWrapper>{options?.tipping && 
      <SettingsWrapper>
            <InputLabel>Tipping Wallets</InputLabel>
            <ActionButton
          style={{ padding: "1% 2%" }}
          onClick={() => {
            new GravatarQuickEditorCore( {
              email: email,
              scope: ['wallet'],
              onProfileUpdated: () => {
              },
              onOpened: () => {
              },
            } ).open()
            
          }}
        >
          Edit
        </ActionButton>
          </SettingsWrapper>
       } </FormWrapper>

          <FormWrapper border>
        <SettingsWrapper>
          <InputLabel>Photo Gallery</InputLabel>
          <ToggleButton
            onText={"On"}
            offText={"Off"}
            toggle={options?.showGallery}
            toggleClick={() => options?.setShowGallery(!options?.showGallery)}
          />
        </SettingsWrapper>
      
    {options?.showGallery && <SettingsWrapper>
            <InputLabel>Gallery</InputLabel>
            <ActionButton
          style={{ padding: "1% 2%" }}
          onClick={() => {
            new GravatarQuickEditorCore( {
              email: email,
              scope: ['photos'],
              onProfileUpdated: () => {
              },
              onOpened: () => {
              },
            } ).open()
            
          }}
        >
          Edit
        </ActionButton>
          </SettingsWrapper>}
          </FormWrapper>
  
 
      
      {options?.about?.trips && (
        <SettingsWrapper>
          <InputLabel>Trips</InputLabel>
          <ToggleButton
            onText={"On"}
            offText={"Off"}
            toggle={options?.showTrips}
            toggleClick={() => options?.setTrips(!options?.showTrips)}
          />
        </SettingsWrapper>
  )}</>}</>}
  {type === 'User' && <>
    <FormWrapper border> 
           <SettingsWrapper>
                    <InputLabel>Profile Picture</InputLabel>
                    <ActionButton
                  style={{ padding: "1% 2%" }}
                  onClick={() => {
                    new GravatarQuickEditorCore( {
                      email: email,
                      scope: ['avatars'],
                      onProfileUpdated: () => {
                      },
                      onOpened: () => {
                      },
                    } ).open()
                    
                  }}
                >
                  Edit
                </ActionButton>
                  </SettingsWrapper></FormWrapper>
            <FormWrapper border>
                  <SettingsWrapper>
                    <InputLabel>Change Theme Color</InputLabel>
                    <ActionButton
                  style={{ padding: "1% 2%" }}
                  onClick={() => {
                    new GravatarQuickEditorCore( {
                      email: email,
                      scope: ['design'],
                      onProfileUpdated: () => {
                      },
                      onOpened: () => {
                      },
                    } ).open()
                    
                  }}
                >
                  Edit
                </ActionButton>
                  </SettingsWrapper></FormWrapper>
           <FormWrapper border>  
            <SettingsWrapper>
                    <InputLabel>Bio</InputLabel>
                    <ActionButton
                  style={{ padding: "1% 2%" }}
                  onClick={() => {
                    new GravatarQuickEditorCore( {
                      email: email,
                      scope: ['about'],
                      onProfileUpdated: () => {
                      },
                      onOpened: () => {
                      },
                    } ).open()
                    
                  }}
                >
                  Edit
                </ActionButton>
                  </SettingsWrapper></FormWrapper>
                  <FormWrapper border>
            <SettingsWrapper>
                    <InputLabel>Social Platforms Links</InputLabel>
                    <ActionButton
                  style={{ padding: "1% 2%" }}
                  onClick={() => {
                    new GravatarQuickEditorCore( {
                      email: email,
                      scope: ['verified-accounts'],
                      onProfileUpdated: () => {
                      },
                      onOpened: () => {
                      },
                    } ).open()
                    
                  }}
                >
                  Edit
                </ActionButton>
                  </SettingsWrapper></FormWrapper>
            <FormWrapper border> 
              <SettingsWrapper>
                    <InputLabel>Tipping Wallets</InputLabel>
                    <ActionButton
                  style={{ padding: "1% 2%" }}
                  onClick={() => {
                    new GravatarQuickEditorCore( {
                      email: email,
                      scope: ['wallet'],
                      onProfileUpdated: () => {
                      },
                      onOpened: () => {
                      },
                    } ).open()
                    
                  }}
                >
                  Edit
                </ActionButton>
                  </SettingsWrapper>
                </FormWrapper>

                  <FormWrapper border>
             
              
            <SettingsWrapper>
                    <InputLabel>Gallery</InputLabel>
                    <ActionButton
                  style={{ padding: "1% 2%" }}
                  onClick={() => {
                    new GravatarQuickEditorCore( {
                      email: email,
                      scope: ['photos'],
                      onProfileUpdated: () => {
                      },
                      onOpened: () => {
                      },
                    } ).open()
                    
                  }}
                >
                  Edit
                </ActionButton>
                  </SettingsWrapper>
                  </FormWrapper>
          
         
    
  
  </>}</>
  )
}

export default GravatarSettings