import React, { useState } from 'react'
import useArtist from '../../hooks/useArtist'
import { TRANSACTION_OPTIONS } from '../../utils/Enum'
import { FormInput } from '../CustomForm/CustomFormElements'
import { HoverOption } from '../MenuOptions/MenuOptionsElements'
import { useModal } from '../Modal/ModalContext'
import OptionModal from '../OptionModal'
import QRCodeForm from '../QRCodeForm'


const NFTStorageTokenForm = ({artistId, currentUser, setMintable}) => {
    const { toggleModal, modalContent, isOpen, openModal } = useModal();
    const {updateStorageToken} = useArtist({})
    const [storageToken, setStorageToken] = useState()
    const handleInput = (e) => {
        setStorageToken(e.target.value)
    }

    const onSuccess = () => {
        setMintable(true)
        toggleModal() 
        modalContent()
    }

    const onFail = () => {
        setMintable(false)
        toggleModal() 
        modalContent()
    }
    
    const onCancel = () => {
        toggleModal() 
        modalContent()
    }


  return (
    <><OptionModal heading={"NFT.Storage API Key Required"} additionalText={`Please provide us with your NFT.Storage api token. It is safe to provide us with this`}>
    <FormInput
      name={"storageToken"}
      onChange={handleInput}
      value={storageToken}
    />
    <HoverOption onClick={() => {  
        modalContent(<>
            <QRCodeForm 
                heading={"Updating Artist Profile"}
                type={TRANSACTION_OPTIONS.UPDATE_ARTIST_PROFILE}
                body={
                    {
                    artistId: artistId,
                    sender: currentUser,
                    memo: "User is signing this transaction to update artist NFT.Storage API Key",
                    instruction: "User is signing this transaction to update artist NFT.Storage API Key",
                    profile: {
                        nftStorageAPIKey: storageToken
                    }
                    }
                }
                onSuccess={onSuccess}
                onFail={onFail}
                cancelQr={onCancel}
            />
        </>)}}>
      Continue
    </HoverOption>
  </OptionModal></>
  )
}

export default NFTStorageTokenForm