import styled from 'styled-components'
import { MdArrowForward, MdKeyboardArrowRight } from 'react-icons/md'

export const HeroContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width:100%;
    height: 100vh;
    border-radius: 25px;
    :before {
        content: '';
        position: relative;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

`

export const HeroBg = styled.div`
display: flex;
position: relative;
top: 0;
right: 0;
bottom: 0;
left: 0;
width: 100%;
height: 100%
`

export const VideoBg = styled.video`
display: flex;
position: relative;
width: 100%;
height: 100%;
opcity: 30%;
-o-object-fit: cover;
object-fit: cover;
background: ${props => props.theme.mainBg};
`

export const HeroContent = styled.div`
z-index: 3;
max-width: 1200px;
position: absolute;
padding: 8px 24px;
display: flex;
flex-direction: column;
align-items: center;
`

export const HeroH1 = styled.h1`
    display: flex;
    position: relative;
    color: ${props => (props.color ? props.color: "#E2DDD5")};
    font-size: 48px;
    text-align: center;

    @media screen and (max-width: 768px) {
        font-size: 40px;
    }

    @media screen and (max-width: 480px) {
        font-size: 32px;
    }
`

export const HeroP = styled.p`
margin-top: 24px;
color: #fff;
font-size: 24px;
text-align: center;
max-width: 600px;

@media screen and (max-width: 768px) {
    font-size: 24px;
}

@media screen and (max-width: 480px) {
    font-size: 18px;
}
`

export const HeroBtnWrapper = styled.div`
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    align-item: center;
`

export const ArrowForward = styled(MdArrowForward)`
    margin-left: 8px;
    font-size: 20px;

`

export const ArrowRight = styled(MdKeyboardArrowRight)`
margin-left: 8px;
font-size: 20px;
`

