import styled from "styled-components";
import hexToRgbA, { hexToRgba } from "../HexToRGBA";


export const ModalContainer = styled.div`
    display: ${props => props.isOpen? 'flex': 'none'}; /* Hidden by default */
    position: fixed; /* Stay in place */
    padding-top: 50px; /* Location of the box */
    left: 0;
    top: 0;
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
    color: ${props => props.theme.secondaryBg};
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */ /* Fallback color */
    background-color: ${props => hexToRgba(props.theme.secondaryBg, 0.4)};
    z-index: 4444;

`

export const ModalWrapper = styled.div`

background-color: transparent;
margin: auto;
padding: 2%;
align-items: center;
justify-content: center;
color: ${props => hexToRgbA(props.theme.secondaryBg)};
flex-direction: column;
min-width: 50%;
z-index: 44444;
`

export const Close = styled.div`
    display: flex;
    color: ${props => hexToRgbA(props.theme.secondaryBg)};;
    margin-left: 100%;
    width: 40px;
    height: 40px;
    font-size: 1.2rem;
    background-color: ${props => hexToRgbA(props.theme.mainBg)};;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: none;
    margin-bottom: 2%;
  
  &:hover, &: focus{
    color: ${props => hexToRgbA(props.theme.secondaryColor)};;
    text-decoration: none;
    cursor: pointer;
    `