import styled from "styled-components";

export const RightSideContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 95%;
    justify-content: flex-start;
    align-content: center;
    gap: 1%;
`
export const ArtistSideDashContainer = styled.div`
display: flex;
flex-direction: column;
padding: 2%;
margin-bottom:1%;
justify-content: space-between;
`