import React from 'react'
import { ChipContainer, CloseButton, IconContainer, Img, Text } from './ChipElements'
import {MdClose} from 'react-icons/md'

function Chip({icon, background, error, textDecoration, image, label, closeIcon, onDelete, closeButton, onChipClick}) {
  return (
    <ChipContainer textDecoration={textDecoration} background={background} error={error} >
        {(icon || image) && 
        <IconContainer onClick={onChipClick}>
            {icon}
            {image && <Img src={image} alt={''}/>}
        </IconContainer>
        }
        <Text onClick={onChipClick}>{label}</Text>
        {(closeButton || closeIcon) && <CloseButton onClick={onDelete}>
            {closeIcon ? closeIcon : <MdClose/> }
        </CloseButton>}
    </ChipContainer>
  )
}

export default Chip