import React, { useState } from 'react'
import usePodcaster from '../../../../hooks/usePodcaster'
import { FormText, FormWrapper } from '../../../CustomForm/CustomFormElements'
import ContentWrapper from '../../../ContentWrapper'

const RevenueContent = ({podcaster, isLoaded, setLoaded, filter}) => {
    const {} = usePodcaster({id: podcaster})

  return (
    <><ContentWrapper><FormWrapper><FormText>Coming Soon...</FormText></FormWrapper></ContentWrapper></>
  )
}

export default RevenueContent