import React from 'react'
import styled from 'styled-components'
import { hexToRgba } from '../HexToRGBA'
import { MintDetailsText } from '../NFTCollectionCreator/NFTCollectionCreatorElements'

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-self: center;
    margin-bottom: 2%;
    width: 100%;
    justify-content: space-evenly;
`

const Text = styled(MintDetailsText)`
    display: flex;
    float: left;
    padding: 8px 16px;
    cursor: pointer;
    max-width: 1.2rem;
    font-size: 0.8rem;
    text-align: center;
    justify-content: center;
    align-items: center;
    color: ${props => props.selected ? props.theme.accentColor : props.theme.secondaryBg};
    background-color: ${props => props.selected ?props.theme.secondaryBg : 'transparent'  };
    border-radius: 25px;
    border: solid 2px ${props => props.selected ?props.theme.secondaryBg : props.theme.accentColor};
    &:hover{
        transform: scale(1.05);
        color: ${props => props.selected ? props.theme.secondaryColor : props.theme.mainBg};

    }
`

const PageIndexer = ({pages, currentPage, onClick}) => {
  
    const showNumbers = () => {
        let pageNumbers = Array(pages)
        return<>{Array.apply(null,pageNumbers).map((number, index) => {
            return (<Text  
            selected={currentPage === index + 1} 
            onClick={() => onClick(index + 1)}>{index + 1}</Text>)
        })}</> 
    }

  
    return (
    <Container>
        {pages > 10 ? 
        <>
        <Text 
        selected={currentPage === 1} 
        onClick={() => onClick(1)}>1</Text>
        <Text selected={currentPage === 2}  onClick={() => onClick(2)}>2</Text>
        <Text selected={currentPage === 3}  onClick={() => onClick(3)}>3</Text>
        <Text selected={currentPage === 4}  onClick={() => onClick(4)}>4</Text>
        {
        (currentPage > 4 && currentPage < (pages - 1)) && 
        (pages > 10) ?
            <><Text>...</Text>
            <Text selected>{currentPage}</Text>
            <Text>...</Text></>:
            <Text>...</Text>
        }
        <Text 
        selected={currentPage === pages - 1} 
        onClick={() => onClick(pages - 1)}>{pages - 1}</Text>
        <Text 
        selected={currentPage === pages} 
        onClick={() => onClick(pages)}>{pages}</Text>
        </>: showNumbers() }
    </Container>
  )
}

export default PageIndexer