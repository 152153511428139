import React, { useEffect, useState } from "react";
import useArtist from "../../../../hooks/useArtist";
import ContentWrapper from "../../../ContentWrapper";
import { SelectButton, SelectButtonWrapper } from "../../../Select";
import {
  getGroupedPlays,
  getNumListeners,
  getObjectLength,
  getPlayCount,
  getUniqueListBy,
  groupInfoByDate,
} from "../../../../utils";
import { ModalLoadingScreen } from "../../../LoadingScreen";
import KeyMetrics from "../../../Charts/KeyMetrics";
import { MUSIC_PLAYING_FROM } from "../../../../utils/Enum";

import { ChipList } from "../../../Chip/ChipElements";
import LineGraph from "../../../Charts/Line";
import {
  PlaylistTable,
  SinglesReleasesTable,
} from "../../../ContentTable/ArtistTables";
import Chip from "../../../Chip";
import useMusic from "../../../../hooks/useMusic";
import {
  EpisodesReleaseTable,
  EpisodesTable,
} from "../../../ContentTable/PodcasterTables";
import { FormText, FormWrapper } from "../../../CustomForm/CustomFormElements";
import { useWebsiteTheme } from "../../../WebsiteTheme/WebsiteThemeContext";
import ChartToggle from "../../../Charts";

/*
Display playlist and episode stats
-Display how many playlists an artists music is added in
-Display podcast episodes an artists music is added in
-Display which playlists
-Display episodes
-display number of plays from playlists
-

*/
const PlaylistContent = ({ artistId, isLoaded, setLoaded, filter }) => {
  const {themeColors} = useWebsiteTheme()


  const {
    getArtistMusicPlaylistsAdditionbyDate,
    getArtistListenersByDate,
    getArtistMusicEpisodesAdditionbyDate,
  } = useArtist({ id: artistId });
  const { getSongs } = useMusic();
  const [listeners, setListeners] = useState();
  const [playlists, setPlaylists] = useState();
  const [episodes, setEpisodes] = useState();
  const [groupedListens, setGroupedListens] = useState();
  const [groupedPlaylists, setGroupedPlaylists] = useState();
  const [groupedEpisodes, setGroupedEpisodes] = useState();
  const [metricType, setMetricType] = useState("plays");
  const [playType, setPlayType] = useState("all");
  const [playlistsLoaded, setPlaylistsLoaded] = useState(false);
  const [songs, setSongs] = useState();
  const [isSongsLoaded, setSongsLoaded] = useState(false);

  const getData = async () => {
    setLoaded(false);
    setListeners([]);
    setGroupedEpisodes({});
    setGroupedListens({});
    setGroupedPlaylists({});
    setPlaylists([]);
    setEpisodes([]);
    await Promise.all([
      getArtistListenersByDate(artistId, filter),
      getArtistMusicPlaylistsAdditionbyDate(artistId, filter, "all"),
      getArtistMusicEpisodesAdditionbyDate(artistId, filter, "all"),
    ])
      .then(async (data) => {
        if (data[0]?.listeners?.length > 0) {
          setListeners(
            data[0]?.listeners?.filter(
              (listen) =>
                listen?.playingFrom?.playingFrom === MUSIC_PLAYING_FROM.PLAYLIST
            )
          );
          let l = groupInfoByDate(
            data[0]?.listeners?.filter(
              (listen) =>
                listen?.playingFrom?.playingFrom === MUSIC_PLAYING_FROM.PLAYLIST
            ),
            "timestamp",
            filter
          );
          setGroupedListens(l);
        }
        if (data[1]?.playlists?.length > 0) {
          setPlaylists(data[1]?.playlists);
          let p = groupInfoByDate(data[1]?.playlists, "datePublished", filter);
          setGroupedPlaylists(p);
        }
        if (data[2]?.episodes?.length > 0) {
          setEpisodes(data[2]?.episodes);
          let e = groupInfoByDate(data[2]?.episodes, "releaseDate", filter);
          setGroupedEpisodes(e);
        }
        return;
      })
      .catch((err) => {
        console.error(err);
        return;
      });
    setLoaded(true);
  };

  useEffect(() => {
    getData();

    return () => {};
  }, [artistId, filter]);

  useEffect(() => {
    if (isLoaded) {
      setSongsLoaded(false);
      let s = getSongsByPlayType();
      if (s?.length > 0) {
        getSongs(s)
          .then((Songs) => {
            if (Songs?.length > 0) {
              setSongs(
                Songs.map((song) => {
                  return {
                    ...song,
                    plays: playType === 'all' ?listeners.filter(
                      (l) =>
                        l?.songId === song?.songId
                    )?.length : listeners.filter(
                      (l) =>
                        l?.songId === song?.songId && l?.playType === playType
                    )?.length,
                  };
                })
              ).sort((a, b) => b?.plays - a?.plays);
            }
            setSongsLoaded(true);
          })
          .catch((err) => {
            setSongsLoaded(true);
          });
      }
    }

    return () => {};
  }, [playType, artistId, filter, isLoaded]);

  useEffect(() => {
    if (isLoaded && getObjectLength(groupedPlaylists) > 0) {
      setPlaylistsLoaded(false);
    }
    return () => {};
  }, [artistId, filter, isLoaded]);

  const getSongsByPlayType = () => {
    let list = [];
    switch (playType) {
      case "skip":
        list = listeners?.filter((play) => play?.playType === playType && !play?.repeat)
          .map((play) => {
            return play?.songId;
          });
        break;
      case "full":
        list = listeners?.filter((play) => play?.playType === playType || play?.repeat > 0)
          .map((play) => {
            return play?.songId;
          });
        break;
      case "partial":
        list = listeners?.filter((play) => play?.playType === playType && !play?.repeat)
          .map((play) => {
            return play?.songId;
          });
        break;

      default:
        list = listeners?.map((play) => {
            return play?.songId;
          });
        break;
    }
    return [...new Set(list)];
  };

  return (
    <>
      <ContentWrapper>
        {!isLoaded && <ModalLoadingScreen text={"Loading Stats..."} />}
        {isLoaded && (
          <>
            <KeyMetrics
              data={[
                {
                  heading: "Playlist Plays",
                  onClick: () => {
                    setMetricType("plays");
                  },
                  labels:
                    getObjectLength(groupedListens) > 0
                      ? Object.keys(groupedListens)
                      : [],
                  datasets: [
                    {
                      label: "Plays",
                      data:
                        getObjectLength(groupedListens) > 0
                          ? Object.values(groupedListens).map((plays) => {
                              return getPlayCount(plays);
                            })
                          : [],
                      borderColor: themeColors.warningColor,
                      backgroundColor: themeColors.warningColor,
                    },
                  ],
                },
                {
                  heading: "Playlist Listeners",
                  onClick: () => {
                    setMetricType("listeners");
                  },
                  labels:
                    getObjectLength(groupedListens) > 0
                      ? Object.keys(groupedListens)
                      : [],
                  datasets: [
                    {
                      label: "Listeners",
                      data:
                        getObjectLength(groupedListens) > 0
                          ? Object.values(getNumListeners(groupedListens))
                          : [],
                      borderColor: themeColors.errorColor,
                      backgroundColor: themeColors.errorColor,
                    },
                  ],
                },
                {
                  heading: "Playlist Additions",
                  onClick: () => {
                    setMetricType("playlistAdditions");
                  },
                  labels:
                    getObjectLength(groupedPlaylists) > 0
                      ? Object.keys(groupedPlaylists)
                      : [],
                  datasets: [
                    {
                      label: "Playlists",
                      data:
                        getObjectLength(groupedPlaylists) > 0
                          ? Object.values(groupedPlaylists)?.map(
                              (playlists) => {
                                return playlists?.length;
                              }
                            )
                          : [],
                      borderColor: themeColors.successColor,
                      backgroundColor: themeColors.successColor,
                    },
                  ],
                },
                {
                  heading: "Episodes Additions",
                  onClick: () => {
                    setMetricType("episodeAdditions");
                  },
                  labels:
                    getObjectLength(groupedEpisodes) > 0
                      ? Object?.keys(groupedEpisodes)
                      : [],
                  datasets: [
                    {
                      label: "Episodes",
                      data:
                        getObjectLength(groupedEpisodes) > 0
                          ? Object?.values(groupedEpisodes)?.map((episodes) => {
                              return episodes?.length;
                            })
                          : [],
                      borderColor: themeColors.background,
                      backgroundColor: themeColors.background,
                    },
                  ],
                },
              ]}
            />
            {metricType === "plays" && listeners?.length > 0 && (
              <>
                <ChipList alignment={"right"}>
                  <Chip
                    label={"All"}
                    background={playType === "all" && themeColors.accentColor}
                    onChipClick={() => setPlayType("all")}
                  />
                  <Chip
                    label={"Full Plays"}
                    background={playType === "full" && themeColors.successColor}
                    onChipClick={() => setPlayType("full")}
                  />
                  <Chip
                    label={"Partial Plays"}
                    background={playType === "partial" && themeColors.infoColor}
                    onChipClick={() => setPlayType("partial")}
                  />
                  <Chip
                    label={"Skips"}
                    background={playType === "skip" && themeColors.warningColor}
                    onChipClick={() => setPlayType("skip")}
                  />
                </ChipList>
                {playType === "all" && (
                  
                    <ChartToggle
                      data={{
                        heading: 'All Plays (By Type)',
                        labels:
                          listeners?.length > 0 > 0
                            ? Object.keys(
                                groupInfoByDate(listeners, "timestamp", filter)
                              )
                            : [],
                        datasets: [
                          {
                            label: "Full",
                            data:
                              listeners.filter((p) => p?.playType === "full")
                                ?.length > 0
                                ? Object.values(
                                    groupInfoByDate(
                                      listeners.filter(
                                        (p) => p?.playType === "full"
                                      ),
                                      "timestamp",
                                      filter
                                    )
                                  ).map((p) => {
                                    return p.length;
                                  })
                                : [],
                                backgroundColor:themeColors.successColor,
                            borderColor: themeColors.successColor,
                          },
                          {
                            label: "Partial",
                            data:
                              listeners.filter((p) => p?.playType === "partial")
                                ?.length > 0
                                ? Object.values(
                                    groupInfoByDate(
                                      listeners.filter(
                                        (p) => p?.playType === "partial"
                                      ),
                                      "timestamp",
                                      filter
                                    )
                                  ).map((p) => {
                                    return p.length;
                                  })
                                : [],
                                backgroundColor:themeColors.infoColor,
                            borderColor: themeColors.infoColor,
                          },
                          {
                            label: "Skips",
                            data:
                              listeners.filter((p) => p?.playType === "skip")
                                ?.length > 0
                                ? Object.values(
                                    groupInfoByDate(
                                      listeners.filter(
                                        (p) => p?.playType === "skip"
                                      ),
                                      "timestamp",
                                      filter
                                    )
                                  ).map((p) => {
                                    return p?.length;
                                  })
                                : [],
                                backgroundColor:themeColors.warningColor,
                            borderColor: themeColors.warningColor,
                          },
                        ],
                      }}
                    />
                 
                )}
                {playType !== "all" && (
                  
                    <ChartToggle
                      data={{
                        heading: `${playType[0].toUpperCase()}${playType.substring(
                      1
                    )}`,
                        labels:
                          getObjectLength(groupedListens) > 0
                            ? Object.keys(groupedListens)
                            : [],
                        datasets: [
                          {
                            label: playType,
                            data:
                              getObjectLength(groupedListens) > 0
                                ? Object.values(groupedListens).map((plays) => {
                                    return getGroupedPlays(plays)[playType];
                                  })
                                : [],
                                backgroundColor: playType === "skip"
                                ? themeColors.warningColor
                                : playType === "partial"
                                ? themeColors.infoColor
                                : themeColors.successColor,
                            borderColor:
                              playType === "skip"
                                ? themeColors.warningColor
                                : playType === "partial"
                                ? themeColors.infoColor
                                : themeColors.successColor,
                          },
                        ],
                      }}
                    />
                  
                )}
                {!isSongsLoaded && <ModalLoadingScreen />}
                {isSongsLoaded && (
                  <ContentWrapper>
                    <FormWrapper>
                      <SinglesReleasesTable
                        releases={songs}
                        heading={`${
                          playType !== "all"
                            ? `${playType[0].toUpperCase()}${playType.substring(
                                1
                              )} Played Songs`
                            : "All Songs"
                        }`}
                      />
                    </FormWrapper>
                  </ContentWrapper>
                )}
              </>
            )}
            {metricType === "listeners" &&
              getObjectLength(groupedListens) > 0 && (
                <>
                  
                    <ChartToggle
                      data={{
                        heading: 'Unique Listeners',
                        labels: Object.keys(groupedListens),
                        datasets: [
                          {
                            label: "Listeners",
                            data: Object.values(
                              getNumListeners(groupedListens)
                            ),
                            backgroundColor:themeColors.successColor,
                            borderColor: themeColors.successColor,
                          },
                        ],
                      }}
                    />
                  
                </>
              )}
            {metricType === "playlistAdditions" &&
              getObjectLength(groupedPlaylists) > 0 && (
                <>
                  {
                    <ContentWrapper>
                      <FormWrapper>
                        <PlaylistTable
                          playlists={playlists}
                          heading={`Playlists`}
                        />
                      </FormWrapper>
                    </ContentWrapper>
                  }
                </>
              )}
            {metricType === "episodeAdditions" &&
              getObjectLength(groupedEpisodes) > 0 && (
                <>
                  {episodes?.length > 0 && (
                    <ContentWrapper>
                      <EpisodesTable releases={episodes} heading={`Episodes`} />
                    </ContentWrapper>
                  )}
                </>
              )}
            {(((metricType === "plays" || metricType === "listeners") &&
              listeners?.length === 0) ||
              (metricType === "playlistAdditions" &&
                getObjectLength(groupedPlaylists) === 0) ||
              (metricType === "episodeAdditions" &&
                getObjectLength(groupedEpisodes) === 0)) && (
              <>
                <FormWrapper>
                  <FormText>No Data Available</FormText>
                </FormWrapper>
              </>
            )}
          </>
        )}
      </ContentWrapper>
    </>
  );
};

export default PlaylistContent;
