import { motion } from 'framer-motion';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import styled from "styled-components"

export const RevenueCards = styled.div`
    display: flex;
    padding-top: 3%;
    gap: 10px;
`

export const RevenueCardContainer = styled.div`
    width: 100%;
    padding: 1%;
`
export const CompactCardContainer = styled(motion.div)`
display: flex;
flex: 1;
height: 10rem !important;
border-radius: 0.7rem;
color: ${props => props.theme.mainBg};
position: relative;
cursor: pointer;
background: ${props => props.theme.accentColor};
box-shadow: ${props => props.theme.accentColor};
padding: 2rem;
&: hover{
    box-shadow: none !important;
}
`
export const ExpandedCardContainer = styled(motion.div)`
display: flex;
border-radius: 1rem;
color: ${props => props.theme.mainBg};
flex-direction:column;
position: absolute;
width: 60%;
height: 70vh;
z-index: 9;
left: 13;
justify-self: center;
align-self: center;
align-items: center;
justify-content: space-between;
padding: 1rem;
background: ${props => props.theme.accentColor};
box-shadow: ${props => props.theme.accentColor};
`

export const RadialBar = styled.div`
flex: 1;
display: flex;
flex-direction: column;
justify-content: center;
gap: 1rem;
`
export const CardTitle = styled.span`


`

export const CProgressbar = styled(CircularProgressbar)`
width: 4rem !important;
overflow: visible;

::path{
    stroke: white!important;
stroke-width: 12px!important;
filter: drop-shadow(2px 4px 6px white);
}
::trail{
    display: none;
}
::text{
    fill: white!important;
}
`
export const CardDetails = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    gap: 1rem;
    align-items: flex-end;
`

export const CardDetailsSpan = styled.div`
    font-size: 22px;
    font-weight: bold;
`
export const CardDetailsSpan2 = styled.div`
    font-size: 10px;
    font-weight: bold;
`

export const ChartContainer = styled.div`
    width: 70%;
`